import { useEffect, useState } from "react";
import { RuntimeConfig } from "../RuntimeConfig";
import { useAuthContext } from "../useAuth";

// This is based on what we get from TW API
export type Tasklist = {
  id: number;
  name: string;
  description: string;
  displayOrder: number;
  projectId: number;
  project: {
    id: number;
    type: string;
  };
  milestoneId: number | null;
  milestone: {
    id: number;
    type: string;
  } | null;
  isPinned: boolean;
  isPrivate: boolean;
  lockdownId: number | null;
  status: string;
  defaultTaskId: number;
  defaultTask: {
    id: number;
    type: string;
  };
  isBillable: boolean | null;
  tasklistBudget: number | null;
  createdAt: string;
  updatedAt: string;
  icon: string | null;
}

// Fetch tasklists from TW
const fetchTasklists = async (projectId: string, accessToken: string) => {
  try {
    const response = await fetch(
      `${RuntimeConfig.backendOrigin}/teamwork/project-tasklists?projectId=${projectId}`,
      {
        headers: {
          "x-api-key": accessToken,
        },
      }
    );
    return await response.json();
  } catch (err) {
    console.error(err);
  }
};

const useProjectTasklists = (projectId: string | undefined) => {
  const { accessToken } = useAuthContext();
  const [tasklists, setTasklists] = useState<Tasklist[]>([]);

  useEffect(() => {
    const getTasklists = async () => {
      if (!projectId || !accessToken) return;
      const tasklistResponse = await fetchTasklists(projectId, accessToken);
      const tasklistsFromTeamwork: Tasklist[] = tasklistResponse?.tasklists ?? [];
      const firstTasklist = tasklistsFromTeamwork.length > 0 ? tasklistsFromTeamwork[0] : null;

      if (!firstTasklist) {
        console.error(`No tasklists found for project ${projectId}`);
        setTasklists([]);
        return;
      }

      // Append a "No Tasklist" option to the tasklist array to account for time entries with a tasklist_id of 0
      // These time entries were created via the API directly on the project no tasklist id was provided
      const noTasklist: Tasklist = { 
        ...firstTasklist,
        id: 0, 
        name: "No Tasklist", 
        projectId: Number(projectId)
      };
      const tasklists: Tasklist[] = [noTasklist, ...tasklistsFromTeamwork];
      setTasklists(tasklists ?? []);
    };

    getTasklists();
  }, [projectId, accessToken]);

  return tasklists as Tasklist[];
}

export { useProjectTasklists };