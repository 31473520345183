
import _ from "lodash";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import tw, { css } from "twin.macro";
import {
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables,
  InvoiceFragmentFragment,
} from "../../codegen/graphql-types";
import { cancelledSymbol } from "../../helpers/usePromptState";
import { useAuthContext } from "../../useAuth";
import {
  getBlobUrlFromInvoiceFileName,
  SupplierInvoiceFile,
} from "./SupplierInvoiceFile";
import { usePromptSelectProject } from "./usePromptSelectProject";
import { useUpdateSingleInvoice } from "./useUpdateSingleInvoice";

const Highlighted = ({ text = "", highlight = "" }) => {
  if (!highlight.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, "gi");
  const parts = text.split(regex);
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
};

export const InvoicesTable = ({
  invoices,
  invoiceNumberHighlight,
}: {
  invoices: InvoiceFragmentFragment[];
  invoiceNumberHighlight?: string | null | undefined;
}): JSX.Element => {
  const apolloClient = useApolloClient();
  const { accessToken } = useAuthContext();

  const [deleteSingleInvoice] = useMutation<
    DeleteInvoiceMutation,
    DeleteInvoiceMutationVariables
  >(
    gql`
      mutation DeleteInvoice($invoiceId: String!) {
        deleteOneInvoice(where: { id: $invoiceId }) {
          id
        }
      }
    `
  );

  const [updateSingleInvoice] = useUpdateSingleInvoice();

  const { modal, promptSelectProject } = usePromptSelectProject();

  return (
    <>
      {modal}
      <Table tw="text-sm" cellPadding={10}>
        <Thead>
          <Tr
            css={css`
              & > th {
                text-align: left;
              }
            `}
          >
            <Th>Date</Th>
            <Th>Amount</Th>
            <Th>Payee</Th>
            <Th>Expense Type</Th>
            <Th>Invoice Number</Th>
            <Th>Project Name</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoices.map((invoice) => {
            return (
              <Tr>
                <Td>
                  <div
                    css={css`
                      width: max-content;
                    `}
                  >
                    {new Date(invoice.date_created).toLocaleDateString("en-CA")}
                  </div>
                </Td>
                <Td align="right" className="group">
                  <Button
                    css={css`
                      width: max-content;
                      cursor: pointer;
                    `}
                    variant="link"
                    size="sm"
                    onClick={() => {
                      const inputString = window.prompt(
                        "What to change this to?",
                        ((invoice.amount_in_cents ?? 0) / 100).toString()
                      );
                      if (inputString === null) return;
                      const numberInput = Number(inputString);
                      if (Number.isNaN(numberInput)) {
                        window.alert(
                          `Error parsing ${inputString} as a number. Please try again?`
                        );
                        return;
                      }
                      updateSingleInvoice({
                        variables: {
                          invoiceId: invoice.id,
                          invoiceData: {
                            amount_in_cents: {
                              set: Math.round(numberInput * 100),
                            },
                          },
                        },
                      });
                    }}
                  >
                    ${invoice.amount_in_cents / 100}
                    <EditIcon
                      css={tw`ml-2 opacity-0 group-hover:opacity-100`}
                    />
                  </Button>
                </Td>
                <Td>{invoice.payee_name}</Td>
                <Td className="group">
                  <Button
                    css={css`
                      width: max-content;
                      cursor: pointer;
                    `}
                    variant="link"
                    size="sm"
                    onClick={() => {
                      const inputString = window.prompt(
                        "What to change this to?",
                        invoice.expense_type
                      );
                      if (inputString === null) return;
                      updateSingleInvoice({
                        variables: {
                          invoiceId: invoice.id,
                          invoiceData: {
                            expense_type: {
                              set: inputString,
                            },
                          },
                        },
                      });
                    }}
                  >
                    {invoice.expense_type}
                    <EditIcon
                      css={tw`ml-2 opacity-0 group-hover:opacity-100`}
                    />
                  </Button>
                </Td>
                <Td className="group">
                  <Button
                    css={css`
                      width: max-content;
                      cursor: pointer;
                    `}
                    variant="link"
                    size="sm"
                    onClick={() => {
                      const inputString = window.prompt(
                        "What to change this to?",
                        invoice.invoice_number
                      );
                      if (inputString === null) return;
                      updateSingleInvoice({
                        variables: {
                          invoiceId: invoice.id,
                          invoiceData: {
                            invoice_number: {
                              set: inputString,
                            },
                          },
                        },
                      });
                    }}
                  >
                    <Highlighted
                      text={invoice.invoice_number}
                      highlight={invoiceNumberHighlight ?? ""}
                    />
                    <EditIcon
                      css={tw`ml-2 opacity-0 group-hover:opacity-100`}
                    />
                  </Button>
                </Td>
                <Td className="group">
                  <Button
                    css={css`
                      width: max-content;
                      cursor: pointer;
                    `}
                    variant="link"
                    size="sm"
                    onClick={async () => {
                      const response = await promptSelectProject();

                      if (response === cancelledSymbol) {
                        return;
                      }

                      updateSingleInvoice({
                        variables: {
                          invoiceId: invoice.id,
                          invoiceData: {
                            project: response.selectedProjectId
                              ? {
                                  connect: {
                                    id: response.selectedProjectId,
                                  },
                                }
                              : invoice.project
                              ? // if invoice is already not connected to a project, attempting to disconnect will result in
                                // Unhandled Rejection (Error): The records for relation `projects_invoices` between the `invoices` and `projects` models are not connected.
                                {
                                  disconnect: true,
                                }
                              : undefined,
                            notes: response.notes
                              ? {
                                  set: response.notes,
                                }
                              : undefined,
                          },
                        },
                      });
                    }}
                  >
                    {invoice.project ? (
                      invoice.project.name
                    ) : (
                      <>
                        <i>(unassociated)</i> {invoice.notes}
                      </>
                    )}
                    <EditIcon
                      css={tw`ml-2 opacity-0 group-hover:opacity-100`}
                    />
                  </Button>
                  {invoice.project && (
                    <a
                      href={`https://mavenmm.teamwork.com/app/projects/${invoice.project.id}/overview/activity`}
                    >
                      open
                    </a>
                  )}
                </Td>
                <Td>
                  {!!invoice.files?.length && (
                    <Popover isLazy trigger="hover">
                      <PopoverTrigger>
                        <Button
                          size="xs"
                          css={tw`flex flex-row items-center gap-5 my-2`}
                          onClick={async () => {
                            if (!accessToken)
                              throw new Error(`No access token`);
                            const imageUrls = await Promise.all(
                              invoice.files.map(
                                async (file) =>
                                  await getBlobUrlFromInvoiceFileName(
                                    file.fileName,
                                    accessToken
                                  )
                              )
                            );
                            window.open(
                              URL.createObjectURL(
                                new Blob(
                                  [
                                    `<!DOCTYPE html>
                                <html>
                                    <head>
                                        <title>Invoices</title>
                                    </head>
                                    <body>
                                        ${imageUrls.map(x => `<img src="${x}" />`).join("")}
                                    </body>
                                </html>`
                                  ],
                                  { type: "text/html" }
                                )
                              )
                            );
                          }}
                        >
                          View invoice
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverBody>
                            {invoice.files.map((file) => (
                              <SupplierInvoiceFile
                                fileName={file.fileName}
                                fileType={file.contentType}
                              />
                            ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  )}
                </Td>
                <Td>
                  <Flex>
                    <Button
                      size="xs"
                      onClick={async () => {
                        const isConfirmed = window.confirm(
                          `Delete invoice ${invoice.invoice_number} from ${invoice.contractor?.first_name} ${invoice.contractor?.last_name}?`
                        );
                        if (!isConfirmed) return;
                        await deleteSingleInvoice({
                          variables: {
                            invoiceId: invoice.id,
                          },
                        });

                        const cache = apolloClient.cache;

                        if (!invoice.project?.id) {
                          cache.modify({
                            fields: {
                              invoices(existingInvoiceRefs, { readField }) {
                                return existingInvoiceRefs.filter(
                                  (invoiceRef: any) => {
                                    return (
                                      invoice.id !== readField("id", invoiceRef)
                                    );
                                  }
                                );
                              },
                            },
                          });
                          return;
                        }

                        cache.modify({
                          id: cache.identify({
                            __typename: invoice.project.__typename,
                            id: invoice.project?.id,
                          }),
                          fields: {
                            invoices(existingInvoiceRefs, { readField }) {
                              return existingInvoiceRefs.filter(
                                (invoiceRef: any) => {
                                  return (
                                    invoice.id !== readField("id", invoiceRef)
                                  );
                                }
                              );
                            },
                          },
                        });
                      }}
                    >
                      delete
                    </Button>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};
