export const palette = [
  "#00429d",
  "#3e67ae",
  "#4b96db",
  "#618fbf",
  "#85b7ce",
  "#b1dfdb",
];

export const chartContainerWidth = 400;

export const primaryColor = "#458CFF";
export const secondaryColor = "#c0c0c0";

// Forecast low and high values that create a coloured cone on the charts
export const aboveForecastColor = "#71c988"; // green-ish
export const forecastColor = "#fffc9e"; // yellow-ish
export const belowForecastColor = "#f7b5a8"; // red-ish
// Grey cone use in ClientRevenueChart
export const forecastGreyColor = "#c0c0c0";

export const EMPTY_ARRAY: ReadonlyArray<any> = Object.freeze([]);
export const currencyFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

export type ComparisonChartData = Array<{
    year: number;
    x: string;
    y: number;
    monthName: string;
  }>;

export type BrandPalette = {
  id: string;
  name: string;
  primary: string;
};

export const brandPalette: BrandPalette[] = [
  { "id": "000001", "name": "Other", "primary": "#c0c0c0" }, // default color for when the Other category is needed for combining small slices of data
  { "id": "145653", "name": "Janssen - BALVERSA™", "primary": "#7fc42f" },
  { "id": "104471", "name": "Janssen - CEE", "primary": "#e76cd3" },
  { "id": "104446", "name": "Janssen - EPREX®", "primary": "#E51B43" },
  { "id": "70859", "name": "Janssen - BioAdvance®", "primary": "#B1B8CE" },
  {
    "id": "94461",
    "name": "Janssen - ERLEADA®/ ZYTIGA®",
    "primary": "#47768C"
  },
  { "id": "176389", "name": "Janssen - Akeega", "primary": "#41AA5E" },
  { "id": "81533", "name": "Janssen - DARZALEX®", "primary": "#722C9A" },
  {
    "id": "86405",
    "name": "Janssen - INVEGA TRINZA®/ INVEGA SUSTENNA®",
    "primary": "#74BDE7"
  },
  { "id": "76889", "name": "Janssen - INVOKANA®", "primary": "#CD0451" },
  { "id": "85464", "name": "Janssen - Medical", "primary": "#093633" },
  {
    "id": "96850",
    "name": "Janssen - Marketing Materials",
    "primary": "#9fbdca"
  },
  { "id": "70860", "name": "Janssen - SIMPONI®", "primary": "#aafc8e" },
  { "id": "86755", "name": "Janssen - IMBRUVICA®", "primary": "#2FA8E1" },
  {
    "id": "115682",
    "name": "Janssen - SIMPONI® (Rheum)",
    "primary": "#BA0042"
  },
  { "id": "80630", "name": "Janssen - REMICADE®", "primary": "#207FC5" },
  {
    "id": "115681",
    "name": "Janssen - SIMPONI® UC",
    "primary": "#b1b1c3"
  },
  { "id": "125056", "name": "Janssen - SPRAVATO™", "primary": "#DD0A52" },
  {
    "id": "82648",
    "name": "Janssen - STELARA® (UC/CD)",
    "primary": "#5E3191"
  },
  { "id": "99237", "name": "Janssen - other", "primary": "#fbf4ad" },
  { "id": "77331", "name": "Janssen - Training", "primary": "#c01620" },
  { "id": "176180", "name": "Janssen - TECVAYLI", "primary": "#3641C2" },
  { "id": "95792", "name": "Janssen - SYMTUZA®", "primary": "#B71033" },
  { "id": "70861", "name": "Janssen - ZYTIGA®", "primary": "#dc5f21" },
  {
    "id": "89876",
    "name": "Janssen - TREMFYA® / TREMFYA ONE-PRESS™",
    "primary": "#38BAAD"
  },
  { "id": "100116", "name": "Merck", "primary": "#1C8F86" },
  { "id": "130134", "name": "Janssen - SEB", "primary": "#e64353" },
  { "id": "151302", "name": "Merck - ONTRUZANT", "primary": "#103f53" },
  { "id": "151300", "name": "Merck - ZENHALE®", "primary": "#687355" },
  { "id": "163716", "name": "Janssen - Rybrevant", "primary": "#F16133" },
  { "id": "158441", "name": "Organon", "primary": "#3CDBC0" },
  { "id": "62812", "name": "Newtopia", "primary": "#528b9c" },
  { "id": "178161", "name": "Organon - JADA", "primary": "#322d28" },
  { "id": "171899", "name": "ORGANON - Nexplanon", "primary": "#D9DA55" },
  {
    "id": "60228",
    "name": "Maven Medical Marketing Inc.",
    "primary": "#ca8ff6"
  },
  { "id": "138075", "name": "Precision Medical", "primary": "#e6f513" },
  { "id": "176876", "name": "Abbott", "primary": "#FFC0CB" },
  {
    "id": "145386",
    "name": "Astra Zeneca - IMFINZI®",
    "primary": "#2ccc34"
  },
  { "id": "160841", "name": "Alaxo", "primary": "#c4488d" },
  { "id": "60647", "name": "Eisai", "primary": "#08def1" },
  { "id": "108058", "name": "Eli Lilly", "primary": "#EF2231" },
  { "id": "69193", "name": "Dr. Shayegan", "primary": "#ce3829" },
  { "id": "176729", "name": "Eli Lilly - Kisunla", "primary": "#FFBD05" },
  { "id": "163433", "name": "Organon - ZENHALE", "primary": "#258BCB" },
  { "id": "178198", "name": "Organon - NuvaRing", "primary": "#b93ab1" },
  { "id": "164092", "name": "Eli Lilly - Adcirca", "primary": "#FCBB7E" },
  { "id": "134447", "name": "Prostate Cancer Canada", "primary": "#4fa583" },
  { "id": "70862", "name": "Janssen - CONCERTA®", "primary": "#F16722" },
  { "id": "151075", "name": "Eli Lilly - Reyvow", "primary": "#994DA3" },
  { "id": "153248", "name": "Organon - PSP", "primary": "#c0d345" },
  { "id": "154364", "name": "Eli Lilly - Emgality", "primary": "#008C95" },
  {
    "id": "150988",
    "name": "Emergent BioSolutions - Narcan",
    "primary": "#FF2FAF"
  },
  { "id": "115749", "name": "Ferring - PENTACARE®", "primary": "#887b05" },
  { "id": "129541", "name": "Ferring", "primary": "#94dcd4" },
  { "id": "177141", "name": "Emergent - orderNARCAN.ca", "primary": "#373738" },
  { "id": "178073", "name": "GSK", "primary": "#fe8905" },
  { "id": "77302", "name": "FREELANCERS", "primary": "#aba0ac" },
  { "id": "152555", "name": "Indivior", "primary": "#203468" },
  { "id": "154992", "name": "Indivior - Unbranded", "primary": "#c03964" },
  { "id": "154993", "name": "Indivior - Sublocade", "primary": "#EA3F3C" },
  { "id": "61274", "name": "Janssen", "primary": "#EB1700" },
  { "id": "173469", "name": "HowWeHalloween", "primary": "#8d2de3" },
  { "id": "177870", "name": "Janssen - (bota vec)", "primary": "#e1676a" },
  { "id": "154994", "name": "Indivior - Suboxone Film", "primary": "#7AC144" },
  { "id": "82647", "name": "Janssen - GUSELKUMAB", "primary": "#df9e10" }
];