import tw, { css } from "twin.macro";
import React, { useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  createColumnHelper,
  flexRender,
  Table,
  Row,
} from "@tanstack/react-table";
import { PersonTimeTarget } from "./PersonTimeTarget";
import {
  BillableTooltip,
  BillableVsNonBillableRate,
  DaysWorkedPopover,
  UtilizedVsNonUtilizedRate,
  UtilizedYtdTimeCell,
} from "./tablePopovers";

type Role = {
  roleTitle: string;
  teams: Team[];
};

type Team = {
  teamName: string;
  jobTitles: string[];
};

// The teamwork user's job title is used to determine which team they are on
// If the tw user's job title is not in the role->team jobTitle array, they will not appear in the table for that role->team
export const ROLES: Role[] = [
  {
    roleTitle: "Senior",
    teams: [
      {
        teamName: "Account team",
        jobTitles: [
          "Senior Client Services Director",
          "Client Services Director",
        ],
      },
      {
        teamName: "Design team",
        jobTitles: ["Associate Creative Director"],
      },
      {
        teamName: "Dev team",
        jobTitles: ["Senior Developer"],
      },
    ],
  },
  {
    roleTitle: "Mid-level",
    teams: [
      {
        teamName: "Account team",
        jobTitles: ["Senior Brand Maven", "Brand Maven"],
      },
      {
        teamName: "Design team",
        jobTitles: ["Art Director"],
      },
    ],
  },
  {
    roleTitle: "Junior",
    teams: [
      {
        teamName: "Account team",
        jobTitles: ["Project Manager", "Associate Project Manager"],
      },
      {
        teamName: "Design team",
        jobTitles: ["Senior Designer", "Designer", "Production Designer"],
      },
      {
        teamName: "Dev team",
        jobTitles: ["Technical Lead", "Developer"],
      },
      {
        teamName: "Copy team",
        jobTitles: ["Proofreader"],
      },
    ],
  },
];

export type ProjectHours = {
  projectName: string;
  totalHours: number;
};

export type Hours = {
  label: string;
  startDate: Date;
  endDate: Date;
  totalMinutes: number;
  numberOfWorkingDays: number;
  numberOfDaysOff: number;
  projects: ProjectHours[];
  target: number;
  actual_ytd: number;
  percent_of_target: number;
};

export type BillableVsNonBillableTime = {
  label: string;
  startDate: Date;
  endDate: Date;
  totalHours: number;
  billableHours: number;
  nonBillableHours: number;
  rate: number;
};

export type UtilizedVsNonUtilizedTime = {
  label: string;
  startDate: Date;
  endDate: Date;
  totalHours: number;
  utilizedHours: number;
  billableHours: number;
  professionalDevelopmentHours: number;
  operationalHours: number;
  innovationDevelopmentHours: number;
  salesMarketingHours: number;
  internalAdministrativeHours: number;
  rate: number;
};

export type PersonTimeAnalysis = {
  roleTitle: "Senior" | "Mid-level" | "Junior" | "";
  teamName: "Account team" | "Design team" | "Dev team" | "Copy team" | "";
  jobTitle: string;
  name: string;
  id: string;
  days: {
    startDate: Date;
    endDate: Date;
    totalDaysInRange: number;
    workingDays: number;
    weekendDays: number;
    holidaysInRangeIncludingExtendedLongWeekends: number;
    daysOffWithinRange: number;
    sickDays: number;
    vacationDays: number;
    otherDaysOff: number;
  };
  rate: number;
  totalHours: Hours;
  utilizedTime: {
    billable: Hours;
    sales_marketing: Hours;
    innovation_development: Hours;
    operational: Hours;
    professional_development: Hours;
  };
  nonUtilizedTime: {
    internal_administrative: Hours;
  };
  utilizationRate: {
    billable_vs_nonBillable: BillableVsNonBillableTime;
    utilized_vs_nonUtilized: UtilizedVsNonUtilizedTime;
  };
};

export type DaysWorked = PersonTimeAnalysis["days"];

// ROW COMPONENTS
const RoleRow = ({
  table,
  role,
  children,
}: {
  table: Table<PersonTimeAnalysis>;
  role: Role;
  children: React.ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const tdCss = css`
    border-bottom: 0px;
    background-color: #cbcacb;
    border-bottom: 1px solid #cbcacb !important;
  `;

  // @ts-ignore
  const firstColumnGroupLength = table.getAllColumns()[0].columnDef?.columns[0]
    .columns.length;

  return (
    <>
      <tr>
        <td
          colSpan={100}
          css={css`
            border-bottom: 0px;
            background-color: #dfbcd2;
          `}
        >
          <div
            css={[
              tw`text-left p-1`,
              css`
                border-bottom: 2px solid #000;
              `,
            ]}
          >
            <button onClick={() => setIsExpanded(!isExpanded)}>
              <strong
                css={css`
                  font-size: 16px;
                `}
              >
                {isExpanded ? "▼" : "▶"}&nbsp;{role.roleTitle}
              </strong>
            </button>
          </div>
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={firstColumnGroupLength + 2} css={tdCss}>
            <div
              css={[
                tw`text-left p-1 pl-4`,
                css`
                  border-bottom: 0px;
                `,
              ]}
            >
              <span
                css={css`
                  font-size: 12px;
                `}
              >
                Team:
              </span>
            </div>
          </td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td
            css={css`
              background-color: #cbcacb;
              border-bottom: 1px solid #cbcacb !important;
            `}
          >
            Average:
          </td>
          <td colSpan={2} css={tdCss}></td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td colSpan={18} css={tdCss}></td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td></td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
          <td css={tdCss}>&nbsp;Average:&nbsp;</td>
        </tr>
      )}
      {isExpanded && children}
    </>
  );
};

const TeamRow = ({
  table,
  role,
  team,
  children,
}: {
  table: Table<PersonTimeAnalysis>;
  role: Role;
  team: Team;
  children: React.ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const rowIsThisRoleAndTeam = (row: Row<PersonTimeAnalysis>) =>
    row.original.roleTitle === role.roleTitle &&
    row.original.teamName === team.teamName;

  const teamMembers: Row<
    PersonTimeAnalysis
  >[] = table.getRowModel().rows.filter(rowIsThisRoleAndTeam);

  const teamMembersActualHours: number[] = teamMembers.map(
    (row) => row.original.totalHours.actual_ytd
  );

  const averageActualHours: number =
    teamMembersActualHours.reduce((acc, val) => acc + val, 0) /
    teamMembersActualHours.length;
  const averageActualHoursValue =
    averageActualHours > 0 ? `${averageActualHours.toFixed(1)}` : "";

  const teamMembersPercentOfTarget: number[] = teamMembers.map(
    (row) => row.original.totalHours.percent_of_target
  );

  const averagePercentOfTarget: number =
    teamMembersPercentOfTarget.reduce((acc, val) => acc + val, 0) /
    teamMembersPercentOfTarget.length;
  const averagePercentOfTotalTargetValue =
    averagePercentOfTarget > 0 ? `${averagePercentOfTarget.toFixed(0)}%` : "";

  const teamMembersBillableHours: number[] = teamMembers.map(
    (row) => row.original.utilizedTime.billable.actual_ytd
  );

  const averageBillableHours: number =
    teamMembersBillableHours.reduce((acc, val) => acc + val, 0) /
    teamMembersBillableHours.length;
  const averageBillableHoursValue =
    averageBillableHours > 0 ? `${averageBillableHours.toFixed(1)}` : "";

  const teamMembersPercentOfTargetBillable: number[] = teamMembers.map(
    (row) => row.original.utilizedTime.billable.percent_of_target
  );

  const averagePercentOfTargetBillable: number =
    teamMembersPercentOfTargetBillable.reduce((acc, val) => acc + val, 0) /
    teamMembersPercentOfTargetBillable.length;
  const averagePercentBillableTargetValue =
    averagePercentOfTargetBillable > 0
      ? `${averagePercentOfTargetBillable.toFixed(0)}%`
      : "";

  const teamMembersNonUtilizedTime: number[] = teamMembers.map(
    (row) => row.original.nonUtilizedTime.internal_administrative.actual_ytd
  );

  const averageNonUtilizedTime: number =
    teamMembersNonUtilizedTime.reduce((acc, val) => acc + val, 0) /
    teamMembersNonUtilizedTime.length;
  const averageNonUtilizedTimeValue =
    averageNonUtilizedTime > 0 ? `${averageNonUtilizedTime.toFixed(1)}` : "";

  const teamMemberPercentNonUtilizedTime: number[] = teamMembers.map(
    (row) =>
      row.original.nonUtilizedTime.internal_administrative.percent_of_target
  );

  const averagePercentNonUtilizedTime: number =
    teamMemberPercentNonUtilizedTime.reduce((acc, val) => acc + val, 0) /
    teamMemberPercentNonUtilizedTime.length;
  const averagePercentNonUtilizedValue =
    averagePercentNonUtilizedTime > 0
      ? `${averagePercentNonUtilizedTime.toFixed(0)}%`
      : "";

  // Average Utilization rates
  // 1) Billable vs Non-Billable:
  /// Billable vs Non-Billable Utilization Rate: "Total (average) Billable hours (per day) divided by (average) total hours (per day)"
  const teamMembersBillableVsNonBillableUtilizationRates: number[] = teamMembers.map(
    (row) => row.original.utilizationRate.billable_vs_nonBillable.rate
  );
  const averageBillableVsNonBillableUtilizationRate: number =
    teamMembersBillableVsNonBillableUtilizationRates.reduce(
      (acc, val) => acc + val,
      0
    ) / teamMembersBillableVsNonBillableUtilizationRates.length;
  const averageBillableVsNonBillableRateValue =
    averageBillableVsNonBillableUtilizationRate > 0
      ? `${averageBillableVsNonBillableUtilizationRate.toFixed(0)}%`
      : "";
  // 2) Utilized vs Non-Utilized
  /// Utilized vs Non-Utilized Utilization Rate: "Total (average) Utilized hours (per day) (sum) divided by (average) total hours (per day)"
  const teamMembersUtilizedVsNonUtilizedUtilizationRates: number[] = teamMembers.map(
    (row) => row.original.utilizationRate.utilized_vs_nonUtilized.rate
  );
  const averageUtilizedVsNonUtilizedUtilizationRate: number =
    teamMembersUtilizedVsNonUtilizedUtilizationRates.reduce(
      (acc, val) => acc + val,
      0
    ) / teamMembersUtilizedVsNonUtilizedUtilizationRates.length;
  const averageUtilizedVsNonUtilizedRateValue =
    averageUtilizedVsNonUtilizedUtilizationRate > 0
      ? `${averageUtilizedVsNonUtilizedUtilizationRate.toFixed(0)}%`
      : "";

  const tdCss = [
    tw`font-bold`,
    css`
      background-color: #fff8b8;
      border-bottom: 1px solid #000 !important;
    `,
  ];

  // @ts-ignore
  const firstColumnGroupLength = table.getAllColumns()[0].columnDef?.columns[0]
    .columns.length;

  return (
    <>
      <tr>
        <td colSpan={firstColumnGroupLength + 2} css={[tw`pl-4`, tdCss]}>
          <div css={[tw`text-left p-1`, css``]}>
            <button onClick={() => setIsExpanded(!isExpanded)}>
              <strong
                css={css`
                  font-size: 14px;
                `}
              >
                {isExpanded ? "▼" : "▶"}&nbsp;{team.teamName}
              </strong>
            </button>
          </div>
        </td>
        <td css={tdCss}>{averageActualHoursValue}</td>
        <td css={tdCss}>{averagePercentOfTotalTargetValue}</td>
        <td colSpan={2} css={tdCss}></td>
        <td css={tdCss}>{averageBillableHoursValue}</td>
        <td css={tdCss}>{averagePercentBillableTargetValue}</td>
        <td colSpan={18} css={tdCss}></td>
        <td css={tdCss}>{averageNonUtilizedTimeValue}</td>
        <td css={tdCss}>{averagePercentNonUtilizedValue}</td>
        <td></td>
        <td css={tdCss}>{averageBillableVsNonBillableRateValue}</td>
        <td css={tdCss}>{averageUtilizedVsNonUtilizedRateValue}</td>
      </tr>
      {isExpanded && children}
    </>
  );
};

// CELL COMPONENTS
const ColorizedPercentOfTarget = ({ info }: { info: any }) => {
  const percentOfTarget = info.getValue();
  const value = percentOfTarget > 0 ? `${percentOfTarget.toFixed(0)}%` : "";
  const redClass = percentOfTarget >= 115 ? "red-bg" : "";
  const orangeClass =
    percentOfTarget >= 105 && percentOfTarget < 115 ? "orange-bg" : "";
  const yellowClass =
    percentOfTarget >= 101 && percentOfTarget < 105 ? "yellow-bg" : "";
  return (
    <div className={`${redClass} ${orangeClass} ${yellowClass}`}>{value}</div>
  );
};

const UtilizedPercentOfTargetCell = ({ info }: { info: any }) => {
  const percentOfTarget = info.getValue().percent_of_target;
  const value = percentOfTarget > 0 ? `${percentOfTarget}%` : "";
  return <span>{value}</span>;
};

const RoundedValueCell = ({
  info,
  bold = false,
}: {
  info: any;
  bold?: boolean;
}) => {
  const value = info.getValue();
  const notZeroValue = value > 0 ? value.toFixed(1) : "";
  return (
    <span style={{ fontWeight: bold ? "bold" : "normal" }}>{notZeroValue}</span>
  );
};

// MAIN COMPONENT
export const TeamTimeTargets = ({
  peopleTimeData, // data
  yearInFocus,
}: {
  peopleTimeData: PersonTimeAnalysis[];
  yearInFocus: number;
}) => {
  //✅ GOOD: This will not cause an infinite loop of re-renders because 'timeData' is a stable reference
  const [timeData, setTimeData] = useState<PersonTimeAnalysis[]>(
    peopleTimeData
  );

  // Updates table row data state for one person
  const updateTargetsStateForPerson = (updatedPerson: PersonTimeAnalysis) => {
    setTimeData(
      timeData.map((person) => {
        if (person.id === updatedPerson.id) {
          return updatedPerson;
        }
        return person;
      })
    );
  };

  // COLUMN DEFINITIONS
  //✅ GOOD: This will not cause an infinite loop of re-renders because `columns` is a stable reference
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<PersonTimeAnalysis>();
    // Create columns based on PersonTimeAnalysis type
    return [
      columnHelper.group({
        id: "person",
        header: "",
        cell: () => null,
        columns: [
          columnHelper.group({
            id: "person_info",
            header: "",
            cell: () => null,
            columns: [
              columnHelper.accessor("jobTitle", {
                id: "job_title",
                header: () => <div style={{ textAlign: "left" }}>Role</div>,
                meta: {
                  className: "job-title",
                  headerProps: {
                    className: "role-header",
                  },
                },
                cell: (info) => (
                  <div
                    style={{ textAlign: "left" }}
                    css={css`
                      white-space: nowrap;
                    `}
                  >
                    {info.getValue()}
                  </div>
                ),
              }),
              columnHelper.accessor("name", {
                id: "name",
                header: () => <span>Team Member</span>,
                meta: {
                  className: "blue-bg",
                },
                cell: (info) => {
                  return (
                    <PersonTimeTarget
                      info={info}
                      yearInFocus={yearInFocus}
                      updateTimeDataState={updateTargetsStateForPerson}
                    />
                  );
                },
              }),
              columnHelper.accessor("days", {
                id: "days",
                header: "Days Worked (YTD)",
                meta: {
                  className: "blue-bg",
                },
                cell: (info) => (
                  <DaysWorkedPopover
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("rate", {
                id: "rate",
                header: "Current Loaded Rate",
                meta: {
                  className: "blue-bg",
                },
                cell: (info) => {
                  const rateInCents = info.getValue();
                  const value = rateInCents > 0 ? `$${rateInCents / 100}` : "";
                  return <span>{value}</span>;
                },
              }),
            ],
          }),
        ],
      }),
      // Empty column for gap
      columnHelper.display({
        id: "gap1",
        header: "",
        cell: () => null,
        meta: {
          isGap: true,
          headerProps: {
            className: "gap",
          },
        },
      }),
      columnHelper.group({
        id: "total_hours",
        header: () => <span>&nbsp;</span>,
        columns: [
          columnHelper.group({
            header: "Total Hours",
            columns: [
              columnHelper.accessor("totalHours.target", {
                id: "total_hours_target",
                header: () => <span>Target</span>,
                cell: (info) => <RoundedValueCell info={info} />,
              }),
              columnHelper.accessor("totalHours.actual_ytd", {
                id: "total_hours_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => <RoundedValueCell info={info} bold={true} />,
              }),
              columnHelper.accessor("totalHours.percent_of_target", {
                id: "total_hours_percent_of_target",
                header: () => <span>% of Target</span>,
                meta: (info: any) => {
                  const percentOfTarget = info.getValue();
                  return {
                    headerProps: {
                      className: percentOfTarget > 100 ? "red-bg" : "",
                    },
                  };
                },
                cell: (info) => <ColorizedPercentOfTarget info={info} />,
              }),
            ],
          }),
        ],
      }),
      // Empty column for gap
      columnHelper.display({
        id: "gap2",
        header: "",
        cell: () => null,
        meta: {
          isGap: true,
          headerProps: {
            className: "gap",
          },
        },
      }),
      // Utilized Time (Billable + Time categories)
      columnHelper.group({
        id: "utilized_time",
        header: () => (
          <span
            css={css`
              font-size: 20px;
            `}
          >
            Utilized time
          </span>
        ),
        meta: {
          headerProps: {
            className: "green-bg",
          },
        },
        // Billable
        columns: [
          columnHelper.group({
            header: "Billable",
            columns: [
              columnHelper.accessor("utilizedTime.billable.target", {
                id: "billable_target",
                header: () => <span>Target</span>,
                cell: (info) => <RoundedValueCell info={info} />,
              }),
              columnHelper.accessor("utilizedTime.billable", {
                id: "billable_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => <BillableTooltip values={info.getValue()} />,
              }),
              columnHelper.accessor("utilizedTime.billable.percent_of_target", {
                id: "billable_percent_of_target",
                header: () => <span>% of Target</span>,
                cell: (info) => {
                  const percentOfTarget = info.getValue();
                  const value =
                    percentOfTarget > 0 ? `${percentOfTarget.toFixed(0)}%` : "";
                  const redClass = percentOfTarget < 85 ? "red-bg" : "";
                  const orangeClass =
                    percentOfTarget >= 85 && percentOfTarget < 95
                      ? "orange-bg"
                      : "";
                  const yellowClass =
                    percentOfTarget >= 95 && percentOfTarget < 99
                      ? "yellow-bg"
                      : "";
                  return (
                    <div
                      className={`${redClass} ${orangeClass} ${yellowClass}`}
                    >
                      {value}
                    </div>
                  );
                },
              }),
            ],
          }),
          // Empty column for gap
          columnHelper.display({
            id: "gap3",
            header: "",
            cell: () => null,
            meta: {
              isGap: true,
              headerProps: {
                className: "gap",
              },
            },
          }),
          // Sales & Marketing
          columnHelper.group({
            header: "Sales & Marketing",
            columns: [
              columnHelper.accessor("utilizedTime.sales_marketing.target", {
                id: "sales_marketing_taget",
                header: () => <span>Target</span>,
                cell: (info) => <RoundedValueCell info={info} />,
              }),
              columnHelper.accessor("utilizedTime.sales_marketing", {
                id: "sales_marketing_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => (
                  <UtilizedYtdTimeCell
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("utilizedTime.sales_marketing", {
                id: "sales_marketing_percent_of_target",
                header: () => <span>% of Target</span>,
                cell: (info) => <UtilizedPercentOfTargetCell info={info} />,
              }),
            ],
          }),
          // Empty column for gap
          columnHelper.display({
            id: "gap4",
            header: "",
            cell: () => null,
            meta: {
              isGap: true,
              headerProps: {
                className: "gap",
              },
            },
          }),
          columnHelper.group({
            header: "Innovation & Development",
            columns: [
              columnHelper.accessor(
                "utilizedTime.innovation_development.target",
                {
                  id: "innovation_development_taget",
                  header: () => <span>Target</span>,
                  cell: (info) => <RoundedValueCell info={info} />,
                }
              ),
              columnHelper.accessor("utilizedTime.innovation_development", {
                id: "innovation_development_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => (
                  <UtilizedYtdTimeCell
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("utilizedTime.innovation_development", {
                id: "innovation_development_percent_of_target",
                header: () => <span>% of Target</span>,
                cell: (info) => <UtilizedPercentOfTargetCell info={info} />,
              }),
            ],
          }),
          // Empty column for gap
          columnHelper.display({
            id: "gap5",
            header: "",
            cell: () => null,
            meta: {
              isGap: true,
              headerProps: {
                className: "gap",
              },
            },
          }),
          columnHelper.group({
            header: "Operational",
            columns: [
              columnHelper.accessor("utilizedTime.operational.target", {
                id: "operational_taget",
                header: () => <span>Target</span>,
                cell: (info) => <RoundedValueCell info={info} />,
              }),
              columnHelper.accessor("utilizedTime.operational", {
                id: "operational_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => (
                  <UtilizedYtdTimeCell
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("utilizedTime.operational", {
                id: "operational_percent_of_target",
                header: () => <span>% of Target</span>,
                cell: (info) => <UtilizedPercentOfTargetCell info={info} />,
              }),
            ],
          }),
          // Empty column for gap
          columnHelper.display({
            id: "gap6",
            header: "",
            cell: () => null,
            meta: {
              isGap: true,
              headerProps: {
                className: "gap",
              },
            },
          }),
          columnHelper.group({
            header: "Professional Development",
            columns: [
              columnHelper.accessor(
                "utilizedTime.professional_development.target",
                {
                  id: "professional_development_taget",
                  header: () => <span>Target</span>,
                  cell: (info) => <RoundedValueCell info={info} />,
                }
              ),
              columnHelper.accessor("utilizedTime.professional_development", {
                id: "professional_development_actual_ytd",
                header: () => <span>Actual YTD</span>,
                cell: (info) => (
                  <UtilizedYtdTimeCell
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("utilizedTime.professional_development", {
                id: "professional_development_percent_of_target",
                header: () => <span>% of Target</span>,
                cell: (info) => <UtilizedPercentOfTargetCell info={info} />,
              }),
            ],
          }),
        ],
      }),
      // Empty column for gap
      columnHelper.display({
        id: "gap7",
        header: "",
        cell: () => null,
        meta: {
          isGap: true,
          headerProps: {
            className: "gap",
          },
        },
      }),
      columnHelper.group({
        id: "non_utilized_time",
        header: () => (
          <div
            css={css`
              background-color: #c50000;
              font-size: 20px;
            `}
          >
            Non-utilized time
          </div>
        ),
        meta: {
          headerProps: {
            className: "red-bg",
          },
        },
        columns: [
          columnHelper.group({
            header: "Internal meetings & administrative tasks",
            meta: {
              headerProps: {
                className: "red-bg",
              },
            },
            columns: [
              columnHelper.accessor(
                "nonUtilizedTime.internal_administrative.target",
                {
                  id: "internal_administrative_taget",
                  header: () => <span>Target</span>,
                  meta: {
                    headerProps: {
                      className: "red-bg",
                    },
                  },
                  cell: (info) => <RoundedValueCell info={info} />,
                }
              ),
              columnHelper.accessor("nonUtilizedTime.internal_administrative", {
                id: "internal_administrative_actual_ytd",
                header: () => <span>Actual YTD</span>,
                meta: {
                  headerProps: {
                    className: "red-bg",
                  },
                },
                cell: (info) => {
                  const actualYtd: number = info.getValue().actual_ytd;
                  const value = actualYtd >= 0.1 ? actualYtd.toFixed(1) : "";
                  return <span>{value}</span>;
                },
              }),
              columnHelper.accessor(
                "nonUtilizedTime.internal_administrative.percent_of_target",
                {
                  id: "internal_administrative_percent_of_target",
                  header: () => <span>% of Target</span>,
                  meta: {
                    headerProps: {
                      className: "red-bg",
                    },
                  },
                  cell: (info) => <ColorizedPercentOfTarget info={info} />,
                }
              ),
            ],
          }),
        ],
      }),
      // Empty column for gap
      columnHelper.display({
        id: "gap8",
        header: "",
        cell: () => null,
        meta: {
          isGap: true,
          headerProps: {
            className: "gap",
          },
        },
      }),
      columnHelper.group({
        id: "utilization_rate",
        header: "",
        columns: [
          columnHelper.group({
            header: () => (
              <span
                css={css`
                  font-size: 16px;
                `}
              >
                Utilization Rates
              </span>
            ),
            id: "utilization_rates",
            columns: [
              columnHelper.accessor("utilizationRate.billable_vs_nonBillable", {
                id: "billable_vs_nonBillable",
                header: () => <span>Billable vs Non-Billable</span>,
                cell: (info) => (
                  <BillableVsNonBillableRate
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
              columnHelper.accessor("utilizationRate.utilized_vs_nonUtilized", {
                id: "utilized_vs_nonUtilized",
                header: () => <span>Utilized vs Non-Utilized</span>,
                cell: (info) => (
                  <UtilizedVsNonUtilizedRate
                    values={info.getValue()}
                    name={info.row.original.name}
                  />
                ),
              }),
            ],
          }),
        ],
      }),
    ];
  }, [timeData]);

  // Columns and data are defined in a stable reference, will not cause infinite loop!
  const table = useReactTable({
    columns: columns,
    data: timeData, //also good to use a fallback array that is defined outside of the component (stable reference)
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // console.log(table.getRowModel().rows) // array of rows

  return (
    <main css={tw`w-full flex flex-col justify-center`}>
      <table
        css={[
          tw`w-full mt-4 overflow-x-auto`,
          css`
            & {
              border-collapse: separate;
              border-spacing: 0;
            }
            th {
              background-color: #233b4d;
              color: #fff;
              font-weight: bold;
              border-bottom: 1px solid #ccc;
              padding: 0.5rem;
            }
            .role-header {
              min-width: 250px;
            }
            .job-title {
              background-color: #dcdcdc;
              padding-left: 2em;
              padding-right: 0.5em;
              font-weight: bold;
            }
            .yellow-bg {
              background-color: #fefba0;
            }
            .orange-bg {
              background-color: #fed694;
            }
            .red-bg {
              background-color: #fea08c;
            }
            .blue-bg {
              color: #fff;
              background-color: #223a4c;
            }
            th.green-bg {
              background-color: #067101;
            }
            th.red-bg {
              background-color: #c50000;
            }
            .gap {
              width: 8px;
              background-color: #fff8b8;
              font-size: 0px;
              padding: 0px;
            }
            & {
              font-size: 0.8rem;
            }
            td {
              text-align: center;
              border-bottom: 1px solid #ccc;
            }
          `,
        ]}
      >
        <thead css={tw`bg-gray-700 z-10 sticky top-0`}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                // @ts-ignore
                <th
                  css={tw`bg-gray-700`}
                  key={header.id}
                  colSpan={header.colSpan}
                  {...header.column.columnDef.meta?.headerProps}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {ROLES.map((role) => {
            return (
              // @ts-ignore
              <RoleRow table={table} role={role} key={role.roleTitle}>
                {role.teams
                  .filter((team) =>
                    table
                      .getRowModel()
                      .rows.some(
                        (row) =>
                          row.original.roleTitle === role.roleTitle &&
                          row.original.teamName === team.teamName
                      )
                  )
                  .map((team) => {
                    return (
                      // @ts-ignore
                      <TeamRow
                        table={table}
                        role={role}
                        team={team}
                        key={team.teamName}
                      >
                        {table
                          .getRowModel()
                          .rows.sort((a, b) =>
                            b.original.jobTitle.localeCompare(
                              a.original.jobTitle
                            )
                          )
                          .filter(
                            (row) =>
                              row.original.teamName === team.teamName &&
                              team.jobTitles.includes(row.original.jobTitle)
                          )
                          .map((row) => {
                            return (
                              <tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                  return (
                                    <td
                                      key={cell.id}
                                      // @ts-ignore
                                      className={
                                        cell.column.columnDef.meta?.className
                                      }
                                    >
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                      </TeamRow>
                    );
                  })}
              </RoleRow>
            );
          })}
        </tbody>
      </table>
    </main>
  );
};
