
import tw from "twin.macro";
import { useMutation } from "@apollo/client"
import { AddDayOffDocument, RemoveDayOffDocument, Person, DayOff, UserTimeSummaryDocument } from "../../codegen/graphql-types";
import { useState } from "react";
import { parse, startOfDay } from "date-fns";
import { convertToUTCTimeZone, Holiday, holidaysForThisYear } from "../dates";
import { useRecentTimeContext } from "../../helpers/useRecentTimeContext";

type DayOffData = {
  __typename?: "DayOff";
  date: string;
  reason: string;
  personId: string;
}

export default function Day({
  day,
  user,
  yearInFocus
}: {
  day: string,
  user: Person,
  yearInFocus: number
}) {
  const { refetch } = useRecentTimeContext();
  const startDate: Date = new Date(yearInFocus, 0, 1); // Jan 1st
  const endDate: Date = new Date(yearInFocus + 1, 0, 1); // Jan 1st, yearInFocus + 1
  const parsedDay = parse(day, 'EEE LLL dd yyyy', new Date());
  const [addDayOff, { loading, error }] = useMutation(AddDayOffDocument);
  const [removeDayOff] = useMutation(RemoveDayOffDocument);
  
  // Check if user has a day off on this day
  // console.log("User days off: ", user.daysOff);
  const usersDayOff: DayOff | undefined = user.daysOff?.find(dayOff => {
    // only compare the day, month, and year, not the time where dayOff.date is "YYYY-MM-DDT00:00:00.000Z";
    const dayOffYearMonthDay: string = dayOff.date.split("T")[0];
    const dayOfWeekYearMonthDay: string = parsedDay.toISOString().split("T")[0];
    return dayOffYearMonthDay === dayOfWeekYearMonthDay;
  });
  const [dayOff, setDayOff] = useState<DayOffData | undefined>(usersDayOff);
  // Check if this day is a holiday, social day, or extended-long-weekend (if included in statutoryHolidays for year)
  const holidays = holidaysForThisYear(parsedDay.getFullYear());
  const holiday: Holiday | undefined = holidays.find(holiday => {
    // compare only the month, day, and year, dont compare the time of the dates
    const holidayYearMonthDay: string = holiday.observedDate; // already in "YYYY-MM-DD" format
    const dayOfWeekYearMonthDay: string = parsedDay.toISOString().split("T")[0];
    return holidayYearMonthDay === dayOfWeekYearMonthDay;
  });
  // Check if this day is a weekend
  const isWeekend: boolean = parsedDay.getDay() === 0 || parsedDay.getDay() === 6;

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.log(error);
    return <div>There was an error.</div>;
  }

  const handleSelectDayOffReason = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const dayOffReason = event.target.value;

    if (!dayOffReason) {
      setDayOff(undefined);
      return;
    }

    if (dayOffReason === 'cancel') {
      setDayOff(undefined);
      // Trigger removeDayOff mutation
      const UTCDate = convertToUTCTimeZone(parsedDay);
      removeDayOff({ 
        variables: { 
          personId: user.id,
          date: startOfDay(UTCDate)
        },
        refetchQueries: [{
          query: UserTimeSummaryDocument,
          variables: {
            start_date: startDate,
            end_date: endDate,
            year: yearInFocus
          }
        }],
        onCompleted: () => {
          // refetch the user's recent time summary (Last 3 months)
          refetch();
          setDayOff(undefined);
        }
      });
      return;
    }

    // otherwise, mark the day off
    const UTCDate = convertToUTCTimeZone(parsedDay);
    addDayOff({ 
      variables: { 
        personId: user.id,
        reason: dayOffReason, 
        date: startOfDay(UTCDate)
      },
      refetchQueries: [{
        query: UserTimeSummaryDocument,
        variables: {
          start_date: startDate,
          end_date: endDate,
          year: yearInFocus
        }
      }],
      onCompleted: () => {
        // refetch the user's recent time summary (Last 3 months)
        refetch();
      }
    });
  }

  // Take the year off the end of the day string
  const dayString: string = day.slice(0, -5);

  return (
    <div 
      style={{ 
        background: usersDayOff || holiday || isWeekend ? "#D1D5DB" : "",
        height: "70px"
      }}
      css={tw`flex relative flex-col justify-start items-center hover:bg-gray-300 rounded-md p-2 py-3`}
    >

      <div css={tw`text-xl`} style={{ fontWeight: "normal" }}>{dayString}</div>

      {holiday && (
        <div css={tw`text-xs text-center`}>
          {holiday.nameEn}
        </div>
      )}

      {dayOff && !holiday && !isWeekend && (
        <select 
          value={usersDayOff?.reason}
          onChange={handleSelectDayOffReason}
          css={tw`text-center cursor-pointer bg-transparent`}
        > 
          <option value="">Select</option>
          <option value="vacation">VACATION</option>
          <option value="sick">SICK</option>
          <option value="other">OTHER</option>
          {usersDayOff && <option value="cancel">CANCEL</option>}
        </select>
      )}
    
      {!dayOff && !holiday && !isWeekend && (
        <button 
          onClick={() => setDayOff({
            reason: "",
            date: parsedDay.toISOString(),
            personId: user.id
          })}
          css={tw`cursor-pointer text-white hover:text-gray-800`}
        >
          Set day off
        </button>
      )}
      
    </div>
  )
}