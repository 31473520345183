import { useContext, useState } from "react";
import { PricingContext } from "../../helpers/usePricing";
import tw from "twin.macro";
import { 
  ProjectedExpense,
  CreateProjectedExpenseDocument,
} from "../../codegen/graphql-types";
import { Button, useToast } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { usePricingDataContext } from "../../helpers/usePricingData";
import { BudgetInput, SelectExpenseType, SelectPayeeName } from "./expenseFields";
import type { BriefProjectedExpense } from "./expenseFields";
import validateBudgetFields from "./validateBudgetFields";

const NewProjectedExpenseForm = ({
  projectId,
  projectPricingId,
  projectedExpenses,
}: {
  projectId: string;
  projectPricingId: number;
  projectedExpenses: ProjectedExpense[];
}) => {
  const { refetchProjectPricing } = usePricingDataContext();
  const toast = useToast();
  const { peopleData } = useContext(PricingContext);
  const [open, setOpen] = useState(false);
  const [newExpense, setNewExpense] = useState<BriefProjectedExpense>({
    project_id: projectId,
    project_pricing_id: projectPricingId,
    expense_type: "",
    payee_name: "",
    budget_in_cents: 0,
  });
  const [createProjectedExpense, { loading, error }] = useMutation(CreateProjectedExpenseDocument);

  const clearForm = () => {
    setNewExpense({
      project_id: projectId,
      project_pricing_id: projectPricingId,
      expense_type: "",
      payee_name: "",
      budget_in_cents: 0,
    });
  };

  // Form submission handler
  const addNewExpense = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { isValid, errorType, errorMessage } = validateBudgetFields({
      fields: newExpense,
      projectedExpenses
    });

    if (!isValid) {
      toast({
        title: `Error: ${errorType}`,
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await createProjectedExpense({
        variables: {
          projectId: newExpense.project_id,
          projectPricingId: newExpense.project_pricing_id,
          budgetInCents: newExpense.budget_in_cents * 100,
          expenseType: newExpense.expense_type,
          payeeName: newExpense.payee_name,
        },
        onCompleted: () => {
          // Refetch the project pricing data to update the table
          refetchProjectPricing();
          toast({
            title: "Success",
            description: "Expense added successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
      });
      clearForm();
    } catch (err) {
      console.error("Error adding budgeted expense:", err);
      toast({
        title: "Error",
        // @ts-ignore
        description: err.message ? err.message : "An error occurred. Please contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {open ? (
        <form css={tw`mt-4 grid grid-cols-4 gap-x-3`} onSubmit={addNewExpense}>
          <SelectExpenseType 
            expense={newExpense} 
            setExpense={setNewExpense}
            isNewExpense={true}
          />
          <SelectPayeeName
            expense={newExpense}
            setExpense={setNewExpense}
            peopleData={peopleData}
            isNewExpense={true}
          />
          <BudgetInput expense={newExpense} setExpense={setNewExpense} />
          <div aria-label="Form actions">
            <label css={tw`block mb-1 font-medium invisible`}>Add Button</label>
            <Button
              size="md"
              css={tw`bg-blue-500 text-white px-4 py-2 hover:bg-blue-600`}
              isDisabled={loading || !newExpense.expense_type || !newExpense.payee_name}
              type="submit"
              style={{ marginRight: "10px" }}
              
            >
              {loading ? "Submitting..." : "Add Budgeted Expense"}
            </Button>
            <Button
              size="md"
              css={tw`bg-gray-500 text-white px-4 py-2 hover:bg-gray-600`}
              onClick={() => {
                clearForm();
                setOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </form>
      ) : (
        <Button
          size="sm"
          css={tw`bg-gray-800 ml-auto max-w-sm text-white hover:bg-blue-600`}
          onClick={() => setOpen(true)}
        >
          Add Expense
        </Button>
      )}
    </>
  );
};

export default NewProjectedExpenseForm;
