import { CUSTOM_TIME_TAGS_MAP } from "./CUSTOM_TAG_MAP";
import { TimeEntry } from "../codegen/graphql-types";

// This function takes all of a user's non-billable time entries groups them by their utilized time parts/categories,
// for each category of utilized time, according to the properties in the custom time tags map.
// Each time entry has a project_tag_id field that corresponds to a category.

export interface UtilizedTimeParts {
  [key: string]: TimeEntry[];
}

export const sumUtilizedTimeParts = (userNonBillableTimeEntries: TimeEntry[]): UtilizedTimeParts => {
  const utilizedTimeParts: UtilizedTimeParts = {};
  Object.keys(CUSTOM_TIME_TAGS_MAP).forEach((key) => {
    const timeMap: Record<string, number> = CUSTOM_TIME_TAGS_MAP;
    const timeTagId: number = timeMap[key];
    const timeEntries = userNonBillableTimeEntries.filter((entry) => Number(entry.project_tag_id) === timeTagId);
    utilizedTimeParts[key] = timeEntries;
  });
  return utilizedTimeParts;
};

// Write a function that filters out all time entries that have a project_tag_id in the CUSTOM_TIME_TAGS_MAP unless it is the id with a key of "Internal non-billable time"
export const getNonUtilizedTimeEntries = (nonBillableTimeEntries: TimeEntry[]): TimeEntry[] => {
  const internalNonBillableTimeTagId = CUSTOM_TIME_TAGS_MAP["Internal non-billable time"];
  const utilizedTimeTagIds = Object.values(CUSTOM_TIME_TAGS_MAP).filter((tagId) => tagId !== internalNonBillableTimeTagId);
  return nonBillableTimeEntries.filter((entry) => !utilizedTimeTagIds.includes(Number(entry.project_tag_id)));
}