export class RuntimeConfig {
  static get backendOrigin() {
    
    //const isLocal = window.location.search.includes('localhost');
    const isDevMode = process.env.NODE_ENV === 'development';
    
    // make sure we use the local backend origin when running in dev mode
    if (isDevMode) {
      // console.log("Using local backend origin");
      return "http://localhost:4000";
    }
    
    // console.log("Using production backend origin");
    return "https://dashboard-api.mavenmm.com"
  }

  static get frontendOrigin() {
    const isDevMode = process.env.NODE_ENV === 'development';
    
    // make sure we use the local frontend origin when running in dev mode
    if (isDevMode) {
      return "http://localhost:4994";
    }
    
    return "https://dashboard.mavenmm.com"
  }
}
