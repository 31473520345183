import styled from "@emotion/styled";
import { chartContainerWidth } from "./constants";

export const ChartHeading = styled.div`
  /* height: 64px; */
  width: 400px;
`;

export const ChartWrapper = styled.div`
    width: ${chartContainerWidth}px;
`