import tw from "twin.macro";
import { useQuickbooksResourceContext } from "../../helpers/useQuickbooksContext";
import { Spinner } from "@chakra-ui/react";
import * as QBO from "../../../../server/lib/quickbooks/types";
import NewQuickbooksInvoice from "./New/NewQuickbooksInvoice";
import ViewQuickbooksInvoice from "./View/ViewQuickbooksInvoice";
import type { InvoicePath } from "./QuickbooksInvoice"

export type InvoiceFragment = {
  id: string;
  created_at: string;
  invoice_number: string;
  date_invoiced: string;
  amount_in_cents: number;
  quickbooks_id: string;
  estimate: {
    id: string;
    name: string;
    po_number: string;
    client_invoice_count: number;
    pdf_file_key: string;
    company: {
      id: string;
      company_name: string;
    };
    brand: {
      id: string;
      brand_name: string;
    };
  };
};

export type QuickbooksResources = {
  Customer: QBO.Customer[];
  Invoice: QBO.Invoice[];
  Item: QBO.Item[];
  Term: QBO.Term[];
  TaxRate: QBO.TaxRate[];
  TaxCode: QBO.TaxCode[];
};

const GetQuickbooksResources = ({
  dashboardInvoiceData,
  invoicePath,
}: {
  dashboardInvoiceData: InvoiceFragment;
  invoicePath: InvoicePath | undefined;
}) => {
  const { qbResources, isLoading, error } = useQuickbooksResourceContext();

  if (isLoading)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex flex-col justify-center items-center`}>
          <p>Fetching data from Quickbooks...</p>
          <br />
          <br />
          <Spinner />
        </section>
      </div>
    );

  if (error)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex justify-center items-center`}>
          <p style={{ color: "red" }}>
            Error fetching data from quickbooks: {error.message}
          </p>
        </section>
      </div>
    );

  console.log("invoicePath", invoicePath);
  console.log("qbResources", qbResources);

  if (qbResources && invoicePath === "new") {
    return <NewQuickbooksInvoice
      dashboardInvoiceData={dashboardInvoiceData}
      qbResources={qbResources as QuickbooksResources}
    />
  }

  if (qbResources && invoicePath === "view") {
    return dashboardInvoiceData.quickbooks_id ? (
      <ViewQuickbooksInvoice
        dashboardInvoiceData={dashboardInvoiceData}
        qbResources={qbResources as QuickbooksResources}
      />
    ) : (
      <p>
        This invoice has not been created in Quickbooks yet. Please create a new invoice in Quickbooks from the Invoices page.
      </p>
    );
  }

  return <p css={tw`p-5`}>You may need to log in to Quickbooks</p>;
};

export default GetQuickbooksResources;
