
import tw, { css } from "twin.macro";
import { useState } from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  Button
} from "@chakra-ui/react";

type SelectYearProps = {
  title: string;
  yearInFocus: number;
  setYearInFocus: (newYear: number) => void;
}

export const SelectYear = ({ title, yearInFocus, setYearInFocus }: SelectYearProps) => {
  const thisYear = new Date().getFullYear();
  const [yearInput, setYearInput] = useState<number | undefined>(yearInFocus ?? thisYear);

  const changeYearInFocus = () => {
    if (yearInput) {
      setYearInFocus(yearInput);
    }
  };

  return(
    <div css={tw`absolute right-5 w-full flex justify-end items-center`}>
      <FormControl
        css={css`
          max-width: 10em;
          display: flex;
          align-items: center;
          margin-right: 1em;
        `}
      >
        <NumberInput
          value={yearInput}
          onChange={(newYear) => setYearInput(Number(newYear))}
        >
          <NumberInputField
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <Button
        onClick={changeYearInFocus}
        colorScheme="blue"
        size="sm"
        style={{ height: '2.5em' }}
      >
        Update Year
      </Button>
    </div>
  )
}