import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { PropsWithChildren, useMemo } from "react";
import { TypedTypePolicies } from "./codegen/apollo-helpers";
import { RuntimeConfig } from "./RuntimeConfig";

export const ApolloClientProvider = ({
  children,
  accessToken,
  adminUserOverride,
}: PropsWithChildren<{
  accessToken: string;
  adminUserOverride: string | null;
}>) => {
  const apolloClient = useMemo(() => {
    if (!accessToken) return null;
    console.log("returning new apollo client");

    const httpLink = createHttpLink({
      uri: (operation) => {
        return `${
          RuntimeConfig.backendOrigin
        }/graphql?operationName=${encodeURIComponent(operation.operationName)}`;
      },
      headers: {
        'x-api-key': accessToken,
        'x-admin-user-override': adminUserOverride ?? "",
      },
    });

    return new ApolloClient({
      link: httpLink,
      name: 'maven-dashboard-ui',
      cache: new InMemoryCache({
        typePolicies,
      }),
    });
  }, [accessToken, adminUserOverride]);

  if (!apolloClient) return null;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const typePolicies: TypedTypePolicies = {
  // Keys in this object will be validated against the typed on your schema
  Person: {
    keyFields: ["id"],
  },
};
