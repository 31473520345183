import { gql, useMutation } from "@apollo/client";
import {
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
} from "../../codegen/graphql-types";

export const useUpdateSingleInvoice = () => {
  return useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(gql`
    mutation UpdateInvoice(
      $invoiceId: String!
      $invoiceData: invoiceUpdateInput!
    ) {
      updateOneInvoice(data: $invoiceData, where: { id: $invoiceId }) {
        id
        invoice_number
        amount_in_cents
        expense_type
        notes
        project {
          id
          invoices {
            id
            amount_in_cents
          }
        }
      }
    }
  `);
};
