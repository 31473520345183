
import tw from "twin.macro";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../useAuth";
import { RuntimeConfig } from "../../RuntimeConfig";

export const getBlobUrlFromInvoiceFileName = (
  fileName: string,
  accessToken: string
) => {
  const url = `${RuntimeConfig.backendOrigin}/supplier-invoices/${fileName}`;
  const headers = new Headers();
  headers.append("x-api-key", accessToken);

  return fetch(url, {
    method: "GET",
    headers: headers,
  })
    .then((response) => {
      if (response.ok) {
        return response.blob();
      } else {
        throw new Error("Image request failed.");
      }
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

export const WithSupplierInvoiceBlobUrl = ({
  children,
  fileName,
  fileType,
}: {
  children: (props: { blobUrl: string }) => JSX.Element;
  fileName: string;
  fileType: string;
}) => {
  const { accessToken } = useAuthContext();
  const [blobUrl, setBlobUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!accessToken) return;

    getBlobUrlFromInvoiceFileName(fileName, accessToken)
      .then((blobUrl) => {
        setBlobUrl(blobUrl);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [fileName, fileType, accessToken]);

  if (!blobUrl) return null;

  return children({ blobUrl });
};

export function SupplierInvoiceFile({
  fileName,
  fileType,
  ...props
}: {
  fileName: string;
  fileType: string;
}) {
  return (
    <WithSupplierInvoiceBlobUrl fileName={fileName} fileType={fileType}>
      {({ blobUrl }) => (
        <img
          src={blobUrl}
          alt=""
          {...props}
          onClick={() => window.open(blobUrl)}
          css={tw`max-w-full max-h-full`}
        />
      )}
    </WithSupplierInvoiceBlobUrl>
  );
}
