
import tw from "twin.macro";
import FluxCapacoMeter from "./FluxCapacoMeter";
import { DayOff, TimeEntry } from "../../codegen/graphql-types";
import { useDateRanges } from "../useDateRanges";
import { daysInDateRange } from "../dates";
import { subDays } from "date-fns";

type FluxCapacityRowProps = {
  totalTargetMinutesPerDay: number;
  recentTimeEntries: TimeEntry[];
  timeEntriesForYearInFocus: TimeEntry[];
  startDate: Date;
  yearInFocus: number;
  daysOff: DayOff[];
  daysOffForYearInFocus: DayOff[];
};

export default function FluxCapacityRow({
  totalTargetMinutesPerDay,
  recentTimeEntries,
  timeEntriesForYearInFocus,
  startDate,
  yearInFocus,
  daysOff, // recent days off
  daysOffForYearInFocus, // days off for the year in focus
}: FluxCapacityRowProps) {
  const currentYear: number = new Date().getFullYear();
  const yesterday = subDays(new Date(), 1);
  const yearEnd: Date = new Date(yearInFocus + 1, 0, 1); // Jan 1st, yearInFocus + 1
  const endOfRangeDate: Date = yearInFocus === currentYear ? yesterday : yearEnd;

  const daysOffForFocus: DayOff[] = yearInFocus === currentYear ? daysOffForYearInFocus : daysOff;

  const { lastWeek, lastFourWeeks, lastThreeMonths } = useDateRanges();

  const {
    numberOfWorkingDaysInRange: numOfWorkingDaysYearToDate
  } = daysInDateRange(startDate, endOfRangeDate, daysOffForFocus);
  
  return (
    <tr>
      <th css={tw`px-4 py-1 text-left`} colSpan={2}>&nbsp;</th>
      <td css={tw`px-4 py-1 text-center`}>
        <FluxCapacoMeter
          totalTargetMinutesPerDay={totalTargetMinutesPerDay}
          data={recentTimeEntries}
          startDate={lastWeek.startDate}
          endDate={lastWeek.endDate}
          daysOff={daysOff}
        />
      </td>
      <td css={tw`px-4 py-1 text-center`}>
        <FluxCapacoMeter
          totalTargetMinutesPerDay={totalTargetMinutesPerDay}
          data={recentTimeEntries}
          startDate={lastFourWeeks.startDate}
          endDate={lastFourWeeks.endDate}
          daysOff={daysOff}
        />
      </td>
      <td css={tw`px-4 py-1 text-center`}>
        <FluxCapacoMeter
          totalTargetMinutesPerDay={totalTargetMinutesPerDay}
          data={recentTimeEntries}
          startDate={lastThreeMonths.startDate}
          endDate={lastThreeMonths.endDate}
          daysOff={daysOff}
        />
      </td>
      <td css={tw`px-4 py-1 text-center`}>
        <FluxCapacoMeter
          totalTargetMinutesPerDay={totalTargetMinutesPerDay}
          data={timeEntriesForYearInFocus}
          startDate={startDate.toISOString()}
          endDate={endOfRangeDate.toISOString()}
          daysOff={daysOffForFocus}
          numOfWorkingDaysForYear={numOfWorkingDaysYearToDate}
        />
      </td>
    </tr>
  )
}