import { useParams } from "react-router-dom";
import tw, {css} from "twin.macro";
import { useContext } from "react";
import { PricingContext } from "../helpers/usePricing";
import ProjectTasklistPricingTable from "./ProjectTasklistPricingTable";
import ProjectDetails from "./ProjectDetails";
import TasklistDetails from "./tasklist/TasklistDetails";
// import ComparableSelector from "./ComparableSelector";
import { Button } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import {
  CreateProjectPricingDocument,
  CreateProjectPricingProjectedTimesDocument,
} from "../codegen/graphql-types";
import { usePricingDataContext } from "../helpers/usePricingData";
import { ProjectPricingSelector } from "./ProjectPricingSelector";
import { usePeopleContext } from "../helpers/usePeopleContext";

export const ProjectPricingById = () => {
  const { data: peopleData } = usePeopleContext();
  const me = peopleData?.me;

  const { projectId } = useParams<{ projectId: string }>();

  const {
    refetchProjectPricing,
    projectDetails,
    projectDetailsLoading,
    projectDetailsError,
  } = usePricingDataContext();

  const { tasklists, meanProjectRates, pricingAppData } = useContext(
    PricingContext
  );

  const [createProjectPricing, createProjectPricingMutation] = useMutation(
    CreateProjectPricingDocument
  );
  const [createProjectedTimes, createProjectedTimesMutation] = useMutation(
    CreateProjectPricingProjectedTimesDocument
  );

  if (projectDetailsLoading) {
    return <p>Fetching project data from database...</p>;
  }

  if (projectDetailsError || !projectDetails) {
    console.log("Error fetching project pricing data:", projectDetailsError);
    return (
      <div css={tw`p-4 flex flex-col gap-y-3`}>
        <strong css={tw`text-red-400`}>Error fetching project pricing data: {projectDetailsError?.message}</strong>
        <ProjectPricingSelector projectDetails={projectDetails} />
      </div>
    );
  }

  if (tasklists.length === 0) {
    return (
      <div css={tw`p-4 flex flex-col gap-y-3`}>
        <strong css={tw`text-red-400`}>No tasklists found for project. This project is either archived or has no tasklists. Check the job in Teamwork by clicking the link below.</strong>
        <ProjectPricingSelector projectDetails={projectDetails} />
      </div>
    );
  }

  const backgroundBriefTasklistId = tasklists.find(
    (tasklist) => tasklist.name === "Background/Brief"
  )?.id ?? 0;

  // if there's no project pricing data for the project, return a button to create it
  if (!projectDetails || !projectDetails.project_pricing) {
    return (
      <div css={tw`p-4 w-full flex justify-center`}>
        <Button
          css={tw`mt-4 mx-auto`}
          onClick={async () => {
            // Create project pricing data
            try {
              await createProjectPricing({
                variables: {
                  projectId: projectId,
                  tasklistId: backgroundBriefTasklistId,
                  creatorId: me ? me.id : "328226", 
                },
              });
              // Refetch project pricing data
              refetchProjectPricing();
            } catch (error) {
              console.error("Error creating project pricing data:", error);
            }
          }}
        >
          {createProjectPricingMutation.loading
            ? "Creating..."
            : "Start Project Pricing"}
        </Button>
      </div>
    );
  }

  const projectPricing = projectDetails.project_pricing;
  const projectedTimes = projectPricing.projected_times;
  const projectPricingId = projectPricing.id?.toString() ?? "";
  const tasklistIds = tasklists
    .filter((tasklist) => tasklist.name !== "Inbox")
    .map((tasklist) => tasklist.id?.toString());

  // Filter tasklist ids that exist in tasklists that are not in projected times
  const missingTasklistIdsInProjectedTimes = tasklistIds.filter(
    (tasklistId) =>
      !projectedTimes.some((pt) => pt.tasklist_id === tasklistId)
  );

  if (missingTasklistIdsInProjectedTimes.length > 0) {
    console.log("Missing tasklist ids in projected times:", missingTasklistIdsInProjectedTimes);
  }

  return (
    <div css={[tw`px-8 py-4 h-full overflow-x-scroll`, css`max-width: 100vw;`]}>
      <section css={tw`grid grid-cols-2`}>
        <ProjectPricingSelector projectDetails={projectDetails} />

        {projectDetails && (
          <ProjectDetails projectDetails={projectDetails} />
        )}
      </section>

      <hr css={tw`my-6 border border-gray-800`} />

      {/* PHASE 2: Comparables */}
      {/* <div css={tw`px-8`}>
        <ComparableSelector />
      </div> */}

      {/* Project Pricing Table */}
      <div>
        {pricingAppData &&
          tasklists &&
          tasklists.length > 0 &&
          meanProjectRates &&
          meanProjectRates.length > 0 && (
            <div key={projectId}>
              <ProjectTasklistPricingTable
                // The SSOT pricingAppData state is passed as a PROP because we need to ensure...
                // it is always defined with calculated values before this child component renders
                pricingAppData={pricingAppData}
              />
            </div>
          )}
      </div>

      {/* Tasklist Details Table */}
      <hr css={tw`my-6 border border-gray-800`} />

      {/* Create projected times for tasklist id of "0" (No Tasklist) */}
      {projectedTimes.length > 0 && missingTasklistIdsInProjectedTimes.length > 0 && meanProjectRates && (
        <div css={tw`px-0 mb-4`}>
          <Button
            onClick={async () => {
              // Create projected times
              const uniqueTeamIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.team.id))
              );
              const uniqueRoleIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.role.id))
              );
              // Create projected times for tasklist_id = 0
              try {
                await createProjectedTimes({
                  variables: {
                    projectId: projectId,
                    projectPricingId: projectPricingId,
                    tasklistIds: missingTasklistIdsInProjectedTimes,
                    teamIds: uniqueTeamIds,
                    roleIds: uniqueRoleIds,
                    minutes: 0,
                  },
                });
                // Refetch project pricing data
                refetchProjectPricing();
              } catch (error) {
                console.error("Error creating projected times for tasklist_id = 0:", error);
              }
            }}
          >
            Create Projected Times for New Tasklists
          </Button>
        </div>
      )}

      <div>
        {pricingAppData &&
          projectedTimes &&
          projectedTimes.length > 0 &&
          meanProjectRates &&
          meanProjectRates.length > 0 && (
            <TasklistDetails
              // The SSOT pricingAppData state is passed as a PROP because we need to ensure...
              // it is always defined with calculated values before this child component renders
              pricingAppData={pricingAppData}
              meanProjectRates={meanProjectRates}
              projectedTimes={projectedTimes}
            />
          )}
      </div>

      {/* If no projected times exist, render button to create one for each tasklist/role/team */}
      {projectedTimes.length === 0 && meanProjectRates && (
        <div css={tw`px-8`}>
          <Button
            onClick={async () => {
              // Create projected times
              const uniqueTeamIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.team.id))
              );
              const uniqueRoleIds = Array.from(
                new Set(meanProjectRates.map((rate) => rate.role.id))
              );

              try {
                await createProjectedTimes({
                  variables: {
                    projectId: projectId,
                    projectPricingId: projectPricingId,
                    tasklistIds: tasklistIds,
                    teamIds: uniqueTeamIds,
                    roleIds: uniqueRoleIds,
                    minutes: 0,
                  },
                });
                // Refetch project pricing data
                refetchProjectPricing();
              } catch (error) {
                console.error("Error creating projected times:", error);
              }
            }}
          >
            Create Projected Times
          </Button>
        </div>
      )}

      {/* Projected Times Creation Loading */}
      {createProjectedTimesMutation.loading && (
        <div css={tw`px-8`}>
          <p>
            Creating projected times for each tasklist, role, and team from
            project rates...
          </p>
        </div>
      )}
    </div>
  );
};
