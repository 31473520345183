
import tw, { css, TwStyle } from 'twin.macro';
import { SerializedStyles } from '@emotion/react';
import { useState, ReactElement } from 'react';

type Style = TwStyle | SerializedStyles;

interface SortArrowProps {
  onSortToggle: () => void;
}

const SortArrow = ({ onSortToggle }: SortArrowProps): ReactElement => {
  return (
    <button onClick={onSortToggle} css={[tw`absolute top-0 right-0 mr-1 text-sm`]}>↕️</button>
  );
};

interface SortableColumnHeaderProps {
  label: ReactElement | string;
  headerCss: any;
  handleSort: () => void;
  key?: number;
}

const SortableColumnHeader = ({ label, headerCss, handleSort }: SortableColumnHeaderProps): ReactElement => {
  return (
    <th css={headerCss}>
      <div css={tw`relative px-4 w-full h-full`}>
        <span>{label}</span><SortArrow onSortToggle={handleSort} />
      </div>
    </th>
  );
};

interface ParentColumn {
  title: string;
  colSpan: number;
  css?: Style[]; // e.g. [tw`sticky top-36`, css`background: aliceblue;`]
}

interface Column {
  title: ReactElement | string;
  key: string; // property name in the data row
  dataKey?: any; // an optional extra key to pass to the cell renderer to access more data for the row
  cell: (value: any, dataKey?: any) => ReactElement | string | number; // What to render with the value of the dataRow accessed by key
  css?: Style[]; // e.g. [tw`sticky top-36`, css`background: aliceblue;`]
}

interface TableProps {
  dataRows: Record<string, any>[];
  columns: Column[];
  defaultSortColumn?: string;
  parentColumns?: ParentColumn[];
}

export const Table = ({
  parentColumns,
  columns,
  dataRows,
  defaultSortColumn
}: TableProps): ReactElement => {
  const [sortColumn, setSortColumn] = useState<string>(defaultSortColumn ?? "");
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const toggleSortDirection = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      toggleSortDirection();
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  const sortComparator = (a: Record<string, any>, b: Record<string, any>) => {
    if (sortDirection === 'asc') {
      return a[sortColumn] > b[sortColumn] ? 1 : -1;
    } else {
      return a[sortColumn] < b[sortColumn] ? 1 : -1;
    }
  };

  const sortedData = [...dataRows].sort(sortComparator);

  const defaultColumnCss = [tw`font-bold`, css`background: aliceblue; max-width: 10rem;`];

  return (
    <table
      css={[
        tw`ml-4 mt-4`,
        css`
          width: max-content;
          text-align: center;
          td, th {
            font-size: 0.875rem;
            line-height: 1.25rem;
            border: 1px solid #ccc;
            padding: 0.25rem 0.5rem;
          }
          th {
            font-weight: bold;
          }
        `,
      ]}
    >
      <thead>
        {parentColumns && (
          <tr css={css`background: aliceblue;`}>
            {parentColumns.map(({ title, colSpan, css = defaultColumnCss }, index) => (
              <th key={index} css={css} colSpan={colSpan}>{title}</th>
            ))}
          </tr>
        )}
        <tr css={css`background: aliceblue; border-right: solid 1px #ddd; border-left: solid 1px #ddd;`}>
          {columns.map((column, i) => (
            <SortableColumnHeader
              key={i}
              label={column.title}
              headerCss={defaultColumnCss}
              handleSort={() => handleSort(column.key)}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((row, index) => (
          <tr key={index}>
            {columns.map((column) => (
              <td key={column.key} css={column.css}>{column.cell(row[column.key], row[column.dataKey])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
