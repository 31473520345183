
import tw, { css } from "twin.macro";
import { useState } from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import Select from "react-select";
import type { ClientNetSalesRow } from "../../../helpers/brandAnalysis";
import { ClientRevenueChart } from "./ClientRevenueChart";
import { ClientAnnualRevenueForecast } from "../../../codegen/graphql-types";

// Define the enum for different filters for lines that can appear on the brands graph
export enum GraphLine {
  LastYear = "Last Year", // green line
  ThisYear = "This Year", // red line
  ThisYearTotal = "This Year Total", // blue line
  ThisYearForecast = "This Year Forecast", // grey cone
}

const GraphFilter = ({
  label, 
  colour, 
  onChange, 
  selectedGraphLines, 
  graphLineFilterString,
}: {
  label: string;
  graphLineFilterString: GraphLine;
  colour: string,
  selectedGraphLines: GraphLine[],
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  // const height = cone ? "10px" : "5px";
  const isChecked = selectedGraphLines.includes(graphLineFilterString);
  return (
    <label css={[
      tw`flex my-2 items-center justify-center px-4 py-2 rounded-full cursor-pointer transition-colors duration-300 ease-in-out`,
      css`background-color: ${isChecked ? colour : "white"};
           border: 2px solid ${isChecked ? colour : "grey"};
           &:hover {
             background-color: ${isChecked ? "white" : colour};
             color: ${isChecked ? "black" : "white"};
           }`
    ]}>
      <div css={css`
        display: inline-block; 
        background-color: ${colour}; 
        width: 15px;
        height: 15px;
        margin-right: 10px;
        border-radius: 50%;
        border: ${isChecked ? "1px solid white" : "none"};
      `}></div>
      <input name={graphLineFilterString} checked={isChecked} onChange={onChange} css={tw`sr-only`} type="checkbox" /> 
      {label}
    </label>
  )
}


export const BrandPerformance = ({ clientRows, yearInFocus, forecastsResultData }: {
  clientRows: ClientNetSalesRow[],
  yearInFocus: number,
  forecastsResultData: ClientAnnualRevenueForecast[]
}): JSX.Element => {
  const brandIdsWithForecastData = forecastsResultData
    .filter((client) => client.lowForecastDollars > 0 || client.highForecastDollars > 0)
    .map(client => client.clientId);
  const [selectedClients, setSelectedClients] = useState<string[]>(brandIdsWithForecastData);
  const [selectedGraphLines, setSelectedGraphLines] = useState<GraphLine[]>([
    GraphLine.LastYear, // completed + cancelled
    GraphLine.ThisYear, // completed + cancelled
    GraphLine.ThisYearTotal, // completed + cancelled + in progress + on hold
    GraphLine.ThisYearForecast, // net sales forecast (low and high)
  ]);

  const handleToggleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Add/Remove the GraphLine from the selectedGraphLines array when a checkbox is toggled
    // Attempt to convert the string back to a GraphLine enum value
    const graphLineValue = event.target.name as GraphLine; // This assumes the names match exactly
    if (event.target.checked) {
      setSelectedGraphLines((prevSelectedGraphLines) => [...prevSelectedGraphLines, graphLineValue]);
    } else {
      setSelectedGraphLines((prevSelectedGraphLines) => prevSelectedGraphLines.filter((selectedGraphLine) => selectedGraphLine !== graphLineValue));
    }
  }

  return (
    <section css={tw`mb-20`}>
      <FormControl
        width={Math.max(
          Math.min(100 + clientRows.length * 250, 250 * 4),
          250
        )}
      >
        <FormLabel>Add Graph</FormLabel>
        <Select<{ value: string; label: string }, true>
          isMulti
          defaultValue={brandIdsWithForecastData.map((clientId) => ({
            value: clientId, 
            label: clientRows.find((client) => client.clientId === clientId)?.clientName ?? ""
          }))}
          onChange={(selectedOptions) => {
            // console.log(selectedOptions);
            if (!selectedOptions) {
              return setSelectedClients([]);
            }
            setSelectedClients(selectedOptions.map((option) => option.value));
          }}
          options={clientRows
            .map((client) => ({
              value: client.clientId,
              label: client.clientName.includes(" - ") ? client.clientName.split(" - ")[1] : client.clientName
            }))}
        />
      </FormControl>
      <div css={tw`flex flex-col items-start mt-10`}>
        <div css={tw`flex flex-row flex-wrap justify-start items-start gap-x-6`}>
          <GraphFilter 
            label={`${yearInFocus - 1} (Completed + Cancelled)`}
            colour="#20BB03" // green
            graphLineFilterString={GraphLine.LastYear}
            selectedGraphLines={selectedGraphLines}
            onChange={handleToggleFilter}
          />
          <GraphFilter 
            label={`${yearInFocus} (Completed + Cancelled)`}
            colour="#F22102" // red
            graphLineFilterString={GraphLine.ThisYear}
            selectedGraphLines={selectedGraphLines}
            onChange={handleToggleFilter}
          />
          <GraphFilter 
            label={`${yearInFocus} (Completed + Cancelled + In Progress + On Hold)`}
            colour="#4EA6D2" // blue
            graphLineFilterString={GraphLine.ThisYearTotal}
            selectedGraphLines={selectedGraphLines}
            onChange={handleToggleFilter}
          />
          <GraphFilter
            label={`${yearInFocus} (Net sales forecast)`}
            colour="#D3D3D3" // grey
            graphLineFilterString={GraphLine.ThisYearForecast}
            selectedGraphLines={selectedGraphLines}
            onChange={handleToggleFilter}
          />
        </div>
        <div css={tw`grid grid-cols-3 gap-4`}>
          {selectedClients.map((clientId) => {
            const client = clientRows.find((client) => client.clientId === clientId);
            if (!client) {
              return null;
            }
            const clientName = client.clientName.includes(" - ") ? client.clientName.split(" - ")[1] : client.clientName;
            const brandForecastData = forecastsResultData.find((client) => client.clientId === clientId);
            if (!brandForecastData) {
              return null;
            }
            return (
              <div key={client.clientId}> 
                <ClientRevenueChart
                  yearInFocus={yearInFocus}
                  client={client}
                  clientName={clientName}
                  selectedGraphLines={selectedGraphLines}
                  brandForecastData={brandForecastData}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  )
}