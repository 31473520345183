import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../useAuth";

//const teamworkClientId = process.env.REACT_APP_TEAMWORK_CLIENT_ID;
const teamworkClientId = import.meta.env.VITE_TEAMWORK_CLIENT_ID;

export const LoginPage = () => {
  const { handleLoginCode } = useAuthContext();
  const location = useLocation();
  
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (!code) return;
    handleLoginCode(code);
  }, [location.search]);

  return (
    <Flex h="full" w="full" justifyContent="center" alignItems="center">
      <a
        href={`https://www.teamwork.com/launchpad/login?redirect_uri=${window.encodeURIComponent(
          `${window.location.origin}/handle_login`
        )}&client_id=${teamworkClientId}`}
      >
        Sign In
      </a>
    </Flex>
  );
};
