
import { Stack } from "@chakra-ui/react";
import _ from "lodash";
import { Navigate } from "react-router-dom";
import tw from "twin.macro";
import * as routes from "../../routes";
import { useAuthContext } from "../../useAuth";
import {
  useAdminGetAllRolesQuery,
  useAdminGetAllTeamsQuery
} from "../../codegen/graphql-types";
import Pods from "./Pods";
import RolesAndTeams from "./RolesAndTeams";
import UserRoleAndTeamAssignment from "./UserRoleAndTeamAssignment";
import { RevenueTargets } from "./RevenueTargets";

export const AdminPage = () => {
  const { isAdmin } = useAuthContext();
  // Get all roles and teams
  const {
    data: rolesData,
    loading: rolesLoading,
    refetch: refetchRoles,
  } = useAdminGetAllRolesQuery();
  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeams,
  } = useAdminGetAllTeamsQuery();

  if (!isAdmin) {
    return <Navigate to={routes.dashboardPage.getInstance()} />;
  }

  if (rolesLoading || teamsLoading) {
    return <div>Loading Roles & Teams...</div>;
  }

  if (!rolesData || !teamsData) {
    return <div>No data found</div>;
  }

  return (
    <Stack direction="column" css={tw`px-6 pt-6 gap-y-6`}>
      <Pods />
      <RolesAndTeams
        rolesData={rolesData}
        teamsData={teamsData}
        refetchRoles={refetchRoles}
        refetchTeams={refetchTeams}
      />
      <UserRoleAndTeamAssignment 
        rolesData={rolesData}
        teamsData={teamsData}
        refetchRoles={refetchRoles}
        refetchTeams={refetchTeams}
      />
      <RevenueTargets />
    </Stack>
  );
};
