import { 
  subWeeks, 
  subMonths,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format, 
  parseISO,
  isWithinInterval, 
  differenceInCalendarDays, 
  subDays,
  startOfYear,
} from 'date-fns';
import { DayOff, TimeEntry } from '../codegen/graphql-types';
import { FlexibleTimeEntry, FlexibleDayOff } from './Employee/TimeSummaryRow';
import { statutoryHolidays } from "../Projects/statutoryHolidays";

export type Holiday = {
  id: number,
  date: string, // e.g. "2021-07-01"
  nameEn: string,
  federal: number,
  observedDate: string, // e.g. "2021-07-01"
  internalOnly?: boolean,
}

export const holidaysForThisYear = function(year: number): Holiday[] {
  return statutoryHolidays
    .filter((holiday) => holiday.date.split("-")[0] === year.toString());
}

export const sumEntryMinutes = (total: number, entry: FlexibleTimeEntry) => {
  return total + entry.minutes;
}

export const sumTime = (entries: TimeEntry[]) => {
  const totalMinutes = entries.reduce(sumEntryMinutes, 0);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
};

export const weekAverageTimePerDay = (entries: TimeEntry[], numOfWorkingDays: number) => {
  const totalMinutes = entries.reduce(sumEntryMinutes, 0);
  const averageMinutes = totalMinutes / numOfWorkingDays;
  const averageHours = (averageMinutes / 60).toFixed(1);
  return averageHours;
}

export const isDateWithinRange = function(
  startDate: string,
  endDate: string,
  dateToCheck: string
): boolean {
  // ensure start date is at the beginning of the day and end date is at the end of the day
  const start = startOfDay(parseISO(startDate));
  const end = endOfDay(parseISO(endDate));
  const date = parseISO(dateToCheck);

  return isWithinInterval(date, { start, end });
}

export const convertToUTCTimeZone = function(date: Date): Date {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()))
}

export type DaysInRange = {
  totalDaysInRange: number;
  weekendDaysInRange: number;
  holidaysInRange: number;
  userDaysOffWithinInterval: FlexibleDayOff[];
  numberOfWorkingDaysInRange: number;
  description: string;
}

/**
 * Calculates the number of days in a date range, including the number of weekend days, holidays, and working days.
 * 
 * @param startDate - a JS Date object
 * @param endDate - a JS Date object
 * @param userDaysOff - An array of DayOff objects.
 * @returns An object containing the number of days in the range, the number of weekend days in the range, the number of holidays in the range, and the number of working days in the range.
 */
export const daysInDateRange = function(
  startDate: Date,
  endDate: Date,
  userDaysOff: FlexibleDayOff[]
): DaysInRange {
  // const start = parseISO(startDate);
  // const end = parseISO(endDate);
  const start = startDate;
  const end = endDate;
  const totalDaysInRange = differenceInCalendarDays(end, start) + 1; // +1 to include end date in the count
  const startYear = start.getUTCFullYear();
  const endYear = end.getUTCFullYear();
  const isStartAndEndInSameYear = startYear === endYear;
  const holidays: Date[] = [];
  const weekendDays: Date[] = [];

  // Parse just the month day and year in words from the start and end dates
  const startDateWords = format(start, 'MMM d, yyyy');
  const endDateWords = format(end, 'MMM d, yyyy');
  const dateRangeString = `Date range: ${startDateWords} to ${endDateWords}`;

  // Count the number of holidays in the range
  const holidaysForStartRange = holidaysForThisYear(start.getUTCFullYear());

  for (let i = 0; i < holidaysForStartRange.length; i++) {
    const holiday = holidaysForStartRange[i];
    const holidayDate = parseISO(holiday.observedDate);
    if (isWithinInterval(holidayDate, { start: startOfDay(start) , end: endOfDay(end) })) {
      holidays.push(holidayDate);
    }
  }

  // If the start and end dates are in different years, count the holidays for the end year as well
  if (!isStartAndEndInSameYear) {
    const holidaysForEndRange = holidaysForThisYear(end.getFullYear());
    for (let i = 0; i < holidaysForEndRange.length; i++) {
      const holiday = holidaysForEndRange[i];
      const holidayDate = parseISO(holiday.observedDate);
      if (isWithinInterval(holidayDate, { start: startOfDay(start) , end: endOfDay(end) })) {
        holidays.push(holidayDate);
      }
    }
  }

  // Count the number of weekendDays in the range.
  let date = new Date(start); // always create new date when using setDate() to avoid mutating the original date
  while (date <= end) {
    if (date.getUTCDay() === 0 || date.getUTCDay() === 6) { // Correctly checking for Sunday (0) and Saturday (6)
      weekendDays.push(new Date(date)); // Creating a new Date object for pushing to avoid mutating the original date
    }
    date = new Date(date.setDate(date.getUTCDate() + 1)); // increment the date
  }

  // filter in the userDaysOff for days in date interval 
  // and filter out if they are the same dates as the holidays or weekends
  // only compares the date portion of the date objects, does not include time or timezone

  const userDaysOffWithinInterval: FlexibleDayOff[] = userDaysOff
    .map(dayOff => {
      // split the date from the time
      const yyyymmdd: string = dayOff.date.split('T')[0];
      return {
        ...dayOff,
        date: parseISO(yyyymmdd)
      }
    })
    .filter(dayOff => isWithinInterval(dayOff.date, { start: startOfDay(start), end: endOfDay(end) }))
    .filter(dayOff => {
      return !weekendDays.some(weekendDate => 
        weekendDate.getUTCFullYear() === dayOff.date.getUTCFullYear() &&
        weekendDate.getUTCMonth() === dayOff.date.getUTCMonth() &&
        weekendDate.getUTCDate() === dayOff.date.getUTCDate())
    })
    .filter(dayOff => {
      return !holidays.some(holiday => 
        holiday.getUTCFullYear() === dayOff.date.getUTCFullYear() &&
        holiday.getUTCMonth() === dayOff.date.getUTCMonth() &&
        holiday.getUTCDate() === dayOff.date.getUTCDate())
    });

  const numberOfWorkingDaysInRange = totalDaysInRange - (holidays.length + weekendDays.length + userDaysOffWithinInterval.length);

  return {
    totalDaysInRange,
    weekendDaysInRange: weekendDays.length,
    holidaysInRange: holidays.length,
    userDaysOffWithinInterval: userDaysOffWithinInterval,
    numberOfWorkingDaysInRange,
    description: dateRangeString
  };
}

// dates are strings in the ISO 8601 format YYYY-MM-DDTHH:mm:ss.sssZ
type DateRange = {
  startDate: string;
  endDate: string;
};

export type DateRanges = {
  lastWeek: DateRange;
  lastFourWeeks: DateRange;
  lastThreeMonths: DateRange;
  yearToDate: DateRange;
};

// Returns an object with three date ranges: this week, last four weeks, and last three months
/**
 * @returns An object with four date ranges: last week, last four weeks, last three months, and year to date.
 * Each date range is an object with a start date and an end date in ISO 8601 format.
 * e.g. { lastWeek: { startDate: '2024-02-04T00:00:00.000Z', endDate: '2024-02-10T23:59:59.999Z' }, ... }
 */
export const getDateRanges = function(): DateRanges {
  const today = new Date();
  // Sunday February 11th, 2024
  // const today = new Date(2024, 1, 11);

  // Calculate the start and end of the current week
  const currentWeekStart = startOfWeek(today, { weekStartsOn: 1 });
  const currentWeekEnd = endOfWeek(today, { weekStartsOn: 1 });

  // Last week range - subtract one week from the current week's start and end
  const startOfLastWeek = startOfDay(subWeeks(currentWeekStart, 1));
  const endOfLastWeek = endOfDay(subWeeks(currentWeekEnd, 1));

  // Yesterday - used as the end date for the last four weeks and last three months ranges
  const yesterday = endOfDay(subDays(today, 1));
  // console.log('yesterday', yesterday);
  // Last four weeks range - subtract four weeks from yesterday
  const startOfLastFourWeeks = startOfDay(subWeeks(yesterday, 4));

  // Last three months range - subtract three months from yesterday
  const startOfLastThreeMonths = startOfDay(subMonths(yesterday, 3));

  // return in format of type Date
  return {
    lastWeek: {
      startDate: startOfLastWeek.toISOString(),
      endDate: endOfLastWeek.toISOString()
    },
    lastFourWeeks: {
      startDate: startOfLastFourWeeks.toISOString(),
      endDate: yesterday.toISOString()
    },
    lastThreeMonths: {
      startDate: startOfLastThreeMonths.toISOString(),
      endDate: yesterday.toISOString()
    },
    yearToDate: {
      startDate: startOfYear(today).toISOString(),
      endDate: yesterday.toISOString()
    }
  }
}

