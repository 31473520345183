import { useMemo } from 'react';
import { getDateRanges } from './dates';

// Function to get the current day in YYYY-MM-DD format
const getCurrentDay = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // Returns date in 'YYYY-MM-DD' format
};

export const useDateRanges = () => {
  const currentDay = getCurrentDay();

  return useMemo(() => {
    // getDateRanges logic here
    return getDateRanges();
  }, [currentDay]); // Recompute only if the current day changes
};
