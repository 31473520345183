import tw from "twin.macro";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Input } from "@chakra-ui/react";
import {
  ProjectedExpense,
  PeopleDataQuery
} from "../../codegen/graphql-types";
import {
  payeesByExpenseType,
  expenseTypes,
} from "../../Projects/Expenses/CreateExpensePage";

export type BriefProjectedExpense = Pick<
  ProjectedExpense,
  | "project_id"
  | "project_pricing_id"
  | "expense_type"
  | "payee_name"
  | "budget_in_cents"
> & {
  id?: number;
};

const RequiredAsterisk = () => <span css={tw`text-red-700`}>*</span>;

const SelectExpenseType = (props: {
  expense: BriefProjectedExpense;
  setExpense: (expense: BriefProjectedExpense) => void;
  isNewExpense?: boolean;
}) => {
  const { expense, setExpense, isNewExpense } = props;
  return (
    <div>
      <label htmlFor="expenseType" css={tw`block mb-1 font-medium`}>
        Expense Type
        <RequiredAsterisk />
      </label>
      <Select
        inputId="expenseType"
        isDisabled={isNewExpense ? false : Boolean(!expense.id)}
        isMulti={false}
        closeMenuOnSelect={true}
        value={
          expense.expense_type
            ? {
                value: expense.expense_type,
                label: expense.expense_type,
              }
            : null
        }
        onChange={(selectedOption) => {
          if (!selectedOption) return;
          setExpense({
            ...expense,
            expense_type: selectedOption.value,
            payee_name: "",
          });
        }}
        options={expenseTypes.map((expenseType) => ({
          value: expenseType,
          label: expenseType,
        }))}
      />
    </div>
  );
};

const SelectPayeeName = (props: {
  expense: BriefProjectedExpense;
  setExpense: (expense: BriefProjectedExpense) => void;
  peopleData: PeopleDataQuery["people"] | undefined;
  isNewExpense?: boolean;
}) => {
  const { expense, setExpense, peopleData, isNewExpense } = props;

  const expenseTypeKey = expense.expense_type as keyof typeof payeesByExpenseType;
  const payeeOptions =
    !expense.expense_type || !peopleData
      ? []
      : expense.expense_type === "Subcontractor"
      ? peopleData.flatMap((person) => {
          return {
            value: person,
            label: `${person.first_name} ${person.last_name}`,
          };
        })
      : payeesByExpenseType[expenseTypeKey].map((name) => ({
          value: name,
          label: name,
        }));
  return (
    <div>
      <label htmlFor="payeeName" css={tw`block mb-1 font-medium`}>
        Payee Name <RequiredAsterisk />
      </label>
      <CreatableSelect
        isDisabled={isNewExpense ? false : !expense.id || !expense.expense_type}
        isMulti={false}
        closeMenuOnSelect={true}
        value={
          expense.payee_name
            ? {
                value: expense.payee_name,
                label: expense.payee_name,
              }
            : null
        }
        onChange={(selectedOption) => {
          const selectedOptionValue = selectedOption?.value;
          if (
            typeof selectedOptionValue === "object" &&
            "first_name" in selectedOptionValue
          ) {
            setExpense({
              ...expense,
              // @ts-ignore
              payee_name: `${selectedOptionValue.first_name} ${selectedOptionValue.last_name}`,
            });
          } else {
            setExpense({
              ...expense,
              payee_name: selectedOptionValue ?? "",
            });
          }
        }}
        // @ts-ignore
        options={payeeOptions}
      />
    </div>
  );
};

const BudgetInput = (props: {
  expense: BriefProjectedExpense;
  setExpense: (expense: BriefProjectedExpense) => void;
}) => {
  const { expense, setExpense } = props;
  return (
    <div>
      <label htmlFor="budgetAmount" css={tw`block mb-1 font-medium`}>
        Budget Amount
      </label>
      <Input
        id="budgetAmount"
        disabled={!expense.expense_type || !expense.payee_name}
        type="number"
        placeholder="Budget Amount"
        value={expense.budget_in_cents}
        onFocus={(e) => e.target.select()}
        onChange={(e) =>
          setExpense({
            ...expense,
            budget_in_cents: Number(e.target.value),
          })
        }
        css={tw`border`}
      />
    </div>
  );
};

export { SelectExpenseType, SelectPayeeName, BudgetInput };
