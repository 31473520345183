import tw from "twin.macro";
import {
  RiBold,
  RiItalic,
  RiUnderline,
  RiStrikethrough,
  RiFontColor,
  RiListOrdered,
  RiListUnordered,
  RiLink,
  RiPaletteLine,
} from "react-icons/ri";
import { Icon, IconButton } from "@chakra-ui/react";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { EditorContent, useEditor } from "@tiptap/react";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import { useCallback, useEffect, useRef } from "react";
import { BlockPicker } from "react-color";
import { Tooltip, PlacesType } from "react-tooltip";

interface TiptapEditorProps {
  id: string;
  value: string;
  setValue: (value: string) => void;
  onSave?: (value: string) => void;
  edit: boolean;
  setEdit: (value: boolean) => void;
  placeholder?: string;
  disabled?: boolean;
}

const TiptapEditor = ({
  id,
  disabled,
  value,
  setValue,
  onSave,
  edit,
  setEdit
}: TiptapEditorProps) => {
  const targetElementRef = useRef<HTMLDivElement | null>(null);
  const COLOR_PICKER_COLORS = [
    "#ff0000",
    "#ffff33",
    "#02f900",
    "#439ae1",
    "#e2e8f0",
  ];
  const fontColorTooltipRef = useRef<HTMLDivElement>(null);
  const fontHighlightTooltipReg = useRef<HTMLDivElement>(null);

  const normalOnUpdate = useCallback(({ editor }: any) => {
    setValue(editor.getHTML());
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight.configure({ multicolor: true }),
      Color,
      TextStyle,
      Link.configure({
        openOnClick: false,
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      })
    ],
    content: value,
    onUpdate: normalOnUpdate,
    editable: !disabled,
    editorProps: {
      attributes: {
        class: "prose",
        style: "min-height: 100px; padding: 0.5rem;",
      },
    }
  });

  const handleColorChange = useCallback(
    (color: any) => {
      editor?.chain().focus().setColor(color.hex).run();
      // Close the color picker if needed
      (fontColorTooltipRef.current as any)?.close();
    },
    [editor]
  );

  const onLinkUpdate = useCallback(() => {
    if (!window) {
      return;
    }
    const previousUrl = editor?.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({
        href: url,
        target: "_blank",
        class: "external-link",
      })
      .run();
  }, [editor]);

  // TODO?: Detect if user clicked outside

  // Attach an event listener for keyboard event of shift+return OR option+return
  // Ensure to use the id of this editor so the event listener is unique
  useEffect(() => {
    const handleSave = (e: KeyboardEvent) => {
      if (
        (e.shiftKey && e.key === "Enter") ||
        (e.metaKey && e.key === "Enter") ||
        (e.ctrlKey && e.key === "Enter")
      ) {
        onSave?.(value); 
      }
    };

    document.addEventListener("keydown", handleSave);

    return () => {
      document.removeEventListener("keydown", handleSave);
    };
  }, [setEdit]);

  useEffect(() => {
    // Make sure the editor content state is in sync with the value (parent's state)
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <div
      css={tw`mt-2`}
      onDoubleClick={() => setEdit(true)}
      ref={targetElementRef}
    >
      {edit ? (
        <div id={id}>
          {/* Tool Bar */}
          <div css={tw`flex gap-x-1`}>
            
            <IconButton
              aria-label="Bold"
              size="sm"
              icon={<Icon as={RiBold} />}
              onClick={() => editor?.chain().focus().toggleBold().run()}
              isActive={editor?.isActive("bold")}
            />
            
            <IconButton 
              aria-label="Italic"
              size="sm"
              icon={<Icon as={RiItalic} />}
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              isActive={editor?.isActive("italic")}
            />
            
            <IconButton 
              aria-label="Underline"
              size="sm"
              icon={<Icon as={RiUnderline} />}
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
              isActive={editor?.isActive("underline")}
            />

            <IconButton
              aria-label="Strikethrough"
              size="sm"
              icon={<Icon as={RiStrikethrough} />}
              onClick={() => editor?.chain().focus().toggleStrike().run()}
              isActive={editor?.isActive("strike")}
            />

            <IconButton
              id={`tiptap-menu-font-color-${id}`}
              aria-label="Font Color"
              size="sm"
              icon={<Icon as={RiFontColor} />}
              onClick={() => {
                (fontColorTooltipRef.current as any)?.open();
              }}
              isActive={editor?.isActive("color")}
            />

            <Tooltip
              ref={fontColorTooltipRef as any}
              anchorSelect={`#tiptap-menu-font-color-${id}`}
              clickable
              openOnClick
              className={`z-50`}
              place={`bottom-right` as PlacesType}
              opacity={1}
              style={{ backgroundColor: "white" }}
            >
              {/* @ts-ignore */}
              <BlockPicker
                className={``}
                colors={COLOR_PICKER_COLORS}
                onChange={handleColorChange}
                onChangeComplete={handleColorChange}
              />
              <button
                onClick={() => {
                  editor?.chain().focus().unsetColor().run();
                  (fontColorTooltipRef.current as any)?.close();
                }}
                className={`text-black my-2 text-xs w-full text-center`}
              >
                Clear
              </button>
            </Tooltip>

            <IconButton
              id={`tiptap-menu-font-highlight-${id}`}
              size="sm"
              aria-label="Highlight"
              icon={<Icon as={RiPaletteLine} />}
              onClick={() => {
                (fontHighlightTooltipReg.current as any)?.open();
              }}
              isActive={editor?.isActive("highlight")}
            />

            <Tooltip
              ref={fontHighlightTooltipReg as any}
              anchorSelect={`#tiptap-menu-font-highlight-${id}`}
              clickable
              openOnClick
              className={`z-50`}
              place={`bottom-right` as PlacesType}
              opacity={1}
              style={{ backgroundColor: "white" }}
            > 
              {/* @ts-ignore */}
              <BlockPicker
                className={``}
                colors={COLOR_PICKER_COLORS}
                onChange={(color) => {
                  editor
                    ?.chain()
                    .focus()
                    .setHighlight({ color: color.hex })
                    .run();
                  (fontHighlightTooltipReg.current as any)?.close();
                }}
              />
              <button
                onClick={() => {
                  editor?.chain().focus().unsetHighlight().run();
                  (fontHighlightTooltipReg.current as any)?.close();
                }}
                className={`text-black my-2 text-xs w-full text-center`}
              >
                Clear
              </button>
            </Tooltip>

            <IconButton
              aria-label="Ordered List"
              size="sm"
              icon={<Icon as={RiListOrdered} />}
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              isActive={editor?.isActive("orderedList")}
            />
            
            <IconButton 
              aria-label="Bullet List"
              size="sm"
              icon={<Icon as={RiListUnordered} />}
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              isActive={editor?.isActive("bulletList")}
            />

            <IconButton
              aria-label="Link"
              size="sm"
              icon={<Icon as={RiLink} />}
              onClick={onLinkUpdate}
            />
            
            <div title="Close">
              <IconButton
                aria-label="Close"
                size="sm"
                icon={<CloseIcon />}
                onClick={() => setEdit(false)}
              />
            </div>
          </div>

          {/* Editor Content */}
          <EditorContent 
            editor={editor} 
            css={tw`border p-1 my-1 rounded-md focus:shadow-lg bg-white min-h-20`} 
          />
        </div>
      ) : (
        <section css={tw`w-full flex justify-end items-center gap-x-3`}>
          <span css={tw`italic text-sm`}>New comment</span>
          <IconButton
            size="sm"
            colorScheme={"blue"}
            aria-label='Write new comment'
            icon={<EditIcon />}
            onClick={() => setEdit(true)}
          />
        </section>
      )}
    </div>
  );
};

export default TiptapEditor;