
import tw, { css } from "twin.macro";
import { useState } from "react";
import { DateTime } from "luxon";

const MonthYearPicker = ({ onChange, selectedDate }: {
  onChange: (date: DateTime) => void;
  selectedDate: DateTime;
}) => {
  const [date, setDate] = useState(selectedDate);

  const handleMonthChange = (e: any) => {
    const newDate = date.set({ month: parseInt(e.target.value) });
    setDate(newDate);
    onChange(newDate);
  };

  const handleYearChange = (e: any) => {
    const newDate = date.set({ year: parseInt(e.target.value) });
    setDate(newDate);
    onChange(newDate);
  };

  const monthOptions = Array.from({ length: 12 }, (_, i) => (
    <option key={i + 1} value={i + 1}>
      {DateTime.local(2022, i + 1).toFormat("MMMM")}
    </option>
  ));

  const yearOptions = Array.from({ length: 10 }, (_, i) => (
    <option key={date.year - 5 + i} value={date.year - 5 + i}>
      {date.year - 5 + i}
    </option>
  ));

  return (
    <div css={[tw`flex space-x-4`, css`
      @media print {
        ${tw`hidden`}
      }
    `]}>
      <select
        value={date.month}
        onChange={handleMonthChange}
        css={tw`p-2 border rounded`}
      >
        {monthOptions}
      </select>
      <select
        value={date.year}
        onChange={handleYearChange}
        css={tw`p-2 border rounded`}
      >
        {yearOptions}
      </select>
    </div>
  );
};

export default MonthYearPicker;