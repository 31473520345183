
import _ from "lodash";
import tw from "twin.macro";
import React, { useEffect, useRef, useState } from "react";
import { PersonTimeAnalysis } from "./TeamTimeTargets";
import { 
  Maybe,
  PersonAnnualTimeTarget, 
  useUpdatePersonAnnualTimeTargetMutation 
} from "../../codegen/graphql-types";
import { CellContext } from "@tanstack/react-table";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { minutesToHours, hoursToMinutes} from "../../helpers/time";

const TimeTargetInput = ({ target, setTarget, inputName }: { target: number, setTarget: (value: number) => void, inputName: string }) => {
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setTarget(hoursToMinutes(value));
  }

  // Automatically select text in the input on render
  const inputRef = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (inputName === "billableMinutes") {
      inputRef.current?.select();
    }
  }, [inputName]);

  const nicerLabels: Record<string, string | JSX.Element> = {
    billableMinutes: "Billable",
    salesAndMarketingMinutes: "Sales & Marketing",
    innovationAndDevMinutes: "Innovation & Development",
    operationalMinutes: <><br/>Operational</>,
    professionalDevMinutes: "Professional Development",
    nonBillableMinutes: "Internal Meetings & Administrative Tasks",
  }

  return (
    <label css={tw`relative`}>
      {nicerLabels[inputName]}
      <input
        ref={inputRef}
        css={[tw`p-1 rounded-md border`]}
        step={0.5}
        onWheel={(e) => e.currentTarget.blur()}
        name={inputName}
        type="number"
        placeholder="Hours"
        autoFocus={inputName === "billableMinutes"}
        value={minutesToHours(target)}
        onChange={onChange}
      />
    </label>
  )
}

type FlexiblePersonAnnualTimeTarget = Pick<PersonAnnualTimeTarget, 'billableMinutes' | 'nonBillableMinutes' | 'operationalMinutes' | 'professionalDevMinutes' | 'innovationAndDevMinutes' | 'salesAndMarketingMinutes'>;

type PersonTimeTargetProps = {
  info: CellContext<PersonTimeAnalysis, any>,
  yearInFocus: number,
  updateTimeDataState: (updatedPerson: PersonTimeAnalysis) => void,
}

export const PersonTimeTarget = ({
  info,
  yearInFocus,
  updateTimeDataState,
}: PersonTimeTargetProps) => {
  const personId = info.row.original.id;
  const toast = useToast();
  const [updatePersonAnnualTimeTarget] = useUpdatePersonAnnualTimeTargetMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validateError, setValidateError] = useState<string | null>(null);
  const { utilizedTime, nonUtilizedTime } = info.row.original; // these values are in hours already
  const initialTargets = {
    billableMinutes: hoursToMinutes(utilizedTime.billable.target),
    salesAndMarketingMinutes: hoursToMinutes(utilizedTime.sales_marketing.target),
    innovationAndDevMinutes: hoursToMinutes(utilizedTime.innovation_development.target),
    operationalMinutes: hoursToMinutes(utilizedTime.operational.target),
    professionalDevMinutes: hoursToMinutes(utilizedTime.professional_development.target),
    nonBillableMinutes: hoursToMinutes(nonUtilizedTime.internal_administrative.target),
  }
  const [targets, setTargets] = useState<FlexiblePersonAnnualTimeTarget>(initialTargets);

  // Track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      // Cleanup function
      isMounted.current = false;
    };
  }, []);

  const onCancel = () => {
    setIsOpen(false);
    setValidateError(null);
    setTargets(initialTargets);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const inputs = Array.from(form.getElementsByTagName("input"));

    // loop through each input and check if it's a number
    // if not, set the validate error and return
    inputs.forEach(input => {
      if (isNaN(Number(input.value))) {
        setValidateError("Please enter a number value for each input");
        return;
      }
    });

    const targetValues = inputs.reduce((acc, input) => {
      const key = input.name;
      const value = Number(input.value);
      return { ...acc, [key]: hoursToMinutes(value) };
    }, {} as FlexiblePersonAnnualTimeTarget);
    
    // update
    const variables = {
      personId,
      year: yearInFocus,
      ...targetValues
    };
    // update the db with the new time targets
    setIsLoading(true);
    await updatePersonAnnualTimeTarget({
      variables: variables,
      onError: (error) => {
        if (!isMounted.current) return;
        console.error(error);
        toast({
          title: "An error occurred.",
          description: "There was an error updating the time targets.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      },
      onCompleted: () => {
        // This function only runs if the mutation is successful
        if (!isMounted.current) return;
        // update timeData state
        const newTotalTargetMinutes = _.sum(Object.values(targetValues));
        const newPercentOfTarget = (newTargetMinutes: Maybe<number> | undefined, actualHours: number): number => {
          return typeof newTargetMinutes === 'number' && newTargetMinutes > 0 ? Math.ceil(actualHours / minutesToHours(newTargetMinutes) * 100) : 0
        };
        const newTarget = (targetMinutes: Maybe<number> | undefined): number => typeof targetMinutes === 'number' ? minutesToHours(targetMinutes) : 0;
        // Re-calculating the new values for the table row
        updateTimeDataState({
          ...info.row.original,
          totalHours: {
            ...info.row.original.totalHours,
            target: minutesToHours(_.sum(Object.values(targetValues))),
            percent_of_target: newPercentOfTarget(newTotalTargetMinutes, info.row.original.totalHours.actual_ytd)
          },
          utilizedTime: {
            ...info.row.original.utilizedTime,
            billable: {
              ...info.row.original.utilizedTime.billable,
              target: newTarget(targetValues.billableMinutes),
              percent_of_target: newPercentOfTarget(targetValues.billableMinutes, info.row.original.utilizedTime.billable.actual_ytd)
            },
            sales_marketing: {
              ...info.row.original.utilizedTime.sales_marketing,
              target: newTarget(targetValues.salesAndMarketingMinutes),
              percent_of_target: newPercentOfTarget(targetValues.salesAndMarketingMinutes, info.row.original.utilizedTime.sales_marketing.actual_ytd)
            },
            innovation_development: {
              ...info.row.original.utilizedTime.innovation_development,
              target: newTarget(targetValues.innovationAndDevMinutes),
              percent_of_target: newPercentOfTarget(targetValues.innovationAndDevMinutes, info.row.original.utilizedTime.innovation_development.actual_ytd)
            },
            operational: {
              ...info.row.original.utilizedTime.operational,
              target: newTarget(targetValues.operationalMinutes),
              percent_of_target: newPercentOfTarget(targetValues.operationalMinutes, info.row.original.utilizedTime.operational.actual_ytd)
            },
            professional_development: {
              ...info.row.original.utilizedTime.professional_development,
              target: newTarget(targetValues.professionalDevMinutes),
              percent_of_target: newPercentOfTarget(targetValues.professionalDevMinutes, info.row.original.utilizedTime.professional_development.actual_ytd)
            }
          },
          nonUtilizedTime: {
            ...info.row.original.nonUtilizedTime,
            internal_administrative: {
              ...info.row.original.nonUtilizedTime.internal_administrative,
              target: newTarget(targetValues.nonBillableMinutes),
              percent_of_target: newPercentOfTarget(targetValues.nonBillableMinutes, info.row.original.nonUtilizedTime.internal_administrative.actual_ytd)
            }
          }
        });
      }
    });
    if (isMounted.current) {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  const modal = (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit}>
        <ModalHeader>Update Targets for {info.row.original.name}</ModalHeader>
        <ModalBody pb={6}>
          <div css={tw`w-full flex flex-col gap-y-3`}>
            <strong>Targets:</strong>
            <section css={tw`w-full grid grid-cols-2 gap-6`}>
              {Object.keys(targets).map((targetKey: string) => (
                  <TimeTargetInput
                    // @ts-ignore
                    key={targetKey}
                    target={targets[targetKey as keyof FlexiblePersonAnnualTimeTarget] ?? 0}
                    setTarget={(value) => setTargets({ ...targets, [targetKey]: value })}
                    inputName={targetKey}
                  />
                )
              )}
            </section>
            <strong>Total Target Hours Per Day: {minutesToHours(_.sum(Object.values(targets)))}</strong>
          </div>
          {validateError && <div css={tw`text-red-500`}>{validateError}</div>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <>
      {isLoading && <div css={tw`text-white`}>Saving...</div>}
      {!isLoading && (
        <button 
          css={tw`w-full flex items-center justify-between gap-x-2 px-1`} 
          onClick={() => setIsOpen(true)}>
            {info.getValue()} <EditIcon w="3" h="3" />
        </button>
      )}
      {modal}
    </>
  )
};