export const abbreviateClientName = (clientName: string, characterLimit: number) => {
  const isLong = clientName.length > characterLimit;

  if (clientName === "Janssen - INVEGA TRINZA®/ INVEGA SUSTENNA®") {
    return "PLAT";
  }

  // If the name includes " - " then we want to split it and only use the 2nd part
  if (clientName.includes(" - ")) {
    const nameAfterDash = clientName.split(" - ")[1];
    // if the name contains a pair of parentheses, we want to remove them
    if (clientName.includes("(") && clientName.includes(")")) {
      return nameAfterDash.split(" (")[0];
    }

    return nameAfterDash;
  }

  return isLong ? clientName.slice(0, characterLimit) : clientName;
};