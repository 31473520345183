import { useState } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Box,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import _ from "lodash";
import tw from "twin.macro";
import {
  useAdminCreateRoleMutation,
  useAdminCreateTeamMutation,
  useAdminUpdateRoleMutation,
  useAdminUpdateTeamMutation,
  // useAdminDeleteRoleMutation,
  // useAdminDeleteTeamMutation,
  AdminGetAllRolesQuery,
  AdminGetAllTeamsQuery,
} from "../../codegen/graphql-types";
import { PopoverInput } from "../../helpers/PopoverInput";

const RolesAndTeams = (props: {
  rolesData: AdminGetAllRolesQuery;
  teamsData: AdminGetAllTeamsQuery;
  refetchRoles: () => void;
  refetchTeams: () => void;
}) => {
  const [addNewRole, setAddNewRole] = useState(false);
  const [addNewTeam, setAddNewTeam] = useState(false);
  
  // Create
  const [createRole] = useAdminCreateRoleMutation();
  const [createTeam] = useAdminCreateTeamMutation();
  // Update
  const [updateRole] = useAdminUpdateRoleMutation();
  const [updateTeam] = useAdminUpdateTeamMutation();
  // Delete
  // const [deleteRole] = useAdminDeleteRoleMutation();
  // const [deleteTeam] = useAdminDeleteTeamMutation();

  const OPTIMISTIC_TEMP_PREFIX = "@@TEMP_ID@@"; // Prefix for optimistic temp IDs

  return (
    <>
      <Heading size="lg" color="#e535ab">
        Roles & Teams
      </Heading>
      <Stack direction="row" css={tw`gap-10 flex-wrap px-6 py-2`}>
        {/* Roles */}
        <Stack direction="column" css={tw`w-1/3`}>
          <Heading size="md">Roles</Heading>
          {props.rolesData && props.rolesData.roles
            ? props.rolesData.roles.map((role) => {
                if (!role) return null;
                return (
                  <Stack
                    direction="row"
                    key={role.id}
                    css={tw`flex items-center w-40 justify-between gap-4`}
                  >
                    <PopoverInput
                      initialValue={role.roleTitle}
                      onSubmit={(_, newValue, { onClose }) => {
                        updateRole({
                          variables: { id: role.id, roleTitle: newValue },
                          onCompleted: () => {
                            props.refetchRoles();
                          },
                        });
                        onClose();
                      }}
                    />
                    {/* <Tooltip label="Delete Role">
                      <Button
                        size="xs"
                        onClick={() => {
                          deleteRole({
                            variables: { id: role.id },
                            onCompleted: () => props.refetchRoles(),
                          });
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip> */}
                  </Stack>
                );
              })
            : null}
          {!addNewRole && (
            <Button size="xs" width="100px" onClick={() => setAddNewRole(true)}>
              Add New Role
            </Button>
          )}
          {addNewRole && (
            <Box css={tw`w-1/2 border rounded-md p-4`}>
              {/* Use the native form element for form submission */}
              <form
                css={tw`flex flex-col gap-y-2`}
                id="roleTitleForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target as HTMLFormElement; // Cast the target to HTMLFormElement type
                  const newRoleTitle = (form.elements.namedItem(
                    "roleTitle"
                  ) as HTMLInputElement).value;
                  createRole({
                    variables: { roleTitle: newRoleTitle },
                    optimisticResponse: {
                      __typename: "Mutation",
                      createRole: {
                        __typename: "Role",
                        id: `${OPTIMISTIC_TEMP_PREFIX}:${newRoleTitle}`,
                        roleTitle: newRoleTitle,
                      },
                    },
                    onCompleted: () => {
                      props.refetchRoles();
                    },
                  });
                  setAddNewRole(false); // Close the form after submission
                }}
              >
                <FormControl size="xs" id="roleTitle">
                  <FormLabel>Add New Role</FormLabel>
                  <Input
                    size="xs"
                    required
                    name="roleTitle"
                    id="roleTitle"
                    aria-label="Role Title"
                  />
                </FormControl>
                <div css={tw`flex justify-between`}>
                  <Button size="xs" onClick={() => setAddNewRole(false)}>
                    Cancel
                  </Button>
                  <Button size="xs" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Box>
          )}
        </Stack>
        {/* Teams */}
        <Stack direction="column" css={tw`w-1/3`}>
          <Heading size="md">Teams</Heading>
          {props.teamsData && props.teamsData.teams
            ? props.teamsData.teams.map((team) => {
                if (!team) return null;
                return (
                  <Stack
                    direction="row"
                    key={team.id}
                    css={tw`flex items-center w-40 justify-between gap-4`}
                  >
                    <PopoverInput
                      initialValue={team.teamName}
                      onSubmit={(_, newValue, { onClose }) => {
                        updateTeam({
                          variables: { id: team.id, teamName: newValue },
                          onCompleted: () => {
                            props.refetchTeams();
                          },
                        });
                        onClose();
                      }}
                    />
                    {/* <Tooltip label="Delete Team">
                      <Button
                        size="xs"
                        onClick={() => {
                          deleteTeam({
                            variables: { id: team.id },
                            onCompleted: () => props.refetchTeams(),
                          });
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Tooltip> */}
                  </Stack>
                );
              })
            : null}
          {!addNewTeam && (
            <Button size="xs" width="100px" onClick={() => setAddNewTeam(true)}>
              Add New Team
            </Button>
          )}
          {addNewTeam && (
            <Box css={tw`w-1/2 border rounded-md p-4`}>
              {/* Use the native form element for form submission */}
              <form
                css={tw`flex flex-col gap-y-2`}
                id="teamNameForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  const form = e.target as HTMLFormElement; // Cast the target to HTMLFormElement type
                  const newTeamName = (form.elements.namedItem(
                    "teamName"
                  ) as HTMLInputElement).value;
                  createTeam({
                    variables: { teamName: newTeamName },
                    optimisticResponse: {
                      __typename: "Mutation",
                      createTeam: {
                        __typename: "Team",
                        id: `${OPTIMISTIC_TEMP_PREFIX}:${newTeamName}`,
                        teamName: newTeamName,
                      },
                    },
                    onCompleted: () => {
                      props.refetchTeams();
                    },
                  });
                }}
              >
                <FormControl size="xs" id="teamName">
                  <FormLabel>Add New Team</FormLabel>
                  <Input
                    size="xs"
                    required
                    name="teamName"
                    id="teamName"
                    aria-label="Team Name"
                  />
                </FormControl>
                <div css={tw`flex justify-between`}>
                  <Button size="xs" onClick={() => setAddNewTeam(false)}>
                    Cancel
                  </Button>
                  <Button size="xs" type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default RolesAndTeams;
