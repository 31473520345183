import { GlobalStyles } from "twin.macro";
import { ApolloClientProvider } from "./ApolloClientProvider";
import "./App.css";
import { AppLoggedIn } from "./AppLoggedIn";
import { LoginPage } from "./LoginPage/LoginPage";
import { useAuthContext } from "./useAuth";
import { Route, Routes } from "react-router-dom";
import * as routes from "./routes";
import { EndUserLicenseAgreement, PrivacyPolicy } from "./PublicPages";

function App() {
  const { accessToken, adminUserOverride } = useAuthContext();

  if (!accessToken) {
    return (
      <Routes> {/* Use Routes instead of Switch */}
        <Route path={routes.eula.definition} element={<EndUserLicenseAgreement />} />
        <Route path={routes.privacy.definition} element={<PrivacyPolicy />} />
        <Route path="/handle_login" element={<LoginPage />} />
        <Route path="/" element={<LoginPage />} />
      </Routes>
    );
  }

  return (
    <>
      <GlobalStyles />
      <ApolloClientProvider
        accessToken={accessToken}
        adminUserOverride={adminUserOverride}
      >
        <AppLoggedIn />
      </ApolloClientProvider>
    </>
  );
}

export default App;
