
import React from "react";
import Select from "react-select";
import { UseFiltersColumnProps } from "react-table";

export function PopulatedOrNotSelectColumnFilter({
  column: { setFilter, preFilteredRows, id },
}: {
  column: UseFiltersColumnProps<Record<string, unknown>> & { id: string };
}) {
  const options = React.useMemo(() => {
    return [
      { value: "all", label: "All" },
      {
        value: "populated",
        label: `Populated (${
          preFilteredRows.filter((row) => row.values[id]?.length).length
        })`,
      },
      {
        value: "blank",
        label: `Blank (${
          preFilteredRows.filter((row) => !row.values[id]?.length).length
        })`,
      },
    ];
  }, [id, preFilteredRows]);

  return (
    <Select
      closeMenuOnSelect={true}
      defaultValue={{ value: "", label: "All" }}
      styles={{
        multiValue: (base) => {
          return { ...base, minWidth: "8rem" };
        },
      }}
      onChange={(selectedOption) => {
        setFilter(selectedOption?.value);
      }}
      options={options}
    />
  );
}
