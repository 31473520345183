import { useMemo, useContext } from "react";
import tw, { css } from "twin.macro";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ProjectedExpense } from "../../codegen/graphql-types";
import { Cogs, ProjectExpenseTypes } from "../../helpers/usePricing";
import { PricingContext } from "../../helpers/usePricing";
import NewProjectedExpenseForm from "./NewProjectedExpenseForm";
import MutateProjectedExpense from "./MutateProjectedExpense";
import { 
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { BriefProjectInvoice } from "../../helpers/usePricingData";

type COGSTableColumns = {
  id?: number;
  expense_type: string;
  payee_name: string;
  budgeted_amount: number;
  actual_cost: number;
  remaining: number;
};

// TODO: Add mutations for Creating, Updating, and Deleting ProjectedExpenses (rows in the table)
// Right now, Rows in the table populate when there are actual project invoices in the database (grouped by expense type and payee name)
// But we need to be able to add rows to the table for projected expenses that are not yet actualized (i.e. not yet invoiced)

// We may need to add a static column for "Actions" that will have buttons for "Create", "Edit" and "Delete" for each row
const COGSTable = ({
  cogs,
  projectedExpenses,
  projectId,
  projectPricingId,
}: {
  cogs: Cogs;
  projectedExpenses: ProjectedExpense[];
  projectId: string;
  projectPricingId: number;
}) => {
  // The COGS Table will have a dynamic number of rows based on the number of expenses
  // The columns will be static, rows will be dynamic

  // Each object returned in the data array will have a property for each column
  const data = useMemo(() => {
    if (projectedExpenses.length === 0) {
      return [];
    }
    const expensesWithABudget = projectedExpenses.map(
      (expense: ProjectedExpense) => {
        const expenseType = expense.expense_type;
        const payeeName = expense.payee_name;
        // Match the key format used in cogs.expenses to get the actual cost from the cogs object
        const expenseKey = `${expenseType} - ${payeeName}`;
        const cogsForExpense = cogs.expenses[expenseKey];
        if (!cogsForExpense) {
          // If there are no actual costs (invoices) for this budgeted expense, it could mean that the expense has not been actualized (invoiced) yet
          return {
            id: expense.id, // expenses with a budget have a ProjectedExpense id
            expense_type: expenseType,
            payee_name: payeeName,
            budgeted_amount: expense.budget_in_cents / 100,
            // Actual cost is 0 because there are no actual costs yet
            actual_cost: 0,
            remaining: expense.budget_in_cents / 100,
          };
        }
        const expenseRemainingTotal =
          expense.budget_in_cents - cogsForExpense.expense_actual_total;
        return {
          id: expense.id, // expenses with a budget have a ProjectedExpense id
          expense_type: expenseType,
          payee_name: payeeName,
          budgeted_amount: expense.budget_in_cents / 100,
          actual_cost: cogsForExpense.expense_actual_total / 100,
          remaining: expenseRemainingTotal / 100,
        };
      }
    );

    // Expenses invoiced that did not have a budgeted amount
    const expensesWithoutABudget = Object.keys(cogs.expenses)
      .filter((expenseKey: string) => {
        const expense = projectedExpenses.find((projectedExpense) => {
          const expenseType = projectedExpense.expense_type;
          const payeeName = projectedExpense.payee_name;
          return expenseKey === `${expenseType} - ${payeeName}`;
        });
        // If the expense is not found in the projected expenses, it means it was invoiced but not budgeted
        return !expense;
      })
      .map((expenseKey: string) => {
        const [expenseType, payeeName] = expenseKey.split(" - ");
        const cogsForExpense = cogs.expenses[expenseKey];
        return {
          expense_type: expenseType,
          payee_name: payeeName,
          budgeted_amount: 0,
          actual_cost: cogsForExpense.expense_actual_total / 100,
          remaining: 0 - cogsForExpense.expense_actual_total / 100,
        };
      });

    const total_bugdeted_amount = expensesWithABudget.reduce(
      (acc, expense) => acc + expense.budgeted_amount,
      0
    );
    const total_actual_cost =
      expensesWithABudget.reduce(
        (acc, expense) => acc + expense.actual_cost,
        0
      ) +
      expensesWithoutABudget.reduce(
        (acc, expense) => acc + expense.actual_cost,
        0
      );
    const total_remaining =
      expensesWithABudget.reduce((acc, expense) => acc + expense.remaining, 0) +
      expensesWithoutABudget.reduce(
        (acc, expense) => acc + expense.remaining,
        0
      );

    return [
      ...expensesWithABudget,
      ...expensesWithoutABudget,
      // TOTALS ROW
      {
        expense_type: "",
        payee_name: "",
        budgeted_amount: total_bugdeted_amount,
        actual_cost: total_actual_cost,
        remaining: total_remaining,
      },
    ];
  }, [cogs, projectedExpenses]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<COGSTableColumns>();
    return [
      columnHelper.accessor("expense_type", {
        header: () => {
          return <div css={tw`flex items-center`}>Expense Type</div>;
        },
        id: "expense_type",
        cell: ({ row }) => {
          const lastRow = row.index === data.length - 1;
          return (
            <div css={tw`flex items-center gap-x-1`}>
              <span>{row.original.expense_type}</span>
              {!row.original.id && !lastRow && (
                <Tooltip
                  label="This expense was invoiced but not budgeted. Click the edit icon in this row to set a budget."
                  aria-label="Expense Type"
                >
                  <InfoIcon css={tw`w-3`} />
                </Tooltip>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("payee_name", {
        header: () => {
          return <div css={tw`flex items-center`}>Payee Name</div>;
        },
        id: "payee_name",
        cell: ({ row }) => {
          return (
            <div css={tw`flex items-center gap-x-1`}>
              <span>{row.original.payee_name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("budgeted_amount", {
        header: () => {
          return (
            <div css={tw`flex items-center justify-end`}>
              Budgeted Amount&nbsp;
              <Tooltip
                label="This represents your budget total for all invoices of this Expense Type and Payee Name for the duration of the project."
                aria-label="Budgeted Amount"
              >
                <InfoIcon css={tw`w-3`} />
              </Tooltip>
            </div>
          );
        },
        id: "budgeted_amount",
        cell: ({ row }) => <MoneyCell value={row.original.budgeted_amount} />,
      }),
      columnHelper.accessor("actual_cost", {
        header: () => {
          return (
            <div css={tw`flex items-center justify-end`}>
              Actual Cost&nbsp;
              <Tooltip
                label="This represents the actual total from all invoices with this Expense Type and Payee Name on the project. Click each cell to see the individual invoices."
                aria-label="Budgeted Amount"
              >
                <InfoIcon css={tw`w-3`} />
              </Tooltip>
            </div>
          );
        },
        id: "actual_cost",
        cell: ({ row }) => {
          const projectExpenseKey: string | undefined = Object.keys(cogs.expenses).find(expenseKey => expenseKey === `${row.original.expense_type} - ${row.original.payee_name}`);
          
          if (!projectExpenseKey) {
            return <MoneyCell value={row.original.actual_cost} />;
          }

          const projectExpense = cogs.expenses[projectExpenseKey];

          return (
            <Popover>
              <PopoverTrigger>
                {/* <MoneyCell value={row.original.actual_cost} /> */}
                <span css={tw`cursor-pointer`}>
                  <MoneyCell value={row.original.actual_cost} />
                </span>
              </PopoverTrigger>
              <PopoverContent
                minW={{ base: "100%", lg: "max-content" }}
                backgroundColor="rgb(45, 55, 72)"
                color="white"
              >
                <PopoverBody padding={0}>
                  <InvoicesTooltip invoices={projectExpense.invoices} />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        }
      }),
      columnHelper.accessor("remaining", {
        header: () => {
          return <div css={tw`flex items-center justify-end`}>Remaining</div>;
        },
        id: "remaining",
        cell: ({ row }) => <MoneyCell value={row.original.remaining} />,
      }),
      // New Actions column
      columnHelper.display({
        id: "actions",
        header: "",
        cell: ({ row }) => {
          if (row.index === data.length - 1) return null;
          return (
            <MutateProjectedExpense
              projectId={projectId}
              projectPricingId={projectPricingId}
              projectedExpenseId={row.original.id}
              expenseType={row.original.expense_type}
              payeeName={row.original.payee_name}
              budgetedAmount={row.original.budgeted_amount}
            />
          )
        },
      }),
    ];
  }, [cogs, projectedExpenses]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
  });
  return (
    <section css={tw`flex flex-col gap-y-2 w-full`}>
      <table css={tw`border-collapse border border-gray-200`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    css={tw`border border-gray-200 px-2 py-1`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              css={
                rowIndex === table.getRowModel().rows.length - 1
                  ? tw`font-bold`
                  : undefined
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  css={tw`border border-gray-200 text-right px-2`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
      <NewProjectedExpenseForm
        projectId={projectId}
        projectPricingId={projectPricingId}
        projectedExpenses={projectedExpenses}
      />
    </section>
  );
};

const MoneyCell = ({ value }: { value: number }) => {
  const { formatNumber } = useContext(PricingContext);
  const isNegative = value < 0;
  if (isNegative) {
    return (
      <span css={tw`text-red-500`}>-${formatNumber(Math.abs(value))}</span>
    );
  }
  return <span>${formatNumber(value)}</span>;
};

const InvoicesTooltip = ({
  invoices,
}: {
  invoices: BriefProjectInvoice[];
}) => {

  return (
    <table
      css={css`
        & > tbody > tr > td {
          padding-left: 1rem;
          padding-right: 1rem;
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
        }
        & > tbody > tr:nth-child(even) {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `}
    >
      <tbody>
        {invoices.map((invoice) => (
          <tr>
            <td>
              {new Date(invoice.date_created).toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </td>
            <td>{invoice.expense_type}</td>
            <td>{invoice.payee_name}</td>
            <td align="right">${(invoice.amount_in_cents / 100).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default COGSTable;
