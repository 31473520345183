
import tw, { css } from "twin.macro";
import { DayOff, TimeEntry } from "../../codegen/graphql-types";
import { TimeSummaryRange } from "./TimeSummaryRange";
import { isDateWithinRange } from "../dates";
import { useDateRanges } from "../useDateRanges";

export type FlexibleTimeEntry = Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'billable' | 'company_id' | 'user_id' | 'project_id' | 'project_tag_id'>;
export type FlexibleDayOff = Pick<DayOff, 'id' | 'date' | 'reason' | 'personId'>;

type TimeSummaryRowProps = {
  timeEntries: FlexibleTimeEntry[];
  daysOff: FlexibleDayOff[];
  targetForYearInMinutes?: number | null;
  isBillable?: boolean;
  isTotal?: boolean;
}

export default function TimeSummaryRow({
  timeEntries,
  daysOff,
  targetForYearInMinutes,
  isBillable,
  isTotal,
}: TimeSummaryRowProps) {

  const { lastWeek, lastFourWeeks, lastThreeMonths } = useDateRanges();
  
  const timeEntriesFromLastWeek = timeEntries.filter(timeEntry => {
    return isDateWithinRange(lastWeek.startDate, lastWeek.endDate, timeEntry.date);
  });

  const timeEntriesFromLastFourWeeks = timeEntries.filter(timeEntry => {
    return isDateWithinRange(lastFourWeeks.startDate, lastFourWeeks.endDate, timeEntry.date);
  });

  // const sortedRecentTimeEntriesByDate = [...timeEntriesFromLastFourWeeks].sort((a, b) => a.date.localeCompare(b.date));
  // // sorted time entries for the last 3 months
  // if (isTotal) {
  //   console.log("Last 4 weeks entries: ", sortedRecentTimeEntriesByDate);
  // }

  const timeEntriesFromLastThreeMonths = timeEntries.filter(timeEntry => {
    return isDateWithinRange(lastThreeMonths.startDate, lastThreeMonths.endDate, timeEntry.date);
  });

  return (
    <>
      <td css={tw`px-4 py-1 text-center`}>
        <TimeSummaryRange
          startDate={lastWeek.startDate}
          endDate={lastWeek.endDate}
          userTimeEntries={timeEntriesFromLastWeek}
          isBillable={isBillable}
          targetForYear={targetForYearInMinutes}
          isTotal={isTotal}
          daysOff={daysOff}
        />
      </td>
      <td css={tw`px-4 py-1 text-center`}>
        <TimeSummaryRange
          startDate={lastFourWeeks.startDate}
          endDate={lastFourWeeks.endDate}
          userTimeEntries={timeEntriesFromLastFourWeeks}
          isBillable={isBillable}
          targetForYear={targetForYearInMinutes}
          isTotal={isTotal}
          daysOff={daysOff}
        />
      </td>
      <td css={tw`px-4 py-1 text-center`}>
        <TimeSummaryRange
          startDate={lastThreeMonths.startDate}
          endDate={lastThreeMonths.endDate}
          userTimeEntries={timeEntriesFromLastThreeMonths}
          isBillable={isBillable}
          targetForYear={targetForYearInMinutes}
          isTotal={isTotal}
          daysOff={daysOff}
        />
      </td>
    </>
  )
}