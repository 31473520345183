import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryAxis,
} from "victory";
import _ from "lodash";
import {
  ComparisonChartData,
  currencyFormatter,
  primaryColor,
  secondaryColor,
} from "./constants";

export const YearComparisonChart = ({
  year1Data,
  year2Data,
}: {
  year1Data: ComparisonChartData;
  year2Data: ComparisonChartData;
}) => {
  return (
    <VictoryChart
      height={500}
      width={600}
      domainPadding={{ y: 20 }}
      padding={80}
      containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
    >
      {[year1Data, year2Data].map((yearData, yearIndex) => {
        const color = yearIndex === 0 ? secondaryColor : primaryColor;
        const otherYearData = (_.without([year1Data, year2Data], yearData)[0] ?? []) as ComparisonChartData ?? [];
        const year1DataY = year1Data.map((x) => x.y);
        const year2DataY = year2Data.map((x) => x.y);
        const thresholdBase = _.max([
          _.last(year1DataY) ?? 0,
          _.last(year2DataY) ?? 0,
        ]) ?? 0;
        const threshold = thresholdBase * 0.07;

        return [
          <VictoryLine
            data={yearData}
            style={{
              data: {
                stroke: color,
                strokeWidth: 2,
              },
              labels: { fill: color },
            }}
          />,
          <VictoryScatter
            data={yearData}
            size={5}
            style={{ data: { fill: color } }}
            labels={({ datum }) =>
              `${datum.monthName} ${datum.year}: $${currencyFormatter.format(
                datum.y
              )}`
            }
            labelComponent={
              <VictoryTooltip
                cornerRadius={0}
                flyoutStyle={{ fill: "white" }}
                dy={({ index }) => {
                  if (yearIndex === 1) return 0;
                  const diff = yearData[Number(index)].y - otherYearData[Number(index)].y;
                  if (Math.abs(diff) < threshold) {
                    if (diff > 0) return -20;
                    return 20;
                  }

                  return 0;
                }}
              />
            }
          />,
        ];
      })}

      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 15,
            paddingTop: 20,
            angle: 30,
            textAnchor: "left",
            verticalAnchor: "start",
          },
          ticks: {
            stroke: "#000000",
            size: 8,
          },
        }}
      />
      <VictoryAxis dependentAxis />
    </VictoryChart>
  );
};
