
import tw, { css } from "twin.macro";
import { TimeEntry } from "../../codegen/graphql-types";
import { isSameDay } from "date-fns";
import { filterBillableTime, filterNonBillableTime } from "../constants";

export const DayTotals = ({
  day,
  timeEntriesForWeek,
}: {
  day: string;
  timeEntriesForWeek: TimeEntry[];
}) => {
  // Parse the day string to a Date object for comparison
  const dateObj = new Date(day);
  const timeEntriesForDay = timeEntriesForWeek.filter((entry) => isSameDay(new Date(entry.date), dateObj));
  
  const totalMinutesForDay = timeEntriesForDay.reduce((acc, entry) => acc + entry.minutes, 0);
  const totalHoursForDay = (totalMinutesForDay / 60).toFixed(1);

  // billable time entries for the day
  const billableTimeEntriesForDay = timeEntriesForDay.filter(filterBillableTime);
  // non-billable time entries for the day
  const nonBillableTimeEntriesForDay = timeEntriesForDay.filter(filterNonBillableTime);
  // total billable time for the day
  const totalBillableMinutesForDay = billableTimeEntriesForDay.reduce((acc, entry) => acc + entry.minutes, 0);
  const totalBillableHoursForDay = (totalBillableMinutesForDay / 60).toFixed(1);
  // total non-billable time for the day
  const totalNonBillableMinutesForDay = nonBillableTimeEntriesForDay.reduce((acc, entry) => acc + entry.minutes, 0);
  const totalNonBillableHoursForDay = (totalNonBillableMinutesForDay / 60).toFixed(1);

  return (
    <div css={tw`flex justify-center text-sm`}>
      <table css={tw`table-auto m-0 p-0`}>
        <tbody>
          <tr>
            <td css={tw`px-1`}>Total</td>
            <td css={tw`px-1`}>{totalHoursForDay}</td>
          </tr>
          <tr>
            <td css={tw`px-1`}>Billable</td>
            <td css={tw`px-1`}>{totalBillableHoursForDay}</td>
          </tr>
          <tr>
            <td css={tw`px-1`}>Non-billable</td>
            <td css={tw`px-1`}>{totalNonBillableHoursForDay}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}