import tw from "twin.macro";
import { RuntimeConfig } from "../../RuntimeConfig";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const UploadEstimatePdf = function({
  formId,
  uploadedFiles,
  setUploadedFiles,
  setFieldValue,
  method = 'POST', // Added method prop with default to POST
  existingFileKey, // Optional prop for file key if updating an existing file
  onSuccessfulUpload,
}: {
  formId: string; // FormControl id
  uploadedFiles: {
    id: string;
    name: string;
    contentType: string;
  }[]; // List of uploaded files
  setUploadedFiles: (files: any) => void; // Set uploaded files
  setFieldValue?: (field: string, value: any) => void; // Formik setFieldValue
  method?: 'POST' | 'PUT'; // New prop for method (POST or PUT)
  existingFileKey?: string; // New prop to pass the file key if updating
  onSuccessfulUpload?: (key: string) => void; // Optional callback on successful upload
}) {
  const [pdfUploadError, setPdfUploadError] = useState<string | null>(null);

  const uploadFiles = useCallback(async (files: File[]) => {
    setPdfUploadError(null);
    const file = files[0];
    if (!file) return;

    // Validate file type
    if (file.type !== 'application/pdf') {
      setPdfUploadError('Only PDF files are allowed');
      return;
    }

    // Validate file size
    if (file.size > 5 * 1024 * 1024) {
      setPdfUploadError('File size must be less than 5MB');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderPath', 'client-estimates');

    if (method === 'PUT' && existingFileKey) {
      // If updating, send the file key to replace
      formData.append('key', existingFileKey);
    }

    try {
      const response = await fetch(
        `${RuntimeConfig.backendOrigin}/s3/${method === 'POST' ? 'upload' : 'update'}`,
        {
          method,
          credentials: 'include',
          headers: {
            // @ts-ignore
            "x-api-key": import.meta.env.VITE_S3_API_KEY!,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`Error ${method === 'POST' ? 'uploading' : 'updating'} PDF. Status: ${response.status}`);
      }

      const { key, fileName } = await response.json();

      setUploadedFiles(uploadedFiles.concat({
        id: key,
        name: fileName,
        contentType: file.type,
      }));

      if (onSuccessfulUpload) {
        // update the estimate record in the database with the new pdf file key
        onSuccessfulUpload(key);
      }

      // Set the formik form value to the file s3 key
      if (setFieldValue) {
        setFieldValue('pdf_file', key);
      }
    } catch (error: any) {
      console.error(`${method === 'POST' ? 'Upload' : 'Update'} failed:`, error);
      setPdfUploadError(`Error ${method === 'POST' ? 'uploading' : 'updating'} PDF: ${error.message}`);
    }
  }, [uploadedFiles, setFieldValue, method, existingFileKey]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDropAccepted: async (acceptedFiles) => {
      uploadFiles(acceptedFiles);
    },
  });

  return (
    <FormControl
      id={formId}
      isRequired
      isInvalid={Boolean(pdfUploadError)}
    >
      <FormLabel>Estimate PDF</FormLabel>
      <div
        {...getRootProps({ className: "dropzone" })}
        css={tw`w-full flex flex-col justify-center bg-gray-100 border border-gray-300 rounded p-2`}
      > 
        {uploadedFiles.length === 0 && (
          <>
            {/* @ts-ignore */}
            <input {...getInputProps()} />
            <p css={tw`text-center opacity-50`}>
              Drop Estimate PDF
            </p>
          </>
        )}
        <FormErrorMessage>{pdfUploadError}</FormErrorMessage>
        <div>
          {uploadedFiles.map(file => (
            <div key={file.id} css={tw`flex items-center`}>
              <p css={tw`text-center opacity-50`}>{file.name}</p>
              <Button
                css={tw`ml-auto`}
                onClick={() =>
                  setUploadedFiles((uploadFiles: any) =>
                    uploadFiles.filter((x: any) => x.id !== file.id)
                  )
                }
              >
                <DeleteIcon />
              </Button>
            </div>
          ))}
        </div>
      </div>
      <br/>
    </FormControl>
  );
}

export default UploadEstimatePdf;
