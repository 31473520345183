/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import {
  Box,
  Button,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Select,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import * as QBO from "../../../../server/lib/quickbooks/types";

// Currency formatter for formatting the amount as a currency string
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface LineItemsTableProps {
  lineItems: QBO.InvoiceLine[];
  items: QBO.Item[];
  taxRates: QBO.TaxRate[];
  taxCodes: QBO.TaxCode[];
  defaultTaxCodeRef: {
    id: string;
    name: string;
  };
  customerCurrency: string;
  onLineItemsChange: (lineItems: QBO.InvoiceLine[]) => void;
}

const LineItemsTable: React.FC<LineItemsTableProps> = ({
  lineItems,
  items,
  taxRates,
  taxCodes,
  defaultTaxCodeRef,
  customerCurrency,
  onLineItemsChange,
}) => {
  const defaultTaxCodeName = defaultTaxCodeRef.name; // e.g. HST ON
  const defaultTaxRate = taxRates.find(
    (taxRate) => taxRate.Name === defaultTaxCodeName
  );
  const defaultTaxRateValue = defaultTaxRate ? defaultTaxRate.RateValue : 0; // E.g. 13 for HST ON

  const handleAddLineItem = () => {
    const newLineItem: QBO.InvoiceLine = {
      Id: (lineItems.length + 1).toString(), // Generating a simple unique Id
      LineNum: lineItems.length + 1,
      Description: "",
      Amount: 0,
      DetailType: "SalesItemLineDetail",
      SalesItemLineDetail: {
        ItemRef: {
          value: "",
          name: "",
        },
        Qty: 1,
        UnitPrice: 0,
        TaxCodeRef: {
          value: "",
          name: "",
        },
      },
    };
    const updatedLineItems = [...lineItems, newLineItem];
    // setLineItems(updatedLineItems);
    onLineItemsChange(updatedLineItems);
  };

  const handleRemoveLineItem = (index: number) => {
    const updatedLineItems = lineItems.filter((_, i) => i !== index);
    // Reindexing LineNum and Id after removal
    const reindexedLineItems = updatedLineItems.map((item, idx) => ({
      ...item,
      LineNum: idx + 1,
      Id: (idx + 1).toString(),
    }));

    onLineItemsChange(reindexedLineItems);
  };

  const handleItemSelect = (index: number, itemId: string) => {
    const selectedItem = items.find((item) => item.Id === itemId);
    if (selectedItem) {
      const unitPrice = selectedItem.UnitPrice || 0;
      const description = selectedItem.Description || "";

      const taxCodeRef = {
        value: selectedItem.Taxable ? "TAX" : "NON",
        name: selectedItem.Taxable ? "Taxable" : "Non-Taxable",
      };

      const updatedLineItems = [...lineItems];
      updatedLineItems[index].SalesItemLineDetail.ItemRef = {
        value: selectedItem.Id,
        name: selectedItem.Name,
      };
      updatedLineItems[index].SalesItemLineDetail.UnitPrice = unitPrice;
      updatedLineItems[index].Description = description;
      updatedLineItems[index].SalesItemLineDetail.TaxCodeRef = taxCodeRef;

      // Recalculate Amount
      const qty = updatedLineItems[index].SalesItemLineDetail.Qty || 0;
      updatedLineItems[index].Amount = qty * unitPrice;

      onLineItemsChange(updatedLineItems);
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof QBO.InvoiceLine,
    value: any
  ) => {
    const updatedLineItems = [...lineItems];
    if (field === "Description") {
      updatedLineItems[index][field] = cleanAndFormatText(value);
    } else if (field === "SalesItemLineDetail") {
      updatedLineItems[index].SalesItemLineDetail = {
        ...updatedLineItems[index].SalesItemLineDetail,
        ...value,
      };
      // Recalculate Amount when Qty or UnitPrice changes
      const qty = updatedLineItems[index].SalesItemLineDetail.Qty || 0;
      const unitPrice =
        updatedLineItems[index].SalesItemLineDetail.UnitPrice || 0;
      updatedLineItems[index].Amount = qty * unitPrice;
    }

    onLineItemsChange(updatedLineItems);
  };

  const cleanAndFormatText = (html: string) => {
    // Convert HTML to markdown-like syntax
    let markdown = html
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "\n\n")
      .replace(/<br\s*\/?>/g, "\n")
      .replace(/<ul>/g, "")
      .replace(/<\/ul>/g, "\n")
      .replace(/<li>/g, "• ")
      .replace(/<\/li>/g, "\n")
      .replace(/<strong>|<b>/g, "**")
      .replace(/<\/strong>|<\/b>/g, "**")
      .replace(/<em>|<i>/g, "_")
      .replace(/<\/em>|<\/i>/g, "_")
      .replace(/<u>/g, "__")
      .replace(/<\/u>/g, "__")
      .replace(/&nbsp;/g, " ")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'");

    // Remove any remaining HTML tags
    markdown = markdown.replace(/<[^>]+>/g, "");

    // Trim extra whitespace and newlines
    markdown = markdown.trim().replace(/\n{3,}/g, "\n\n");

    return markdown;
  };

  return (
    <div css={tw`w-full my-8`}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Product/Service</Th>
            <Th>Description</Th>
            <Th>Quantity</Th>
            <Th>Rate</Th>
            <Th>Amount</Th>
            <Th>Tax</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {lineItems.map((lineItem, index) => (
            <Tr key={`id-${lineItem.Id}-index-${index}`}>
              <Td verticalAlign="top">{lineItem.LineNum}</Td>
              <Td width={400} padding={1} verticalAlign="top">
                <Select
                  placeholder="Select item"
                  onChange={(e) => handleItemSelect(index, e.target.value)}
                  value={lineItem.SalesItemLineDetail.ItemRef.value}
                >
                  {items.map((item) => (
                    <option key={item.Id} value={item.Id}>
                      {item.Name}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td width={400} padding={1} verticalAlign="top">
                {/* We need to support rich-text formatting in this field */}
                <div
                  css={tw`border overflow-scroll max-h-40 border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 whitespace-pre-wrap`}
                  contentEditable
                  onBlur={(e) =>
                    handleInputChange(
                      index,
                      "Description",
                      e.currentTarget.innerHTML
                    )
                  }
                  dangerouslySetInnerHTML={{ __html: lineItem.Description }}
                />
                {/* character length of description */}
                <div
                  css={tw`text-xs`}
                  style={{
                    color: lineItem.Description.length > 4000 ? "red" : "gray",
                  }}
                >
                  {lineItem.Description.length} characters
                  {lineItem.Description.length > 4000
                    ? " (Please create another line item)"
                    : ""}
                </div>
              </Td>
              <Td padding={1} verticalAlign="top">
                <Input
                  type="number"
                  placeholder="Quantity"
                  min="0"
                  value={lineItem.SalesItemLineDetail.Qty}
                  onChange={(e) =>
                    handleInputChange(index, "SalesItemLineDetail", {
                      Qty: parseFloat(e.target.value) || 0,
                    })
                  }
                />
              </Td>
              <Td padding={1} verticalAlign="top">
                <Input
                  type="number"
                  placeholder="Rate"
                  min="0"
                  value={lineItem.SalesItemLineDetail.UnitPrice}
                  onChange={(e) =>
                    handleInputChange(index, "SalesItemLineDetail", {
                      UnitPrice: parseFloat(e.target.value) || 0,
                    })
                  }
                />
              </Td>
              <Td padding={1} verticalAlign="top">
                <Input
                  placeholder="Amount"
                  value={currencyFormatter.format(lineItem.Amount)}
                  isReadOnly
                />
              </Td>
              <Td padding={1} verticalAlign="top">
                <Select
                  placeholder="Select Tax"
                  value={lineItem.SalesItemLineDetail.TaxCodeRef.value}
                  onChange={(e) =>
                    handleInputChange(index, "SalesItemLineDetail", {
                      TaxCodeRef: {
                        value: e.target.value,
                        name:
                          e.target.value === "TAX" ? "Taxable" : "Non-Taxable",
                      },
                    })
                  }
                >
                  {taxCodes.map((taxCode) => (
                    <option key={taxCode.Id} value={taxCode.Id}>
                      {taxCode.Name}
                    </option>
                  ))}
                </Select>
              </Td>
              <Td
                padding={1}
                verticalAlign="top"
                display="flex"
                justifyContent="center"
              >
                <IconButton
                  aria-label="Delete line item"
                  icon={<DeleteIcon />}
                  onClick={() => handleRemoveLineItem(index)}
                  colorScheme="red"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Button
        onClick={handleAddLineItem}
        leftIcon={<AddIcon />}
        colorScheme="blue"
        mt={4}
      >
        Add Line Item
      </Button>
      {/* Invoice Total */}
      <div css={tw`mt-4 flex w-full flex-col items-end`}>
        <table css={tw`w-1/4`}>
          <tbody>
            <tr>
              <td>SUBTOTAL</td>
              <td css={tw`text-right`}>
                {currencyFormatter.format(
                  lineItems.reduce((total, item) => total + item.Amount, 0)
                )}
              </td>
            </tr>
            <tr>
              <td>
                {defaultTaxCodeName} @ {defaultTaxRateValue}%
              </td>
              <td css={tw`text-right`}>
                {currencyFormatter.format(
                  (lineItems.reduce((total, item) => total + item.Amount, 0) *
                    defaultTaxRateValue) /
                    100
                )}
              </td>
            </tr>
            <tr>
              <td>TOTAL</td>
              <td css={tw`text-right`}>
                {currencyFormatter.format(
                  lineItems.reduce((total, item) => total + item.Amount, 0) *
                    (1 + defaultTaxRateValue / 100)
                )}
              </td>
            </tr>
            <tr>
              <td css={tw`text-lg`}>
                <strong>BALANCE DUE</strong>
              </td>
              <td css={tw`text-lg text-right`}>
                <strong>
                  {customerCurrency}{" "}
                  {currencyFormatter.format(
                    lineItems.reduce((total, item) => total + item.Amount, 0) *
                      (1 + defaultTaxRateValue / 100)
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LineItemsTable;
