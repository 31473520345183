
import { DateTime, Interval } from "luxon";
import tw, { css } from "twin.macro";
import _ from "lodash";
import { useMemo } from "react";

import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import {
  useCompanyRevenueForecastQuery,
  CompanyRevenueForecastQuery,
} from "../../../codegen/graphql-types";
import {
  chartContainerWidth,
  currencyFormatter,
  forecastColor,
  aboveForecastColor,
  belowForecastColor
} from "../Charts/constants";
import { ChartHeading } from "../Charts/chartHelpers";
import { useAuthContext } from "../../../useAuth";
import { usePeopleContext } from "../../../helpers/usePeopleContext";

const soldColor = "#095AB2";
const earnedColor = "#FB0005";
// const podBonusColor = "#5C1A8E";

export const CompanyRevenueForecast = ({
  yearInFocus,
}: {
  yearInFocus: number;
}) => {
  const peopleData = usePeopleContext();
  const { isAdmin } = useAuthContext();
  const { data } = useCompanyRevenueForecastQuery({
    variables: {
      year: yearInFocus,
    },
  });
  const lastYearOfData = data?.companyAnnualRevenueTargets.find(
    (x) => x.year === yearInFocus
  );

  type CompanyPodProjects = {
    projects: NonNullable<
      CompanyRevenueForecastQuery
    >["companyAnnualRevenueForecastProjects"];
    budget: number;
    cogs: number;
  };
  type CompanyPodsProjects = Record<
    string,
    {
      podLead: string;
      completedProjects: CompanyPodProjects;
      cancelledProjects: CompanyPodProjects;
      inProgressProjects: CompanyPodProjects;
      onHoldProjects: CompanyPodProjects;
    }
  >;

  // console.log("data: ", data);

  const companyPodsProjectsKeyedBySBMLead = useMemo(() => {
    if (!data) return {};
    return data.companyAnnualRevenueForecastProjects.reduce<
      CompanyPodsProjects
    >(
      (acc, project) => {
        const podOwningProject = peopleData.data?.pods.find(
          (x) => x.senior_brand_maven_lead === project.senior_brand_maven_lead
        );
        const ownerSbmLead =
          podOwningProject?.senior_brand_maven_lead ?? "No Pod Lead";
        const podSplittingProject =
          project.sales_split_pod &&
          peopleData.data?.pods.find(
            (x) => x.id === project.sales_split_pod?.id
          );

        const ownerParticipationFactor = !podSplittingProject
          ? 1
          : (100 - project.sales_split_ratio) / 100;

        const mapProjectStatusToAccField = {
          Completed: "completedProjects",
          Cancelled: "cancelledProjects",
          "In Progress": "inProgressProjects",
          "On Hold": "onHoldProjects",
        } as const;

        const projectStatus = project.project_status as keyof typeof mapProjectStatusToAccField;

        if (!acc[ownerSbmLead]) {
          console.log("No Pod Lead found on this project for ownerSbmLead: ", ownerSbmLead);
          console.log("Project Id: ", project.id);
          console.log("Project: ", project);
          console.log("projectStatus: ", projectStatus);
          console.log("ownerSbmLead: ", ownerSbmLead);
          console.log("acc[ownerSbmLead]: ", acc[ownerSbmLead]);
        }

        // If there is a "Pod Lead" (acc[ownerSbmLead])
        if (acc[ownerSbmLead]) {
          // Add project to the appropriate project status key
          acc[ownerSbmLead][
            mapProjectStatusToAccField[projectStatus]
          ].projects.push(project);

          // Increment total sum budget and cogs to the appropriate project status key
          acc[ownerSbmLead][mapProjectStatusToAccField[projectStatus]].budget +=
            (project.budget ?? 0) * ownerParticipationFactor;
          acc[ownerSbmLead][mapProjectStatusToAccField[projectStatus]].cogs +=
            (project.cogs_in_cents ?? 0) * ownerParticipationFactor;
        }

        if (podSplittingProject) {
          const splitterParticipationFactor = project.sales_split_ratio / 100;
          const sbmLeadKey = podSplittingProject.senior_brand_maven_lead as keyof CompanyPodsProjects;
          acc[sbmLeadKey][
            mapProjectStatusToAccField[projectStatus]
          ].projects.push(project);
          acc[sbmLeadKey][
            mapProjectStatusToAccField[projectStatus]
          ].budget += (project.budget ?? 0) * splitterParticipationFactor;
          acc[sbmLeadKey][
            mapProjectStatusToAccField[projectStatus]
          ].cogs += (project.cogs_in_cents ?? 0) * splitterParticipationFactor;
        }

        // if (project.project_status === "Completed") {
        //   acc[sbmLead].completedProjects.projects.push(project);
        //   acc[sbmLead].completedProjects.budget += project.budget ?? 0;
        //   acc[sbmLead].completedProjects.cogs += project.cogs_in_cents ?? 0;
        // } else if (project.project_status === "Cancelled") {
        //   acc[sbmLead].cancelledProjects.projects.push(project);
        //   acc[sbmLead].cancelledProjects.budget += project.budget ?? 0;
        //   acc[sbmLead].cancelledProjects.cogs += project.cogs_in_cents ?? 0;
        // } else if (project.project_status === "In Progress") {
        //   acc[sbmLead].inProgressProjects.projects.push(project);
        //   acc[sbmLead].inProgressProjects.budget += project.budget ?? 0;
        //   acc[sbmLead].inProgressProjects.cogs += project.cogs_in_cents ?? 0;
        // } else if (project.project_status === "On Hold") {
        //   acc[sbmLead].onHoldProjects.projects.push(project);
        //   acc[sbmLead].onHoldProjects.budget += project.budget ?? 0;
        //   acc[sbmLead].onHoldProjects.cogs += project.cogs_in_cents ?? 0;
        // }
        return acc;
      },
      Object.fromEntries(
        _.orderBy(
          _.uniq(
            data.companyAnnualRevenueForecastProjects.map(
              (x) => x.senior_brand_maven_lead ?? "No Pod Lead"
            )
          ),
          (x) => (x === "No Pod Lead" ? 99999 : x)
        ).map((x) => [
          x,
          {
            podLead: x,
            completedProjects: {
              projects: [],
              budget: 0,
              cogs: 0,
            },
            cancelledProjects: {
              projects: [],
              budget: 0,
              cogs: 0,
            },
            inProgressProjects: {
              projects: [],
              budget: 0,
              cogs: 0,
            },
            onHoldProjects: {
              projects: [],
              budget: 0,
              cogs: 0,
            },
          },
        ])
      )
    );
  }, [data]);

  //console.log("companyPodsProjectsKeyedBySBMLead: ", companyPodsProjectsKeyedBySBMLead);

  if (!data)
    return (
      <div css={[tw`flex flex-col`]}>
        &nbsp;Loading company performance data...
      </div>
    );
  if (!lastYearOfData) return <>No data for Company Revenue Forecast</>;

  // const percentOfTargetSold =
  //   lastYearOfData.net_sales_potential_cents /
  //   ((lastYearOfData.revenue_target_dollars ?? 0) * 100);
  // const max = Math.max(1, percentOfTargetSold);

  const firstDayOfYear = DateTime.fromISO(`${lastYearOfData.year}-01-01`, { zone: 'America/Toronto' });
  const lastDayOfYear = DateTime.fromISO(`${lastYearOfData.year}-12-31`, { zone: 'America/Toronto' });

  // const domainInterval = Interval.fromDateTimes(firstDayOfYear, lastDayOfYear);

  const revenueTargetDollars = lastYearOfData.revenue_target_dollars ?? 0;

  const aggregated_data = {
    monthlyDataNetSales:
      _.sumBy(
        data.companyAnnualRevenueForecastProjects,
        // @ts-expect-error
        (x) => x.budget * 100 - x.cogs_in_cents
      ) / 100,
    monthlyDataNetEarnedSales: _.sortBy(
      Object.values(
        _.groupBy(
          data.companyAnnualRevenueForecastProjects.filter(
            (project) =>
              project.project_status &&
              ["Completed", "Cancelled"].includes(project.project_status)
          ),
          (project) => {
            return DateTime.fromISO(project?.end_date, { zone: 'America/Toronto' }).toFormat("YYYY-MM");
          }
        )
      ).flatMap((group) => {
        if (group == null) return [];
        return {
          startOfMonth: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).startOf("month"),
          year: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).toFormat("YYYY"),
          month: DateTime.fromISO(group[0].end_date, { zone: 'America/Toronto' }).toFormat("MM"),
          netSales:
            // @ts-expect-error
            _.sumBy(group, (x) => x.budget * 100 - x.cogs_in_cents) / 100,
        };
      }),
      (x) => x.startOfMonth.toJSDate().getTime()
    ).map((x, i, arr) => ({
      ...x,
      cumulativeNetSales: _.sumBy(arr.slice(0, i + 1), "netSales") ?? 0,
    })),
  };

  const orderedPodLeadProjects = Object.entries(
    _.orderBy(companyPodsProjectsKeyedBySBMLead, (x) =>
      x.podLead === "No Pod Lead" ? 99999 : x.podLead
    )
  );

  const lowCompanyForecast =
    data.companyAnnualRevenueTargets.find((x) => x.year === yearInFocus)
      ?.revenue_target_dollars ?? 0;
  const highCompanyForecast = Math.floor(lowCompanyForecast * 1.3);

  // console.log("orderedPodLeadProjects: ", orderedPodLeadProjects);

  return (
    <>
      <div css={[tw`flex flex-col`]}>
        <ChartHeading>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-top: 1rem;
            `}
          >
            Maven Company Performance
          </div>
        </ChartHeading>
        <div style={{ width: chartContainerWidth }}>
          <CompanyRevenueChart
            firstDayOfYear={firstDayOfYear}
            lastDayOfYear={lastDayOfYear}
            revenueTargetDollars={revenueTargetDollars ?? 0}
            dollarsSold={lastYearOfData.net_sales_potential_cents / 100}
            monthlyDataNetEarnedSales={
              aggregated_data.monthlyDataNetEarnedSales
            }
          />
        </div>
        {isAdmin && (
          <table
            css={[
              tw`ml-12`,
              css`
                text-align: right;
                td,
                th {
                  border: 1px solid #ccc;
                  padding: 0.5rem;
                  vertical-align: middle;
                }
                th {
                  font-weight: bold;
                }
              `,
            ]}
          >
            <tbody>
              <tr
                css={css`
                  background: aliceblue;
                  border-right: solid 1px #ddd;
                  border-left: solid 1px #ddd;
                `}
              >
                <th css={tw`font-bold`}>Pod Lead:</th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  return (
                    <td key={podProjects.podLead} css={tw`text-left font-bold`}>
                      {podProjects.podLead}
                    </td>
                  );
                })}
                <td css={tw`text-left font-bold`}>Company</td>
              </tr>
              <tr>
                <th>Completed in {yearInFocus}:</th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  return (
                    <td key={podProjects.podLead}>
                      $
                      {currencyFormatter.format(
                        (podProjects.completedProjects.budget * 100 -
                          podProjects.completedProjects.cogs) /
                          100
                      )}
                    </td>
                  );
                })}
                <td>
                  $
                  {currencyFormatter.format(
                    orderedPodLeadProjects.reduce(
                      (acc, [, podProjects]) =>
                        acc +
                        (podProjects.completedProjects.budget * 100 -
                          podProjects.completedProjects.cogs),
                      0
                    ) / 100
                  )}
                </td>
              </tr>
              <tr>
                <th>Cancelled in {yearInFocus}:</th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  return (
                    <td key={podProjects.podLead}>
                      $
                      {currencyFormatter.format(
                        (podProjects.cancelledProjects.budget * 100 -
                          podProjects.cancelledProjects.cogs) /
                          100
                      )}
                    </td>
                  );
                })}
                <td>
                  $
                  {currencyFormatter.format(
                    orderedPodLeadProjects
                      .flatMap(([, x]) => x)
                      .reduce(
                        (acc, x) =>
                          acc +
                          x.cancelledProjects.budget * 100 -
                          x.cancelledProjects.cogs,
                        0
                      ) / 100
                  )}
                </td>
              </tr>
              <tr>
                <th>In Progress:</th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  return (
                    <td key={podProjects.podLead}>
                      $
                      {currencyFormatter.format(
                        (podProjects.inProgressProjects.budget * 100 -
                          podProjects.inProgressProjects.cogs) /
                          100
                      )}
                    </td>
                  );
                })}
                <td>
                  $
                  {currencyFormatter.format(
                    orderedPodLeadProjects
                      .flatMap(([, x]) => x)
                      .reduce(
                        (acc, x) =>
                          acc +
                          x.inProgressProjects.budget * 100 -
                          x.inProgressProjects.cogs,
                        0
                      ) / 100
                  )}
                </td>
              </tr>
              <tr>
                <th>On Hold:</th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  return (
                    <td key={podProjects.podLead}>
                      $
                      {currencyFormatter.format(
                        (podProjects.onHoldProjects.budget * 100 -
                          podProjects.onHoldProjects.cogs) /
                          100
                      )}
                    </td>
                  );
                })}
                <td>
                  $
                  {currencyFormatter.format(
                    orderedPodLeadProjects
                      .flatMap(([, x]) => x)
                      .reduce(
                        (acc, x) =>
                          acc +
                          x.onHoldProjects.budget * 100 -
                          x.onHoldProjects.cogs,
                        0
                      ) / 100
                  )}
                </td>
              </tr>
              <tr>
                <th>Total:</th>
                {orderedPodLeadProjects.map(
                  ([
                    ,
                    {
                      completedProjects,
                      cancelledProjects,
                      inProgressProjects,
                      onHoldProjects,
                    },
                  ], index) => {
                    return (
                      <td key={index}>
                        $
                        {currencyFormatter.format(
                          (completedProjects.budget * 100 -
                            completedProjects.cogs) /
                            100 +
                            (cancelledProjects.budget * 100 -
                              cancelledProjects.cogs) /
                              100 +
                            (inProgressProjects.budget * 100 -
                              inProgressProjects.cogs) /
                              100 +
                            (onHoldProjects.budget * 100 -
                              onHoldProjects.cogs) /
                              100
                        )}
                      </td>
                    );
                  }
                )}
                <td>
                  $
                  {currencyFormatter.format(
                    orderedPodLeadProjects
                      .flatMap(([, x]) => x)
                      .reduce(
                        (acc, x) =>
                          acc +
                          (x.onHoldProjects.budget +
                            x.inProgressProjects.budget +
                            x.cancelledProjects.budget +
                            x.completedProjects.budget) *
                            100 -
                          (x.onHoldProjects.cogs +
                            x.inProgressProjects.cogs +
                            x.cancelledProjects.cogs +
                            x.completedProjects.cogs),
                        0
                      ) / 100
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan={orderedPodLeadProjects.length + 2}></td>
              </tr>
              <tr>
                <th>
                  Pod forecast
                  <br />
                  (Company net sales
                  <br />
                  target {yearInFocus}):
                </th>
                {orderedPodLeadProjects.map(([, podProjects]) => {
                  const lowPodForecast =
                    data.pods.find(
                      (pod) =>
                        pod.senior_brand_maven_lead === podProjects.podLead
                    )?.annual_revenue_target.revenue_target_dollars ?? 0;
                  const highPodForecast = Math.floor(lowPodForecast * 1.3);
                  return (
                    <td key={podProjects.podLead}>
                      <span css={css`color:green;`}>Target: ${currencyFormatter.format(highPodForecast)}</span>
                      <br />
                      <span css={css`color:red;`}>Base: ${currencyFormatter.format(lowPodForecast)}</span>
                    </td>
                  );
                })}
                <td>                
                  <span css={css`color:green;`}>Target: ${currencyFormatter.format(highCompanyForecast)}</span>
                  <br />
                  <span css={css`color:red;`}>Base: ${currencyFormatter.format(lowCompanyForecast)}</span>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export const CompanyRevenueChart = ({
  firstDayOfYear,
  lastDayOfYear,
  revenueTargetDollars,
  dollarsSold,
  monthlyDataNetEarnedSales,
}: {
  firstDayOfYear: DateTime;
  lastDayOfYear: DateTime;
  revenueTargetDollars: number;
  dollarsSold: number;
  monthlyDataNetEarnedSales: {
    startOfMonth: DateTime;
    year: string;
    month: string;
    netSales: number;
    cumulativeNetSales: number;
  }[];
}) => {
  const hasSoldTarget = dollarsSold > revenueTargetDollars;
  
  console.log("monthlyDataNetEarnedSales: ", monthlyDataNetEarnedSales);
  console.log("firstDayOfYear: ", firstDayOfYear);
  console.log("lastDayOfYear: ", lastDayOfYear);

  // revenueTargetDollars is actually the low forecast (it's the minimum we need to make to pay for expenses)
  const highTargetRevenueDollars = Math.floor(revenueTargetDollars * 1.3); // Target is = Bottom of the green cone, top of the yellow cone

  const max = _.max([
    ...monthlyDataNetEarnedSales.map((row) => row.netSales),
    dollarsSold,
    // revenueTargetDollars,
    highTargetRevenueDollars,
  ]);

  return (
    <VictoryChart
      height={500}
      width={600}
      domainPadding={{ y: 20 }}
      padding={80}
      containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
    >
      {/* <VictoryLine
        data={_.range(1, 13).map((month) => {
          const monthStart = firstDayOfYear.set({ month });
          return {
            i: month,
            x: monthStart.toSeconds(),
            y: (revenueTargetDollars / 12) * month,
          };
        })}
        style={{
          data: {
            stroke: podBonusColor,
            strokeWidth: 2,
          },
        }}
      /> */}

      {/* <VictoryScatter
        data={_.range(1, 13).map((month, i) => {
          const monthStart = firstDayOfYear.set({ month });
          return {
            i,
            x: monthStart.toSeconds(),
            y: (revenueTargetDollars / 12) * month,
          };
        })}
        size={5}
        style={{
          data: { fill: podBonusColor },
          labels: { fill: podBonusColor },
        }}
        labels={({ datum }) =>
          `Company net sales target: $${Math.floor(
            (datum.y / revenueTargetDollars) * 100
          )}%`
        }
        labelComponent={
          <VictoryTooltip
            cornerRadius={0}
            flyoutStyle={{ fill: "white" }}
            dy={({ datum, index }) => {
              return (monthlyDataNetEarnedSales[datum.i]?.cumulativeNetSales ??
                0) >
                (revenueTargetDollars / 12) * (datum.i + 1)
                ? 20
                : -0;
            }}
          />
        }
      /> */}
      
      {/* Green Cone representing the high (1.3) to very high (1.6) company forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);
          // For now we are using a 50% increase for the very high forecast
          const veryHighForecastDollars = Math.floor(revenueTargetDollars * 1.6);

          return {
            x: startOfMonth.toSeconds(),
            y0: (highForecastDollars / 12) * month,
            y: (veryHighForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: aboveForecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />
      {/* Green line at top of yellow cone */}
      <VictoryLine
        data={_.range(1, 13).map((month) => {
          const monthStart = firstDayOfYear.set({ month });
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);
          return {
            i: month,
            x: monthStart.toSeconds(),
            y: (highForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            stroke: "green",
            strokeWidth: 3,
          },
        }}
      />
      {/* Yellow Cone representing the low (1.0) to high (1.3) company forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // For now we are using the revenue target set by Admin/Forecasting as the low forecast
          const lowForecastDollars = revenueTargetDollars;
          // For now we are using a 30% increase for the high forecast
          const highForecastDollars = Math.floor(revenueTargetDollars * 1.3);

          return {
            x: startOfMonth.toSeconds(),
            y0: (lowForecastDollars / 12) * month,
            y: (highForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: forecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />
      {/* Red line at bottom of yellow cone (1.0) */}
      <VictoryLine
        data={_.range(1, 13).map((month) => {
          const monthStart = firstDayOfYear.set({ month });
          return {
            i: month,
            x: monthStart.toSeconds(),
            y: (revenueTargetDollars / 12) * month,
          };
        })}
        style={{
          data: {
            stroke: "red",
            strokeWidth: 3,
          },
        }}
      />
      {/* Red Cone representing the very low (0.7) to low (1.0) company forecast data from January to December */}
      <VictoryArea
        data={Array.from({ length: 12 }, (_, i) => {
          const startOfMonth = DateTime.fromObject({
            year: firstDayOfYear.year,
            month: i + 1,
            day: 1,
          });
          const month = startOfMonth.month; // 1-12
          // For now we are using the revenue target set by Admin/Forecasting as the low forecast
          const lowForecastDollars = revenueTargetDollars;
          // For now we are using a 30% increase for the high forecast
          const veryLowForecastDollars = Math.floor(revenueTargetDollars * 0.7);

          return {
            x: startOfMonth.toSeconds(),
            y0: (veryLowForecastDollars / 12) * month,
            y: (lowForecastDollars / 12) * month,
          };
        })}
        style={{
          data: {
            fill: belowForecastColor,
            fillOpacity: 0.3,
            pointerEvents: "none",
          },
        }}
      />

      {/** Line at top of graph that shows the Y where the revenue would end up if we finished all work */}
      <VictoryLine
        data={[
          {
            i: 0,
            x: firstDayOfYear.toSeconds(),
            y: dollarsSold,
          },
          {
            i: 1,
            x: lastDayOfYear.toSeconds(),
            y: dollarsSold,
          },
        ]}
        style={{
          data: {
            stroke: soldColor,
            strokeWidth: 2,
          },
          labels: { fill: soldColor },
        }}
        labels={({ datum }) =>
          datum.i === 0
            ? `(Completed + Cancelled in ${
                firstDayOfYear.year
              }) + In Progress + On Hold: ${Math.floor(
                (datum.y / highTargetRevenueDollars) * 100
              )}%`
            : null
        }
        labelComponent={
          <VictoryLabel
            textAnchor="start"
            dx="10"
            dy={hasSoldTarget ? -6 : 20}
          />
        }
      />

      {/** Line that shows how the company is doing */}
      <VictoryLine
        data={monthlyDataNetEarnedSales.map((row, i, arr) => ({
          year: row.year,
          x: row.startOfMonth.toSeconds(),
          y: _.sumBy(arr.slice(0, i + 1), (x) => x.netSales),
        }))}
        style={{
          data: {
            stroke: "#000",
            strokeWidth: 2,
          },
          labels: { fill: "#000" },
        }}
      />
      {/** Dots on the line that shows how the company is doing each month */}
      <VictoryScatter
        data={monthlyDataNetEarnedSales.map((row, i, arr) => ({
          year: row.year,
          x: row.startOfMonth.toSeconds(),
          y: _.sumBy(arr.slice(0, i + 1), (x) => x.netSales),
        }))}
        size={5}
        style={{ data: { fill: "#000" } }}
        labels={({ datum }) => {
          const earned = datum.y;
          const earnedRatio = earned / (Math.floor(revenueTargetDollars * 1.3) ?? 0);
          return `${DateTime.fromSeconds(datum.x).toFormat(
            "yyyy-MM"
          )} (Completed + Cancelled): $${currencyFormatter.format(datum.y)} (${Math.floor(earnedRatio * 100)}% of target forecast)`;
        }}
        labelComponent={
          <VictoryTooltip
            cornerRadius={0}
            flyoutStyle={{ fill: "white" }}
            dy={({ index }) => {
              const idx = Number(index);
              return (monthlyDataNetEarnedSales[idx]?.cumulativeNetSales ?? 0) >
                (revenueTargetDollars / 12) * (idx + 1)
                ? 0
                : 40;
            }}
          />
        }
      />

      {/** X-axis = months */}
      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 15,
            paddingTop: 20,
            angle: 30,
            textAnchor: "left",
            verticalAnchor: "start",
          },
          ticks: {
            stroke: "#000000",
            size: 8,
          },
        }}
        tickValues={Interval.fromDateTimes(
          monthlyDataNetEarnedSales[0].startOfMonth,
          monthlyDataNetEarnedSales[0].startOfMonth.plus({ months: 12 })
        )
          .splitBy({ months: 1 })
          .map((x) => x.start.toSeconds())}
        tickFormat={(x) => DateTime.fromSeconds(x, { zone: 'America/Toronto' }).toFormat("MMMM")}
      />
      {/** Y-axis */}
      <VictoryAxis
        dependentAxis
        domain={[0, max ?? 0]}
        tickFormat={(x) => `${Math.floor((x / highTargetRevenueDollars) * 100)}%`}
      />
    </VictoryChart>
  );
};
