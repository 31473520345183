
import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Select, { OptionTypeBase } from "react-select";
import { css } from "twin.macro";

export const PopoverSelect = function PopoverSelect<
  SelectOptionType extends OptionTypeBase
>(props: {
  // initialValue: string;
  displayText: string | null | undefined | React.ReactNode;
  options: SelectOptionType[];
  supressEditIcon?: boolean;
  center?: boolean;
  selectProps?: React.ComponentProps<typeof Select>

  onSubmit: (
    event: React.FormEvent<HTMLDivElement>,
    newValue: SelectOptionType | null | undefined
  ) => void;
}) {
  const initialFocusRef = React.useRef(null);
  const [
    selectedOption,
    setSelectedOption,
  ] = useState<SelectOptionType | null>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Popover isLazy initialFocusRef={initialFocusRef}>
      <PopoverTrigger>
        <div
          css={css`
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: ${props.center ? "center" : "flex-start"};
          `}
        >
          {props.displayText}&nbsp;
          {props.supressEditIcon ? null : (
            <EditIcon
              className="cell-hover-visible"
              style={{ marginLeft: "auto" }}
            />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>Select the new value:</PopoverHeader>
        <PopoverBody>
          <Stack
            as="form"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsSubmitting(true);
              await props.onSubmit(e, selectedOption);
              setIsSubmitting(false);
            }}
          >
            <Select<SelectOptionType, false>
              isDisabled={isSubmitting}
              isMulti={false}
              closeMenuOnSelect={true}
              onChange={(selectedOption) => {
                setSelectedOption(selectedOption);
              }}
              options={props.options}
              ref={initialFocusRef}
              {...props.selectProps as any}
              menuPlacement="auto"
            />
            <Button isDisabled={isSubmitting} type="submit">
              {isSubmitting ? "submitting" : "submit"}
            </Button>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
