
import { gql, useQuery } from "@apollo/client";
import {
  ProjectInvoicesQuery,
  ProjectInvoicesQueryVariables
} from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { InvoicesTable } from "./InvoicesTable";

export const InvoiceFragment = gql`
  fragment InvoiceFragment on Invoice {
    id
    invoice_number
    amount_in_cents
    expense_type
    date_created
    payee_name
    notes
    project {
      id
      component_code
      name
    }
    contractor {
      id
      first_name
      last_name
    }
    files {
      id
      contentType
      fileName
      createdBy {
        id
      }
    }
  }
`;

export const ProjectInvoices = ({ projectId }: { projectId: string }) => {
  const { data, loading } = useQuery<
    ProjectInvoicesQuery,
    ProjectInvoicesQueryVariables
  >(
    gql`
      query ProjectInvoices($projectId: String!) {
        projects(where: { id: { equals: $projectId } }) {
          id
          invoices {
            id
            ...InvoiceFragment
          }
        }
      }
      ${InvoiceFragment}
    `,
    {
      variables: {
        projectId,
      },
    }
  );

  if (loading) return <FullSpinner />;

  return <InvoicesTable invoices={data?.projects[0].invoices ?? []} />;
};
