import { useParams } from "react-router-dom";
import { useState } from "react";
import constate from "constate";
import { useQuery } from "@apollo/client";
import { 
  ProjectedTime,
  GetProjectPricingDetailsByIdQueryResult,
  TimeEntry,
  Invoice,
  ProjectedExpense,
  PricingComment,
} from "../codegen/graphql-types";
import gql from "graphql-tag";

const GetProjectPricingDetailsById = gql`
  query GetProjectPricingDetailsById($projectId: String!) {
    project(where: { id: $projectId }) {
      id
      name
      last_synced_details_at
      budget
      project_status
      invoices {
        id
        expense_type
        payee_name
        amount_in_cents
        date_created
      }
      time_entries {
        id
        date
        minutes
        tasklist_id
        user_id
      }
      project_pricing {
        id
        price
        actual_cost
        currency
        projected_times {
          id
          tasklist_id
          role_id
          team_id
          projected_minutes
        }
        projected_expenses {
          id
          project_id
          project_pricing_id
          budget_in_cents
          expense_type
          payee_name
        }
        comments {
          id
          project_id
          project_pricing_id
          tasklist_id
          comment
          created_at
          created_by_id
          is_for_cogs
          created_by {
            id
            first_name
            last_name
            avatar_url
          }
        }
      }
    }
  }
`;

// Pick only the necessary fields from TimeEntry
export type BriefTimeEntry = Pick<TimeEntry, "id" | "date" | "minutes" | "tasklist_id" | "user_id">;
export type BriefProjectInvoice = Pick<Invoice, "id" | "expense_type" | "payee_name" | "amount_in_cents" | "date_created">;

export type ProjectPricingDetails = {
  id: string;
  name: string;
  last_synced_details_at: string; // Date e.g. "2021-09-01T00:00:00Z"
  budget?: number;
  project_status?: string;
  project_pricing?: {
    actual_cost: number;
    id: number;
    currency: string;
    price: number;
    project_id: string;
    projected_times: ProjectedTime[];
    projected_expenses: ProjectedExpense[];
    comments: PricingComment[];
  };
  invoices: BriefProjectInvoice[];
  time_entries: BriefTimeEntry[];
};

export type ProjectPricingDetailsDataResponse = ProjectPricingDetails | null;

export type PricingData = {
  projectId: string;  
  projectDetails: ProjectPricingDetailsDataResponse,
  projectDetailsLoading: boolean,
  projectDetailsError: GetProjectPricingDetailsByIdQueryResult["error"] | null;
  refetchProjectPricing: () => void,
}

const usePricingData = (): PricingData => {
  const { projectId } = useParams<{ projectId: string }>();

  const [projectDetails, setProjectDetails] = useState<ProjectPricingDetailsDataResponse>(null);
  const [projectDetailsError, setProjectDetailsError] = useState<GetProjectPricingDetailsByIdQueryResult["error"]>(undefined);
  
  // Original query for pricing data
  const { loading, refetch } = useQuery(GetProjectPricingDetailsById, {
    variables: { projectId },
    skip: !projectId, // Skip the query if projectId is undefined
    onCompleted: (data) => {
      setProjectDetails(data.project);
    },
    onError: (error) => {
      setProjectDetailsError(error);
    },
  });

  return {
    projectId: projectId ?? "",
    projectDetails,
    projectDetailsLoading: loading,
    projectDetailsError,
    refetchProjectPricing: refetch,
  };
};

export const [PricingDataProvider, usePricingDataContext] = constate(usePricingData);
