import React from "react";
import { useFilters, useTable } from "react-table";
import tw, { css } from "twin.macro";
import { EMPTY_ARRAY } from "./Charts/constants";
import type {
  NetSalesTableColumnDefinition,
  NetSalesTablePostProcessedRow,
} from "./types";

const defaultSortBy = [
  {
    id: "monthNumber",
    desc: false,
  },
];

export const NetSalesReportTable = ({
  columnDefinitions,
  postProcessedRows,
  isCumulative,
}: {
  columnDefinitions: NetSalesTableColumnDefinition[];
  postProcessedRows: NetSalesTablePostProcessedRow[];
  isCumulative: boolean;
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      // - @ts-expect-error
      columns: columnDefinitions,
      // - @ts-expect-error
      data: postProcessedRows ?? EMPTY_ARRAY,
      // - @ts-expect-error
      //   defaultColumn,
      autoResetFilters: false,
      autoResetSortBy: false,
      // autoResetGroupBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      initialState: {
        // hiddenColumns: initiallyHiddenColumns,
        // @ts-expect-error
        sortBy: defaultSortBy,
      },
    },
    useFilters
  );
  return (
    <table
      {...getTableProps()}
      css={[
        tw`text-sm`,
        css`
          .row-hover-visible {
            opacity: 0;
          }
          & tr:hover .row-hover-visible {
            opacity: 1;
          }

          .cell-hover-visible {
            opacity: 0;
          }
          & td:hover .cell-hover-visible {
            opacity: 1;
          }
        `,
      ]}
    >
      <thead
        css={css`
          &,
          & > tr > th {
            background: aliceblue;
            border-right: solid 1px #ddd;
            border-left: solid 1px #ddd;
          }
          border-top: solid 1px #ddd;
        `}
      >
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          const { key, ...rest } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...rest}>
              {headerGroup.headers.map((column) => {
                const { key, ...rest } = column.getHeaderProps();
                if ((column as any).showWhen === "cumulative" && !isCumulative)
                  return null;
                if (
                  (column as any).showWhen === "not_cumulative" &&
                  isCumulative
                )
                  return null;
                return (
                  <th
                    key={key}
                    {...rest}
                    css={css`
                  padding: 0.5rem;
                  top: 0;
                  /* backdrop-filter: blur(6px); */
                  ${
                    !column.placeholderOf &&
                    css`
                      border-bottom: solid 1px #ddd;
                    `
                  }
                  /* ${
                    headerGroups.length === 1 ||
                    headerGroups.length - 2 < headerGroupIndex
                      ? css`
                          position: sticky;
                        `
                      : ""
                  } */
                  border-left: solid 1px #ddd;
                  border-right: solid 1px #ddd;
                  box-sizing: border-box;

                  color: black;
                  font-weight: 500;
                `}
                  >
                    <div style={{ display: "flex" }}>
                      <>
                        {column.render("Header")}
                        {headerGroups.length === 1 ||
                          (headerGroups.length - 2 < headerGroupIndex && (
                            <span>
                              {
                                // @ts-expect-error
                                column.isSorted
                                  ? // @ts-expect-error
                                    column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : " ↕️"
                              }
                            </span>
                          ))}
                      </>
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      <tbody
        {...getTableBodyProps()}
        css={css`
          & > tr {
            content-visibility: auto;
            contain-intrinsic-size: 1000px;
            & > td {
              border: solid 1px #ddd;
              padding: 0.5rem;
            }
          }
        `}
      >
        {rows.map((row) => {
          prepareRow(row);
          const { key, ...rest } = row.getRowProps();
          return (
            <React.Fragment
              // @ts-expect-error
              key={key}
            >
              <tr
                {...rest}
                data-estimate-id={row.original.monthNumber}
              >
                {row.cells.map((cell) => {
                  const { key, ...rest } = cell.getCellProps();
                  if (
                    (cell.column as any).showWhen === "cumulative" &&
                    !isCumulative
                  )
                    return null;
                  if (
                    (cell.column as any).showWhen === "not_cumulative" &&
                    isCumulative
                  )
                    return null;

                  return (
                    <td
                      key={key}
                      {...rest}
                      style={{
                        textAlign:
                          typeof cell.value === "number" ||
                          (typeof cell.value === "string" &&
                            cell.value.startsWith("$"))
                            ? "right"
                            : "left",
                      }}
                    >
                      <>{cell.render("Cell")}</>
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};
