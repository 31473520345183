import React, { Flex, Spinner } from "@chakra-ui/react";

export const FullSpinner = () => (
  <Flex direction="column" alignItems="center" justifyContent="center" height="100%">
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  </Flex>
);
