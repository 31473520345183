import { useCallback, useRef, useState } from "react";

export const cancelledSymbol = Symbol("Cancelled");

export function usePromptState<
  PromptResults,
  PromptFunction extends (...args: unknown[]) => void = () => void
>({ stableOnPrompt }: { stableOnPrompt?: PromptFunction } = {}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const resolveRef = useRef<
    (data: PromptResults | typeof cancelledSymbol) => void
  >();
  const rejectRef = useRef<(data?: unknown) => void>();

  return {
    isOpen,
    prompt: useCallback(
      async (...args): Promise<PromptResults | typeof cancelledSymbol> => {
        stableOnPrompt?.(...args);
        setIsOpen(true);

        return new Promise((resolve, reject) => {
          resolveRef.current = resolve;
          rejectRef.current = reject;
        });
      },
      [stableOnPrompt]
    ),
    onCancel: useCallback(() => {
      resolveRef.current?.(cancelledSymbol);
      setIsOpen(false);
    }, [setIsOpen]),
    onResolve: useCallback(
      (resolveWith: PromptResults) => {
        resolveRef.current?.(resolveWith);
        setIsOpen(false);
      },
      [setIsOpen]
    ),
  };
}
