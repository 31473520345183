import {
  GetProjectRatesByProjectIdQueryResult,
  Team,
  Role
} from "../codegen/graphql-types";

// expected type of input
type ProjectRatesForPricing = GetProjectRatesByProjectIdQueryResult["data"];
// expected type of output
export type MeanProjectRatesByTeamAndRole = {
  team: Pick<Team, "id" | "teamName">;
  role: Pick<Role, "id" | "roleTitle">;
  meanRateInCents: number;
};

const getMeanProjectRatesByTeamAndRole = (projectRates: ProjectRatesForPricing): MeanProjectRatesByTeamAndRole[] | null => {
  
  const projectRatesByUser = projectRates ? projectRates.projectRatesByProjectId : null;

  // if there are no project rates, return null
  if (!projectRatesByUser) {
    return null;
  }

  // exclude any project rate where the rate_in_cents is 0 or person does not have a team or role
  const projectRatesByUsersWithTeamAndRole = projectRatesByUser.filter(projectRate => projectRate.rate_in_cents > 0 && projectRate.person.team && projectRate.person.role);

  // create an object to store the sum of all rates for each team and role
  const sumRatesByTeamAndRole: { [key: string]: { [key: string]: number[] } } = {};

  // iterate over each project rate
  projectRatesByUsersWithTeamAndRole.forEach(projectRate => {
    // get the team and role IDs
    const teamId = projectRate.person.team?.id;
    const roleId = projectRate.person.role?.id;

    // if the team ID is not in the object, add it
    if (teamId && !sumRatesByTeamAndRole[teamId]) {
      sumRatesByTeamAndRole[teamId] = {};
    }

    // if the role ID is not in the object, add it
    if (teamId && roleId && !sumRatesByTeamAndRole[teamId][roleId]) {
      sumRatesByTeamAndRole[teamId][roleId] = [];
    }

    if (teamId && roleId) {
      // add the rate to the array
      sumRatesByTeamAndRole[teamId][roleId].push(projectRate.rate_in_cents);
    }
  });

  // create an array to store the mean rates
  const meanRates: MeanProjectRatesByTeamAndRole[] = [];

  // iterate over each team
  Object.entries(sumRatesByTeamAndRole).forEach(([teamId, roles]) => {
    // iterate over each role
    Object.entries(roles).forEach(([roleId, rates]) => {
      // calculate the mean rate
      const meanRateInCents = Math.round(rates.reduce((acc, rate) => acc + rate, 0) / rates.length);

      // add the mean rate to the array
      meanRates.push({
        team: {
          id: teamId,
          teamName: projectRatesByUsersWithTeamAndRole.find(projectRate => projectRate.person.team?.id === teamId)?.person.team?.teamName || ""
        },
        role: {
          id: roleId,
          roleTitle: projectRatesByUsersWithTeamAndRole.find(projectRate => projectRate.person.role?.id === roleId)?.person.role?.roleTitle || ""
        },
        meanRateInCents
      });
    });
  });

  return meanRates;
}

export { getMeanProjectRatesByTeamAndRole };