
import tw, { css } from "twin.macro";
import { usePodTimeContext } from "../../helpers/usePodTimeContext";
import { FullSpinner } from "../../FullSpinner";
import { parseISO, format } from "date-fns";
import { Person, PersonAnnualTimeTarget } from "../../codegen/graphql-types";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { capacityCalculator } from "../capacityCalculator";
import { TimeSummaryRange } from "../Employee/TimeSummaryRange";
import { Capacity } from "../Employee/FluxCapacoMeter";
import {
  combineMinutesByCompany,
  MinutesByCompany,
} from "../../helpers/combineMinutesByCompany";
import { brandPalette } from "../../Financials/Performance/Charts/constants";
import { SlicedPieChart } from "../../components/SlicedPieChart";
import { filterBillableTime } from "../constants";
import { HoursOverTimeLineGraph } from "./HoursOverTimeLineGraph";
import { sumEntryMinutes } from "../dates";

export const PodTimeSummary = () => {
  const { lastThreeMonthsPodTime } = usePodTimeContext();

  if (lastThreeMonthsPodTime.loading) {
    return <FullSpinner />;
  }

  if (!lastThreeMonthsPodTime.variables) {
    return <div>Failed to fetch pod time entries. Please check the logs.</div>;
  }

  const startDate = format(
    parseISO(lastThreeMonthsPodTime.variables.start_date),
    "yyyy-MM-dd"
  );
  const endDate = format(
    parseISO(lastThreeMonthsPodTime.variables.end_date),
    "yyyy-MM-dd"
  );

  const podMembers = lastThreeMonthsPodTime.data?.me.pod[0].members;

  const PodPersonTimeSummary: Record<string, any>[] = podMembers.map(
    (person: Person) => {
      const annualTimeTarget: PersonAnnualTimeTarget = person.annual_time_targets[0];
      const totalTargetMinutesPerDay = annualTimeTarget.billableMinutes 
      + annualTimeTarget.nonBillableMinutes + (annualTimeTarget.innovationAndDevMinutes ?? 0)
      + (annualTimeTarget.professionalDevMinutes ?? 0) + (annualTimeTarget.operationalMinutes ?? 0)
      + (annualTimeTarget.salesAndMarketingMinutes ?? 0);
      
      const {
        targetMinutes,
        averageTotalHoursPerDay,
        totalTargetHoursPerDay,
        percentageOfTarget,
        totalWorkingDaysInRange,
      } = capacityCalculator({
        timeEntries: person.timeEntries,
        startDate,
        endDate,
        daysOff: person.daysOff,
        totalTargetMinutesPerDay,
      });

      const avgBillableHoursPerDay = (
        <TimeSummaryRange
          startDate={startDate}
          endDate={endDate}
          userTimeEntries={person.timeEntries}
          isBillable={true}
          targetForYear={person.annual_time_targets[0].billableMinutes}
          daysOff={person.daysOff}
        />
      );

      const avgNonBillableHoursPerDay = (
        <TimeSummaryRange
          startDate={startDate}
          endDate={endDate}
          userTimeEntries={person.timeEntries}
          isBillable={false}
          targetForYear={person.annual_time_targets[0].nonBillableMinutes}
          daysOff={person.daysOff}
        />
      );

      const avgTotalHoursPerDay = (
        <TimeSummaryRange
          startDate={startDate}
          endDate={endDate}
          userTimeEntries={person.timeEntries}
          isTotal={true}
          targetForYear={totalTargetMinutesPerDay}
          daysOff={person.daysOff}
        />
      );

      // Percentage of total avg hours per day / total annual time target in minutes
      const capacity = (
        <Capacity
          totalMinutes={person.timeEntries.reduce(sumEntryMinutes, 0)}
          startDate={startDate}
          endDate={endDate}
          totalWorkingDaysInRange={totalWorkingDaysInRange}
          averageTotalHoursPerDay={averageTotalHoursPerDay}
          totalTargetHoursPerDay={totalTargetHoursPerDay}
          percentageOfTarget={percentageOfTarget}
          targetMinutes={targetMinutes}
        />
      );

      // Pie chart of Pod member's billable time spent across the Pod's brands
      const THRESHOLD = 0.1; // Any slice that is less than 10% of the whole pie will be combined into an "Other" category
      const personBillableTimeEntries = person.timeEntries.filter(filterBillableTime);
      const wholePie: number = personBillableTimeEntries.reduce(sumEntryMinutes, 0);
      const personTotalMinutesByBrand = combineMinutesByCompany(
        personBillableTimeEntries,
        brandPalette,
        wholePie,
        THRESHOLD
      );
      const pieData = personTotalMinutesByBrand.map((data) => ({
        name: data.clientName,
        value: data.totalMinutes,
      }));
      const otherCategory = pieData.filter(
        (slice) => slice.value / wholePie < THRESHOLD
      );

      const pieChart = (
        <div css={tw`flex flex-col justify-center items-center`}>
          <SlicedPieChart
            // title={person.first_name}
            data={pieData}
            wholePie={wholePie}
            width={300}
            labelRadius={15}
          />
          <Tooltip
            label={
              <Stack>
                {otherCategory
                  .sort((a, b) => b.value - a.value)
                  .map((slice, i) => (
                    <Text key={i}>
                      {slice.name}:{" "}
                      {((slice.value / wholePie) * 100).toFixed(2)}%
                    </Text>
                  ))}
              </Stack>
            }
          >
            <span>{`${person.first_name} - Other*`}</span>
          </Tooltip>
        </div>
      );

      // Total Time entries for each brand for each of the last three months
      const dataForLargerSlices = personTotalMinutesByBrand.filter(
        (slice) => !slice.isOther
      );
      const dataForOtherSlice = personTotalMinutesByBrand.filter(
        (slice) => slice.isOther
      );
      // Combine all "Other" slices into one slice
      const otherSlice: MinutesByCompany = dataForOtherSlice.reduce(
        (acc, slice) => {
          acc.totalMinutes += slice.totalMinutes;
          // Combine minutes by date range (last 30, 60, 90 days)
          Object.keys(slice.minutesByDateRange).forEach((range) => {
            const rangeKey = range as keyof MinutesByCompany["minutesByDateRange"];

            if (!acc.minutesByDateRange[rangeKey]) {
              acc.minutesByDateRange[rangeKey] = slice.minutesByDateRange[rangeKey];
            } else {
              acc.minutesByDateRange[rangeKey] +=
                slice.minutesByDateRange[rangeKey];
            }
          });
          return {
            ...acc,
            clientName: "Other",
          };
        },
        {
          clientName: "Other",
          companyId: brandPalette.find((brand) => brand.name === "Other")?.id || "0",
          clientColour: brandPalette.find((brand) => brand.name === "Other")?.primary ||
            "gray",
          totalMinutes: 0,
          minutesByDateRange: {},
        } as MinutesByCompany
      );

      const clientsForLineGraph: MinutesByCompany[] = [
        ...dataForLargerSlices,
        otherSlice,
      ];

      // Line graph of Pod member's hours spent on each brand over the last 3 months
      const lineGraph = (
        <HoursOverTimeLineGraph
          clients={clientsForLineGraph}
          person={person}
        />
      );

      return {
        firstName: person.first_name,
        avgBillableHoursPerDay,
        avgNonBillableHoursPerDay,
        avgTotalHoursPerDay,
        capacity,
        pieChart,
        lineGraph,
      };
    }
  );

  const rows = [
    {
      title: "Hrs/Day",
      key: "firstName",
      cell: (value: any) => <strong>{value}</strong>,
    },
    {
      title: "Billable",
      key: "avgBillableHoursPerDay",
      cell: (value: any) => value,
    },
    {
      title: "Non-Billable",
      key: "avgNonBillableHoursPerDay",
      cell: (value: any) => value,
    },
    {
      title: "Total",
      key: "avgTotalHoursPerDay",
      cell: (value: any) => value,
    },
    {
      title: "Capacity",
      key: "capacity",
      cell: (value: any) => value,
    },
    {
      key: "pieChart",
      cell: (value: any) => value,
    },
    {
      key: "lineGraph",
      cell: (value: any) => value,
    },
  ];

  return (
    <section css={tw`w-full flex flex-col gap-y-3 items-center p-3`}>
      <table css={tw`w-full`}>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              <th css={[css`min-width:100px;`]}>{row.title}</th>
              {PodPersonTimeSummary.map(
                (member: Record<string, any>, j: number) => (
                  <td
                    key={j}
                    css={tw`flex-col justify-center items-center px-2 py-1 text-center`}
                  >
                    {row.cell(member[row.key])}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

