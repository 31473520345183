
import tw from "twin.macro";
import type { 
  DaysWorked, 
  ProjectHours, 
  Hours, 
  BillableVsNonBillableTime, 
  UtilizedVsNonUtilizedTime 
} from './TeamTimeTargets';
import { 
  Popover, 
  PopoverTrigger, 
  PopoverContent, 
  PopoverBody, 
  PopoverArrow, 
  PopoverCloseButton,
  Tooltip,
  Stack,
  Text
} from '@chakra-ui/react';
import { format } from "date-fns";
import { minutesToHours } from "../../helpers/time";

// To see this in UI, click on a cell in the "Days Worked (YTD)" column
export const DaysWorkedPopover = ({ values, name }: { values: DaysWorked, name: string }) => {
  const { 
    totalDaysInRange,
    workingDays, 
    weekendDays, 
    holidaysInRangeIncludingExtendedLongWeekends, 
    daysOffWithinRange, 
    sickDays, 
    vacationDays,
    otherDaysOff,
    startDate,
    endDate,
  } = values;
  return (
    <Popover>
      <PopoverTrigger>
        <div css={tw`cursor-pointer`}>
          {workingDays}
        </div>
      </PopoverTrigger>
      <PopoverContent sx={{ width: "min-content", backgroundColor: "#233B4D", color: "white" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <h3 css={tw`font-bold whitespace-nowrap`}>{name}</h3>
          <p css={tw`font-bold`}>From {format(startDate, "MMM dd yyyy")} to {format(endDate, "MMM dd yyyy")}</p>
          <p css={tw`font-bold`}>Of {`${totalDaysInRange} total days within date range:`}</p>
          <div css={tw`text-left whitespace-nowrap w-full`}>
            <p>&emsp;&bull; {`${workingDays} total days worked`}</p>
          </div>
          <div css={tw`text-left whitespace-nowrap w-full`}>
            <p css={`whitespace-nowrap`}>&emsp;&bull; {`${weekendDays + holidaysInRangeIncludingExtendedLongWeekends + daysOffWithinRange} total days off`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&bull; {`${weekendDays} weekend days`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&bull; {`${holidaysInRangeIncludingExtendedLongWeekends} holidays (including extended-long weekends)`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&bull; {`${daysOffWithinRange} days off for user:`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&emsp;&bull; {`${sickDays} days off sick`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&emsp;&bull; {`${vacationDays} vacation days`}</p>
            <p css={`whitespace-nowrap`}>&emsp;&emsp;&emsp;&bull; {`${otherDaysOff} other days off`}</p>
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

// To see this in UI, click on a cell in any of the "Utilized > Non-billable -> Actual YTD" columns (e.g. "Sales & Marketing, Innovation & Development, etc.")
export const UtilizedYtdTimeCell = ({ values, name }: { values: Hours, name: string }) => {
  const { label, totalMinutes, startDate, endDate, numberOfWorkingDays, numberOfDaysOff, projects, actual_ytd } = values;
  const value = actual_ytd >= 0.1 ? actual_ytd.toFixed(1) : "";
  return (
    <Popover>
      <PopoverTrigger>
        <div css={tw`cursor-pointer`}>
          {value}
        </div>
      </PopoverTrigger>
      <PopoverContent sx={{ width: "min-content", backgroundColor: "#233B4D", color: "white" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <h3 css={tw`font-bold`}>{name} - {label} (YTD)</h3>
          <h4 css={tw`font-bold`}>{`From ${format(startDate, "MMM dd yyyy")} to ${format(endDate, "MMM dd yyyy")}:`}</h4>
          <p css={tw`font-bold`}>{`${minutesToHours(totalMinutes)} hours logged`}</p>
          <div css={tw`text-left w-full`}>
            {projects
              .sort((a: ProjectHours, b: ProjectHours) => b.totalHours - a.totalHours)
              .map((project: ProjectHours) => (
              <p css={tw`whitespace-nowrap flex justify-between w-full`} key={project.projectName}>
                <span>&nbsp;&nbsp;{project.projectName}</span>&nbsp;<span>({project.totalHours.toFixed(1)} hours)</span>
              </p>
            ))}
          </div>
          <p>&divide;</p>
          <p css={tw`font-bold`}>{`${numberOfWorkingDays} total working days (subtracted ${numberOfDaysOff} days off for user)`}</p>
          <p>= {value} hours/day</p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
};

// Click on a cell in the "Utilization Rates" column -> Billable vs Non-billable
export const BillableVsNonBillableRate = ({ values, name }: { values: BillableVsNonBillableTime, name: string }) => {
  const { label, totalHours, startDate, endDate, billableHours, nonBillableHours, rate } = values;
  const value = rate > 0 ? `${rate}%` : "";
  return (
    <Popover>
      <PopoverTrigger>
        <div css={tw`cursor-pointer`}>
          {value}
        </div>
      </PopoverTrigger>
      <PopoverContent sx={{ width: "min-content", backgroundColor: "#233B4D", color: "white" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <h3 css={tw`font-bold whitespace-nowrap`}>{name} - {label}</h3>
          <h4 css={tw`font-bold whitespace-nowrap`}>{`From ${format(startDate, "MMM dd yyyy")} to ${format(endDate, "MMM dd yyyy")}:`}</h4>
          <p css={tw`font-bold`}>{`${totalHours} hours logged`}</p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&bull; {`${billableHours} billable hours logged`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&bull; {`${nonBillableHours} non-billable hours logged`}</span>
          </p>
          <p>Rate: {billableHours} &divide; {totalHours} = {value}</p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

// Click on a cell in the "Utilization Rates" column -> Utilized vs Non-utilized 
export const UtilizedVsNonUtilizedRate = ({ values, name }: { values: UtilizedVsNonUtilizedTime, name: string }) => {
  const { 
    label, 
    totalHours, 
    startDate, 
    endDate,
    utilizedHours,
    billableHours,
    salesMarketingHours,
    innovationDevelopmentHours,
    operationalHours,
    professionalDevelopmentHours,
    internalAdministrativeHours,
    rate
  } = values;
  const value = rate > 0 ? `${rate}%` : "";
  return (
    <Popover>
      <PopoverTrigger>
        <div css={tw`cursor-pointer`}>
          {value}
        </div>
      </PopoverTrigger>
      <PopoverContent sx={{ width: "min-content", backgroundColor: "#233B4D", color: "white" }}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <h3 css={tw`font-bold whitespace-nowrap`}>{name} - {label}</h3>
          <h4 css={tw`font-bold whitespace-nowrap`}>{`From ${format(startDate, "MMM dd yyyy")} to ${format(endDate, "MMM dd yyyy")}:`}</h4>
          <p css={tw`font-bold`}>{`${totalHours} hours logged`}</p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&bull; {utilizedHours} utilized hours logged:</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${billableHours} billable hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${salesMarketingHours} sales & marketing hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${innovationDevelopmentHours} innovation & development hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${operationalHours} operational hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${professionalDevelopmentHours} professional development hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&bull; {`${internalAdministrativeHours} non-utilized hours`}</span>
          </p>
          <p css={tw`text-left whitespace-nowrap w-full`}>
            <span>&emsp;&emsp;&bull; {`${internalAdministrativeHours} internal administrative hours`}</span>
          </p>
          <p>Rate: {utilizedHours} &divide; {totalHours} = {value}</p>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

// Hover over a cell in the Utilized Time > Billable > Actual YTD column
export const BillableTooltip = ({ values }: { values: Hours }) => {
  const { label, totalMinutes, startDate, endDate, numberOfWorkingDays, numberOfDaysOff, actual_ytd } = values;
  const value = actual_ytd >= 0.1 ? actual_ytd.toFixed(1) : "";
  return (
    <Tooltip 
      label={
        <Stack>
          <Text>{`From ${format(startDate, "MMM dd yyyy")} to ${format(endDate, "MMM dd yyyy")}:`}</Text>
          <Text>{`${minutesToHours(totalMinutes)} ${label} hours logged`}</Text>
          <Text>&divide;</Text>
          <Text>{`${numberOfWorkingDays} total working days (subtracted ${numberOfDaysOff} days off for user)`}</Text>
          <Text>= {value} {label} hours/day</Text>
        </Stack>
      }>
      <div css={tw`cursor-pointer`}>
        {value}
      </div>
    </Tooltip>
  )
}