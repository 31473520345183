
import tw, { css } from "twin.macro";
import { ReactNode } from "react";

type BannerProps = {
  children: ReactNode
}

export const Banner = ({ children }: BannerProps) => {
  return (
    <div
      css={[
        tw`w-full font-bold text-xl text-center mt-4 py-2`,
        css`
          background: linear-gradient(45deg, #dbeafe00, #dbeafe9e, #dbeafe00);
        `,
      ]}
    >
      {children}
    </div>
  );
}