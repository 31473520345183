
import { Button } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import Select from "react-select";
import {
  AdminPagePersonFragment,
  useAdminAddPodMemberMutation,
  useAdminPagePeopleQuery,
} from "../../codegen/graphql-types";

export const AddMemberToPod = ({
  podId,
  existingMembers,
}: {
  podId: string;
  existingMembers: Array<AdminPagePersonFragment>;
}) => {
  const [shouldShowDropdown, setShouldShowDropdown] = useState(false);
  const [addPodMember] = useAdminAddPodMemberMutation();
  const { data: peopleData } = useAdminPagePeopleQuery();

  return (
    <>
      {shouldShowDropdown ? (
        <Select<{
          value: string;
          label: string;
          rawValue: AdminPagePersonFragment;
        }>
          autoFocus
          onBlur={() => setShouldShowDropdown(false)}
          menuIsOpen
          onChange={(selectedOption) => {
            if (!selectedOption) return;
            addPodMember({
              variables: {
                personId: selectedOption?.value,
                podId: podId,
              },
              optimisticResponse: {
                addPodMember: {
                  __typename: "Pod",
                  id: podId,
                  members: existingMembers.concat(selectedOption.rawValue),
                },
              },
            });
            setShouldShowDropdown(false);
          }}
          options={_.orderBy(peopleData?.people, (x) => x.first_name)
            .filter(
              (person) =>
                !existingMembers
                  .map((podMember) => podMember.id)
                  .includes(person.id)
            )
            .flatMap((person) => {
              return {
                value: person.id,
                label: `${person.first_name} ${person.last_name}`,
                rawValue: person,
              };
            })}
        />
      ) : (
        <Button
          variant="ghost"
          onClick={() => setShouldShowDropdown(true)}
          size="sm"
          disabled={shouldShowDropdown}
        >
          Add member
        </Button>
      )}
    </>
  );
};