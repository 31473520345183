import _ from "lodash";
import { matchSorter } from "match-sorter";
import { getYear } from "date-fns/esm";

export const filterTypes = {
  // Add a new fuzzyTextFilterFn filter type.
  fuzzyText: fuzzyTextFilterFn,
  // Or, override the default text filter to use
  // "startWith"
  text: (rows: any[], id: string | number, filterValue: any) => {
    return rows.filter((row: any) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },

  // multi-select filter
  multiple: (rows: any[], id: string | number, filterValue: string | any[]) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined ? filterValue.includes(rowValue) : true;
    });
  },

  populatedOrNot: (
    rows: any[],
    id: string | number,
    filterValue: "all" | "populated" | "blank"
  ) => {
    if (filterValue === "all") return rows;
    console.log("populatedOrNot id", id);
    const [populatedRows, blankRows] = _.partition(
      rows,
      (row) => row.values[id]?.length
    );
    console.log({ populatedRows, blankRows, rows });
    if (filterValue === "blank") {
      return blankRows;
    }
    return populatedRows;
  },

  dateIsSameYear: (rows: any[], id: string | number, filterValue: string) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? getYear(new Date(rowValue)).toString() === filterValue
        : true;
    });
  },
};

function fuzzyTextFilterFn(rows: any, id: string | number, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: string) => !val;
