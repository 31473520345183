
import { gql, useQuery } from "@apollo/client";
import type {
  InvoicesByInvoiceNumberQuery,
  InvoicesByInvoiceNumberQueryVariables,
} from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { InvoicesTable } from "./InvoicesTable";
import { InvoiceFragment } from "./ProjectInvoices";

export const InvoicesByInvoiceNumber = ({ invoiceNumber }: { invoiceNumber: string }) => {
  const { data, loading } = useQuery<
    InvoicesByInvoiceNumberQuery,
    InvoicesByInvoiceNumberQueryVariables
  >(
    gql`
      query InvoicesByInvoiceNumber($where: invoiceWhereInput) {
        invoices(where: $where) {
          ...InvoiceFragment
        }
      }
      ${InvoiceFragment}
    `,
    {
      variables: {
        where: {
          invoice_number: {
            contains: invoiceNumber,
          },
        },
      },
    }
  );

  if (loading) return <FullSpinner />;

  return <InvoicesTable invoices={data?.invoices ?? []} invoiceNumberHighlight={invoiceNumber} />;
};
