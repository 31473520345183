import { useState } from "react";
// import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  // Input,
  Box,
  Stack,
  // Tooltip,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { uniq } from "lodash";
import tw from "twin.macro";
import {
  // useAdminAssignRoleMutation,
  // useAdminAssignTeamMutation,
  useAdminAssignRoleAndTeamToUserMutation, // assigning the same role and team to one user
  useAdminAssignRoleAndTeamToUsersMutation, // assigning the same role and team to multiple users
  AdminGetAllRolesQuery,
  AdminGetAllTeamsQuery,
  Person,
} from "../../codegen/graphql-types";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import AllUsers from "./AllUsers";
import { InfoIcon } from "@chakra-ui/icons";

const UserRoleAndTeamAssignment = (props: {
  rolesData: AdminGetAllRolesQuery;
  teamsData: AdminGetAllTeamsQuery;
  refetchRoles: () => void;
  refetchTeams: () => void;
}) => {
  const toast = useToast();
  const peopleQuery = usePeopleContext();
  const {
    data: peopleData,
    loading: peopleLoading,
    error: peopleError,
    refetch: refetchPeople,
  } = peopleQuery;
  // const [assignRole] = useAdminAssignRoleMutation();
  // const [assignTeam] = useAdminAssignTeamMutation();
  const [assignRoleAndTeam] = useAdminAssignRoleAndTeamToUserMutation();
  const [assignRoleAndTeamToUsers] = useAdminAssignRoleAndTeamToUsersMutation();

  // Admin user selects either assign role & team per user OR assign for all users of a certain Job title

  // Radio option: For assigning the same role and team to one user
  const [assignByUser, setAssignByUser] = useState(true);
  // Radio option: For assigning the same role and team to all users of a certain job title
  const [assignByJobTitle, setAssignByJobTitle] = useState(false);
  
  // Toggle option: For showing a table of all users and their roles and teams
  const [showAll, setShowAll] = useState(false);
  // Toggle option: Including only people with a company of Maven Medical Marketing Inc.
  const [showMavenOnly, setShowMavenOnly] = useState(true);

  // For assigning the same role and team to individual users
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null);

  // For assigning the same role and team to all users of a certain job title
  const [selectedJobTitle, setSelectedJobTitle] = useState<string | null>(null);
  const [selectedRoleIdForJobTitle, setSelectedRoleIdForJobTitle] = useState<string | null>(null);
  const [selectedTeamIdForJobTitle, setSelectedTeamIdForJobTitle] = useState<string | null>(null);

  if (!props.rolesData.roles || !props.teamsData.teams) {
    return <div>Loading roles and teams...</div>;
  }

  if (peopleLoading) {
    return <div>Loading people data...</div>;
  }

  if (!peopleData || peopleError) {
    console.log(peopleError);
    return <div>Error loading people data</div>;
  }

  // For now we will assign roles and teams by person
  const people = [...peopleData.people] as Person[];
  
  // const notMavenPeople = people.filter((person) => person.company !== "Maven Medical Marketing Inc.");
  // console.log("notMavenPeople: ", notMavenPeople);

  // Don't filter people by company because some users who used to work at Maven are still in the database
  // And we need to assign them roles and teams because they have time entries on projects (needed for project pricing)
  const filteredPeople = people
    .filter((person) => showMavenOnly ? person.company === "Maven Medical Marketing Inc." : true)
    .sort((a, b) => {
      // if person has first_name, sort by first_name
      if (a.first_name && b.first_name) {
        if (a.first_name < b.first_name) return -1;
        if (a.first_name > b.first_name) return 1;
        return 0;
      } else {
        return 0;
      }
    });

  const uniqueJobTitles: string[] = uniq(
    filteredPeople
      .sort((a, b) => {
        // if both person a and b have a team name, sort by team name
        if (a.team && b.team) {
          if (a.team.teamName < b.team.teamName) return -1;
          if (a.team.teamName > b.team.teamName) return 1;
          return 0;
        } else {
          // if person a has a team name but person b does not, sort person a first
          if (a.team && !b.team) return -1;
          // if person b has a team name but person a does not, sort person b first
          if (!a.team && b.team) return 1;
          // if neither person a nor b have a team name, sort by job title
          if (a.title < b.title) return -1;
          if (a.title > b.title) return 1;
          return 0;
        }
      })
      .filter((person) => person.title)
      .map((person: any) => person.title)
  );

  return (
    <>
      <Heading size="lg" color="#e535ab">
        User Role & Team Assignment
      </Heading>
      
      {/* Radio Select either assign role & team per user OR assign for all users of a certain Job title */}
      <RadioGroup 
        defaultValue="user"
        onChange={(value) => {
        if (value === "user") {
          setAssignByUser(true);
          setAssignByJobTitle(false);
        } else {
          setAssignByUser(false);
          setAssignByJobTitle(true);
        }
      }}>
        <Stack direction="row">
          <Radio value="user">Assign by User</Radio>
          <Radio value="jobTitle">Assign the same role and team by Job Title</Radio>
          {/* <Radio value="allUsers">Show All Users</Radio> */}
        </Stack>
      </RadioGroup>


      {/* Assign roles and teams to individual Users */}
      {assignByUser && (
        <Box css={tw`flex gap-x-6 items-end`}>
          {/* User Select */}
          <FormControl width="fit-content">
            <FormLabel>Select User</FormLabel>
            <Select
              placeholder="Select User"
              value={selectedUserId ?? ""}
              onChange={(e) => {
                console.log(e.target.value);
                const selectedUser = filteredPeople.find((person) => person.id === e.target.value);
                if (!selectedUser) {
                  setSelectedUserId(null);
                  setSelectedRoleId(null);
                  setSelectedTeamId(null);
                  return;
                };
                const selectedRole = selectedUser.role?.id;
                const selectedTeam = selectedUser.team?.id;
                setSelectedUserId(e.target.value);
                setSelectedRoleId(selectedRole ?? "");
                setSelectedTeamId(selectedTeam ?? "");
              }}
            >
              {filteredPeople.map((person) => (
                <option key={person.id} value={person.id}>
                  {person.first_name} {person.last_name} ({person.email_address})
                </option>
              ))}
            </Select>
          </FormControl>
          {/* Role Select */}
          <FormControl width="fit-content">
            <FormLabel>Select Role</FormLabel>
            <Select
              value={selectedRoleId ?? ""} 
              placeholder="Select Role"
              onChange={(e) => {
                setSelectedRoleId(e.target.value);
              }}
            >
              {props.rolesData.roles
                ? props.rolesData.roles.map((role) => {
                    if (!role) return null;
                    return (
                      <option key={role.id} value={role.id}>
                        {role.roleTitle}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          {/* Team Select */}
          <FormControl width="fit-content">
            <FormLabel>Select Team</FormLabel>
            <Select
              value={selectedTeamId ?? ""}
              placeholder="Select Team"
              onChange={(e) => {
                setSelectedTeamId(e.target.value);
              }}
            >
              {props.teamsData.teams
                ? props.teamsData.teams.map((team) => {
                    if (!team) return null;
                    return (
                      <option key={team.id} value={team.id}>
                        {team.teamName}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          {/* Submit Button */}
          <Button
            isDisabled={!selectedUserId || !selectedRoleId || !selectedTeamId}
            onClick={() => {
              // assign role and team to user based on state
              if (!selectedUserId) return;
              if (!selectedRoleId || !selectedTeamId) return;

              assignRoleAndTeam({
                variables: {
                  userId: selectedUserId,
                  roleId: selectedRoleId,
                  teamId: selectedTeamId,
                },
                onCompleted: () => {
                  refetchPeople();
                  toast({
                    title: "Role & Team Assigned",
                    description: `Successfully assigned role and team to user`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                },
                onError: (error) => {
                  console.error(error);
                  toast({
                    title: "Error",
                    description: "Failed to assign role and team to user",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              })
            }}
          >Assign Role & Team</Button>
        </Box>
      )}

      {/* Assign roles and teams to all Users of a certain Job Title */}
      {assignByJobTitle && (
        <Box css={tw`flex gap-x-6 items-end`}>
          {/* Job Title Select */}
          <FormControl width="fit-content">
            <FormLabel>Select Job Title</FormLabel>
            <Select
              placeholder="Select Job Title"
              value={selectedJobTitle ?? ""}
              onChange={(e) => {
                setSelectedJobTitle(e.target.value);
              }}
            >
              {uniqueJobTitles.map((title, i) => (
                <option key={i} value={title}>
                  {title}
                </option>
              ))}
            </Select>
          </FormControl>
          {/* Role Select */}
          <FormControl width="fit-content">
            <FormLabel>Select Role</FormLabel>
            <Select
              value={selectedRoleIdForJobTitle ?? ""} 
              placeholder="Select Role"
              onChange={(e) => {
                setSelectedRoleIdForJobTitle(e.target.value);
              }}
            >
              {props.rolesData.roles
                ? props.rolesData.roles.map((role) => {
                    if (!role) return null;
                    return (
                      <option key={role.id} value={role.id}>
                        {role.roleTitle}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          {/* Team Select */}
          <FormControl width="fit-content">
            <FormLabel>Select Team</FormLabel>
            <Select
              value={selectedTeamIdForJobTitle ?? ""}
              placeholder="Select Team"
              onChange={(e) => {
                setSelectedTeamIdForJobTitle(e.target.value);
              }}
            >
              {props.teamsData.teams
                ? props.teamsData.teams.map((team) => {
                    if (!team) return null;
                    return (
                      <option key={team.id} value={team.id}>
                        {team.teamName}
                      </option>
                    );
                  })
                : null}
            </Select>
          </FormControl>
          {/* Submit Button */}
          <Button
            isDisabled={!selectedJobTitle || !selectedRoleIdForJobTitle || !selectedTeamIdForJobTitle}
            onClick={() => {
              // assign role and team to all users of a certain job title based on state
              if (!selectedJobTitle) return;
              if (!selectedRoleIdForJobTitle || !selectedTeamIdForJobTitle) return;
              const selectedRoleTitle = props.rolesData.roles?.find((role) => role ? role.id === selectedRoleIdForJobTitle : false)?.roleTitle;
              const selectedTeamName = props.teamsData.teams?.find((team) => team ? team.id === selectedTeamIdForJobTitle : false)?.teamName;
              const usersWithJobTitle = filteredPeople.filter((person) => person.title === selectedJobTitle);
              const userIds = usersWithJobTitle.map((user) => user.id);

              assignRoleAndTeamToUsers({
                variables: {
                  userIds,
                  roleId: selectedRoleIdForJobTitle,
                  teamId: selectedTeamIdForJobTitle,
                },
                onCompleted: () => {
                  refetchPeople();
                  toast({
                    title: "Role & Team Assigned",
                    description: `Successfully assigned ${selectedRoleTitle} and ${selectedTeamName} to all users with job title: ${selectedJobTitle}`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                },
                onError: (error) => {
                  console.error(error);
                  toast({
                    title: "Error",
                    description: "Failed to assign role and team to all users with job title",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                }
              })
            }}
          >Assign Role & Team to All Users</Button>
        </Box>
      )}
        
      {/* Show All Users */}  
      <Box css={tw`flex gap-x-6 items-end`}>
        <Button 
          css={tw`max-w-max mt-4`}
          size="sm" onClick={() => { setShowAll(!showAll) }}>
            {showAll ? "Hide" : "Show"} all Users
        </Button>

        {/* Checkbox for showing only Maven Medical Marketing Inc. users */}
        <Checkbox
          isChecked={showMavenOnly}
          onChange={(e) => setShowMavenOnly(e.target.checked)}
        >
          Show only Maven Medical Marketing Inc.
          <Tooltip label="This filters users who have their Company set to Maven Medical Marketing Inc. in Teamwork">
            <InfoIcon color="gray.300" ml={2} />
          </Tooltip>
        </Checkbox>
      </Box>

      {/* Show All Users Table */}
      {showAll && (
          <Box>
            <AllUsers people={filteredPeople} />
          </Box>
        )}
    </>
  );
};

export default UserRoleAndTeamAssignment;
