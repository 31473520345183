// import { TimeEntry } from "../../codegen/graphql-types";
import { FlexibleTimeEntry } from "../../Time/Employee/TimeSummaryRow";
import { minutesToHours } from "../../helpers/time";
import { ProjectHours } from "./TeamTimeTargets";

type TimeEntryWithProject = FlexibleTimeEntry & { project: { name: string } };

// A function that takes an array of TimeEntry objects
// Returns an array of projects by project name and the total number of hours
export default function groupTimeEntriesByProject(timeEntries: TimeEntryWithProject[]): ProjectHours[] {
  // Create a new object to store the grouped time entries
  const groupedTimeEntries: { [key: string]: TimeEntryWithProject[] } = {};
  // Loop through each time entry
  timeEntries.forEach((timeEntry) => {
    // Get the project name of the time entry
    const projectName = timeEntry.project.name;
    // Check if the project ID is already in the groupedTimeEntries object
    if (projectName in groupedTimeEntries) {
      // If it is, push the time entry to the existing array
      groupedTimeEntries[projectName].push(timeEntry);
    } else {
      // If it's not, create a new array with the time entry
      groupedTimeEntries[projectName] = [timeEntry];
    }
  });
  
  const projectHours: ProjectHours[] = Object.keys(groupedTimeEntries).map((projectName) => {
    const projectTimeEntries = groupedTimeEntries[projectName];
    const totalMinutes = projectTimeEntries.reduce((total, entry) => total + entry.minutes, 0);
    return {
      projectName,
      totalHours: minutesToHours(totalMinutes),
    };
  });

  return projectHours;
}