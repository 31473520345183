
import tw, { css } from "twin.macro";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { FullSpinner } from "../../FullSpinner";
import { Banner } from "../../components/Banner";
import { PodNetSalesTimeComparison } from "./PodNetSalesTimeComparison";
import { PodTimeSummary } from "./PodTimeSummary";

export const TimeForPod = () => {
  const peopleData = usePeopleContext();
  const loggedInPersonPods = peopleData.data?.me?.pods ?? [];
  const podSeniorBrandMavenLead = loggedInPersonPods?.length
    ? loggedInPersonPods[0].senior_brand_maven_lead
    : null;
  const podId = loggedInPersonPods?.length ? loggedInPersonPods[0].id : null;

  return (
    <section css={tw`flex flex-col gap-y-3 items-center p-3 h-full`}>
      {peopleData.loading && <FullSpinner />}
      {!peopleData.loading && !podId && !podSeniorBrandMavenLead && (
        <div css={tw`flex items-center text-center h-full`}>
          You are not assigned to a pod.
        </div>
      )}
      {podId && podSeniorBrandMavenLead && (
        <>
          <Banner>
            <h2 css={tw`font-bold text-xl`}>
              % of Net Sales (based on split) vs. % of Time Spent (year to date)
            </h2>
          </Banner>
          <PodNetSalesTimeComparison
            podId={podId}
            podSeniorBrandMavenLead={podSeniorBrandMavenLead}
          />
          <Banner>
            <h2 css={tw`font-bold text-xl`}>
              Pod Time Summary (Last 3 Months)
            </h2>
          </Banner>
          <PodTimeSummary />
        </>
      )}
    </section>
  );
};
