import { CloseIcon, DeleteIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import _ from "lodash";
import tw from "twin.macro";
import {
  useAdminCreatePodMutation,
  useAdminDeletePodMutation,
  useAdminPagePodsQuery,
  useAdminRemovePodMemberMutation,
  useAdminUpdatePodMutation,
} from "../../codegen/graphql-types";
import { PopoverInput } from "../../helpers/PopoverInput";
import { PopoverSelect } from "../../helpers/PopoverSelect";
import { AddMemberToPod } from "./AddMemberToPod";

const Pods = () => {
  const { data: podsData, loading } = useAdminPagePodsQuery();

  const [createPod] = useAdminCreatePodMutation();
  const [updatePod] = useAdminUpdatePodMutation();
  const [deletePod] = useAdminDeletePodMutation();
  const [removePodMember] = useAdminRemovePodMemberMutation();

  const OPTIMISTIC_TEMP_PREFIX = "@@TEMP_ID@@";

  return (
    <>
      <Heading size="lg" color="#e535ab">
        Pods
      </Heading>
      {loading && (
        <Stack direction="row" css={tw`gap-10 flex-wrap px-6 py-2`}>
          Loading Pods data...
        </Stack>
      )}
      <Stack direction="row" css={tw`gap-10 flex-wrap px-6 py-2`}>
        {podsData?.pods.length ? (
          podsData?.pods
            .filter((pod) => pod.name !== "Stefko")
            .map((pod) => {
              const isOptimistic = pod.id.startsWith(OPTIMISTIC_TEMP_PREFIX);
              return (
                <div
                  key={pod.id}
                  css={[isOptimistic && tw`opacity-50`, tw`w-[calc(100% / 4)]`]}
                  className="group"
                >
                  <div css={tw`flex`} className="group">
                    <Heading size="md">
                      <PopoverInput
                        supressEditIcon
                        initialValue={pod.name}
                        displayText={
                          <span css={tw`relative`}>
                            <EditIcon
                              css={tw`absolute -right-4 bottom-1 opacity-0 group-hover:opacity-100`}
                              w="3"
                              h="3"
                            />
                            {pod.name}
                          </span>
                        }
                        type="string"
                        onSubmit={async (e, newValue) => {
                          await updatePod({
                            variables: {
                              updateOnePodWhere: { id: pod.id },
                              updateOnePodData2: { name: { set: newValue } },
                            },
                            optimisticResponse: {
                              updateOnePod: {
                                id: pod.id,
                                name: newValue,
                                members: pod.members,
                              },
                            },
                          });
                        }}
                      />
                    </Heading>
                    <Tooltip label={`Delete ${pod.name}`}>
                      <Button
                        size="xs"
                        variant="ghost"
                        ml="6"
                        onClick={() => {
                          deletePod({
                            variables: { where: { id: pod.id } },
                            update(cache) {
                              cache.modify({
                                fields: {
                                  pods: (pods, { readField }) =>
                                    pods.filter(
                                      (cachedPod: any) =>
                                        readField("id", cachedPod) !== pod.id
                                    ),
                                },
                              });
                            },
                            optimisticResponse: {
                              deleteOnePod: {
                                __typename: "Pod",
                                id: pod.id,
                              },
                            },
                          });
                        }}
                        disabled={isOptimistic}
                      >
                        <DeleteIcon
                          css={tw`opacity-0 group-hover:opacity-100`}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  <div css={tw`flex flex-row`}>
                    <Tooltip
                      label={
                        <>
                          Projects with a "Pod Lead" value of "
                          <strong>
                            {pod.senior_brand_maven_lead ?? "Unassigned"}
                          </strong>
                          " will count toward this pod's revenue forecast.
                        </>
                      }
                    >
                      <div css={tw`flex flex-row items-center`}>
                        Pod Lead <InfoIcon w="3" ml="1" />
                      </div>
                    </Tooltip>
                    :&nbsp;
                    <PopoverSelect
                      displayText={pod.senior_brand_maven_lead ?? "Unassigned"}
                      options={podsData.senior_brand_maven_lead.map((x) => ({
                        value: x,
                        label: x,
                      }))}
                      onSubmit={(e, selectedOption) => {
                        const newValue = selectedOption?.value;
                        if (!newValue) return;
                        return updatePod({
                          variables: {
                            updateOnePodWhere: { id: pod.id },
                            updateOnePodData2: {
                              senior_brand_maven_lead: { set: newValue },
                            },
                          },
                          optimisticResponse: {
                            updateOnePod: {
                              id: pod.id,
                              name: pod.name,
                              senior_brand_maven_lead: newValue,
                              members: pod.members,
                            },
                          },
                        });
                      }}
                    />
                  </div>
                  <Stack direction="column" mt={4}>
                    {_.orderBy(
                      pod.members,
                      [
                        (person) =>
                          person.title.toLowerCase().includes("senior")
                            ? 100
                            : 0,
                        (person) => person.first_name,
                      ],
                      ["desc", "asc"]
                    ).map((person) => (
                      <Stack
                        direction="row"
                        align="center"
                        css={tw`gap-2`}
                        key={person.id}
                        className="group"
                      >
                        <Avatar size="sm" src={person.avatar_url} />
                        <span>
                          {person.first_name} {person.last_name}
                        </span>
                        <Tooltip
                          label={`Remove ${person.first_name} ${person.last_name} from ${pod.name}`}
                        >
                          <Button
                            size="xs"
                            variant="ghost"
                            ml={2}
                            onClick={() => {
                              removePodMember({
                                variables: {
                                  podId: pod.id,
                                  personId: person.id,
                                },
                                optimisticResponse: {
                                  removePodMember: {
                                    __typename: "Pod",
                                    id: pod.id,
                                    members: pod.members.filter(
                                      (member) => member.id !== person.id
                                    ),
                                  },
                                },
                              });
                            }}
                            disabled={isOptimistic}
                          >
                            <CloseIcon
                              css={tw`opacity-0 group-hover:opacity-100`}
                            />
                          </Button>
                        </Tooltip>
                      </Stack>
                    ))}
                    <div css={tw`flex flex-col opacity-0 hover:opacity-100`}>
                      <AddMemberToPod
                        podId={pod.id}
                        existingMembers={pod.members}
                      />
                    </div>
                  </Stack>
                </div>
              );
            })
        ) : (
          <div css={tw`mt-1`}>No Pods have yet been created</div>
        )}
        {!loading && (
          <form
            css={tw`self-start`}
            onSubmit={(e) => {
              e.preventDefault();
              const podName = e.currentTarget.podname.value;
              createPod({
                variables: { data: { name: podName } },
                update(cache, response) {
                  cache.modify({
                    fields: {
                      pods: (pods) => pods.concat(response.data?.createOnePod),
                    },
                  });
                },
                optimisticResponse: {
                  createOnePod: {
                    __typename: "Pod",
                    id: `${OPTIMISTIC_TEMP_PREFIX}:${podName}`,
                    name: podName,
                    members: [],
                  },
                },
              });
              e.currentTarget.podname.value = "";
            }}
          >
            <FormControl isRequired size="xs">
              <FormLabel>Create a Pod</FormLabel>
              <Input
                size="xs"
                required
                name="podname"
                id="podname"
                aria-label="Pod Name"
              />
            </FormControl>
          </form>
        )}
      </Stack>
    </>
  );
};

export default Pods;
