import holidays2019 from "./holidays2019.json";
import holidays2020 from "./holidays2020.json";
import holidays2021 from "./holidays2021.json";
import holidays2022 from "./holidays2022.json";
import holidays2023 from "./holidays2023.json";
import holidays2024 from "./holidays2024.json";

export const statutoryHolidays = [
  ...holidays2019,
  ...holidays2020,
  ...holidays2021,
  ...holidays2022,
  ...holidays2023,
  ...holidays2024,
];
