import tw from "twin.macro";
import { useMutation } from "@apollo/client";
import {
  Button,
  useToast,
  Select,
  Input,
  Heading,
} from "@chakra-ui/react";
import { PopoverInput } from "../../../helpers/PopoverInput";
import { useState } from "react";
import { RuntimeConfig } from "../../../RuntimeConfig";
import { useAuthContext } from "../../../useAuth";
import { useQuickbooksAuthContext } from "../../../useQuickbooksAuth";
import LoginWithQuickbooks from "../../../QuickBooks/LoginWithQuickbooks";
import LogoutFromQuickbooks from "../../../QuickBooks/LogoutFromQuickbooks";
import * as QBO from "../../../../../server/lib/quickbooks/types";
import LineItemsTable from "../LineItemsTable";
import SendInvoiceWithQuickbooks from "./SendInvoiceWithQuickbooks";
import SideDrawer from "../../Estimates/SideDrawer";
import PdfViewer from "../../Estimates/PdfViewer";
import type { QuickbooksResources } from "../GetQuickbooksResources";
import * as GraphQLTypes from "../../../codegen/graphql-types";
import { INVOICE_PORTAL_URLS } from "../constants";
import { useNavigate } from "react-router-dom";
import { clientInvoicesPage } from "../../../routes";
import type { InvoiceFragment } from "../GetQuickbooksResources";
import SelectQuickbooksCustomer from "../SelectQuickbooksCustomer";
import { WarningBox } from "../View/InvoiceWarnings";

const NewQuickbooksInvoice = ({
  dashboardInvoiceData,
  qbResources,
}: {
  dashboardInvoiceData: InvoiceFragment;
  qbResources: QuickbooksResources;
}) => {
  const navigate = useNavigate();
  const { isAccounting } = useAuthContext();
  const {
    isLoggingOut,
    isLoggedIntoQuickbooks,
    setIsLoggedIntoQuickbooks,
  } = useQuickbooksAuthContext();

  const [updateOneClientInvoice] = useMutation<
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutation,
    GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageMutationVariables
  >(GraphQLTypes.UpdateClientInvoiceFromCreateInvoicesPageDocument);

  const databaseInvoiceId = dashboardInvoiceData.id;
  const companyName = dashboardInvoiceData.estimate.company.company_name;
  const brandName = dashboardInvoiceData.estimate.brand.brand_name;
  const estimateName = dashboardInvoiceData.estimate.name;
  const purchaseOrderNumber = dashboardInvoiceData.estimate.po_number;
  // const invoiceNumber = dashboardInvoiceData.invoice_number; // database invoice number
  const invoiceAmount = dashboardInvoiceData.amount_in_cents / 100;
  const estimateFileKey = dashboardInvoiceData.estimate.pdf_file_key;

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const makeErrorToast = (title: string, message: string) => {
    toast({
      title: title,
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };
  const handleSuccess = () => {
    toast({
      title: "Invoice created in QuickBooks & Updated in Dashboard",
      description: `Invoice no. was updated.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  // Get the PO number custom field from the first invoice - trying to guess the name of the PO field to get the Id specific to the company account
  const poNumberCustomField =
    qbResources.Invoice?.length > 0
      ? qbResources.Invoice[0].CustomField.find((field) => field.Name === "PO")
      : null;

  // Get the Sales item from the items list
  const salesItem = qbResources.Item?.find(
    (item) => item.FullyQualifiedName === "Sales"
  );
  const salesItemValue = salesItem ? salesItem.Id : "";

  // Find the customer by the company name + brand name (e.g. "Company - Brand") as it is saved in Quickbooks
  const customer = qbResources.Customer?.find(
    (customer) =>
      customer.DisplayName.toLowerCase() ===
      `${companyName.toLowerCase()} - ${brandName.toLowerCase()}`
  );
  console.log("QBO customer: ", customer);

  // Find this QBO invoice if it exists
  const qbInvoice = qbResources.Invoice?.find(
    (invoice) => invoice.Id === dashboardInvoiceData.quickbooks_id
  );
  console.log("QBO invoice: ", qbInvoice);

  const qbInvoiceIsVoided = qbInvoice?.PrivateNote === "Voided";

  console.log("qbInvoiceIsVoided: ", qbInvoiceIsVoided);

  const customerId = customer ? customer.Id : "";
  const customerDisplayName = customer ? customer.DisplayName : "";

  // Find the SalesTermRef id for the customer - this is the payment term for the customer i.e. Net 30, Net 60, etc.
  const salesTermRefId = customer ? customer.SalesTermRef?.value : "";
  const defaultSalesTerm = qbResources.Term?.find(
    (term: QBO.Term) => term.Name === "Net 30"
  );

  const salesTermForCustomer = qbResources.Term?.find(
    (term: QBO.Term) => term.Id === salesTermRefId
  );
  const dueDaysForCustomer = salesTermForCustomer
    ? salesTermForCustomer.DueDays
    : defaultSalesTerm?.DueDays ?? 30;

  // Find the customer's default tax code id e.g. "4"
  const defaultTaxCodeId =
    customer && customer.DefaultTaxCodeRef
      ? customer.DefaultTaxCodeRef.value
      : "";
  // console.log("defaultTaxCodeId: ", defaultTaxCodeId);

  const defaultTaxCode = qbResources.TaxCode?.find(
    (taxCode) => defaultTaxCodeId === taxCode.Id
  );
  const defaultTaxCodeName = defaultTaxCode ? defaultTaxCode.Name : "";

  const invoiceNumber = estimateName.split(" ")[1] ?? "";
  const invoiceDashNumber = dashboardInvoiceData.estimate.client_invoice_count;
  const invoiceNumberWithDash = invoiceNumber
    ? `${invoiceNumber}-${invoiceDashNumber}`
    : "";

  console.log("invoiceNumberWithDash: ", invoiceNumberWithDash);
  console.log("DocNumber: ", qbInvoice?.DocNumber);

  const initialValues = {
    customerId: customerId,
    customerDisplayName: customerDisplayName,
    customerEmail: customer?.PrimaryEmailAddr?.Address ?? "",
    purchaseOrderNumber: purchaseOrderNumber,
    invoiceNumber: qbInvoice ? qbInvoice.DocNumber : invoiceNumberWithDash,
    invoiceDate: new Date().toLocaleDateString("en-CA", { timeZone: "UTC" }), // today's date
    invoiceTerm: salesTermForCustomer ?? defaultSalesTerm,
    invoiceDueDate: new Date(
      new Date().setDate(new Date().getDate() + dueDaysForCustomer)
    ).toLocaleDateString("en-CA", { timeZone: "UTC" }),
    // If this is an existing quickbooks invoice - use the line items from the invoice in quickbooks
    lineItems: qbInvoice
      ? Array.from(qbInvoice.Line)
          .filter((line) => line.SalesItemLineDetail) // take out any blank line items quickbooks returns
          .map((line) => {
            console.log("line: ", line);
            return {
              DetailType: line.DetailType ?? "SalesItemLineDetail",
              Description: line.Description ?? "",
              Amount: line.Amount ?? Number(invoiceAmount),
              SalesItemLineDetail: {
                ItemRef: {
                  value: line.SalesItemLineDetail?.ItemRef.value ?? 0,
                },
                UnitPrice: line.SalesItemLineDetail?.UnitPrice ?? 0,
                Qty: line.SalesItemLineDetail?.Qty ?? 1,
                TaxCodeRef: {
                  value:
                    line.SalesItemLineDetail?.TaxCodeRef.value ??
                    defaultTaxCodeId,
                  name:
                    line.SalesItemLineDetail?.TaxCodeRef.name ??
                    defaultTaxCodeName,
                },
              },
            };
          })
      : [
          // Default new line item
          {
            DetailType: "SalesItemLineDetail",
            Description: "",
            Amount: Number(invoiceAmount),
            SalesItemLineDetail: {
              ItemRef: {
                value: salesItemValue,
              },
              UnitPrice: Number(invoiceAmount),
              Qty: 1,
              TaxCodeRef: {
                value: defaultTaxCodeId,
                name: defaultTaxCodeName,
              },
            },
          },
        ],
  };

  // Values we will use to make the post request to create the invoice
  const [values, setValues] = useState<QBO.QuickbooksInvoice>(
    initialValues
  );
  // warning message if no customer is found
  const [warning, setWarning] = useState(!customerId);
  // viewing the estimate pdf
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  // File key for viewing invoice PDF = quickbooks invoice id
  const [invoiceFileKey, setInvoiceFileKey] = useState<string | null>(null);

  // invoice from quickbooks after creation
  const [
    quickbooksInvoice,
    setQuickbooksInvoice,
  ] = useState<QBO.Invoice | null>(null);

  const createQuickbooksInvoice = async (
    postBody: QBO.InvoicePostRequestBody
  ) => {
    const response = await fetch(
      `${RuntimeConfig.backendOrigin}/quickbooks/invoice`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postBody),
      }
    );

    if (response.status === 401) {
      // unauthorized
      setIsLoggedIntoQuickbooks(false);
      throw new Error(
        `Could not create invoice. Please log in to Quickbooks and try again.`
      );
    }

    if (!response.ok) {
      const json = await response.json();
      console.log("Error creating invoice: ", json);
      const detail = json.data?.Fault?.Error[0]?.Detail;
      throw new Error(`Error in createQuickbooksInvoice. Detail: ${detail}`);
    }

    const json = await response.json();

    return json.data;
  };

  const updateDashboardInvoice = async (
    quickbooksInvoiceId: string,
    dashboardInvoiceNumber: string,
    companyPortalUrl: string
  ) => {
    try {
      return await updateOneClientInvoice({
        variables: {
          updateOneClientInvoiceData: {
            quickbooks_id: {
              set: quickbooksInvoiceId,
            },
            invoice_number: {
              set: dashboardInvoiceNumber,
            },
            invoice_status: {
              set: companyPortalUrl
                ? GraphQLTypes.InvoiceStatus.GoToPortal
                : GraphQLTypes.InvoiceStatus.GoToInvoice,
            },
          },
          updateOneClientInvoiceWhere: {
            id: databaseInvoiceId,
          },
        },
      });
    } catch (error) {
      console.error(error);
      throw new Error(
        `Error in updateDashboardInvoice: Could not update database invoice (Invoice: ${dashboardInvoiceNumber}) with Quickbooks invoice ID: ${quickbooksInvoiceId}`
      );
    }
  };

  const handleCreateInvoice = async (postBody: QBO.InvoicePostRequestBody) => {
    const databaseEstimateCompanyName =
      dashboardInvoiceData.estimate.company.company_name;
    const companyPortalUrl: string | undefined =
      INVOICE_PORTAL_URLS[databaseEstimateCompanyName];

    try {
      setIsLoading(true);
      const data: {
        Invoice: QBO.Invoice;
        time: string; // "2021-09-29T17:00:00.000-07:00"
      } = await createQuickbooksInvoice(postBody);

      const databaseResponse = await updateDashboardInvoice(
        data.Invoice.Id,
        values.invoiceNumber,
        companyPortalUrl
      );

      if (databaseResponse.data?.updateOneClientInvoice) {
        handleSuccess();
        // Move on to Invoice Sending...
        if (companyPortalUrl) {
          // redirect to invoices page
          navigate(clientInvoicesPage.definition);
        } else {
          console.log(
            "Invoice created in Quickbooks. Opening Send to customer dialogue..."
          );
          // open dialogue to view invoice pdf and send to customer email
          setIsSideDrawerOpen(true);
          setQuickbooksInvoice(data.Invoice);
        }
      }
    } catch (error: any) {
      console.error("Error creating invoice: ", error);
      makeErrorToast("Error creating invoice: ", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validateInvoice = () => {
    if (!validateLineItems()) return false;
    if (!validateRequiredFields()) return false;

    const postBody: QBO.InvoicePostRequestBody = {
      BillEmail: {
        Address: values.customerEmail,
      },
      CustomerRef: {
        value: values.customerId,
      },
      Line: values.lineItems,
      DueDate: values.invoiceDueDate,
      DocNumber: values.invoiceNumber,
      CustomField: [
        {
          DefinitionId: poNumberCustomField?.DefinitionId ?? "1",
          StringValue: values.purchaseOrderNumber,
          Type: poNumberCustomField?.Type ?? "StringType",
          Name: poNumberCustomField?.Name ?? "PO",
        },
      ],
    };
    
    handleCreateInvoice(postBody);
    return true;
  };

  const validateLineItems = () => {
    if (values.lineItems.length === 0) {
      makeErrorToast("Validation Error", "Please add at least one line item");
      return false;
    }
    if (values.lineItems[0].Description === "") {
      makeErrorToast(
        "Validation Error",
        "Please paste description from estimate"
      );
      return false;
    }
    return true;
  };

  const validateRequiredFields = () => {

    const requiredFields = [
      "customerId",
      "customerDisplayName",
      "customerEmail",
      "purchaseOrderNumber",
      "invoiceNumber",
      "invoiceDate",
      "invoiceTerm",
      "invoiceDueDate",
    ].filter((field) => {
      if (field === "customerEmail" && INVOICE_PORTAL_URLS[companyName]) {
        // skip customer email check if company has a portal
        return false;
      }
      return true;
    });

    for (const field of requiredFields) {
      // @ts-ignore
      if (!values[field]) {
        makeErrorToast(
          "Validation Error",
          `Please fill out all fields. Missing: ${field}`
        );
        console.log(values);
        return false;
      }
    }
    return true;
  };

  return (
    <div css={tw`p-6 m-6 rounded shadow`}>
      <section css={tw`w-full flex justify-between`}>
        <Heading size="md" mb={10}>
          Create Quickbooks Invoice for Estimate:
          <br /> {estimateName}
        </Heading>
        <div css={tw`flex flex-col justify-end`}>
          {isAccounting && !isLoggedIntoQuickbooks && (
            <LoginWithQuickbooks
              setIsAuthenticated={setIsLoggedIntoQuickbooks}
            />
          )}
          {isAccounting && isLoggedIntoQuickbooks && (
            <LogoutFromQuickbooks isLoggingOut={isLoggingOut} />
          )}
          <section css={tw`flex gap-2 mb-4`}>
            <Button
              onClick={() => {
                setIsSideDrawerOpen(true);
              }}
            >
              View Estimate PDF
            </Button>
            {dashboardInvoiceData.quickbooks_id && (
              <Button
                onClick={() => {
                  setInvoiceFileKey(dashboardInvoiceData.quickbooks_id);
                  setIsSideDrawerOpen(true);
                }}
              >
                View Invoice PDF
              </Button>
            )}
          </section>
        </div>
      </section>

      {isSideDrawerOpen && estimateFileKey && (
        <SideDrawer
          isOpen={isSideDrawerOpen}
          onClose={() => {
            setIsSideDrawerOpen(false);
          }}
        >
          <PdfViewer s3Key={estimateFileKey} />
        </SideDrawer>
      )}

      {isSideDrawerOpen && invoiceFileKey && !estimateFileKey && (
        <SideDrawer
          isOpen={isSideDrawerOpen}
          onClose={() => {
            setIsSideDrawerOpen(false);
            setInvoiceFileKey(null);
          }}
        >
          <PdfViewer invoiceId={invoiceFileKey} />
        </SideDrawer>
      )}

      {isSideDrawerOpen && quickbooksInvoice && customer && (
        <SideDrawer
          isOpen={isSideDrawerOpen}
          onClose={() => {
            setIsSideDrawerOpen(false);
          }}
          width="90%"
        >
          <SendInvoiceWithQuickbooks
            quickbooksCustomer={customer}
            quickbooksInvoice={quickbooksInvoice}
            dashboardDatabaseInvoiceId={dashboardInvoiceData.id}
            dashboardInvoiceNumber={invoiceNumberWithDash}
            setIsSideDrawerOpen={setIsSideDrawerOpen}
            updateOneClientInvoice={updateOneClientInvoice}
          />
        </SideDrawer>
      )}

      {isLoggedIntoQuickbooks && (
        <main>
          <div css={tw`w-full flex flex-col gap-y-3`}>
            <section css={tw`w-full grid grid-cols-4 gap-6`}>
              {/* Customer */}
              <SelectQuickbooksCustomer 
                isDisabled={false}
                values={values}
                setValues={setValues}
                qbResources={qbResources}
                warning={warning}
                setWarning={setWarning}
                customerId={customerId}
                qbCustomerDisplayName={`${companyName} - ${brandName}`}
                defaultSalesTerm={defaultSalesTerm}
              />

              {/* Invoice Date */}
              <div>
                <label htmlFor="invoiceDate">Invoice date</label>
                <div css={tw`border rounded-md px-4 py-2`}>
                  <PopoverInput
                    initialValue={values.invoiceDate}
                    displayText={values.invoiceDate}
                    type="date"
                    onSubmit={async (e: any, newValue: string) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        invoiceDate: newValue,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Due Date */}
              <div>
                <label htmlFor="invoiceDueDate">Due date</label>
                <div css={tw`border rounded-md px-4 py-2`}>
                  <PopoverInput
                    initialValue={values.invoiceDueDate}
                    displayText={values.invoiceDueDate}
                    type="date"
                    onSubmit={async (e: any, newValue: string) => {
                      e.preventDefault();
                      setValues({
                        ...values,
                        invoiceDueDate: newValue,
                      });
                    }}
                  />
                </div>
              </div>

              {/* Term of Invoice - Due in Net 15, Net 30 days, etc */}
              <div>
                <label htmlFor="invoiceDueDate">Term</label>
                <Select
                  id="invoiceDueDate"
                  name="invoiceDueDate"
                  value={values.invoiceTerm?.Id}
                  onChange={(e) => {
                    const selectedTermId = e.target.value;
                    const selectedTerm = qbResources.Term.find(
                      (term) => term.Id === selectedTermId
                    );
                    if (selectedTerm) {
                      setValues({
                        ...values,
                        invoiceTerm: selectedTerm,
                        invoiceDueDate: new Date(
                          new Date().setDate(
                            new Date().getDate() + selectedTerm.DueDays
                          )
                        ).toLocaleDateString("en-CA", { timeZone: "UTC" }),
                      });
                    }
                  }}
                >
                  {qbResources.Term &&
                    qbResources.Term.map((term, i) => (
                      <option key={i} value={term.Id}>
                        {term.Name}
                      </option>
                    ))}
                </Select>
              </div>

              {/* Purchase Order (PO) Number */}
              <div>
                <label htmlFor="purchaseOrderNumber">PO #</label>
                <Input
                  id="purchaseOrderNumber"
                  name="purchaseOrderNumber"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      purchaseOrderNumber: e.target.value,
                    });
                  }}
                  value={values.purchaseOrderNumber}
                />
              </div>

              {/* Invoice Number */}
              <div>
                <label htmlFor="invoiceNumber">Invoice #</label>
                <Input
                  id="invoiceNumber"
                  name="invoiceNumber"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      invoiceNumber: e.target.value,
                    });
                  }}
                  value={values.invoiceNumber}
                />
              </div>

              {/* Customer Email */}
              <div>
                <label htmlFor="customerEmail">Customer Email</label>
                <Input
                  id="customerEmail"
                  name="customerEmail"
                  onChange={(e) => {
                    setValues({
                      ...values,
                      customerEmail: e.target.value,
                    });
                  }}
                  value={INVOICE_PORTAL_URLS[companyName] ? "" : values.customerEmail}
                />
              </div>
            </section>

            {/* Line Items */}
            {qbResources.Item &&
              qbResources.TaxRate &&
              qbResources.TaxCode &&
              customer &&
              defaultTaxCode && (
                <LineItemsTable
                  lineItems={values.lineItems}
                  items={qbResources.Item}
                  taxRates={qbResources.TaxRate}
                  taxCodes={qbResources.TaxCode}
                  defaultTaxCodeRef={{
                    id: defaultTaxCodeId,
                    name: defaultTaxCodeName,
                  }}
                  customerCurrency={customer.CurrencyRef.value}
                  onLineItemsChange={(updatedLineItems) => {
                    setValues({
                      ...values,
                      lineItems: updatedLineItems,
                    });
                  }}
                />
              )}

            {/* Warning for missing customer tax code */}
            {qbResources.Item &&
              qbResources.TaxRate &&
              qbResources.TaxCode &&
              customer &&
              !defaultTaxCode && (
                <WarningBox>
                  <p css={tw`text-red-500`}>
                    No default tax code found for this customer:{" "}
                    {customerDisplayName}. Please set one in Quickbooks.
                  </p>
                </WarningBox>
              )}

            {/* Warning for VOIDED invoice */}
            {qbInvoiceIsVoided && (
              <>
                <p css={tw`text-red-500`}>
                  This invoice has been voided in Quickbooks. Please create a
                  new invoice.
                </p>
                {/* <Button
                  onClick={validateInvoice}
                  isLoading={isLoading}
                  loadingText="Creating invoice..."
                  colorScheme="blue"
                  css={tw`mt-4`}
                  width="max-content"
                  isDisabled={
                    isLoading ||
                    warning ||
                    !defaultTaxCode
                  }
                >
                  Create New Invoice
                </Button> */}
              </>
            )}

            {/* Footer - Submit & Reset */}
            <footer css={tw`w-full flex justify-end`}>
              {quickbooksInvoice?.EmailStatus === "EmailSent" ? (
                <>Invoice Sent</>
              ) : (
                <>
                  <Button
                    onClick={validateInvoice}
                    isLoading={isLoading}
                    loadingText="Creating invoice..."
                    colorScheme="blue"
                    css={tw`mt-4`}
                    isDisabled={
                      isLoading ||
                      warning ||
                      !defaultTaxCode
                    }
                  >
                    Create Invoice
                  </Button>
                </>
              )}
            </footer>
          </div>
        </main>
      )}
    </div>
  );
};

export default NewQuickbooksInvoice;