// Table heavily referenced from https://tanstack.com/table/v8/docs/framework/react/examples/editable-data
import React, { useMemo, useContext } from "react";
import tw from "twin.macro";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
} from "@tanstack/react-table";
import { PricingContext } from "../helpers/usePricing";
import transposeDataForTasklistTable from "../helpers/transposeDataForTasklistTable";
import type { 
  PricingAppData, 
  TransposedDataForTasklistTable,
} from "../helpers/usePricing";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    // updateData: (taskname: string, keyname: string, value: unknown) => void;
    // updateCogs: (index: number, value: number) => void;
    updateData: (rowIndex: number, columnId: string, value: string) => void;
    updateCogs: (rowIndex: number, columnId: string, value: string) => void;
  }
}

// Skipper for pagination reset
/*
function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
  */

interface ProjectPricingTableProps {
  pricingAppData: PricingAppData;
}
  
// Too many repeated styles, use this
const styles = {
  baseTh: tw`justify-center items-center border border-gray-200 px-2 py-1 bg-gray-100 font-bold`,
  subtotalTh: tw`justify-center items-center border border-gray-200 px-2 py-1 bg-blue-100 font-bold`,
  selectedTh: tw`justify-center items-center border border-gray-200 px-2 py-1 text-white bg-gray-800 font-bold`,
  baseTd: tw`justify-center items-center text-center border border-gray-200 px-2 py-1`,
  selectedTd: tw`justify-center items-center text-center border border-gray-200 px-2 py-1 bg-blue-100 font-bold`,
  totalTd: tw`justify-center items-center text-center border border-gray-200 px-2 py-1 font-bold`
};

const RemainingTotalCell = ({
  info,
  tasklistName,
  isSelectedTasklist,
}: {
  info: any;
  tasklistName: string;
  isSelectedTasklist: boolean;
}) => {
  const { formatNumber } = useContext(PricingContext);
  const isRemaining = info?.row?.original?.type === "Remaining";
  const isNegative = info.row.original?.[tasklistName]?.value < 0;
  const tdStyle = isSelectedTasklist ? styles.selectedTd : styles.baseTd;
  return (
    <td css={tdStyle}>
      {isRemaining && isNegative ? (
        <span css={tw`text-red-500`}>
          -$
          {formatNumber(
            Math.abs(info.row.original?.[tasklistName]?.value / 100)
          )}
        </span>
      ) : (
        <span>
          ${formatNumber(info.row.original?.[tasklistName]?.value / 100)}
        </span>
      )}
    </td>
  );
};

const ProjectTasklistPricingTable: React.FC<ProjectPricingTableProps> = ({
  pricingAppData,
}) => {
  const {
    formatNumber,
    currentDetailView,
    setCurrentDetailView,
    selectedTasklist,
    setSelectedTasklist,
    tasklists,
  } = useContext(PricingContext);

  const transposedProjectTasklistsData = useMemo(() => {
    return transposeDataForTasklistTable(
      tasklists,
      pricingAppData.db.project_pricings,
      pricingAppData.db.cogs
    );
  }, [tasklists, pricingAppData]);

  // Create dynamic columns based on the data
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<TransposedDataForTasklistTable>();

    return [
      // First column: Fixed titles (Budget, Actual, Remaining) accessed by the "type" key
      columnHelper.accessor("type", {
        id: "column-head",
        header: () => "",
        cell: ({ row }) => {
          const type = row.original.type;
          return (
            <td css={styles.baseTh}>{type}</td>
          );
        },
      }),

      // Dynamic columns based on tasklists
      ...tasklists.map((tasklist) => {
        return columnHelper.accessor((row) => row.name, {
          id: tasklist.name,
          header: () => (
            // Dynamic button header based on the first item's name (tasklist name)
            <button
              onClick={() => {
                setCurrentDetailView("tasklist");
                setSelectedTasklist(tasklist);
              }}
            >
              {tasklist.name}
            </button>
          ), 
          cell: (info) => (
            <RemainingTotalCell 
              info={info} 
              tasklistName={tasklist.name}
              isSelectedTasklist={selectedTasklist?.id === tasklist.id}
            />
          ),
        });
      }),

      // "Subtotal based on hrs" column accessed by the "hoursSubtotal" key
      columnHelper.accessor("hoursSubtotal", {
        id: "hoursSubtotal",
        header: () => "Subtotal based on hrs",
        cell: ({ row }) => {
          
          switch (row.original.type) {
            case "Remaining":
              const remainingSubtotal = row.original.hoursSubtotal;
              return (
                <td key={row.id} css={styles.baseTd} style={{ textAlign: "center" }}>
                  {remainingSubtotal > 0 ? (
                    `$${formatNumber(remainingSubtotal / 100)}`
                  ) : (
                    <span css={tw`text-red-500`}>
                      -${formatNumber(Math.abs(remainingSubtotal / 100))}
                    </span>
                  )}
                </td>
              );
            default:
              const subtotal = row.original.hoursSubtotal;
              return (
                <td key={row.id} css={styles.baseTd} style={{ textAlign: "center" }}>
                  ${formatNumber(subtotal / 100)}
                </td>
              );
          }
        },
      }),

      // COGS column accessed by the "cogs" key
      columnHelper.accessor("cogs", {
        id: "cogs",
        header: (info) => (
          <button
            tabIndex={info.column.depth}
            onClick={() => {
              setCurrentDetailView("cogs");
              setSelectedTasklist(null);
            }}
          >
            COGS
          </button>
        ),
        cell: ({ row }) => {
          const cogs = row.original.cogs;
          const isCogsSelected = currentDetailView === "cogs";
          const tdStyle = isCogsSelected ? styles.selectedTd : styles.baseTd;
          switch (row.original.type) {
            case "Remaining":
              return (
                <td key={row.id} css={tdStyle}>
                  {cogs >= 0 ? (
                    `$${formatNumber(cogs / 100)}`
                  ) : (
                    <span css={tw`text-red-500`}>
                      -${formatNumber(Math.abs(cogs / 100))}
                    </span>
                  )}
                </td>
              );
            default:
              return (
                <td key={row.id} css={tdStyle}>
                  ${formatNumber(cogs / 100)}
                </td>
              );
          }
        },
      }),

      // TOTAL column across all tasklists and COGS
      columnHelper.accessor("total", {
        id: "total",
        header: () => "TOTAL",
        cell: (info) => {
          const rowTotal = info.row.original.total;
          switch (info.row.original.type) {
            case "Remaining":
              const isNegative = rowTotal < 0;
              return (
                <td key={info.row.id} css={styles.totalTd}>
                  {isNegative ? (
                    <span css={tw`text-red-500`}>
                      -$
                      {formatNumber(
                        Math.abs(rowTotal / 100)
                      )}
                    </span>
                  ) : (
                    <span css={tw`text-green-600`}>
                      +${formatNumber(rowTotal / 100)}
                    </span>
                  )}
                </td>
              );

            default:
              return (
                <td key={info.row.id} css={styles.totalTd}>
                  ${formatNumber(rowTotal / 100)}
                </td>
              );
          }
        },
      }),
    ];
  }, [selectedTasklist, transposedProjectTasklistsData, pricingAppData]);

  const table = useReactTable({
    data: transposedProjectTasklistsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  // console.log("transposedProjectTasklistsData: ", transposedProjectTasklistsData);

  return (
    <div>
      <table css={tw`border-collapse border border-gray-200`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const columnName = header.id;
                const isCogsSelected = columnName === "cogs" && currentDetailView === "cogs";
                const isSelectedTasklist = selectedTasklist?.name === columnName;

                let thStyle = styles.baseTh;

                switch (columnName) {
                  case "hoursSubtotal":
                    thStyle = styles.subtotalTh;
                    break;
                  case "cogs":
                    thStyle = isCogsSelected ? styles.selectedTh : styles.baseTh;
                    break;
                  case "total":
                    thStyle = styles.subtotalTh;
                    break;
                  default:
                    if (isSelectedTasklist) {
                      thStyle = styles.selectedTh;
                    }
                    break;
                }

                return (
                  <th key={header.id} css={thStyle}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                // @ts-ignore
                <React.Fragment key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTasklistPricingTable;
