import tw, { css } from "twin.macro";
import { useState } from "react";
import {
  PricingComment,
  ProjectPricing,
  useCreatePricingCommentMutation,
  useUpdatePricingCommentMutation,
  useDeletePricingCommentMutation,
} from "../../codegen/graphql-types";
import { Button, IconButton } from "@chakra-ui/react";
import {
  EditIcon,
  DeleteIcon,
  CloseIcon,
  CheckCircleIcon,
} from "@chakra-ui/icons";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { usePricingDataContext } from "../../helpers/usePricingData";
import TiptapEditor from "./TiptapEditor";

const NewComment = ({ onSubmit }: { onSubmit: (content: string) => void }) => {
  const [content, setContent] = useState("");
  const [edit, setEdit] = useState(false);

  const handleSubmit = () => {
    if (content.trim()) {
      onSubmit(content);
      setContent("");
    }
  };

  return (
    <div css={tw`flex flex-col gap-y-3`}>
      <TiptapEditor
        id={"new-comment-editor"}
        value={content}
        setValue={setContent}
        onSave={handleSubmit}
        edit={edit}
        setEdit={setEdit}
        placeholder={"Enter your comment here..."}
        disabled={false}
      />
      {edit && (
        <Button size="sm" colorScheme="blue" onClick={handleSubmit}>
          Post Comment
        </Button>
      )}
    </div>
  );
};

const TasklistComments = ({
  projectId,
  projectPricing,
  tasklistId,
  tasklistName,
}: {
  projectId: string;
  projectPricing: Pick<ProjectPricing, "id" | "project_id" | "comments">;
  tasklistId: number | null;
  tasklistName: string;
}) => {
  const isForCogs = tasklistName === "COGS";
  const [showTasklistComments, setShowTasklistComments] = useState(false);
  const { refetchProjectPricing } = usePricingDataContext();
  const peopleData = usePeopleContext();
  const userId = peopleData.data?.me?.id;
  const [createPricingComment] = useCreatePricingCommentMutation();

  const handleCommentSubmit = async (comment: string) => {
    if (!userId || !projectId || !projectPricing.id) {
      console.log("projectId", projectId);
      console.log("projectPricing.id", projectPricing.id);
      console.log("userId", userId);
      console.error("Missing required data for comment submission");
      return;
    }

    try {
      await createPricingComment({
        variables: {
          projectId: projectId,
          projectPricingId: projectPricing.id,
          tasklistId: tasklistId?.toString() ?? "0",
          createdById: userId,
          comment: comment,
          isForCogs: isForCogs,
        },
        onCompleted: (data) => {
          refetchProjectPricing();
        },
        onError: (error) => {
          console.error("Error creating comment:", error);
        },
      });
    } catch (error) {
      console.error("Error creating project pricing data:", error);
    }
  };

  const commentsForTasklist = projectPricing.comments.filter(
    (comment) => comment.tasklist_id === tasklistId?.toString() && !comment.is_for_cogs
  );
  const commentsForCogs = projectPricing.comments.filter(
    (comment) => comment.is_for_cogs
  );



  return (
    <div
      css={[
        tw`w-1/4 flex flex-col p-4 border border-gray-300 rounded-md shadow-sm bg-gray-50`,
        css`transition: all 0.3s;`,
      ]}
    >
      <button
        css={tw`text-center text-gray-700 font-medium mb-3 bg-gray-200 py-1 rounded cursor-pointer hover:bg-gray-300`}
        onClick={() => setShowTasklistComments(!showTasklistComments)}
      >
        {showTasklistComments ? "Hide" : "See"} Comments for {tasklistName ?? "Tasklist"}
      </button>
      {showTasklistComments && (
        <>
          <CommentList comments={isForCogs ? commentsForCogs : commentsForTasklist} />
          <NewComment onSubmit={handleCommentSubmit} />
        </>
      )}
    </div>
  );
};

const CommentList = ({ comments }: { comments: PricingComment[] }) => {
  const { refetchProjectPricing } = usePricingDataContext();
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [updatedContent, setUpdatedContent] = useState("");
  const [updatePricingComment] = useUpdatePricingCommentMutation();
  const [deletePricingComment] = useDeletePricingCommentMutation();

  const handleEdit = (comment: PricingComment) => {
    setEditingCommentId(comment.id);
    setUpdatedContent(comment.comment);
  };

  const handleUpdate = async (commentId: number) => {
    try {
      await updatePricingComment({
        variables: { id: commentId, comment: updatedContent },
        onCompleted: () => {
          setEditingCommentId(null);
          refetchProjectPricing();
        },
        onError: (error) => {
          console.error("Error updating comment:", error);
        },
      });
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async (commentId: number) => {
    if (window.confirm("Are you sure you want to delete this comment?")) {
      try {
        await deletePricingComment({
          variables: { id: commentId },
          onCompleted: () => {
            refetchProjectPricing();
          },
          onError: (error) => {
            console.error("Error deleting comment:", error);
          },
        });
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    }
  };

  return (
    <div css={tw`mt-4`}>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          updatedContent={updatedContent}
          handleEdit={handleEdit}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          editingCommentId={editingCommentId}
          setEditingCommentId={setEditingCommentId}
          setUpdatedContent={setUpdatedContent}
        />
      ))}
    </div>
  );
};

interface CommentProps {
  key: number;
  comment: PricingComment;
  updatedContent: string;
  handleEdit: (comment: PricingComment) => void;
  handleUpdate: (commentId: number) => void;
  handleDelete: (commentId: number) => void;
  editingCommentId: number | null;
  setEditingCommentId: (commentId: number | null) => void;
  setUpdatedContent: (content: string) => void;
}

const Comment = ({
  comment,
  updatedContent,
  handleEdit,
  handleUpdate,
  handleDelete,
  editingCommentId,
  setEditingCommentId,
  setUpdatedContent,
}: CommentProps) => {
  const [showButtons, setShowButtons] = useState(false);
  const buttonTw = showButtons ? tw`` : tw`invisible`;
  return (
    <div
      css={tw`border-b py-2 hover:bg-white`}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      {editingCommentId === comment.id ? (
        <div>
          <TiptapEditor
            id={`edit-comment-editor-${comment.id}`}
            value={updatedContent}
            setValue={setUpdatedContent}
            onSave={() => handleUpdate(comment.id)}
            edit={true}
            setEdit={() => {}}
            placeholder={"Edit your comment..."}
            disabled={false}
          />
          <div css={tw`pb-2 flex justify-end items-center w-full gap-x-1`}>
            <IconButton
              size="xs"
              aria-label="Save"
              icon={<CheckCircleIcon />}
              colorScheme="green"
              onClick={() => handleUpdate(comment.id)}
              css={buttonTw}
            />
            <IconButton
              size="xs"
              aria-label="Cancel"
              icon={<CloseIcon />}
              colorScheme="gray"
              onClick={() => setEditingCommentId(null)}
              css={buttonTw}
            />
          </div>
        </div>
      ) : (
        <div css={tw`px-2`}>
          <div css={tw`pb-2 flex justify-end items-center w-full gap-x-1`}>
            <IconButton
              size="xs"
              aria-label="Save"
              icon={<EditIcon />}
              colorScheme="blue"
              onClick={() => handleEdit(comment)}
              css={buttonTw}
            />
            <IconButton
              size="xs"
              aria-label="Delete"
              icon={<DeleteIcon />}
              colorScheme="red"
              onClick={() => handleDelete(comment.id)}
              css={buttonTw}
            />
          </div>
          <div
            css={tw`text-sm`}
            dangerouslySetInnerHTML={{ __html: comment.comment }}
          />
          <div css={tw`text-xs text-gray-500`}>
            — {comment.created_by.first_name} on{" "}
            {new Date(comment.created_at).toLocaleString()}
          </div>
        </div>
      )}
    </div>
  );
};

export default TasklistComments;
