import tw, { css } from "twin.macro";
import { useState } from "react";
import {
  useToast,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import UploadEstimatePdf from "./UploadEstimatePdf";
import { FullSpinner } from "../../FullSpinner";

const UpdateEstimatePdf = ({
  estimateName,
  fileKey,
  estimateId,
  updateEstimate,
  setIsDrawerOpen,
  setPdfFileKey,
}: {
  estimateName: string;
  fileKey: string;
  estimateId: string;
  updateEstimate: any;
  setIsDrawerOpen: (isOpen: boolean) => void;
  setPdfFileKey: (key: string) => void;
}) => {
  const [editPdf, setEditPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const [uploadedFiles, setUploadedFiles] = useState<
    {
      id: string;
      name: string;
      contentType: string;
    }[]
  >([]);

  const onCancel = () => {
    setEditPdf(false);
  };

  return (
    <>
      {/* Viewing or downloading the Pdf */}
      <Button
        css={tw`w-full`}
        size="sm"
        onClick={() => {
          setPdfFileKey(fileKey);
          setIsDrawerOpen(true);
        }}
      >
        <DownloadIcon />
      </Button>
      {/* Updating the Pdf */}
      {!editPdf && (
        <a
          css={tw`hover:underline cursor-pointer text-center inline-block w-full pt-1`}
          onClick={() => {
            setEditPdf(true);
          }}
        >
          Edit
        </a>
      )}
      {editPdf && (
        <Modal
          isOpen={editPdf}
          onClose={() => {
            setEditPdf(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Upload the new PDF for {estimateName}
            </ModalHeader>
            <ModalBody>
              {isLoading && <FullSpinner />}
              {isLoading && <p>Updating the file key in the database...</p>}
              {!isLoading && (
                <UploadEstimatePdf
                  formId={fileKey}
                  method="PUT"
                  existingFileKey={fileKey}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  onSuccessfulUpload={async (newKey: string) => {
                    setIsLoading(true);
                    await updateEstimate({
                      variables: {
                        updateOneEstimateData: {
                          pdf_file_key: {
                            set: newKey,
                          },
                        },
                        updateOneEstimateWhere: {
                          id: estimateId,
                        },
                      },
                      onCompleted: () => {
                        toast({
                          status: "success",
                          title: "PDF updated",
                        });
                      },
                      onError: (error: any) => {
                        console.log(error);
                        toast({
                          status: "error",
                          title: "Error updating PDF",
                          description: error.message,
                        });
                      },
                    });
                    setIsLoading(false);
                    setEditPdf(false);
                  }}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button onClick={onCancel} mr={3}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default UpdateEstimatePdf;