/**
 * There are layers of data used throughout the page
 * 1) Project Selector
 * Search/Fetch project name from synced data in DB from TW
 * a) New pricing, fetch project data + tasklist from TW API
 * b) Existing pricing, fetch
 *
 * 2) Project Pricing Table
 * Fetch data from DB, will have to transpose for table use
 * Here are the types of tables
 * - Tasklist
 * - COGS
 * - Total/Subtotal
 *
 * 3) Tasklist Details Table
 * Fetch data from DB, will have to transpose for table use
 * Here are the types of tables
 * - Team
 * - Total
 *
 * The data is used in different components and it is passed around using context.
 */
import { BriefProjectInvoice, usePricingDataContext } from "./usePricingData";
import {
  createContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import type { Tasklist } from "../helpers/useProjectTasklists";
import {
  Maybe,
  ProjectedTime,
  GetProjectRatesByProjectIdDocument,
  Team,
  Role,
  useUpdateProjectedTimeMutation,
  GetProjectRatesByProjectIdQueryResult,
  PeopleDataQuery,
  ProjectedExpense,
} from "../codegen/graphql-types";
import { usePeopleContext } from "./usePeopleContext";
import { useProjectTasklists } from "./useProjectTasklists";
import { getMeanProjectRatesByTeamAndRole } from "./getMeanProjectRatesByTeamAndRole";
import type { MeanProjectRatesByTeamAndRole } from "./getMeanProjectRatesByTeamAndRole";
import type { BriefTimeEntry } from "./usePricingData";

interface PricingContextType {
  projectId: string | undefined;

  // 0.Utils
  formatNumber: (num: number) => string;

  peopleData: PeopleDataQuery["people"] | undefined;

  pricingAppData: PricingAppData | null;
  setPricingAppData: (data: PricingAppData | null) => void;

  tasklists: Tasklist[];
  selectedTasklist: Tasklist | null;
  setSelectedTasklist: (tasklist: Tasklist | null) => void;

  // 3.Tasklist Detail Section
  currentDetailView: "tasklist" | "cogs";
  setCurrentDetailView: (view: "tasklist" | "cogs") => void;

  updateTeamRoleProjectedTime: (
    tasklistId: number,
    projectedTimeId: number,
    value: number
  ) => void;

  convertTasklistDetailsRowDataByTeam: (
    teamName: string,
    data: ProjectedTime[],
    meanProjectRates: MeanProjectRatesByTeamAndRole[],
    transformedTimeEntries: TimeEntryWithRate[]
  ) => any;

  meanProjectRates: MeanProjectRatesByTeamAndRole[] | null;
  uniqueRoles: Role[];
  uniqueTeams: Team[];

  transformedTimeEntries: TimeEntryWithRate[] | null;
}

export const PricingContext = createContext<PricingContextType>({
  // Initialize context
  formatNumber: () => "",

  // 1.Project Selector Section
  projectId: undefined,

  peopleData: undefined,
  pricingAppData: null,
  setPricingAppData: () => {},

  tasklists: [],
  selectedTasklist: null,
  setSelectedTasklist: () => {},

  // 3.Tasklist Team Table Section
  currentDetailView: "tasklist",
  setCurrentDetailView: () => {},

  convertTasklistDetailsRowDataByTeam: () => {},

  updateTeamRoleProjectedTime: () => {},

  meanProjectRates: null,
  uniqueRoles: [],
  uniqueTeams: [],

  transformedTimeEntries: null,
});

/*
Interface for Pricing Context
*/

export type Cogs = {
  expenses: ProjectExpenseTypes;
  cogs_budget_total: number, // total estimates across all invoices for project
  cogs_actual_total: number // total actuals across all invoices for project
}

// Keep track of budget and actual for each type of expense key'd by string "expense_type - payee_name"
export type ProjectExpenseTypes = {
  [key: string]: {
    invoices: BriefProjectInvoice[];
    expense_budget_total: number;
    expense_actual_total: number;
  }
}

export interface ProjectPricingCalculatedData {
  budget: { [key: string]: number };
  actual: { [key: string]: number };
  total: { [key: string]: number };
}

type TasklistPricing = {
  project_pricing_id: number; // parent project_pricing_id
  tasklist_id: number;
  tasklist_name: string;
  comments: string;
  actual_cost: number;
  price: number;
  currency: string;
  projected_times: ProjectedTime[];
};

export interface TasklistPricingWithCalculatedData extends TasklistPricing {
  // CALCULATED holds the running totals for the tasklist (across all Teams)
  CALCULATED: ProjectPricingCalculatedData;
}

interface ProjectedTimesWithCalculatedData extends ProjectedTime {
  estimated_cost: number;
  actual_cost: number;
  estimated_hours: number;
  actual_hours: number;
}

export type PricingAppData = {
  db: {
    project_pricing_id: number;
    project_pricings: TasklistPricingWithCalculatedData[];
    cogs: Cogs;
  };
  projectId?: number | string;
  tasklists: Tasklist[];
};

interface PricingProviderProps {
  children: ReactNode;
}

// Interface for Tasklists Table
export type TransposedDataForTasklistTable = {
  type: "Budget" | "Actual" | "Remaining";
  [key: string]: { value: number } | string | number; // Tasklist name as key
  hoursSubtotal: number;
  cogs: number;
  total: number; // (Sum of "type" hoursSubtotal) + cogs
}

export type SelecetedProject = {
  id: string;
  name: string;
};

export type TimeEntryWithRate = {
  id: string;
  tasklist_id: Maybe<number> | undefined;
  minutes: number;
  user_id: string;
  user_role_id: string | undefined;
  user_team_id: string | undefined;
  user_project_rate: number;
};

export const PricingProvider = ({ children }: PricingProviderProps) => {
  const toast = useToast();
  const {
    projectId,
    projectDetails,
  } = usePricingDataContext();

  // Fetch people from db
  const peopleQuery = usePeopleContext();
  const {
    data: peopleData,
    // loading: peopleLoading,
    // error: peopleError,
    // refetch: refetchPeople,
  } = peopleQuery;

  // Fetch project rates from db based on projectId
  const {
    data: projectRatesData,
  }: {
    data: GetProjectRatesByProjectIdQueryResult["data"];
    // loading: boolean;
    // error: any;
  } = useQuery(GetProjectRatesByProjectIdDocument, {
    variables: { projectId: projectId?.toString() },
  });

  // Fetch tasklists from teamwork
  const tasklists = useProjectTasklists(projectId);

  // Get mean project rates by team and role
  const meanProjectRates = getMeanProjectRatesByTeamAndRole(projectRatesData);

  // Mutation for updating a single ProjectedTime
  const [updateProjectedTime] = useUpdateProjectedTimeMutation();

  const formatNumber = (num: number) => {
    // Round the number up if decimal is 0.50 or higher (do not display cents)
    const roundedNum = Math.floor(num) + (num % 1 >= 0.5 ? 1 : 0);
    return new Intl.NumberFormat().format(roundedNum);
  };

  const dedupeWithSet = (array: string[] | undefined) => {
    return Array.from(new Set(array));
  };

  const removeDuplicatesById = (arr: any[]) => {
    const uniqueObjects: any[] = [];
    const seenIds = new Set();

    arr.forEach((item) => {
      if (!seenIds.has(item.id)) {
        seenIds.add(item.id);
        uniqueObjects.push(item);
      }
    });

    return uniqueObjects;
  };

  const teamsFromPeopleData = peopleData
    ? peopleData.people
        .filter((person) => person.team)
        .map((person) => person.team)
    : [];

  const rolesFromPeopleData = peopleData
    ? peopleData.people
        .filter((person) => person.role)
        .map((person) => person.role)
    : [];

  const uniqueTeams: Team[] = removeDuplicatesById(teamsFromPeopleData);
  const uniqueRoles: Role[] = removeDuplicatesById(rolesFromPeopleData);

  // Ideal shape of each time entry for determining the actual cost and actual time spent per role and team
  /**
   * tasklist_id: number
   * minutes: number
   * user_id: string
   * user_role_id: string
   * user_team_id: string
   * user_project_rate: number
   */
  const transformTimeEntries = useCallback(
    (
      timeEntries: BriefTimeEntry[],
      people: PeopleDataQuery["people"],
      projectRates: GetProjectRatesByProjectIdQueryResult["data"]
    ) => {
      return timeEntries.map((entry) => {
        const entryId = entry.id;
        const user = people.find((person) => person.id === entry.user_id);
        const team = user?.team;
        const role = user?.role;
        const rate = projectRates?.projectRatesByProjectId.find(
          (rate) => rate.person.id === entry.user_id
        );

        return {
          id: entryId,
          tasklist_id: entry.tasklist_id,
          minutes: entry.minutes,
          user_id: entry.user_id,
          user_role_id: role?.id,
          user_team_id: team?.id,
          user_project_rate: rate?.rate_in_cents ?? 0,
        };
      });
    },
    []
  );

  const transformedTimeEntries: TimeEntryWithRate[] | null = useMemo(() => {
    if (
      !peopleData ||
      !projectRatesData ||
      !projectRatesData.projectRatesByProjectId ||
      !projectDetails
    ) {
      return null;
    }
    return transformTimeEntries(
      projectDetails.time_entries,
      peopleData.people,
      projectRatesData
    );
  }, [peopleData, projectRatesData, projectDetails?.time_entries]);

  // console.log("transformedTimeEntries", transformedTimeEntries);

  const [selectedTasklist, setSelectedTasklist] = useState<Tasklist | null>(
    null
  );

  // Determine the detail view from tasklist selection
  const [currentDetailView, setCurrentDetailView] = useState<
    "tasklist" | "cogs"
  >("tasklist");

  // Will use this as SSOT data
  // All outside data will be manage here before passing down to children
  const [pricingAppData, setPricingAppData] = useState<PricingAppData | null>(
    null
  );

  const extractTeams = (projectedTimes: ProjectedTime[]) =>
    projectedTimes.map((pt: ProjectedTime) => pt.team_id);

  const sumByTeam = (
    source: ProjectedTimesWithCalculatedData[],
    team_id: string,
    rowName: "budget" | "actual",
    sumType: "cost" | "time"
  ) => {
    const costKey = rowName === "budget" ? "estimated_cost" : "actual_cost";
    const timeKey = rowName === "budget" ? "estimated_hours" : "actual_hours";

    // If sumType is for time
    if (sumType === "time") {
      return source.reduce(
        (acc: number, curr: ProjectedTimesWithCalculatedData) =>
          curr.team_id === team_id ? acc + curr[timeKey] : acc,
        0
      );
    }

    // If sumType is for cost
    if (sumType === "cost") {
      return source.reduce(
        (acc: number, curr: ProjectedTimesWithCalculatedData) =>
          curr.team_id === team_id ? acc + curr[costKey] : acc,
        0
      );
    }

    return 0;
  };

  const sumTotal = (source: { [key: string]: number }, keyName: string) => {
    return Object.keys(source).reduce(
      (acc: number, curr: string) =>
        curr.includes(keyName) ? acc + source[curr] : acc,
      0
    );
  };

  const calculateTotalTimeCost = (
    tasklistPricing: TasklistPricing
  ): TasklistPricingWithCalculatedData => {
    // Hold the totals for budget, actual, and total for the tasklist
    let calculatedData: ProjectPricingCalculatedData = {
      budget: {},
      actual: {},
      total: {},
    };

    if (
      !transformedTimeEntries ||
      !tasklistPricing ||
      !tasklistPricing.projected_times ||
      tasklistPricing.projected_times.length === 0 ||
      !projectDetails
    ) {
      return {
        ...tasklistPricing,
        CALCULATED: calculatedData,
      };
    }

    const timeEntriesForTasklist = transformedTimeEntries.filter(
      (entry) => entry.tasklist_id === tasklistPricing.tasklist_id
    );

    // Calculate Projected Times (Get estimated cost and actual cost for each projected time)
    // This logic determines the cell values for "Hrs" and "Cost" for each Team > Role
    const calculatedProjectedTimes = tasklistPricing.projected_times.map(
      (projectedTime: ProjectedTime) => {
        const meanRateForTeamAndRole = meanProjectRates?.find(
          (rate) =>
            rate.team.id === projectedTime.team_id &&
            rate.role.id === projectedTime.role_id
        );

        // For Estimated Cost and Estimated Hours
        const projectedHours = projectedTime.projected_minutes / 60;
        const estimated_cost =
          projectedHours * (meanRateForTeamAndRole?.meanRateInCents ?? 0);

        // For Actual Cost and Actual Hours
        const timeEntriesForTeamAndRole = timeEntriesForTasklist.filter(
          (timeEntry) =>
            timeEntry.user_team_id === projectedTime.team_id &&
            timeEntry.user_role_id === projectedTime.role_id
        );
        const actual_hours = timeEntriesForTeamAndRole.reduce(
          (acc: number, curr: TimeEntryWithRate) => acc + curr.minutes / 60,
          0
        );
        const actual_cost = timeEntriesForTeamAndRole.reduce(
          (acc: number, curr: TimeEntryWithRate) => {
            const cost = (curr.minutes / 60) * curr.user_project_rate;
            return acc + cost;
          },
          0
        );

        return {
          ...projectedTime,
          estimated_hours: projectedHours,
          estimated_cost,
          actual_hours,
          actual_cost,
        };
      }
    );

    // This logic determines cell values for "Total - Hrs" and "Total - Cost" for each Team
    dedupeWithSet(extractTeams(tasklistPricing.projected_times)).forEach(
      (teamId: string) => {
        const teamName = uniqueTeams.find((team: Team) => team.id === teamId)
          ?.teamName;

        // Budget/Estimate/Projected
        // Total Time for team
        calculatedData.budget[`${teamName}-total-time`] = sumByTeam(
          calculatedProjectedTimes,
          teamId,
          "budget",
          "time"
        );
        calculatedData.budget[`${teamName}-total-cost`] = sumByTeam(
          calculatedProjectedTimes,
          teamId,
          "budget",
          "cost"
        );

        // Total Cost for all teams
        calculatedData.budget["total-cost"] = sumTotal(
          calculatedData.budget,
          "-total-cost"
        );
        // Total Time for all teams
        calculatedData.budget["total-time"] = sumTotal(
          calculatedData.budget,
          "-total-time"
        );

        // Actual
        // Total Time per team
        calculatedData.actual[`${teamName}-total-time`] = sumByTeam(
          calculatedProjectedTimes,
          teamId,
          "actual",
          "time"
        );
        calculatedData.actual[`${teamName}-total-cost`] = sumByTeam(
          calculatedProjectedTimes,
          teamId,
          "actual",
          "cost"
        );
        
        // Total Cost
        calculatedData.actual["total-cost"] = sumTotal(
          calculatedData.actual,
          "-total-cost"
        );
        // Total Time
        calculatedData.actual["total-time"] = sumTotal(
          calculatedData.actual,
          "-total-time"
        );

        // Determine remaining budget and time by subtracting actual from budget
        calculatedData.total[`${teamName}-total-cost`] =
          Number(calculatedData.budget[`${teamName}-total-cost`]) -
          Number(calculatedData.actual[`${teamName}-total-cost`]);
        calculatedData.total[`${teamName}-total-time`] =
          Number(calculatedData.budget[`${teamName}-total-time`]) -
          Number(calculatedData.actual[`${teamName}-total-time`]);
      }
    );

    // Subtract the actual total cost from the budget total cost
    calculatedData.total["total-cost"] = calculatedData.budget["total-cost"] - calculatedData.actual["total-cost"];

    // Subtract the actual total time from the budget total time
    calculatedData.total["total-time"] = calculatedData.budget["total-time"] - calculatedData.actual["total-time"];
    

    return {
      ...tasklistPricing,
      CALCULATED: calculatedData,
    };
  };

  // Set pricing app data as state
  useEffect(() => {
    if (
      !projectDetails ||
      !projectDetails.project_pricing ||
      !tasklists
    ) {
      return;
    }

    // Projected Times for each Tasklist -> Team -> Role
    const projectPricing = projectDetails.project_pricing;

    // Group projected times by tasklist, then calculate total time cost for each ProjectPricing (Tasklist)
    const projectedTimesGroupedByTasklist: TasklistPricingWithCalculatedData[] = projectPricing ? tasklists.map(
      (tasklist: Tasklist) => {
        return calculateTotalTimeCost({
          project_pricing_id: projectPricing.id,
          tasklist_id: tasklist.id,
          tasklist_name: tasklist.name,
          comments: "", // TODO: Add comments
          actual_cost: projectPricing.actual_cost,
          price: projectPricing.price,
          currency: projectPricing.currency,
          projected_times: projectPricing.projected_times.filter(
            (pt: ProjectedTime) => Number(pt.tasklist_id) === tasklist.id
          ),
        });
      }
    ) : [];

    // COGS
    const projectInvoices = projectDetails.invoices;
    // Estimated/Budgeted expenses for the project
    const projectedExpenses = projectPricing.projected_expenses;

    // Group actualized project invoices (expenses) by `type - payee_name`
    const expensesGroupedByType: ProjectExpenseTypes = projectInvoices.reduce(
      (acc: any, curr: BriefProjectInvoice) => {
        const key = `${curr.expense_type} - ${curr.payee_name}`; // Invoice.expense_type
        const projectedExpense = projectedExpenses.find((pe: ProjectedExpense) => `${pe.expense_type} - ${pe.payee_name}` === key);

        if (!acc[key]) {
          // Create new key-value for each type of expense "expense_type"
          acc[key] = {
            invoices: [],
            expense_type: curr.expense_type,
            payee_name: curr.payee_name,
            expense_actual_total: 0,
            // Set budgeted total for this type of expense (This will be a fixed value from the db)
            expense_budget_total: projectedExpense ? projectedExpense.budget_in_cents : 0, 
          };
        }
        acc[key].invoices.push(curr);
        // add to actual total for this type of expense
        acc[key].expense_actual_total += curr.amount_in_cents;
        return acc;
      },
      {}
    );

    // console.log("expensesGroupedByType: ", expensesGroupedByType);

    // These are the expenses that have not been actualized yet (no project invoices)
    // It could also be the case that the `expense_type - payee_name` key is just not found in the invoices.
    // Since the budgets are set before the invoices are made, the accounts team may have assumed a certain payee name but the bookkeeper used a different one
    const ProjectedExpensesYetToBeInvoiced = projectedExpenses.filter((pe: ProjectedExpense) => {
      const key = `${pe.expense_type} - ${pe.payee_name}`;
      return !expensesGroupedByType[key];
    });

    // console.log("ProjectedExpensesYetToBeInvoiced: ", ProjectedExpensesYetToBeInvoiced);

    const cogs = {
      expenses: expensesGroupedByType,
      // Sum budgeted total across all expense types + expenses that have not been invoiced yet (or not found in invoices)
      cogs_budget_total: Object.keys(expensesGroupedByType).reduce(
        (acc: number, curr: string) => {
          const expense = expensesGroupedByType[curr];
          return acc + expense.expense_budget_total;
        }, 0) + ProjectedExpensesYetToBeInvoiced.reduce((acc: number, curr: ProjectedExpense) => acc + curr.budget_in_cents, 0),
      // Sum actual total across all expenses
      cogs_actual_total: Object.keys(expensesGroupedByType).reduce(
        (acc: number, curr: string) => {
          const expense = expensesGroupedByType[curr];
          return acc + expense.expense_actual_total;
        }, 0),
    };

    // console.log("cogs: ", cogs);

    setPricingAppData(() => {
      const newData: PricingAppData = {
        projectId: projectId,
        tasklists: tasklists,
        db: {
          project_pricing_id: projectPricing ? projectPricing.id : 0,
          project_pricings: projectedTimesGroupedByTasklist,
          cogs: cogs,
        },
      };
      
      return newData;
    });
  }, [tasklists, projectDetails]);

  /**
   * used in TasklistDetails.tsx
   */
  // This function is to structure the data used in each Team Column
  const convertTasklistDetailsRowDataByTeam = (
    teamName: string,
    projectedTimes: ProjectedTime[],
    meanProjectRates: MeanProjectRatesByTeamAndRole[],
    transformedTimeEntries: TimeEntryWithRate[]
  ) => {
    const teamId = uniqueTeams.find((team) => team.teamName === teamName)?.id;

    // Initialize data with 0 total for both budget and actual
    const budget: ProjectPricingCalculatedData["budget"] = {};
    const actual: ProjectPricingCalculatedData["actual"] = {};

    const timeEntriesForTasklist = transformedTimeEntries.filter(
      (entry) => entry.tasklist_id === selectedTasklist?.id
    );
    const timeEntriesForTeam = timeEntriesForTasklist.filter(
      (entry) => entry.user_team_id === teamId
    );

    projectedTimes.forEach((item: ProjectedTime) => {
      const roleTitle = uniqueRoles.find(
        (role: Role) => role.id === item.role_id
      )?.roleTitle;
      const keyName = `${teamName}-${roleTitle}`;
      const meanProjectRateForTeamAndRole = meanProjectRates?.find(
        (rate) => rate.team.id === item.team_id && rate.role.id === item.role_id
      );
      const timeEntriesForRole = timeEntriesForTeam?.filter(
        (entry) => entry.user_role_id === item.role_id
      );


      // For Budget time and cost
      const rateInCents = meanProjectRateForTeamAndRole?.meanRateInCents ?? 0;
      const budgetTimeHours = item.projected_minutes / 60; // projected hours that we think this role & team will take
      const costInCents = budgetTimeHours * rateInCents; // total cost in cents based on average rate of team & role

      // For Actual time and cost
      const actualTimeHours = timeEntriesForRole.reduce(
        (acc: number, curr: TimeEntryWithRate) => acc + curr.minutes / 60,
        0
      );
      const actualCostInCents = timeEntriesForRole.reduce(
        (acc: number, curr: TimeEntryWithRate) =>
          acc + (curr.minutes / 60) * curr.user_project_rate,
        0
      );

      // Assign "Projected Budget" and "Actuals" to the initial data
      // Budget is based on user input on time (Hrs)
      budget[`id`] = item.id;

      // Time & Cost
      budget[`${keyName}-time`] = budgetTimeHours;
      budget[`${keyName}-cost`] = costInCents;

      // Totals across each role of the team
      budget[`${teamName}-total-time`] += budgetTimeHours;
      budget[`${teamName}-total-cost`] += costInCents;

      // Actual should use data from outside, since it's not editable it should be fixated
      // ID needs to be unique for each row
      actual[`id`] = item.id;

      // Time & Cost for this role of the team
      actual[`${keyName}-time`] = actualTimeHours;
      actual[`${keyName}-cost`] = actualCostInCents;

      // Totals across this role of the team
      actual[`${keyName}-total-time`] += actualTimeHours;
      actual[`${keyName}-total-cost`] += actualCostInCents;
    });

    return [
      { ...budget, [`total-time`]: 0, [`total-cost`]: 0 },
      { ...actual, [`total-time`]: 0, [`total-cost`]: 0 },
    ];
  };

  // Update Team Role Hour
  const updateTeamRoleProjectedTime = (
    _: number, // Not in use for now
    projectedTimeId: number, // id to use in mutation
    valueInHours: number // New value from <input>
  ) => {
    const newProjectedMinutes = valueInHours * 60;

    // Maybe set a loading state here to true before mutation?

    // Update the value in the database then update the state
    updateProjectedTime({
      variables: {
        id: projectedTimeId,
        minutes: newProjectedMinutes,
      },
      update: (cache, { data }) => {
        // Update the cache here
        // console.log("updateTeamRoleProjectedTime: cache", cache);
        // console.log("updateTeamRoleProjectedTime: data", data);
      },
      // TODO: set onCompleted to handle success
      onCompleted: (data) => {
        // console.log("updateTeamRoleProjectedTime: data", data);
        // update the state here ?
      },
      // TODO: set onError to handle error
      onError: (error) => {
        console.error("updateTeamRoleProjectedTime: error", error);
        toast({
          title: "Error updating projected time",
          description: "Please check the logs for more information",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });

    // update the pricing app data state optimistically for now
    setPricingAppData((prevData) => {
      if (!prevData) {
        return null;
      }
      return {
        ...prevData,
        db: {
          ...prevData.db,
          project_pricings: prevData.db.project_pricings?.map(
            (project_pricing) => {
              // Find the project pricing based on selected tasklist
              if (project_pricing.tasklist_id === selectedTasklist?.id) {
                // Find the target projected time, update value here
                // update projected_minutes based on new value
                return calculateTotalTimeCost(project_pricing);
              }
              return project_pricing;
            }
          ),
        },
      };
    });
  };

  // console.log("usePricing.tsx: pricingAppData", pricingAppData);

  const value = {
    // Utils
    formatNumber,
    
    projectId,

    peopleData: peopleData?.people,

    pricingAppData,
    setPricingAppData,

    // Tasklist Section
    tasklists,
    selectedTasklist,
    setSelectedTasklist,

    // Detail Section
    currentDetailView,
    setCurrentDetailView,

    convertTasklistDetailsRowDataByTeam,

    updateTeamRoleProjectedTime,

    meanProjectRates,
    uniqueRoles,
    uniqueTeams,

    transformedTimeEntries,
  };

  return (
    <PricingContext.Provider value={value}>{children}</PricingContext.Provider>
  );
};
