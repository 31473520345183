export const projectStatuses = [
  "In Progress",
  "Cancelled",
  "On Hold",
  "Completed",
  "Time tracking",
  "COGs",
  "Internal",
  null
] as const;

export type ProjectStatus = typeof projectStatuses[number];
