
import React from "react";
import { css } from "twin.macro";

export const TextTruncateRevealOnHover = ({
  text,
  className,
  wrap,
  maxWidth = "20rem"
}: {
  text: string | React.ReactNode;
  className?: string;
  wrap?: boolean;
  maxWidth?: string;
}) => {
  return (
    <div
      className={"group " + className}
      css={css`
        position: relative;
        margin: -0.5rem;
        padding: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        max-width: ${maxWidth};

        &:hover {
          .group-hover-hide {
            opacity: 0;
          }
          .group-hover-show {
            opacity: 1;
            pointer-events: all;
          }
        }
      `}
    >
      <div
        className="group-hover-hide"
        css={css`
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        `}
      >
        {text}
      </div>
      <div
        className="group-hover-show"
        css={css`
          user-select: none;
          position: absolute;
          pointer-events: none;
          z-index: 1;
          width: max-content;
          min-width: calc(100% + 1px);
          padding: 0.1rem 0.5rem;
          margin-top: -0.1rem;
          margin-bottom: -0.1rem;
          --tw-bg-opacity: 1;
          background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
          opacity: 0;
          display: flex;
          align-items: center;

          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          /* background-color: #f9f9ff; */
          border-right: 1px solid #ccc;
          box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.1);

          ${wrap
            ? css`
                width: 100%;
                height: max-content;
              `
            : ""}
        `}
      >
        {text}
      </div>
    </div>
  );
};
