import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";
import { ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContextProvider } from "./useAuth";
import { ErrorBoundary } from "./ErrorBoundary";

// Only initialize Sentry if the app is not running on localhost
if (!window.location.hostname.includes("localhost")) {
  Sentry.init({
    dsn: "https://864839f87d554af4913fc5559e289a8e@o558997.ingest.sentry.io/5693268",
    integrations: [SentryBrowser.browserTracingIntegration()],
    // Adjust sample rate in production as needed
    tracesSampleRate: 0.1,  // Use 1.0 for 100% or adjust for lower
    environment: window.location.hostname, // Set environment based on hostname
  });

  // Set additional context if needed
  Sentry.setContext(
    "fullstory",
    { sessionUrl: window.FS?.getCurrentSessionURL?.() }
  );
}

// Set up the root React render
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Router>
        <ErrorBoundary>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </ErrorBoundary>
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);

// Enable performance monitoring
reportWebVitals();
