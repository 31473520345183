import { Stack } from "@chakra-ui/react";
import React from "react";
import * as Sentry from "@sentry/react";

export class ErrorBoundary extends React.Component<
  {
    // https://docs.sentry.io/enriching-error-data/additional-data/#setting-the-level
    sentryErrorLevel?: Sentry.Severity;
  },
  {
    caughtError: Error | null;
  }
> {
  state = {
    caughtError: null as Error | null,
  };

  componentDidCatch(error: Error) {
    console.trace(error.toString()); // eslint-disable-line no-console

    Sentry.captureException(error, {
      level: this.props.sentryErrorLevel ?? Sentry.Severity.Fatal,
    });
    this.setState({ caughtError: error });
  }

  render() {
    const { children } = this.props;
    const { caughtError } = this.state;
    if (caughtError) {
      return (
        <Stack justifyContent="center" alignItems="center" h="full">
          <h2>Error encountered: </h2>
          <pre>{caughtError.message}</pre>
        </Stack>
      );
    }

    return children;
  }
}
