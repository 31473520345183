import React from 'react';

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    color: '#333',
    borderBottom: '2px solid #333',
    paddingBottom: '10px',
  },
  section: {
    marginBottom: '20px',
  },
  list: {
    paddingLeft: '20px',
    listStyleType: 'disc',
  }
};

export const EndUserLicenseAgreement = function() {

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Maven Dashboard - End-User License Agreement</h1>
      <br/>
      <p>This End-User License Agreement ("Agreement") is a binding contract between you ("User" or "you") and Maven Medical Marketing Inc. ("Company," "we," or "us") for the use of the Maven Dashboard web application ("Application"). By accessing or using the Application, you agree to be bound by the terms of this Agreement.</p>
      <br/>
      <div style={styles.section}>
        <p>The Maven Dashboard is strictly for internal use by Maven Medical Marketing Inc. employees only. This Application is not accessible to the public by any means and is not intended for external use. Any attempt to access or use this Application or it's data by non-employees without explicit request or permission from the Company is strictly prohibited and may result in legal action.</p>
      </div>
      <div style={styles.section}>
        <h2>1. License Grant</h2>
        <p>Subject to the terms of this Agreement, Company grants you a limited, non-exclusive, non-transferable license to access and use the Application solely for internal business purposes within the Company.</p>
      </div>

      <div style={styles.section}>
        <h2>2. Scope of Use</h2>
        <p>The Application is designed for tracking project budgets, employee time logs, project expenses, and managing client invoices. It also integrates with Teamwork API, Intuit QuickBooks API, and other internal custom tools.</p>
      </div>

      <div style={styles.section}>
        <h2>3. Restrictions on Use</h2>
        <p>You agree not to:</p>
        <ul style={styles.list}>
          <li>Use the Application for any purpose other than the Company's internal business needs</li>
          <li>Share your login credentials with any third party</li>
          <li>Attempt to reverse engineer, decompile, or disassemble the Application</li>
          <li>Copy, modify, or create derivative works of the Application</li>
          <li>Use the Application in any way that violates applicable laws or regulations</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>4. Intellectual Property Rights</h2>
        <p>The Application, including all content, features, and functionality, is owned by the Company and is protected by copyright, trademark, and other intellectual property laws.</p>
      </div>

      <div style={styles.section}>
        <h2>5. Data Privacy and Security</h2>
        <p>The Company will handle all data entered into or generated by the Application in accordance with our internal data privacy and security policies. You agree to use the Application in compliance with these policies.</p>
      </div>

      <div style={styles.section}>
        <h2>6. Third-Party Integrations</h2>
        <p>The Application integrates with third-party services such as Teamwork API and Intuit QuickBooks API. Your use of these integrations is subject to the respective terms of service of these third-party providers.</p>
      </div>

      <div style={styles.section}>
        <h2>7. Termination</h2>
        <p>Your right to use the Application will automatically terminate upon the cessation of your employment with the Company. The Company reserves the right to suspend or terminate your access to the Application for any violation of this Agreement.</p>
      </div>

      <div style={styles.section}>
        <h2>8. Disclaimer of Warranties</h2>
        <p>The Application is provided "as is" without any warranties, express or implied. The Company does not warrant that the Application will be error-free or uninterrupted.</p>
      </div>

      <div style={styles.section}>
        <h2>9. Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Application.</p>
      </div>

      <div style={styles.section}>
        <h2>10. Amendments</h2>
        <p>The Company reserves the right to modify this Agreement at any time. Continued use of the Application following any such changes shall constitute your consent to such changes.</p>
      </div>

      <div style={styles.section}>
        <h2>11. Governing Law</h2>
        <p>This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.</p>
      </div>

      <p>By using the Maven Dashboard, you acknowledge that you have read this Agreement, understand it, and agree to be bound by its terms and conditions. If you do not agree to this Agreement, you may not access or use the Application.</p>
    </div>
  );
};

export const PrivacyPolicy = function() {

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Maven Dashboard - Privacy Policy</h1><br/>
      <p>This Privacy Policy describes how Maven Medical Marketing Inc. ("we", "us", or "our") collects, uses, and protects the personal information of users ("you" or "your") of the Maven Dashboard web application ("Application").</p>
      <br/>
      <div style={styles.section}>
        <p>The Maven Dashboard is an internal tool designed exclusively for use by employees of Maven Medical Marketing Inc. This Application is not accessible to the public or any external parties. All data collected, processed, and stored within the Application is considered confidential company information and is subject to our internal data protection policies.</p>
      </div>
      <div style={styles.section}>
        <h2>1. Information We Collect</h2>
        <p>We collect and process the following types of information:</p>
        <ul style={styles.list}>
          <li>Personal information such as name, email address, and employee ID</li>
          <li>Project-related data including budgets, time logs, and expenses</li>
          <li>Client information for invoicing purposes</li>
          <li>Usage data and analytics related to your interaction with the Application</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>2. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul style={styles.list}>
          <li>To provide and maintain the Application</li>
          <li>To track project progress and manage resources</li>
          <li>To generate and manage client invoices</li>
          <li>To improve and optimize the Application's performance and user experience</li>
          <li>To comply with legal obligations and company policies</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>3. Data Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
      </div>

      <div style={styles.section}>
        <h2>4. Third-Party Integrations</h2>
        <p>The Application integrates with third-party services such as Teamwork API and Intuit QuickBooks API. Your use of these integrations is subject to the respective privacy policies of these third-party providers.</p>
      </div>

      <div style={styles.section}>
        <h2>5. Data Retention</h2>
        <p>We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, and as required by applicable laws and regulations.</p>
      </div>

      <div style={styles.section}>
        <h2>6. Your Rights</h2>
        <p>You have the right to:</p>
        <ul style={styles.list}>
          <li>Access the personal information we hold about you</li>
          <li>Request correction of any inaccurate personal information</li>
          <li>Request deletion of your personal information, subject to any legal obligations to retain certain data</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
      </div>

      <div style={styles.section}>
        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at support@mavenmm.com.</p>
      </div>

      <p>Last updated: August 27th, 2024</p>
    </div>
  );
};