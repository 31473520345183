import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import _ from "lodash";
import { useCallback, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  PromptProjectQuery,
  PromptProjectQueryVariables,
} from "../../codegen/graphql-types";
import { usePromptState } from "../../helpers/usePromptState";

export const usePromptSelectProject = () => {
  const [selectedProjectId, setSelectedProjectId] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const { isOpen, onCancel, prompt, onResolve } = usePromptState<{
    selectedProjectId: string | undefined;
    notes: string | undefined;
  }>();

  const { data, loading } = useQuery<
    PromptProjectQuery,
    PromptProjectQueryVariables
  >(
    gql`
      query PromptProject {
        projects {
          id
          component_code
          name
        }
      }
    `,
    {}
  );

  const loadSuggestedOptions = useCallback(
    _.debounce((inputValue, callback) => {
      // getOptions(inputValue).then(options => callback(options))
      callback(
        data?.projects.flatMap((project) => {
          if (
            inputValue &&
            !`${project.component_code} ${project.name}`
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          ) {
            return [];
          }
          return {
            value: project,
            label: project.name,
          };
        })
      );
    }, 500),
    [data]
  );

  const modal = (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={() => {
        onCancel();
      }}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={(e) => {
          e.preventDefault();
          onResolve({ selectedProjectId, notes });
        }}
      >
        <ModalHeader>Update Project</ModalHeader>
        <ModalBody pb={6}>
          <AsyncCreatableSelect
            loadOptions={loadSuggestedOptions}
            isDisabled={loading}
            isMulti={false}
            closeMenuOnSelect={true}
            formatCreateLabel={(inputValue) =>
              `Note as "${inputValue}" without associating with a project`
            }
            onChange={(selectedOption, { action }) => {
              if (action === "create-option") {
                setSelectedProjectId(undefined);
                setNotes(selectedOption?.value);
              } else if (action === "select-option") {
                setSelectedProjectId(selectedOption?.value.id);
                setNotes(undefined);
              }
            }}
          />
        </ModalBody>

        <ModalFooter>
          <Button onClick={onCancel} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return {
    modal,
    promptSelectProject: prompt,
  };
};
