import { sumEntryMinutes, isDateWithinRange, DaysInRange, daysInDateRange } from './dates';
import { TimeEntry, DayOff } from "../codegen/graphql-types";
import { parseISO } from "date-fns";

type capacityCalculatorProps = {
  totalTargetMinutesPerDay: number; // total target minutes (billable + non-billable) per day
  timeEntries: TimeEntry[];
  startDate: string;
  endDate: string;
  daysOff: DayOff[];
  numOfWorkingDaysForYear?: number; // optional only used for full year calculations
};

// Function to calculate the totalMinutes / targetMinutes for a given date range
export const capacityCalculator = ({timeEntries, startDate, endDate, daysOff, totalTargetMinutesPerDay, numOfWorkingDaysForYear}: capacityCalculatorProps) => {
  // Calculating total number of days in the range
  const days: DaysInRange = daysInDateRange(
    parseISO(startDate),
    parseISO(endDate), 
    daysOff
  );

  // Total target hours per day 
  const totalTargetHoursPerDay: number = totalTargetMinutesPerDay / 60;

  // Total working days in range
  const totalWorkingDaysInRange: number = numOfWorkingDaysForYear ?? days.numberOfWorkingDaysInRange;

  // Calculating target total minutes for the date range
  const targetMinutes = totalWorkingDaysInRange * totalTargetMinutesPerDay;
  
  //console.log(` Target minutes per day: ${totalTargetMinutesPerDay}\n Target hours per day: ${totalTargetMinutesPerDay / 60}\n Number of working days in range: ${days.numberOfWorkingDaysInRange}.\n Target number of hours in range: ${targetMinutes / 60}`)
  
  // console.log("Entries before filtering date range", data);
  // filter the data to only include entries that are within the date range
  const entriesWithinDateRange: TimeEntry[] = timeEntries.filter(
    (entry: TimeEntry) =>
      isDateWithinRange(startDate, endDate, entry.date)
  );
  // console.log("Entries within date range", entriesWithinDateRange);

  // Average total hours worked in date range
  const averageTotalHoursPerDay: string = (entriesWithinDateRange.reduce(sumEntryMinutes, 0) / totalWorkingDaysInRange / 60).toFixed(2);

  // Calculating total hours from time entries
  const totalMinutes = entriesWithinDateRange.reduce(sumEntryMinutes, 0);

  // Percentage totalMinutes of targetMinutes (aka capacity)
  const percentageOfTarget = Math.round(totalMinutes / targetMinutes * 100);
  
  return {
    totalMinutes,
    targetMinutes,
    averageTotalHoursPerDay,
    totalTargetHoursPerDay,
    percentageOfTarget,
    totalWorkingDaysInRange
  };
};
