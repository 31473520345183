import * as QBO from "../../../../server/lib/quickbooks/types";
import type { QuickbooksResources } from "./GetQuickbooksResources";
import tw from "twin.macro";
import { Select } from "@chakra-ui/react";

const SelectQuickbooksCustomer = (props: {
  isDisabled: boolean,
  values: QBO.QuickbooksInvoice,
  setValues: (values: QBO.QuickbooksInvoice) => void,
  qbResources: QuickbooksResources,
  warning: boolean,
  setWarning: (warning: boolean) => void,
  customerId: string,
  qbCustomerDisplayName: string,
  defaultSalesTerm: QBO.Term | undefined,
}) => {

  console.log(props.customerId);

  return (
    <div>
      <label htmlFor="customerId">
        Customer
        {!props.customerId && props.warning && (
          <span style={{ color: "red" }}>
            *<br />
            No quickbooks customer was found for Company: "{props.qbCustomerDisplayName}". Please do the following
            <br />
            1) Log into quickbooks to Create the customer "{props.qbCustomerDisplayName}" 2) Refresh this page.<br/>
            <button
              css={tw`text-blue-500 underline`}
              onClick={() => {
                props.setWarning(false);
              }}
            >
              Got It!
            </button>
          </span>
        )}
      </label>
      {props.qbResources.Customer && (
        <Select
          isDisabled={props.isDisabled}
          id="customerId"
          name="customerId"
          value={props.values.customerId}
          onChange={(e) => {
            const selectedCustomerId = e.target.value;
            const selectedCustomer = props.qbResources.Customer
              ? props.qbResources.Customer.find(
                  (customer) => customer.Id === selectedCustomerId
                )
              : null;
            if (selectedCustomer) {
              const newCustomerTerm =
                props.qbResources.Term.find(
                  (term) => term.Id === selectedCustomer.SalesTermRef?.value
                ) ?? props.defaultSalesTerm;
              const newDueDate: string = new Date(
                new Date().setDate(
                  new Date().getDate() + (newCustomerTerm?.DueDays ?? 30)
                )
              ).toLocaleDateString("en-CA", { timeZone: "UTC" });

              props.setValues({
                ...props.values,
                customerId: selectedCustomer.Id,
                customerDisplayName: selectedCustomer.DisplayName,
                invoiceTerm: newCustomerTerm,
                invoiceDueDate: newDueDate,
              });
            }
          }}
        >
          {props.qbResources.Customer.map((customer, i) => (
            <option key={i} value={customer.Id}>
              {customer.DisplayName}
            </option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default SelectQuickbooksCustomer;