/** @jsxImportSource @emotion/react */

import { useEffect, useState, useMemo, useContext, ReactNode } from "react";
import tw, { css } from "twin.macro";
import { PricingContext } from "../../helpers/usePricing";
import { ProjectedTime } from "../../codegen/graphql-types";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  CellContext,
} from "@tanstack/react-table";
import { Button } from "@chakra-ui/react";

const updateColour = "#FFFAC8";
const totalCss = tw`font-bold`;

// const columnHelper = createColumnHelper<ProjectedTimeType>();
const TableCell = ({ getValue, row, column, table }: any) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);
  };
  return (
    <input
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={onBlur}
      css={[
        tw`text-center w-full h-full`,
        css`
          background-color: ${updateColour};
        `,
      ]}
      style={{ width: `${value.toString().length + 2}ch` }}
      type="number"
    />
  );
};

export const TotalCostCell = ({ getValue, row }: any) => {
  const { formatNumber } = useContext(PricingContext);
  // render the cell with red text if the value is negative and use a "-" sign
  const value = getValue();
  const isNegative = value < 0;
  const isLastRow = row.index === 2; // Adjust this condition as needed
  return isNegative && isLastRow ? (
    <span css={totalCss} style={{ color: "red" }}>
      -{`$${formatNumber(Math.abs(value / 100))}`}
    </span>
  ) : (
    <span css={totalCss}>{`$${formatNumber(value / 100)}`}</span>
  );
};

const formatHours = (hours: number) => {
  // if zero, return 0
  if (hours === 0 || !hours) {
    return 0;
  } else {
    // otherwise, round to 1 decimal place
    return hours.toFixed(1);
  }
}

const TasklistDetailsTeamTable = ({
  groupedData,
  rowData,
}: {
  groupedData: { [key: string]: ProjectedTime[] };
  rowData: { [key: string]: number }[];
}) => {
  const { updateTeamRoleProjectedTime, formatNumber, uniqueRoles } = useContext(
    PricingContext
  );

  const columns = useMemo(
    () => [
      {
        header: "",
        id: "column-header",
        accessorFn: (_: any, index: number) => {
          if (index === 0) {
            return "Projected Budget";
          } else if (index === 1) {
            return "Actuals";
          } else {
            return "";
          }
        },
        meta: {
          tdProps: {
            style: {
              backgroundColor: "#f7f7f7",
            },
          },
        },
        cell: (props: CellContext<{ [key: string]: number }, string>) => {
          return <strong>{props.getValue()}</strong>;
        }
      },
      ...Object.entries(groupedData)
        .map(([teamName, teamData]: [string, ProjectedTime[]]) => {
          return {
            header: teamName,
            accessorFn: `${teamName}-team`,
            columns: [
            // Columns for each role
            ...teamData.map((td: ProjectedTime) => {
                const roleTitle = uniqueRoles.find(
                  (role) => role.id === td.role_id
                )?.roleTitle;
                return {
                  header: roleTitle,
                  accessorFn: `${teamName}-${roleTitle}-name`,
                  id: `${teamName}-${roleTitle}-cost`,
                  columns: [
                    {
                      header: "Hrs",
                      id: td.id, // use the id of the projected time from the database to made updates easier
                      accessorFn: (row: { [key: string]: number }) => {
                        return row[`${teamName}-${roleTitle}-time`];
                      },
                      meta: {
                        tdProps: {
                          style: {
                            backgroundColor: updateColour,
                          },
                        },
                      },
                      cell: (
                        props: CellContext<{ [key: string]: number }, number>
                      ) => {
                        // Sometimes value can be NaN if data is not available
                        const value = props.getValue();
                        return props.row.index === 0 ? (
                          <TableCell {...props} />
                        ) : (
                          <div>
                            {typeof value === 'number' ? formatHours(value) : value}
                          </div>
                        );
                      },
                    },
                    {
                      header: "Cost",
                      id: `${teamName}-${roleTitle}-cost`,
                      accessorFn: (row: { [key: string]: number }) => {
                        if (row[`${teamName}-${roleTitle}-cost`]) {
                          return `$${formatNumber(
                            row[`${teamName}-${roleTitle}-cost`] / 100
                          )}`;
                        } else {
                          return "";
                        }
                      },
                    },
                  ],
                };
              }
            ),
              // Column for the total (Team total)
              {
                header: "Total",
                accessorFn: `${teamName}-total-name`,
                id: `${teamName}-total-name`,
                columns: [
                  {
                    header: "Hrs",
                    id: `${teamName}-total-time`,
                    accessorFn: (row: any) => row[`${teamName}-total-time`],
                    cell: (props: CellContext<{ [key: string]: number }, number>) => {
                      const value: number = props.getValue();
                      return <span css={totalCss}>{formatHours(value)}</span>;
                    },
                  },
                  {
                    header: "Cost",
                    id: `${teamName}-total-cost`,
                    accessorFn: (row: any) => row[`${teamName}-total-cost`],
                    cell: (props: CellContext<{ [key: string]: number }, ReactNode>) => (
                      <TotalCostCell {...props} />
                    ),
                  },
                ],
              },
            ].sort((a, b) => {
              // sort the columns by role title (id) alphabetically,
              // but make sure the total is always last
              // ensure header is defined
              if (!a.header || !b.header) {
                return 0;
              }
              if (a.header === "Total") {
                return 1;
              } else if (b.header === "Total") {
                return -1;
              } else {
                return a.header.localeCompare(b.header);
              }
            }),
          };
        })
        .sort((a, b) => {
          // sort the teams alphabetically
          return a.header.localeCompare(b.header);
        }),
      {
        header: "Tasklist Totals",
        id: "total",
        accessorFn: (row: any) => row[`total-cost`],
        columns: [
          {
            header: "Hrs",
            id: "total-time",
            accessorFn: (row: any) => row[`total-time`],
            cell: (props: CellContext<{ [key: string]: number }, number>) => {
              const value = props.getValue();
              return <span css={totalCss}>{formatHours(value)}</span>;
            },
          },
          {
            header: "Cost",
            id: "total-cost",
            accessorFn: (row: any) => row[`total-cost`], // Only access the raw data with accessorFn
            cell: (props: CellContext<{ [key: string]: number }, unknown>) => (
              <TotalCostCell {...props} />
            ),
          },
        ],
      },
    ],
    [groupedData]
  );

  const table = useReactTable({
    data: rowData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (
        rowIndex: number,
        projectedTimeId: string,
        value: string
      ) => {
        updateTeamRoleProjectedTime(
          rowIndex,
          Number(projectedTimeId),
          Number(value)
        );
      },
      updateCogs: (rowIndex: number, columnId: string, value: string) => {
        console.log("updateCogs", rowIndex, columnId, value);
      },
    },
    debugTable: true,
  });

  return (
    <div>
      <table css={tw`border-collapse border border-gray-200 text-sm`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    css={tw`border border-gray-200 px-2 py-1`}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex, rowArray) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                const columnHeader = cell.column.columnDef.header;
                // @ts-ignore
                const tdProps = cell.column.columnDef.meta?.tdProps || {}; // Access tdProps from meta, default to empty object if not defined

                // if this is the first column, render the row header
                if (cell.column.id === "column-header") {
                  return (
                    <td
                      key={cell.id}
                      css={tw`border border-gray-200 px-2 py-1`}
                      {...tdProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }

                // If this is the first row, use tdProps in the returned td
                if (rowIndex === 0 && columnHeader === "Hrs") {
                  return (
                    <td
                      key={cell.id}
                      css={tw`border border-gray-200 text-center`}
                      {...tdProps}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                }

                // If this is the last row render the cell empty if it's the Hrs column
                if (
                  rowIndex === rowArray.length - 1 &&
                  columnHeader === "Hrs"
                ) {
                  return <td key={cell.id}>&nbsp;</td>;
                }

                return (
                  <td
                    key={cell.id}
                    css={tw`border border-gray-200 text-center`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TasklistDetailsTeamTable;
