
import tw, { css } from "twin.macro";
import { useMemo } from "react";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { TimeEntry, DayOff } from "../../codegen/graphql-types";
import { format, parseISO } from "date-fns";
import { capacityCalculator } from "../capacityCalculator";

type FluxCapacoMeterProps = {
  totalTargetMinutesPerDay: number; // total target minutes (billable + non-billable) per day
  data: TimeEntry[];
  startDate: string;
  endDate: string;
  daysOff: DayOff[];
  numOfWorkingDaysForYear?: number; // optional only used for full year calculations
};

const FluxCapacoMeter = ({ 
  totalTargetMinutesPerDay, 
  data, 
  startDate, 
  endDate,
  numOfWorkingDaysForYear,
  daysOff
}: FluxCapacoMeterProps) => {
  // Calculate the capacity for the given date range and memoize the result
  const { totalMinutes, targetMinutes, averageTotalHoursPerDay, totalTargetHoursPerDay, percentageOfTarget, totalWorkingDaysInRange } = useMemo(() => {
    return capacityCalculator({
      timeEntries: data,
      startDate,
      endDate,
      daysOff,
      totalTargetMinutesPerDay,
      numOfWorkingDaysForYear
    });
  }, [data, startDate, endDate, daysOff, totalTargetMinutesPerDay, numOfWorkingDaysForYear]);

  // Convert the ratio to a range of -1 to 1, where 0 is the middle for a vertical arrow character
  const arrowRatio = (totalMinutes / targetMinutes - 1) * 2;
  // Clamp the ratio to -1 to 1
  const clampedRatio = Math.max(-1, Math.min(1, arrowRatio));
  // Convert the clamped ratio to degrees (-90 to 90)
  const arrowDegree = clampedRatio * 90;

  return (
    <>
      <div css={tw`relative top-2 flex items-center justify-center w-24 h-24 mx-auto z-10`}>
        {/* The gradient arc gauge/meter */}
        <div 
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(to right, #f1f52f 0%, #38c763 66%, #c92830 100%) border-box',
            border: '8px solid transparent',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
        {/* The arrow that pivots */}
        <div
          css={tw`transition-all absolute flex items-center justify-center text-black z-10 text-center leading-none w-8 h-8`}
          style={{
            transform: `rotate(${arrowDegree}deg)`,
            transformOrigin: 'bottom', // Rotate around the bottom center
            bottom: '50%', // Adjust this value as needed
            lineHeight: "0",
            verticalAlign: "bottom",
            fontSize: "3rem",
            fontWeight: "bold",
            transitionDuration: "0.5s",
          }}
        >↑</div>
        <div
          css={tw`flex items-center justify-center text-black z-10 text-center leading-none w-8 h-8`}
          style={{
            position: "relative",
            top: "25px", 
            height: "50px",
            width: "100px",
            background: "white",
            zIndex: 5,
            opacity: "1",
          }}
        >
          <Capacity 
            totalMinutes={totalMinutes}
            startDate={startDate}
            endDate={endDate}
            totalWorkingDaysInRange={totalWorkingDaysInRange}
            averageTotalHoursPerDay={averageTotalHoursPerDay}
            totalTargetHoursPerDay={totalTargetHoursPerDay}
            percentageOfTarget={percentageOfTarget}
            targetMinutes={targetMinutes}
          />
        </div>
      </div>
    </>
  );
};

export const Capacity = ({
  totalMinutes,
  startDate,
  endDate,
  totalWorkingDaysInRange,
  averageTotalHoursPerDay,
  totalTargetHoursPerDay,
  percentageOfTarget,
  targetMinutes
}: {
  totalMinutes: number;
  startDate: string;
  endDate: string;
  totalWorkingDaysInRange: number;
  averageTotalHoursPerDay: string;
  totalTargetHoursPerDay: number;
  percentageOfTarget: number;
  targetMinutes: number | undefined;
}) => {

  const percentOfTargetIsInfinity = percentageOfTarget === Infinity;

  return (
    <Tooltip 
      label={
        <Stack>
          <Text>{`From ${format(parseISO(startDate), "MMM dd yyyy")} to ${format(parseISO(endDate), "MMM dd yyyy")}:`}</Text>
          <Text>{`${(totalMinutes / 60).toFixed(2)} total hours logged`}</Text>
          <Text>&divide;</Text>
          <Text>{`${totalWorkingDaysInRange} total working days`}</Text>
          <Text>{`= ${averageTotalHoursPerDay} avg total hrs/day`}</Text>
          {targetMinutes && (
            <>
              <Text>&divide;</Text>
              <Text>{`${totalTargetHoursPerDay} target total hrs/day`}</Text>
              <Text>{`= ${percentageOfTarget}% of target`}</Text>
            </>
          )}
        </Stack>
      } 
      aria-label="A tooltip"
    >
      <div css={tw`cursor-pointer`}>
        {!totalTargetHoursPerDay && <strong style={{ fontSize: "14px" }}>No target set</strong>}
        {totalTargetHoursPerDay && !percentOfTargetIsInfinity ? <strong>{percentageOfTarget}%</strong> : null}
      </div>
    </Tooltip>
  );
};

export default FluxCapacoMeter;
