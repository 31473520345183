import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const SideDrawer = ({ children, isOpen, onClose, width }: any) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const variants = {
    open: { x: 0 },
    closed: { x: screenWidth },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              width: width || '50%',
              backgroundColor: 'white',
              boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              overflowY: 'auto',
            }}
          >
            <button
              onClick={onClose}
              style={{
                position: 'absolute',
                top: '10px',
                right: '20px',
                background: 'none',
                border: 'none',
                fontSize: '2em',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
            <main style={{ padding: '20px', paddingTop: '30px' }}>
              {children}
            </main>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999,
            }}
            onClick={onClose}
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default SideDrawer;