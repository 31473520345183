import { TimeEntry } from "../codegen/graphql-types";
import { FlexibleTimeEntry } from "./Employee/TimeSummaryRow";


export const nonBillableCompanyIds = [
  '60228', // Maven Medical Marketing Inc.
  '173469', // HowWeHalloween
  '77302', // FREELANCERS
];

export const peopleIdsToExclude = [
  "166435", // Chang
  "181598", // Jeffrey
  "258954", // Vadim
  "276302", // Benson
  "322966", // Barbara
  "378679", // Jenn D
  "365114", // Jenn D
  "320970", // Ripika
  "316602", // Ala
  "370293", // Thalia
  "374706", // Faye
  "374707", // Tamara
  "304724", // Art
  //"375810", // RM Bookkeeper,
  "328228", // Ibrahim
  "390495", // Carol
  "373086", // Stefko
];

// filter callback functions for time entries
export const filterBillableTime = ((entry: TimeEntry | FlexibleTimeEntry): Boolean => {
  // time entries that are not in the non-billable company list and do not have a project tag
  return !nonBillableCompanyIds.includes(entry.company_id) && !entry.project_tag_id
});
export const filterNonBillableTime = (entry: TimeEntry | FlexibleTimeEntry): Boolean => {
  // time entries that are in the non-billable company list or have a project tag
  return Boolean(nonBillableCompanyIds.includes(entry.company_id) || entry.project_tag_id);
}