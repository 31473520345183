import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import constate from "constate";
import { UserPodTimeSummaryDocument } from "../codegen/graphql-types";
import { useDateRanges } from "../Time/useDateRanges";
import { parseISO, endOfDay, startOfDay } from "date-fns";

const usePodTimeEntries = () => {
  // Date range for the last three months, and year to date/yesterday
  const { lastThreeMonths, yearToDate } = useDateRanges();
  const currentYear = new Date().getFullYear();
  
  // Include all times of day in time range - there might be small chance of missing entries due to start date timezone being in daylight savings time

  // get the time entries for the user's pod from the last three months
  const lastThreeMonthsPodTime = useQuery(UserPodTimeSummaryDocument, {
    variables: {
      start_date: startOfDay(parseISO(lastThreeMonths.startDate)).toISOString(), // GMT-0400 (Eastern Daylight Time)
      end_date: endOfDay(parseISO(lastThreeMonths.endDate)).toISOString(), // GMT-0500 (Eastern Standard Time)
      year: parseISO(lastThreeMonths.startDate).getFullYear(),
    }
  });

  // get the time entries for the user's pod from year to date
  const yearToDatePodTime = useQuery(UserPodTimeSummaryDocument, {
    variables: {
      start_date: startOfDay(parseISO(yearToDate.startDate)).toISOString(),
      end_date: endOfDay(parseISO(yearToDate.endDate)).toISOString(),
      year: currentYear
    }
  });

  return useMemo(() => ({
    lastThreeMonthsPodTime,
    yearToDatePodTime,
  }), [lastThreeMonthsPodTime, yearToDatePodTime]);
};

export const [PodTimeProvider, usePodTimeContext] = constate(usePodTimeEntries);