// Charts
import { VictoryPie, VictoryLabel } from "victory";
import { abbreviateClientName } from "../helpers/abbreviateClientName";
import { brandPalette } from "../Financials/Performance/Charts/constants";

// Custom label component to render both inside and outside labels
const CustomLabel = (props: any) => {
  const { x, y, datum, index, data } = props;
  // Ensure we're calculating angles correctly
  const wholePie = data.reduce((acc: any, cur: any) => acc + cur.y, 0);
  const currentAngle = data
    .slice(0, index)
    .reduce((acc: any, cur: any) => acc + (cur.y / wholePie) * 360, 0);
  const sliceAngle = (datum.y / wholePie) * 360;
  const angleDegrees = currentAngle + sliceAngle / 2;
  const angleRadians = (angleDegrees * Math.PI) / 180;

  // Adjust radius for outside label positioning
  const radius = 80; // May need adjustment based on your chart size
  const outsideX = x + radius * Math.sin(angleRadians);
  const outsideY = y - radius * Math.cos(angleRadians);

  return (
    <g>
      <VictoryLabel
        x={outsideX}
        y={outsideY}
        text={datum.x}
        style={{
          fill: "black",
          fontSize: 12,
          lineHeight: 16,
          fontFamily: "inherit",
        }}
        textAnchor={outsideX > x ? "start" : "end"}
      />
    </g>
  );
};

// Define the props the component expects, using more generic terms
export type PieSlice = {
  name: string;
  value: number;
};

// Component to render a pie chart with slices that are too small combined into an "Other" category if they are below a certain threshold
export const SlicedPieChart = ({
  title,
  data,
  wholePie,
  threshold = 0.1,
  width = 500,
  height = 300,
  innerRadius = 80,
  labelRadius = 30,
  padding = 100,
}: {
  title?: string;
  data: PieSlice[];
  wholePie: number;
  threshold?: number;
  width?: number;
  height?: number;
  innerRadius?: number;
  labelRadius?: number;
  padding?: number;
}) => {
  // max character limit for slice names
  const CHARACTER_LIMIT = 20;

  // Calculate the "Other" category for small slices
  const otherCategoryTotal = data
    .filter((slice) => slice.value / wholePie < threshold)
    .reduce((acc, slice) => acc + slice.value, 0);

  // Calculate the percentage for the "Other" category
  const percentageOfOtherCategory = (otherCategoryTotal / wholePie) * 100;

  // Filter out larger slices and prepare them for rendering
  const dataForLargerSlices = data
    .filter((slice) => slice.value / wholePie >= threshold)
    .map((slice) => {
      return {
        x: slice.name,
        y: (slice.value / wholePie) * 100,
      };
    });

  // Combine data for rendering, including the "Other" category
  const pieData = [
    ...dataForLargerSlices,
    { x: `Other`, y: percentageOfOtherCategory },
  ];

  // sorted pieData by y value
  const sortedPieData = pieData.sort((a, b) => b.y - a.y);

  // use the brandPalette to color the pie slices if the slice name matches a brand name
  const piePaletteBrands = brandPalette.filter((brand) =>
    pieData.some((slice) => slice.x === brand.name)
  );

  // sort the palette so that the colours line up with the brands in the pieData
  const piePalette = piePaletteBrands
    .sort((a, b) => {
      // sort the palette based on the order of the brands y value in the pieData
      const aIndex = sortedPieData.findIndex((slice) => slice.x === a.name);
      const bIndex = sortedPieData.findIndex((slice) => slice.x === b.name);
      return aIndex - bIndex;
    })
    .map((brand) => brand.primary);

  // Format the pie slices to fit the character limit and include the value
  const pieDataWithFormattedLabels = sortedPieData.map((slice) => {
    const pieLabel = abbreviateClientName(slice.x, CHARACTER_LIMIT);
    return {
      x: `${pieLabel}\n${slice.y.toFixed(2)}%`,
      y: slice.y,
    };
  });

  return (
    <svg width={width} height={height}>
      {title && (
        <VictoryLabel
          x={width / 2}
          y={20}
          textAnchor="middle"
          style={{ fontSize: 20, fontWeight: "bold", fontFamily: "inherit" }}
          text={title}
        />
      )}
      <VictoryPie
        standalone={false}
        data={pieDataWithFormattedLabels}
        width={width}
        height={height}
        padding={padding}
        colorScale={piePalette}
        labelRadius={labelRadius}
        innerRadius={innerRadius}
        labelComponent={<CustomLabel />}
      />
    </svg>
  );
};
