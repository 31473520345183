import { useState, useContext } from "react";
import tw, { css } from "twin.macro";
import { useMutation } from "@apollo/client";
import {
  CreateProjectedExpenseDocument,
  UpdateProjectedExpenseDocument,
  DeleteProjectedExpenseDocument,
} from "../../codegen/graphql-types";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import { usePricingDataContext } from "../../helpers/usePricingData";
import {
  BudgetInput,
  SelectExpenseType,
  SelectPayeeName,
} from "./expenseFields";
import type { BriefProjectedExpense } from "./expenseFields";
import { PricingContext } from "../../helpers/usePricing";
import validateBudgetFields from "./validateBudgetFields";

// This component will handle UPDATE and DELETE operations for a ProjectedExpense row in the COGSTable
// This component will handle CREATE operations for a row in the COGSTable for expenses without a budgeted amount (no ProjectedExpense id)
const MutateProjectedExpense = (props: {
  projectId: string;
  projectPricingId: number;
  projectedExpenseId: number | undefined;
  expenseType: string;
  payeeName: string;
  budgetedAmount: number;
}) => {
  const { projectedExpenseId } = props;
  const { peopleData } = useContext(PricingContext);
  // mutations for updating and deleting a ProjectedExpense
  const [
    updateProjectedExpense,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UpdateProjectedExpenseDocument);
  const [
    deleteProjectedExpense,
    { loading: deleteLoading, error: deleteError },
  ] = useMutation(DeleteProjectedExpenseDocument);
  const [
    createProjectedExpense,
    { loading: createLoading, error: createError },
  ] = useMutation(CreateProjectedExpenseDocument);

  const { refetchProjectPricing } = usePricingDataContext();

  const toast = useToast();

  // State for modal
  const [isOpen, setIsOpen] = useState(false);

  // State for new ProjectedExpense (no projectedExpenseId)
  const [newExpense, setNewExpense] = useState<BriefProjectedExpense>({
    project_id: props.projectId,
    project_pricing_id: props.projectPricingId,
    expense_type: props.expenseType ?? "",
    payee_name: props.payeeName ?? "",
    budget_in_cents: 0,
  });

  // State for update form (existing ProjectedExpense)
  const [expense, setExpense] = useState<BriefProjectedExpense>({
    id: projectedExpenseId,
    project_id: props.projectId,
    project_pricing_id: props.projectPricingId,
    expense_type: props.expenseType,
    payee_name: props.payeeName,
    budget_in_cents: props.budgetedAmount,
  });

  // handle update for ProjectedExpense
  const handleSubmitUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { isValid, errorType, errorMessage } = validateBudgetFields({
      fields: expense
    });

    if (!isValid) {
      toast({
        title: `Error: ${errorType}`,
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsOpen(false);
      return;
    }

    try {
      await updateProjectedExpense({
        variables: {
          id: expense.id,
          budgetInCents: expense.budget_in_cents * 100,
          expenseType: expense.expense_type,
          payeeName: expense.payee_name,
        },
        onCompleted: () => {
          toast({
            title: "Projected Expense Updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          // clear state
          setExpense({
            ...expense,
            expense_type: "",
            payee_name: "",
            budget_in_cents: 0,
          });
          refetchProjectPricing();
        },
        onError: (error) => {
          toast({
            title: "Error updating Projected Expense",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    } catch (error) {
      toast({
        title: "Error updating Projected Expense",
        description: "An error occurred while updating the Projected Expense.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsOpen(false);
    }
  };

  const handleSubmitCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isValid, errorType, errorMessage } = validateBudgetFields({
      fields: newExpense
    });

    if (!isValid) {
      toast({
        title: `Error: ${errorType}`,
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsOpen(false);
      return;
    }

    try {
      await createProjectedExpense({
        variables: {
          projectId: newExpense.project_id,
          projectPricingId: newExpense.project_pricing_id,
          budgetInCents: newExpense.budget_in_cents * 100,
          expenseType: newExpense.expense_type,
          payeeName: newExpense.payee_name,
        },
        onCompleted: () => {
          toast({
            title: "Projected Expense Created",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          refetchProjectPricing();
        },
        onError: (error) => {
          toast({
            title: "Error creating Projected Expense",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    } catch (error) {
      toast({
        title: "Error creating Projected Expense",
        description: "An error occurred while creating the Projected Expense.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsOpen(false);
    }
  }

  const handleDelete = async () => {
    if (!projectedExpenseId) {
      toast({
        title: "Error deleting Projected Expense",
        description: "Projected Expense ID is missing.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsOpen(false);
      return;
    }
    try {
      await deleteProjectedExpense({
        variables: {
          id: projectedExpenseId,
        },
        onCompleted: () => {
          toast({
            title: "Projected Expense Deleted",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          refetchProjectPricing();
        },
        onError: (error) => {
          toast({
            title: "Error deleting Projected Expense",
            description: error.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        },
      });
    } catch (error) {
      toast({
        title: "Error deleting Projected Expense",
        description: "An error occurred while deleting the Projected Expense.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsOpen(false);
    }
  };

  const modal = (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <ModalOverlay />
      <ModalContent as="form" onSubmit={projectedExpenseId ? handleSubmitUpdate : handleSubmitCreate}>
        <ModalHeader>
          {projectedExpenseId ? "Update" : "Create"} Budget Expense for:<br/> {props.expenseType} - {props.payeeName}
          <Tooltip label="Updating the Expense Type or Payee Name of the Budgeted Amount will change the Actual invoices that are matched with the budget" aria-label="Warning">
            <sup>
              <InfoIcon css={tw`w-4 ml-1`} style={{ color: "orange" }} />
            </sup>
          </Tooltip>
        </ModalHeader>
        <ModalBody pb={6} css={tw`flex flex-col gap-y-3`}>
          <SelectExpenseType
            expense={projectedExpenseId ? expense : newExpense} 
            setExpense={projectedExpenseId ? setExpense : setNewExpense}
          />
          <SelectPayeeName
            expense={projectedExpenseId ? expense : newExpense}
            setExpense={projectedExpenseId ? setExpense : setNewExpense}
            peopleData={peopleData}
          />
          <BudgetInput 
            expense={projectedExpenseId ? expense : newExpense} 
            setExpense={projectedExpenseId ? setExpense : setNewExpense}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            mr={3}
          >
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit" disabled={projectedExpenseId ? updateLoading : createLoading}>
            {projectedExpenseId && updateLoading ? "Saving..." : "Save"}
            {!projectedExpenseId && createLoading ? "Creating..." : ""}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <div css={tw`flex justify-center items-center gap-2`}>
      {modal}
      <Button variant="ghost" size="sm" aria-label="Edit" onClick={() => { setIsOpen(true) }}>
        <EditIcon />
      </Button>
      <Tooltip label={!projectedExpenseId ? "This expense does not have a budget estimate record to delete" : "Delete"} aria-label="Delete">
        <Button variant="ghost" size="sm" aria-label="Delete" onClick={handleDelete} isDisabled={!projectedExpenseId}>
          {!deleteLoading && <DeleteIcon />}
          {deleteLoading && <Spinner size="xs" />}
        </Button>
      </Tooltip>
    </div>
  );
};

export default MutateProjectedExpense;
