// helper function to validate the budget fields
import type { BriefProjectedExpense } from "./expenseFields";
import { ProjectedExpense } from "../../codegen/graphql-types";

export type BudgetValidation = {
  isValid: boolean;
  errorType: string;
  errorMessage: string;
};

const validateBudgetFields = ({
  fields,
  projectedExpenses
}: {
  fields: BriefProjectedExpense;
  projectedExpenses?: ProjectedExpense[];
}): BudgetValidation => {
  
  if (!fields.expense_type || !fields.payee_name) {
    return {
      isValid: false,
      errorType: "Required",
      errorMessage: "Please fill out expense type and payee name.",
    };
  }

  const existingExpense = projectedExpenses ? projectedExpenses.find(
    (expense) =>
      expense.expense_type === fields.expense_type &&
      expense.payee_name === fields.payee_name
  ) : null;

  if (existingExpense) {
    return {
      isValid: false,
      errorType: "Duplicate",
      errorMessage: `You can only add one expense budget per expense type and payee. There is already an expense budgeted for ${fields.expense_type} - ${fields.payee_name}.`,
    }
  }

  if (fields.payee_name.includes(" - ")) {
    return {
      isValid: false,
      errorType: "Invalid",
      errorMessage: "Payee name cannot contain ' - '. Please remove the hyphen.",
    };
  }

  if (fields.budget_in_cents <= 0 || Number.isNaN(fields.budget_in_cents)) {
    return {
      isValid: false,
      errorType: "Invalid",
      errorMessage: "Budget must be a positive number.",
    };
  }

  return {
    isValid: true,
    errorType: "",
    errorMessage: "",
  };
};

export default validateBudgetFields;
