import { useState, useEffect, useCallback } from "react";
import constate from "constate";
import { RuntimeConfig } from "../RuntimeConfig";
import * as QBO from "../../../server/lib/quickbooks/types";

// Quickbooks objects to fetch - find more object names as needed from: https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account
const qbObjects = {
  Customer: null,
  Invoice: null,
  Item: null,
  Term: null,
  TaxRate: null,
  TaxCode: null,
};

interface QuickbooksResourceResponses {
  Customer: QBO.Customer[] | null;
  Invoice: QBO.Invoice[] | null;
  Item: QBO.Item[] | null;
  Term: QBO.Term[] | null;
  TaxRate: QBO.TaxRate[] | null;
  TaxCode: QBO.TaxCode[] | null;
}

const useQuickbooksContext = () => {
  const [qbResources, setQbResources] = useState<QuickbooksResourceResponses>(qbObjects);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const getQuickbooksResource = useCallback(async (object: string) => {
    try {
      const response = await fetch(
        `${RuntimeConfig.backendOrigin}/quickbooks/resource?object=${object}`,
        {
          method: "GET",
          credentials: "include",
          redirect: "manual",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data } = await response.json();
      return { [object]: data };
    } catch (err) {
      console.error(`Error fetching ${object}:`, err);
      return { [object]: null };
    }
  }, []);

  useEffect(() => {
    const fetchResources = async () => {
      setIsLoading(true);
      try {
        const results = await Promise.all(
          Object.keys(qbObjects).map((object) => getQuickbooksResource(object))
        );
        const combinedResults = Object.assign({}, ...results);
        setQbResources(combinedResults);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResources();
  }, [getQuickbooksResource]);

  return { qbResources, isLoading, error };
};

export const [
  QuickbooksResourceProvider,
  useQuickbooksResourceContext,
] = constate(useQuickbooksContext);