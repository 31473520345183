const currencyFormatter = new Intl.NumberFormat("en-US", {
    // style: 'currency',
    // currency: 'CAD',
    // currencyDisplay: ''
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  

export const Money = ({ value, title }: { value: number; title?: string }) => (
    <div
      style={{
        textAlign: "right",
        color: value < 0 ? "#aa1b1b" : undefined,
      }}
      title={title}
    >
      {value !== undefined ? `${(value >= 0 ? "$" : "-$")}${currencyFormatter.format(Math.abs(value))}` : ""}
    </div>
  );
  