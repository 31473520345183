import * as GraphQLTypes from "../../codegen/graphql-types";
import { InvoiceStatus } from "../../codegen/graphql-types";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Select,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { EditIcon, InfoIcon, ViewIcon } from "@chakra-ui/icons";
import { useState } from "react";

const invoiceStatusDescriptions = {
  CREATE_INVOICE: "Invoice has not been created in Quickbooks yet",
  GO_TO_INVOICE:
    "Invoice has been created in Quickbooks but not sent via email",
  GO_TO_PORTAL:
    "Invoice has been created in Quickbooks but not uploaded to the client's invoicing portal",
  REVIEW_AGING_TERMS:
    "Invoice has been created in Quickbooks and uploaded to client's portal, but hasn't been accepted by the client's portal yet",
  INVOICED:
    "Invoice has been created in Quickbooks and has a date invoiced, indicating it has been either sent via email or accepted by the client's portal",
};

function getInvoiceStatusDescription(status: InvoiceStatus): string {
  return invoiceStatusDescriptions[status];
}

export const InvoiceStatusAction = ({
  invoiceStatus,
  databaseInvoiceId,
  companyPortalUrl,
  quickbooksId,
  dateInvoiced,
  isLoggedIntoQuickbooks,
  onUpdate,
}: {
  invoiceStatus: GraphQLTypes.Maybe<InvoiceStatus | undefined>;
  databaseInvoiceId: string;
  companyPortalUrl: string | undefined;
  quickbooksId: GraphQLTypes.Maybe<string | undefined>;
  dateInvoiced: string;
  isLoggedIntoQuickbooks: boolean;
  onUpdate: (e: React.FormEvent, newStatus: InvoiceStatus) => Promise<void>;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedStatus, setSelectedStatus] = useState<
    InvoiceStatus | undefined
  >(invoiceStatus ?? InvoiceStatus.CreateInvoice);

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(e.target.value as InvoiceStatus);
  };

  const handleUpdate = async (e: React.FormEvent) => {
    if (selectedStatus && selectedStatus !== invoiceStatus) {
      await onUpdate(e, selectedStatus);
      onClose();
    }
  };

  if (dateInvoiced) {
    return <Text>Invoiced</Text>;
  }

  if (!isLoggedIntoQuickbooks) {
    return <Text>{invoiceStatus}</Text>;
  }

  return (
    <Box display="flex" gap="2">
      {/* Show action links based on the current status */}
      {companyPortalUrl &&
        (selectedStatus === InvoiceStatus.GoToPortal ||
          selectedStatus === InvoiceStatus.ReviewAgingTerms) && (
          <Link
            to={companyPortalUrl}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "inline-block",
              textDecoration: "none",
            }}
          >
            {selectedStatus === InvoiceStatus.GoToPortal && (
              <Button size="sm" colorScheme="red">
                Go to Portal
              </Button>
            )}
            {selectedStatus === InvoiceStatus.ReviewAgingTerms && (
              <Button size="sm" colorScheme="yellow">
                Review Aging Terms
              </Button>
            )}
          </Link>
        )}

      {selectedStatus === InvoiceStatus.GoToInvoice && (
        <Link to={`/financials/invoices/view?id=${databaseInvoiceId}`}>
          <Button size="sm" colorScheme="green">
            Go to Invoice
          </Button>
        </Link>
      )}

      {selectedStatus === InvoiceStatus.CreateInvoice && (
        <Link to={`/financials/invoices/new?id=${databaseInvoiceId}`}>
          <Button size="sm" colorScheme="green">
            Create Invoice
          </Button>
        </Link>
      )}

      {selectedStatus === InvoiceStatus.Invoiced && (
        <Text display="flex" alignItems="center">
          Invoiced - Please set the Date Invoiced
        </Text>
      )}

      {/* Edit Button to open the modal - Status only needs to be changed manually for companies with an invoice portal */}
      {companyPortalUrl && (
        <IconButton
          aria-label="Edit Invoice Status"
          icon={<EditIcon />}
          onClick={onOpen}
          size="sm"
        />
      )}

      {/* Modal for updating the status */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Invoice Status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              value={selectedStatus || ""}
              onChange={handleStatusChange}
              mb={4}
            >
              {/* <option value={InvoiceStatus.CreateInvoice}>
                Create Invoice
              </option> */}
              {/* <option value={InvoiceStatus.GoToInvoice}>Go to Invoice</option> */}
              <option value={InvoiceStatus.GoToPortal}>Go to Portal</option>
              <option value={InvoiceStatus.ReviewAgingTerms}>
                Review Aging Terms
              </option>
              {/* <option value={InvoiceStatus.Invoiced}>Invoiced</option> */}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleUpdate}
              isDisabled={!selectedStatus || selectedStatus === invoiceStatus}
            >
              Update Status
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Open Invoice */}
      {databaseInvoiceId && quickbooksId && (
        <Link to={`/financials/invoices/view?id=${databaseInvoiceId}`}>
          <IconButton
            aria-label="View Invoice In New Tab"
            icon={<ViewIcon />}
            size="sm"
          />
        </Link>
      )}

      {/* Display the current status with a popover */}
      <Box marginLeft="auto">
        <Popover>
          <PopoverTrigger>
            <IconButton
              aria-label="Info"
              icon={<InfoIcon />}
              size="sm"
              variant="ghost"
            />
          </PopoverTrigger>
          <PopoverContent css={tw`z-10`}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>
              {selectedStatus ? selectedStatus : "No status available"}
            </PopoverHeader>
            <PopoverBody>
              {selectedStatus
                ? getInvoiceStatusDescription(selectedStatus)
                : "No description available"}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Box>
  );
};

export default InvoiceStatusAction;
