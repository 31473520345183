
import tw, { css } from "twin.macro"; 
import { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { AddTimeLogDocument } from '../../codegen/graphql-types';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRecentTimeContext } from '../../helpers/useRecentTimeContext';

// Makes a request to teamwork to create the new time entry and get a time entry id,
// Then makes a request to the server to create a new time entry with the same time entry id
export const TimeLog = ({ 
  timeSum,
  companyId,
  projectId,
  userId,
  date,
  billable,
  refetchYearTimeEntries
}: {
  timeSum: string;
  companyId: string;
  projectId: string;
  userId: string;
  date: string;
  billable: boolean;
  refetchYearTimeEntries: () => void;
}) => {
  const { refetch } = useRecentTimeContext();
  const [addTimeLog, { data, loading }] = useMutation(AddTimeLogDocument);
  const toast = useToast();
  const isoDateString = new Date(date).toISOString();
  
  const [displayTime, setDisplayTime] = useState(timeSum);
  // keep track of the new time entry id so we don't add the same time entry to local state twice
  const [newTimeEntryId, setNewTimeEntryId] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [validateError, setValidateError] = useState<string | null>(null);

  const onCancel = () => {
    setIsOpen(false);
    setIsHovered(false);
    setValidateError(null);
  };

  
  
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      hours: { value: string };
      minutes: { value: string };
    };
    const hours = Number(target.hours.value);
    const minutes = Number(target.minutes.value);
    if (hours === 0 && minutes === 0) {
      setValidateError("Please enter a time value");
      return;
    }
    // construct variables for mutation query to server
    const variables = {
      personId: userId,
      date: isoDateString,
      hours: Number(hours),
      minutes: Number(minutes),
      companyId: companyId,
      projectId: projectId,
      billable: billable
    };
    // create the time entry in the server
    addTimeLog({ 
      variables: variables,
      onCompleted: () => {
        refetchYearTimeEntries();
        // also refetch the user's recent time summary (Last 3 months)
        refetch();
      }
    });
    // close the modal and reset the validate error
    onCancel();
  };

  // Toast successful mutation result with data from mutation
  useEffect(() => {
    if (data && data.createTimeEntry && data.createTimeEntry.id !== newTimeEntryId) {
      toast({
        title: "Time added.",
        description: `You added ${data.createTimeEntry.minutes} minutes to the project.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      const totalMinutesAdded = data.createTimeEntry.minutes;
      // the display time is in the format of "0h 0m"
      // we need to add the total minutes added to the display time
      // if the existing display time is "1h 30m" and we add 75 total minutes
      // then the new display time should be "2h 45m"
      // we can do this by splitting the display time into hours and minutes
      const existingHours = Number(displayTime.split("h")[0]);
      const existingMinutes = Number(displayTime.split("h")[1].split("m")[0]);
      const newTime = `${existingHours + Math.floor((existingMinutes + totalMinutesAdded) / 60)}h ${Math.floor((existingMinutes + totalMinutesAdded) % 60)}m`;
      // update the display time if the new time is different than the existing time
      setDisplayTime(newTime);
      // set the new time entry id to make sure we don't add the same time entry twice
      setNewTimeEntryId(data.createTimeEntry.id);
      // set the validate error to null
      setValidateError(null);
    }
  }, [data]);


  const modal = (
    <Modal
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <ModalOverlay />
      <ModalContent
        as="form"
        onSubmit={handleSubmit}
      >
        <ModalHeader>Add Time</ModalHeader>
        <ModalBody pb={6}>
          <div css={tw`w-36 flex flex-col gap-y-3`}>
            <strong>{date}</strong>
            <label>
              Hours
              <input name="hours" type="number" placeholder="Hours" />
            </label>
            <label>
              Minutes
            <input name="minutes" type="number" placeholder="Minutes" />
            </label>
          </div>
          {validateError && <div css={tw`text-red-500`}>{validateError}</div>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancel} mr={3}>
            Cancel
          </Button>
          <Button colorScheme="blue" type="submit">
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <div 
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      css={tw`relative p-3 text-center whitespace-nowrap border-2 cursor-pointer bg-gray-800 text-white rounded-md`}
      style={{
        backgroundColor: displayTime === "0h 0m" ? "#eaeaea" : "#2d3748",
        color: displayTime === "0h 0m" ? "#2d3748" : "#eaeaea"
      }}
    >
      {!loading && displayTime}
      {loading && "Loading..."}
      <button
        onClick={() => setIsOpen(true)}
        css={tw`absolute hidden top-0 right-0 p-1 text-xs bg-gray-800 text-white rounded-md`}
        style={{
          display: isHovered ? "block" : "none"
        }}
        >
        Add time
      </button>
      {modal}
    </div>
  );
}