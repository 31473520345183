
import tw from "twin.macro";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { 
  // create a single forecast
  AddClientAnnualRevenueForecastDocument,
  AddClientAnnualRevenueForecastMutation,
  AddClientAnnualRevenueForecastMutationVariables,
  // create multiple forecasts
  AddClientAnnualRevenueForecastsDocument,
  AddClientAnnualRevenueForecastsMutation,
  AddClientAnnualRevenueForecastsMutationVariables,
  // update a single forecast
  UpdateClientAnnualRevenueForecastDocument,
  UpdateClientAnnualRevenueForecastMutation,
  UpdateClientAnnualRevenueForecastMutationVariables, 
} from "../../../codegen/graphql-types";
import { useToast } from "@chakra-ui/react";
import { EditIcon, CheckIcon } from "@chakra-ui/icons";

const currencyFormatter = new Intl.NumberFormat("en-US", {});

// Adds all forecasts for multiple clients for a single year
export const AddClientAnnualRevenueForecasts = ({ 
  clientIds, 
  year, 
  userId,
  podId, 
  refetchForecasts
}: { 
  clientIds: string[], 
  year: number, 
  userId: string,
  podId: string,
  refetchForecasts: () => void
}) => {
  const [addClientAnnualRevenueForecasts, result] = useMutation<
    AddClientAnnualRevenueForecastsMutation,
    AddClientAnnualRevenueForecastsMutationVariables
  >(AddClientAnnualRevenueForecastsDocument);

  const buttonCss = [tw`bg-gray-800 hover:bg-green-500 text-white font-bold px-4 rounded`];

  const handleAdd = () => {
    addClientAnnualRevenueForecasts({
      variables: {
        clientIds,
        year,
        userId,
        podId,
      },
      onCompleted: () => {
        refetchForecasts();
      }
    });
  };

  if (result.loading) {
    return <button disabled css={buttonCss}>Working on it...</button>;
  }

  if (result.error) {
    return <button css={tw`bg-red-500`}>{result.error.message}</button>;
  }

  return (
    <button
      onClick={handleAdd}
      css={buttonCss}
    >
      Create all forecasts for {year}
    </button>
  );
}

// component for a single row of low and high revenue forecasts for a single client
export const ClientAnnualRevenueForecasts = ({
  clientId,
  year,
  userId,
  podId,
  refetchForecasts,
  forecast,
}: {
  key?: string,
  clientId: string,
  year: number,
  userId: string,
  podId: string,
  refetchForecasts: () => void,
  forecast: any
}) => {
  const toast = useToast();
  const [updateClientAnnualRevenueForecast, result] = useMutation<
    UpdateClientAnnualRevenueForecastMutation,
    UpdateClientAnnualRevenueForecastMutationVariables
  >(UpdateClientAnnualRevenueForecastDocument);

  const [isEditing, setIsEditing] = useState(false);
  const [forecastDollars, setForecastDollars] = useState({
    low: forecast.lowForecastDollars,
    high: forecast.highForecastDollars,
  });

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const newLow = Number(e.currentTarget.lowForecastDollars.value);
    const newHigh = Number(e.currentTarget.highForecastDollars.value);

    // validate the new low and high values
    if (typeof newLow !== "number" || typeof newHigh !== "number") {
      toast({
        title: "Invalid data",
        description: "Please enter a number value for both low and high forecasts",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // Update database
    updateClientAnnualRevenueForecast({
      variables: {
        clientId,
        year,
        podId, 
        updatedByUserId: userId,
        lowForecastDollars: newLow,
        highForecastDollars: newHigh,
      },
      onCompleted: () => {
        refetchForecasts();
      }
    });
    setIsEditing(false);
  };

  return (
    <>
      {!isEditing && (
        <>
          <td css={[tw`px-4 py-2 font-bold`]}>
            {forecast.lowForecastDollars === 0 && <>&nbsp;</>}
            {forecast.lowForecastDollars > 0 && <>${currencyFormatter.format(forecast.lowForecastDollars)}</>}
          </td>
          <td css={[tw`px-4 py-2 font-bold flex justify-end`]}>
            {forecast.highForecastDollars === 0 && <>&nbsp;</>}
            {forecast.highForecastDollars > 0 && <>${currencyFormatter.format(forecast.highForecastDollars)}</>}
            <button onClick={() => setIsEditing(true)} css={tw`ml-3 flex items-center`}>
              <EditIcon
                w="4"
                h="4"
              />
            </button>
          </td>
        </>
      )}
      {isEditing && (
        <td colSpan={2} css={[tw`border bg-gray-800 px-4 py-2`]}>
          <form
            onSubmit={(e) => { handleUpdate(e) }}
            css={tw`flex justify-center items-center`}
          >
            <div css={tw`w-full flex justify-between`}>
              <div>
                <label htmlFor="lowForecastDollars" css={tw`text-white`}>Low: </label>
                <input
                  id="lowForecastDollars"
                  type="number"
                  step={1000}
                  name="lowForecastDollars"
                  value={forecastDollars.low}
                  css={tw`text-center w-20`}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setForecastDollars({
                      ...forecastDollars,
                      low: Number(e.currentTarget.value),
                    });
                  }}
                />
              </div>
              <div>
                <label htmlFor="highForecastDollars" css={tw`text-white`}>High: </label>
                <input
                  id="highForecastDollars"
                  type="number"
                  step={1000}
                  name="highForecastDollars"
                  value={forecastDollars.high}
                  css={tw`text-center w-20`}
                  onWheel={(e) => e.currentTarget.blur()}
                  onChange={(e) => {
                    setForecastDollars({
                      ...forecastDollars,
                      high: Number(e.currentTarget.value),
                    });
                  }}
                />
              </div>
              <button disabled={result.loading} type="submit" css={tw`flex items-center`}>
                <CheckIcon
                  w="4"
                  h="4"
                  css={tw`text-green-500 hover:text-green-300`}
                />
              </button>
            </div>
          </form>
        </td>
      )}
    </>
  );
}


// Component for adding a single forecast record for a single client
export const AddClientAnnualRevenueForecast = ({ 
  clientId, 
  year, 
  userId, 
  podId, 
  refetchForecasts 
}: { 
  clientId: string, 
  year: number, 
  userId: string, 
  podId: string, 
  refetchForecasts: () => void 
}) => {
  const [addClientAnnualRevenueForecast, result] = useMutation<
    AddClientAnnualRevenueForecastMutation,
    AddClientAnnualRevenueForecastMutationVariables
  >(AddClientAnnualRevenueForecastDocument);
  
  const buttonCss = [tw`bg-gray-800 hover:bg-green-500 text-white font-bold px-4 rounded`];

  const handleAdd = () => {
    addClientAnnualRevenueForecast({
      variables: {
        clientId,
        year,
        podId,
        lowForecastDollars: 0,
        highForecastDollars: 0,
        updatedByUserId: userId,
      },
      onCompleted: () => {
        refetchForecasts();
      }
    });
  };

  if (result.loading) {
    return (
      <td colSpan={2}>
        <button disabled css={buttonCss}>Working on it...</button>
      </td>
    );
  }

  if (result.error) {
    return (
      <td colSpan={2}>
        <div css={tw`bg-red-500`}>{result.error.message}</div>
      </td>
    );
  }

  return (
    <td colSpan={2}>
      <button
        onClick={handleAdd}
        css={buttonCss}
      >
        Add forecast for {year}
      </button>
    </td>
  );
}