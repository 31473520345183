import type { Tasklist } from "./useProjectTasklists";
import type { 
  TasklistPricingWithCalculatedData,
  TransposedDataForTasklistTable,
  Cogs,
} from "./usePricing";

const transposeDataForTasklistTable = (
  tasklists: Tasklist[], 
  tasklistPricings: TasklistPricingWithCalculatedData[],
  cogs: Cogs
) => {
  // Selected Project Tasklists is data from TW
  // We need to pull data from DB and use that to calculate budget
  if (!tasklists || tasklists.length === 0) {
    return [];
  } 

  // Budget row for each tasklist
  const tasklistBudgets: { [key: string]: { value: number } } = tasklists.reduce(
    (acc, tasklist) => {
      // calculatedTasklistPricings is an array of tasklists with calculated data
      const tasklistPricing = tasklistPricings.find(
        (pricing: TasklistPricingWithCalculatedData) => pricing.tasklist_id === tasklist.id
      );

      if (!tasklistPricing) {
        return acc;
      }

      const budget = tasklistPricing.CALCULATED?.budget?.["total-cost"] ?? 0;
      return {
        ...acc,
        [tasklist.name]: {
          value: budget,
        },
      };
    },
    {}
  );

  // Actual row for each tasklist
  const tasklistActuals: { [key: string]: { value: number } } = tasklists.reduce(
    (acc, tasklist) => { 
      // calculatedTasklistPricings is an array of tasklists with calculated data
      const tasklistPricing = tasklistPricings.find(
        (pricing: TasklistPricingWithCalculatedData) => pricing.tasklist_id === tasklist.id
      );

      if (!tasklistPricing) {
        return acc;
      }

      // TODO: Determine actual cost from projected_minutes * time entries with the same tasklist_id
      const actual = tasklistPricing.CALCULATED?.actual?.["total-cost"] ?? 0
      return {
        ...acc,
        [tasklist.name]: {
          value: actual,
        },
      };
    },
    {}
  );

  // Remaining row for each tasklist
  const tasklistRemaining: { [key: string]: { value: number } } = tasklists.reduce(
    (acc, tasklist) => {
      // calculatedTasklistPricings is an array of tasklists with calculated data
      const tasklistPricing = tasklistPricings.find(
        (pricing: TasklistPricingWithCalculatedData) => pricing.tasklist_id === tasklist.id
      );

      if (!tasklistPricing) {
        return acc;
      }
      const budget = tasklistPricing.CALCULATED.budget?.["total-cost"] ?? 0;
      const actual = tasklistPricing.CALCULATED.actual?.["total-cost"] ?? 0
      return {
        ...acc,
        [tasklist.name]: {
          value: budget - actual,
        },
      };
    },
    {}
  );

  // Subtotal based on hours column (get sum of all budgets/actuals across all tasklists)
  const dbProjectPricings = tasklistPricings;
  
  const budgetSubtotal =
    dbProjectPricings?.reduce(
      (acc, pricing) =>
        acc + (pricing?.CALCULATED?.budget?.["total-cost"] ?? 0),
      0
    ) ?? 0;
  const actualSubtotal =
    dbProjectPricings?.reduce(
      (acc, pricing) =>
        acc + (pricing?.CALCULATED?.actual?.["total-cost"] ?? 0),
      0
    ) ?? 0;

  const remainingSubtotal = budgetSubtotal - actualSubtotal;

  // Complete transposed data object
  const transposedData: TransposedDataForTasklistTable[] = [
    {
      type: "Budget",
      ...tasklistBudgets,
      hoursSubtotal: budgetSubtotal,
      cogs: cogs.cogs_budget_total,
      total: budgetSubtotal + cogs.cogs_budget_total,
    },
    {
      type: "Actual",
      ...tasklistActuals,
      hoursSubtotal: actualSubtotal,
      cogs: cogs.cogs_actual_total,
      total: actualSubtotal + cogs.cogs_actual_total,
    },
    {
      type: "Remaining",
      ...tasklistRemaining,
      hoursSubtotal: remainingSubtotal,
      cogs: cogs.cogs_budget_total - cogs.cogs_actual_total,
      total: remainingSubtotal + (cogs.cogs_budget_total - cogs.cogs_actual_total),
    },
  ];

  return transposedData;
};

export default transposeDataForTasklistTable;