
import tw, { css } from "twin.macro";
// import { DayOff, TimeEntry } from "../codegen/graphql-types";
import { FlexibleTimeEntry, FlexibleDayOff } from "./TimeSummaryRow";
import { minutesToHours } from "../../helpers/time";
import { sumEntryMinutes, daysInDateRange, DaysInRange } from "../dates";
import { parseISO } from "date-fns";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import { filterBillableTime, filterNonBillableTime } from "../constants";

interface AverageHoursDateRangeProps {
  startDate: string;
  endDate: string;
  userTimeEntries: FlexibleTimeEntry[];
  isBillable?: boolean;
  isTotal?: boolean;
  targetForYear?: number | null; // minutes
  daysOff: FlexibleDayOff[]
}

export const TimeSummaryRange = ({
  startDate,
  endDate,
  userTimeEntries,
  isBillable,
  isTotal,
  targetForYear, // minutes
  daysOff
}: AverageHoursDateRangeProps) => {
  // filter the time entries based on whether the component should count billable or non-billable hours, or both
  const entriesToCount: FlexibleTimeEntry[] = isTotal ? userTimeEntries : userTimeEntries
    .filter((entry: FlexibleTimeEntry) => isBillable ? filterBillableTime(entry) : filterNonBillableTime(entry));

  // get the total number of minutes for the date range
  const totalMinutes: number = entriesToCount.reduce(sumEntryMinutes, 0);

  // get summary of days in the date range
  const days: DaysInRange = daysInDateRange(
    parseISO(startDate), 
    parseISO(endDate), 
    daysOff
  );
  
  // get the number of working days in the date range
  const numOfWorkingDaysForDateRange: number = Math.max(days.numberOfWorkingDaysInRange, 0);

  // get the average number of minutes/day
  const averageMinutesPerDay: number = totalMinutes / numOfWorkingDaysForDateRange;

  // get the total number of hours/day 
  const totalHoursPerDay: number = minutesToHours(averageMinutesPerDay);

  // target hours/day
  const targetHoursPerDay = targetForYear ? minutesToHours(targetForYear) : 0;

  const isBelowTarget: boolean = targetForYear ? totalHoursPerDay < targetHoursPerDay : false;

  return (
    <div>
      <Tooltip
        label={
          <Stack>
            <Text>{`${days.description}`}</Text>
            <Text>{`${days.totalDaysInRange} total days in range`}</Text>
            <Text>{`- ${days.userDaysOffWithinInterval.length} days off (vacation, sick, other)`}</Text>
            <Text>{`- ${days.weekendDaysInRange} weekend days`}</Text>
            <Text>{`- ${days.holidaysInRange} holidays/social days/extended-long weekends`}</Text>
            <Text>{`= ${days.numberOfWorkingDaysInRange} working days in range`}</Text>
            <Text>{`Total hours worked was ${(totalMinutes / 60).toFixed(2)}`}</Text>
            <Text>{`${(totalMinutes / 60).toFixed(2)} ÷ ${numOfWorkingDaysForDateRange} working days = ${totalHoursPerDay.toFixed(2)} hrs/day`}</Text>
          </Stack>
        }
      >
        <div css={tw`w-full`}>          
          <p css={tw`cursor-pointer`}
            style={{
              color: isBillable && targetForYear && isBelowTarget ? "red" : "black"
            }}>
            {totalHoursPerDay === 0 || numOfWorkingDaysForDateRange === 0 ? `0` : `${totalHoursPerDay.toFixed(1)}`}
          </p>
        </div>
      </Tooltip>
    </div>
  );
}