import { gql, useQuery } from "@apollo/client";
import constate from "constate";
import type * as GraphQLTypes from "../codegen/graphql-types";

export const PartEstimate = gql`
  fragment PartEstimate on Estimate {
    id
    date
    owner {
      id
    }
    name
    po_number
    po_subtotal
    company {
      id
      company_name
    }
    brand {
      id
      brand_name
    }
    comments
    # this is an expensive field, do not query
    # client_invoice_count
    # this is an expensive field, do not query
    # estimate_status
  }
`;

const useEstimates = () => {
  const queryResult = useQuery<
    GraphQLTypes.EstimatesQuery,
    GraphQLTypes.EstimatesQueryVariables
  >(
    gql`
      query Estimates {
        estimates {
          ...PartEstimate
        }
      }
      ${PartEstimate}
    `
  );
  return queryResult;
};

export const [EstimatesProvider, useEstimatesContext] = constate(useEstimates);
