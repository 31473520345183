
import { gql, useQuery } from "@apollo/client";
import { ClientInvoiceTable } from "./ClientInvoiceTable";
import type * as GraphQLTypes from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";

export const ClientInvoicesListForEstimate = ({
  estimateId,
}: {
  estimateId: string;
}) => {
  const queryResult = useQuery<
    GraphQLTypes.ClientInvoicesListQuery,
    GraphQLTypes.ClientInvoicesListQueryVariables
  >(
    gql`
      query ClientInvoicesList($estimateWhere: estimateWhereUniqueInput!) {
        estimate(where: $estimateWhere) {
          id
          client_invoices {
            id
            created_at
            invoice_number
            date_invoiced
            amount_in_cents
            estimate {
              id
            }
          }
        }
      }
    `,
    {
      variables: {
        estimateWhere: {
          id: estimateId,
        },
      },
    }
  );

  if (!queryResult.data) return <FullSpinner />;

  const clientInvoices = queryResult.data.estimate?.client_invoices;

  if (!clientInvoices) return null;

  return (
    <ClientInvoiceTable
      clientInvoices={clientInvoices}
      refetch={queryResult.refetch}
    />
  );
};
