import tw from "twin.macro";
import { useQuery } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import * as GraphQLTypes from "../../codegen/graphql-types";
import GetQuickbooksResources from "./GetQuickbooksResources";

// Define a type for valid invoice paths
export type InvoicePath = "view" | "new";

// Utility function to validate the invoice path
const getValidInvoicePath = (path: string | undefined): InvoicePath | undefined => {
  const validPaths: InvoicePath[] = ["view", "new"];
  return validPaths.includes(path as InvoicePath) ? (path as InvoicePath) : undefined;
};

export const QuickbooksInvoice = () => {
  // get the invoice id from the URL
  const queryParams = new URLSearchParams(window.location.search);
  const invoiceId = queryParams.get("id") ?? "";
  // Get the path from the URL, e.g., /financials/invoices/view or /financials/invoices/new
  const lastPathSegment = window.location.pathname.split("/").pop();
  const invoicePath: InvoicePath | undefined = getValidInvoicePath(lastPathSegment);

  // fetch the invoice data from the server
  const { loading, error, data } = useQuery(
    GraphQLTypes.GetClientInvoiceDocument,
    {
      variables: { id: invoiceId },
    }
  );

  if (loading)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex flex-col justify-center items-center`}>
          <p>Fetching Invoice data from Dashboard database...</p>
          <br />
          <br />
          <Spinner />
        </section>
      </div>
    );

  if (error)
    return (
      <div css={tw`h-full p-6`}>
        <section css={tw`w-full flex justify-center items-center`}>
          <p style={{ color: "red" }}>Error: {error.message}</p>
        </section>
      </div>
    );

  return (
    <GetQuickbooksResources 
      dashboardInvoiceData={data.clientInvoiceById}
      invoicePath={invoicePath}
    />
  );
};


