
import tw, { css } from "twin.macro";
import { DateTime } from "luxon";

type WeekSelectorProps = {
  yearInFocus: number;
  weekInFocus: number;
  setWeekInFocus: (week: number) => void;
  setYearInFocus: (year: number) => void;
}

export const WeekSelector = ({
  yearInFocus,
  weekInFocus,
  setWeekInFocus,
  setYearInFocus
}: WeekSelectorProps) => {

  // const currentYear = DateTime.now().year;
  // const isLeapYear = DateTime.fromObject({ year: yearInFocus }).isInLeapYear;
  
  // index of week numbers from 1 to 52 (1 is the first week of the year)
  const weeksInTheYear = Array.from({ length: 53 }, (_, i) => i + 1);

  const getWeekRange = (weekNumber: number) => {
    // Start from the first day of the year
    const firstDayOfYear = DateTime.fromObject({ year: yearInFocus });
    // Calculate the start of the week
    const startOfWeek = firstDayOfYear.plus({ weeks: weekNumber - 1 }).startOf('week');
    // Calculate the end of the week
    const endOfWeek = startOfWeek.plus({ days: 6 });

    return `${startOfWeek.toFormat('MMM dd')} - ${endOfWeek.toFormat('MMM dd')}`;
  };

  const handleWeekChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedWeek = parseInt(e.target.value);
    setWeekInFocus(selectedWeek);
  }

  const incrementWeekNumber = () => {
    const selectedWeek = weekInFocus + 1;
    
    if (selectedWeek > weeksInTheYear.length) {
      // increment year and reset week number
      setYearInFocus(yearInFocus + 1);
      setWeekInFocus(1);
      return;
    } else {
      setWeekInFocus(selectedWeek);
    }
  }

  const decrementWeekNumber = () => {
    const selectedWeek = weekInFocus - 1;

    if (selectedWeek < 1) {
      // decrement year and set week number to last week of the year
      setYearInFocus(yearInFocus - 1);
      const lastWeekOfYear = DateTime.fromObject({ year: yearInFocus - 1 }).endOf('year').weekNumber;
      setWeekInFocus(lastWeekOfYear);
      return;
    } else {
      setWeekInFocus(selectedWeek);
    }
  }

  return (
    <div css={tw`w-full flex justify-center items-center text-center`}>
      <div css={tw`w-full flex items-center gap-x-2`}>
        <button onClick={decrementWeekNumber} css={tw`border-2 rounded-full p-2 leading-none text-center`}>←</button>
        <select
          css={tw`w-full p-2 border-2 rounded-lg`}
          value={weekInFocus}
          onChange={handleWeekChange}
          style={{ maxWidth: "180px"  }}
          >
          {weeksInTheYear.map((week) => (
            <option key={week} value={week}>
              {getWeekRange(week)}
            </option>
          ))}
        </select>
        <button onClick={incrementWeekNumber} css={tw`border-2 rounded-full p-2 leading-none text-center`}>→</button>
        <h3 css={tw`font-bold text-2xl px-5`}>{yearInFocus}</h3>
      </div>
    </div>
  )
}