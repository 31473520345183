import {
  VictoryChart,
  VictoryVoronoiContainer,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryAxis,
  VictoryLabel,
  VictoryArea,
} from "victory";
import { 
  ClientNetSalesRow, // type
  sumNetSalesSplit,
  filterByCompletedCancelled
} from "../../../helpers/brandAnalysis";

// constants
import { forecastGreyColor } from "../Charts/constants"; // the grey color for cones on the chart

import _ from "lodash";
// types
import { ClientAnnualRevenueForecast } from "../../../codegen/graphql-types";
import { GraphLine } from "./BrandPerformance";
import { DateTime, Interval } from "luxon";

const currencyFormatter = new Intl.NumberFormat("en-US", {});

export const ClientRevenueChart = ({ 
  client,
  clientName,
  yearInFocus,
  selectedGraphLines,
  brandForecastData
}: { 
  client: ClientNetSalesRow,
  yearInFocus: number,
  clientName: string,
  selectedGraphLines: GraphLine[],
  brandForecastData: ClientAnnualRevenueForecast
}) => {
  
  // This is the grey horizontal line on the chart
  const dollarsSold = client.sumInProgressOnHoldInFocusYear + client.sumNetSalesSplitInFocusYear;
  const revenueTargetDollars = dollarsSold + 50000; // make this relative to this highest value in the data
  const clientTotalFocusYear = "#4EA6D2"; // blue

  const hasSoldTarget = dollarsSold >= revenueTargetDollars;
  const max = Math.max(revenueTargetDollars, dollarsSold);

  // monthly data for net earned sales (completed + cancelled) for the year in focus
  // This is the red line on the chart
  const focusYearColor = "#F22102"; // red
  const monthlyDataNetEarnedSalesFocusYear = Array.from({ length: 12 }, (_, i) => {
    const startOfMonth = DateTime.fromObject({ year: yearInFocus, month: i + 1, day: 1 });
    const sumNetSalesSplitInMonth = sumNetSalesSplit(
      client.projectsInFocusYear
        .filter(filterByCompletedCancelled)
        .filter((project: any) => {
          const endDate = new Date(project.end_date);
          const projectEndMonth = endDate.getMonth() + 1; // add 1 to get months in 1-12, instead of 0-11
          const targetMonth = startOfMonth.month; // 1-12
          return projectEndMonth === targetMonth;
        })
    );

    return {
      year: yearInFocus,
      startOfMonth,
      netSales: sumNetSalesSplitInMonth,
    };
  }).map((x, i, arr) => ({
    ...x,
    cumulativeNetSales: _.sumBy(arr.slice(0, i + 1), "netSales") ?? 0,
  }));

  // monthly data for net earned sales (completed + cancelled) for the previous year
  // This is the green line on the chart
  const previousYearColor = "#20BB03"; // green
  const monthlyDataNetEarnedSalesPreviousYear = Array.from({ length: 12 }, (_, i) => {
    const startOfMonth = DateTime.fromObject({ year: yearInFocus - 1, month: i + 1, day: 1 });
    const sumNetSalesSplitInMonth = sumNetSalesSplit(
      client.projectsInPreviousYear
        .filter(filterByCompletedCancelled)
        .filter((project: any) => {
          const endDate = new Date(project.end_date);
          const projectEndMonth = endDate.getMonth() + 1; // add 1 to get months in 1-12, instead of 0-11
          const targetMonth = startOfMonth.month; // 1-12
          return projectEndMonth === targetMonth;
        })
    );

    return {
      year: yearInFocus - 1,
      startOfMonth,
      netSales: sumNetSalesSplitInMonth,
    };
  }).map((x, i, arr) => ({
    ...x,
    cumulativeNetSales: _.sumBy(arr.slice(0, i + 1), "netSales") ?? 0,
  }));

  const firstDayOfYearInFocus: DateTime = DateTime.fromObject({ year: yearInFocus, month: 1, day: 1 });
  const lastDayOfYearInFocus: DateTime = DateTime.fromObject({ year: yearInFocus, month: 12, day: 31 });
  const currentMonth = DateTime.local().month;

  return (
    <VictoryChart
      height={500}
      width={600}
      padding={80}
      containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
    >
      <VictoryLabel
        x={300} y={40}
        textAnchor="middle"
        style={{ fontSize: 20, fontWeight: "bold", fontFamily: "inherit"}}
        text={`${clientName}`}
      />
      {/* Focus Year Completed + Cancelled Solid Line */}
      {selectedGraphLines.includes(GraphLine.ThisYear) && (
        <VictoryLine
          data={monthlyDataNetEarnedSalesFocusYear
            .filter(x => x.startOfMonth.month <= currentMonth)
            .map(({startOfMonth, netSales, cumulativeNetSales}) => {
              const month = startOfMonth.month;
              const monthStart = firstDayOfYearInFocus.set({ month });      
              return {
                i: month,
                x: monthStart.toSeconds(),
                y: cumulativeNetSales,
                netSales,
              };
            })}
          style={{
            data: {
              stroke: focusYearColor,
              strokeWidth: 2,
            },
          }}
        />
      )}
      {/* Focus Year Completed + Cancelled Scatter dotted line */}
      {selectedGraphLines.includes(GraphLine.ThisYear) && (
        <VictoryScatter
          data={monthlyDataNetEarnedSalesFocusYear
              .filter(x => x.startOfMonth.month <= currentMonth)
              .map(({startOfMonth, netSales, cumulativeNetSales}, i) => {
                const month = startOfMonth.month;
                const monthStart = firstDayOfYearInFocus.set({ month });
                return {
                  i,
                  x: monthStart.toSeconds(),
                  y: cumulativeNetSales,
                  netSales,
                };
              })}
          size={5}
          style={{ data: { fill: focusYearColor } }}
          labels={({ datum }) => [
            `${yearInFocus}-${datum.i + 1} (Completed + Cancelled): $${currencyFormatter.format(datum.y)}`,
          ]}
          labelComponent={
            <VictoryTooltip
              cornerRadius={0}
              flyoutStyle={{ fill: "white", stroke: focusYearColor }}
              dy={({ datum }) => {
                return (monthlyDataNetEarnedSalesFocusYear[datum.i]?.cumulativeNetSales ??
                  0) >
                  (revenueTargetDollars / 12) * datum.i
                  ? 40
                  : -0;
              }}
            />
          }
        />
      )}

      {/* Focus Year Completed + Cancelled + In Progress + On Hold Total */}
      {selectedGraphLines.includes(GraphLine.ThisYearTotal) && (
        <VictoryLine
          data={[
            {
              i: 0,
              x: firstDayOfYearInFocus.toSeconds(),
              y: dollarsSold,
            },
            {
              i: 1,
              x: lastDayOfYearInFocus.toSeconds(),
              y: dollarsSold,
            },
          ]}
          style={{
            data: {
              stroke: clientTotalFocusYear,
              strokeWidth: 2,
            },
            labels: { fill: clientTotalFocusYear },
          }}
          labels={({ datum }) =>
            datum.i === 0
              ? `(Completed + Cancelled in ${yearInFocus}) + In Progress + On Hold: $${currencyFormatter.format(datum.y)}`
              : null
          }
          labelComponent={
            <VictoryLabel
              textAnchor="start"
              dx="10"
              dy={hasSoldTarget ? -6 : 20}
            />
          }
        />
      )}

      {/* Last Year Completed + Cancelled Solid Line */}
      {selectedGraphLines.includes(GraphLine.LastYear) && (
        <VictoryLine
          data={monthlyDataNetEarnedSalesPreviousYear.map((client) => {
            const month = client.startOfMonth.month;
            const monthStart = firstDayOfYearInFocus.set({ month });
            return {
              year: client.year,
              x: monthStart.toSeconds(),
              y: client.cumulativeNetSales,
              netSales: client.netSales,
            };
          })}
          style={{
            data: {
              stroke: previousYearColor,
              strokeWidth: 2,
            },
            labels: { fill: previousYearColor },
          }}
        />
      )}
      {/* Last Year Completed + Cancelled Scatter dots */}
      {selectedGraphLines.includes(GraphLine.LastYear) && (
        <VictoryScatter
          data={monthlyDataNetEarnedSalesPreviousYear.map((client) => {
            const month = client.startOfMonth.month;
            const monthStart = firstDayOfYearInFocus.set({ month });
            return {
              year: client.year,
              month: month,
              x: monthStart.toSeconds(),
              y: client.cumulativeNetSales,
              netSales: client.netSales,
            };
          })}
          size={5}
          style={{ data: { fill: previousYearColor } }}
          labels={({ datum }) => [
            `${datum.year}-${datum.month} (Completed + Cancelled): $${currencyFormatter.format(datum.y)}`
          ]}
          labelComponent={
            <VictoryTooltip
              cornerRadius={0}
              flyoutStyle={{ fill: "white", stroke: previousYearColor }}
              dy={({ index }) => {
                const idx = Number(index);
                return (monthlyDataNetEarnedSalesPreviousYear[idx]?.cumulativeNetSales ?? 0) >
                  (revenueTargetDollars / 12) * (idx + 1)
                  ? 0
                  : 80;
              }}
            />
          }
        />
      )}

      {/* Grey Cone representing the low to high brand forecast data from January to December */}
      {selectedGraphLines.includes(GraphLine.ThisYearForecast) && (
        <VictoryArea
          data={Array.from({ length: 12 }, (_, i) => {
            const startOfMonth = DateTime.fromObject({ year: yearInFocus, month: i + 1, day: 1 });
            const month = startOfMonth.month; // 1-12
            const { lowForecastDollars, highForecastDollars } = brandForecastData;
            return {
              x: startOfMonth.toSeconds(),
              y0: lowForecastDollars / 12 * month,
              y: highForecastDollars / 12 * month,
            };
          })}
          style={{
            data: {
              fill: forecastGreyColor,
              fillOpacity: 0.3,
              pointerEvents: "none",
            },
          }}
        />
      )}

      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 15,
            angle: 30,
            textAnchor: "left",
            verticalAnchor: "start",
          },
          ticks: {
            stroke: "#000000",
            size: 8,
          },
        }}
        tickValues={Interval.fromDateTimes(
          monthlyDataNetEarnedSalesFocusYear[0].startOfMonth,
          monthlyDataNetEarnedSalesFocusYear[0].startOfMonth.plus({ months: 12 })
        )
          .splitBy({ months: 1 })
          .map((x) => x.start.toSeconds())}
        tickFormat={(x) => DateTime.fromSeconds(x).toFormat("MMMM")}
      />
      <VictoryAxis dependentAxis domain={[0, max ?? 0]} />
    </VictoryChart>
  )
}