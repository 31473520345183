
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Money } from "../../components/Money";

export const EstimateBudgetUnaccountedForTooltipContent = ({
  estimateId,
}: {
  estimateId: string;
}) => {
  const { data, loading } = useQuery(
    gql`
      query EstimateProjectBudgets($where: estimateWhereUniqueInput!) {
        estimate(where: $where) {
          id
          projects {
            id
            name
            budget
          }
        }
      }
    `,
    {
      variables: {
        where: {
          id: estimateId,
        },
      },
    }
  );

  return (
    <table>
      {!!data?.estimate?.projects?.length && (
        <thead>
          <tr>
            <th align="left">Project</th>
            <th align="right">Budget</th>
          </tr>
        </thead>
      )}
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={2}>Loading...</td>
          </tr>
        ) : data?.estimate?.projects.length ? (
          data.estimate.projects.map((project: any, i: number) => {
            return (
              <tr
                key={project.id}
                style={{
                  ...(i % 2 === 0
                    ? { backgroundColor: "rgba(0,0,0,0.1)" }
                    : {}),
                }}
              >
                <td>{project.name}</td>
                <td style={{
                    paddingLeft: 10,
                }}>
                  <Money value={project.budget} />
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={2}>No projects associated with this estimate</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
