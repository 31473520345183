import tw from "twin.macro";
import { useQuickbooksAuthContext } from "../useQuickbooksAuth";


const LogoutFromQuickbooks = ({ isLoggingOut }: {
  isLoggingOut: boolean;
}) => {

  const { quickbooksLogout } = useQuickbooksAuthContext();

  return (
    <button 
      css={tw`bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4`}
      onClick={quickbooksLogout}
      disabled={isLoggingOut}
      style={{
        opacity: isLoggingOut ? 0.5 : 1,
      }}
    >
      {isLoggingOut ? "Logging Out..." : "Log Out From Quickbooks"}
    </button>
  );
};

export default LogoutFromQuickbooks;