import { PersonTimeAnalysis, Hours, ROLES, BillableVsNonBillableTime, UtilizedVsNonUtilizedTime } from "./TeamTimeTargets";
import { PeopleTimeTargetsAndEntriesQuery, TimeEntry } from "../../codegen/graphql-types";
import { getTargetTotal, getAverageHoursPerDay, getTargetPerDay, minutesToHours } from "../../helpers/time";
import { filterBillableTime, filterNonBillableTime } from "../../Time/constants";
import { CUSTOM_TIME_TAGS_MAP } from "../../helpers/CUSTOM_TAG_MAP";
import { DateTime } from "luxon";
import { peopleIdsToExclude } from "../../Time/constants";
import { sumEntryMinutes, daysInDateRange } from "../../Time/dates";
import groupTimeEntriesByProject from "./groupTimeEntriesByProject";
// Processes data from graphql query into the type PersonTimeAnalysis

interface TimeDataTransformerProps {
  data: PeopleTimeTargetsAndEntriesQuery;
  yearInFocus: number;
  startDate: Date;
  endDate: Date;
}

export const timeDataTransformer = ({
  data,
  yearInFocus,
  startDate,
  endDate,
}: TimeDataTransformerProps): PersonTimeAnalysis[] => {
  const thisYear = DateTime.now().year;
  const { peopleTimeTargetsAndEntries } = data;

  const peopleTimeAnalysis: PersonTimeAnalysis[] = peopleTimeTargetsAndEntries
    .filter((person) => !peopleIdsToExclude.includes(person.id) && !person.is_deleted_on_tw && person.company === "Maven Medical Marketing Inc.")
    .map(person => {
      const timeEntries = person.timeEntries as TimeEntry[];
      const teamworkUserCreatedDate = DateTime.fromISO(person.created_at).toJSDate();
      const adjustedStartDate = teamworkUserCreatedDate > startDate && thisYear === yearInFocus ? teamworkUserCreatedDate : startDate;
      const {
        totalDaysInRange,
        numberOfWorkingDaysInRange: workingDays,
        weekendDaysInRange: weekendDays,
        holidaysInRange: holidaysInRangeIncludingExtendedLongWeekends,
        userDaysOffWithinInterval: daysOffWithinRange,
      } = daysInDateRange(adjustedStartDate, endDate, person.daysOff);
      
      // Find the person's role title and team name based on their teamwork job title (person.title) and it's presence in the ROLES[].teams[].jobTitles
      let roleTitle: "Senior" | "Mid-level" | "Junior" | "" = "";
      let teamName: 'Account team' | 'Design team' | 'Dev team' | 'Copy team' | "" = "";
      ROLES.find(role => role.teams.find(team => {
        const personTeam = team.jobTitles.includes(person.title);
        if (personTeam) {
          roleTitle = role.roleTitle as "Senior" | "Mid-level" | "Junior" | "" ;
          teamName = team.teamName as 'Account team' | 'Design team' | 'Dev team' | 'Copy team' | "" ;
        }
        return undefined;
      }));
      // Total Hours (target, actual, percent of target)
      const timeTargetsForYear = person.annual_time_targets.find(target => target.year === yearInFocus);
      const totalTargetHoursPerDay = getTargetTotal(timeTargetsForYear);
      const totalActualMinutesLogged = timeEntries.reduce(sumEntryMinutes, 0);
      const averageHoursPerDay = getAverageHoursPerDay(timeEntries, adjustedStartDate, endDate, person.daysOff); 

      const totalHours = {
        label: "Total Hours",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: totalActualMinutesLogged,
        projects: [],
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        target: totalTargetHoursPerDay,
        actual_ytd: averageHoursPerDay,
        percent_of_target: totalTargetHoursPerDay > 0 ? Math.ceil(averageHoursPerDay / totalTargetHoursPerDay * 100) : 0,
      };
      // Utilized Time Hours (target, actual, percent of target)
      // 1) Billable
      const billableTarget = getTargetPerDay(timeTargetsForYear, 'billableMinutes');
      const billableTimeEntries = timeEntries.filter(filterBillableTime);
      const totalBillableMinutes = billableTimeEntries.reduce(sumEntryMinutes, 0);
      const averageBillableHoursPerDay = getAverageHoursPerDay(billableTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const billableHours: Hours = {
        label: "Billable",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: billableTimeEntries.reduce(sumEntryMinutes, 0),
        projects: [],
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        target: billableTarget,
        actual_ytd: averageBillableHoursPerDay,
        percent_of_target: billableTarget > 0 ? Math.ceil(averageBillableHoursPerDay / billableTarget * 100) : 0,
      };

      // All other "utilized time" categories are filtered from non-billable time entries
      const nonBillableTimeEntries = timeEntries.filter(filterNonBillableTime);
      const totalNonBillableMinutes = nonBillableTimeEntries.reduce(sumEntryMinutes, 0);
      // const averageNonBillableHoursPerDay = getAverageHoursPerDay(nonBillableTimeEntries, adjustedStartDate, endDate, person.daysOff);

      // 2) Sales and Marketing
      const salesAndMarketingTarget = getTargetPerDay(timeTargetsForYear, 'salesAndMarketingMinutes');
      const salesAndMarketingTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Sales and marketing']);
      const totalSalesAndMarketingMinutes = salesAndMarketingTimeEntries.reduce(sumEntryMinutes, 0);
      const averageSalesAndMarketingHoursPerDay = getAverageHoursPerDay(salesAndMarketingTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const salesAndMarketingHours: Hours = {
        label: "Sales & Marketing",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: salesAndMarketingTimeEntries.reduce(sumEntryMinutes, 0),
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        projects: groupTimeEntriesByProject(salesAndMarketingTimeEntries),
        target: salesAndMarketingTarget,
        actual_ytd: averageSalesAndMarketingHoursPerDay,
        percent_of_target: salesAndMarketingTarget > 0 ? Math.ceil(averageSalesAndMarketingHoursPerDay / salesAndMarketingTarget * 100) : 0,
      };
      // 3) Innovation and Development
      const innovationAndDevTarget = getTargetPerDay(timeTargetsForYear, 'innovationAndDevMinutes');
      const innovationAndDevTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Innovation and development']);
      const totalInnovationAndDevMinutes = innovationAndDevTimeEntries.reduce(sumEntryMinutes, 0);
      const averageInnovationAndDevHoursPerDay = getAverageHoursPerDay(innovationAndDevTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const innovationAndDevHours: Hours = {
        label: "Innovation & Development",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: innovationAndDevTimeEntries.reduce(sumEntryMinutes, 0),
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        projects: groupTimeEntriesByProject(innovationAndDevTimeEntries),
        target: innovationAndDevTarget,
        actual_ytd: averageInnovationAndDevHoursPerDay,
        percent_of_target: innovationAndDevTarget > 0 ? Math.ceil(averageInnovationAndDevHoursPerDay / innovationAndDevTarget * 100) : 0,
      };
      // 4) Operational
      const operationalTarget = getTargetPerDay(timeTargetsForYear, 'operationalMinutes');
      const operationalTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Operational activities']);
      const totalOperationalMinutes = operationalTimeEntries.reduce(sumEntryMinutes, 0);
      const averageOperationalHoursPerDay = getAverageHoursPerDay(operationalTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const operationalHours: Hours = {
        label: "Operational",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: operationalTimeEntries.reduce(sumEntryMinutes, 0),
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        projects: groupTimeEntriesByProject(operationalTimeEntries),
        target: operationalTarget,
        actual_ytd: averageOperationalHoursPerDay,
        percent_of_target: operationalTarget > 0 ? Math.ceil(averageOperationalHoursPerDay / operationalTarget * 100) : 0,
      };
      // 5) Professional Development
      const professionalDevTarget = getTargetPerDay(timeTargetsForYear, 'professionalDevMinutes');
      const professionalDevTimeEntries = nonBillableTimeEntries.filter(entry => Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Time: Professional development']);
      const totalProfessionalDevMinutes = professionalDevTimeEntries.reduce(sumEntryMinutes, 0);
      const averageProfessionalDevHoursPerDay = getAverageHoursPerDay(professionalDevTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const professionalDevHours: Hours = {
        label: "Professional Development",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: professionalDevTimeEntries.reduce(sumEntryMinutes, 0),
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        projects: groupTimeEntriesByProject(professionalDevTimeEntries),
        target: professionalDevTarget,
        actual_ytd: averageProfessionalDevHoursPerDay,
        percent_of_target: professionalDevTarget > 0 ? Math.ceil(averageProfessionalDevHoursPerDay / professionalDevTarget * 100) : 0,
      };

      // 6) Non-utilized time
      const internalAdminHoursTarget = getTargetPerDay(timeTargetsForYear, 'nonBillableMinutes');
      const internalAndAdminTimeEntries = nonBillableTimeEntries.filter(entry => entry.project_tag_id === "" || Number(entry.project_tag_id) === CUSTOM_TIME_TAGS_MAP['Internal non-billable time']);
      const totalInternalAndAdminMinutes = internalAndAdminTimeEntries.reduce(sumEntryMinutes, 0);
      const averageInternalAdminHoursPerDay = getAverageHoursPerDay(internalAndAdminTimeEntries, adjustedStartDate, endDate, person.daysOff);
      const internalAndAdminHours: Hours = {
        label: "Internal & Admin",
        startDate: adjustedStartDate,
        endDate,
        totalMinutes: internalAndAdminTimeEntries.reduce(sumEntryMinutes, 0),
        numberOfWorkingDays: workingDays,
        numberOfDaysOff: daysOffWithinRange.length,
        projects: groupTimeEntriesByProject(internalAndAdminTimeEntries),
        target: internalAdminHoursTarget,
        actual_ytd: averageInternalAdminHoursPerDay,
        percent_of_target: internalAdminHoursTarget > 0 ? Math.ceil(averageInternalAdminHoursPerDay / internalAdminHoursTarget * 100) : 0,
      };

      // Utilization Rates
      /// 1) Billable vs Non-Billable Utilization Rate: 
      /// "Total (average) Billable hours (per day) divided by (average) total hours (per day)"
      const billableVsNonBillableRate: BillableVsNonBillableTime = {
        label: "Billable vs Non-Billable",
        startDate: adjustedStartDate,
        endDate: endDate,
        totalHours: minutesToHours(totalActualMinutesLogged),
        billableHours: minutesToHours(totalBillableMinutes),
        nonBillableHours: minutesToHours(totalNonBillableMinutes),
        rate: averageHoursPerDay > 0 ? Math.ceil(averageBillableHoursPerDay / averageHoursPerDay * 100) : 0
      };
      /// 2) Utilized vs Non-Utilized Utilization Rate:
      /// "Total (average) Utilized hours (per day) (sum) divided by (average) total hours (per day)"
      const sumAverageUtilizedHoursPerDay = averageBillableHoursPerDay + averageSalesAndMarketingHoursPerDay + averageInnovationAndDevHoursPerDay + averageOperationalHoursPerDay + averageProfessionalDevHoursPerDay;
      const utilizedVsNonUtilizedRate: UtilizedVsNonUtilizedTime = {
        label: "Utilized vs Non-Utilized",
        startDate: adjustedStartDate,
        endDate: endDate,
        totalHours: minutesToHours(totalActualMinutesLogged),
        utilizedHours: minutesToHours(totalBillableMinutes + totalSalesAndMarketingMinutes + totalInnovationAndDevMinutes + totalOperationalMinutes + totalProfessionalDevMinutes),
        billableHours: minutesToHours(totalBillableMinutes),
        salesMarketingHours: minutesToHours(totalSalesAndMarketingMinutes),
        innovationDevelopmentHours: minutesToHours(totalInnovationAndDevMinutes),
        operationalHours: minutesToHours(totalOperationalMinutes),
        professionalDevelopmentHours: minutesToHours(totalProfessionalDevMinutes),
        internalAdministrativeHours: minutesToHours(totalInternalAndAdminMinutes),
        rate: averageHoursPerDay > 0 ? Math.ceil(sumAverageUtilizedHoursPerDay / averageHoursPerDay * 100) : 0
      };

    return {
      roleTitle,
      teamName,
      jobTitle: person.title,
      name: person.first_name,
      id: person.id,
      days: {
        startDate: adjustedStartDate,
        endDate,
        totalDaysInRange,
        workingDays,
        weekendDays,
        holidaysInRangeIncludingExtendedLongWeekends,
        daysOffWithinRange: daysOffWithinRange.length,
        sickDays: daysOffWithinRange.filter(dayOff => dayOff.reason.toLowerCase() === "sick").length,
        vacationDays: daysOffWithinRange.filter(dayOff => dayOff.reason.toLowerCase() === "vacation").length,
        otherDaysOff: daysOffWithinRange.filter(dayOff => dayOff.reason.toLowerCase() === "other").length,
      },
      rate: person.rate,
      totalHours,
      utilizedTime: {
        billable: billableHours,
        sales_marketing: salesAndMarketingHours,
        innovation_development: innovationAndDevHours,
        operational: operationalHours,
        professional_development: professionalDevHours,
      },
      nonUtilizedTime: {
        internal_administrative: internalAndAdminHours
      },
      utilizationRate: {
        billable_vs_nonBillable: billableVsNonBillableRate,
        utilized_vs_nonUtilized: utilizedVsNonUtilizedRate
      }
    }
  });

  return peopleTimeAnalysis;
};