import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import constate from "constate";
import { UserTimeSummaryDocument } from "../codegen/graphql-types";
import { useDateRanges } from "../Time/useDateRanges";
import { parseISO, endOfDay, startOfDay } from "date-fns";

const useRecentTimeEntries = () => {
  // Date range for the last three months
  const { lastThreeMonths  } = useDateRanges();

  // Include all times of day in time range - there might be small chance of missing entries due to start date timezone being in daylight savings time
  const lastThreeMonthsStartDate = startOfDay(parseISO(lastThreeMonths.startDate)).toISOString(); // GMT-0400 (Eastern Daylight Time)
  const lastThreeMonthsEndDate = endOfDay(parseISO(lastThreeMonths.endDate)).toISOString(); // GMT-0500 (Eastern Standard Time)

  // get the time entries for the user from the last three months
  const queryResult = useQuery(UserTimeSummaryDocument, {
    variables: {
      start_date: lastThreeMonthsStartDate,
      end_date: lastThreeMonthsEndDate,
      year: parseISO(lastThreeMonths.startDate).getFullYear(),
    }
  });

  return useMemo(() => ({
    ...queryResult,
  }), [queryResult]);
};

export const [RecentTimeProvider, useRecentTimeContext] = constate(useRecentTimeEntries);