
import React from "react";
import Select from "react-select";
import { UseFiltersColumnProps } from "react-table";

export function RequiresReviewFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: UseFiltersColumnProps<Record<string, unknown>> & { id: string };
}) {
  const options = React.useMemo(() => {
    const counts = {
      "Requires review": 0,
      "Reviewed": 0
    };

    preFilteredRows.forEach((row) => {
      const { is_reviewed, relativeProfitability } = row.values[id];
      if (relativeProfitability && relativeProfitability < 1) {
        counts[is_reviewed ? "Reviewed" : "Requires review"]++;
      }
    });

    return [
      { value: "Requires review", label: `Requires review (${counts["Requires review"]})` },
      { value: "Reviewed", label: `Reviewed (${counts["Reviewed"]})` },
    ];
  }, [id, preFilteredRows]);

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      value={filterValue?.map((value: string) => options.find(option => option.value === value))}
      styles={{
        multiValue: (base) => ({
          ...base,
          minWidth: "8rem"
        }),
      }}
      onChange={(selectedOptions) => {
        const allValues = selectedOptions?.map(o => o.value) || [];
        setFilter(allValues.length ? allValues : undefined);
      }}
      options={options}
    />
  );
}