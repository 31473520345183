import tw from "twin.macro";
import { useState } from "react";
import ProjectSelector from "./ProjectSelector";
import { Button } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
// import the open window icon from chakra-ui/icons
import { LastSynced } from "../Projects/Dashboard/ProjectsTableDataContainer";
import { subDays } from "date-fns";
import type { ProjectPricingDetailsDataResponse } from "../helpers/usePricingData";
import { usePricingDataContext } from "../helpers/usePricingData";

export const ProjectPricingSelector = ({
  projectDetails,
}: {
  projectDetails?: ProjectPricingDetailsDataResponse;
}) => {
  const [isSyncing, setIsSyncing] = useState(false);
  const { refetchProjectPricing } = usePricingDataContext();

  const projectId = projectDetails?.id;
  const projectName = projectDetails?.name;
  const projectLastSynced = projectDetails?.last_synced_details_at;

  // if projectLastSynced is undefined, set lastSynced to yesterday
  const lastSynced = projectLastSynced
    ? new Date(projectLastSynced)
    : subDays(new Date(), 1);

  return (
    <div css={tw`border max-w-max p-4`}>
      <div css={tw`pb-4`}>
        {/* Project Header and Teamwork Link */}
        {projectName && projectId && (
          <header>
            <a
              href={`https://mavenmm.teamwork.com/app/projects/${projectId}/tasks/list`}
              target="_blank"
              rel="noopener noreferrer"
              css={tw`inline-block font-bold flex flex-row justify-between items-center`}
            >
              {projectName}
              <div css={tw`h-full flex items-center justify-center`}>
                <ExternalLinkIcon />
              </div>
            </a>
          </header>
        )}
        {!projectName && (
          <p css={tw`text-sm`}>Select a project from teamwork:</p>
        )}
      </div>

      {/* Project Selector */}
      <div css={tw`grid grid-cols-4 gap-4 items-center`}>
        <div css={tw`col-span-3`}>
          <ProjectSelector />
        </div>
        {!isSyncing && projectId && projectLastSynced && (
          <div css={tw`col-span-1`}>
            <p css={tw`text-xs text-center pb-1`}>Last synced</p>
            <LastSynced
              dateSynced={lastSynced}
              projectId={projectId}
              onSyncStart={() => setIsSyncing(true)}
              onSyncComplete={async () => {
                //await refetchProjects();
                console.log("DONE SYNCING");
                setIsSyncing(false);
                refetchProjectPricing();
              }}
            />
          </div>
        )}
        {isSyncing && (
          <div css={tw`col-span-1`}>
            <Button
              colorScheme="blue"
              isLoading={isSyncing}
              loadingText="Syncing..."
            />
          </div>
        )}
      </div>
    </div>
  );
};
