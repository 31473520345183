import { css } from "@emotion/react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";

interface StartEndDateFiltersProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const StartEndDateFilters = ({ startDate, endDate, setStartDate, setEndDate }: StartEndDateFiltersProps) => {
  return (
    <Stack
      css={css`
        position: relative;
      `}
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
      }}
      direction="row"
      justifyContent="flex-start"
      mb="4"
    >
      <FormControl width="auto">
        <FormLabel>Minimum Start Date</FormLabel>
        <Input
          type="date"
          defaultValue={startDate?.toLocaleDateString("en-CA")}
          onChange={(e) => {
            setStartDate(new Date(e.target.value));
          }}
        />
      </FormControl>
      <FormControl width="auto">
        <FormLabel>Maximum End Date</FormLabel>
        <Input
          type="date"
          defaultValue={endDate?.toLocaleDateString("en-CA")}
          onChange={(e) => {
            setEndDate(new Date(e.target.value));
          }}
        />
      </FormControl>
    </Stack>
  );
};

export default StartEndDateFilters;