
import tw, { css } from "twin.macro";
import { useQuery } from "@apollo/client";
import { 
  GetProjectsForBrandAnalysisQueryResult,
  GetProjectsForBrandAnalysisQuery,
  GetProjectsForBrandAnalysisQueryVariables,
  GetProjectsForBrandAnalysisDocument,
  TimeEntry,
  Person
} from "../../codegen/graphql-types";
import { Stack, Text, Tooltip } from "@chakra-ui/react";
import {
  projectsGroupedByClientName,
  processClientRows,
  ClientNetSalesRow,
} from "../../helpers/brandAnalysis";
import { ProjectRow } from "../../helpers/useProjectRows";
import { useProjectRows } from "../../helpers/useProjectRows";
import { usePodTimeContext } from "../../helpers/usePodTimeContext";
import { Table } from "../../components/Table";
import { TextTruncateRevealOnHover } from "../../Projects/Dashboard/TextTruncateRevealOnHover";
import { FullSpinner } from "../../FullSpinner";
import { sumEntryMinutes } from "../dates";
import { parseISO, format } from "date-fns";
import { Fragment } from "react";

interface PodTimeSummaryProps {
  podId: string;
  podSeniorBrandMavenLead: string;
}

type PodMemberHoursOnClient = {
  memberName: string;
  memberId: string;
  completedHours: number;
  cancelledHours: number;
  inProgressHours: number;
  timeTracking: number;
  onHoldHours: number;
  otherPodsProjectHours: number;
  totalHours: number;
}

const currencyFormatter = new Intl.NumberFormat("en-US", {});

// Only uses time logs of Pod Members for the current year (year to date)
// Only uses projects from 2020 that belong to the Pod Lead or the Pod id exists in the Pod Sales Split for the project
export const PodNetSalesTimeComparison = ({podId, podSeniorBrandMavenLead}: PodTimeSummaryProps) => {
  // Query for projects of the current user's pod since 2020 that have a project_status of "Time tracking", "Completed", "Cancelled", "On Hold", or "In Progress"
  const result: GetProjectsForBrandAnalysisQueryResult = useQuery<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>(GetProjectsForBrandAnalysisDocument, {
    variables: {
      "where": {
        "AND": [
          {
            "OR": [
              { "start_date": { "gt": "2020-01-01T04:00:00.000Z" } },
              { "start_date": { "equals": null } }
            ]
          },
          {
            "OR": [
              { "senior_brand_maven_lead": { "in": [podSeniorBrandMavenLead] } },
              { "sales_split_pod_id": { "in": [podId] } },
              { "senior_brand_maven_lead": { "equals": null } }
            ] 
          },
          { "is_deleted": { "equals": false } },
          { "project_status": { "in": ["Time tracking", "Completed", "Cancelled", "On Hold", "In Progress"] } }
        ]
      }
    }
  });

  const postProcessedProjectRows = useProjectRows(result);
  const { yearToDatePodTime } = usePodTimeContext();

  // projects with end date in current year
  const currentYear = new Date().getFullYear();
  // projects grouped by client name
  const projectsByClientName = projectsGroupedByClientName(postProcessedProjectRows);
  // client rows
  const clientRows = processClientRows(projectsByClientName, currentYear);


  if (result.loading || yearToDatePodTime.loading) {
    return <FullSpinner />;
  }
  if (result.error) {
    return <div>Failed to fetch pod projects. Please check the logs.</div>;
  }
  if (yearToDatePodTime.error) {
    return <div>Failed to fetch pod time entries. Please check the logs.</div>;
  }
  if (!yearToDatePodTime.variables) {
    return <div>Failed to fetch pod time entries. Please check the logs.</div>;
  }

  const startDate = format(
    parseISO(yearToDatePodTime.variables.start_date),
    "MMM dd, yyyy"
  );
  const endDate = format(
    parseISO(yearToDatePodTime.variables.end_date),
    "MMM dd, yyyy"
  );

  const clientRowsWithTotalNetSales = clientRows.map((clientRow) => {
    const totalNetSales = clientRow.sumNetSalesSplitInFocusYear + clientRow.sumInProgressOnHoldInFocusYear;
    return {
      ...clientRow,
      totalNetSales,
    };
  });

  const sumOfTotalNetSales = clientRowsWithTotalNetSales.reduce((acc, clientRow) => acc + clientRow.totalNetSales, 0);

  const clientRowsWithPercentageOfTotalNetSales = clientRowsWithTotalNetSales.map((clientRow) => {
    return {
      ...clientRow,
      percentageOfTotalNetSales: clientRow.totalNetSales / sumOfTotalNetSales,
    };
  });

  const clientRowsWithTotalHours = clientRowsWithPercentageOfTotalNetSales.map((clientRow) => {
    const clientId = clientRow.clientId;

    const podMinutesOnClient = yearToDatePodTime.data?.me.pod[0].members.map((member: Person) => {
      const timeEntriesForClientByMember = member.timeEntries
        .filter((timeEntry: TimeEntry) => timeEntry.company_id === clientId);

      return timeEntriesForClientByMember.reduce(sumEntryMinutes, 0);
    }).reduce((acc: number, memberMinutes: number) => acc + memberMinutes, 0);

    return {
      ...clientRow,
      totalHoursSpentOnClient: podMinutesOnClient / 60,
    };
  });

  // Sum of total hours spent on all clients
  const sumOfTotalClientHoursForPod = clientRowsWithTotalHours.reduce((acc, clientRow) => acc + clientRow.totalHoursSpentOnClient, 0);

  const getProjectStatusFromTimeEntry = (timeEntry: TimeEntry, clientRow: ClientNetSalesRow) => {
    const projectForTimeEntry = clientRow.projects.find((project: ProjectRow) => project.id === timeEntry.project_id);

    // if (!projectForTimeEntry) {
    //   console.log(`No project found for time entry for ${clientRow.clientName} projects for Pod Lead: ${podSeniorBrandMavenLead} or for sales split pods: `);
    //   console.log(timeEntry);
    // }

    return projectForTimeEntry?.project_status;
  };

  const getNumberOfHoursForProjectStatus = (timeEntries: TimeEntry[], clientRow: ClientNetSalesRow, projectStatus: string) => {
    return timeEntries.filter((timeEntry: TimeEntry) => getProjectStatusFromTimeEntry(timeEntry, clientRow) === projectStatus).reduce(sumEntryMinutes, 0) / 60;
  }

  const getNumberOfHoursForOtherPodsProjects = (timeEntries: TimeEntry[], clientRow: ClientNetSalesRow) => {
    return timeEntries.filter((timeEntry: TimeEntry) => {
      // Projects not found in the clientRow.projects array are projects not fetched from the above query
      // Which means they tracked time on the same client's projects that have a different SBM lead or no sales split with this Pod.
      const project = clientRow.projects.find((project: ProjectRow) => project.id === timeEntry.project_id);
      return !project;
    }).reduce(sumEntryMinutes, 0) / 60;
  }

  // Percentage of total hours spent on all clients + Pod Member Hours By Project Status for each client
  const processedClientRows = clientRowsWithTotalHours.map((clientRow) => {
    const clientId = clientRow.clientId;
    
    // This is the additional data that will be passed to the cell renderer with a dataKey for the "Hours" column for the Tooltip label
    const podMemberHoursOnClient: PodMemberHoursOnClient[] = yearToDatePodTime.data?.me.pod[0].members.map((member: Person) => {
      
      const timeEntriesForClient = member.timeEntries
        .filter((timeEntry: TimeEntry) => timeEntry.company_id === clientId);
      
      return {
        memberName: member.first_name,
        memberId: member.id,
        completedHours: getNumberOfHoursForProjectStatus(timeEntriesForClient, clientRow, "Completed"),
        cancelledHours: getNumberOfHoursForProjectStatus(timeEntriesForClient, clientRow, "Cancelled"),
        inProgressHours: getNumberOfHoursForProjectStatus(timeEntriesForClient, clientRow, "In Progress"),
        onHoldHours: getNumberOfHoursForProjectStatus(timeEntriesForClient, clientRow, "On Hold"),
        timeTracking: getNumberOfHoursForProjectStatus(timeEntriesForClient, clientRow, "Time tracking"),
        otherPodsProjectHours: getNumberOfHoursForOtherPodsProjects(timeEntriesForClient, clientRow),
        totalHours: timeEntriesForClient.reduce(sumEntryMinutes, 0) / 60,
      };
    });

    return {
      ...clientRow,
      podMemberHoursOnClient,
      percentageOfTotalHours: clientRow.totalHoursSpentOnClient / sumOfTotalClientHoursForPod,
    };
  });

  const getPercentageString = (percentage: number) => {
    return (percentage * 100).toFixed(0) + '%';
  }

  return (
    <section css={tw`w-full flex flex-col gap-y-3 items-center p-3`}>
      <Table
        parentColumns={[
          { title: "", colSpan: 1 },
          { title: "Net Sales (based on split)", colSpan: 5 },
          { title: "Time Logs", colSpan: 2 },
        ]}
        columns={[
          { 
            title: "Client",
            key: "clientName",
            // The value parameter is the dataRow value for this column accessed by key
            cell: (value) => <TextTruncateRevealOnHover text={value} maxWidth="15rem" /> 
          },
          { 
            title: <Tooltip label={<Stack><Text>(Time tracking + Completed + Cancelled)</Text></Stack>}>Profitability</Tooltip>,
            key: "sumProfitabilityInFocusYear", 
            cell: value => `μ = ${value}`,
          },
          { 
            title: "Completed + Cancelled",
            key: "sumNetSalesSplitInFocusYear",
            cell: (value) => `$${currencyFormatter.format(value)}`
          },
          { 
            title: "In Progress + On Hold",
            key: "sumInProgressOnHoldInFocusYear", 
            cell: (value) => `$${currencyFormatter.format(value)}`
          },
          {
            title: "Total",
            key: "totalNetSales", 
            cell: (value) => `$${currencyFormatter.format(value)}`
          },
          { 
            title: "% of Total Pod Sales",
            key: "percentageOfTotalNetSales",
            cell: (value) => <Tooltip label={value.toFixed(4)}>{getPercentageString(value)}</Tooltip>,
            css: [css`background: yellow;`]
          },
          { 
            title: "% of time",
            key: "percentageOfTotalHours",
            cell: (value) => <Tooltip label={value.toFixed(4)}>{getPercentageString(value)}</Tooltip>,
            css: [css`background: yellow;`]
          },
          { 
            title: "Hours",
            key: "totalHoursSpentOnClient",
            dataKey: "podMemberHoursOnClient",
            cell: (value, podMemberHoursOnClient: PodMemberHoursOnClient[]) => (
              <Tooltip label={
                <Stack>
                  <Text>Date Range: {startDate} - {endDate}</Text>
                  <Text>Pod:</Text>
                  <Text>
                    Completed + Cancelled: {podMemberHoursOnClient.reduce((acc: number, member: PodMemberHoursOnClient) => acc + member.completedHours + member.cancelledHours, 0).toFixed(2)}<br/>
                    In Progress + On Hold: {podMemberHoursOnClient.reduce((acc: number, member: PodMemberHoursOnClient) => acc + member.inProgressHours + member.onHoldHours, 0).toFixed(2)}<br/>
                    Time Tracking: {podMemberHoursOnClient.reduce((acc: number, member: PodMemberHoursOnClient) => acc + member.timeTracking, 0).toFixed(2)}<br/>
                    Other Pods*: {podMemberHoursOnClient.reduce((acc: number, member: PodMemberHoursOnClient) => acc + member.otherPodsProjectHours, 0).toFixed(2)}
                  </Text>
                  <Text>*Other pods means tracked time on the same client's projects that have a different SBM lead or no sales split with this Pod.</Text>
                  {podMemberHoursOnClient.map((member: PodMemberHoursOnClient) => (
                    <Fragment
                      // @ts-ignore
                      key={member.memberId}
                    >
                      <Text>
                        &emsp;{member.memberName}: {member.totalHours.toFixed(2)} hours<br/>
                        &emsp;&emsp;Completed + Cancelled: {(member.completedHours + member.cancelledHours).toFixed(2)} hours<br/>
                        &emsp;&emsp;In Progress + On Hold: {(member.inProgressHours + member.onHoldHours).toFixed(2)} hours<br/>
                        &emsp;&emsp;Time Tracking: {member.timeTracking.toFixed(2)} hours<br/>
                        &emsp;&emsp;Other Pods: {member.otherPodsProjectHours.toFixed(2)} hours
                      </Text>
                    </Fragment>
                  ))}
                </Stack>
              }>
                {value.toFixed(2)}
              </Tooltip>
            )
          },
        ]}
        dataRows={processedClientRows}
        defaultSortColumn="totalNetSales"
      />
    </section>
  )
};