
import { EditIcon } from "@chakra-ui/icons";
import {
    Box, Heading, Select,
    Stack
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import tw, { css } from "twin.macro";
import {
    useRevenueTargetsQuery,
    useSetCompanyRevenueTargetMutation,
    useSetPodRevenueTargetMutation
} from "../../codegen/graphql-types";
import { PopoverInput } from "../../helpers/PopoverInput";

const currencyFormatter = new Intl.NumberFormat("en-US", {});

export const RevenueTargets = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const [activeYear, setActiveYear] = useState(currentYear);

  const { data } = useRevenueTargetsQuery({
    variables: {
      companyAnnualRevenueTargetsStartingYear: activeYear - 3,
      podAnnualRevenueTargetYear: activeYear,
    },
  });

  const [setCompanyRevenueTarget] = useSetCompanyRevenueTargetMutation({
    refetchQueries: ["RevenueTargets"],
  });
  const [setPodRevenueTarget] = useSetPodRevenueTargetMutation({
    refetchQueries: ["RevenueTargets"],
  });

  return (
    <Stack
      css={css`
        .group-hover-visible {
          opacity: 0;
        }
        .group:hover .group-hover-visible {
          opacity: 1;
        }
      `}
    >
      <Heading size="lg" paddingTop="1em" color="#e535ab" flexShrink={0} display="flex" flexDirection="row">
        Net Sales Targets{" "}
        <Select
          placeholder="Select option"
          ml="4"
          width="max-content"
          onChange={(e) => {
            e.target.value && setActiveYear(Number(e.target.value));
          }}
          value={activeYear.toString()}
        >
          {new Array(10).fill(0).map((_x, i) => (
            <option key={i} value={currentYear - i}>
              {currentYear - i}
            </option>
          ))}
        </Select>
      </Heading>

      <Box px="6" py="2">
        <Heading size="md" flexShrink={0} display="flex" flexDirection="row">
          Maven Company Forecast (Low - High)
        </Heading>
        {new Array(3).fill(null).map((_x, i) => {
          const year = activeYear - i;
          const revenue_target_dollars = data?.companyAnnualRevenueTargets.find(
            (annualCompanyTarget) => {
              return annualCompanyTarget.year === year;
            }
            )?.revenue_target_dollars;
          
          const lowRevenueTargetDollars = revenue_target_dollars && "$" + currencyFormatter.format(revenue_target_dollars);
          const highReveneueTargetDollars = revenue_target_dollars && "$" + currencyFormatter.format(Math.floor(revenue_target_dollars * 1.3));

          return (
            <div
              key={`${activeYear}:${year}`}
              css={[
                tw`flex flex-row items-center my-1`,
                year !== activeYear && tw`text-gray-400`,
              ]}
              className="group"
            >
              <Heading
                size="sm"
                flexShrink={0}
                display="flex"
                flexDirection="row"
                w="16"
              >
                {year}
              </Heading>
              <div>
                {(!revenue_target_dollars && year < activeYear) ? (
                  <i>unspecified</i>
                ) : (
                  <PopoverInput
                    supressEditIcon={true}
                    initialValue={revenue_target_dollars ?? ""}
                    popoverHeaderText={`Enter the new low value:`}
                    displayText={
                      <>
                        {(lowRevenueTargetDollars) ?
                          <span>{lowRevenueTargetDollars}</span>
                         : <i>unspecified</i>}
                        <span>&nbsp;-&nbsp;</span>
                        {(highReveneueTargetDollars) ?
                          <span>{highReveneueTargetDollars}</span>
                         : <i>unspecified</i>}
                        <EditIcon
                          className="group-hover-visible"
                          style={{ marginLeft: "0.5em" }}
                        />
                      </>
                    }
                    onSubmit={async (e, newValue, { onClose }) => {
                      if (!newValue) return;
                      await setCompanyRevenueTarget({
                        variables: {
                          revenueTargetDollars: Number(newValue),
                          year,
                        },
                      });
                      onClose();
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}

        <Heading
          mt="6"
          size="md"
          flexShrink={0}
          display="flex"
          flexDirection="row"
        >
          SBM Forecast - {activeYear} (Low - High)
        </Heading>

        {data?.pods
          // .filter((pod) => pod.annual_revenue_target.revenue_target_dollars)
          .map((pod) => {
          const podRevenueTarget = pod.annual_revenue_target.revenue_target_dollars ?? 1000000;

          const lowPodRevenueTargetDollars = podRevenueTarget && "$" + currencyFormatter.format(podRevenueTarget);
          const highPodRevenueTargetDollars = podRevenueTarget && "$" + currencyFormatter.format(Math.floor(podRevenueTarget * 1.3));

          return (
            <div
              key={pod.id}
              css={tw`flex flex-row items-center my-1`}
              className="group"
            >
              <Heading
                size="sm"
                flexShrink={0}
                display="flex"
                flexDirection="row"
                w="16"
              >
                {pod.name}
              </Heading>
              <PopoverInput
                supressEditIcon={true}
                popoverHeaderText={`Enter the new low value:`}
                initialValue={
                  pod.annual_revenue_target.revenue_target_dollars ?? ""
                }
                displayText={
                  <>
                    {(lowPodRevenueTargetDollars) ?
                      <span>{lowPodRevenueTargetDollars}</span> 
                      : <i>unspecified</i>}
                      <span>&nbsp;-&nbsp;</span>
                    {(highPodRevenueTargetDollars) ?
                      <span>{highPodRevenueTargetDollars}</span>
                      : <i>unspecified</i>}
                    <EditIcon
                      className="group-hover-visible"
                      style={{ marginLeft: "0.5em" }}
                    />
                  </>
                }
                onSubmit={async (e, newValue, { onClose }) => {
                  if (!newValue) return;
                  await setPodRevenueTarget({
                    variables: {
                      revenueTargetDollars: Number(newValue),
                      year: activeYear, // changed from currentYear
                      podId: pod.id,
                    },
                  });
                  onClose();
                }}
              />
            </div>
          );
        })}
      </Box>

      {JSON.stringify(data)}
    </Stack>
  );
};
