
import _ from "lodash";
import React from "react";
import Select from "react-select";
import { UseFiltersColumnProps } from "react-table";

export function MultiSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: {
  column: UseFiltersColumnProps<Record<string, unknown>> & { id: string };
}) {
  const options = React.useMemo(() => {
    const uniqueOptionValues = _.sortBy(
      _.uniq(preFilteredRows.map((row) => row.values[id]))
    );

    return [
      { value: "", label: "All" },
      ...uniqueOptionValues.map((optionValue) => ({
        value: optionValue,
        label: `${optionValue} (${
          preFilteredRows.filter((row) => row.values[id] === optionValue).length
        })`,
      })),
    ];
  }, [id, preFilteredRows]);

  return (
    <Select
      isMulti
      closeMenuOnSelect={false}
      defaultValue={filterValue}
      styles={{
        multiValue: (base, props) => {
          return { ...base, minWidth: "8rem" };
        },
      }}
      onChange={(selectedOptions) => {
        const allValues = Array.from(selectedOptions ?? [])
          .map((o) => o.value)
          .filter(Boolean);
        setFilter(allValues && allValues.length ? allValues : undefined);
      }}
      options={options}
    />
  );
}
