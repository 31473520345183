
import tw, { css } from "twin.macro";
import { CompanyRevenueForecast } from "./CompanyRevenueForecast";
import { PodRevenueForecasts } from "./PodRevenueForecast";
import { BrandAnalysis } from "./BrandAnalysis";
import { usePeopleContext } from "../../../helpers/usePeopleContext";
import { Banner } from "../../../components/Banner";

export const RevenueForecasts = ({ 
  yearInFocus
}: {
  yearInFocus: number, 
  isAdmin: string | undefined | boolean
}) => {
  const peopleData = usePeopleContext();
  const loggedInPersonPods = peopleData?.data?.me?.pods;
  const podSeniorBrandMavenLead = loggedInPersonPods?.length ? loggedInPersonPods[0].senior_brand_maven_lead : null;
  const podId = loggedInPersonPods?.length ? loggedInPersonPods[0].id : null;

  return (
    <div
      css={[
        tw`w-full`,
        css`
          min-width: 1200px;
        `,
      ]}
    >
      <Banner>
        <h2 css={tw`font-bold text-xl`}>Forecast for Pod and Company</h2>
        <span css={tw`text-base`}>
          (Net sales of all projects that were completed or cancelled)
        </span>
      </Banner>
      <div css={tw`flex flex-row`}>
        <PodRevenueForecasts yearInFocus={yearInFocus} />
        <CompanyRevenueForecast yearInFocus={yearInFocus} />
      </div>
      <div css={tw`flex flex-col w-full`}>
        {podId && podSeniorBrandMavenLead && (
          <>
            <Banner>
              <h2 css={tw`font-bold text-xl`}>Brand Analysis</h2>
            </Banner>
            <div css={tw`flex flex-row w-full`}>
                <BrandAnalysis 
                  yearInFocus={yearInFocus}
                  podId={podId}
                  podSeniorBrandMavenLead={podSeniorBrandMavenLead}
                  peopleData={peopleData}
                />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
