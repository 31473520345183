
import tw, { css } from "twin.macro";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from "victory";
import { ChartHeading } from "./Charts/chartHelpers";
import {
  chartContainerWidth,
  currencyFormatter,
  palette,
} from "./Charts/constants";


export const NetSalesPerEmployee = function (props: {
  postProcessedRows: any;
  endYear: number;
  idToPersonMap: Record<string, any>;
  ownerIds: string[];
}) {
  return (
    <div css={[tw`flex-shrink-0 w-1/3`]}>
      <div style={{ width: chartContainerWidth }}>
        <VictoryChart
          height={500}
          width={600}
          domainPadding={{ y: 20 }}
          padding={80}
        >
          {props.ownerIds.map((ownerId, i) => {
            const owner = props.idToPersonMap[ownerId];
            const indexColor = palette[i % palette.length];

            return [
              <VictoryLine
                data={props.postProcessedRows.map((row: any) => ({
                  year: props.endYear,
                  x: row.monthName,
                  y:
                    row.endYearCumulativeGrossProfitByOwnerDict?.[ownerId] ?? 0,
                }))}
                style={{
                  data: {
                    stroke: indexColor,
                    strokeWidth: 2,
                    pointerEvents: "none",
                  },
                  labels: { fill: indexColor },
                }}
              />,
              <VictoryScatter
                data={props.postProcessedRows.map((row: any) => ({
                  year: props.endYear,
                  monthName: row.monthName,
                  x: row.monthName,
                  y:
                    row.endYearCumulativeGrossProfitByOwnerDict?.[ownerId] ?? 0,
                }))}
                size={5}
                style={{ data: { fill: indexColor } }}
                labels={({ datum }) =>
                  `${owner.first_name} ${owner.last_name} ${datum.monthName} ${
                    datum.year
                  }: $${currencyFormatter.format(datum.y)}`
                }
                labelComponent={
                  <VictoryTooltip
                    cornerRadius={0}
                    flyoutStyle={{ fill: "white" }}
                  />
                }
              />,
            ];
          })}
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: 15,
                paddingTop: 20,
                angle: 30,
                textAnchor: "left",
                verticalAnchor: "start",
              },
              ticks: {
                stroke: "#000000",
                size: 8,
              },
            }}
          />
          <VictoryAxis dependentAxis />
        </VictoryChart>
      </div>
      <ChartHeading>
        <h2 css={tw`text-center`}>Net Sales: Employee Contributions</h2>
      </ChartHeading>
    </div>
  );
};
