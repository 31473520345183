import { useQuery } from "@apollo/client"
import { 
  CompanyNameByCompanyIdDocument,
  ProjectNameByProjectIdDocument,
} from "../../codegen/graphql-types"

export const CompanyNameById = ({ id }: { id: string }) => {
  // request company name from apollo server by id
  const { loading, error, data } = useQuery(CompanyNameByCompanyIdDocument, {
    variables: { companyId: id },
  })

  if (loading) return "Loading..."

  if (error) return `Error! ${error.message}`

  const { clientById } = data;

  return clientById?.name || `No company name found for id ${id}`
}

export const ProjectNameById = ({ id }: { id: string }): any => {
  // request project name from apollo server by id
  const { loading, error, data } = useQuery(ProjectNameByProjectIdDocument, {
    variables: { projectId: id },
  })

  if (loading) return "Loading..."

  if (error) return `Error! ${error.message}`;

  const { projectById } = data;

  if (!projectById) return `No project name found for id ${id}`

  return (
    <a href={`https://mavenmm.teamwork.com/app/projects/${id}/time`} target="_blank">{projectById?.name}</a> 
  )
}
