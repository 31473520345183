
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { currencyFormatter, primaryColor, secondaryColor } from "./constants";
import type { ComparisonChartData } from "./constants";

export const MonthOverMonthChart = ({
  year1Data,
  year2Data,
}: {
  year1Data: ComparisonChartData;
  year2Data: ComparisonChartData;
}) => {
  return (
    <VictoryChart
      height={500}
      width={600}
      domainPadding={{ y: 20 }}
      padding={80}
      containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
    >
      <VictoryGroup offset={10}>
        <VictoryBar
          alignment="start"
          data={year1Data}
          style={{
            data: {
              fill: secondaryColor,
              strokeWidth: 2,
              transform: "translateX(5px)",
            },
            labels: { fill: secondaryColor },
          }}
          labels={({ datum }) =>
            `${datum.monthName} ${datum.year}: $${currencyFormatter.format(
              datum.y
            )}`
          }
          labelComponent={
            <VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white" }} />
          }
        />
        <VictoryBar
          alignment="start"
          data={year2Data}
          style={{
            data: {
              fill: primaryColor,
              strokeWidth: 2,
              transform: "translateX(5px)",
            },
            labels: { fill: primaryColor },
          }}
          labels={({ datum }) =>
            `${datum.monthName} ${datum.year}: $${currencyFormatter.format(
              datum.y
            )}`
          }
          labelComponent={
            <VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white" }} />
          }
        />
      </VictoryGroup>

      <VictoryAxis
        style={{
          tickLabels: {
            fontSize: 15,
            paddingTop: 20,
            angle: 30,
            textAnchor: "left",
            verticalAnchor: "start",
          },
          ticks: {
            stroke: "#000000",
            size: 8,
          }
        }}
      />
      <VictoryAxis dependentAxis />
    </VictoryChart>
  );
};
