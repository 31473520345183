
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useMemo } from "react";
import {
  CellProps,
  Column,
  Renderer,
  useExpanded,
  useFilters,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseGroupByColumnOptions,
  useSortBy,
  UseSortByColumnOptions,
  useTable,
} from "react-table";
import tw, { css } from "twin.macro";
import type * as GraphQLTypes from "../../codegen/graphql-types";
import { filterTypes } from "../../helpers/filterTypes";

const currencyFormatter = new Intl.NumberFormat("en-US", {});
const EMPTY_ARRAY: Array<void> = [];

export const ClientInvoiceTable = ({
  clientInvoices,
  refetch,
}: {
  clientInvoices: Array<
    Omit<GraphQLTypes.ClientInvoice, "estimate" | "estimate_id"> & {
      estimate: Pick<GraphQLTypes.Estimate, "__typename" | "id">;
    }
  >;
  refetch: () => unknown;
}) => {
  const postProcessedRows = clientInvoices;

  const columnDefinitions = useMemo(() => {
    if (!postProcessedRows) return [];
    type Row = typeof postProcessedRows[number];
    type DataColumn = UseSortByColumnOptions<Row> &
      UseFiltersColumnOptions<Row> &
      UseGroupByColumnOptions<Row> &
      Column<Row> & {
        Header2?: Renderer<UseFiltersColumnProps<Row>>;
        align?: "left" | "center" | "right";
      };
    const NONE = `(None)`;

    // const years = _.uniq(
    //   postProcessedRows.flatMap((row) => {
    //     if (!row.created_at) return [];
    //     return getYear(new Date(row.created_at));
    //   })
    // );

    const columns: Array<DataColumn> = [
      {
        id: "invoiceAmount",
        Header: (
          <Box w="full" textAlign="right">
            Invoice amount
          </Box>
        ),
        accessor: (x) => Number(x.amount_in_cents),
        Cell: ({ cell }: CellProps<Row>) =>
          <>{"$" + currencyFormatter.format(cell.value / 100)}</>,
      },

      {
        Header: "Invoice #",
        accessor: (x) => x.invoice_number,
        Cell: ({ cell }: CellProps<Row>) => <>{cell.value ?? NONE}</>,
      },

      {
        Header: "Date Invoiced",
        accessor: (x) => x.date_invoiced,
        Cell: ({ cell }: CellProps<Row>) => (
          <>
            {cell.value
              ? new Date(cell.value).toLocaleDateString("en-CA", {
                  timeZone: "UTC",
                })
              : NONE}
          </>
        ),
      },

      // {
      //   Header: "Date Deposit Expected",
      //   accessor: (x) => x.date_deposit_expected,
      //   Cell: ({ cell }: CellProps<Row>) => (
      //     <>
      //       {cell.value
      //         ? new Date(cell.value).toLocaleDateString("en-CA", {
      //             timeZone: "UTC",
      //           })
      //         : NONE}
      //     </>
      //   ),
      // },

      // {
      //   Header: "Deposit Date",
      //   accessor: (x) => x.date_deposited,
      //   Cell: ({ cell }: CellProps<Row>) => (
      //     <>
      //       {cell.value
      //         ? new Date(cell.value).toLocaleDateString("en-CA", {
      //             timeZone: "UTC",
      //           })
      //         : NONE}
      //     </>
      //   ),
      // },

      {
        Header: "Actions",
        accessor: (x) => x.date_deposited,
        Cell: ({ row }: CellProps<Row>) => (
          <DeleteInvoice
            clientInvoice={row.original}
            onFinishDelete={refetch}
          />
        ),
      },
    ];
    return columns.map((columnDefinition) => ({
      ...columnDefinition,
      ...("sortDescFirst" in columnDefinition ? null : { sortDescFirst: true }),
    }));
  }, [postProcessedRows, refetch]);

  const {
    // allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    // state: { expanded },
  } = useTable(
    {
      columns: columnDefinitions,
      data: postProcessedRows ?? EMPTY_ARRAY,
      defaultColumn: {
        // Let's set up our default Filter UI
        // @ts-expect-error
        Filter: () => null,
        sortType: "alphanumeric",
      },
      filterTypes,
      autoResetFilters: false,
      autoResetSortBy: false,
      // autoResetGroupBy: false,
      autoResetExpanded: false,
      autoResetSelectedRows: false,
      initialState: {
        // hiddenColumns: initiallyHiddenColumns,
      },
    },
    useFilters,
    // useGroupBy,
    useSortBy,
    useExpanded
  );

  return (
    <div css={tw`h-full`}>
      <table
        {...getTableProps()}
        css={[
          tw`text-sm w-full`,
          css`
            background-color: #fbfbfb;
            .row-hover-visible {
              opacity: 0;
            }
            & tr:hover .row-hover-visible {
              opacity: 1;
            }

            .cell-hover-visible {
              opacity: 0;
            }
            & td:hover .cell-hover-visible {
              opacity: 1;
            }
          `,
        ]}
      >
        <thead
          css={css`
            &,
            & > tr > th {
              background-color: #f0f0f0;
              border-right: solid 1px #ddd;
              border-left: solid 1px #ddd;
            }
            border-top: solid 1px #ddd;
          `}
        >
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    css={css`
                      padding: 0.5rem;
                      top: 0;
                      ${!column.placeholderOf &&
                      css`
                        border-bottom: solid 1px #ddd;
                      `}
                      ${headerGroups.length === 1 ||
                      headerGroups.length - 2 < headerGroupIndex
                        ? css`
                            position: sticky;
                          `
                        : ""}
                      border-left: solid 1px #ddd;
                      border-right: solid 1px #ddd;
                      box-sizing: border-box;

                      color: black;
                      font-weight: 500;
                    `}
                    // @ts-expect-error
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div style={{ display: "flex" }}>
                      <>
                        {column.render("Header")}

                        {headerGroups.length === 1 ||
                          (headerGroups.length - 2 < headerGroupIndex && (
                            <span>
                              {
                                // @ts-expect-error
                                column.isSorted
                                  ? // @ts-expect-error
                                    column.isSortedDesc
                                    ? " 🔽"
                                    : " 🔼"
                                  : " ↕️"
                              }
                            </span>
                          ))}
                      </>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody
          {...getTableBodyProps()}
          css={css`
            & > tr {
              content-visibility: auto;
              contain-intrinsic-size: 1000px;
              & > td {
                border: solid 1px #ddd;
                padding: 0.5rem;
              }
            }
          `}
        >
          {rows.length === 0 && (
            <tr>
              <td colSpan={visibleColumns.length} align="center">
                no invoices
              </td>
            </tr>
          )}
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        textAlign:
                          typeof cell.value === "number" ||
                          (typeof cell.value === "string" &&
                            cell.value.startsWith("$"))
                            ? "right"
                            : "left",
                      }}
                    >
                      <>{cell.render("Cell")}</>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const DeleteInvoice = ({
  clientInvoice,
  onFinishDelete,
}: {
  clientInvoice: Pick<
    GraphQLTypes.ClientInvoice,
    "id" | "amount_in_cents" | "invoice_number"
  > & {
    estimate: Pick<GraphQLTypes.Estimate, "__typename" | "id">;
  };
  onFinishDelete: () => void;
}) => {
  const [deleteOneClientInvoice] = useMutation<
    GraphQLTypes.DeleteClientInvoiceMutation,
    GraphQLTypes.DeleteClientInvoiceMutationVariables
  >(gql`
    mutation DeleteClientInvoice(
      $deleteOneClientInvoiceWhere: client_invoiceWhereUniqueInput!
    ) {
      deleteOneClientInvoice(where: $deleteOneClientInvoiceWhere) {
        id
      }
    }
  `);

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button size="sm">Delete</Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <FormControl>
                <FormLabel>
                  Confirm deletion of invoice {clientInvoice.invoice_number} for
                  ${clientInvoice.amount_in_cents / 100}
                </FormLabel>
              </FormControl>
              <Button
                type="submit"
                mt="2"
                size="sm"
                colorScheme="red"
                onClick={async () => {
                  await deleteOneClientInvoice({
                    variables: {
                      deleteOneClientInvoiceWhere: {
                        id: clientInvoice.id,
                      },
                    },
                    update(cache) {
                      cache.evict({
                        id: cache.identify({
                          __typename: clientInvoice.estimate.__typename,
                          id: clientInvoice.estimate.id,
                        }),
                        fieldName: "po_subtotal_remaining",
                      });
                      cache.evict({
                        id: cache.identify({
                          __typename: clientInvoice.estimate.__typename,
                          id: clientInvoice.estimate.id,
                        }),
                        fieldName: "client_invoices",
                      });
                    },
                  });
                  onFinishDelete();
                  onClose();
                }}
              >
                DELETE
              </Button>
            </PopoverBody>
            <PopoverCloseButton onClick={onClose} />
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
