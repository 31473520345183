import { PersonAnnualTimeTarget } from "../codegen/graphql-types";
import { sumEntryMinutes, daysInDateRange, DaysInRange } from "../Time/dates";
import { FlexibleDayOff, FlexibleTimeEntry } from "../Time/Employee/TimeSummaryRow";

const minutesToHours = (minutes: number): number => {
  return Number((minutes / 60).toFixed(1));
}

const hoursToMinutes = (hours: number): number => {
  return Math.floor(hours * 60);
}

// Function to convert time target minutes to hours and format the result
const getTargetPerDay = (target: PersonAnnualTimeTarget | undefined, key: keyof PersonAnnualTimeTarget): number => {
  if (!target) return 0;
  const minutes = target[key];
  return typeof minutes === 'number' && minutes > 0 ? minutesToHours(minutes) : 0;
}

// Sum all targets to get the total target number of hours per day 
// Have to check if the target is defined and only add target property to total if it is a number
const getTargetTotal = (target: PersonAnnualTimeTarget | undefined): number => {
  if (!target) return 0;
  let total = 0;
  const billableMinutes = target.billableMinutes;
  const salesAndMarketingMinutes = target.salesAndMarketingMinutes;
  const innovationAndDevMinutes = target.innovationAndDevMinutes;
  const operationalMinutes = target.operationalMinutes;
  const professionalDevMinutes = target.professionalDevMinutes;
  const nonBillableMinutes = target.nonBillableMinutes;
  const targets = [billableMinutes, salesAndMarketingMinutes, innovationAndDevMinutes, operationalMinutes, professionalDevMinutes, nonBillableMinutes];
  targets.forEach((target) => {
    if (typeof target === 'number') {
      total += target;
    }
  })
  return total ? minutesToHours(total) : 0;
}

const getAverageHoursPerDay = (
  timeEntries: FlexibleTimeEntry[], 
  startDate: Date, 
  endDate: Date,
  daysOff: FlexibleDayOff[]
): number => {
  // get the total number of minutes for the date range
  const totalMinutes: number = timeEntries.reduce(sumEntryMinutes, 0);

  // get summary of days in the date range
  const days: DaysInRange = daysInDateRange(startDate, endDate, daysOff);
  
  // get the number of working days in the date range
  const numOfWorkingDaysForDateRange: number = Math.max(days.numberOfWorkingDaysInRange, 0);

  // get the average number of minutes/day
  const averageMinutesPerDay: number = totalMinutes / numOfWorkingDaysForDateRange;

  // get the total number of hours/day 
  const totalHoursPerDay: number = minutesToHours(averageMinutesPerDay);

  return totalHoursPerDay;
}

export { 
  minutesToHours, 
  hoursToMinutes, 
  getTargetPerDay, 
  getTargetTotal,
  getAverageHoursPerDay
}