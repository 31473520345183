import _ from "lodash";
import { useMemo } from "react";
import { usePeopleContext } from "./usePeopleContext";
import { 
  GetProjectsForBrandAnalysisQueryResult,
  GetProjectsForBrandAnalysisQuery,
} from "../codegen/graphql-types";

/**
 * Custom hook that processes the project rows from the query result.
 * @param result The query result containing the projects data.
 * @returns An array of processed project rows.
 */
const useProjectRows = (result: GetProjectsForBrandAnalysisQueryResult) => {
  const peopleData = usePeopleContext();
  const data: GetProjectsForBrandAnalysisQuery | undefined = result.data;
  const projects = data?.projects ?? [];

  // Add additional calculated fields to project rows from the query above
  return useMemo(() => {
    return projects?.map((project) => {
      const projectTimeEntries = project.time_entries;

      const timeEntriesToUse = projectTimeEntries.flatMap((entry) => {
        const person = peopleData.peopleByPersonId[entry.user_id];
        if (person?.company !== "Maven Medical Marketing Inc.") {
          return [];
        } else {
          return [
            {
              ...entry,
              person,
              rateInCents:
                project.rates.find(
                  (x) => x.person_id.toString() === person.id.toString()
                )?.rate_in_cents ?? 0,
            },
          ];
        }
      });

      const cogs =
        (_.sumBy(project.invoices, (x) => x.amount_in_cents) ?? 0) / 100;

      const subtotalByTimeEntries = Math.round(
        timeEntriesToUse.reduce((sum, entry) => {
          const rate = entry.rateInCents / 100;
          if (rate === undefined || rate === null) {
            return sum;
            // throw new Error(
            //   `Could not find rate for ${person.id} ${person.first_name} ${person.last_name} with title of ${person.title}`
            // );
          }
          return sum + (entry.minutes / 60) * rate;
        }, 0)
      );

      // Calculated as project budget minus actual cogs
      const netIncome = (project.budget ?? 0) - cogs;

      // Tracking VS Budget; Calculated as Maven NET INCOME - ACTUAL subtotal
      const netIncomeMinusSubtotal =
        (project.budget ?? 0) - cogs - subtotalByTimeEntries;

      const totalHoursOnProject =
        _.sumBy(timeEntriesToUse, (entry) => entry.minutes) / 60;

      const blendedRateBasedOnNetIncome = totalHoursOnProject
        ? netIncome / totalHoursOnProject
        : 0;


      const relativeProfitability = subtotalByTimeEntries
        ? netIncome / subtotalByTimeEntries
        : 0;

      // Determine project netSalesSplit  
      let netSalesSplit = 0;
      if (
        peopleData.data?.me?.pods
          .map((pod: any) => pod.senior_brand_maven_lead)
          .includes(project.senior_brand_maven_lead)
      ) {
        // user belongs to pod that owns this project
        netSalesSplit = Math.round(
          (netIncome * (100 - project.sales_split_ratio)) / 100
        );
      } else if (
        project.sales_split_pod &&
        peopleData.data?.me?.pods
          .map((pod: any) => pod.id)
          .includes(project.sales_split_pod.id)
      ) {
        // user belongs to pod that is splitting this project
        netSalesSplit = Math.round((netIncome * project.sales_split_ratio) / 100);
      } else {
        netSalesSplit = 0;
      }

      return {
        ...project,
        cogs,
        subtotalByTimeEntries,
        blendedRateBasedOnNetIncome,
        netIncome,
        netSalesSplit,
        netIncomeMinusSubtotal,
        relativeProfitability,
      };
    });
  }, [peopleData.peopleByPersonId, projects]);
};

// use the inferred type from the useProjectRows hook
export type ProjectRow = ReturnType<typeof useProjectRows>[0];

export { useProjectRows };