import tw from "twin.macro";
import { Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { INVOICE_PORTAL_URLS } from "../constants";
// types
import * as QBO from "../../../../../server/lib/quickbooks/types";
import type { QuickbooksResources } from "../GetQuickbooksResources";

const InvoiceWarnings = (props: {
  qbResources: QuickbooksResources;
  customer: QBO.Customer | undefined;
  qbInvoice: QBO.Invoice | undefined;
  defaultTaxCode: QBO.TaxCode | undefined;
  customerDisplayName: string;
  qbInvoiceIsVoided: boolean;
  resetInvoice: () => void;
  showInstructions: boolean;
  invoiceNumberWithDash: string;
  validateInvoice: () => void;
  isLoading: boolean;
  warning: boolean;
  dashboardHasQuickbooksId: boolean;
  companyName: string;
}) => {

  const isCreateInvoiceDisabled = props.isLoading || props.warning || !props.defaultTaxCode;

  return (
    <>
      {/* Warning for missing customer tax code */}
      {props.qbResources.Item &&
        props.qbResources.TaxRate &&
        props.qbResources.TaxCode &&
        props.customer &&
        !props.defaultTaxCode && (
          <WarningBox>
            <p css={tw`text-red-500`}>
              No default tax code found for this customer: {props.customerDisplayName}
              . Please set one in Quickbooks.
            </p>
          </WarningBox>
        )}

      {/* Warning for VOIDED invoice */}
      {props.qbInvoiceIsVoided && (
        <WarningBox>
          <strong css={tw`text-red-500`}>
            This invoice has been voided in Quickbooks. Quickbooks set the
            balance to $0.00
            <br />
            Do you want to create another quickbooks invoice with the original
            Invoice Number and Amount from the dashboard?
          </strong>
          <Button
            width="max-content"
            size="sm"
            onClick={() => props.resetInvoice()}
          >
            Yes
          </Button>
          {props.showInstructions && (
            <div css={tw`flex flex-col gap-y-3`}>
              <p>Please do the following:</p>
              <ol css={tw`text-red-500 font-bold list-disc pl-10`}>
                <li>
                  Go to Quickbooks and Edit the corresponding Void invoice.
                </li>
                <li>
                  Update the Invoice number to "{props.invoiceNumberWithDash}
                  -VOID"
                </li>
                <li>Save the invoice in Quickbooks.</li>
                <li>
                  Verify all details, then click the "Create Invoice" button
                  below.
                </li>
              </ol>
              <CreateInvoiceButton
                onClick={props.validateInvoice}
                isLoading={props.isLoading}
                warning={props.warning}
                disabled={isCreateInvoiceDisabled}
                invoiceNumberWithDash={props.invoiceNumberWithDash}
              />
            </div>
          )}
        </WarningBox>
      )}

      {/* Warning for dashboardHasQuickbooksId but there is no qbInvoice (Deleted) */}
      {props.dashboardHasQuickbooksId && !props.qbInvoice && (
        <WarningBox>
          <strong css={tw`text-red-500`}>
            The dashboard has a record of this invoice being created in
            quickbooks. However, the invoice could not be found with the current
            Quickbooks data. This might mean that the invoice was deleted in
            Quickbooks.
            <br />
            <br />
            Do you want to create another quickbooks invoice with the original
            Invoice Number and Amount from the dashboard?
          </strong>
          <Button
            width="max-content"
            size="sm"
            onClick={() => props.resetInvoice()}
          >
            Yes
          </Button>
          {props.showInstructions && (
            <div css={tw`flex flex-col gap-y-3`}>
              <p>Please do the following:</p>
              <ol css={tw`text-red-500 font-bold list-disc pl-10`}>
                <li>
                  Copy the description from the Estimate PDF (View Estimate PDF)
                  and paste into the Description field (paste and match
                  formatting)
                </li>
                <li>
                  Verify all details, then click the "Create Invoice" button
                  below.
                </li>
              </ol>
              <CreateInvoiceButton
                onClick={props.validateInvoice}
                isLoading={props.isLoading}
                warning={props.warning}
                disabled={isCreateInvoiceDisabled}
                invoiceNumberWithDash={props.invoiceNumberWithDash}
              />
            </div>
          )}
        </WarningBox>
      )}

      {/* Warning if invoice is to be uploaded via customer portal */}
      {INVOICE_PORTAL_URLS[props.companyName] && props.qbInvoice && !props.qbInvoiceIsVoided && (
        <WarningBox>
          <strong css={tw`text-red-500`}>
            This invoice is sent to the customer via the company portal.
            <br /> You will not be able to send this invoice via email from the
            dashboard.
          </strong>
          <Link
            to={INVOICE_PORTAL_URLS[props.companyName]}
            target="_blank"
            rel="noreferrer"
            css={tw`text-blue-500 underline`}
          >
            Go to {props.companyName} Portal
          </Link>
        </WarningBox>
      )}
    </>
  );
};

export default InvoiceWarnings;


export const WarningBox = ({ children }: {
  children: React.ReactNode;
}) => {
  return (
    <Box
      bg="blue.100"
      p={4}
      borderRadius="md"
      css={tw`w-1/2 flex flex-col gap-y-3 shadow-md`}
    >
      {children}
    </Box>
  );
};

const CreateInvoiceButton = (props: {
  onClick: () => void;
  isLoading: boolean;
  warning: boolean;
  disabled: boolean;
  invoiceNumberWithDash: string;
}) => {
  return (
    <Button
      onClick={props.onClick}
      isLoading={props.isLoading}
      loadingText="Creating invoice..."
      colorScheme="blue"
      css={tw`mt-4`}
      width="max-content"
      isDisabled={props.disabled}
    >
      Create New Invoice for {props.invoiceNumberWithDash}
    </Button>
  );
}