import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  Json: any;
  DateTime: any;
  Decimal: any;
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of records affected by the batch operation. */
  count: Scalars['Int'];
};

export type SyncStatus = {
  __typename?: 'SyncStatus';
  id: Scalars['String'];
  ingest_from_teamwork_status?: Maybe<Scalars['Json']>;
  created_at: Scalars['DateTime'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  owner?: Maybe<Person>;
  owner_id?: Maybe<Scalars['ID']>;
  client: Client;
  client_brand_manager?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  is_reviewed: Scalars['Boolean'];
  is_archived: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  project_type?: Maybe<Project_Type>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  language?: Maybe<Project_Language>;
  internal_review?: Maybe<Scalars['Boolean']>;
  external_reviewer?: Maybe<External_Reviewer>;
  last_synced_overview_at: Scalars['DateTime'];
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  project_status?: Maybe<Scalars['String']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  project_pricing?: Maybe<ProjectPricing>;
  associated_estimate?: Maybe<Estimate>;
  sales_split_pod?: Maybe<Pod>;
  sales_split_ratio: Scalars['Int'];
  time_entries: Array<TimeEntry>;
  invoices: Array<Invoice>;
  cogs_in_cents?: Maybe<Scalars['Int']>;
  rates: Array<ProjectRate>;
};


export type ProjectInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<InvoiceWhereUniqueInput>;
  after?: Maybe<InvoiceWhereUniqueInput>;
};

export type ProjectRate = {
  __typename?: 'ProjectRate';
  id: Scalars['ID'];
  rate_in_cents: Scalars['Int'];
  project: Project;
  project_id: Scalars['ID'];
  person: Person;
  person_id: Scalars['ID'];
  updated_at: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type DayOff = {
  __typename?: 'DayOff';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  reason: Scalars['String'];
  personId: Scalars['String'];
  person: Person;
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['String'];
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  is_deleted_on_tw: Scalars['Boolean'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  rate: Scalars['Int'];
  company: Scalars['String'];
  estimates: Array<Estimate>;
  pod: Array<Pod>;
  roleId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
  timeEntries: Array<TimeEntry>;
  annual_time_targets: Array<PersonAnnualTimeTarget>;
  daysOff: Array<DayOff>;
  pods: Array<Pod>;
  role?: Maybe<Role>;
  team?: Maybe<Team>;
};


export type PersonEstimatesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<EstimateWhereUniqueInput>;
  after?: Maybe<EstimateWhereUniqueInput>;
};


export type PersonPodArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PodWhereUniqueInput>;
  after?: Maybe<PodWhereUniqueInput>;
};


export type PersonTimeEntriesArgs = {
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
};


export type PersonAnnual_Time_TargetsArgs = {
  year: Scalars['Int'];
};


export type PersonDaysOffArgs = {
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
};

export type Pod = {
  __typename?: 'Pod';
  id: Scalars['String'];
  name: Scalars['String'];
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  members: Array<Person>;
  annual_revenue_target: PodAnnualRevenueTarget;
  projects: Array<Project>;
};


export type PodMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PersonWhereUniqueInput>;
  after?: Maybe<PersonWhereUniqueInput>;
};


export type PodAnnual_Revenue_TargetArgs = {
  year: Scalars['Int'];
};


export type PodProjectsArgs = {
  year: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['String'];
  roleTitle: Scalars['String'];
  persons: Array<Person>;
};


export type RolePersonsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PersonWhereUniqueInput>;
  after?: Maybe<PersonWhereUniqueInput>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['String'];
  teamName: Scalars['String'];
  persons: Array<Person>;
};


export type TeamPersonsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PersonWhereUniqueInput>;
  after?: Maybe<PersonWhereUniqueInput>;
};

export type ProjectedTime = {
  __typename?: 'ProjectedTime';
  id: Scalars['Int'];
  tasklist_id: Scalars['String'];
  role_id: Scalars['String'];
  team_id: Scalars['String'];
  project_id: Scalars['String'];
  project_pricing_id: Scalars['Int'];
  project_pricing: ProjectPricing;
  projected_minutes: Scalars['Int'];
};

export type ProjectedExpense = {
  __typename?: 'ProjectedExpense';
  id: Scalars['Int'];
  project_id: Scalars['String'];
  project_pricing_id: Scalars['Int'];
  budget_in_cents: Scalars['Int'];
  expense_type: Scalars['String'];
  payee_name: Scalars['String'];
  project_pricing: ProjectPricing;
};

export type ProjectPricing = {
  __typename?: 'ProjectPricing';
  id: Scalars['Int'];
  project_id: Scalars['String'];
  project: Project;
  price: Scalars['Int'];
  actual_cost: Scalars['Int'];
  currency: Scalars['String'];
  projected_times: Array<ProjectedTime>;
  projected_expenses: Array<ProjectedExpense>;
  comments: Array<PricingComment>;
};


export type ProjectPricingProjected_TimesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ProjectedTimeWhereUniqueInput>;
  after?: Maybe<ProjectedTimeWhereUniqueInput>;
};


export type ProjectPricingProjected_ExpensesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ProjectedExpenseWhereUniqueInput>;
  after?: Maybe<ProjectedExpenseWhereUniqueInput>;
};


export type ProjectPricingCommentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<PricingCommentWhereUniqueInput>;
  after?: Maybe<PricingCommentWhereUniqueInput>;
};

export type PricingComment = {
  __typename?: 'PricingComment';
  id: Scalars['Int'];
  project_id: Scalars['String'];
  project_pricing_id: Scalars['Int'];
  tasklist_id: Scalars['String'];
  comment: Scalars['String'];
  created_at: Scalars['DateTime'];
  created_by_id: Scalars['String'];
  is_for_cogs: Scalars['Boolean'];
  created_by: Person;
  project_pricing: ProjectPricing;
};

export type PersonAnnualTimeTarget = {
  __typename?: 'PersonAnnualTimeTarget';
  id: Scalars['Int'];
  year: Scalars['Int'];
  billableMinutes: Scalars['Int'];
  nonBillableMinutes: Scalars['Int'];
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
  personId: Scalars['Int'];
};

export type ClientAnnualRevenueForecast = {
  __typename?: 'ClientAnnualRevenueForecast';
  id: Scalars['String'];
  year: Scalars['Int'];
  /** Low forecast in dollars */
  lowForecastDollars: Scalars['Int'];
  /** High forecast in dollars */
  highForecastDollars: Scalars['Int'];
  /** The ID of the client */
  clientId: Scalars['String'];
  /** The ID of the pod */
  podId: Scalars['String'];
  /** The ID of the user who last updated this record */
  updatedByUserId: Scalars['String'];
  /** When the forecast was last updated */
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyAnnualRevenueTarget = {
  __typename?: 'CompanyAnnualRevenueTarget';
  year: Scalars['Int'];
  revenue_target_dollars?: Maybe<Scalars['Int']>;
  net_sales_potential_cents: Scalars['Int'];
  net_sales_earned_cents: Scalars['Int'];
};

export type PodAnnualRevenueTarget = {
  __typename?: 'PodAnnualRevenueTarget';
  year: Scalars['Int'];
  pod: Pod;
  podId: Scalars['String'];
  revenue_target_dollars?: Maybe<Scalars['Int']>;
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  invoice_number: Scalars['String'];
  contractor?: Maybe<Person>;
  date_created: Scalars['DateTime'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  files: Array<SupplierInvoiceFile>;
};


export type InvoiceFilesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Supplier_Invoice_FilesWhereUniqueInput>;
  after?: Maybe<Supplier_Invoice_FilesWhereUniqueInput>;
};

export type SupplierInvoiceFile = {
  __typename?: 'SupplierInvoiceFile';
  id: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  createdBy: Person;
  invoice: Invoice;
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
  project: Project;
  project_tag_id?: Maybe<Scalars['String']>;
  tasklist_id?: Maybe<Scalars['Int']>;
  project_id: Scalars['String'];
  company_id: Scalars['String'];
  user_id: Scalars['String'];
  person: Person;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['String'];
  brand_name: Scalars['String'];
  company: Company;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['String'];
  company_name: Scalars['String'];
  brands: Array<Brand>;
};


export type CompanyBrandsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<BrandWhereUniqueInput>;
  after?: Maybe<BrandWhereUniqueInput>;
};

/** Invoice that are sent to clients */
export type ClientInvoice = {
  __typename?: 'ClientInvoice';
  id: Scalars['String'];
  amount_in_cents: Scalars['Int'];
  invoice_number?: Maybe<Scalars['String']>;
  quickbooks_id?: Maybe<Scalars['String']>;
  date_invoiced?: Maybe<Scalars['DateTime']>;
  date_deposit_expected?: Maybe<Scalars['DateTime']>;
  date_deposited?: Maybe<Scalars['DateTime']>;
  estimate_id: Scalars['String'];
  estimate: Estimate;
  created_at: Scalars['DateTime'];
  invoice_status?: Maybe<InvoiceStatus>;
};

export type Estimate = {
  __typename?: 'Estimate';
  id: Scalars['String'];
  name: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  company_id: Scalars['String'];
  company: Company;
  brand_id: Scalars['String'];
  brand: Brand;
  client_invoices: Array<ClientInvoice>;
  date: Scalars['DateTime'];
  owner: Person;
  po_number?: Maybe<Scalars['String']>;
  po_subtotal: Scalars['Decimal'];
  pdf_file_key?: Maybe<Scalars['String']>;
  projects: Array<Project>;
  cogs?: Maybe<Scalars['Decimal']>;
  budget_unaccounted_for?: Maybe<Scalars['Decimal']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  client_invoice_count?: Maybe<Scalars['Int']>;
  po_subtotal_remaining?: Maybe<Scalars['Decimal']>;
  estimate_status?: Maybe<EstimateStatus>;
};


export type EstimateClient_InvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Client_InvoiceWhereUniqueInput>;
  after?: Maybe<Client_InvoiceWhereUniqueInput>;
};


export type EstimateProjectsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ProjectWhereUniqueInput>;
  after?: Maybe<ProjectWhereUniqueInput>;
};

export enum EstimateStatus {
  /**
   * display when: 
   *         “PO” field blank
   */
  NoPo = 'NO_PO',
  /**
   * No invoices exist yet OR
   *         If invoice exists, Invoice date not blank
   */
  Ready = 'READY',
  /**
   * Invoice exists
   *         Invoice date is blank
   */
  InvoiceSubmittable = 'INVOICE_SUBMITTABLE',
  /**
   * Subtotal remaining on PO = 0
   *         And at least one invoice deposit date = blank
   */
  FullyInvoiced = 'FULLY_INVOICED',
  /**
   * Subtotal remaining on PO = 0
   *         All invoice deposit dates are not blank
   */
  Complete = 'COMPLETE'
}

export type GrossProfitReport = {
  __typename?: 'GrossProfitReport';
  id: Scalars['ID'];
  entries: Array<GrossProfitReportEstimateEntry>;
};

export type GrossProfitReportEstimateEntry = {
  __typename?: 'GrossProfitReportEstimateEntry';
  estimate_id: Scalars['String'];
  estimate_name: Scalars['String'];
  estimate_date: Scalars['String'];
  owner_id: Scalars['ID'];
  estimate_po_subtotal: Scalars['Float'];
  invoiced_amount: Scalars['Float'];
  expense_amount: Scalars['Float'];
};

export type PodRevenueForecastReport = {
  __typename?: 'PodRevenueForecastReport';
  id: Scalars['ID'];
  entries: Array<PodRevenueForecastReportEntry>;
};

export type PodRevenueForecastReportEntry = {
  __typename?: 'PodRevenueForecastReportEntry';
  project_id: Scalars['String'];
  project_name: Scalars['String'];
  start_date: Scalars['String'];
  end_date: Scalars['String'];
  project_status: Scalars['String'];
  project_owner_id: Scalars['ID'];
  budget_in_cents: Scalars['Int'];
  cogs_in_cents: Scalars['Int'];
  net_sales_in_cents: Scalars['Int'];
};

export type InvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  contractor?: Maybe<PersonCreateNestedOneWithoutInvoicesInput>;
  project?: Maybe<ProjectCreateNestedOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type InvoiceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoice_number?: Maybe<StringFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  date_created?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  payee_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contractor?: Maybe<PersonUpdateOneWithoutInvoicesInput>;
  project?: Maybe<ProjectUpdateOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutInvoiceInput>;
};

export type BrandCreateInput = {
  id?: Maybe<Scalars['String']>;
  brand_name: Scalars['String'];
  company: CompanyCreateNestedOneWithoutBrandsInput;
  estimates?: Maybe<EstimateCreateNestedManyWithoutBrandInput>;
};

export type BrandWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BrandUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  brand_name?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutBrandsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutBrandInput>;
};

export type CompanyCreateInput = {
  id?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
  brands?: Maybe<BrandCreateNestedManyWithoutCompanyInput>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutCompanyInput>;
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CompanyUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  brands?: Maybe<BrandUpdateManyWithoutCompanyInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutCompanyInput>;
};

export type Client_InvoiceCreateInput = {
  id?: Maybe<Scalars['String']>;
  amount_in_cents?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  date_invoiced?: Maybe<Scalars['DateTime']>;
  date_deposit_expected?: Maybe<Scalars['DateTime']>;
  date_deposited?: Maybe<Scalars['DateTime']>;
  invoice_number?: Maybe<Scalars['String']>;
  quickbooks_id?: Maybe<Scalars['String']>;
  estimate: EstimateCreateNestedOneWithoutClient_InvoicesInput;
  invoice_status?: Maybe<InvoiceStatus>;
};

export type Client_InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Client_InvoiceUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_invoiced?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposit_expected?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposited?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoice_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quickbooks_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  estimate?: Maybe<EstimateUpdateOneRequiredWithoutClient_InvoicesInput>;
  invoice_status?: Maybe<NullableEnumInvoiceStatusFieldUpdateOperationsInput>;
};

export type EstimateCreateInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  brand: BrandCreateNestedOneWithoutEstimatesInput;
  company: CompanyCreateNestedOneWithoutEstimatesInput;
  owner: PersonCreateNestedOneWithoutEstimatesInput;
  client_invoices?: Maybe<Client_InvoiceCreateNestedManyWithoutEstimateInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutAssociated_EstimateInput>;
};

export type EstimateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EstimateUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutEstimatesInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutEstimatesInput>;
  owner?: Maybe<PersonUpdateOneRequiredWithoutEstimatesInput>;
  client_invoices?: Maybe<Client_InvoiceUpdateManyWithoutEstimateInput>;
  projects?: Maybe<ProjectUpdateManyWithoutAssociated_EstimateInput>;
};

export type ProjectCreateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProjectUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PodCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetCreateNestedManyWithoutPodInput>;
  members?: Maybe<PersonCreateNestedManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectCreateNestedManyWithoutSales_Split_PodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastCreateNestedManyWithoutPodInput>;
};

export type PodWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PodUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetUpdateManyWithoutPodInput>;
  members?: Maybe<PersonUpdateManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectUpdateManyWithoutSales_Split_PodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastUpdateManyWithoutPodInput>;
};

export type ProjectWhereInput = {
  AND?: Maybe<Array<ProjectWhereInput>>;
  OR?: Maybe<Array<ProjectWhereInput>>;
  NOT?: Maybe<Array<ProjectWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  project_owner_id?: Maybe<StringNullableFilter>;
  budget?: Maybe<IntNullableFilter>;
  client_id?: Maybe<StringFilter>;
  component_code?: Maybe<StringNullableFilter>;
  category_id?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  start_date?: Maybe<DateTimeNullableFilter>;
  end_date?: Maybe<DateTimeNullableFilter>;
  last_changed_at?: Maybe<DateTimeNullableFilter>;
  project_type?: Maybe<Enumproject_TypeNullableFilter>;
  language?: Maybe<Enumproject_LanguageNullableFilter>;
  external_reviewer?: Maybe<Enumexternal_ReviewerNullableFilter>;
  internal_review?: Maybe<BoolNullableFilter>;
  last_synced_overview_at?: Maybe<DateTimeFilter>;
  project_status?: Maybe<StringNullableFilter>;
  last_synced_details_at?: Maybe<DateTimeNullableFilter>;
  client_brand_manager?: Maybe<StringNullableFilter>;
  is_reviewed?: Maybe<BoolFilter>;
  is_archived?: Maybe<BoolFilter>;
  is_deleted?: Maybe<BoolFilter>;
  associated_estimate_id?: Maybe<StringNullableFilter>;
  project_notes?: Maybe<StringNullableFilter>;
  project_notes_2?: Maybe<StringNullableFilter>;
  senior_brand_maven_lead?: Maybe<StringNullableFilter>;
  associated_estimate?: Maybe<EstimateWhereInput>;
  category?: Maybe<CategoriesWhereInput>;
  client?: Maybe<ClientWhereInput>;
  owner?: Maybe<PersonWhereInput>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  time_entries?: Maybe<Time_EntriesListRelationFilter>;
  sales_split_pod_id?: Maybe<StringNullableFilter>;
  sales_split_pod?: Maybe<PodWhereInput>;
  sales_split_ratio?: Maybe<IntFilter>;
  project_rates?: Maybe<Project_RateListRelationFilter>;
  project_pricing?: Maybe<ProjectPricingWhereInput>;
  epoch?: Maybe<IntFilter>;
};

export type ProjectOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  project_owner_id?: Maybe<SortOrder>;
  budget?: Maybe<SortOrder>;
  client_id?: Maybe<SortOrder>;
  component_code?: Maybe<SortOrder>;
  category_id?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  start_date?: Maybe<SortOrder>;
  end_date?: Maybe<SortOrder>;
  last_changed_at?: Maybe<SortOrder>;
  project_type?: Maybe<SortOrder>;
  language?: Maybe<SortOrder>;
  external_reviewer?: Maybe<SortOrder>;
  internal_review?: Maybe<SortOrder>;
  last_synced_overview_at?: Maybe<SortOrder>;
  project_status?: Maybe<SortOrder>;
  last_synced_details_at?: Maybe<SortOrder>;
  client_brand_manager?: Maybe<SortOrder>;
  is_reviewed?: Maybe<SortOrder>;
  is_archived?: Maybe<SortOrder>;
  is_deleted?: Maybe<SortOrder>;
  associated_estimate_id?: Maybe<SortOrder>;
  project_notes?: Maybe<SortOrder>;
  project_notes_2?: Maybe<SortOrder>;
  senior_brand_maven_lead?: Maybe<SortOrder>;
  associated_estimate?: Maybe<EstimateOrderByWithRelationInput>;
  category?: Maybe<CategoriesOrderByWithRelationInput>;
  client?: Maybe<ClientOrderByWithRelationInput>;
  owner?: Maybe<PersonOrderByWithRelationInput>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  time_entries?: Maybe<Time_EntriesOrderByRelationAggregateInput>;
  sales_split_pod_id?: Maybe<SortOrder>;
  sales_split_pod?: Maybe<PodOrderByWithRelationInput>;
  sales_split_ratio?: Maybe<SortOrder>;
  project_rates?: Maybe<Project_RateOrderByRelationAggregateInput>;
  project_pricing?: Maybe<ProjectPricingOrderByWithRelationInput>;
  epoch?: Maybe<SortOrder>;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  invoice_number?: Maybe<StringFilter>;
  project_id?: Maybe<StringNullableFilter>;
  expense_type?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  date_created?: Maybe<DateTimeFilter>;
  amount_in_cents?: Maybe<IntFilter>;
  payee_name?: Maybe<StringNullableFilter>;
  contractor_id?: Maybe<StringNullableFilter>;
  contractor?: Maybe<PersonWhereInput>;
  project?: Maybe<ProjectWhereInput>;
  files?: Maybe<Supplier_Invoice_FilesListRelationFilter>;
};

export type BrandWhereInput = {
  AND?: Maybe<Array<BrandWhereInput>>;
  OR?: Maybe<Array<BrandWhereInput>>;
  NOT?: Maybe<Array<BrandWhereInput>>;
  id?: Maybe<StringFilter>;
  brand_name?: Maybe<StringFilter>;
  company_id?: Maybe<StringFilter>;
  company?: Maybe<CompanyWhereInput>;
  estimates?: Maybe<EstimateListRelationFilter>;
};

export type BrandOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  brand_name?: Maybe<SortOrder>;
  company_id?: Maybe<SortOrder>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  estimates?: Maybe<EstimateOrderByRelationAggregateInput>;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  id?: Maybe<StringFilter>;
  company_name?: Maybe<StringFilter>;
  brands?: Maybe<BrandListRelationFilter>;
  estimates?: Maybe<EstimateListRelationFilter>;
};

export type CompanyOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  company_name?: Maybe<SortOrder>;
  brands?: Maybe<BrandOrderByRelationAggregateInput>;
  estimates?: Maybe<EstimateOrderByRelationAggregateInput>;
};

export type Client_InvoiceWhereInput = {
  AND?: Maybe<Array<Client_InvoiceWhereInput>>;
  OR?: Maybe<Array<Client_InvoiceWhereInput>>;
  NOT?: Maybe<Array<Client_InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  amount_in_cents?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_invoiced?: Maybe<DateTimeNullableFilter>;
  date_deposit_expected?: Maybe<DateTimeNullableFilter>;
  date_deposited?: Maybe<DateTimeNullableFilter>;
  invoice_number?: Maybe<StringNullableFilter>;
  quickbooks_id?: Maybe<StringNullableFilter>;
  estimate_id?: Maybe<StringFilter>;
  estimate?: Maybe<EstimateWhereInput>;
  invoice_status?: Maybe<EnumInvoiceStatusNullableFilter>;
};

export type Client_InvoiceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  amount_in_cents?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  date_invoiced?: Maybe<SortOrder>;
  date_deposit_expected?: Maybe<SortOrder>;
  date_deposited?: Maybe<SortOrder>;
  invoice_number?: Maybe<SortOrder>;
  quickbooks_id?: Maybe<SortOrder>;
  estimate_id?: Maybe<SortOrder>;
  estimate?: Maybe<EstimateOrderByWithRelationInput>;
  invoice_status?: Maybe<SortOrder>;
};

export type EstimateWhereInput = {
  AND?: Maybe<Array<EstimateWhereInput>>;
  OR?: Maybe<Array<EstimateWhereInput>>;
  NOT?: Maybe<Array<EstimateWhereInput>>;
  id?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  owner_id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  po_number?: Maybe<StringNullableFilter>;
  company_id?: Maybe<StringFilter>;
  brand_id?: Maybe<StringFilter>;
  po_subtotal?: Maybe<DecimalFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  pdf_file_key?: Maybe<StringNullableFilter>;
  brand?: Maybe<BrandWhereInput>;
  company?: Maybe<CompanyWhereInput>;
  owner?: Maybe<PersonWhereInput>;
  client_invoices?: Maybe<Client_InvoiceListRelationFilter>;
  projects?: Maybe<ProjectListRelationFilter>;
};

export type EstimateOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  owner_id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  po_number?: Maybe<SortOrder>;
  company_id?: Maybe<SortOrder>;
  brand_id?: Maybe<SortOrder>;
  po_subtotal?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  updated_at?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  pdf_file_key?: Maybe<SortOrder>;
  brand?: Maybe<BrandOrderByWithRelationInput>;
  company?: Maybe<CompanyOrderByWithRelationInput>;
  owner?: Maybe<PersonOrderByWithRelationInput>;
  client_invoices?: Maybe<Client_InvoiceOrderByRelationAggregateInput>;
  projects?: Maybe<ProjectOrderByRelationAggregateInput>;
};


export type CompanyAnnualRevenueTargetWhereInput = {
  AND?: Maybe<Array<CompanyAnnualRevenueTargetWhereInput>>;
  OR?: Maybe<Array<CompanyAnnualRevenueTargetWhereInput>>;
  NOT?: Maybe<Array<CompanyAnnualRevenueTargetWhereInput>>;
  year?: Maybe<IntFilter>;
  revenue_target_dollars?: Maybe<IntNullableFilter>;
};

export type CompanyAnnualRevenueTargetOrderByWithRelationInput = {
  year?: Maybe<SortOrder>;
  revenue_target_dollars?: Maybe<SortOrder>;
};

export type PodAnnualRevenueTargetWhereInput = {
  AND?: Maybe<Array<PodAnnualRevenueTargetWhereInput>>;
  OR?: Maybe<Array<PodAnnualRevenueTargetWhereInput>>;
  NOT?: Maybe<Array<PodAnnualRevenueTargetWhereInput>>;
  year?: Maybe<IntFilter>;
  revenue_target_dollars?: Maybe<IntNullableFilter>;
  podId?: Maybe<StringFilter>;
  pod?: Maybe<PodWhereInput>;
};

export type PodAnnualRevenueTargetOrderByWithRelationInput = {
  year?: Maybe<SortOrder>;
  revenue_target_dollars?: Maybe<SortOrder>;
  podId?: Maybe<SortOrder>;
  pod?: Maybe<PodOrderByWithRelationInput>;
};



export enum Project_Type {
  Strategic = 'strategic',
  Tactical = 'tactical',
  PaabExempt = 'paab_exempt',
  Internal = 'internal'
}

export enum Project_Language {
  En = 'en',
  Fr = 'fr',
  EnFr = 'en_fr'
}

export enum External_Reviewer {
  Paab = 'paab',
  Asc = 'asc',
  None = 'none'
}

export type PersonWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ProjectedTimeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  tasklist_id_role_id_team_id_project_pricing_id?: Maybe<ProjectedTimeTasklist_IdRole_IdTeam_IdProject_Pricing_IdCompoundUniqueInput>;
};

export type ProjectedExpenseWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  project_id_expense_type_payee_name?: Maybe<ProjectedExpenseProject_IdExpense_TypePayee_NameCompoundUniqueInput>;
};

export type PricingCommentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type Supplier_Invoice_FilesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  CreateInvoice = 'CREATE_INVOICE',
  GoToInvoice = 'GO_TO_INVOICE',
  GoToPortal = 'GO_TO_PORTAL',
  ReviewAgingTerms = 'REVIEW_AGING_TERMS',
  Invoiced = 'INVOICED'
}


export type PersonCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<PersonCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type ProjectCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<ProjectCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
};

export type Supplier_Invoice_FilesCreateNestedManyWithoutInvoiceInput = {
  create?: Maybe<Array<Supplier_Invoice_FilesCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<Supplier_Invoice_FilesCreateOrConnectWithoutInvoiceInput>>;
  createMany?: Maybe<Supplier_Invoice_FilesCreateManyInvoiceInputEnvelope>;
  connect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type PersonUpdateOneWithoutInvoicesInput = {
  create?: Maybe<PersonCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<PersonUpsertWithoutInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutInvoicesInput>;
};

export type ProjectUpdateOneWithoutInvoicesInput = {
  create?: Maybe<ProjectCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<ProjectUpsertWithoutInvoicesInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProjectWhereUniqueInput>;
  update?: Maybe<ProjectUpdateWithoutInvoicesInput>;
};

export type Supplier_Invoice_FilesUpdateManyWithoutInvoiceInput = {
  create?: Maybe<Array<Supplier_Invoice_FilesCreateWithoutInvoiceInput>>;
  connectOrCreate?: Maybe<Array<Supplier_Invoice_FilesCreateOrConnectWithoutInvoiceInput>>;
  upsert?: Maybe<Array<Supplier_Invoice_FilesUpsertWithWhereUniqueWithoutInvoiceInput>>;
  createMany?: Maybe<Supplier_Invoice_FilesCreateManyInvoiceInputEnvelope>;
  set?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  disconnect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  delete?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  connect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  update?: Maybe<Array<Supplier_Invoice_FilesUpdateWithWhereUniqueWithoutInvoiceInput>>;
  updateMany?: Maybe<Array<Supplier_Invoice_FilesUpdateManyWithWhereWithoutInvoiceInput>>;
  deleteMany?: Maybe<Array<Supplier_Invoice_FilesScalarWhereInput>>;
};

export type CompanyCreateNestedOneWithoutBrandsInput = {
  create?: Maybe<CompanyCreateWithoutBrandsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBrandsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type EstimateCreateNestedManyWithoutBrandInput = {
  create?: Maybe<Array<EstimateCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutBrandInput>>;
  createMany?: Maybe<EstimateCreateManyBrandInputEnvelope>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
};

export type CompanyUpdateOneRequiredWithoutBrandsInput = {
  create?: Maybe<CompanyCreateWithoutBrandsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBrandsInput>;
  upsert?: Maybe<CompanyUpsertWithoutBrandsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutBrandsInput>;
};

export type EstimateUpdateManyWithoutBrandInput = {
  create?: Maybe<Array<EstimateCreateWithoutBrandInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutBrandInput>>;
  upsert?: Maybe<Array<EstimateUpsertWithWhereUniqueWithoutBrandInput>>;
  createMany?: Maybe<EstimateCreateManyBrandInputEnvelope>;
  set?: Maybe<Array<EstimateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EstimateWhereUniqueInput>>;
  delete?: Maybe<Array<EstimateWhereUniqueInput>>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
  update?: Maybe<Array<EstimateUpdateWithWhereUniqueWithoutBrandInput>>;
  updateMany?: Maybe<Array<EstimateUpdateManyWithWhereWithoutBrandInput>>;
  deleteMany?: Maybe<Array<EstimateScalarWhereInput>>;
};

export type BrandCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<BrandCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<BrandCreateOrConnectWithoutCompanyInput>>;
  createMany?: Maybe<BrandCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<BrandWhereUniqueInput>>;
};

export type EstimateCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<EstimateCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutCompanyInput>>;
  createMany?: Maybe<EstimateCreateManyCompanyInputEnvelope>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
};

export type BrandUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<BrandCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<BrandCreateOrConnectWithoutCompanyInput>>;
  upsert?: Maybe<Array<BrandUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<BrandCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<BrandWhereUniqueInput>>;
  disconnect?: Maybe<Array<BrandWhereUniqueInput>>;
  delete?: Maybe<Array<BrandWhereUniqueInput>>;
  connect?: Maybe<Array<BrandWhereUniqueInput>>;
  update?: Maybe<Array<BrandUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<Array<BrandUpdateManyWithWhereWithoutCompanyInput>>;
  deleteMany?: Maybe<Array<BrandScalarWhereInput>>;
};

export type EstimateUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<EstimateCreateWithoutCompanyInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutCompanyInput>>;
  upsert?: Maybe<Array<EstimateUpsertWithWhereUniqueWithoutCompanyInput>>;
  createMany?: Maybe<EstimateCreateManyCompanyInputEnvelope>;
  set?: Maybe<Array<EstimateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EstimateWhereUniqueInput>>;
  delete?: Maybe<Array<EstimateWhereUniqueInput>>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
  update?: Maybe<Array<EstimateUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: Maybe<Array<EstimateUpdateManyWithWhereWithoutCompanyInput>>;
  deleteMany?: Maybe<Array<EstimateScalarWhereInput>>;
};

export type EstimateCreateNestedOneWithoutClient_InvoicesInput = {
  create?: Maybe<EstimateCreateWithoutClient_InvoicesInput>;
  connectOrCreate?: Maybe<EstimateCreateOrConnectWithoutClient_InvoicesInput>;
  connect?: Maybe<EstimateWhereUniqueInput>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type EstimateUpdateOneRequiredWithoutClient_InvoicesInput = {
  create?: Maybe<EstimateCreateWithoutClient_InvoicesInput>;
  connectOrCreate?: Maybe<EstimateCreateOrConnectWithoutClient_InvoicesInput>;
  upsert?: Maybe<EstimateUpsertWithoutClient_InvoicesInput>;
  connect?: Maybe<EstimateWhereUniqueInput>;
  update?: Maybe<EstimateUpdateWithoutClient_InvoicesInput>;
};

export type NullableEnumInvoiceStatusFieldUpdateOperationsInput = {
  set?: Maybe<InvoiceStatus>;
};

export type BrandCreateNestedOneWithoutEstimatesInput = {
  create?: Maybe<BrandCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutEstimatesInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutEstimatesInput = {
  create?: Maybe<CompanyCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutEstimatesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type PersonCreateNestedOneWithoutEstimatesInput = {
  create?: Maybe<PersonCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutEstimatesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type Client_InvoiceCreateNestedManyWithoutEstimateInput = {
  create?: Maybe<Array<Client_InvoiceCreateWithoutEstimateInput>>;
  connectOrCreate?: Maybe<Array<Client_InvoiceCreateOrConnectWithoutEstimateInput>>;
  createMany?: Maybe<Client_InvoiceCreateManyEstimateInputEnvelope>;
  connect?: Maybe<Array<Client_InvoiceWhereUniqueInput>>;
};

export type ProjectCreateNestedManyWithoutAssociated_EstimateInput = {
  create?: Maybe<Array<ProjectCreateWithoutAssociated_EstimateInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutAssociated_EstimateInput>>;
  createMany?: Maybe<ProjectCreateManyAssociated_EstimateInputEnvelope>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
};

export type DecimalFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Decimal']>;
  increment?: Maybe<Scalars['Decimal']>;
  decrement?: Maybe<Scalars['Decimal']>;
  multiply?: Maybe<Scalars['Decimal']>;
  divide?: Maybe<Scalars['Decimal']>;
};

export type BrandUpdateOneRequiredWithoutEstimatesInput = {
  create?: Maybe<BrandCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<BrandCreateOrConnectWithoutEstimatesInput>;
  upsert?: Maybe<BrandUpsertWithoutEstimatesInput>;
  connect?: Maybe<BrandWhereUniqueInput>;
  update?: Maybe<BrandUpdateWithoutEstimatesInput>;
};

export type CompanyUpdateOneRequiredWithoutEstimatesInput = {
  create?: Maybe<CompanyCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutEstimatesInput>;
  upsert?: Maybe<CompanyUpsertWithoutEstimatesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUpdateWithoutEstimatesInput>;
};

export type PersonUpdateOneRequiredWithoutEstimatesInput = {
  create?: Maybe<PersonCreateWithoutEstimatesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutEstimatesInput>;
  upsert?: Maybe<PersonUpsertWithoutEstimatesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutEstimatesInput>;
};

export type Client_InvoiceUpdateManyWithoutEstimateInput = {
  create?: Maybe<Array<Client_InvoiceCreateWithoutEstimateInput>>;
  connectOrCreate?: Maybe<Array<Client_InvoiceCreateOrConnectWithoutEstimateInput>>;
  upsert?: Maybe<Array<Client_InvoiceUpsertWithWhereUniqueWithoutEstimateInput>>;
  createMany?: Maybe<Client_InvoiceCreateManyEstimateInputEnvelope>;
  set?: Maybe<Array<Client_InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<Client_InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<Client_InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<Client_InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<Client_InvoiceUpdateWithWhereUniqueWithoutEstimateInput>>;
  updateMany?: Maybe<Array<Client_InvoiceUpdateManyWithWhereWithoutEstimateInput>>;
  deleteMany?: Maybe<Array<Client_InvoiceScalarWhereInput>>;
};

export type ProjectUpdateManyWithoutAssociated_EstimateInput = {
  create?: Maybe<Array<ProjectCreateWithoutAssociated_EstimateInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutAssociated_EstimateInput>>;
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutAssociated_EstimateInput>>;
  createMany?: Maybe<ProjectCreateManyAssociated_EstimateInputEnvelope>;
  set?: Maybe<Array<ProjectWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutAssociated_EstimateInput>>;
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereWithoutAssociated_EstimateInput>>;
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>;
};

export type EstimateCreateNestedOneWithoutProjectsInput = {
  create?: Maybe<EstimateCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<EstimateCreateOrConnectWithoutProjectsInput>;
  connect?: Maybe<EstimateWhereUniqueInput>;
};

export type CategoriesCreateNestedOneWithoutProjectsInput = {
  create?: Maybe<CategoriesCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<CategoriesCreateOrConnectWithoutProjectsInput>;
  connect?: Maybe<CategoriesWhereUniqueInput>;
};

export type ClientCreateNestedOneWithoutProjectsInput = {
  create?: Maybe<ClientCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutProjectsInput>;
  connect?: Maybe<ClientWhereUniqueInput>;
};

export type PersonCreateNestedOneWithoutProjectsInput = {
  create?: Maybe<PersonCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutProjectsInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type InvoiceCreateNestedManyWithoutProjectInput = {
  create?: Maybe<Array<InvoiceCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutProjectInput>>;
  createMany?: Maybe<InvoiceCreateManyProjectInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type Time_EntriesCreateNestedManyWithoutProjectInput = {
  create?: Maybe<Array<Time_EntriesCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<Time_EntriesCreateOrConnectWithoutProjectInput>>;
  createMany?: Maybe<Time_EntriesCreateManyProjectInputEnvelope>;
  connect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
};

export type PodCreateNestedOneWithoutIncoming_Split_ProjectsInput = {
  create?: Maybe<PodCreateWithoutIncoming_Split_ProjectsInput>;
  connectOrCreate?: Maybe<PodCreateOrConnectWithoutIncoming_Split_ProjectsInput>;
  connect?: Maybe<PodWhereUniqueInput>;
};

export type Project_RateCreateNestedManyWithoutProjectInput = {
  create?: Maybe<Array<Project_RateCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<Project_RateCreateOrConnectWithoutProjectInput>>;
  createMany?: Maybe<Project_RateCreateManyProjectInputEnvelope>;
  connect?: Maybe<Array<Project_RateWhereUniqueInput>>;
};

export type ProjectPricingCreateNestedOneWithoutProjectInput = {
  create?: Maybe<ProjectPricingCreateWithoutProjectInput>;
  connectOrCreate?: Maybe<ProjectPricingCreateOrConnectWithoutProjectInput>;
  connect?: Maybe<ProjectPricingWhereUniqueInput>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableEnumproject_TypeFieldUpdateOperationsInput = {
  set?: Maybe<Project_Type>;
};

export type NullableEnumproject_LanguageFieldUpdateOperationsInput = {
  set?: Maybe<Project_Language>;
};

export type NullableEnumexternal_ReviewerFieldUpdateOperationsInput = {
  set?: Maybe<External_Reviewer>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type EstimateUpdateOneWithoutProjectsInput = {
  create?: Maybe<EstimateCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<EstimateCreateOrConnectWithoutProjectsInput>;
  upsert?: Maybe<EstimateUpsertWithoutProjectsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<EstimateWhereUniqueInput>;
  update?: Maybe<EstimateUpdateWithoutProjectsInput>;
};

export type CategoriesUpdateOneWithoutProjectsInput = {
  create?: Maybe<CategoriesCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<CategoriesCreateOrConnectWithoutProjectsInput>;
  upsert?: Maybe<CategoriesUpsertWithoutProjectsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<CategoriesWhereUniqueInput>;
  update?: Maybe<CategoriesUpdateWithoutProjectsInput>;
};

export type ClientUpdateOneRequiredWithoutProjectsInput = {
  create?: Maybe<ClientCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutProjectsInput>;
  upsert?: Maybe<ClientUpsertWithoutProjectsInput>;
  connect?: Maybe<ClientWhereUniqueInput>;
  update?: Maybe<ClientUpdateWithoutProjectsInput>;
};

export type PersonUpdateOneWithoutProjectsInput = {
  create?: Maybe<PersonCreateWithoutProjectsInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutProjectsInput>;
  upsert?: Maybe<PersonUpsertWithoutProjectsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutProjectsInput>;
};

export type InvoiceUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<InvoiceCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutProjectInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutProjectInput>>;
  createMany?: Maybe<InvoiceCreateManyProjectInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutProjectInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type Time_EntriesUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<Time_EntriesCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<Time_EntriesCreateOrConnectWithoutProjectInput>>;
  upsert?: Maybe<Array<Time_EntriesUpsertWithWhereUniqueWithoutProjectInput>>;
  createMany?: Maybe<Time_EntriesCreateManyProjectInputEnvelope>;
  set?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  disconnect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  delete?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  connect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  update?: Maybe<Array<Time_EntriesUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: Maybe<Array<Time_EntriesUpdateManyWithWhereWithoutProjectInput>>;
  deleteMany?: Maybe<Array<Time_EntriesScalarWhereInput>>;
};

export type PodUpdateOneWithoutIncoming_Split_ProjectsInput = {
  create?: Maybe<PodCreateWithoutIncoming_Split_ProjectsInput>;
  connectOrCreate?: Maybe<PodCreateOrConnectWithoutIncoming_Split_ProjectsInput>;
  upsert?: Maybe<PodUpsertWithoutIncoming_Split_ProjectsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<PodWhereUniqueInput>;
  update?: Maybe<PodUpdateWithoutIncoming_Split_ProjectsInput>;
};

export type Project_RateUpdateManyWithoutProjectInput = {
  create?: Maybe<Array<Project_RateCreateWithoutProjectInput>>;
  connectOrCreate?: Maybe<Array<Project_RateCreateOrConnectWithoutProjectInput>>;
  upsert?: Maybe<Array<Project_RateUpsertWithWhereUniqueWithoutProjectInput>>;
  createMany?: Maybe<Project_RateCreateManyProjectInputEnvelope>;
  set?: Maybe<Array<Project_RateWhereUniqueInput>>;
  disconnect?: Maybe<Array<Project_RateWhereUniqueInput>>;
  delete?: Maybe<Array<Project_RateWhereUniqueInput>>;
  connect?: Maybe<Array<Project_RateWhereUniqueInput>>;
  update?: Maybe<Array<Project_RateUpdateWithWhereUniqueWithoutProjectInput>>;
  updateMany?: Maybe<Array<Project_RateUpdateManyWithWhereWithoutProjectInput>>;
  deleteMany?: Maybe<Array<Project_RateScalarWhereInput>>;
};

export type ProjectPricingUpdateOneWithoutProjectInput = {
  create?: Maybe<ProjectPricingCreateWithoutProjectInput>;
  connectOrCreate?: Maybe<ProjectPricingCreateOrConnectWithoutProjectInput>;
  upsert?: Maybe<ProjectPricingUpsertWithoutProjectInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<ProjectPricingWhereUniqueInput>;
  update?: Maybe<ProjectPricingUpdateWithoutProjectInput>;
};

export type PodAnnualRevenueTargetCreateNestedManyWithoutPodInput = {
  create?: Maybe<Array<PodAnnualRevenueTargetCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<PodAnnualRevenueTargetCreateOrConnectWithoutPodInput>>;
  createMany?: Maybe<PodAnnualRevenueTargetCreateManyPodInputEnvelope>;
  connect?: Maybe<Array<PodAnnualRevenueTargetWhereUniqueInput>>;
};

export type PersonCreateNestedManyWithoutPodInput = {
  create?: Maybe<Array<PersonCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<PersonCreateOrConnectWithoutPodInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
};

export type ProjectCreateNestedManyWithoutSales_Split_PodInput = {
  create?: Maybe<Array<ProjectCreateWithoutSales_Split_PodInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutSales_Split_PodInput>>;
  createMany?: Maybe<ProjectCreateManySales_Split_PodInputEnvelope>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
};

export type ClientAnnualRevenueForecastCreateNestedManyWithoutPodInput = {
  create?: Maybe<Array<ClientAnnualRevenueForecastCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<ClientAnnualRevenueForecastCreateOrConnectWithoutPodInput>>;
  createMany?: Maybe<ClientAnnualRevenueForecastCreateManyPodInputEnvelope>;
  connect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
};

export type PodAnnualRevenueTargetUpdateManyWithoutPodInput = {
  create?: Maybe<Array<PodAnnualRevenueTargetCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<PodAnnualRevenueTargetCreateOrConnectWithoutPodInput>>;
  upsert?: Maybe<Array<PodAnnualRevenueTargetUpsertWithWhereUniqueWithoutPodInput>>;
  createMany?: Maybe<PodAnnualRevenueTargetCreateManyPodInputEnvelope>;
  set?: Maybe<Array<PodAnnualRevenueTargetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodAnnualRevenueTargetWhereUniqueInput>>;
  delete?: Maybe<Array<PodAnnualRevenueTargetWhereUniqueInput>>;
  connect?: Maybe<Array<PodAnnualRevenueTargetWhereUniqueInput>>;
  update?: Maybe<Array<PodAnnualRevenueTargetUpdateWithWhereUniqueWithoutPodInput>>;
  updateMany?: Maybe<Array<PodAnnualRevenueTargetUpdateManyWithWhereWithoutPodInput>>;
  deleteMany?: Maybe<Array<PodAnnualRevenueTargetScalarWhereInput>>;
};

export type PersonUpdateManyWithoutPodInput = {
  create?: Maybe<Array<PersonCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<PersonCreateOrConnectWithoutPodInput>>;
  upsert?: Maybe<Array<PersonUpsertWithWhereUniqueWithoutPodInput>>;
  set?: Maybe<Array<PersonWhereUniqueInput>>;
  disconnect?: Maybe<Array<PersonWhereUniqueInput>>;
  delete?: Maybe<Array<PersonWhereUniqueInput>>;
  connect?: Maybe<Array<PersonWhereUniqueInput>>;
  update?: Maybe<Array<PersonUpdateWithWhereUniqueWithoutPodInput>>;
  updateMany?: Maybe<Array<PersonUpdateManyWithWhereWithoutPodInput>>;
  deleteMany?: Maybe<Array<PersonScalarWhereInput>>;
};

export type ProjectUpdateManyWithoutSales_Split_PodInput = {
  create?: Maybe<Array<ProjectCreateWithoutSales_Split_PodInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutSales_Split_PodInput>>;
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutSales_Split_PodInput>>;
  createMany?: Maybe<ProjectCreateManySales_Split_PodInputEnvelope>;
  set?: Maybe<Array<ProjectWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutSales_Split_PodInput>>;
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereWithoutSales_Split_PodInput>>;
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>;
};

export type ClientAnnualRevenueForecastUpdateManyWithoutPodInput = {
  create?: Maybe<Array<ClientAnnualRevenueForecastCreateWithoutPodInput>>;
  connectOrCreate?: Maybe<Array<ClientAnnualRevenueForecastCreateOrConnectWithoutPodInput>>;
  upsert?: Maybe<Array<ClientAnnualRevenueForecastUpsertWithWhereUniqueWithoutPodInput>>;
  createMany?: Maybe<ClientAnnualRevenueForecastCreateManyPodInputEnvelope>;
  set?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  delete?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  connect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  update?: Maybe<Array<ClientAnnualRevenueForecastUpdateWithWhereUniqueWithoutPodInput>>;
  updateMany?: Maybe<Array<ClientAnnualRevenueForecastUpdateManyWithWhereWithoutPodInput>>;
  deleteMany?: Maybe<Array<ClientAnnualRevenueForecastScalarWhereInput>>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type Enumproject_TypeNullableFilter = {
  equals?: Maybe<Project_Type>;
  in?: Maybe<Array<Project_Type>>;
  notIn?: Maybe<Array<Project_Type>>;
  not?: Maybe<NestedEnumproject_TypeNullableFilter>;
};

export type Enumproject_LanguageNullableFilter = {
  equals?: Maybe<Project_Language>;
  in?: Maybe<Array<Project_Language>>;
  notIn?: Maybe<Array<Project_Language>>;
  not?: Maybe<NestedEnumproject_LanguageNullableFilter>;
};

export type Enumexternal_ReviewerNullableFilter = {
  equals?: Maybe<External_Reviewer>;
  in?: Maybe<Array<External_Reviewer>>;
  notIn?: Maybe<Array<External_Reviewer>>;
  not?: Maybe<NestedEnumexternal_ReviewerNullableFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type CategoriesWhereInput = {
  AND?: Maybe<Array<CategoriesWhereInput>>;
  OR?: Maybe<Array<CategoriesWhereInput>>;
  NOT?: Maybe<Array<CategoriesWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  color?: Maybe<StringFilter>;
  projects?: Maybe<ProjectListRelationFilter>;
};

export type ClientWhereInput = {
  AND?: Maybe<Array<ClientWhereInput>>;
  OR?: Maybe<Array<ClientWhereInput>>;
  NOT?: Maybe<Array<ClientWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  projects?: Maybe<ProjectListRelationFilter>;
  forecasts?: Maybe<ClientAnnualRevenueForecastListRelationFilter>;
};

export type PersonWhereInput = {
  AND?: Maybe<Array<PersonWhereInput>>;
  OR?: Maybe<Array<PersonWhereInput>>;
  NOT?: Maybe<Array<PersonWhereInput>>;
  id?: Maybe<StringFilter>;
  tw_username?: Maybe<StringNullableFilter>;
  email_address?: Maybe<StringFilter>;
  avatar_url?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeNullableFilter>;
  first_name?: Maybe<StringFilter>;
  last_name?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  company?: Maybe<StringFilter>;
  rate?: Maybe<IntFilter>;
  estimates?: Maybe<EstimateListRelationFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  projects?: Maybe<ProjectListRelationFilter>;
  time_entries?: Maybe<Time_EntriesListRelationFilter>;
  pod?: Maybe<PodListRelationFilter>;
  days_off?: Maybe<Day_OffListRelationFilter>;
  pricing_comments?: Maybe<PricingCommentListRelationFilter>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesListRelationFilter>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetListRelationFilter>;
  project_rates?: Maybe<Project_RateListRelationFilter>;
  is_deleted_on_tw?: Maybe<BoolFilter>;
  roleId?: Maybe<StringNullableFilter>;
  role?: Maybe<RoleWhereInput>;
  teamId?: Maybe<StringNullableFilter>;
  team?: Maybe<TeamWhereInput>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type Time_EntriesListRelationFilter = {
  every?: Maybe<Time_EntriesWhereInput>;
  some?: Maybe<Time_EntriesWhereInput>;
  none?: Maybe<Time_EntriesWhereInput>;
};

export type PodWhereInput = {
  AND?: Maybe<Array<PodWhereInput>>;
  OR?: Maybe<Array<PodWhereInput>>;
  NOT?: Maybe<Array<PodWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  senior_brand_maven_lead?: Maybe<StringNullableFilter>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetListRelationFilter>;
  members?: Maybe<PersonListRelationFilter>;
  incoming_split_projects?: Maybe<ProjectListRelationFilter>;
  forecasts?: Maybe<ClientAnnualRevenueForecastListRelationFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type Project_RateListRelationFilter = {
  every?: Maybe<Project_RateWhereInput>;
  some?: Maybe<Project_RateWhereInput>;
  none?: Maybe<Project_RateWhereInput>;
};

export type ProjectPricingWhereInput = {
  AND?: Maybe<Array<ProjectPricingWhereInput>>;
  OR?: Maybe<Array<ProjectPricingWhereInput>>;
  NOT?: Maybe<Array<ProjectPricingWhereInput>>;
  id?: Maybe<IntFilter>;
  project_id?: Maybe<StringFilter>;
  project?: Maybe<ProjectWhereInput>;
  price?: Maybe<IntFilter>;
  actual_cost?: Maybe<IntFilter>;
  currency?: Maybe<StringFilter>;
  projected_times?: Maybe<ProjectedTimeListRelationFilter>;
  projected_expenses?: Maybe<ProjectedExpenseListRelationFilter>;
  comments?: Maybe<PricingCommentListRelationFilter>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type CategoriesOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  color?: Maybe<SortOrder>;
  projects?: Maybe<ProjectOrderByRelationAggregateInput>;
};

export type ClientOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  projects?: Maybe<ProjectOrderByRelationAggregateInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastOrderByRelationAggregateInput>;
};

export type PersonOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  tw_username?: Maybe<SortOrder>;
  email_address?: Maybe<SortOrder>;
  avatar_url?: Maybe<SortOrder>;
  created_at?: Maybe<SortOrder>;
  first_name?: Maybe<SortOrder>;
  last_name?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  company?: Maybe<SortOrder>;
  rate?: Maybe<SortOrder>;
  estimates?: Maybe<EstimateOrderByRelationAggregateInput>;
  invoices?: Maybe<InvoiceOrderByRelationAggregateInput>;
  projects?: Maybe<ProjectOrderByRelationAggregateInput>;
  time_entries?: Maybe<Time_EntriesOrderByRelationAggregateInput>;
  pod?: Maybe<PodOrderByRelationAggregateInput>;
  days_off?: Maybe<Day_OffOrderByRelationAggregateInput>;
  pricing_comments?: Maybe<PricingCommentOrderByRelationAggregateInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesOrderByRelationAggregateInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetOrderByRelationAggregateInput>;
  project_rates?: Maybe<Project_RateOrderByRelationAggregateInput>;
  is_deleted_on_tw?: Maybe<SortOrder>;
  roleId?: Maybe<SortOrder>;
  role?: Maybe<RoleOrderByWithRelationInput>;
  teamId?: Maybe<SortOrder>;
  team?: Maybe<TeamOrderByWithRelationInput>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type Time_EntriesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PodOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  senior_brand_maven_lead?: Maybe<SortOrder>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetOrderByRelationAggregateInput>;
  members?: Maybe<PersonOrderByRelationAggregateInput>;
  incoming_split_projects?: Maybe<ProjectOrderByRelationAggregateInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastOrderByRelationAggregateInput>;
};

export type Project_RateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProjectPricingOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  project_id?: Maybe<SortOrder>;
  project?: Maybe<ProjectOrderByWithRelationInput>;
  price?: Maybe<SortOrder>;
  actual_cost?: Maybe<SortOrder>;
  currency?: Maybe<SortOrder>;
  projected_times?: Maybe<ProjectedTimeOrderByRelationAggregateInput>;
  projected_expenses?: Maybe<ProjectedExpenseOrderByRelationAggregateInput>;
  comments?: Maybe<PricingCommentOrderByRelationAggregateInput>;
};

export type Supplier_Invoice_FilesListRelationFilter = {
  every?: Maybe<Supplier_Invoice_FilesWhereInput>;
  some?: Maybe<Supplier_Invoice_FilesWhereInput>;
  none?: Maybe<Supplier_Invoice_FilesWhereInput>;
};

export type EstimateListRelationFilter = {
  every?: Maybe<EstimateWhereInput>;
  some?: Maybe<EstimateWhereInput>;
  none?: Maybe<EstimateWhereInput>;
};

export type EstimateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type BrandListRelationFilter = {
  every?: Maybe<BrandWhereInput>;
  some?: Maybe<BrandWhereInput>;
  none?: Maybe<BrandWhereInput>;
};

export type BrandOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type EnumInvoiceStatusNullableFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusNullableFilter>;
};

export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type Client_InvoiceListRelationFilter = {
  every?: Maybe<Client_InvoiceWhereInput>;
  some?: Maybe<Client_InvoiceWhereInput>;
  none?: Maybe<Client_InvoiceWhereInput>;
};

export type ProjectListRelationFilter = {
  every?: Maybe<ProjectWhereInput>;
  some?: Maybe<ProjectWhereInput>;
  none?: Maybe<ProjectWhereInput>;
};

export type Client_InvoiceOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProjectOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProjectedTimeTasklist_IdRole_IdTeam_IdProject_Pricing_IdCompoundUniqueInput = {
  tasklist_id: Scalars['String'];
  role_id: Scalars['String'];
  team_id: Scalars['String'];
  project_pricing_id: Scalars['Int'];
};

export type ProjectedExpenseProject_IdExpense_TypePayee_NameCompoundUniqueInput = {
  project_id: Scalars['String'];
  expense_type: Scalars['String'];
  payee_name: Scalars['String'];
};

export type PersonCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutInvoicesInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutInvoicesInput;
};

export type ProjectCreateWithoutInvoicesInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutInvoicesInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutInvoicesInput;
};

export type Supplier_Invoice_FilesCreateWithoutInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  created_by: PersonCreateNestedOneWithoutUploaded_Supplier_InvoicesInput;
};

export type Supplier_Invoice_FilesCreateOrConnectWithoutInvoiceInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  create: Supplier_Invoice_FilesCreateWithoutInvoiceInput;
};

export type Supplier_Invoice_FilesCreateManyInvoiceInputEnvelope = {
  data?: Maybe<Array<Supplier_Invoice_FilesCreateManyInvoiceInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PersonUpsertWithoutInvoicesInput = {
  update: PersonUpdateWithoutInvoicesInput;
  create: PersonCreateWithoutInvoicesInput;
};

export type PersonUpdateWithoutInvoicesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type ProjectUpsertWithoutInvoicesInput = {
  update: ProjectUpdateWithoutInvoicesInput;
  create: ProjectCreateWithoutInvoicesInput;
};

export type ProjectUpdateWithoutInvoicesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type Supplier_Invoice_FilesUpsertWithWhereUniqueWithoutInvoiceInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  update: Supplier_Invoice_FilesUpdateWithoutInvoiceInput;
  create: Supplier_Invoice_FilesCreateWithoutInvoiceInput;
};

export type Supplier_Invoice_FilesUpdateWithWhereUniqueWithoutInvoiceInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  data: Supplier_Invoice_FilesUpdateWithoutInvoiceInput;
};

export type Supplier_Invoice_FilesUpdateManyWithWhereWithoutInvoiceInput = {
  where: Supplier_Invoice_FilesScalarWhereInput;
  data: Supplier_Invoice_FilesUpdateManyMutationInput;
};

export type Supplier_Invoice_FilesScalarWhereInput = {
  AND?: Maybe<Array<Supplier_Invoice_FilesScalarWhereInput>>;
  OR?: Maybe<Array<Supplier_Invoice_FilesScalarWhereInput>>;
  NOT?: Maybe<Array<Supplier_Invoice_FilesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  content_type?: Maybe<StringFilter>;
  file_name?: Maybe<StringFilter>;
  created_by_id?: Maybe<StringFilter>;
  invoice_id?: Maybe<StringFilter>;
};

export type CompanyCreateWithoutBrandsInput = {
  id?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
  estimates?: Maybe<EstimateCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutBrandsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutBrandsInput;
};

export type EstimateCreateWithoutBrandInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  company: CompanyCreateNestedOneWithoutEstimatesInput;
  owner: PersonCreateNestedOneWithoutEstimatesInput;
  client_invoices?: Maybe<Client_InvoiceCreateNestedManyWithoutEstimateInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutAssociated_EstimateInput>;
};

export type EstimateCreateOrConnectWithoutBrandInput = {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutBrandInput;
};

export type EstimateCreateManyBrandInputEnvelope = {
  data?: Maybe<Array<EstimateCreateManyBrandInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CompanyUpsertWithoutBrandsInput = {
  update: CompanyUpdateWithoutBrandsInput;
  create: CompanyCreateWithoutBrandsInput;
};

export type CompanyUpdateWithoutBrandsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutCompanyInput>;
};

export type EstimateUpsertWithWhereUniqueWithoutBrandInput = {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutBrandInput;
  create: EstimateCreateWithoutBrandInput;
};

export type EstimateUpdateWithWhereUniqueWithoutBrandInput = {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutBrandInput;
};

export type EstimateUpdateManyWithWhereWithoutBrandInput = {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
};

export type EstimateScalarWhereInput = {
  AND?: Maybe<Array<EstimateScalarWhereInput>>;
  OR?: Maybe<Array<EstimateScalarWhereInput>>;
  NOT?: Maybe<Array<EstimateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  owner_id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  po_number?: Maybe<StringNullableFilter>;
  company_id?: Maybe<StringFilter>;
  brand_id?: Maybe<StringFilter>;
  po_subtotal?: Maybe<DecimalFilter>;
  created_at?: Maybe<DateTimeFilter>;
  updated_at?: Maybe<DateTimeNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  pdf_file_key?: Maybe<StringNullableFilter>;
};

export type BrandCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  brand_name: Scalars['String'];
  estimates?: Maybe<EstimateCreateNestedManyWithoutBrandInput>;
};

export type BrandCreateOrConnectWithoutCompanyInput = {
  where: BrandWhereUniqueInput;
  create: BrandCreateWithoutCompanyInput;
};

export type BrandCreateManyCompanyInputEnvelope = {
  data?: Maybe<Array<BrandCreateManyCompanyInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EstimateCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  brand: BrandCreateNestedOneWithoutEstimatesInput;
  owner: PersonCreateNestedOneWithoutEstimatesInput;
  client_invoices?: Maybe<Client_InvoiceCreateNestedManyWithoutEstimateInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutAssociated_EstimateInput>;
};

export type EstimateCreateOrConnectWithoutCompanyInput = {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutCompanyInput;
};

export type EstimateCreateManyCompanyInputEnvelope = {
  data?: Maybe<Array<EstimateCreateManyCompanyInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BrandUpsertWithWhereUniqueWithoutCompanyInput = {
  where: BrandWhereUniqueInput;
  update: BrandUpdateWithoutCompanyInput;
  create: BrandCreateWithoutCompanyInput;
};

export type BrandUpdateWithWhereUniqueWithoutCompanyInput = {
  where: BrandWhereUniqueInput;
  data: BrandUpdateWithoutCompanyInput;
};

export type BrandUpdateManyWithWhereWithoutCompanyInput = {
  where: BrandScalarWhereInput;
  data: BrandUpdateManyMutationInput;
};

export type BrandScalarWhereInput = {
  AND?: Maybe<Array<BrandScalarWhereInput>>;
  OR?: Maybe<Array<BrandScalarWhereInput>>;
  NOT?: Maybe<Array<BrandScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  brand_name?: Maybe<StringFilter>;
  company_id?: Maybe<StringFilter>;
};

export type EstimateUpsertWithWhereUniqueWithoutCompanyInput = {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutCompanyInput;
  create: EstimateCreateWithoutCompanyInput;
};

export type EstimateUpdateWithWhereUniqueWithoutCompanyInput = {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutCompanyInput;
};

export type EstimateUpdateManyWithWhereWithoutCompanyInput = {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
};

export type EstimateCreateWithoutClient_InvoicesInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  brand: BrandCreateNestedOneWithoutEstimatesInput;
  company: CompanyCreateNestedOneWithoutEstimatesInput;
  owner: PersonCreateNestedOneWithoutEstimatesInput;
  projects?: Maybe<ProjectCreateNestedManyWithoutAssociated_EstimateInput>;
};

export type EstimateCreateOrConnectWithoutClient_InvoicesInput = {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutClient_InvoicesInput;
};

export type EstimateUpsertWithoutClient_InvoicesInput = {
  update: EstimateUpdateWithoutClient_InvoicesInput;
  create: EstimateCreateWithoutClient_InvoicesInput;
};

export type EstimateUpdateWithoutClient_InvoicesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutEstimatesInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutEstimatesInput>;
  owner?: Maybe<PersonUpdateOneRequiredWithoutEstimatesInput>;
  projects?: Maybe<ProjectUpdateManyWithoutAssociated_EstimateInput>;
};

export type BrandCreateWithoutEstimatesInput = {
  id?: Maybe<Scalars['String']>;
  brand_name: Scalars['String'];
  company: CompanyCreateNestedOneWithoutBrandsInput;
};

export type BrandCreateOrConnectWithoutEstimatesInput = {
  where: BrandWhereUniqueInput;
  create: BrandCreateWithoutEstimatesInput;
};

export type CompanyCreateWithoutEstimatesInput = {
  id?: Maybe<Scalars['String']>;
  company_name: Scalars['String'];
  brands?: Maybe<BrandCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutEstimatesInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyCreateWithoutEstimatesInput;
};

export type PersonCreateWithoutEstimatesInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutEstimatesInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutEstimatesInput;
};

export type Client_InvoiceCreateWithoutEstimateInput = {
  id?: Maybe<Scalars['String']>;
  amount_in_cents?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  date_invoiced?: Maybe<Scalars['DateTime']>;
  date_deposit_expected?: Maybe<Scalars['DateTime']>;
  date_deposited?: Maybe<Scalars['DateTime']>;
  invoice_number?: Maybe<Scalars['String']>;
  quickbooks_id?: Maybe<Scalars['String']>;
  invoice_status?: Maybe<InvoiceStatus>;
};

export type Client_InvoiceCreateOrConnectWithoutEstimateInput = {
  where: Client_InvoiceWhereUniqueInput;
  create: Client_InvoiceCreateWithoutEstimateInput;
};

export type Client_InvoiceCreateManyEstimateInputEnvelope = {
  data?: Maybe<Array<Client_InvoiceCreateManyEstimateInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProjectCreateWithoutAssociated_EstimateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutAssociated_EstimateInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutAssociated_EstimateInput;
};

export type ProjectCreateManyAssociated_EstimateInputEnvelope = {
  data?: Maybe<Array<ProjectCreateManyAssociated_EstimateInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BrandUpsertWithoutEstimatesInput = {
  update: BrandUpdateWithoutEstimatesInput;
  create: BrandCreateWithoutEstimatesInput;
};

export type BrandUpdateWithoutEstimatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  brand_name?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutBrandsInput>;
};

export type CompanyUpsertWithoutEstimatesInput = {
  update: CompanyUpdateWithoutEstimatesInput;
  create: CompanyCreateWithoutEstimatesInput;
};

export type CompanyUpdateWithoutEstimatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  company_name?: Maybe<StringFieldUpdateOperationsInput>;
  brands?: Maybe<BrandUpdateManyWithoutCompanyInput>;
};

export type PersonUpsertWithoutEstimatesInput = {
  update: PersonUpdateWithoutEstimatesInput;
  create: PersonCreateWithoutEstimatesInput;
};

export type PersonUpdateWithoutEstimatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type Client_InvoiceUpsertWithWhereUniqueWithoutEstimateInput = {
  where: Client_InvoiceWhereUniqueInput;
  update: Client_InvoiceUpdateWithoutEstimateInput;
  create: Client_InvoiceCreateWithoutEstimateInput;
};

export type Client_InvoiceUpdateWithWhereUniqueWithoutEstimateInput = {
  where: Client_InvoiceWhereUniqueInput;
  data: Client_InvoiceUpdateWithoutEstimateInput;
};

export type Client_InvoiceUpdateManyWithWhereWithoutEstimateInput = {
  where: Client_InvoiceScalarWhereInput;
  data: Client_InvoiceUpdateManyMutationInput;
};

export type Client_InvoiceScalarWhereInput = {
  AND?: Maybe<Array<Client_InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<Client_InvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<Client_InvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  amount_in_cents?: Maybe<IntFilter>;
  created_at?: Maybe<DateTimeFilter>;
  date_invoiced?: Maybe<DateTimeNullableFilter>;
  date_deposit_expected?: Maybe<DateTimeNullableFilter>;
  date_deposited?: Maybe<DateTimeNullableFilter>;
  invoice_number?: Maybe<StringNullableFilter>;
  quickbooks_id?: Maybe<StringNullableFilter>;
  estimate_id?: Maybe<StringFilter>;
  invoice_status?: Maybe<EnumInvoiceStatusNullableFilter>;
};

export type ProjectUpsertWithWhereUniqueWithoutAssociated_EstimateInput = {
  where: ProjectWhereUniqueInput;
  update: ProjectUpdateWithoutAssociated_EstimateInput;
  create: ProjectCreateWithoutAssociated_EstimateInput;
};

export type ProjectUpdateWithWhereUniqueWithoutAssociated_EstimateInput = {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateWithoutAssociated_EstimateInput;
};

export type ProjectUpdateManyWithWhereWithoutAssociated_EstimateInput = {
  where: ProjectScalarWhereInput;
  data: ProjectUpdateManyMutationInput;
};

export type ProjectScalarWhereInput = {
  AND?: Maybe<Array<ProjectScalarWhereInput>>;
  OR?: Maybe<Array<ProjectScalarWhereInput>>;
  NOT?: Maybe<Array<ProjectScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  project_owner_id?: Maybe<StringNullableFilter>;
  budget?: Maybe<IntNullableFilter>;
  client_id?: Maybe<StringFilter>;
  component_code?: Maybe<StringNullableFilter>;
  category_id?: Maybe<StringNullableFilter>;
  created_at?: Maybe<DateTimeFilter>;
  start_date?: Maybe<DateTimeNullableFilter>;
  end_date?: Maybe<DateTimeNullableFilter>;
  last_changed_at?: Maybe<DateTimeNullableFilter>;
  project_type?: Maybe<Enumproject_TypeNullableFilter>;
  language?: Maybe<Enumproject_LanguageNullableFilter>;
  external_reviewer?: Maybe<Enumexternal_ReviewerNullableFilter>;
  internal_review?: Maybe<BoolNullableFilter>;
  last_synced_overview_at?: Maybe<DateTimeFilter>;
  project_status?: Maybe<StringNullableFilter>;
  last_synced_details_at?: Maybe<DateTimeNullableFilter>;
  client_brand_manager?: Maybe<StringNullableFilter>;
  is_reviewed?: Maybe<BoolFilter>;
  is_archived?: Maybe<BoolFilter>;
  is_deleted?: Maybe<BoolFilter>;
  associated_estimate_id?: Maybe<StringNullableFilter>;
  project_notes?: Maybe<StringNullableFilter>;
  project_notes_2?: Maybe<StringNullableFilter>;
  senior_brand_maven_lead?: Maybe<StringNullableFilter>;
  sales_split_pod_id?: Maybe<StringNullableFilter>;
  sales_split_ratio?: Maybe<IntFilter>;
  epoch?: Maybe<IntFilter>;
};

export type EstimateCreateWithoutProjectsInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  brand: BrandCreateNestedOneWithoutEstimatesInput;
  company: CompanyCreateNestedOneWithoutEstimatesInput;
  owner: PersonCreateNestedOneWithoutEstimatesInput;
  client_invoices?: Maybe<Client_InvoiceCreateNestedManyWithoutEstimateInput>;
};

export type EstimateCreateOrConnectWithoutProjectsInput = {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutProjectsInput;
};

export type CategoriesCreateWithoutProjectsInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
};

export type CategoriesCreateOrConnectWithoutProjectsInput = {
  where: CategoriesWhereUniqueInput;
  create: CategoriesCreateWithoutProjectsInput;
};

export type CategoriesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type ClientCreateWithoutProjectsInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  forecasts?: Maybe<ClientAnnualRevenueForecastCreateNestedManyWithoutClientInput>;
};

export type ClientCreateOrConnectWithoutProjectsInput = {
  where: ClientWhereUniqueInput;
  create: ClientCreateWithoutProjectsInput;
};

export type ClientWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PersonCreateWithoutProjectsInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutProjectsInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutProjectsInput;
};

export type InvoiceCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  contractor?: Maybe<PersonCreateNestedOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateOrConnectWithoutProjectInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateWithoutProjectInput;
};

export type InvoiceCreateManyProjectInputEnvelope = {
  data?: Maybe<Array<InvoiceCreateManyProjectInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Time_EntriesCreateWithoutProjectInput = {
  id: Scalars['String'];
  date: Scalars['DateTime'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
  company_id?: Maybe<Scalars['String']>;
  project_tag_id?: Maybe<Scalars['String']>;
  tasklist_id?: Maybe<Scalars['Int']>;
  person: PersonCreateNestedOneWithoutTime_EntriesInput;
};

export type Time_EntriesCreateOrConnectWithoutProjectInput = {
  where: Time_EntriesWhereUniqueInput;
  create: Time_EntriesCreateWithoutProjectInput;
};

export type Time_EntriesCreateManyProjectInputEnvelope = {
  data?: Maybe<Array<Time_EntriesCreateManyProjectInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Time_EntriesWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PodCreateWithoutIncoming_Split_ProjectsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetCreateNestedManyWithoutPodInput>;
  members?: Maybe<PersonCreateNestedManyWithoutPodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastCreateNestedManyWithoutPodInput>;
};

export type PodCreateOrConnectWithoutIncoming_Split_ProjectsInput = {
  where: PodWhereUniqueInput;
  create: PodCreateWithoutIncoming_Split_ProjectsInput;
};

export type Project_RateCreateWithoutProjectInput = {
  id?: Maybe<Scalars['String']>;
  person: PersonCreateNestedOneWithoutProject_RatesInput;
  rate_in_cents: Scalars['Int'];
  source: Project_Rate_Source;
  updated_at: Scalars['DateTime'];
  created_at?: Maybe<Scalars['DateTime']>;
};

export type Project_RateCreateOrConnectWithoutProjectInput = {
  where: Project_RateWhereUniqueInput;
  create: Project_RateCreateWithoutProjectInput;
};

export type Project_RateCreateManyProjectInputEnvelope = {
  data?: Maybe<Array<Project_RateCreateManyProjectInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Project_RateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  project_id_person_id?: Maybe<Project_RateProject_IdPerson_IdCompoundUniqueInput>;
};

export type ProjectPricingCreateWithoutProjectInput = {
  price?: Maybe<Scalars['Int']>;
  actual_cost?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  projected_times?: Maybe<ProjectedTimeCreateNestedManyWithoutProject_PricingInput>;
  projected_expenses?: Maybe<ProjectedExpenseCreateNestedManyWithoutProject_PricingInput>;
  comments?: Maybe<PricingCommentCreateNestedManyWithoutProject_PricingInput>;
};

export type ProjectPricingCreateOrConnectWithoutProjectInput = {
  where: ProjectPricingWhereUniqueInput;
  create: ProjectPricingCreateWithoutProjectInput;
};

export type ProjectPricingWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['String']>;
};

export type EstimateUpsertWithoutProjectsInput = {
  update: EstimateUpdateWithoutProjectsInput;
  create: EstimateCreateWithoutProjectsInput;
};

export type EstimateUpdateWithoutProjectsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutEstimatesInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutEstimatesInput>;
  owner?: Maybe<PersonUpdateOneRequiredWithoutEstimatesInput>;
  client_invoices?: Maybe<Client_InvoiceUpdateManyWithoutEstimateInput>;
};

export type CategoriesUpsertWithoutProjectsInput = {
  update: CategoriesUpdateWithoutProjectsInput;
  create: CategoriesCreateWithoutProjectsInput;
};

export type CategoriesUpdateWithoutProjectsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  color?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientUpsertWithoutProjectsInput = {
  update: ClientUpdateWithoutProjectsInput;
  create: ClientCreateWithoutProjectsInput;
};

export type ClientUpdateWithoutProjectsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastUpdateManyWithoutClientInput>;
};

export type PersonUpsertWithoutProjectsInput = {
  update: PersonUpdateWithoutProjectsInput;
  create: PersonCreateWithoutProjectsInput;
};

export type PersonUpdateWithoutProjectsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutProjectInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUpdateWithoutProjectInput;
  create: InvoiceCreateWithoutProjectInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutProjectInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUpdateWithoutProjectInput;
};

export type InvoiceUpdateManyWithWhereWithoutProjectInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUpdateManyMutationInput;
};

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<InvoiceScalarWhereInput>>;
  OR?: Maybe<Array<InvoiceScalarWhereInput>>;
  NOT?: Maybe<Array<InvoiceScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  invoice_number?: Maybe<StringFilter>;
  project_id?: Maybe<StringNullableFilter>;
  expense_type?: Maybe<StringFilter>;
  notes?: Maybe<StringNullableFilter>;
  date_created?: Maybe<DateTimeFilter>;
  amount_in_cents?: Maybe<IntFilter>;
  payee_name?: Maybe<StringNullableFilter>;
  contractor_id?: Maybe<StringNullableFilter>;
};

export type Time_EntriesUpsertWithWhereUniqueWithoutProjectInput = {
  where: Time_EntriesWhereUniqueInput;
  update: Time_EntriesUpdateWithoutProjectInput;
  create: Time_EntriesCreateWithoutProjectInput;
};

export type Time_EntriesUpdateWithWhereUniqueWithoutProjectInput = {
  where: Time_EntriesWhereUniqueInput;
  data: Time_EntriesUpdateWithoutProjectInput;
};

export type Time_EntriesUpdateManyWithWhereWithoutProjectInput = {
  where: Time_EntriesScalarWhereInput;
  data: Time_EntriesUpdateManyMutationInput;
};

export type Time_EntriesScalarWhereInput = {
  AND?: Maybe<Array<Time_EntriesScalarWhereInput>>;
  OR?: Maybe<Array<Time_EntriesScalarWhereInput>>;
  NOT?: Maybe<Array<Time_EntriesScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  minutes?: Maybe<IntFilter>;
  billable?: Maybe<BoolFilter>;
  company_id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  user_id?: Maybe<StringFilter>;
  project_tag_id?: Maybe<StringNullableFilter>;
  tasklist_id?: Maybe<IntNullableFilter>;
};

export type PodUpsertWithoutIncoming_Split_ProjectsInput = {
  update: PodUpdateWithoutIncoming_Split_ProjectsInput;
  create: PodCreateWithoutIncoming_Split_ProjectsInput;
};

export type PodUpdateWithoutIncoming_Split_ProjectsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetUpdateManyWithoutPodInput>;
  members?: Maybe<PersonUpdateManyWithoutPodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastUpdateManyWithoutPodInput>;
};

export type Project_RateUpsertWithWhereUniqueWithoutProjectInput = {
  where: Project_RateWhereUniqueInput;
  update: Project_RateUpdateWithoutProjectInput;
  create: Project_RateCreateWithoutProjectInput;
};

export type Project_RateUpdateWithWhereUniqueWithoutProjectInput = {
  where: Project_RateWhereUniqueInput;
  data: Project_RateUpdateWithoutProjectInput;
};

export type Project_RateUpdateManyWithWhereWithoutProjectInput = {
  where: Project_RateScalarWhereInput;
  data: Project_RateUpdateManyMutationInput;
};

export type Project_RateScalarWhereInput = {
  AND?: Maybe<Array<Project_RateScalarWhereInput>>;
  OR?: Maybe<Array<Project_RateScalarWhereInput>>;
  NOT?: Maybe<Array<Project_RateScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  person_id?: Maybe<StringFilter>;
  rate_in_cents?: Maybe<IntFilter>;
  source?: Maybe<Enumproject_Rate_SourceFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  created_at?: Maybe<DateTimeFilter>;
};

export type ProjectPricingUpsertWithoutProjectInput = {
  update: ProjectPricingUpdateWithoutProjectInput;
  create: ProjectPricingCreateWithoutProjectInput;
};

export type ProjectPricingUpdateWithoutProjectInput = {
  price?: Maybe<IntFieldUpdateOperationsInput>;
  actual_cost?: Maybe<IntFieldUpdateOperationsInput>;
  currency?: Maybe<StringFieldUpdateOperationsInput>;
  projected_times?: Maybe<ProjectedTimeUpdateManyWithoutProject_PricingInput>;
  projected_expenses?: Maybe<ProjectedExpenseUpdateManyWithoutProject_PricingInput>;
  comments?: Maybe<PricingCommentUpdateManyWithoutProject_PricingInput>;
};

export type PodAnnualRevenueTargetCreateWithoutPodInput = {
  year: Scalars['Int'];
  revenue_target_dollars?: Maybe<Scalars['Int']>;
};

export type PodAnnualRevenueTargetCreateOrConnectWithoutPodInput = {
  where: PodAnnualRevenueTargetWhereUniqueInput;
  create: PodAnnualRevenueTargetCreateWithoutPodInput;
};

export type PodAnnualRevenueTargetCreateManyPodInputEnvelope = {
  data?: Maybe<Array<PodAnnualRevenueTargetCreateManyPodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PodAnnualRevenueTargetWhereUniqueInput = {
  year_podId?: Maybe<PodAnnualRevenueTargetYearPodIdCompoundUniqueInput>;
};

export type PersonCreateWithoutPodInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutPodInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutPodInput;
};

export type ProjectCreateWithoutSales_Split_PodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutSales_Split_PodInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutSales_Split_PodInput;
};

export type ProjectCreateManySales_Split_PodInputEnvelope = {
  data?: Maybe<Array<ProjectCreateManySales_Split_PodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientAnnualRevenueForecastCreateWithoutPodInput = {
  id?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  low_forecast_dollars: Scalars['Int'];
  high_forecast_dollars: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by_user_id: Scalars['String'];
  client: ClientCreateNestedOneWithoutForecastsInput;
};

export type ClientAnnualRevenueForecastCreateOrConnectWithoutPodInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  create: ClientAnnualRevenueForecastCreateWithoutPodInput;
};

export type ClientAnnualRevenueForecastCreateManyPodInputEnvelope = {
  data?: Maybe<Array<ClientAnnualRevenueForecastCreateManyPodInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientAnnualRevenueForecastWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  year_client_id_pod_id?: Maybe<ClientAnnualRevenueForecastYearClient_IdPod_IdCompoundUniqueInput>;
};

export type PodAnnualRevenueTargetUpsertWithWhereUniqueWithoutPodInput = {
  where: PodAnnualRevenueTargetWhereUniqueInput;
  update: PodAnnualRevenueTargetUpdateWithoutPodInput;
  create: PodAnnualRevenueTargetCreateWithoutPodInput;
};

export type PodAnnualRevenueTargetUpdateWithWhereUniqueWithoutPodInput = {
  where: PodAnnualRevenueTargetWhereUniqueInput;
  data: PodAnnualRevenueTargetUpdateWithoutPodInput;
};

export type PodAnnualRevenueTargetUpdateManyWithWhereWithoutPodInput = {
  where: PodAnnualRevenueTargetScalarWhereInput;
  data: PodAnnualRevenueTargetUpdateManyMutationInput;
};

export type PodAnnualRevenueTargetScalarWhereInput = {
  AND?: Maybe<Array<PodAnnualRevenueTargetScalarWhereInput>>;
  OR?: Maybe<Array<PodAnnualRevenueTargetScalarWhereInput>>;
  NOT?: Maybe<Array<PodAnnualRevenueTargetScalarWhereInput>>;
  year?: Maybe<IntFilter>;
  revenue_target_dollars?: Maybe<IntNullableFilter>;
  podId?: Maybe<StringFilter>;
};

export type PersonUpsertWithWhereUniqueWithoutPodInput = {
  where: PersonWhereUniqueInput;
  update: PersonUpdateWithoutPodInput;
  create: PersonCreateWithoutPodInput;
};

export type PersonUpdateWithWhereUniqueWithoutPodInput = {
  where: PersonWhereUniqueInput;
  data: PersonUpdateWithoutPodInput;
};

export type PersonUpdateManyWithWhereWithoutPodInput = {
  where: PersonScalarWhereInput;
  data: PersonUpdateManyMutationInput;
};

export type PersonScalarWhereInput = {
  AND?: Maybe<Array<PersonScalarWhereInput>>;
  OR?: Maybe<Array<PersonScalarWhereInput>>;
  NOT?: Maybe<Array<PersonScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  tw_username?: Maybe<StringNullableFilter>;
  email_address?: Maybe<StringFilter>;
  avatar_url?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeNullableFilter>;
  first_name?: Maybe<StringFilter>;
  last_name?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  company?: Maybe<StringFilter>;
  rate?: Maybe<IntFilter>;
  is_deleted_on_tw?: Maybe<BoolFilter>;
  roleId?: Maybe<StringNullableFilter>;
  teamId?: Maybe<StringNullableFilter>;
};

export type ProjectUpsertWithWhereUniqueWithoutSales_Split_PodInput = {
  where: ProjectWhereUniqueInput;
  update: ProjectUpdateWithoutSales_Split_PodInput;
  create: ProjectCreateWithoutSales_Split_PodInput;
};

export type ProjectUpdateWithWhereUniqueWithoutSales_Split_PodInput = {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateWithoutSales_Split_PodInput;
};

export type ProjectUpdateManyWithWhereWithoutSales_Split_PodInput = {
  where: ProjectScalarWhereInput;
  data: ProjectUpdateManyMutationInput;
};

export type ClientAnnualRevenueForecastUpsertWithWhereUniqueWithoutPodInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  update: ClientAnnualRevenueForecastUpdateWithoutPodInput;
  create: ClientAnnualRevenueForecastCreateWithoutPodInput;
};

export type ClientAnnualRevenueForecastUpdateWithWhereUniqueWithoutPodInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  data: ClientAnnualRevenueForecastUpdateWithoutPodInput;
};

export type ClientAnnualRevenueForecastUpdateManyWithWhereWithoutPodInput = {
  where: ClientAnnualRevenueForecastScalarWhereInput;
  data: ClientAnnualRevenueForecastUpdateManyMutationInput;
};

export type ClientAnnualRevenueForecastScalarWhereInput = {
  AND?: Maybe<Array<ClientAnnualRevenueForecastScalarWhereInput>>;
  OR?: Maybe<Array<ClientAnnualRevenueForecastScalarWhereInput>>;
  NOT?: Maybe<Array<ClientAnnualRevenueForecastScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
  low_forecast_dollars?: Maybe<IntFilter>;
  high_forecast_dollars?: Maybe<IntFilter>;
  client_id?: Maybe<StringFilter>;
  pod_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  updated_by_user_id?: Maybe<StringFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedEnumproject_TypeNullableFilter = {
  equals?: Maybe<Project_Type>;
  in?: Maybe<Array<Project_Type>>;
  notIn?: Maybe<Array<Project_Type>>;
  not?: Maybe<NestedEnumproject_TypeNullableFilter>;
};

export type NestedEnumproject_LanguageNullableFilter = {
  equals?: Maybe<Project_Language>;
  in?: Maybe<Array<Project_Language>>;
  notIn?: Maybe<Array<Project_Language>>;
  not?: Maybe<NestedEnumproject_LanguageNullableFilter>;
};

export type NestedEnumexternal_ReviewerNullableFilter = {
  equals?: Maybe<External_Reviewer>;
  in?: Maybe<Array<External_Reviewer>>;
  notIn?: Maybe<Array<External_Reviewer>>;
  not?: Maybe<NestedEnumexternal_ReviewerNullableFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type ClientAnnualRevenueForecastListRelationFilter = {
  every?: Maybe<ClientAnnualRevenueForecastWhereInput>;
  some?: Maybe<ClientAnnualRevenueForecastWhereInput>;
  none?: Maybe<ClientAnnualRevenueForecastWhereInput>;
};

export type PodListRelationFilter = {
  every?: Maybe<PodWhereInput>;
  some?: Maybe<PodWhereInput>;
  none?: Maybe<PodWhereInput>;
};

export type Day_OffListRelationFilter = {
  every?: Maybe<Day_OffWhereInput>;
  some?: Maybe<Day_OffWhereInput>;
  none?: Maybe<Day_OffWhereInput>;
};

export type PricingCommentListRelationFilter = {
  every?: Maybe<PricingCommentWhereInput>;
  some?: Maybe<PricingCommentWhereInput>;
  none?: Maybe<PricingCommentWhereInput>;
};

export type PersonAnnualTimeTargetListRelationFilter = {
  every?: Maybe<PersonAnnualTimeTargetWhereInput>;
  some?: Maybe<PersonAnnualTimeTargetWhereInput>;
  none?: Maybe<PersonAnnualTimeTargetWhereInput>;
};

export type RoleWhereInput = {
  AND?: Maybe<Array<RoleWhereInput>>;
  OR?: Maybe<Array<RoleWhereInput>>;
  NOT?: Maybe<Array<RoleWhereInput>>;
  id?: Maybe<StringFilter>;
  roleTitle?: Maybe<StringFilter>;
  persons?: Maybe<PersonListRelationFilter>;
};

export type TeamWhereInput = {
  AND?: Maybe<Array<TeamWhereInput>>;
  OR?: Maybe<Array<TeamWhereInput>>;
  NOT?: Maybe<Array<TeamWhereInput>>;
  id?: Maybe<StringFilter>;
  teamName?: Maybe<StringFilter>;
  persons?: Maybe<PersonListRelationFilter>;
};

export type Time_EntriesWhereInput = {
  AND?: Maybe<Array<Time_EntriesWhereInput>>;
  OR?: Maybe<Array<Time_EntriesWhereInput>>;
  NOT?: Maybe<Array<Time_EntriesWhereInput>>;
  id?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  minutes?: Maybe<IntFilter>;
  billable?: Maybe<BoolFilter>;
  company_id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  user_id?: Maybe<StringFilter>;
  project_tag_id?: Maybe<StringNullableFilter>;
  tasklist_id?: Maybe<IntNullableFilter>;
  project?: Maybe<ProjectWhereInput>;
  person?: Maybe<PersonWhereInput>;
};

export type PodAnnualRevenueTargetListRelationFilter = {
  every?: Maybe<PodAnnualRevenueTargetWhereInput>;
  some?: Maybe<PodAnnualRevenueTargetWhereInput>;
  none?: Maybe<PodAnnualRevenueTargetWhereInput>;
};

export type PersonListRelationFilter = {
  every?: Maybe<PersonWhereInput>;
  some?: Maybe<PersonWhereInput>;
  none?: Maybe<PersonWhereInput>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type Project_RateWhereInput = {
  AND?: Maybe<Array<Project_RateWhereInput>>;
  OR?: Maybe<Array<Project_RateWhereInput>>;
  NOT?: Maybe<Array<Project_RateWhereInput>>;
  id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  person_id?: Maybe<StringFilter>;
  project?: Maybe<ProjectWhereInput>;
  person?: Maybe<PersonWhereInput>;
  rate_in_cents?: Maybe<IntFilter>;
  source?: Maybe<Enumproject_Rate_SourceFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  created_at?: Maybe<DateTimeFilter>;
};

export type ProjectedTimeListRelationFilter = {
  every?: Maybe<ProjectedTimeWhereInput>;
  some?: Maybe<ProjectedTimeWhereInput>;
  none?: Maybe<ProjectedTimeWhereInput>;
};

export type ProjectedExpenseListRelationFilter = {
  every?: Maybe<ProjectedExpenseWhereInput>;
  some?: Maybe<ProjectedExpenseWhereInput>;
  none?: Maybe<ProjectedExpenseWhereInput>;
};

export type ClientAnnualRevenueForecastOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PodOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type Day_OffOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PricingCommentOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type Supplier_Invoice_FilesOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PersonAnnualTimeTargetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type RoleOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  roleTitle?: Maybe<SortOrder>;
  persons?: Maybe<PersonOrderByRelationAggregateInput>;
};

export type TeamOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  teamName?: Maybe<SortOrder>;
  persons?: Maybe<PersonOrderByRelationAggregateInput>;
};

export type PodAnnualRevenueTargetOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type PersonOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProjectedTimeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type ProjectedExpenseOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
};

export type Supplier_Invoice_FilesWhereInput = {
  AND?: Maybe<Array<Supplier_Invoice_FilesWhereInput>>;
  OR?: Maybe<Array<Supplier_Invoice_FilesWhereInput>>;
  NOT?: Maybe<Array<Supplier_Invoice_FilesWhereInput>>;
  id?: Maybe<StringFilter>;
  content_type?: Maybe<StringFilter>;
  file_name?: Maybe<StringFilter>;
  created_by?: Maybe<PersonWhereInput>;
  created_by_id?: Maybe<StringFilter>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoice_id?: Maybe<StringFilter>;
};

export type NestedEnumInvoiceStatusNullableFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusNullableFilter>;
};

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type EstimateCreateNestedManyWithoutOwnerInput = {
  create?: Maybe<Array<EstimateCreateWithoutOwnerInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutOwnerInput>>;
  createMany?: Maybe<EstimateCreateManyOwnerInputEnvelope>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
};

export type ProjectCreateNestedManyWithoutOwnerInput = {
  create?: Maybe<Array<ProjectCreateWithoutOwnerInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutOwnerInput>>;
  createMany?: Maybe<ProjectCreateManyOwnerInputEnvelope>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
};

export type Time_EntriesCreateNestedManyWithoutPersonInput = {
  create?: Maybe<Array<Time_EntriesCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Time_EntriesCreateOrConnectWithoutPersonInput>>;
  createMany?: Maybe<Time_EntriesCreateManyPersonInputEnvelope>;
  connect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
};

export type PodCreateNestedManyWithoutMembersInput = {
  create?: Maybe<Array<PodCreateWithoutMembersInput>>;
  connectOrCreate?: Maybe<Array<PodCreateOrConnectWithoutMembersInput>>;
  connect?: Maybe<Array<PodWhereUniqueInput>>;
};

export type Day_OffCreateNestedManyWithoutPersonInput = {
  create?: Maybe<Array<Day_OffCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Day_OffCreateOrConnectWithoutPersonInput>>;
  createMany?: Maybe<Day_OffCreateManyPersonInputEnvelope>;
  connect?: Maybe<Array<Day_OffWhereUniqueInput>>;
};

export type PricingCommentCreateNestedManyWithoutCreated_ByInput = {
  create?: Maybe<Array<PricingCommentCreateWithoutCreated_ByInput>>;
  connectOrCreate?: Maybe<Array<PricingCommentCreateOrConnectWithoutCreated_ByInput>>;
  createMany?: Maybe<PricingCommentCreateManyCreated_ByInputEnvelope>;
  connect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
};

export type Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput = {
  create?: Maybe<Array<Supplier_Invoice_FilesCreateWithoutCreated_ByInput>>;
  connectOrCreate?: Maybe<Array<Supplier_Invoice_FilesCreateOrConnectWithoutCreated_ByInput>>;
  createMany?: Maybe<Supplier_Invoice_FilesCreateManyCreated_ByInputEnvelope>;
  connect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
};

export type PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput = {
  create?: Maybe<Array<PersonAnnualTimeTargetCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<PersonAnnualTimeTargetCreateOrConnectWithoutPersonInput>>;
  createMany?: Maybe<PersonAnnualTimeTargetCreateManyPersonInputEnvelope>;
  connect?: Maybe<Array<PersonAnnualTimeTargetWhereUniqueInput>>;
};

export type Project_RateCreateNestedManyWithoutPersonInput = {
  create?: Maybe<Array<Project_RateCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Project_RateCreateOrConnectWithoutPersonInput>>;
  createMany?: Maybe<Project_RateCreateManyPersonInputEnvelope>;
  connect?: Maybe<Array<Project_RateWhereUniqueInput>>;
};

export type RoleCreateNestedOneWithoutPersonsInput = {
  create?: Maybe<RoleCreateWithoutPersonsInput>;
  connectOrCreate?: Maybe<RoleCreateOrConnectWithoutPersonsInput>;
  connect?: Maybe<RoleWhereUniqueInput>;
};

export type TeamCreateNestedOneWithoutPersonsInput = {
  create?: Maybe<TeamCreateWithoutPersonsInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutPersonsInput>;
  connect?: Maybe<TeamWhereUniqueInput>;
};

export type PersonCreateNestedOneWithoutUploaded_Supplier_InvoicesInput = {
  create?: Maybe<PersonCreateWithoutUploaded_Supplier_InvoicesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutUploaded_Supplier_InvoicesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type Supplier_Invoice_FilesCreateManyInvoiceInput = {
  id?: Maybe<Scalars['String']>;
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  created_by_id: Scalars['String'];
};

export type EstimateUpdateManyWithoutOwnerInput = {
  create?: Maybe<Array<EstimateCreateWithoutOwnerInput>>;
  connectOrCreate?: Maybe<Array<EstimateCreateOrConnectWithoutOwnerInput>>;
  upsert?: Maybe<Array<EstimateUpsertWithWhereUniqueWithoutOwnerInput>>;
  createMany?: Maybe<EstimateCreateManyOwnerInputEnvelope>;
  set?: Maybe<Array<EstimateWhereUniqueInput>>;
  disconnect?: Maybe<Array<EstimateWhereUniqueInput>>;
  delete?: Maybe<Array<EstimateWhereUniqueInput>>;
  connect?: Maybe<Array<EstimateWhereUniqueInput>>;
  update?: Maybe<Array<EstimateUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: Maybe<Array<EstimateUpdateManyWithWhereWithoutOwnerInput>>;
  deleteMany?: Maybe<Array<EstimateScalarWhereInput>>;
};

export type ProjectUpdateManyWithoutOwnerInput = {
  create?: Maybe<Array<ProjectCreateWithoutOwnerInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutOwnerInput>>;
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutOwnerInput>>;
  createMany?: Maybe<ProjectCreateManyOwnerInputEnvelope>;
  set?: Maybe<Array<ProjectWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereWithoutOwnerInput>>;
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>;
};

export type Time_EntriesUpdateManyWithoutPersonInput = {
  create?: Maybe<Array<Time_EntriesCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Time_EntriesCreateOrConnectWithoutPersonInput>>;
  upsert?: Maybe<Array<Time_EntriesUpsertWithWhereUniqueWithoutPersonInput>>;
  createMany?: Maybe<Time_EntriesCreateManyPersonInputEnvelope>;
  set?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  disconnect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  delete?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  connect?: Maybe<Array<Time_EntriesWhereUniqueInput>>;
  update?: Maybe<Array<Time_EntriesUpdateWithWhereUniqueWithoutPersonInput>>;
  updateMany?: Maybe<Array<Time_EntriesUpdateManyWithWhereWithoutPersonInput>>;
  deleteMany?: Maybe<Array<Time_EntriesScalarWhereInput>>;
};

export type PodUpdateManyWithoutMembersInput = {
  create?: Maybe<Array<PodCreateWithoutMembersInput>>;
  connectOrCreate?: Maybe<Array<PodCreateOrConnectWithoutMembersInput>>;
  upsert?: Maybe<Array<PodUpsertWithWhereUniqueWithoutMembersInput>>;
  set?: Maybe<Array<PodWhereUniqueInput>>;
  disconnect?: Maybe<Array<PodWhereUniqueInput>>;
  delete?: Maybe<Array<PodWhereUniqueInput>>;
  connect?: Maybe<Array<PodWhereUniqueInput>>;
  update?: Maybe<Array<PodUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: Maybe<Array<PodUpdateManyWithWhereWithoutMembersInput>>;
  deleteMany?: Maybe<Array<PodScalarWhereInput>>;
};

export type Day_OffUpdateManyWithoutPersonInput = {
  create?: Maybe<Array<Day_OffCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Day_OffCreateOrConnectWithoutPersonInput>>;
  upsert?: Maybe<Array<Day_OffUpsertWithWhereUniqueWithoutPersonInput>>;
  createMany?: Maybe<Day_OffCreateManyPersonInputEnvelope>;
  set?: Maybe<Array<Day_OffWhereUniqueInput>>;
  disconnect?: Maybe<Array<Day_OffWhereUniqueInput>>;
  delete?: Maybe<Array<Day_OffWhereUniqueInput>>;
  connect?: Maybe<Array<Day_OffWhereUniqueInput>>;
  update?: Maybe<Array<Day_OffUpdateWithWhereUniqueWithoutPersonInput>>;
  updateMany?: Maybe<Array<Day_OffUpdateManyWithWhereWithoutPersonInput>>;
  deleteMany?: Maybe<Array<Day_OffScalarWhereInput>>;
};

export type PricingCommentUpdateManyWithoutCreated_ByInput = {
  create?: Maybe<Array<PricingCommentCreateWithoutCreated_ByInput>>;
  connectOrCreate?: Maybe<Array<PricingCommentCreateOrConnectWithoutCreated_ByInput>>;
  upsert?: Maybe<Array<PricingCommentUpsertWithWhereUniqueWithoutCreated_ByInput>>;
  createMany?: Maybe<PricingCommentCreateManyCreated_ByInputEnvelope>;
  set?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  delete?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  connect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  update?: Maybe<Array<PricingCommentUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: Maybe<Array<PricingCommentUpdateManyWithWhereWithoutCreated_ByInput>>;
  deleteMany?: Maybe<Array<PricingCommentScalarWhereInput>>;
};

export type Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput = {
  create?: Maybe<Array<Supplier_Invoice_FilesCreateWithoutCreated_ByInput>>;
  connectOrCreate?: Maybe<Array<Supplier_Invoice_FilesCreateOrConnectWithoutCreated_ByInput>>;
  upsert?: Maybe<Array<Supplier_Invoice_FilesUpsertWithWhereUniqueWithoutCreated_ByInput>>;
  createMany?: Maybe<Supplier_Invoice_FilesCreateManyCreated_ByInputEnvelope>;
  set?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  disconnect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  delete?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  connect?: Maybe<Array<Supplier_Invoice_FilesWhereUniqueInput>>;
  update?: Maybe<Array<Supplier_Invoice_FilesUpdateWithWhereUniqueWithoutCreated_ByInput>>;
  updateMany?: Maybe<Array<Supplier_Invoice_FilesUpdateManyWithWhereWithoutCreated_ByInput>>;
  deleteMany?: Maybe<Array<Supplier_Invoice_FilesScalarWhereInput>>;
};

export type PersonAnnualTimeTargetUpdateManyWithoutPersonInput = {
  create?: Maybe<Array<PersonAnnualTimeTargetCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<PersonAnnualTimeTargetCreateOrConnectWithoutPersonInput>>;
  upsert?: Maybe<Array<PersonAnnualTimeTargetUpsertWithWhereUniqueWithoutPersonInput>>;
  createMany?: Maybe<PersonAnnualTimeTargetCreateManyPersonInputEnvelope>;
  set?: Maybe<Array<PersonAnnualTimeTargetWhereUniqueInput>>;
  disconnect?: Maybe<Array<PersonAnnualTimeTargetWhereUniqueInput>>;
  delete?: Maybe<Array<PersonAnnualTimeTargetWhereUniqueInput>>;
  connect?: Maybe<Array<PersonAnnualTimeTargetWhereUniqueInput>>;
  update?: Maybe<Array<PersonAnnualTimeTargetUpdateWithWhereUniqueWithoutPersonInput>>;
  updateMany?: Maybe<Array<PersonAnnualTimeTargetUpdateManyWithWhereWithoutPersonInput>>;
  deleteMany?: Maybe<Array<PersonAnnualTimeTargetScalarWhereInput>>;
};

export type Project_RateUpdateManyWithoutPersonInput = {
  create?: Maybe<Array<Project_RateCreateWithoutPersonInput>>;
  connectOrCreate?: Maybe<Array<Project_RateCreateOrConnectWithoutPersonInput>>;
  upsert?: Maybe<Array<Project_RateUpsertWithWhereUniqueWithoutPersonInput>>;
  createMany?: Maybe<Project_RateCreateManyPersonInputEnvelope>;
  set?: Maybe<Array<Project_RateWhereUniqueInput>>;
  disconnect?: Maybe<Array<Project_RateWhereUniqueInput>>;
  delete?: Maybe<Array<Project_RateWhereUniqueInput>>;
  connect?: Maybe<Array<Project_RateWhereUniqueInput>>;
  update?: Maybe<Array<Project_RateUpdateWithWhereUniqueWithoutPersonInput>>;
  updateMany?: Maybe<Array<Project_RateUpdateManyWithWhereWithoutPersonInput>>;
  deleteMany?: Maybe<Array<Project_RateScalarWhereInput>>;
};

export type RoleUpdateOneWithoutPersonsInput = {
  create?: Maybe<RoleCreateWithoutPersonsInput>;
  connectOrCreate?: Maybe<RoleCreateOrConnectWithoutPersonsInput>;
  upsert?: Maybe<RoleUpsertWithoutPersonsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<RoleWhereUniqueInput>;
  update?: Maybe<RoleUpdateWithoutPersonsInput>;
};

export type TeamUpdateOneWithoutPersonsInput = {
  create?: Maybe<TeamCreateWithoutPersonsInput>;
  connectOrCreate?: Maybe<TeamCreateOrConnectWithoutPersonsInput>;
  upsert?: Maybe<TeamUpsertWithoutPersonsInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  connect?: Maybe<TeamWhereUniqueInput>;
  update?: Maybe<TeamUpdateWithoutPersonsInput>;
};

export type Supplier_Invoice_FilesUpdateWithoutInvoiceInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  content_type?: Maybe<StringFieldUpdateOperationsInput>;
  file_name?: Maybe<StringFieldUpdateOperationsInput>;
  created_by?: Maybe<PersonUpdateOneRequiredWithoutUploaded_Supplier_InvoicesInput>;
};

export type Supplier_Invoice_FilesUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  content_type?: Maybe<StringFieldUpdateOperationsInput>;
  file_name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type EstimateCreateManyBrandInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  owner_id: Scalars['String'];
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  company_id: Scalars['String'];
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
};

export type EstimateUpdateWithoutBrandInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutEstimatesInput>;
  owner?: Maybe<PersonUpdateOneRequiredWithoutEstimatesInput>;
  client_invoices?: Maybe<Client_InvoiceUpdateManyWithoutEstimateInput>;
  projects?: Maybe<ProjectUpdateManyWithoutAssociated_EstimateInput>;
};

export type EstimateUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type BrandCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  brand_name: Scalars['String'];
};

export type EstimateCreateManyCompanyInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  owner_id: Scalars['String'];
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  brand_id: Scalars['String'];
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
};

export type BrandUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  brand_name?: Maybe<StringFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutBrandInput>;
};

export type BrandUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  brand_name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type EstimateUpdateWithoutCompanyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutEstimatesInput>;
  owner?: Maybe<PersonUpdateOneRequiredWithoutEstimatesInput>;
  client_invoices?: Maybe<Client_InvoiceUpdateManyWithoutEstimateInput>;
  projects?: Maybe<ProjectUpdateManyWithoutAssociated_EstimateInput>;
};

export type InvoiceCreateNestedManyWithoutContractorInput = {
  create?: Maybe<Array<InvoiceCreateWithoutContractorInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutContractorInput>>;
  createMany?: Maybe<InvoiceCreateManyContractorInputEnvelope>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type Client_InvoiceCreateManyEstimateInput = {
  id?: Maybe<Scalars['String']>;
  amount_in_cents?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  date_invoiced?: Maybe<Scalars['DateTime']>;
  date_deposit_expected?: Maybe<Scalars['DateTime']>;
  date_deposited?: Maybe<Scalars['DateTime']>;
  invoice_number?: Maybe<Scalars['String']>;
  quickbooks_id?: Maybe<Scalars['String']>;
  invoice_status?: Maybe<InvoiceStatus>;
};

export type ProjectCreateManyAssociated_EstimateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  project_owner_id?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  client_id: Scalars['String'];
  component_code?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  sales_split_pod_id?: Maybe<Scalars['String']>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  epoch?: Maybe<Scalars['Int']>;
};

export type InvoiceUpdateManyWithoutContractorInput = {
  create?: Maybe<Array<InvoiceCreateWithoutContractorInput>>;
  connectOrCreate?: Maybe<Array<InvoiceCreateOrConnectWithoutContractorInput>>;
  upsert?: Maybe<Array<InvoiceUpsertWithWhereUniqueWithoutContractorInput>>;
  createMany?: Maybe<InvoiceCreateManyContractorInputEnvelope>;
  set?: Maybe<Array<InvoiceWhereUniqueInput>>;
  disconnect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  delete?: Maybe<Array<InvoiceWhereUniqueInput>>;
  connect?: Maybe<Array<InvoiceWhereUniqueInput>>;
  update?: Maybe<Array<InvoiceUpdateWithWhereUniqueWithoutContractorInput>>;
  updateMany?: Maybe<Array<InvoiceUpdateManyWithWhereWithoutContractorInput>>;
  deleteMany?: Maybe<Array<InvoiceScalarWhereInput>>;
};

export type Client_InvoiceUpdateWithoutEstimateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_invoiced?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposit_expected?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposited?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoice_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quickbooks_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoice_status?: Maybe<NullableEnumInvoiceStatusFieldUpdateOperationsInput>;
};

export type Client_InvoiceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  date_invoiced?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposit_expected?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  date_deposited?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  invoice_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  quickbooks_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  invoice_status?: Maybe<NullableEnumInvoiceStatusFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutAssociated_EstimateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProjectUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ClientAnnualRevenueForecastCreateNestedManyWithoutClientInput = {
  create?: Maybe<Array<ClientAnnualRevenueForecastCreateWithoutClientInput>>;
  connectOrCreate?: Maybe<Array<ClientAnnualRevenueForecastCreateOrConnectWithoutClientInput>>;
  createMany?: Maybe<ClientAnnualRevenueForecastCreateManyClientInputEnvelope>;
  connect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
};

export type InvoiceCreateManyProjectInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  contractor_id?: Maybe<Scalars['String']>;
};

export type PersonCreateNestedOneWithoutTime_EntriesInput = {
  create?: Maybe<PersonCreateWithoutTime_EntriesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutTime_EntriesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type Time_EntriesCreateManyProjectInput = {
  id: Scalars['String'];
  date: Scalars['DateTime'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
  company_id?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
  project_tag_id?: Maybe<Scalars['String']>;
  tasklist_id?: Maybe<Scalars['Int']>;
};

export type PersonCreateNestedOneWithoutProject_RatesInput = {
  create?: Maybe<PersonCreateWithoutProject_RatesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutProject_RatesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export enum Project_Rate_Source {
  Installationrate = 'installationrate',
  Userprojectrate = 'userprojectrate',
  Projectrate = 'projectrate'
}

export type Project_RateCreateManyProjectInput = {
  id?: Maybe<Scalars['String']>;
  person_id: Scalars['String'];
  rate_in_cents: Scalars['Int'];
  source: Project_Rate_Source;
  updated_at: Scalars['DateTime'];
  created_at?: Maybe<Scalars['DateTime']>;
};

export type Project_RateProject_IdPerson_IdCompoundUniqueInput = {
  project_id: Scalars['String'];
  person_id: Scalars['String'];
};

export type ProjectedTimeCreateNestedManyWithoutProject_PricingInput = {
  create?: Maybe<Array<ProjectedTimeCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<ProjectedTimeCreateOrConnectWithoutProject_PricingInput>>;
  createMany?: Maybe<ProjectedTimeCreateManyProject_PricingInputEnvelope>;
  connect?: Maybe<Array<ProjectedTimeWhereUniqueInput>>;
};

export type ProjectedExpenseCreateNestedManyWithoutProject_PricingInput = {
  create?: Maybe<Array<ProjectedExpenseCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<ProjectedExpenseCreateOrConnectWithoutProject_PricingInput>>;
  createMany?: Maybe<ProjectedExpenseCreateManyProject_PricingInputEnvelope>;
  connect?: Maybe<Array<ProjectedExpenseWhereUniqueInput>>;
};

export type PricingCommentCreateNestedManyWithoutProject_PricingInput = {
  create?: Maybe<Array<PricingCommentCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<PricingCommentCreateOrConnectWithoutProject_PricingInput>>;
  createMany?: Maybe<PricingCommentCreateManyProject_PricingInputEnvelope>;
  connect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
};

export type ClientAnnualRevenueForecastUpdateManyWithoutClientInput = {
  create?: Maybe<Array<ClientAnnualRevenueForecastCreateWithoutClientInput>>;
  connectOrCreate?: Maybe<Array<ClientAnnualRevenueForecastCreateOrConnectWithoutClientInput>>;
  upsert?: Maybe<Array<ClientAnnualRevenueForecastUpsertWithWhereUniqueWithoutClientInput>>;
  createMany?: Maybe<ClientAnnualRevenueForecastCreateManyClientInputEnvelope>;
  set?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  disconnect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  delete?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  connect?: Maybe<Array<ClientAnnualRevenueForecastWhereUniqueInput>>;
  update?: Maybe<Array<ClientAnnualRevenueForecastUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ClientAnnualRevenueForecastUpdateManyWithWhereWithoutClientInput>>;
  deleteMany?: Maybe<Array<ClientAnnualRevenueForecastScalarWhereInput>>;
};

export type InvoiceUpdateWithoutProjectInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoice_number?: Maybe<StringFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  date_created?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  payee_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contractor?: Maybe<PersonUpdateOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutInvoiceInput>;
};

export type InvoiceUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoice_number?: Maybe<StringFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  date_created?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  payee_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type Time_EntriesUpdateWithoutProjectInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  minutes?: Maybe<IntFieldUpdateOperationsInput>;
  billable?: Maybe<BoolFieldUpdateOperationsInput>;
  company_id?: Maybe<StringFieldUpdateOperationsInput>;
  project_tag_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutTime_EntriesInput>;
};

export type Time_EntriesUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  minutes?: Maybe<IntFieldUpdateOperationsInput>;
  billable?: Maybe<BoolFieldUpdateOperationsInput>;
  company_id?: Maybe<StringFieldUpdateOperationsInput>;
  project_tag_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type Project_RateUpdateWithoutProjectInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  person?: Maybe<PersonUpdateOneRequiredWithoutProject_RatesInput>;
  rate_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  source?: Maybe<Enumproject_Rate_SourceFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type Project_RateUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  rate_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  source?: Maybe<Enumproject_Rate_SourceFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type Enumproject_Rate_SourceFilter = {
  equals?: Maybe<Project_Rate_Source>;
  in?: Maybe<Array<Project_Rate_Source>>;
  notIn?: Maybe<Array<Project_Rate_Source>>;
  not?: Maybe<NestedEnumproject_Rate_SourceFilter>;
};

export type ProjectedTimeUpdateManyWithoutProject_PricingInput = {
  create?: Maybe<Array<ProjectedTimeCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<ProjectedTimeCreateOrConnectWithoutProject_PricingInput>>;
  upsert?: Maybe<Array<ProjectedTimeUpsertWithWhereUniqueWithoutProject_PricingInput>>;
  createMany?: Maybe<ProjectedTimeCreateManyProject_PricingInputEnvelope>;
  set?: Maybe<Array<ProjectedTimeWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectedTimeWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectedTimeWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectedTimeWhereUniqueInput>>;
  update?: Maybe<Array<ProjectedTimeUpdateWithWhereUniqueWithoutProject_PricingInput>>;
  updateMany?: Maybe<Array<ProjectedTimeUpdateManyWithWhereWithoutProject_PricingInput>>;
  deleteMany?: Maybe<Array<ProjectedTimeScalarWhereInput>>;
};

export type ProjectedExpenseUpdateManyWithoutProject_PricingInput = {
  create?: Maybe<Array<ProjectedExpenseCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<ProjectedExpenseCreateOrConnectWithoutProject_PricingInput>>;
  upsert?: Maybe<Array<ProjectedExpenseUpsertWithWhereUniqueWithoutProject_PricingInput>>;
  createMany?: Maybe<ProjectedExpenseCreateManyProject_PricingInputEnvelope>;
  set?: Maybe<Array<ProjectedExpenseWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectedExpenseWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectedExpenseWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectedExpenseWhereUniqueInput>>;
  update?: Maybe<Array<ProjectedExpenseUpdateWithWhereUniqueWithoutProject_PricingInput>>;
  updateMany?: Maybe<Array<ProjectedExpenseUpdateManyWithWhereWithoutProject_PricingInput>>;
  deleteMany?: Maybe<Array<ProjectedExpenseScalarWhereInput>>;
};

export type PricingCommentUpdateManyWithoutProject_PricingInput = {
  create?: Maybe<Array<PricingCommentCreateWithoutProject_PricingInput>>;
  connectOrCreate?: Maybe<Array<PricingCommentCreateOrConnectWithoutProject_PricingInput>>;
  upsert?: Maybe<Array<PricingCommentUpsertWithWhereUniqueWithoutProject_PricingInput>>;
  createMany?: Maybe<PricingCommentCreateManyProject_PricingInputEnvelope>;
  set?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  disconnect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  delete?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  connect?: Maybe<Array<PricingCommentWhereUniqueInput>>;
  update?: Maybe<Array<PricingCommentUpdateWithWhereUniqueWithoutProject_PricingInput>>;
  updateMany?: Maybe<Array<PricingCommentUpdateManyWithWhereWithoutProject_PricingInput>>;
  deleteMany?: Maybe<Array<PricingCommentScalarWhereInput>>;
};

export type PodAnnualRevenueTargetCreateManyPodInput = {
  year: Scalars['Int'];
  revenue_target_dollars?: Maybe<Scalars['Int']>;
};

export type PodAnnualRevenueTargetYearPodIdCompoundUniqueInput = {
  year: Scalars['Int'];
  podId: Scalars['String'];
};

export type ProjectCreateManySales_Split_PodInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  project_owner_id?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  client_id: Scalars['String'];
  component_code?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  associated_estimate_id?: Maybe<Scalars['String']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ClientCreateNestedOneWithoutForecastsInput = {
  create?: Maybe<ClientCreateWithoutForecastsInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutForecastsInput>;
  connect?: Maybe<ClientWhereUniqueInput>;
};

export type ClientAnnualRevenueForecastCreateManyPodInput = {
  id?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  low_forecast_dollars: Scalars['Int'];
  high_forecast_dollars: Scalars['Int'];
  client_id: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by_user_id: Scalars['String'];
};

export type ClientAnnualRevenueForecastYearClient_IdPod_IdCompoundUniqueInput = {
  year: Scalars['Int'];
  client_id: Scalars['String'];
  pod_id: Scalars['String'];
};

export type PodAnnualRevenueTargetUpdateWithoutPodInput = {
  year?: Maybe<IntFieldUpdateOperationsInput>;
  revenue_target_dollars?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PodAnnualRevenueTargetUpdateManyMutationInput = {
  year?: Maybe<IntFieldUpdateOperationsInput>;
  revenue_target_dollars?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PersonUpdateWithoutPodInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type PersonUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type ProjectUpdateWithoutSales_Split_PodInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ClientAnnualRevenueForecastUpdateWithoutPodInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
  low_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  high_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_by_user_id?: Maybe<StringFieldUpdateOperationsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutForecastsInput>;
};

export type ClientAnnualRevenueForecastUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
  low_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  high_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_by_user_id?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ClientAnnualRevenueForecastWhereInput = {
  AND?: Maybe<Array<ClientAnnualRevenueForecastWhereInput>>;
  OR?: Maybe<Array<ClientAnnualRevenueForecastWhereInput>>;
  NOT?: Maybe<Array<ClientAnnualRevenueForecastWhereInput>>;
  id?: Maybe<StringFilter>;
  year?: Maybe<IntFilter>;
  low_forecast_dollars?: Maybe<IntFilter>;
  high_forecast_dollars?: Maybe<IntFilter>;
  client_id?: Maybe<StringFilter>;
  pod_id?: Maybe<StringFilter>;
  updated_at?: Maybe<DateTimeFilter>;
  updated_by_user_id?: Maybe<StringFilter>;
  client?: Maybe<ClientWhereInput>;
  pod?: Maybe<PodWhereInput>;
};

export type Day_OffWhereInput = {
  AND?: Maybe<Array<Day_OffWhereInput>>;
  OR?: Maybe<Array<Day_OffWhereInput>>;
  NOT?: Maybe<Array<Day_OffWhereInput>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  reason?: Maybe<StringFilter>;
  personId?: Maybe<StringFilter>;
  person?: Maybe<PersonWhereInput>;
};

export type PricingCommentWhereInput = {
  AND?: Maybe<Array<PricingCommentWhereInput>>;
  OR?: Maybe<Array<PricingCommentWhereInput>>;
  NOT?: Maybe<Array<PricingCommentWhereInput>>;
  id?: Maybe<IntFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  tasklist_id?: Maybe<StringFilter>;
  comment?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<StringFilter>;
  is_for_cogs?: Maybe<BoolFilter>;
  created_by?: Maybe<PersonWhereInput>;
  project_pricing?: Maybe<ProjectPricingWhereInput>;
};

export type PersonAnnualTimeTargetWhereInput = {
  AND?: Maybe<Array<PersonAnnualTimeTargetWhereInput>>;
  OR?: Maybe<Array<PersonAnnualTimeTargetWhereInput>>;
  NOT?: Maybe<Array<PersonAnnualTimeTargetWhereInput>>;
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  billableMinutes?: Maybe<IntFilter>;
  nonBillableMinutes?: Maybe<IntFilter>;
  salesAndMarketingMinutes?: Maybe<IntNullableFilter>;
  innovationAndDevMinutes?: Maybe<IntNullableFilter>;
  operationalMinutes?: Maybe<IntNullableFilter>;
  professionalDevMinutes?: Maybe<IntNullableFilter>;
  personId?: Maybe<StringFilter>;
  person?: Maybe<PersonWhereInput>;
};

export type ProjectedTimeWhereInput = {
  AND?: Maybe<Array<ProjectedTimeWhereInput>>;
  OR?: Maybe<Array<ProjectedTimeWhereInput>>;
  NOT?: Maybe<Array<ProjectedTimeWhereInput>>;
  id?: Maybe<IntFilter>;
  tasklist_id?: Maybe<StringFilter>;
  role_id?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  project_pricing?: Maybe<ProjectPricingWhereInput>;
  projected_minutes?: Maybe<IntFilter>;
};

export type ProjectedExpenseWhereInput = {
  AND?: Maybe<Array<ProjectedExpenseWhereInput>>;
  OR?: Maybe<Array<ProjectedExpenseWhereInput>>;
  NOT?: Maybe<Array<ProjectedExpenseWhereInput>>;
  id?: Maybe<IntFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  budget_in_cents?: Maybe<IntFilter>;
  expense_type?: Maybe<StringFilter>;
  payee_name?: Maybe<StringFilter>;
  project_pricing?: Maybe<ProjectPricingWhereInput>;
};

export type EstimateCreateWithoutOwnerInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
  brand: BrandCreateNestedOneWithoutEstimatesInput;
  company: CompanyCreateNestedOneWithoutEstimatesInput;
  client_invoices?: Maybe<Client_InvoiceCreateNestedManyWithoutEstimateInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutAssociated_EstimateInput>;
};

export type EstimateCreateOrConnectWithoutOwnerInput = {
  where: EstimateWhereUniqueInput;
  create: EstimateCreateWithoutOwnerInput;
};

export type EstimateCreateManyOwnerInputEnvelope = {
  data?: Maybe<Array<EstimateCreateManyOwnerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProjectCreateWithoutOwnerInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutOwnerInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutOwnerInput;
};

export type ProjectCreateManyOwnerInputEnvelope = {
  data?: Maybe<Array<ProjectCreateManyOwnerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Time_EntriesCreateWithoutPersonInput = {
  id: Scalars['String'];
  date: Scalars['DateTime'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
  company_id?: Maybe<Scalars['String']>;
  project_tag_id?: Maybe<Scalars['String']>;
  tasklist_id?: Maybe<Scalars['Int']>;
  project: ProjectCreateNestedOneWithoutTime_EntriesInput;
};

export type Time_EntriesCreateOrConnectWithoutPersonInput = {
  where: Time_EntriesWhereUniqueInput;
  create: Time_EntriesCreateWithoutPersonInput;
};

export type Time_EntriesCreateManyPersonInputEnvelope = {
  data?: Maybe<Array<Time_EntriesCreateManyPersonInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PodCreateWithoutMembersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetCreateNestedManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectCreateNestedManyWithoutSales_Split_PodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastCreateNestedManyWithoutPodInput>;
};

export type PodCreateOrConnectWithoutMembersInput = {
  where: PodWhereUniqueInput;
  create: PodCreateWithoutMembersInput;
};

export type Day_OffCreateWithoutPersonInput = {
  date: Scalars['DateTime'];
  reason: Scalars['String'];
};

export type Day_OffCreateOrConnectWithoutPersonInput = {
  where: Day_OffWhereUniqueInput;
  create: Day_OffCreateWithoutPersonInput;
};

export type Day_OffCreateManyPersonInputEnvelope = {
  data?: Maybe<Array<Day_OffCreateManyPersonInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Day_OffWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  date_personId?: Maybe<Day_OffDatePersonIdCompoundUniqueInput>;
};

export type PricingCommentCreateWithoutCreated_ByInput = {
  project_id: Scalars['String'];
  tasklist_id: Scalars['String'];
  comment: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  is_for_cogs?: Maybe<Scalars['Boolean']>;
  project_pricing: ProjectPricingCreateNestedOneWithoutCommentsInput;
};

export type PricingCommentCreateOrConnectWithoutCreated_ByInput = {
  where: PricingCommentWhereUniqueInput;
  create: PricingCommentCreateWithoutCreated_ByInput;
};

export type PricingCommentCreateManyCreated_ByInputEnvelope = {
  data?: Maybe<Array<PricingCommentCreateManyCreated_ByInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type Supplier_Invoice_FilesCreateWithoutCreated_ByInput = {
  id?: Maybe<Scalars['String']>;
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  invoice: InvoiceCreateNestedOneWithoutFilesInput;
};

export type Supplier_Invoice_FilesCreateOrConnectWithoutCreated_ByInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  create: Supplier_Invoice_FilesCreateWithoutCreated_ByInput;
};

export type Supplier_Invoice_FilesCreateManyCreated_ByInputEnvelope = {
  data?: Maybe<Array<Supplier_Invoice_FilesCreateManyCreated_ByInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PersonAnnualTimeTargetCreateWithoutPersonInput = {
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
};

export type PersonAnnualTimeTargetCreateOrConnectWithoutPersonInput = {
  where: PersonAnnualTimeTargetWhereUniqueInput;
  create: PersonAnnualTimeTargetCreateWithoutPersonInput;
};

export type PersonAnnualTimeTargetCreateManyPersonInputEnvelope = {
  data?: Maybe<Array<PersonAnnualTimeTargetCreateManyPersonInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PersonAnnualTimeTargetWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  year_personId?: Maybe<PersonAnnualTimeTargetYearPersonIdCompoundUniqueInput>;
};

export type Project_RateCreateWithoutPersonInput = {
  id?: Maybe<Scalars['String']>;
  project: ProjectCreateNestedOneWithoutProject_RatesInput;
  rate_in_cents: Scalars['Int'];
  source: Project_Rate_Source;
  updated_at: Scalars['DateTime'];
  created_at?: Maybe<Scalars['DateTime']>;
};

export type Project_RateCreateOrConnectWithoutPersonInput = {
  where: Project_RateWhereUniqueInput;
  create: Project_RateCreateWithoutPersonInput;
};

export type Project_RateCreateManyPersonInputEnvelope = {
  data?: Maybe<Array<Project_RateCreateManyPersonInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type RoleCreateWithoutPersonsInput = {
  id?: Maybe<Scalars['String']>;
  roleTitle: Scalars['String'];
};

export type RoleCreateOrConnectWithoutPersonsInput = {
  where: RoleWhereUniqueInput;
  create: RoleCreateWithoutPersonsInput;
};

export type RoleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  roleTitle?: Maybe<Scalars['String']>;
};

export type TeamCreateWithoutPersonsInput = {
  id?: Maybe<Scalars['String']>;
  teamName: Scalars['String'];
};

export type TeamCreateOrConnectWithoutPersonsInput = {
  where: TeamWhereUniqueInput;
  create: TeamCreateWithoutPersonsInput;
};

export type TeamWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
};

export type PersonCreateWithoutUploaded_Supplier_InvoicesInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutUploaded_Supplier_InvoicesInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutUploaded_Supplier_InvoicesInput;
};

export type EstimateUpsertWithWhereUniqueWithoutOwnerInput = {
  where: EstimateWhereUniqueInput;
  update: EstimateUpdateWithoutOwnerInput;
  create: EstimateCreateWithoutOwnerInput;
};

export type EstimateUpdateWithWhereUniqueWithoutOwnerInput = {
  where: EstimateWhereUniqueInput;
  data: EstimateUpdateWithoutOwnerInput;
};

export type EstimateUpdateManyWithWhereWithoutOwnerInput = {
  where: EstimateScalarWhereInput;
  data: EstimateUpdateManyMutationInput;
};

export type ProjectUpsertWithWhereUniqueWithoutOwnerInput = {
  where: ProjectWhereUniqueInput;
  update: ProjectUpdateWithoutOwnerInput;
  create: ProjectCreateWithoutOwnerInput;
};

export type ProjectUpdateWithWhereUniqueWithoutOwnerInput = {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateWithoutOwnerInput;
};

export type ProjectUpdateManyWithWhereWithoutOwnerInput = {
  where: ProjectScalarWhereInput;
  data: ProjectUpdateManyMutationInput;
};

export type Time_EntriesUpsertWithWhereUniqueWithoutPersonInput = {
  where: Time_EntriesWhereUniqueInput;
  update: Time_EntriesUpdateWithoutPersonInput;
  create: Time_EntriesCreateWithoutPersonInput;
};

export type Time_EntriesUpdateWithWhereUniqueWithoutPersonInput = {
  where: Time_EntriesWhereUniqueInput;
  data: Time_EntriesUpdateWithoutPersonInput;
};

export type Time_EntriesUpdateManyWithWhereWithoutPersonInput = {
  where: Time_EntriesScalarWhereInput;
  data: Time_EntriesUpdateManyMutationInput;
};

export type PodUpsertWithWhereUniqueWithoutMembersInput = {
  where: PodWhereUniqueInput;
  update: PodUpdateWithoutMembersInput;
  create: PodCreateWithoutMembersInput;
};

export type PodUpdateWithWhereUniqueWithoutMembersInput = {
  where: PodWhereUniqueInput;
  data: PodUpdateWithoutMembersInput;
};

export type PodUpdateManyWithWhereWithoutMembersInput = {
  where: PodScalarWhereInput;
  data: PodUpdateManyMutationInput;
};

export type PodScalarWhereInput = {
  AND?: Maybe<Array<PodScalarWhereInput>>;
  OR?: Maybe<Array<PodScalarWhereInput>>;
  NOT?: Maybe<Array<PodScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  senior_brand_maven_lead?: Maybe<StringNullableFilter>;
};

export type Day_OffUpsertWithWhereUniqueWithoutPersonInput = {
  where: Day_OffWhereUniqueInput;
  update: Day_OffUpdateWithoutPersonInput;
  create: Day_OffCreateWithoutPersonInput;
};

export type Day_OffUpdateWithWhereUniqueWithoutPersonInput = {
  where: Day_OffWhereUniqueInput;
  data: Day_OffUpdateWithoutPersonInput;
};

export type Day_OffUpdateManyWithWhereWithoutPersonInput = {
  where: Day_OffScalarWhereInput;
  data: Day_OffUpdateManyMutationInput;
};

export type Day_OffScalarWhereInput = {
  AND?: Maybe<Array<Day_OffScalarWhereInput>>;
  OR?: Maybe<Array<Day_OffScalarWhereInput>>;
  NOT?: Maybe<Array<Day_OffScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  reason?: Maybe<StringFilter>;
  personId?: Maybe<StringFilter>;
};

export type PricingCommentUpsertWithWhereUniqueWithoutCreated_ByInput = {
  where: PricingCommentWhereUniqueInput;
  update: PricingCommentUpdateWithoutCreated_ByInput;
  create: PricingCommentCreateWithoutCreated_ByInput;
};

export type PricingCommentUpdateWithWhereUniqueWithoutCreated_ByInput = {
  where: PricingCommentWhereUniqueInput;
  data: PricingCommentUpdateWithoutCreated_ByInput;
};

export type PricingCommentUpdateManyWithWhereWithoutCreated_ByInput = {
  where: PricingCommentScalarWhereInput;
  data: PricingCommentUpdateManyMutationInput;
};

export type PricingCommentScalarWhereInput = {
  AND?: Maybe<Array<PricingCommentScalarWhereInput>>;
  OR?: Maybe<Array<PricingCommentScalarWhereInput>>;
  NOT?: Maybe<Array<PricingCommentScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  tasklist_id?: Maybe<StringFilter>;
  comment?: Maybe<StringFilter>;
  created_at?: Maybe<DateTimeFilter>;
  created_by_id?: Maybe<StringFilter>;
  is_for_cogs?: Maybe<BoolFilter>;
};

export type Supplier_Invoice_FilesUpsertWithWhereUniqueWithoutCreated_ByInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  update: Supplier_Invoice_FilesUpdateWithoutCreated_ByInput;
  create: Supplier_Invoice_FilesCreateWithoutCreated_ByInput;
};

export type Supplier_Invoice_FilesUpdateWithWhereUniqueWithoutCreated_ByInput = {
  where: Supplier_Invoice_FilesWhereUniqueInput;
  data: Supplier_Invoice_FilesUpdateWithoutCreated_ByInput;
};

export type Supplier_Invoice_FilesUpdateManyWithWhereWithoutCreated_ByInput = {
  where: Supplier_Invoice_FilesScalarWhereInput;
  data: Supplier_Invoice_FilesUpdateManyMutationInput;
};

export type PersonAnnualTimeTargetUpsertWithWhereUniqueWithoutPersonInput = {
  where: PersonAnnualTimeTargetWhereUniqueInput;
  update: PersonAnnualTimeTargetUpdateWithoutPersonInput;
  create: PersonAnnualTimeTargetCreateWithoutPersonInput;
};

export type PersonAnnualTimeTargetUpdateWithWhereUniqueWithoutPersonInput = {
  where: PersonAnnualTimeTargetWhereUniqueInput;
  data: PersonAnnualTimeTargetUpdateWithoutPersonInput;
};

export type PersonAnnualTimeTargetUpdateManyWithWhereWithoutPersonInput = {
  where: PersonAnnualTimeTargetScalarWhereInput;
  data: PersonAnnualTimeTargetUpdateManyMutationInput;
};

export type PersonAnnualTimeTargetScalarWhereInput = {
  AND?: Maybe<Array<PersonAnnualTimeTargetScalarWhereInput>>;
  OR?: Maybe<Array<PersonAnnualTimeTargetScalarWhereInput>>;
  NOT?: Maybe<Array<PersonAnnualTimeTargetScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  year?: Maybe<IntFilter>;
  billableMinutes?: Maybe<IntFilter>;
  nonBillableMinutes?: Maybe<IntFilter>;
  salesAndMarketingMinutes?: Maybe<IntNullableFilter>;
  innovationAndDevMinutes?: Maybe<IntNullableFilter>;
  operationalMinutes?: Maybe<IntNullableFilter>;
  professionalDevMinutes?: Maybe<IntNullableFilter>;
  personId?: Maybe<StringFilter>;
};

export type Project_RateUpsertWithWhereUniqueWithoutPersonInput = {
  where: Project_RateWhereUniqueInput;
  update: Project_RateUpdateWithoutPersonInput;
  create: Project_RateCreateWithoutPersonInput;
};

export type Project_RateUpdateWithWhereUniqueWithoutPersonInput = {
  where: Project_RateWhereUniqueInput;
  data: Project_RateUpdateWithoutPersonInput;
};

export type Project_RateUpdateManyWithWhereWithoutPersonInput = {
  where: Project_RateScalarWhereInput;
  data: Project_RateUpdateManyMutationInput;
};

export type RoleUpsertWithoutPersonsInput = {
  update: RoleUpdateWithoutPersonsInput;
  create: RoleCreateWithoutPersonsInput;
};

export type RoleUpdateWithoutPersonsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  roleTitle?: Maybe<StringFieldUpdateOperationsInput>;
};

export type TeamUpsertWithoutPersonsInput = {
  update: TeamUpdateWithoutPersonsInput;
  create: TeamCreateWithoutPersonsInput;
};

export type TeamUpdateWithoutPersonsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  teamName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PersonUpdateOneRequiredWithoutUploaded_Supplier_InvoicesInput = {
  create?: Maybe<PersonCreateWithoutUploaded_Supplier_InvoicesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutUploaded_Supplier_InvoicesInput>;
  upsert?: Maybe<PersonUpsertWithoutUploaded_Supplier_InvoicesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutUploaded_Supplier_InvoicesInput>;
};

export type InvoiceCreateWithoutContractorInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectCreateNestedOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutInvoiceInput>;
};

export type InvoiceCreateOrConnectWithoutContractorInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateWithoutContractorInput;
};

export type InvoiceCreateManyContractorInputEnvelope = {
  data?: Maybe<Array<InvoiceCreateManyContractorInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type InvoiceUpsertWithWhereUniqueWithoutContractorInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUpdateWithoutContractorInput;
  create: InvoiceCreateWithoutContractorInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutContractorInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUpdateWithoutContractorInput;
};

export type InvoiceUpdateManyWithWhereWithoutContractorInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUpdateManyMutationInput;
};

export type ClientAnnualRevenueForecastCreateWithoutClientInput = {
  id?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  low_forecast_dollars: Scalars['Int'];
  high_forecast_dollars: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by_user_id: Scalars['String'];
  pod: PodCreateNestedOneWithoutForecastsInput;
};

export type ClientAnnualRevenueForecastCreateOrConnectWithoutClientInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  create: ClientAnnualRevenueForecastCreateWithoutClientInput;
};

export type ClientAnnualRevenueForecastCreateManyClientInputEnvelope = {
  data?: Maybe<Array<ClientAnnualRevenueForecastCreateManyClientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PersonCreateWithoutTime_EntriesInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutTime_EntriesInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutTime_EntriesInput;
};

export type PersonCreateWithoutProject_RatesInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentCreateNestedManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutProject_RatesInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutProject_RatesInput;
};

export type ProjectedTimeCreateWithoutProject_PricingInput = {
  tasklist_id: Scalars['String'];
  role_id: Scalars['String'];
  team_id: Scalars['String'];
  project_id: Scalars['String'];
  projected_minutes?: Maybe<Scalars['Int']>;
};

export type ProjectedTimeCreateOrConnectWithoutProject_PricingInput = {
  where: ProjectedTimeWhereUniqueInput;
  create: ProjectedTimeCreateWithoutProject_PricingInput;
};

export type ProjectedTimeCreateManyProject_PricingInputEnvelope = {
  data?: Maybe<Array<ProjectedTimeCreateManyProject_PricingInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProjectedExpenseCreateWithoutProject_PricingInput = {
  project_id: Scalars['String'];
  budget_in_cents: Scalars['Int'];
  expense_type: Scalars['String'];
  payee_name: Scalars['String'];
};

export type ProjectedExpenseCreateOrConnectWithoutProject_PricingInput = {
  where: ProjectedExpenseWhereUniqueInput;
  create: ProjectedExpenseCreateWithoutProject_PricingInput;
};

export type ProjectedExpenseCreateManyProject_PricingInputEnvelope = {
  data?: Maybe<Array<ProjectedExpenseCreateManyProject_PricingInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PricingCommentCreateWithoutProject_PricingInput = {
  project_id: Scalars['String'];
  tasklist_id: Scalars['String'];
  comment: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  is_for_cogs?: Maybe<Scalars['Boolean']>;
  created_by: PersonCreateNestedOneWithoutPricing_CommentsInput;
};

export type PricingCommentCreateOrConnectWithoutProject_PricingInput = {
  where: PricingCommentWhereUniqueInput;
  create: PricingCommentCreateWithoutProject_PricingInput;
};

export type PricingCommentCreateManyProject_PricingInputEnvelope = {
  data?: Maybe<Array<PricingCommentCreateManyProject_PricingInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ClientAnnualRevenueForecastUpsertWithWhereUniqueWithoutClientInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  update: ClientAnnualRevenueForecastUpdateWithoutClientInput;
  create: ClientAnnualRevenueForecastCreateWithoutClientInput;
};

export type ClientAnnualRevenueForecastUpdateWithWhereUniqueWithoutClientInput = {
  where: ClientAnnualRevenueForecastWhereUniqueInput;
  data: ClientAnnualRevenueForecastUpdateWithoutClientInput;
};

export type ClientAnnualRevenueForecastUpdateManyWithWhereWithoutClientInput = {
  where: ClientAnnualRevenueForecastScalarWhereInput;
  data: ClientAnnualRevenueForecastUpdateManyMutationInput;
};

export type PersonUpdateOneRequiredWithoutTime_EntriesInput = {
  create?: Maybe<PersonCreateWithoutTime_EntriesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutTime_EntriesInput>;
  upsert?: Maybe<PersonUpsertWithoutTime_EntriesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutTime_EntriesInput>;
};

export type PersonUpdateOneRequiredWithoutProject_RatesInput = {
  create?: Maybe<PersonCreateWithoutProject_RatesInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutProject_RatesInput>;
  upsert?: Maybe<PersonUpsertWithoutProject_RatesInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutProject_RatesInput>;
};

export type Enumproject_Rate_SourceFieldUpdateOperationsInput = {
  set?: Maybe<Project_Rate_Source>;
};

export type NestedEnumproject_Rate_SourceFilter = {
  equals?: Maybe<Project_Rate_Source>;
  in?: Maybe<Array<Project_Rate_Source>>;
  notIn?: Maybe<Array<Project_Rate_Source>>;
  not?: Maybe<NestedEnumproject_Rate_SourceFilter>;
};

export type ProjectedTimeUpsertWithWhereUniqueWithoutProject_PricingInput = {
  where: ProjectedTimeWhereUniqueInput;
  update: ProjectedTimeUpdateWithoutProject_PricingInput;
  create: ProjectedTimeCreateWithoutProject_PricingInput;
};

export type ProjectedTimeUpdateWithWhereUniqueWithoutProject_PricingInput = {
  where: ProjectedTimeWhereUniqueInput;
  data: ProjectedTimeUpdateWithoutProject_PricingInput;
};

export type ProjectedTimeUpdateManyWithWhereWithoutProject_PricingInput = {
  where: ProjectedTimeScalarWhereInput;
  data: ProjectedTimeUpdateManyMutationInput;
};

export type ProjectedTimeScalarWhereInput = {
  AND?: Maybe<Array<ProjectedTimeScalarWhereInput>>;
  OR?: Maybe<Array<ProjectedTimeScalarWhereInput>>;
  NOT?: Maybe<Array<ProjectedTimeScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  tasklist_id?: Maybe<StringFilter>;
  role_id?: Maybe<StringFilter>;
  team_id?: Maybe<StringFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  projected_minutes?: Maybe<IntFilter>;
};

export type ProjectedExpenseUpsertWithWhereUniqueWithoutProject_PricingInput = {
  where: ProjectedExpenseWhereUniqueInput;
  update: ProjectedExpenseUpdateWithoutProject_PricingInput;
  create: ProjectedExpenseCreateWithoutProject_PricingInput;
};

export type ProjectedExpenseUpdateWithWhereUniqueWithoutProject_PricingInput = {
  where: ProjectedExpenseWhereUniqueInput;
  data: ProjectedExpenseUpdateWithoutProject_PricingInput;
};

export type ProjectedExpenseUpdateManyWithWhereWithoutProject_PricingInput = {
  where: ProjectedExpenseScalarWhereInput;
  data: ProjectedExpenseUpdateManyMutationInput;
};

export type ProjectedExpenseScalarWhereInput = {
  AND?: Maybe<Array<ProjectedExpenseScalarWhereInput>>;
  OR?: Maybe<Array<ProjectedExpenseScalarWhereInput>>;
  NOT?: Maybe<Array<ProjectedExpenseScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  project_id?: Maybe<StringFilter>;
  project_pricing_id?: Maybe<IntFilter>;
  budget_in_cents?: Maybe<IntFilter>;
  expense_type?: Maybe<StringFilter>;
  payee_name?: Maybe<StringFilter>;
};

export type PricingCommentUpsertWithWhereUniqueWithoutProject_PricingInput = {
  where: PricingCommentWhereUniqueInput;
  update: PricingCommentUpdateWithoutProject_PricingInput;
  create: PricingCommentCreateWithoutProject_PricingInput;
};

export type PricingCommentUpdateWithWhereUniqueWithoutProject_PricingInput = {
  where: PricingCommentWhereUniqueInput;
  data: PricingCommentUpdateWithoutProject_PricingInput;
};

export type PricingCommentUpdateManyWithWhereWithoutProject_PricingInput = {
  where: PricingCommentScalarWhereInput;
  data: PricingCommentUpdateManyMutationInput;
};

export type ClientCreateWithoutForecastsInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectCreateNestedManyWithoutClientInput>;
};

export type ClientCreateOrConnectWithoutForecastsInput = {
  where: ClientWhereUniqueInput;
  create: ClientCreateWithoutForecastsInput;
};

export type ClientUpdateOneRequiredWithoutForecastsInput = {
  create?: Maybe<ClientCreateWithoutForecastsInput>;
  connectOrCreate?: Maybe<ClientCreateOrConnectWithoutForecastsInput>;
  upsert?: Maybe<ClientUpsertWithoutForecastsInput>;
  connect?: Maybe<ClientWhereUniqueInput>;
  update?: Maybe<ClientUpdateWithoutForecastsInput>;
};

export type EstimateCreateManyOwnerInput = {
  id?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  po_number?: Maybe<Scalars['String']>;
  company_id: Scalars['String'];
  brand_id: Scalars['String'];
  po_subtotal?: Maybe<Scalars['Decimal']>;
  created_at?: Maybe<Scalars['DateTime']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Scalars['String']>;
  pdf_file_key?: Maybe<Scalars['String']>;
};

export type ProjectCreateManyOwnerInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  client_id: Scalars['String'];
  component_code?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  associated_estimate_id?: Maybe<Scalars['String']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  sales_split_pod_id?: Maybe<Scalars['String']>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateNestedOneWithoutTime_EntriesInput = {
  create?: Maybe<ProjectCreateWithoutTime_EntriesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutTime_EntriesInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
};

export type Time_EntriesCreateManyPersonInput = {
  id: Scalars['String'];
  date: Scalars['DateTime'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
  company_id?: Maybe<Scalars['String']>;
  project_id: Scalars['String'];
  project_tag_id?: Maybe<Scalars['String']>;
  tasklist_id?: Maybe<Scalars['Int']>;
};

export type Day_OffCreateManyPersonInput = {
  id?: Maybe<Scalars['Int']>;
  date: Scalars['DateTime'];
  reason: Scalars['String'];
};

export type Day_OffDatePersonIdCompoundUniqueInput = {
  date: Scalars['DateTime'];
  personId: Scalars['String'];
};

export type ProjectPricingCreateNestedOneWithoutCommentsInput = {
  create?: Maybe<ProjectPricingCreateWithoutCommentsInput>;
  connectOrCreate?: Maybe<ProjectPricingCreateOrConnectWithoutCommentsInput>;
  connect?: Maybe<ProjectPricingWhereUniqueInput>;
};

export type PricingCommentCreateManyCreated_ByInput = {
  id?: Maybe<Scalars['Int']>;
  project_id: Scalars['String'];
  project_pricing_id: Scalars['Int'];
  tasklist_id: Scalars['String'];
  comment: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  is_for_cogs?: Maybe<Scalars['Boolean']>;
};

export type InvoiceCreateNestedOneWithoutFilesInput = {
  create?: Maybe<InvoiceCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutFilesInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
};

export type Supplier_Invoice_FilesCreateManyCreated_ByInput = {
  id?: Maybe<Scalars['String']>;
  content_type: Scalars['String'];
  file_name: Scalars['String'];
  invoice_id: Scalars['String'];
};

export type PersonAnnualTimeTargetCreateManyPersonInput = {
  id?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
};

export type PersonAnnualTimeTargetYearPersonIdCompoundUniqueInput = {
  year: Scalars['Int'];
  personId: Scalars['String'];
};

export type ProjectCreateNestedOneWithoutProject_RatesInput = {
  create?: Maybe<ProjectCreateWithoutProject_RatesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutProject_RatesInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
};

export type Project_RateCreateManyPersonInput = {
  id?: Maybe<Scalars['String']>;
  project_id: Scalars['String'];
  rate_in_cents: Scalars['Int'];
  source: Project_Rate_Source;
  updated_at: Scalars['DateTime'];
  created_at?: Maybe<Scalars['DateTime']>;
};

export type EstimateUpdateWithoutOwnerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  po_number?: Maybe<NullableStringFieldUpdateOperationsInput>;
  po_subtotal?: Maybe<DecimalFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  pdf_file_key?: Maybe<NullableStringFieldUpdateOperationsInput>;
  brand?: Maybe<BrandUpdateOneRequiredWithoutEstimatesInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutEstimatesInput>;
  client_invoices?: Maybe<Client_InvoiceUpdateManyWithoutEstimateInput>;
  projects?: Maybe<ProjectUpdateManyWithoutAssociated_EstimateInput>;
};

export type ProjectUpdateWithoutOwnerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type Time_EntriesUpdateWithoutPersonInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  minutes?: Maybe<IntFieldUpdateOperationsInput>;
  billable?: Maybe<BoolFieldUpdateOperationsInput>;
  company_id?: Maybe<StringFieldUpdateOperationsInput>;
  project_tag_id?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<NullableIntFieldUpdateOperationsInput>;
  project?: Maybe<ProjectUpdateOneRequiredWithoutTime_EntriesInput>;
};

export type PodUpdateWithoutMembersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetUpdateManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectUpdateManyWithoutSales_Split_PodInput>;
  forecasts?: Maybe<ClientAnnualRevenueForecastUpdateManyWithoutPodInput>;
};

export type PodUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type Day_OffUpdateWithoutPersonInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  reason?: Maybe<StringFieldUpdateOperationsInput>;
};

export type Day_OffUpdateManyMutationInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  reason?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PricingCommentUpdateWithoutCreated_ByInput = {
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<StringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_for_cogs?: Maybe<BoolFieldUpdateOperationsInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneRequiredWithoutCommentsInput>;
};

export type PricingCommentUpdateManyMutationInput = {
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<StringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_for_cogs?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type Supplier_Invoice_FilesUpdateWithoutCreated_ByInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  content_type?: Maybe<StringFieldUpdateOperationsInput>;
  file_name?: Maybe<StringFieldUpdateOperationsInput>;
  invoice?: Maybe<InvoiceUpdateOneRequiredWithoutFilesInput>;
};

export type PersonAnnualTimeTargetUpdateWithoutPersonInput = {
  year?: Maybe<IntFieldUpdateOperationsInput>;
  billableMinutes?: Maybe<IntFieldUpdateOperationsInput>;
  nonBillableMinutes?: Maybe<IntFieldUpdateOperationsInput>;
  salesAndMarketingMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  innovationAndDevMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  operationalMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  professionalDevMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PersonAnnualTimeTargetUpdateManyMutationInput = {
  year?: Maybe<IntFieldUpdateOperationsInput>;
  billableMinutes?: Maybe<IntFieldUpdateOperationsInput>;
  nonBillableMinutes?: Maybe<IntFieldUpdateOperationsInput>;
  salesAndMarketingMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  innovationAndDevMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  operationalMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
  professionalDevMinutes?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type Project_RateUpdateWithoutPersonInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  project?: Maybe<ProjectUpdateOneRequiredWithoutProject_RatesInput>;
  rate_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  source?: Maybe<Enumproject_Rate_SourceFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type PersonUpsertWithoutUploaded_Supplier_InvoicesInput = {
  update: PersonUpdateWithoutUploaded_Supplier_InvoicesInput;
  create: PersonCreateWithoutUploaded_Supplier_InvoicesInput;
};

export type PersonUpdateWithoutUploaded_Supplier_InvoicesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type InvoiceCreateManyContractorInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  project_id?: Maybe<Scalars['String']>;
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
};

export type InvoiceUpdateWithoutContractorInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoice_number?: Maybe<StringFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  date_created?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  payee_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project?: Maybe<ProjectUpdateOneWithoutInvoicesInput>;
  files?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutInvoiceInput>;
};

export type PodCreateNestedOneWithoutForecastsInput = {
  create?: Maybe<PodCreateWithoutForecastsInput>;
  connectOrCreate?: Maybe<PodCreateOrConnectWithoutForecastsInput>;
  connect?: Maybe<PodWhereUniqueInput>;
};

export type ClientAnnualRevenueForecastCreateManyClientInput = {
  id?: Maybe<Scalars['String']>;
  year: Scalars['Int'];
  low_forecast_dollars: Scalars['Int'];
  high_forecast_dollars: Scalars['Int'];
  pod_id: Scalars['String'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by_user_id: Scalars['String'];
};

export type ProjectedTimeCreateManyProject_PricingInput = {
  id?: Maybe<Scalars['Int']>;
  tasklist_id: Scalars['String'];
  role_id: Scalars['String'];
  team_id: Scalars['String'];
  project_id: Scalars['String'];
  projected_minutes?: Maybe<Scalars['Int']>;
};

export type ProjectedExpenseCreateManyProject_PricingInput = {
  id?: Maybe<Scalars['Int']>;
  project_id: Scalars['String'];
  budget_in_cents: Scalars['Int'];
  expense_type: Scalars['String'];
  payee_name: Scalars['String'];
};

export type PersonCreateNestedOneWithoutPricing_CommentsInput = {
  create?: Maybe<PersonCreateWithoutPricing_CommentsInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutPricing_CommentsInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
};

export type PricingCommentCreateManyProject_PricingInput = {
  id?: Maybe<Scalars['Int']>;
  project_id: Scalars['String'];
  tasklist_id: Scalars['String'];
  comment: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  created_by_id: Scalars['String'];
  is_for_cogs?: Maybe<Scalars['Boolean']>;
};

export type ClientAnnualRevenueForecastUpdateWithoutClientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  year?: Maybe<IntFieldUpdateOperationsInput>;
  low_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  high_forecast_dollars?: Maybe<IntFieldUpdateOperationsInput>;
  updated_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updated_by_user_id?: Maybe<StringFieldUpdateOperationsInput>;
  pod?: Maybe<PodUpdateOneRequiredWithoutForecastsInput>;
};

export type PersonUpsertWithoutTime_EntriesInput = {
  update: PersonUpdateWithoutTime_EntriesInput;
  create: PersonCreateWithoutTime_EntriesInput;
};

export type PersonUpdateWithoutTime_EntriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type PersonUpsertWithoutProject_RatesInput = {
  update: PersonUpdateWithoutProject_RatesInput;
  create: PersonCreateWithoutProject_RatesInput;
};

export type PersonUpdateWithoutProject_RatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  pricing_comments?: Maybe<PricingCommentUpdateManyWithoutCreated_ByInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type ProjectedTimeUpdateWithoutProject_PricingInput = {
  tasklist_id?: Maybe<StringFieldUpdateOperationsInput>;
  role_id?: Maybe<StringFieldUpdateOperationsInput>;
  team_id?: Maybe<StringFieldUpdateOperationsInput>;
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  projected_minutes?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProjectedTimeUpdateManyMutationInput = {
  tasklist_id?: Maybe<StringFieldUpdateOperationsInput>;
  role_id?: Maybe<StringFieldUpdateOperationsInput>;
  team_id?: Maybe<StringFieldUpdateOperationsInput>;
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  projected_minutes?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProjectedExpenseUpdateWithoutProject_PricingInput = {
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  budget_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  payee_name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type ProjectedExpenseUpdateManyMutationInput = {
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  budget_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  payee_name?: Maybe<StringFieldUpdateOperationsInput>;
};

export type PricingCommentUpdateWithoutProject_PricingInput = {
  project_id?: Maybe<StringFieldUpdateOperationsInput>;
  tasklist_id?: Maybe<StringFieldUpdateOperationsInput>;
  comment?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  is_for_cogs?: Maybe<BoolFieldUpdateOperationsInput>;
  created_by?: Maybe<PersonUpdateOneRequiredWithoutPricing_CommentsInput>;
};

export type ProjectCreateNestedManyWithoutClientInput = {
  create?: Maybe<Array<ProjectCreateWithoutClientInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutClientInput>>;
  createMany?: Maybe<ProjectCreateManyClientInputEnvelope>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
};

export type ClientUpsertWithoutForecastsInput = {
  update: ClientUpdateWithoutForecastsInput;
  create: ClientCreateWithoutForecastsInput;
};

export type ClientUpdateWithoutForecastsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  projects?: Maybe<ProjectUpdateManyWithoutClientInput>;
};

export type ProjectCreateWithoutTime_EntriesInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutTime_EntriesInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutTime_EntriesInput;
};

export type ProjectPricingCreateWithoutCommentsInput = {
  project: ProjectCreateNestedOneWithoutProject_PricingInput;
  price?: Maybe<Scalars['Int']>;
  actual_cost?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  projected_times?: Maybe<ProjectedTimeCreateNestedManyWithoutProject_PricingInput>;
  projected_expenses?: Maybe<ProjectedExpenseCreateNestedManyWithoutProject_PricingInput>;
};

export type ProjectPricingCreateOrConnectWithoutCommentsInput = {
  where: ProjectPricingWhereUniqueInput;
  create: ProjectPricingCreateWithoutCommentsInput;
};

export type InvoiceCreateWithoutFilesInput = {
  id?: Maybe<Scalars['String']>;
  invoice_number: Scalars['String'];
  expense_type: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['DateTime']>;
  amount_in_cents: Scalars['Int'];
  payee_name?: Maybe<Scalars['String']>;
  contractor?: Maybe<PersonCreateNestedOneWithoutInvoicesInput>;
  project?: Maybe<ProjectCreateNestedOneWithoutInvoicesInput>;
};

export type InvoiceCreateOrConnectWithoutFilesInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceCreateWithoutFilesInput;
};

export type ProjectCreateWithoutProject_RatesInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutProject_RatesInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutProject_RatesInput;
};

export type ProjectUpdateOneRequiredWithoutTime_EntriesInput = {
  create?: Maybe<ProjectCreateWithoutTime_EntriesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutTime_EntriesInput>;
  upsert?: Maybe<ProjectUpsertWithoutTime_EntriesInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
  update?: Maybe<ProjectUpdateWithoutTime_EntriesInput>;
};

export type ProjectPricingUpdateOneRequiredWithoutCommentsInput = {
  create?: Maybe<ProjectPricingCreateWithoutCommentsInput>;
  connectOrCreate?: Maybe<ProjectPricingCreateOrConnectWithoutCommentsInput>;
  upsert?: Maybe<ProjectPricingUpsertWithoutCommentsInput>;
  connect?: Maybe<ProjectPricingWhereUniqueInput>;
  update?: Maybe<ProjectPricingUpdateWithoutCommentsInput>;
};

export type InvoiceUpdateOneRequiredWithoutFilesInput = {
  create?: Maybe<InvoiceCreateWithoutFilesInput>;
  connectOrCreate?: Maybe<InvoiceCreateOrConnectWithoutFilesInput>;
  upsert?: Maybe<InvoiceUpsertWithoutFilesInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  update?: Maybe<InvoiceUpdateWithoutFilesInput>;
};

export type ProjectUpdateOneRequiredWithoutProject_RatesInput = {
  create?: Maybe<ProjectCreateWithoutProject_RatesInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutProject_RatesInput>;
  upsert?: Maybe<ProjectUpsertWithoutProject_RatesInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
  update?: Maybe<ProjectUpdateWithoutProject_RatesInput>;
};

export type PodCreateWithoutForecastsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetCreateNestedManyWithoutPodInput>;
  members?: Maybe<PersonCreateNestedManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectCreateNestedManyWithoutSales_Split_PodInput>;
};

export type PodCreateOrConnectWithoutForecastsInput = {
  where: PodWhereUniqueInput;
  create: PodCreateWithoutForecastsInput;
};

export type PersonCreateWithoutPricing_CommentsInput = {
  id?: Maybe<Scalars['String']>;
  tw_username?: Maybe<Scalars['String']>;
  email_address: Scalars['String'];
  avatar_url: Scalars['String'];
  created_at?: Maybe<Scalars['DateTime']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  title: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  estimates?: Maybe<EstimateCreateNestedManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutContractorInput>;
  projects?: Maybe<ProjectCreateNestedManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutPersonInput>;
  pod?: Maybe<PodCreateNestedManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffCreateNestedManyWithoutPersonInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesCreateNestedManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetCreateNestedManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<Scalars['Boolean']>;
  role?: Maybe<RoleCreateNestedOneWithoutPersonsInput>;
  team?: Maybe<TeamCreateNestedOneWithoutPersonsInput>;
};

export type PersonCreateOrConnectWithoutPricing_CommentsInput = {
  where: PersonWhereUniqueInput;
  create: PersonCreateWithoutPricing_CommentsInput;
};

export type PodUpdateOneRequiredWithoutForecastsInput = {
  create?: Maybe<PodCreateWithoutForecastsInput>;
  connectOrCreate?: Maybe<PodCreateOrConnectWithoutForecastsInput>;
  upsert?: Maybe<PodUpsertWithoutForecastsInput>;
  connect?: Maybe<PodWhereUniqueInput>;
  update?: Maybe<PodUpdateWithoutForecastsInput>;
};

export type PersonUpdateOneRequiredWithoutPricing_CommentsInput = {
  create?: Maybe<PersonCreateWithoutPricing_CommentsInput>;
  connectOrCreate?: Maybe<PersonCreateOrConnectWithoutPricing_CommentsInput>;
  upsert?: Maybe<PersonUpsertWithoutPricing_CommentsInput>;
  connect?: Maybe<PersonWhereUniqueInput>;
  update?: Maybe<PersonUpdateWithoutPricing_CommentsInput>;
};

export type ProjectCreateWithoutClientInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingCreateNestedOneWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutClientInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutClientInput;
};

export type ProjectCreateManyClientInputEnvelope = {
  data?: Maybe<Array<ProjectCreateManyClientInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type ProjectUpdateManyWithoutClientInput = {
  create?: Maybe<Array<ProjectCreateWithoutClientInput>>;
  connectOrCreate?: Maybe<Array<ProjectCreateOrConnectWithoutClientInput>>;
  upsert?: Maybe<Array<ProjectUpsertWithWhereUniqueWithoutClientInput>>;
  createMany?: Maybe<ProjectCreateManyClientInputEnvelope>;
  set?: Maybe<Array<ProjectWhereUniqueInput>>;
  disconnect?: Maybe<Array<ProjectWhereUniqueInput>>;
  delete?: Maybe<Array<ProjectWhereUniqueInput>>;
  connect?: Maybe<Array<ProjectWhereUniqueInput>>;
  update?: Maybe<Array<ProjectUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: Maybe<Array<ProjectUpdateManyWithWhereWithoutClientInput>>;
  deleteMany?: Maybe<Array<ProjectScalarWhereInput>>;
};

export type ProjectCreateNestedOneWithoutProject_PricingInput = {
  create?: Maybe<ProjectCreateWithoutProject_PricingInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutProject_PricingInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
};

export type ProjectUpsertWithoutTime_EntriesInput = {
  update: ProjectUpdateWithoutTime_EntriesInput;
  create: ProjectCreateWithoutTime_EntriesInput;
};

export type ProjectUpdateWithoutTime_EntriesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProjectPricingUpsertWithoutCommentsInput = {
  update: ProjectPricingUpdateWithoutCommentsInput;
  create: ProjectPricingCreateWithoutCommentsInput;
};

export type ProjectPricingUpdateWithoutCommentsInput = {
  project?: Maybe<ProjectUpdateOneRequiredWithoutProject_PricingInput>;
  price?: Maybe<IntFieldUpdateOperationsInput>;
  actual_cost?: Maybe<IntFieldUpdateOperationsInput>;
  currency?: Maybe<StringFieldUpdateOperationsInput>;
  projected_times?: Maybe<ProjectedTimeUpdateManyWithoutProject_PricingInput>;
  projected_expenses?: Maybe<ProjectedExpenseUpdateManyWithoutProject_PricingInput>;
};

export type InvoiceUpsertWithoutFilesInput = {
  update: InvoiceUpdateWithoutFilesInput;
  create: InvoiceCreateWithoutFilesInput;
};

export type InvoiceUpdateWithoutFilesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  invoice_number?: Maybe<StringFieldUpdateOperationsInput>;
  expense_type?: Maybe<StringFieldUpdateOperationsInput>;
  notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  date_created?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount_in_cents?: Maybe<IntFieldUpdateOperationsInput>;
  payee_name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  contractor?: Maybe<PersonUpdateOneWithoutInvoicesInput>;
  project?: Maybe<ProjectUpdateOneWithoutInvoicesInput>;
};

export type ProjectUpsertWithoutProject_RatesInput = {
  update: ProjectUpdateWithoutProject_RatesInput;
  create: ProjectCreateWithoutProject_RatesInput;
};

export type ProjectUpdateWithoutProject_RatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type PodUpsertWithoutForecastsInput = {
  update: PodUpdateWithoutForecastsInput;
  create: PodCreateWithoutForecastsInput;
};

export type PodUpdateWithoutForecastsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  PodAnnualRevenueTarget?: Maybe<PodAnnualRevenueTargetUpdateManyWithoutPodInput>;
  members?: Maybe<PersonUpdateManyWithoutPodInput>;
  incoming_split_projects?: Maybe<ProjectUpdateManyWithoutSales_Split_PodInput>;
};

export type PersonUpsertWithoutPricing_CommentsInput = {
  update: PersonUpdateWithoutPricing_CommentsInput;
  create: PersonCreateWithoutPricing_CommentsInput;
};

export type PersonUpdateWithoutPricing_CommentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  tw_username?: Maybe<NullableStringFieldUpdateOperationsInput>;
  email_address?: Maybe<StringFieldUpdateOperationsInput>;
  avatar_url?: Maybe<StringFieldUpdateOperationsInput>;
  created_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  first_name?: Maybe<StringFieldUpdateOperationsInput>;
  last_name?: Maybe<StringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  company?: Maybe<StringFieldUpdateOperationsInput>;
  rate?: Maybe<IntFieldUpdateOperationsInput>;
  estimates?: Maybe<EstimateUpdateManyWithoutOwnerInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutContractorInput>;
  projects?: Maybe<ProjectUpdateManyWithoutOwnerInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutPersonInput>;
  pod?: Maybe<PodUpdateManyWithoutMembersInput>;
  days_off?: Maybe<Day_OffUpdateManyWithoutPersonInput>;
  uploaded_supplier_invoices?: Maybe<Supplier_Invoice_FilesUpdateManyWithoutCreated_ByInput>;
  annual_time_targets?: Maybe<PersonAnnualTimeTargetUpdateManyWithoutPersonInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutPersonInput>;
  is_deleted_on_tw?: Maybe<BoolFieldUpdateOperationsInput>;
  role?: Maybe<RoleUpdateOneWithoutPersonsInput>;
  team?: Maybe<TeamUpdateOneWithoutPersonsInput>;
};

export type ProjectCreateManyClientInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  project_owner_id?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  associated_estimate_id?: Maybe<Scalars['String']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  sales_split_pod_id?: Maybe<Scalars['String']>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectUpsertWithWhereUniqueWithoutClientInput = {
  where: ProjectWhereUniqueInput;
  update: ProjectUpdateWithoutClientInput;
  create: ProjectCreateWithoutClientInput;
};

export type ProjectUpdateWithWhereUniqueWithoutClientInput = {
  where: ProjectWhereUniqueInput;
  data: ProjectUpdateWithoutClientInput;
};

export type ProjectUpdateManyWithWhereWithoutClientInput = {
  where: ProjectScalarWhereInput;
  data: ProjectUpdateManyMutationInput;
};

export type ProjectCreateWithoutProject_PricingInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Int']>;
  component_code?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  last_changed_at?: Maybe<Scalars['DateTime']>;
  project_type?: Maybe<Project_Type>;
  language?: Maybe<Project_Language>;
  external_reviewer?: Maybe<External_Reviewer>;
  internal_review?: Maybe<Scalars['Boolean']>;
  last_synced_overview_at: Scalars['DateTime'];
  project_status?: Maybe<Scalars['String']>;
  last_synced_details_at?: Maybe<Scalars['DateTime']>;
  client_brand_manager?: Maybe<Scalars['String']>;
  is_reviewed?: Maybe<Scalars['Boolean']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_deleted?: Maybe<Scalars['Boolean']>;
  project_notes?: Maybe<Scalars['String']>;
  project_notes_2?: Maybe<Scalars['String']>;
  senior_brand_maven_lead?: Maybe<Scalars['String']>;
  associated_estimate?: Maybe<EstimateCreateNestedOneWithoutProjectsInput>;
  category?: Maybe<CategoriesCreateNestedOneWithoutProjectsInput>;
  client: ClientCreateNestedOneWithoutProjectsInput;
  owner?: Maybe<PersonCreateNestedOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesCreateNestedManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodCreateNestedOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<Scalars['Int']>;
  project_rates?: Maybe<Project_RateCreateNestedManyWithoutProjectInput>;
  epoch?: Maybe<Scalars['Int']>;
};

export type ProjectCreateOrConnectWithoutProject_PricingInput = {
  where: ProjectWhereUniqueInput;
  create: ProjectCreateWithoutProject_PricingInput;
};

export type ProjectUpdateOneRequiredWithoutProject_PricingInput = {
  create?: Maybe<ProjectCreateWithoutProject_PricingInput>;
  connectOrCreate?: Maybe<ProjectCreateOrConnectWithoutProject_PricingInput>;
  upsert?: Maybe<ProjectUpsertWithoutProject_PricingInput>;
  connect?: Maybe<ProjectWhereUniqueInput>;
  update?: Maybe<ProjectUpdateWithoutProject_PricingInput>;
};

export type ProjectUpdateWithoutClientInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  project_pricing?: Maybe<ProjectPricingUpdateOneWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type ProjectUpsertWithoutProject_PricingInput = {
  update: ProjectUpdateWithoutProject_PricingInput;
  create: ProjectCreateWithoutProject_PricingInput;
};

export type ProjectUpdateWithoutProject_PricingInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  description?: Maybe<StringFieldUpdateOperationsInput>;
  budget?: Maybe<NullableIntFieldUpdateOperationsInput>;
  component_code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  created_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  start_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  end_date?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  last_changed_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  project_type?: Maybe<NullableEnumproject_TypeFieldUpdateOperationsInput>;
  language?: Maybe<NullableEnumproject_LanguageFieldUpdateOperationsInput>;
  external_reviewer?: Maybe<NullableEnumexternal_ReviewerFieldUpdateOperationsInput>;
  internal_review?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  last_synced_overview_at?: Maybe<DateTimeFieldUpdateOperationsInput>;
  project_status?: Maybe<NullableStringFieldUpdateOperationsInput>;
  last_synced_details_at?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  client_brand_manager?: Maybe<NullableStringFieldUpdateOperationsInput>;
  is_reviewed?: Maybe<BoolFieldUpdateOperationsInput>;
  is_archived?: Maybe<BoolFieldUpdateOperationsInput>;
  is_deleted?: Maybe<BoolFieldUpdateOperationsInput>;
  project_notes?: Maybe<NullableStringFieldUpdateOperationsInput>;
  project_notes_2?: Maybe<NullableStringFieldUpdateOperationsInput>;
  senior_brand_maven_lead?: Maybe<NullableStringFieldUpdateOperationsInput>;
  associated_estimate?: Maybe<EstimateUpdateOneWithoutProjectsInput>;
  category?: Maybe<CategoriesUpdateOneWithoutProjectsInput>;
  client?: Maybe<ClientUpdateOneRequiredWithoutProjectsInput>;
  owner?: Maybe<PersonUpdateOneWithoutProjectsInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutProjectInput>;
  time_entries?: Maybe<Time_EntriesUpdateManyWithoutProjectInput>;
  sales_split_pod?: Maybe<PodUpdateOneWithoutIncoming_Split_ProjectsInput>;
  sales_split_ratio?: Maybe<IntFieldUpdateOperationsInput>;
  project_rates?: Maybe<Project_RateUpdateManyWithoutProjectInput>;
  epoch?: Maybe<IntFieldUpdateOperationsInput>;
};

export type Query = {
  __typename?: 'Query';
  syncStatuses: Array<SyncStatus>;
  projects: Array<Project>;
  project?: Maybe<Project>;
  categories: Array<Category>;
  people: Array<Person>;
  invoices: Array<Invoice>;
  clients: Array<Client>;
  brands: Array<Brand>;
  companies: Array<Company>;
  clientInvoices: Array<ClientInvoice>;
  estimate?: Maybe<Estimate>;
  estimates: Array<Estimate>;
  grossProfitReport?: Maybe<GrossProfitReport>;
  podRevenueForecastReport?: Maybe<PodRevenueForecastReport>;
  pods: Array<Pod>;
  me?: Maybe<Person>;
  companyAnnualRevenueTargets: Array<CompanyAnnualRevenueTarget>;
  companyAnnualRevenueForecastProjects: Array<Project>;
  podAnnualRevenueTargets: Array<PodAnnualRevenueTarget>;
  senior_brand_maven_lead: Array<Scalars['String']>;
  clientById?: Maybe<Client>;
  projectById?: Maybe<Project>;
  projectRatesByProjectId: Array<ProjectRate>;
  clientInvoiceById?: Maybe<ClientInvoice>;
  clientAnnualRevenueForecast?: Maybe<ClientAnnualRevenueForecast>;
  clientAnnualRevenueForecasts: Array<ClientAnnualRevenueForecast>;
  peopleTimeTargetsAndEntries: Array<Person>;
  teams?: Maybe<Array<Maybe<Team>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  projectPricing?: Maybe<ProjectPricing>;
};


export type QueryProjectsArgs = {
  where?: Maybe<ProjectWhereInput>;
  orderBy?: Maybe<Array<ProjectOrderByWithRelationInput>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<ProjectWhereUniqueInput>;
  after?: Maybe<ProjectWhereUniqueInput>;
};


export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type QueryInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
};


export type QueryBrandsArgs = {
  where?: Maybe<BrandWhereInput>;
  orderBy?: Maybe<Array<BrandOrderByWithRelationInput>>;
};


export type QueryCompaniesArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<CompanyOrderByWithRelationInput>>;
};


export type QueryClientInvoicesArgs = {
  where?: Maybe<Client_InvoiceWhereInput>;
  orderBy?: Maybe<Array<Client_InvoiceOrderByWithRelationInput>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Client_InvoiceWhereUniqueInput>;
  after?: Maybe<Client_InvoiceWhereUniqueInput>;
};


export type QueryEstimateArgs = {
  where: EstimateWhereUniqueInput;
};


export type QueryEstimatesArgs = {
  where?: Maybe<EstimateWhereInput>;
  orderBy?: Maybe<Array<EstimateOrderByWithRelationInput>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<EstimateWhereUniqueInput>;
  after?: Maybe<EstimateWhereUniqueInput>;
};


export type QueryGrossProfitReportArgs = {
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
};


export type QueryPodRevenueForecastReportArgs = {
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
};


export type QueryCompanyAnnualRevenueTargetsArgs = {
  where?: Maybe<CompanyAnnualRevenueTargetWhereInput>;
  orderBy?: Maybe<Array<CompanyAnnualRevenueTargetOrderByWithRelationInput>>;
};


export type QueryCompanyAnnualRevenueForecastProjectsArgs = {
  year: Scalars['Int'];
};


export type QueryPodAnnualRevenueTargetsArgs = {
  where?: Maybe<PodAnnualRevenueTargetWhereInput>;
  orderBy?: Maybe<Array<PodAnnualRevenueTargetOrderByWithRelationInput>>;
};


export type QueryClientByIdArgs = {
  id: Scalars['String'];
};


export type QueryProjectByIdArgs = {
  id: Scalars['String'];
};


export type QueryProjectRatesByProjectIdArgs = {
  projectId: Scalars['String'];
};


export type QueryClientInvoiceByIdArgs = {
  id: Scalars['String'];
};


export type QueryClientAnnualRevenueForecastArgs = {
  year: Scalars['Int'];
  clientId: Scalars['String'];
  podId: Scalars['String'];
};


export type QueryClientAnnualRevenueForecastsArgs = {
  clientIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  podId: Scalars['String'];
};


export type QueryPeopleTimeTargetsAndEntriesArgs = {
  year: Scalars['Int'];
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
};


export type QueryProjectPricingArgs = {
  project_id?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneInvoice: Invoice;
  deleteOneInvoice?: Maybe<Invoice>;
  updateOneInvoice?: Maybe<Invoice>;
  createOneBrand: Brand;
  deleteOneBrand?: Maybe<Brand>;
  updateOneBrand?: Maybe<Brand>;
  createOneCompany: Company;
  deleteOneCompany?: Maybe<Company>;
  updateOneCompany?: Maybe<Company>;
  createOneClientInvoice: ClientInvoice;
  deleteOneClientInvoice?: Maybe<ClientInvoice>;
  updateOneClientInvoice?: Maybe<ClientInvoice>;
  createOneEstimate: Estimate;
  deleteOneEstimate?: Maybe<Estimate>;
  updateOneEstimate?: Maybe<Estimate>;
  createOneProject: Project;
  deleteOneProject?: Maybe<Project>;
  updateOneProject?: Maybe<Project>;
  createOnePod: Pod;
  deleteOnePod?: Maybe<Pod>;
  updateOnePod?: Maybe<Pod>;
  pullProjectDetailsFromTeamwork?: Maybe<Array<Maybe<Project>>>;
  upsertProjectComponentCode?: Maybe<Project>;
  upsertProjectBudget?: Maybe<Project>;
  upsertProjectClientBrandManager?: Maybe<Project>;
  upsertProjectNotes?: Maybe<Project>;
  upsertProjectNotes2?: Maybe<Project>;
  upsertProjectCategoryId?: Maybe<Project>;
  upsertProjectType?: Maybe<Project>;
  upsertProjectExternalReviewType?: Maybe<Project>;
  setProjectInternalReview?: Maybe<Project>;
  upsertProjectLanguage?: Maybe<Project>;
  upsertProjectStatus?: Maybe<Project>;
  upsertProjectOwner?: Maybe<Project>;
  upsertSBMLead?: Maybe<Project>;
  upsertProjectSalesSplitPod?: Maybe<Project>;
  upsertProjectSalesSplitRatioPercent?: Maybe<Project>;
  addPodMember?: Maybe<Pod>;
  removePodMember?: Maybe<Pod>;
  createRole?: Maybe<Role>;
  updateRole?: Maybe<Role>;
  deleteRole?: Maybe<Role>;
  createTeam?: Maybe<Team>;
  updateTeam?: Maybe<Team>;
  deleteTeam?: Maybe<Team>;
  assignUserToRole?: Maybe<Person>;
  assignUserToTeam?: Maybe<Person>;
  assignRoleAndTeamToUser?: Maybe<Person>;
  assignRoleAndTeamToUsers?: Maybe<BatchPayload>;
  createProjectPricing?: Maybe<ProjectPricing>;
  createProjectedExpense?: Maybe<ProjectedExpense>;
  updateProjectedExpense?: Maybe<ProjectedExpense>;
  deleteProjectedExpense?: Maybe<ProjectedExpense>;
  createPricingComment?: Maybe<PricingComment>;
  updatePricingComment?: Maybe<PricingComment>;
  deletePricingComment?: Maybe<PricingComment>;
  updateProjectedTime?: Maybe<ProjectedTime>;
  createProjectedTimes?: Maybe<BatchPayload>;
  addPersonAnnualTimeTargets?: Maybe<BatchPayload>;
  updateManyPersonAnnualTimeTargets?: Maybe<BatchPayload>;
  updatePersonAnnualTimeTarget?: Maybe<PersonAnnualTimeTarget>;
  addClientAnnualRevenueForecasts?: Maybe<BatchPayload>;
  addClientAnnualRevenueForecast?: Maybe<ClientAnnualRevenueForecast>;
  deleteClientAnnualRevenueForecast?: Maybe<ClientAnnualRevenueForecast>;
  updateClientAnnualRevenueForecast?: Maybe<ClientAnnualRevenueForecast>;
  markDayOff?: Maybe<DayOff>;
  removeDayOff?: Maybe<DayOff>;
  updateDayOffReason?: Maybe<DayOff>;
  markMultipleDaysOff: Array<Maybe<DayOff>>;
  createTimeEntry?: Maybe<TimeEntry>;
  deleteTimeEntry?: Maybe<TimeEntry>;
  setCompanyRevenueTarget?: Maybe<CompanyAnnualRevenueTarget>;
  setPodRevenueTarget?: Maybe<PodAnnualRevenueTarget>;
  setProjectBudgetAndAssociateToEstimate?: Maybe<Project>;
};


export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationUpdateOneInvoiceArgs = {
  data: InvoiceUpdateInput;
  where: InvoiceWhereUniqueInput;
};


export type MutationCreateOneBrandArgs = {
  data: BrandCreateInput;
};


export type MutationDeleteOneBrandArgs = {
  where: BrandWhereUniqueInput;
};


export type MutationUpdateOneBrandArgs = {
  data: BrandUpdateInput;
  where: BrandWhereUniqueInput;
};


export type MutationCreateOneCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationDeleteOneCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type MutationUpdateOneCompanyArgs = {
  data: CompanyUpdateInput;
  where: CompanyWhereUniqueInput;
};


export type MutationCreateOneClientInvoiceArgs = {
  data: Client_InvoiceCreateInput;
};


export type MutationDeleteOneClientInvoiceArgs = {
  where: Client_InvoiceWhereUniqueInput;
};


export type MutationUpdateOneClientInvoiceArgs = {
  data: Client_InvoiceUpdateInput;
  where: Client_InvoiceWhereUniqueInput;
};


export type MutationCreateOneEstimateArgs = {
  data: EstimateCreateInput;
};


export type MutationDeleteOneEstimateArgs = {
  where: EstimateWhereUniqueInput;
};


export type MutationUpdateOneEstimateArgs = {
  data: EstimateUpdateInput;
  where: EstimateWhereUniqueInput;
};


export type MutationCreateOneProjectArgs = {
  data: ProjectCreateInput;
};


export type MutationDeleteOneProjectArgs = {
  where: ProjectWhereUniqueInput;
};


export type MutationUpdateOneProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};


export type MutationCreateOnePodArgs = {
  data: PodCreateInput;
};


export type MutationDeleteOnePodArgs = {
  where: PodWhereUniqueInput;
};


export type MutationUpdateOnePodArgs = {
  data: PodUpdateInput;
  where: PodWhereUniqueInput;
};


export type MutationPullProjectDetailsFromTeamworkArgs = {
  projectIds: Array<Scalars['ID']>;
};


export type MutationUpsertProjectComponentCodeArgs = {
  projectId: Scalars['ID'];
  componentCode: Scalars['String'];
};


export type MutationUpsertProjectBudgetArgs = {
  projectId: Scalars['ID'];
  budget: Scalars['Int'];
};


export type MutationUpsertProjectClientBrandManagerArgs = {
  projectId: Scalars['ID'];
  clientBrandManager: Scalars['String'];
};


export type MutationUpsertProjectNotesArgs = {
  projectId: Scalars['ID'];
  newNotes: Scalars['String'];
};


export type MutationUpsertProjectNotes2Args = {
  projectId: Scalars['ID'];
  newNotes: Scalars['String'];
};


export type MutationUpsertProjectCategoryIdArgs = {
  projectId: Scalars['ID'];
  projectCategoryId: Scalars['ID'];
};


export type MutationUpsertProjectTypeArgs = {
  projectId: Scalars['ID'];
  projectType: Project_Type;
};


export type MutationUpsertProjectExternalReviewTypeArgs = {
  projectId: Scalars['ID'];
  externalReviewType: External_Reviewer;
};


export type MutationSetProjectInternalReviewArgs = {
  projectId: Scalars['ID'];
  internalReview: Scalars['Boolean'];
};


export type MutationUpsertProjectLanguageArgs = {
  projectId: Scalars['ID'];
  languageType: Project_Language;
};


export type MutationUpsertProjectStatusArgs = {
  projectId: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationUpsertProjectOwnerArgs = {
  projectId: Scalars['ID'];
  projectOwnerId: Scalars['ID'];
};


export type MutationUpsertSbmLeadArgs = {
  projectId: Scalars['ID'];
  sbmLeadName: Scalars['String'];
};


export type MutationUpsertProjectSalesSplitPodArgs = {
  projectId: Scalars['ID'];
  salesSplitPodId?: Maybe<Scalars['ID']>;
};


export type MutationUpsertProjectSalesSplitRatioPercentArgs = {
  projectId: Scalars['ID'];
  salesSplitRatioPercent?: Maybe<Scalars['Int']>;
};


export type MutationAddPodMemberArgs = {
  podId: Scalars['ID'];
  personId: Scalars['String'];
};


export type MutationRemovePodMemberArgs = {
  podId: Scalars['ID'];
  personId: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  roleTitle: Scalars['String'];
};


export type MutationUpdateRoleArgs = {
  id: Scalars['String'];
  roleTitle: Scalars['String'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['String'];
};


export type MutationCreateTeamArgs = {
  teamName: Scalars['String'];
};


export type MutationUpdateTeamArgs = {
  id: Scalars['String'];
  teamName: Scalars['String'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['String'];
};


export type MutationAssignUserToRoleArgs = {
  userId: Scalars['String'];
  roleId: Scalars['String'];
};


export type MutationAssignUserToTeamArgs = {
  userId: Scalars['String'];
  teamId: Scalars['String'];
};


export type MutationAssignRoleAndTeamToUserArgs = {
  userId: Scalars['String'];
  roleId: Scalars['String'];
  teamId: Scalars['String'];
};


export type MutationAssignRoleAndTeamToUsersArgs = {
  userIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
  teamId: Scalars['String'];
};


export type MutationCreateProjectPricingArgs = {
  projectId: Scalars['String'];
  tasklistId: Scalars['Int'];
  creatorId: Scalars['String'];
};


export type MutationCreateProjectedExpenseArgs = {
  projectId: Scalars['String'];
  projectPricingId: Scalars['Int'];
  budgetInCents: Scalars['Int'];
  expenseType: Scalars['String'];
  payeeName: Scalars['String'];
};


export type MutationUpdateProjectedExpenseArgs = {
  id: Scalars['Int'];
  budgetInCents: Scalars['Int'];
  expenseType: Scalars['String'];
  payeeName: Scalars['String'];
};


export type MutationDeleteProjectedExpenseArgs = {
  id: Scalars['Int'];
};


export type MutationCreatePricingCommentArgs = {
  projectId: Scalars['String'];
  projectPricingId: Scalars['Int'];
  tasklistId: Scalars['String'];
  createdById: Scalars['String'];
  isForCogs: Scalars['Boolean'];
  comment: Scalars['String'];
};


export type MutationUpdatePricingCommentArgs = {
  id: Scalars['Int'];
  comment: Scalars['String'];
};


export type MutationDeletePricingCommentArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateProjectedTimeArgs = {
  id: Scalars['Int'];
  minutes: Scalars['Int'];
};


export type MutationCreateProjectedTimesArgs = {
  projectId: Scalars['String'];
  projectPricingId: Scalars['String'];
  tasklistIds: Array<Scalars['String']>;
  roleIds: Array<Scalars['String']>;
  teamIds: Array<Scalars['String']>;
  minutes: Scalars['Int'];
};


export type MutationAddPersonAnnualTimeTargetsArgs = {
  personIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
};


export type MutationUpdateManyPersonAnnualTimeTargetsArgs = {
  personIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
};


export type MutationUpdatePersonAnnualTimeTargetArgs = {
  personId: Scalars['String'];
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
};


export type MutationAddClientAnnualRevenueForecastsArgs = {
  clientIds: Array<Scalars['String']>;
  podId: Scalars['String'];
  year: Scalars['Int'];
  lowForecastDollars?: Scalars['Int'];
  highForecastDollars?: Scalars['Int'];
  userId: Scalars['String'];
};


export type MutationAddClientAnnualRevenueForecastArgs = {
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
  lowForecastDollars: Scalars['Int'];
  highForecastDollars: Scalars['Int'];
  updatedByUserId: Scalars['String'];
};


export type MutationDeleteClientAnnualRevenueForecastArgs = {
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
};


export type MutationUpdateClientAnnualRevenueForecastArgs = {
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
  lowForecastDollars?: Maybe<Scalars['Int']>;
  highForecastDollars?: Maybe<Scalars['Int']>;
  updatedByUserId: Scalars['String'];
};


export type MutationMarkDayOffArgs = {
  date: Scalars['String'];
  reason: Scalars['String'];
  personId: Scalars['String'];
};


export type MutationRemoveDayOffArgs = {
  date: Scalars['String'];
  personId: Scalars['String'];
};


export type MutationUpdateDayOffReasonArgs = {
  date: Scalars['String'];
  personId: Scalars['String'];
  newReason: Scalars['String'];
};


export type MutationMarkMultipleDaysOffArgs = {
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  personId: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationCreateTimeEntryArgs = {
  personId: Scalars['String'];
  projectId: Scalars['String'];
  companyId: Scalars['String'];
  date: Scalars['String'];
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
  billable: Scalars['Boolean'];
};


export type MutationDeleteTimeEntryArgs = {
  id: Scalars['String'];
};


export type MutationSetCompanyRevenueTargetArgs = {
  year: Scalars['Int'];
  revenueTargetDollars: Scalars['Int'];
};


export type MutationSetPodRevenueTargetArgs = {
  podId: Scalars['ID'];
  year: Scalars['Int'];
  revenueTargetDollars: Scalars['Int'];
};


export type MutationSetProjectBudgetAndAssociateToEstimateArgs = {
  projectId: Scalars['ID'];
  budgetDollars: Scalars['Int'];
  estimateId: Scalars['ID'];
};

export type AdminPagePersonFragment = (
  { __typename?: 'Person' }
  & Pick<Person, 'id' | 'avatar_url' | 'first_name' | 'last_name' | 'title'>
);

export type AdminPagePodsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminPagePodsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'senior_brand_maven_lead'>
  & { pods: Array<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
    & { members: Array<(
      { __typename?: 'Person' }
      & AdminPagePersonFragment
    )> }
  )> }
);

export type AdminCreatePodMutationVariables = Exact<{
  data: PodCreateInput;
}>;


export type AdminCreatePodMutation = (
  { __typename?: 'Mutation' }
  & { createOnePod: (
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name'>
    & { members: Array<(
      { __typename?: 'Person' }
      & AdminPagePersonFragment
    )> }
  ) }
);

export type AdminDeletePodMutationVariables = Exact<{
  where: PodWhereUniqueInput;
}>;


export type AdminDeletePodMutation = (
  { __typename?: 'Mutation' }
  & { deleteOnePod?: Maybe<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id'>
  )> }
);

export type AdminUpdatePodMutationVariables = Exact<{
  updateOnePodData2: PodUpdateInput;
  updateOnePodWhere: PodWhereUniqueInput;
}>;


export type AdminUpdatePodMutation = (
  { __typename?: 'Mutation' }
  & { updateOnePod?: Maybe<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
    & { members: Array<(
      { __typename?: 'Person' }
      & AdminPagePersonFragment
    )> }
  )> }
);

export type AdminAddPodMemberMutationVariables = Exact<{
  podId: Scalars['ID'];
  personId: Scalars['String'];
}>;


export type AdminAddPodMemberMutation = (
  { __typename?: 'Mutation' }
  & { addPodMember?: Maybe<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id'>
    & { members: Array<(
      { __typename?: 'Person' }
      & AdminPagePersonFragment
    )> }
  )> }
);

export type AdminRemovePodMemberMutationVariables = Exact<{
  podId: Scalars['ID'];
  personId: Scalars['String'];
}>;


export type AdminRemovePodMemberMutation = (
  { __typename?: 'Mutation' }
  & { removePodMember?: Maybe<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id'>
    & { members: Array<(
      { __typename?: 'Person' }
      & AdminPagePersonFragment
    )> }
  )> }
);

export type AdminPagePeopleQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminPagePeopleQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & AdminPagePersonFragment
  )> }
);

export type AdminCreateRoleMutationVariables = Exact<{
  roleTitle: Scalars['String'];
}>;


export type AdminCreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'roleTitle'>
  )> }
);

export type AdminCreateTeamMutationVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type AdminCreateTeamMutation = (
  { __typename?: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamName'>
  )> }
);

export type AdminDeleteRoleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminDeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'roleTitle'>
  )> }
);

export type AdminDeleteTeamMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type AdminDeleteTeamMutation = (
  { __typename?: 'Mutation' }
  & { deleteTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamName'>
  )> }
);

export type AdminUpdateRoleMutationVariables = Exact<{
  id: Scalars['String'];
  roleTitle: Scalars['String'];
}>;


export type AdminUpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'roleTitle'>
  )> }
);

export type AdminUpdateTeamMutationVariables = Exact<{
  id: Scalars['String'];
  teamName: Scalars['String'];
}>;


export type AdminUpdateTeamMutation = (
  { __typename?: 'Mutation' }
  & { updateTeam?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamName'>
  )> }
);

export type AdminGetAllTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetAllTeamsQuery = (
  { __typename?: 'Query' }
  & { teams?: Maybe<Array<Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamName'>
  )>>> }
);

export type AdminGetAllRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminGetAllRolesQuery = (
  { __typename?: 'Query' }
  & { roles?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'roleTitle'>
  )>>> }
);

export type AdminAssignRoleMutationVariables = Exact<{
  userId: Scalars['String'];
  roleId: Scalars['String'];
}>;


export type AdminAssignRoleMutation = (
  { __typename?: 'Mutation' }
  & { assignUserToRole?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title'>
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'roleTitle'>
    )> }
  )> }
);

export type AdminAssignTeamMutationVariables = Exact<{
  userId: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type AdminAssignTeamMutation = (
  { __typename?: 'Mutation' }
  & { assignUserToTeam?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title'>
    & { team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'teamName'>
    )> }
  )> }
);

export type AdminAssignRoleAndTeamToUserMutationVariables = Exact<{
  userId: Scalars['String'];
  roleId: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type AdminAssignRoleAndTeamToUserMutation = (
  { __typename?: 'Mutation' }
  & { assignRoleAndTeamToUser?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title'>
    & { role?: Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'roleTitle'>
    )>, team?: Maybe<(
      { __typename?: 'Team' }
      & Pick<Team, 'id' | 'teamName'>
    )> }
  )> }
);

export type AdminAssignRoleAndTeamToUsersMutationVariables = Exact<{
  userIds: Array<Scalars['String']>;
  roleId: Scalars['String'];
  teamId: Scalars['String'];
}>;


export type AdminAssignRoleAndTeamToUsersMutation = (
  { __typename?: 'Mutation' }
  & { assignRoleAndTeamToUsers?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type RevenueTargetsQueryVariables = Exact<{
  companyAnnualRevenueTargetsStartingYear: Scalars['Int'];
  podAnnualRevenueTargetYear: Scalars['Int'];
}>;


export type RevenueTargetsQuery = (
  { __typename?: 'Query' }
  & { companyAnnualRevenueTargets: Array<(
    { __typename?: 'CompanyAnnualRevenueTarget' }
    & Pick<CompanyAnnualRevenueTarget, 'year' | 'revenue_target_dollars'>
  )>, pods: Array<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name'>
    & { annual_revenue_target: (
      { __typename?: 'PodAnnualRevenueTarget' }
      & Pick<PodAnnualRevenueTarget, 'podId' | 'revenue_target_dollars' | 'year'>
    ) }
  )> }
);

export type SetCompanyRevenueTargetMutationVariables = Exact<{
  year: Scalars['Int'];
  revenueTargetDollars: Scalars['Int'];
}>;


export type SetCompanyRevenueTargetMutation = (
  { __typename?: 'Mutation' }
  & { setCompanyRevenueTarget?: Maybe<(
    { __typename?: 'CompanyAnnualRevenueTarget' }
    & Pick<CompanyAnnualRevenueTarget, 'revenue_target_dollars' | 'year'>
  )> }
);

export type SetPodRevenueTargetMutationVariables = Exact<{
  podId: Scalars['ID'];
  year: Scalars['Int'];
  revenueTargetDollars: Scalars['Int'];
}>;


export type SetPodRevenueTargetMutation = (
  { __typename?: 'Mutation' }
  & { setPodRevenueTarget?: Maybe<(
    { __typename?: 'PodAnnualRevenueTarget' }
    & Pick<PodAnnualRevenueTarget, 'year' | 'podId' | 'revenue_target_dollars'>
  )> }
);

export type PeopleTimeTargetsAndEntriesQueryVariables = Exact<{
  year: Scalars['Int'];
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
}>;


export type PeopleTimeTargetsAndEntriesQuery = (
  { __typename?: 'Query' }
  & { peopleTimeTargetsAndEntries: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title' | 'rate' | 'company' | 'created_at' | 'is_deleted_on_tw'>
    & { annual_time_targets: Array<(
      { __typename?: 'PersonAnnualTimeTarget' }
      & Pick<PersonAnnualTimeTarget, 'id' | 'year' | 'billableMinutes' | 'nonBillableMinutes' | 'personId' | 'salesAndMarketingMinutes' | 'innovationAndDevMinutes' | 'operationalMinutes' | 'professionalDevMinutes'>
    )>, daysOff: Array<(
      { __typename?: 'DayOff' }
      & Pick<DayOff, 'id' | 'date' | 'reason' | 'personId'>
    )>, timeEntries: Array<(
      { __typename?: 'TimeEntry' }
      & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'user_id' | 'company_id' | 'project_id' | 'project_tag_id' | 'billable'>
      & { project: (
        { __typename?: 'Project' }
        & Pick<Project, 'name'>
      ) }
    )> }
  )> }
);

export type AddPersonAnnualTimeTargetsMutationVariables = Exact<{
  personIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
}>;


export type AddPersonAnnualTimeTargetsMutation = (
  { __typename?: 'Mutation' }
  & { addPersonAnnualTimeTargets?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdateManyPersonAnnualTimeTargetsMutationVariables = Exact<{
  personIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
}>;


export type UpdateManyPersonAnnualTimeTargetsMutation = (
  { __typename?: 'Mutation' }
  & { updateManyPersonAnnualTimeTargets?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdatePersonAnnualTimeTargetMutationVariables = Exact<{
  personId: Scalars['String'];
  year: Scalars['Int'];
  billableMinutes?: Maybe<Scalars['Int']>;
  nonBillableMinutes?: Maybe<Scalars['Int']>;
  salesAndMarketingMinutes?: Maybe<Scalars['Int']>;
  innovationAndDevMinutes?: Maybe<Scalars['Int']>;
  operationalMinutes?: Maybe<Scalars['Int']>;
  professionalDevMinutes?: Maybe<Scalars['Int']>;
}>;


export type UpdatePersonAnnualTimeTargetMutation = (
  { __typename?: 'Mutation' }
  & { updatePersonAnnualTimeTarget?: Maybe<(
    { __typename?: 'PersonAnnualTimeTarget' }
    & Pick<PersonAnnualTimeTarget, 'id' | 'year' | 'billableMinutes' | 'nonBillableMinutes' | 'salesAndMarketingMinutes' | 'innovationAndDevMinutes' | 'operationalMinutes' | 'professionalDevMinutes' | 'personId'>
  )> }
);

export type PeopleTimeByMonthQueryVariables = Exact<{
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
}>;


export type PeopleTimeByMonthQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title' | 'rate' | 'company' | 'created_at' | 'is_deleted_on_tw'>
    & { timeEntries: Array<(
      { __typename?: 'TimeEntry' }
      & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'user_id' | 'company_id' | 'project_id' | 'project_tag_id' | 'billable'>
    )> }
  )> }
);

export type DeleteClientInvoiceMutationVariables = Exact<{
  deleteOneClientInvoiceWhere: Client_InvoiceWhereUniqueInput;
}>;


export type DeleteClientInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneClientInvoice?: Maybe<(
    { __typename?: 'ClientInvoice' }
    & Pick<ClientInvoice, 'id'>
  )> }
);

export type ClientInvoicesListQueryVariables = Exact<{
  estimateWhere: EstimateWhereUniqueInput;
}>;


export type ClientInvoicesListQuery = (
  { __typename?: 'Query' }
  & { estimate?: Maybe<(
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id'>
    & { client_invoices: Array<(
      { __typename?: 'ClientInvoice' }
      & Pick<ClientInvoice, 'id' | 'created_at' | 'invoice_number' | 'date_invoiced' | 'amount_in_cents'>
      & { estimate: (
        { __typename?: 'Estimate' }
        & Pick<Estimate, 'id'>
      ) }
    )> }
  )> }
);

export type PeopleForNewEstimateQueryVariables = Exact<{ [key: string]: never; }>;


export type PeopleForNewEstimateQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'email_address' | 'first_name' | 'last_name' | 'title' | 'is_deleted_on_tw'>
  )> }
);

export type CompaniesForNewEstimateQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesForNewEstimateQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
  )> }
);

export type BrandsForNewEstimateQueryVariables = Exact<{ [key: string]: never; }>;


export type BrandsForNewEstimateQuery = (
  { __typename?: 'Query' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'brand_name'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  )> }
);

export type CreateEstimateMutationVariables = Exact<{
  createOneEstimateData: EstimateCreateInput;
}>;


export type CreateEstimateMutation = (
  { __typename?: 'Mutation' }
  & { createOneEstimate: (
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id'>
  ) }
);

export type CreateCompanyMutationVariables = Exact<{
  createOneCompanyData: CompanyCreateInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createOneCompany: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
  ) }
);

export type CreateBrandMutationVariables = Exact<{
  createOneBrandData: BrandCreateInput;
}>;


export type CreateBrandMutation = (
  { __typename?: 'Mutation' }
  & { createOneBrand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'brand_name'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id'>
    ) }
  ) }
);

export type EstimateProjectBudgetsQueryVariables = Exact<{
  where: EstimateWhereUniqueInput;
}>;


export type EstimateProjectBudgetsQuery = (
  { __typename?: 'Query' }
  & { estimate?: Maybe<(
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'name' | 'budget'>
    )> }
  )> }
);

export type EstimateForEstimatesPageQueryVariables = Exact<{
  estimatesWhere?: Maybe<EstimateWhereInput>;
}>;


export type EstimateForEstimatesPageQuery = (
  { __typename?: 'Query' }
  & { estimates: Array<(
    { __typename?: 'Estimate' }
    & EstimateFragmentForEstimatesPageFragment
  )> }
);

export type CompaniesForEstimatesTableQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesForEstimatesTableQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
    & { brands: Array<(
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'brand_name'>
    )> }
  )> }
);

export type UpdateEstimateMutationVariables = Exact<{
  updateOneEstimateData: EstimateUpdateInput;
  updateOneEstimateWhere: EstimateWhereUniqueInput;
}>;


export type UpdateEstimateMutation = (
  { __typename?: 'Mutation' }
  & { updateOneEstimate?: Maybe<(
    { __typename?: 'Estimate' }
    & PartEstimateFragment
  )> }
);

export type DeleteOneEstimateMutationVariables = Exact<{
  deleteOneEstimateWhere: EstimateWhereUniqueInput;
}>;


export type DeleteOneEstimateMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneEstimate?: Maybe<(
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id'>
  )> }
);

export type CreateOneClientInvoiceMutationVariables = Exact<{
  createOneClientInvoiceData: Client_InvoiceCreateInput;
}>;


export type CreateOneClientInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createOneClientInvoice: (
    { __typename?: 'ClientInvoice' }
    & Pick<ClientInvoice, 'id'>
    & { estimate: (
      { __typename?: 'Estimate' }
      & Pick<Estimate, 'id' | 'po_subtotal' | 'po_subtotal_remaining'>
    ) }
  ) }
);

export type EstimateFragmentForEstimatesPageFragment = (
  { __typename?: 'Estimate' }
  & Pick<Estimate, 'id' | 'date' | 'name' | 'po_number' | 'po_subtotal' | 'pdf_file_key' | 'cogs' | 'po_subtotal_remaining' | 'client_invoice_count' | 'estimate_status' | 'comments'>
  & { owner: (
    { __typename?: 'Person' }
    & Pick<Person, 'id'>
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
  ), brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'brand_name'>
  ), projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'budget'>
  )> }
);

export type ClientNewInvoiceFragmentFragment = (
  { __typename?: 'ClientInvoice' }
  & Pick<ClientInvoice, 'id' | 'created_at' | 'invoice_number' | 'date_invoiced' | 'amount_in_cents' | 'quickbooks_id' | 'invoice_status'>
  & { estimate: (
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id' | 'name' | 'po_number' | 'client_invoice_count' | 'pdf_file_key'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'company_name'>
    ), brand: (
      { __typename?: 'Brand' }
      & Pick<Brand, 'id' | 'brand_name'>
    ) }
  ) }
);

export type ClientInvoicesPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientInvoicesPageQuery = (
  { __typename?: 'Query' }
  & { clientInvoices: Array<(
    { __typename?: 'ClientInvoice' }
    & ClientNewInvoiceFragmentFragment
  )> }
);

export type GetClientInvoiceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetClientInvoiceQuery = (
  { __typename?: 'Query' }
  & { clientInvoiceById?: Maybe<(
    { __typename?: 'ClientInvoice' }
    & ClientNewInvoiceFragmentFragment
  )> }
);

export type UpdateClientInvoiceFromCreateInvoicesPageMutationVariables = Exact<{
  updateOneClientInvoiceData: Client_InvoiceUpdateInput;
  updateOneClientInvoiceWhere: Client_InvoiceWhereUniqueInput;
}>;


export type UpdateClientInvoiceFromCreateInvoicesPageMutation = (
  { __typename?: 'Mutation' }
  & { updateOneClientInvoice?: Maybe<(
    { __typename?: 'ClientInvoice' }
    & ClientNewInvoiceFragmentFragment
  )> }
);

export type GrossProfitSummaryQueryVariables = Exact<{
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
}>;


export type GrossProfitSummaryQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name'>
  )>, grossProfitReport?: Maybe<(
    { __typename?: 'GrossProfitReport' }
    & Pick<GrossProfitReport, 'id'>
    & { entries: Array<(
      { __typename?: 'GrossProfitReportEstimateEntry' }
      & Pick<GrossProfitReportEstimateEntry, 'estimate_id' | 'estimate_name' | 'estimate_date' | 'owner_id' | 'estimate_po_subtotal' | 'expense_amount'>
    )> }
  )> }
);

export type GetProjectsForBrandAnalysisQueryVariables = Exact<{
  where?: Maybe<ProjectWhereInput>;
}>;


export type GetProjectsForBrandAnalysisQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'project_status' | 'senior_brand_maven_lead' | 'owner_id' | 'start_date' | 'end_date' | 'budget' | 'sales_split_ratio' | 'created_at'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title'>
    )>, client: (
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
    ), time_entries: Array<(
      { __typename?: 'TimeEntry' }
      & Pick<TimeEntry, 'id' | 'minutes' | 'user_id'>
    )>, rates: Array<(
      { __typename?: 'ProjectRate' }
      & Pick<ProjectRate, 'id' | 'person_id' | 'rate_in_cents'>
    )>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'amount_in_cents'>
    )>, sales_split_pod?: Maybe<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
    )> }
  )> }
);

export type GetClientAnnualRevenueForecastQueryVariables = Exact<{
  client_id: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
}>;


export type GetClientAnnualRevenueForecastQuery = (
  { __typename?: 'Query' }
  & { clientAnnualRevenueForecast?: Maybe<(
    { __typename?: 'ClientAnnualRevenueForecast' }
    & Pick<ClientAnnualRevenueForecast, 'id' | 'clientId' | 'podId' | 'year' | 'lowForecastDollars' | 'highForecastDollars' | 'updatedAt' | 'updatedByUserId'>
  )> }
);

export type GetClientAnnualRevenueForecastsQueryVariables = Exact<{
  clientIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  podId: Scalars['String'];
}>;


export type GetClientAnnualRevenueForecastsQuery = (
  { __typename?: 'Query' }
  & { clientAnnualRevenueForecasts: Array<(
    { __typename?: 'ClientAnnualRevenueForecast' }
    & Pick<ClientAnnualRevenueForecast, 'id' | 'clientId' | 'podId' | 'year' | 'lowForecastDollars' | 'highForecastDollars' | 'updatedAt' | 'updatedByUserId'>
  )> }
);

export type AddClientAnnualRevenueForecastsMutationVariables = Exact<{
  clientIds: Array<Scalars['String']>;
  year: Scalars['Int'];
  podId: Scalars['String'];
  lowForecastDollars?: Maybe<Scalars['Int']>;
  highForecastDollars?: Maybe<Scalars['Int']>;
  userId: Scalars['String'];
}>;


export type AddClientAnnualRevenueForecastsMutation = (
  { __typename?: 'Mutation' }
  & { addClientAnnualRevenueForecasts?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type AddClientAnnualRevenueForecastMutationVariables = Exact<{
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
  lowForecastDollars: Scalars['Int'];
  highForecastDollars: Scalars['Int'];
  updatedByUserId: Scalars['String'];
}>;


export type AddClientAnnualRevenueForecastMutation = (
  { __typename?: 'Mutation' }
  & { addClientAnnualRevenueForecast?: Maybe<(
    { __typename?: 'ClientAnnualRevenueForecast' }
    & Pick<ClientAnnualRevenueForecast, 'id' | 'clientId' | 'podId' | 'year' | 'lowForecastDollars' | 'highForecastDollars' | 'updatedByUserId'>
  )> }
);

export type UpdateClientAnnualRevenueForecastMutationVariables = Exact<{
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
  lowForecastDollars?: Maybe<Scalars['Int']>;
  highForecastDollars?: Maybe<Scalars['Int']>;
  updatedByUserId: Scalars['String'];
}>;


export type UpdateClientAnnualRevenueForecastMutation = (
  { __typename?: 'Mutation' }
  & { updateClientAnnualRevenueForecast?: Maybe<(
    { __typename?: 'ClientAnnualRevenueForecast' }
    & Pick<ClientAnnualRevenueForecast, 'id' | 'clientId' | 'year' | 'lowForecastDollars' | 'highForecastDollars' | 'updatedByUserId'>
  )> }
);

export type DeleteClientAnnualRevenueForecastMutationVariables = Exact<{
  clientId: Scalars['String'];
  year: Scalars['Int'];
  podId: Scalars['String'];
}>;


export type DeleteClientAnnualRevenueForecastMutation = (
  { __typename?: 'Mutation' }
  & { deleteClientAnnualRevenueForecast?: Maybe<(
    { __typename?: 'ClientAnnualRevenueForecast' }
    & Pick<ClientAnnualRevenueForecast, 'clientId' | 'year' | 'podId'>
  )> }
);

export type CompanyRevenueForecastQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type CompanyRevenueForecastQuery = (
  { __typename?: 'Query' }
  & { companyAnnualRevenueTargets: Array<(
    { __typename?: 'CompanyAnnualRevenueTarget' }
    & Pick<CompanyAnnualRevenueTarget, 'year' | 'revenue_target_dollars' | 'net_sales_potential_cents' | 'net_sales_earned_cents'>
  )>, companyAnnualRevenueForecastProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'project_status' | 'end_date' | 'cogs_in_cents' | 'budget' | 'senior_brand_maven_lead' | 'sales_split_ratio'>
    & { sales_split_pod?: Maybe<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
    )> }
  )>, pods: Array<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
    & { annual_revenue_target: (
      { __typename?: 'PodAnnualRevenueTarget' }
      & Pick<PodAnnualRevenueTarget, 'podId' | 'revenue_target_dollars' | 'year'>
    ) }
  )> }
);

export type PodRevenueForecastQueryVariables = Exact<{
  year: Scalars['Int'];
}>;


export type PodRevenueForecastQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id'>
    & { pods: Array<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
      & { annual_revenue_target: (
        { __typename?: 'PodAnnualRevenueTarget' }
        & Pick<PodAnnualRevenueTarget, 'podId' | 'year' | 'revenue_target_dollars'>
      ), projects: Array<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'project_status' | 'start_date' | 'end_date' | 'cogs_in_cents' | 'budget' | 'senior_brand_maven_lead' | 'sales_split_ratio'>
        & { sales_split_pod?: Maybe<(
          { __typename?: 'Pod' }
          & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
        )> }
      )> }
    )> }
  )> }
);

export type NavQueryVariables = Exact<{ [key: string]: never; }>;


export type NavQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title' | 'email_address' | 'avatar_url'>
  )>, me?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'email_address' | 'first_name' | 'last_name' | 'avatar_url'>
    & { pods: Array<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'name'>
      & { members: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'first_name' | 'last_name' | 'email_address'>
      )> }
    )> }
  )> }
);

export type GetProjectPricingQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectPricingQuery = (
  { __typename?: 'Query' }
  & { projectPricing?: Maybe<(
    { __typename?: 'ProjectPricing' }
    & Pick<ProjectPricing, 'id' | 'project_id' | 'price' | 'actual_cost' | 'currency'>
    & { projected_times: Array<(
      { __typename?: 'ProjectedTime' }
      & Pick<ProjectedTime, 'id' | 'tasklist_id' | 'role_id' | 'team_id' | 'projected_minutes'>
    )>, projected_expenses: Array<(
      { __typename?: 'ProjectedExpense' }
      & Pick<ProjectedExpense, 'id' | 'project_id' | 'project_pricing_id' | 'budget_in_cents' | 'expense_type' | 'payee_name'>
    )> }
  )> }
);

export type GetProjectRatesByProjectIdQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectRatesByProjectIdQuery = (
  { __typename?: 'Query' }
  & { projectRatesByProjectId: Array<(
    { __typename?: 'ProjectRate' }
    & Pick<ProjectRate, 'id' | 'rate_in_cents' | 'project_id'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'name'>
    ), person: (
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'first_name' | 'last_name'>
      & { team?: Maybe<(
        { __typename?: 'Team' }
        & Pick<Team, 'id' | 'teamName'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'id' | 'roleTitle'>
      )> }
    ) }
  )> }
);

export type CreateProjectPricingMutationVariables = Exact<{
  projectId: Scalars['String'];
  tasklistId: Scalars['Int'];
  creatorId: Scalars['String'];
}>;


export type CreateProjectPricingMutation = (
  { __typename?: 'Mutation' }
  & { createProjectPricing?: Maybe<(
    { __typename?: 'ProjectPricing' }
    & Pick<ProjectPricing, 'id' | 'project_id' | 'price' | 'actual_cost' | 'currency'>
    & { projected_times: Array<(
      { __typename?: 'ProjectedTime' }
      & Pick<ProjectedTime, 'id' | 'tasklist_id' | 'role_id' | 'team_id' | 'projected_minutes'>
    )> }
  )> }
);

export type CreateProjectPricingProjectedTimesMutationVariables = Exact<{
  projectId: Scalars['String'];
  projectPricingId: Scalars['String'];
  tasklistIds: Array<Scalars['String']>;
  roleIds: Array<Scalars['String']>;
  teamIds: Array<Scalars['String']>;
  minutes: Scalars['Int'];
}>;


export type CreateProjectPricingProjectedTimesMutation = (
  { __typename?: 'Mutation' }
  & { createProjectedTimes?: Maybe<(
    { __typename?: 'BatchPayload' }
    & Pick<BatchPayload, 'count'>
  )> }
);

export type UpdateProjectedTimeMutationVariables = Exact<{
  id: Scalars['Int'];
  minutes: Scalars['Int'];
}>;


export type UpdateProjectedTimeMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectedTime?: Maybe<(
    { __typename?: 'ProjectedTime' }
    & Pick<ProjectedTime, 'id' | 'tasklist_id' | 'role_id' | 'team_id' | 'projected_minutes'>
  )> }
);

export type CreateProjectedExpenseMutationVariables = Exact<{
  projectId: Scalars['String'];
  projectPricingId: Scalars['Int'];
  budgetInCents: Scalars['Int'];
  expenseType: Scalars['String'];
  payeeName: Scalars['String'];
}>;


export type CreateProjectedExpenseMutation = (
  { __typename?: 'Mutation' }
  & { createProjectedExpense?: Maybe<(
    { __typename?: 'ProjectedExpense' }
    & Pick<ProjectedExpense, 'id' | 'project_id' | 'project_pricing_id' | 'budget_in_cents' | 'expense_type' | 'payee_name'>
  )> }
);

export type UpdateProjectedExpenseMutationVariables = Exact<{
  id: Scalars['Int'];
  budgetInCents: Scalars['Int'];
  expenseType: Scalars['String'];
  payeeName: Scalars['String'];
}>;


export type UpdateProjectedExpenseMutation = (
  { __typename?: 'Mutation' }
  & { updateProjectedExpense?: Maybe<(
    { __typename?: 'ProjectedExpense' }
    & Pick<ProjectedExpense, 'id' | 'project_id' | 'project_pricing_id' | 'budget_in_cents' | 'expense_type' | 'payee_name'>
  )> }
);

export type DeleteProjectedExpenseMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProjectedExpenseMutation = (
  { __typename?: 'Mutation' }
  & { deleteProjectedExpense?: Maybe<(
    { __typename?: 'ProjectedExpense' }
    & Pick<ProjectedExpense, 'id' | 'project_id' | 'project_pricing_id' | 'budget_in_cents' | 'expense_type' | 'payee_name'>
  )> }
);

export type SearchProjectsForEstimatorQueryVariables = Exact<{
  keyword?: Maybe<Scalars['String']>;
}>;


export type SearchProjectsForEstimatorQuery = (
  { __typename?: 'Query' }
  & { searchProjects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )> }
);

export type CreatePricingCommentMutationVariables = Exact<{
  projectId: Scalars['String'];
  projectPricingId: Scalars['Int'];
  tasklistId: Scalars['String'];
  createdById: Scalars['String'];
  isForCogs: Scalars['Boolean'];
  comment: Scalars['String'];
}>;


export type CreatePricingCommentMutation = (
  { __typename?: 'Mutation' }
  & { createPricingComment?: Maybe<(
    { __typename?: 'PricingComment' }
    & Pick<PricingComment, 'id' | 'project_id' | 'project_pricing_id' | 'tasklist_id' | 'created_by_id' | 'is_for_cogs' | 'comment' | 'created_at'>
  )> }
);

export type UpdatePricingCommentMutationVariables = Exact<{
  id: Scalars['Int'];
  comment: Scalars['String'];
}>;


export type UpdatePricingCommentMutation = (
  { __typename?: 'Mutation' }
  & { updatePricingComment?: Maybe<(
    { __typename?: 'PricingComment' }
    & Pick<PricingComment, 'id' | 'project_id' | 'project_pricing_id' | 'tasklist_id' | 'created_by_id' | 'comment'>
  )> }
);

export type DeletePricingCommentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePricingCommentMutation = (
  { __typename?: 'Mutation' }
  & { deletePricingComment?: Maybe<(
    { __typename?: 'PricingComment' }
    & Pick<PricingComment, 'id' | 'project_id' | 'project_pricing_id' | 'tasklist_id' | 'created_by_id' | 'comment' | 'created_at'>
  )> }
);

export type UpsertProjectOwnerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  projectOwnerId: Scalars['ID'];
}>;


export type UpsertProjectOwnerMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectOwner?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { owner?: Maybe<(
      { __typename?: 'Person' }
      & Pick<Person, 'id' | 'first_name' | 'last_name'>
    )> }
  )> }
);

export type UpsertProjectSbmLeadMutationVariables = Exact<{
  projectId: Scalars['ID'];
  sbmLeadName: Scalars['String'];
}>;


export type UpsertProjectSbmLeadMutation = (
  { __typename?: 'Mutation' }
  & { upsertSBMLead?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'senior_brand_maven_lead'>
  )> }
);

export type UpsertProjectSalesSplitPodMutationVariables = Exact<{
  projectId: Scalars['ID'];
  salesSplitPodId?: Maybe<Scalars['ID']>;
}>;


export type UpsertProjectSalesSplitPodMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectSalesSplitPod?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'senior_brand_maven_lead'>
    & { sales_split_pod?: Maybe<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
    )> }
  )> }
);

export type UpsertProjectSalesSplitRatioPercentMutationVariables = Exact<{
  projectId: Scalars['ID'];
  salesSplitRatioPercent?: Maybe<Scalars['Int']>;
}>;


export type UpsertProjectSalesSplitRatioPercentMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectSalesSplitRatioPercent?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'senior_brand_maven_lead' | 'sales_split_ratio'>
    & { sales_split_pod?: Maybe<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
    )> }
  )> }
);

export type UpsertProjectNotes2MutationVariables = Exact<{
  projectId: Scalars['ID'];
  newNotes: Scalars['String'];
}>;


export type UpsertProjectNotes2Mutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectNotes2?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'project_notes_2'>
  )> }
);

export type ProjectFragmentFragment = (
  { __typename?: 'Project' }
  & Pick<Project, 'id' | 'name' | 'description' | 'owner_id' | 'client_brand_manager' | 'budget' | 'component_code' | 'project_type' | 'project_notes' | 'project_notes_2' | 'is_reviewed' | 'language' | 'internal_review' | 'external_reviewer' | 'last_synced_details_at' | 'project_status' | 'start_date' | 'end_date' | 'created_at' | 'senior_brand_maven_lead' | 'sales_split_ratio'>
  & { owner?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title'>
  )>, client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  ), category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )>, time_entries: Array<(
    { __typename?: 'TimeEntry' }
    & Pick<TimeEntry, 'id' | 'minutes' | 'user_id'>
  )>, rates: Array<(
    { __typename?: 'ProjectRate' }
    & Pick<ProjectRate, 'id' | 'person_id' | 'rate_in_cents'>
  )>, invoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'amount_in_cents'>
  )>, associated_estimate?: Maybe<(
    { __typename?: 'Estimate' }
    & Pick<Estimate, 'id' | 'name' | 'po_subtotal'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'budget'>
    )> }
  )>, sales_split_pod?: Maybe<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
  )> }
);

export type GetProjectsQueryVariables = Exact<{
  where?: Maybe<ProjectWhereInput>;
}>;


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )> }
);

export type UpsertProjectComponentCodeMutationVariables = Exact<{
  projectId: Scalars['ID'];
  newComponentCode: Scalars['String'];
}>;


export type UpsertProjectComponentCodeMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectComponentCode?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'component_code'>
  )> }
);

export type UpsertProjectCategoryMutationVariables = Exact<{
  upsertProjectCategoryIdProjectId: Scalars['ID'];
  upsertProjectCategoryIdProjectCategoryId: Scalars['ID'];
}>;


export type UpsertProjectCategoryMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectCategoryId?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name'>
    )> }
  )> }
);

export type UpsertProjectBudgetMutationVariables = Exact<{
  projectId: Scalars['ID'];
  budget: Scalars['Int'];
}>;


export type UpsertProjectBudgetMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectBudget?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'budget'>
  )> }
);

export type UpsertProjectClientBrandManagerMutationVariables = Exact<{
  projectId: Scalars['ID'];
  clientBrandManager: Scalars['String'];
}>;


export type UpsertProjectClientBrandManagerMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectClientBrandManager?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'client_brand_manager'>
  )> }
);

export type UpsertProjectNotesMutationVariables = Exact<{
  projectId: Scalars['ID'];
  newNotes: Scalars['String'];
}>;


export type UpsertProjectNotesMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectNotes?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'project_notes'>
  )> }
);

export type UpsertProjectTypeMutationVariables = Exact<{
  upsertProjectTypeProjectId: Scalars['ID'];
  upsertProjectTypeProjectType: Project_Type;
}>;


export type UpsertProjectTypeMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectType?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'project_type'>
  )> }
);

export type UpsertProjectLanguageMutationVariables = Exact<{
  upsertProjectLanguageProjectId: Scalars['ID'];
  upsertProjectLanguageLanguageType: Project_Language;
}>;


export type UpsertProjectLanguageMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectLanguage?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'language'>
  )> }
);

export type UpsertProjectStatusMutationVariables = Exact<{
  upsertProjectStatusProjectId: Scalars['ID'];
  upsertProjectStatusStatus: Scalars['String'];
}>;


export type UpsertProjectStatusMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectStatus?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'project_status'>
  )> }
);

export type UpsertProjectExternalReviewTypeMutationVariables = Exact<{
  upsertProjectExternalReviewTypeProjectId: Scalars['ID'];
  upsertProjectExternalReviewTypeExternalReviewType: External_Reviewer;
}>;


export type UpsertProjectExternalReviewTypeMutation = (
  { __typename?: 'Mutation' }
  & { upsertProjectExternalReviewType?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'external_reviewer'>
  )> }
);

export type SetProjectInternalReviewMutationVariables = Exact<{
  setProjectInternalReviewProjectId: Scalars['ID'];
  setProjectInternalReviewInternalReview: Scalars['Boolean'];
}>;


export type SetProjectInternalReviewMutation = (
  { __typename?: 'Mutation' }
  & { setProjectInternalReview?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'internal_review'>
  )> }
);

export type UpdateOneProjectMutationVariables = Exact<{
  updateOneProjectData: ProjectUpdateInput;
  updateOneProjectWhere: ProjectWhereUniqueInput;
}>;


export type UpdateOneProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateOneProject?: Maybe<(
    { __typename?: 'Project' }
    & ProjectFragmentFragment
  )> }
);

export type PullProjectDetailsFromTeamworkMutationVariables = Exact<{
  projectIds: Array<Scalars['ID']>;
}>;


export type PullProjectDetailsFromTeamworkMutation = (
  { __typename?: 'Mutation' }
  & { pullProjectDetailsFromTeamwork?: Maybe<Array<Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & ProjectFragmentFragment
  )>>> }
);

export type ProjectInvoicesQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ProjectInvoicesQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id'>
    & { invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id'>
      & InvoiceFragmentFragment
    )> }
  )> }
);

export type UnassociatdInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnassociatdInvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
    & InvoiceFragmentFragment
  )> }
);

export type PeopleAndProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type PeopleAndProjectsQuery = (
  { __typename?: 'Query' }
  & { people: Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name'>
  )>, projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'component_code' | 'name'>
  )> }
);

export type CreateInvoiceMutationVariables = Exact<{
  createOneinvoicesData: InvoiceCreateInput;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createOneInvoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'cogs_in_cents'>
      & { invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id'>
      )> }
    )>, files: Array<(
      { __typename?: 'SupplierInvoiceFile' }
      & Pick<SupplierInvoiceFile, 'id' | 'contentType' | 'fileName'>
      & { createdBy: (
        { __typename?: 'Person' }
        & Pick<Person, 'id'>
      ) }
    )> }
  ) }
);

export type InvoicesByInvoiceNumberQueryVariables = Exact<{
  where?: Maybe<InvoiceWhereInput>;
}>;


export type InvoicesByInvoiceNumberQuery = (
  { __typename?: 'Query' }
  & { invoices: Array<(
    { __typename?: 'Invoice' }
    & InvoiceFragmentFragment
  )> }
);

export type DeleteInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
}>;


export type DeleteInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteOneInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id'>
  )> }
);

export type InvoiceFragmentFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'invoice_number' | 'amount_in_cents' | 'expense_type' | 'date_created' | 'payee_name' | 'notes'>
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'component_code' | 'name'>
  )>, contractor?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name'>
  )>, files: Array<(
    { __typename?: 'SupplierInvoiceFile' }
    & Pick<SupplierInvoiceFile, 'id' | 'contentType' | 'fileName'>
    & { createdBy: (
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    ) }
  )> }
);

export type PromptProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type PromptProjectQuery = (
  { __typename?: 'Query' }
  & { projects: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'component_code' | 'name'>
  )> }
);

export type UpdateInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String'];
  invoiceData: InvoiceUpdateInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateOneInvoice?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoice_number' | 'amount_in_cents' | 'expense_type' | 'notes'>
    & { project?: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id'>
      & { invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'amount_in_cents'>
      )> }
    )> }
  )> }
);

export type CompanyNameByCompanyIdQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type CompanyNameByCompanyIdQuery = (
  { __typename?: 'Query' }
  & { clientById?: Maybe<(
    { __typename?: 'Client' }
    & Pick<Client, 'name'>
  )> }
);

export type ProjectNameByProjectIdQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type ProjectNameByProjectIdQuery = (
  { __typename?: 'Query' }
  & { projectById?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'name'>
  )> }
);

export type UserTimeSummaryQueryVariables = Exact<{
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
  year: Scalars['Int'];
}>;


export type UserTimeSummaryQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name' | 'created_at'>
    & { annual_time_targets: Array<(
      { __typename?: 'PersonAnnualTimeTarget' }
      & Pick<PersonAnnualTimeTarget, 'id' | 'billableMinutes' | 'nonBillableMinutes' | 'salesAndMarketingMinutes' | 'innovationAndDevMinutes' | 'operationalMinutes' | 'professionalDevMinutes' | 'year' | 'personId'>
    )>, timeEntries: Array<(
      { __typename?: 'TimeEntry' }
      & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'user_id' | 'company_id' | 'project_id' | 'project_tag_id'>
    )>, daysOff: Array<(
      { __typename?: 'DayOff' }
      & Pick<DayOff, 'date' | 'reason' | 'personId'>
    )> }
  )> }
);

export type AddDayOffMutationVariables = Exact<{
  personId: Scalars['String'];
  date: Scalars['String'];
  reason: Scalars['String'];
}>;


export type AddDayOffMutation = (
  { __typename?: 'Mutation' }
  & { markDayOff?: Maybe<(
    { __typename?: 'DayOff' }
    & Pick<DayOff, 'date' | 'reason' | 'personId'>
  )> }
);

export type RemoveDayOffMutationVariables = Exact<{
  personId: Scalars['String'];
  date: Scalars['String'];
}>;


export type RemoveDayOffMutation = (
  { __typename?: 'Mutation' }
  & { removeDayOff?: Maybe<(
    { __typename?: 'DayOff' }
    & Pick<DayOff, 'date' | 'reason' | 'personId'>
  )> }
);

export type MarkMultipleDaysOffMutationVariables = Exact<{
  personId: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  reason: Scalars['String'];
}>;


export type MarkMultipleDaysOffMutation = (
  { __typename?: 'Mutation' }
  & { markMultipleDaysOff: Array<Maybe<(
    { __typename?: 'DayOff' }
    & Pick<DayOff, 'date' | 'reason' | 'personId'>
  )>> }
);

export type AddTimeLogMutationVariables = Exact<{
  personId: Scalars['String'];
  date: Scalars['String'];
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
  projectId: Scalars['String'];
  companyId: Scalars['String'];
  billable: Scalars['Boolean'];
}>;


export type AddTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { createTimeEntry?: Maybe<(
    { __typename?: 'TimeEntry' }
    & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'user_id' | 'company_id' | 'project_id'>
  )> }
);

export type DeleteTimeLogMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTimeLogMutation = (
  { __typename?: 'Mutation' }
  & { deleteTimeEntry?: Maybe<(
    { __typename?: 'TimeEntry' }
    & Pick<TimeEntry, 'id'>
  )> }
);

export type UserPodTimeSummaryQueryVariables = Exact<{
  start_date: Scalars['Date'];
  end_date: Scalars['Date'];
  year: Scalars['Int'];
}>;


export type UserPodTimeSummaryQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'first_name' | 'last_name'>
    & { pod: Array<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'name' | 'senior_brand_maven_lead'>
      & { members: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'first_name' | 'last_name' | 'id'>
        & { annual_time_targets: Array<(
          { __typename?: 'PersonAnnualTimeTarget' }
          & Pick<PersonAnnualTimeTarget, 'id' | 'billableMinutes' | 'nonBillableMinutes' | 'salesAndMarketingMinutes' | 'innovationAndDevMinutes' | 'operationalMinutes' | 'professionalDevMinutes' | 'year' | 'personId'>
        )>, timeEntries: Array<(
          { __typename?: 'TimeEntry' }
          & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'user_id' | 'company_id' | 'project_id'>
        )>, daysOff: Array<(
          { __typename?: 'DayOff' }
          & Pick<DayOff, 'date' | 'reason' | 'personId'>
        )> }
      )> }
    )> }
  )> }
);

export type PartEstimateFragment = (
  { __typename?: 'Estimate' }
  & Pick<Estimate, 'id' | 'date' | 'name' | 'po_number' | 'po_subtotal' | 'comments'>
  & { owner: (
    { __typename?: 'Person' }
    & Pick<Person, 'id'>
  ), company: (
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
  ), brand: (
    { __typename?: 'Brand' }
    & Pick<Brand, 'id' | 'brand_name'>
  ) }
);

export type EstimatesQueryVariables = Exact<{ [key: string]: never; }>;


export type EstimatesQuery = (
  { __typename?: 'Query' }
  & { estimates: Array<(
    { __typename?: 'Estimate' }
    & PartEstimateFragment
  )> }
);

export type PeopleDataQueryVariables = Exact<{ [key: string]: never; }>;


export type PeopleDataQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'Person' }
    & { pods: Array<(
      { __typename?: 'Pod' }
      & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
      & { members: Array<(
        { __typename?: 'Person' }
        & Pick<Person, 'id' | 'first_name'>
      )> }
    )> }
    & PersonFragment
  )>, people: Array<(
    { __typename?: 'Person' }
    & PersonFragment
  )>, pods: Array<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'name' | 'senior_brand_maven_lead'>
    & { members: Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'id'>
    )> }
  )> }
);

export type PersonFragment = (
  { __typename?: 'Person' }
  & Pick<Person, 'id' | 'first_name' | 'last_name' | 'title' | 'rate' | 'email_address' | 'company' | 'is_deleted_on_tw'>
  & { pods: Array<(
    { __typename?: 'Pod' }
    & Pick<Pod, 'id' | 'senior_brand_maven_lead'>
  )>, role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'roleTitle'>
  )>, team?: Maybe<(
    { __typename?: 'Team' }
    & Pick<Team, 'id' | 'teamName'>
  )> }
);

export type GetProjectPricingDetailsByIdQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;


export type GetProjectPricingDetailsByIdQuery = (
  { __typename?: 'Query' }
  & { project?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'name' | 'last_synced_details_at' | 'budget' | 'project_status'>
    & { invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'expense_type' | 'payee_name' | 'amount_in_cents' | 'date_created'>
    )>, time_entries: Array<(
      { __typename?: 'TimeEntry' }
      & Pick<TimeEntry, 'id' | 'date' | 'minutes' | 'tasklist_id' | 'user_id'>
    )>, project_pricing?: Maybe<(
      { __typename?: 'ProjectPricing' }
      & Pick<ProjectPricing, 'id' | 'price' | 'actual_cost' | 'currency'>
      & { projected_times: Array<(
        { __typename?: 'ProjectedTime' }
        & Pick<ProjectedTime, 'id' | 'tasklist_id' | 'role_id' | 'team_id' | 'projected_minutes'>
      )>, projected_expenses: Array<(
        { __typename?: 'ProjectedExpense' }
        & Pick<ProjectedExpense, 'id' | 'project_id' | 'project_pricing_id' | 'budget_in_cents' | 'expense_type' | 'payee_name'>
      )>, comments: Array<(
        { __typename?: 'PricingComment' }
        & Pick<PricingComment, 'id' | 'project_id' | 'project_pricing_id' | 'tasklist_id' | 'comment' | 'created_at' | 'created_by_id' | 'is_for_cogs'>
        & { created_by: (
          { __typename?: 'Person' }
          & Pick<Person, 'id' | 'first_name' | 'last_name' | 'avatar_url'>
        ) }
      )> }
    )> }
  )> }
);

export const AdminPagePersonFragmentDoc = gql`
    fragment AdminPagePerson on Person {
  id
  avatar_url
  first_name
  last_name
  title
}
    `;
export const EstimateFragmentForEstimatesPageFragmentDoc = gql`
    fragment EstimateFragmentForEstimatesPage on Estimate {
  id
  date
  owner {
    id
  }
  name
  po_number
  po_subtotal
  pdf_file_key
  company {
    id
    company_name
  }
  brand {
    id
    brand_name
  }
  projects {
    id
    budget
  }
  cogs
  po_subtotal_remaining
  client_invoice_count
  estimate_status
  comments
}
    `;
export const ClientNewInvoiceFragmentFragmentDoc = gql`
    fragment ClientNewInvoiceFragment on ClientInvoice {
  id
  created_at
  invoice_number
  date_invoiced
  amount_in_cents
  quickbooks_id
  invoice_status
  estimate {
    id
    name
    po_number
    client_invoice_count
    pdf_file_key
    company {
      id
      company_name
    }
    brand {
      id
      brand_name
    }
  }
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  id
  name
  description
  owner_id
  owner {
    id
    first_name
    last_name
    title
  }
  client {
    id
    name
  }
  client_brand_manager
  budget
  component_code
  category {
    id
    name
    color
  }
  time_entries {
    id
    minutes
    user_id
  }
  project_type
  project_notes
  project_notes_2
  is_reviewed
  language
  internal_review
  external_reviewer
  last_synced_details_at
  project_status
  start_date
  end_date
  created_at
  senior_brand_maven_lead
  rates {
    id
    person_id
    rate_in_cents
  }
  invoices {
    id
    amount_in_cents
  }
  associated_estimate {
    id
    name
    po_subtotal
    projects {
      id
      budget
    }
  }
  sales_split_ratio
  sales_split_pod {
    id
    senior_brand_maven_lead
  }
}
    `;
export const InvoiceFragmentFragmentDoc = gql`
    fragment InvoiceFragment on Invoice {
  id
  invoice_number
  amount_in_cents
  expense_type
  date_created
  payee_name
  notes
  project {
    id
    component_code
    name
  }
  contractor {
    id
    first_name
    last_name
  }
  files {
    id
    contentType
    fileName
    createdBy {
      id
    }
  }
}
    `;
export const PartEstimateFragmentDoc = gql`
    fragment PartEstimate on Estimate {
  id
  date
  owner {
    id
  }
  name
  po_number
  po_subtotal
  company {
    id
    company_name
  }
  brand {
    id
    brand_name
  }
  comments
}
    `;
export const PersonFragmentDoc = gql`
    fragment Person on Person {
  id
  first_name
  last_name
  title
  rate
  email_address
  company
  is_deleted_on_tw
  pods {
    id
    senior_brand_maven_lead
  }
  role {
    id
    roleTitle
  }
  team {
    id
    teamName
  }
}
    `;
export const AdminPagePodsDocument = gql`
    query AdminPagePods {
  pods {
    id
    name
    senior_brand_maven_lead
    members {
      ...AdminPagePerson
    }
  }
  senior_brand_maven_lead
}
    ${AdminPagePersonFragmentDoc}`;

/**
 * __useAdminPagePodsQuery__
 *
 * To run a query within a React component, call `useAdminPagePodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPagePodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPagePodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPagePodsQuery(baseOptions?: Apollo.QueryHookOptions<AdminPagePodsQuery, AdminPagePodsQueryVariables>) {
        return Apollo.useQuery<AdminPagePodsQuery, AdminPagePodsQueryVariables>(AdminPagePodsDocument, baseOptions);
      }
export function useAdminPagePodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPagePodsQuery, AdminPagePodsQueryVariables>) {
          return Apollo.useLazyQuery<AdminPagePodsQuery, AdminPagePodsQueryVariables>(AdminPagePodsDocument, baseOptions);
        }
export type AdminPagePodsQueryHookResult = ReturnType<typeof useAdminPagePodsQuery>;
export type AdminPagePodsLazyQueryHookResult = ReturnType<typeof useAdminPagePodsLazyQuery>;
export type AdminPagePodsQueryResult = Apollo.QueryResult<AdminPagePodsQuery, AdminPagePodsQueryVariables>;
export const AdminCreatePodDocument = gql`
    mutation AdminCreatePod($data: podCreateInput!) {
  createOnePod(data: $data) {
    id
    name
    members {
      ...AdminPagePerson
    }
  }
}
    ${AdminPagePersonFragmentDoc}`;
export type AdminCreatePodMutationFn = Apollo.MutationFunction<AdminCreatePodMutation, AdminCreatePodMutationVariables>;

/**
 * __useAdminCreatePodMutation__
 *
 * To run a mutation, you first call `useAdminCreatePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreatePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreatePodMutation, { data, loading, error }] = useAdminCreatePodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreatePodMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreatePodMutation, AdminCreatePodMutationVariables>) {
        return Apollo.useMutation<AdminCreatePodMutation, AdminCreatePodMutationVariables>(AdminCreatePodDocument, baseOptions);
      }
export type AdminCreatePodMutationHookResult = ReturnType<typeof useAdminCreatePodMutation>;
export type AdminCreatePodMutationResult = Apollo.MutationResult<AdminCreatePodMutation>;
export type AdminCreatePodMutationOptions = Apollo.BaseMutationOptions<AdminCreatePodMutation, AdminCreatePodMutationVariables>;
export const AdminDeletePodDocument = gql`
    mutation AdminDeletePod($where: podWhereUniqueInput!) {
  deleteOnePod(where: $where) {
    id
  }
}
    `;
export type AdminDeletePodMutationFn = Apollo.MutationFunction<AdminDeletePodMutation, AdminDeletePodMutationVariables>;

/**
 * __useAdminDeletePodMutation__
 *
 * To run a mutation, you first call `useAdminDeletePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeletePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeletePodMutation, { data, loading, error }] = useAdminDeletePodMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAdminDeletePodMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeletePodMutation, AdminDeletePodMutationVariables>) {
        return Apollo.useMutation<AdminDeletePodMutation, AdminDeletePodMutationVariables>(AdminDeletePodDocument, baseOptions);
      }
export type AdminDeletePodMutationHookResult = ReturnType<typeof useAdminDeletePodMutation>;
export type AdminDeletePodMutationResult = Apollo.MutationResult<AdminDeletePodMutation>;
export type AdminDeletePodMutationOptions = Apollo.BaseMutationOptions<AdminDeletePodMutation, AdminDeletePodMutationVariables>;
export const AdminUpdatePodDocument = gql`
    mutation AdminUpdatePod($updateOnePodData2: podUpdateInput!, $updateOnePodWhere: podWhereUniqueInput!) {
  updateOnePod(data: $updateOnePodData2, where: $updateOnePodWhere) {
    id
    name
    senior_brand_maven_lead
    members {
      ...AdminPagePerson
    }
  }
}
    ${AdminPagePersonFragmentDoc}`;
export type AdminUpdatePodMutationFn = Apollo.MutationFunction<AdminUpdatePodMutation, AdminUpdatePodMutationVariables>;

/**
 * __useAdminUpdatePodMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePodMutation, { data, loading, error }] = useAdminUpdatePodMutation({
 *   variables: {
 *      updateOnePodData2: // value for 'updateOnePodData2'
 *      updateOnePodWhere: // value for 'updateOnePodWhere'
 *   },
 * });
 */
export function useAdminUpdatePodMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdatePodMutation, AdminUpdatePodMutationVariables>) {
        return Apollo.useMutation<AdminUpdatePodMutation, AdminUpdatePodMutationVariables>(AdminUpdatePodDocument, baseOptions);
      }
export type AdminUpdatePodMutationHookResult = ReturnType<typeof useAdminUpdatePodMutation>;
export type AdminUpdatePodMutationResult = Apollo.MutationResult<AdminUpdatePodMutation>;
export type AdminUpdatePodMutationOptions = Apollo.BaseMutationOptions<AdminUpdatePodMutation, AdminUpdatePodMutationVariables>;
export const AdminAddPodMemberDocument = gql`
    mutation AdminAddPodMember($podId: ID!, $personId: String!) {
  addPodMember(podId: $podId, personId: $personId) {
    id
    members {
      ...AdminPagePerson
    }
  }
}
    ${AdminPagePersonFragmentDoc}`;
export type AdminAddPodMemberMutationFn = Apollo.MutationFunction<AdminAddPodMemberMutation, AdminAddPodMemberMutationVariables>;

/**
 * __useAdminAddPodMemberMutation__
 *
 * To run a mutation, you first call `useAdminAddPodMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddPodMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddPodMemberMutation, { data, loading, error }] = useAdminAddPodMemberMutation({
 *   variables: {
 *      podId: // value for 'podId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAdminAddPodMemberMutation(baseOptions?: Apollo.MutationHookOptions<AdminAddPodMemberMutation, AdminAddPodMemberMutationVariables>) {
        return Apollo.useMutation<AdminAddPodMemberMutation, AdminAddPodMemberMutationVariables>(AdminAddPodMemberDocument, baseOptions);
      }
export type AdminAddPodMemberMutationHookResult = ReturnType<typeof useAdminAddPodMemberMutation>;
export type AdminAddPodMemberMutationResult = Apollo.MutationResult<AdminAddPodMemberMutation>;
export type AdminAddPodMemberMutationOptions = Apollo.BaseMutationOptions<AdminAddPodMemberMutation, AdminAddPodMemberMutationVariables>;
export const AdminRemovePodMemberDocument = gql`
    mutation AdminRemovePodMember($podId: ID!, $personId: String!) {
  removePodMember(podId: $podId, personId: $personId) {
    id
    members {
      ...AdminPagePerson
    }
  }
}
    ${AdminPagePersonFragmentDoc}`;
export type AdminRemovePodMemberMutationFn = Apollo.MutationFunction<AdminRemovePodMemberMutation, AdminRemovePodMemberMutationVariables>;

/**
 * __useAdminRemovePodMemberMutation__
 *
 * To run a mutation, you first call `useAdminRemovePodMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemovePodMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemovePodMemberMutation, { data, loading, error }] = useAdminRemovePodMemberMutation({
 *   variables: {
 *      podId: // value for 'podId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAdminRemovePodMemberMutation(baseOptions?: Apollo.MutationHookOptions<AdminRemovePodMemberMutation, AdminRemovePodMemberMutationVariables>) {
        return Apollo.useMutation<AdminRemovePodMemberMutation, AdminRemovePodMemberMutationVariables>(AdminRemovePodMemberDocument, baseOptions);
      }
export type AdminRemovePodMemberMutationHookResult = ReturnType<typeof useAdminRemovePodMemberMutation>;
export type AdminRemovePodMemberMutationResult = Apollo.MutationResult<AdminRemovePodMemberMutation>;
export type AdminRemovePodMemberMutationOptions = Apollo.BaseMutationOptions<AdminRemovePodMemberMutation, AdminRemovePodMemberMutationVariables>;
export const AdminPagePeopleDocument = gql`
    query AdminPagePeople {
  people {
    ...AdminPagePerson
  }
}
    ${AdminPagePersonFragmentDoc}`;

/**
 * __useAdminPagePeopleQuery__
 *
 * To run a query within a React component, call `useAdminPagePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPagePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPagePeopleQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminPagePeopleQuery(baseOptions?: Apollo.QueryHookOptions<AdminPagePeopleQuery, AdminPagePeopleQueryVariables>) {
        return Apollo.useQuery<AdminPagePeopleQuery, AdminPagePeopleQueryVariables>(AdminPagePeopleDocument, baseOptions);
      }
export function useAdminPagePeopleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPagePeopleQuery, AdminPagePeopleQueryVariables>) {
          return Apollo.useLazyQuery<AdminPagePeopleQuery, AdminPagePeopleQueryVariables>(AdminPagePeopleDocument, baseOptions);
        }
export type AdminPagePeopleQueryHookResult = ReturnType<typeof useAdminPagePeopleQuery>;
export type AdminPagePeopleLazyQueryHookResult = ReturnType<typeof useAdminPagePeopleLazyQuery>;
export type AdminPagePeopleQueryResult = Apollo.QueryResult<AdminPagePeopleQuery, AdminPagePeopleQueryVariables>;
export const AdminCreateRoleDocument = gql`
    mutation AdminCreateRole($roleTitle: String!) {
  createRole(roleTitle: $roleTitle) {
    id
    roleTitle
  }
}
    `;
export type AdminCreateRoleMutationFn = Apollo.MutationFunction<AdminCreateRoleMutation, AdminCreateRoleMutationVariables>;

/**
 * __useAdminCreateRoleMutation__
 *
 * To run a mutation, you first call `useAdminCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateRoleMutation, { data, loading, error }] = useAdminCreateRoleMutation({
 *   variables: {
 *      roleTitle: // value for 'roleTitle'
 *   },
 * });
 */
export function useAdminCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateRoleMutation, AdminCreateRoleMutationVariables>) {
        return Apollo.useMutation<AdminCreateRoleMutation, AdminCreateRoleMutationVariables>(AdminCreateRoleDocument, baseOptions);
      }
export type AdminCreateRoleMutationHookResult = ReturnType<typeof useAdminCreateRoleMutation>;
export type AdminCreateRoleMutationResult = Apollo.MutationResult<AdminCreateRoleMutation>;
export type AdminCreateRoleMutationOptions = Apollo.BaseMutationOptions<AdminCreateRoleMutation, AdminCreateRoleMutationVariables>;
export const AdminCreateTeamDocument = gql`
    mutation AdminCreateTeam($teamName: String!) {
  createTeam(teamName: $teamName) {
    id
    teamName
  }
}
    `;
export type AdminCreateTeamMutationFn = Apollo.MutationFunction<AdminCreateTeamMutation, AdminCreateTeamMutationVariables>;

/**
 * __useAdminCreateTeamMutation__
 *
 * To run a mutation, you first call `useAdminCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateTeamMutation, { data, loading, error }] = useAdminCreateTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useAdminCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateTeamMutation, AdminCreateTeamMutationVariables>) {
        return Apollo.useMutation<AdminCreateTeamMutation, AdminCreateTeamMutationVariables>(AdminCreateTeamDocument, baseOptions);
      }
export type AdminCreateTeamMutationHookResult = ReturnType<typeof useAdminCreateTeamMutation>;
export type AdminCreateTeamMutationResult = Apollo.MutationResult<AdminCreateTeamMutation>;
export type AdminCreateTeamMutationOptions = Apollo.BaseMutationOptions<AdminCreateTeamMutation, AdminCreateTeamMutationVariables>;
export const AdminDeleteRoleDocument = gql`
    mutation AdminDeleteRole($id: String!) {
  deleteRole(id: $id) {
    id
    roleTitle
  }
}
    `;
export type AdminDeleteRoleMutationFn = Apollo.MutationFunction<AdminDeleteRoleMutation, AdminDeleteRoleMutationVariables>;

/**
 * __useAdminDeleteRoleMutation__
 *
 * To run a mutation, you first call `useAdminDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteRoleMutation, { data, loading, error }] = useAdminDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteRoleMutation, AdminDeleteRoleMutationVariables>) {
        return Apollo.useMutation<AdminDeleteRoleMutation, AdminDeleteRoleMutationVariables>(AdminDeleteRoleDocument, baseOptions);
      }
export type AdminDeleteRoleMutationHookResult = ReturnType<typeof useAdminDeleteRoleMutation>;
export type AdminDeleteRoleMutationResult = Apollo.MutationResult<AdminDeleteRoleMutation>;
export type AdminDeleteRoleMutationOptions = Apollo.BaseMutationOptions<AdminDeleteRoleMutation, AdminDeleteRoleMutationVariables>;
export const AdminDeleteTeamDocument = gql`
    mutation AdminDeleteTeam($id: String!) {
  deleteTeam(id: $id) {
    id
    teamName
  }
}
    `;
export type AdminDeleteTeamMutationFn = Apollo.MutationFunction<AdminDeleteTeamMutation, AdminDeleteTeamMutationVariables>;

/**
 * __useAdminDeleteTeamMutation__
 *
 * To run a mutation, you first call `useAdminDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteTeamMutation, { data, loading, error }] = useAdminDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteTeamMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteTeamMutation, AdminDeleteTeamMutationVariables>) {
        return Apollo.useMutation<AdminDeleteTeamMutation, AdminDeleteTeamMutationVariables>(AdminDeleteTeamDocument, baseOptions);
      }
export type AdminDeleteTeamMutationHookResult = ReturnType<typeof useAdminDeleteTeamMutation>;
export type AdminDeleteTeamMutationResult = Apollo.MutationResult<AdminDeleteTeamMutation>;
export type AdminDeleteTeamMutationOptions = Apollo.BaseMutationOptions<AdminDeleteTeamMutation, AdminDeleteTeamMutationVariables>;
export const AdminUpdateRoleDocument = gql`
    mutation AdminUpdateRole($id: String!, $roleTitle: String!) {
  updateRole(id: $id, roleTitle: $roleTitle) {
    id
    roleTitle
  }
}
    `;
export type AdminUpdateRoleMutationFn = Apollo.MutationFunction<AdminUpdateRoleMutation, AdminUpdateRoleMutationVariables>;

/**
 * __useAdminUpdateRoleMutation__
 *
 * To run a mutation, you first call `useAdminUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateRoleMutation, { data, loading, error }] = useAdminUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleTitle: // value for 'roleTitle'
 *   },
 * });
 */
export function useAdminUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateRoleMutation, AdminUpdateRoleMutationVariables>) {
        return Apollo.useMutation<AdminUpdateRoleMutation, AdminUpdateRoleMutationVariables>(AdminUpdateRoleDocument, baseOptions);
      }
export type AdminUpdateRoleMutationHookResult = ReturnType<typeof useAdminUpdateRoleMutation>;
export type AdminUpdateRoleMutationResult = Apollo.MutationResult<AdminUpdateRoleMutation>;
export type AdminUpdateRoleMutationOptions = Apollo.BaseMutationOptions<AdminUpdateRoleMutation, AdminUpdateRoleMutationVariables>;
export const AdminUpdateTeamDocument = gql`
    mutation AdminUpdateTeam($id: String!, $teamName: String!) {
  updateTeam(id: $id, teamName: $teamName) {
    id
    teamName
  }
}
    `;
export type AdminUpdateTeamMutationFn = Apollo.MutationFunction<AdminUpdateTeamMutation, AdminUpdateTeamMutationVariables>;

/**
 * __useAdminUpdateTeamMutation__
 *
 * To run a mutation, you first call `useAdminUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateTeamMutation, { data, loading, error }] = useAdminUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useAdminUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateTeamMutation, AdminUpdateTeamMutationVariables>) {
        return Apollo.useMutation<AdminUpdateTeamMutation, AdminUpdateTeamMutationVariables>(AdminUpdateTeamDocument, baseOptions);
      }
export type AdminUpdateTeamMutationHookResult = ReturnType<typeof useAdminUpdateTeamMutation>;
export type AdminUpdateTeamMutationResult = Apollo.MutationResult<AdminUpdateTeamMutation>;
export type AdminUpdateTeamMutationOptions = Apollo.BaseMutationOptions<AdminUpdateTeamMutation, AdminUpdateTeamMutationVariables>;
export const AdminGetAllTeamsDocument = gql`
    query AdminGetAllTeams {
  teams {
    id
    teamName
  }
}
    `;

/**
 * __useAdminGetAllTeamsQuery__
 *
 * To run a query within a React component, call `useAdminGetAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetAllTeamsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAllTeamsQuery, AdminGetAllTeamsQueryVariables>) {
        return Apollo.useQuery<AdminGetAllTeamsQuery, AdminGetAllTeamsQueryVariables>(AdminGetAllTeamsDocument, baseOptions);
      }
export function useAdminGetAllTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAllTeamsQuery, AdminGetAllTeamsQueryVariables>) {
          return Apollo.useLazyQuery<AdminGetAllTeamsQuery, AdminGetAllTeamsQueryVariables>(AdminGetAllTeamsDocument, baseOptions);
        }
export type AdminGetAllTeamsQueryHookResult = ReturnType<typeof useAdminGetAllTeamsQuery>;
export type AdminGetAllTeamsLazyQueryHookResult = ReturnType<typeof useAdminGetAllTeamsLazyQuery>;
export type AdminGetAllTeamsQueryResult = Apollo.QueryResult<AdminGetAllTeamsQuery, AdminGetAllTeamsQueryVariables>;
export const AdminGetAllRolesDocument = gql`
    query AdminGetAllRoles {
  roles {
    id
    roleTitle
  }
}
    `;

/**
 * __useAdminGetAllRolesQuery__
 *
 * To run a query within a React component, call `useAdminGetAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminGetAllRolesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAllRolesQuery, AdminGetAllRolesQueryVariables>) {
        return Apollo.useQuery<AdminGetAllRolesQuery, AdminGetAllRolesQueryVariables>(AdminGetAllRolesDocument, baseOptions);
      }
export function useAdminGetAllRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAllRolesQuery, AdminGetAllRolesQueryVariables>) {
          return Apollo.useLazyQuery<AdminGetAllRolesQuery, AdminGetAllRolesQueryVariables>(AdminGetAllRolesDocument, baseOptions);
        }
export type AdminGetAllRolesQueryHookResult = ReturnType<typeof useAdminGetAllRolesQuery>;
export type AdminGetAllRolesLazyQueryHookResult = ReturnType<typeof useAdminGetAllRolesLazyQuery>;
export type AdminGetAllRolesQueryResult = Apollo.QueryResult<AdminGetAllRolesQuery, AdminGetAllRolesQueryVariables>;
export const AdminAssignRoleDocument = gql`
    mutation AdminAssignRole($userId: String!, $roleId: String!) {
  assignUserToRole(userId: $userId, roleId: $roleId) {
    id
    first_name
    last_name
    title
    role {
      id
      roleTitle
    }
  }
}
    `;
export type AdminAssignRoleMutationFn = Apollo.MutationFunction<AdminAssignRoleMutation, AdminAssignRoleMutationVariables>;

/**
 * __useAdminAssignRoleMutation__
 *
 * To run a mutation, you first call `useAdminAssignRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignRoleMutation, { data, loading, error }] = useAdminAssignRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useAdminAssignRoleMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssignRoleMutation, AdminAssignRoleMutationVariables>) {
        return Apollo.useMutation<AdminAssignRoleMutation, AdminAssignRoleMutationVariables>(AdminAssignRoleDocument, baseOptions);
      }
export type AdminAssignRoleMutationHookResult = ReturnType<typeof useAdminAssignRoleMutation>;
export type AdminAssignRoleMutationResult = Apollo.MutationResult<AdminAssignRoleMutation>;
export type AdminAssignRoleMutationOptions = Apollo.BaseMutationOptions<AdminAssignRoleMutation, AdminAssignRoleMutationVariables>;
export const AdminAssignTeamDocument = gql`
    mutation AdminAssignTeam($userId: String!, $teamId: String!) {
  assignUserToTeam(userId: $userId, teamId: $teamId) {
    id
    first_name
    last_name
    title
    team {
      id
      teamName
    }
  }
}
    `;
export type AdminAssignTeamMutationFn = Apollo.MutationFunction<AdminAssignTeamMutation, AdminAssignTeamMutationVariables>;

/**
 * __useAdminAssignTeamMutation__
 *
 * To run a mutation, you first call `useAdminAssignTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignTeamMutation, { data, loading, error }] = useAdminAssignTeamMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAdminAssignTeamMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssignTeamMutation, AdminAssignTeamMutationVariables>) {
        return Apollo.useMutation<AdminAssignTeamMutation, AdminAssignTeamMutationVariables>(AdminAssignTeamDocument, baseOptions);
      }
export type AdminAssignTeamMutationHookResult = ReturnType<typeof useAdminAssignTeamMutation>;
export type AdminAssignTeamMutationResult = Apollo.MutationResult<AdminAssignTeamMutation>;
export type AdminAssignTeamMutationOptions = Apollo.BaseMutationOptions<AdminAssignTeamMutation, AdminAssignTeamMutationVariables>;
export const AdminAssignRoleAndTeamToUserDocument = gql`
    mutation AdminAssignRoleAndTeamToUser($userId: String!, $roleId: String!, $teamId: String!) {
  assignRoleAndTeamToUser(userId: $userId, roleId: $roleId, teamId: $teamId) {
    id
    first_name
    last_name
    title
    role {
      id
      roleTitle
    }
    team {
      id
      teamName
    }
  }
}
    `;
export type AdminAssignRoleAndTeamToUserMutationFn = Apollo.MutationFunction<AdminAssignRoleAndTeamToUserMutation, AdminAssignRoleAndTeamToUserMutationVariables>;

/**
 * __useAdminAssignRoleAndTeamToUserMutation__
 *
 * To run a mutation, you first call `useAdminAssignRoleAndTeamToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignRoleAndTeamToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignRoleAndTeamToUserMutation, { data, loading, error }] = useAdminAssignRoleAndTeamToUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      roleId: // value for 'roleId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAdminAssignRoleAndTeamToUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssignRoleAndTeamToUserMutation, AdminAssignRoleAndTeamToUserMutationVariables>) {
        return Apollo.useMutation<AdminAssignRoleAndTeamToUserMutation, AdminAssignRoleAndTeamToUserMutationVariables>(AdminAssignRoleAndTeamToUserDocument, baseOptions);
      }
export type AdminAssignRoleAndTeamToUserMutationHookResult = ReturnType<typeof useAdminAssignRoleAndTeamToUserMutation>;
export type AdminAssignRoleAndTeamToUserMutationResult = Apollo.MutationResult<AdminAssignRoleAndTeamToUserMutation>;
export type AdminAssignRoleAndTeamToUserMutationOptions = Apollo.BaseMutationOptions<AdminAssignRoleAndTeamToUserMutation, AdminAssignRoleAndTeamToUserMutationVariables>;
export const AdminAssignRoleAndTeamToUsersDocument = gql`
    mutation AdminAssignRoleAndTeamToUsers($userIds: [String!]!, $roleId: String!, $teamId: String!) {
  assignRoleAndTeamToUsers(userIds: $userIds, roleId: $roleId, teamId: $teamId) {
    count
  }
}
    `;
export type AdminAssignRoleAndTeamToUsersMutationFn = Apollo.MutationFunction<AdminAssignRoleAndTeamToUsersMutation, AdminAssignRoleAndTeamToUsersMutationVariables>;

/**
 * __useAdminAssignRoleAndTeamToUsersMutation__
 *
 * To run a mutation, you first call `useAdminAssignRoleAndTeamToUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAssignRoleAndTeamToUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAssignRoleAndTeamToUsersMutation, { data, loading, error }] = useAdminAssignRoleAndTeamToUsersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      roleId: // value for 'roleId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAdminAssignRoleAndTeamToUsersMutation(baseOptions?: Apollo.MutationHookOptions<AdminAssignRoleAndTeamToUsersMutation, AdminAssignRoleAndTeamToUsersMutationVariables>) {
        return Apollo.useMutation<AdminAssignRoleAndTeamToUsersMutation, AdminAssignRoleAndTeamToUsersMutationVariables>(AdminAssignRoleAndTeamToUsersDocument, baseOptions);
      }
export type AdminAssignRoleAndTeamToUsersMutationHookResult = ReturnType<typeof useAdminAssignRoleAndTeamToUsersMutation>;
export type AdminAssignRoleAndTeamToUsersMutationResult = Apollo.MutationResult<AdminAssignRoleAndTeamToUsersMutation>;
export type AdminAssignRoleAndTeamToUsersMutationOptions = Apollo.BaseMutationOptions<AdminAssignRoleAndTeamToUsersMutation, AdminAssignRoleAndTeamToUsersMutationVariables>;
export const RevenueTargetsDocument = gql`
    query RevenueTargets($companyAnnualRevenueTargetsStartingYear: Int!, $podAnnualRevenueTargetYear: Int!) {
  companyAnnualRevenueTargets(
    where: {year: {gte: $companyAnnualRevenueTargetsStartingYear}}
  ) {
    year
    revenue_target_dollars
  }
  pods {
    id
    name
    annual_revenue_target(year: $podAnnualRevenueTargetYear) {
      podId
      revenue_target_dollars
      year
    }
  }
}
    `;

/**
 * __useRevenueTargetsQuery__
 *
 * To run a query within a React component, call `useRevenueTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueTargetsQuery({
 *   variables: {
 *      companyAnnualRevenueTargetsStartingYear: // value for 'companyAnnualRevenueTargetsStartingYear'
 *      podAnnualRevenueTargetYear: // value for 'podAnnualRevenueTargetYear'
 *   },
 * });
 */
export function useRevenueTargetsQuery(baseOptions: Apollo.QueryHookOptions<RevenueTargetsQuery, RevenueTargetsQueryVariables>) {
        return Apollo.useQuery<RevenueTargetsQuery, RevenueTargetsQueryVariables>(RevenueTargetsDocument, baseOptions);
      }
export function useRevenueTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RevenueTargetsQuery, RevenueTargetsQueryVariables>) {
          return Apollo.useLazyQuery<RevenueTargetsQuery, RevenueTargetsQueryVariables>(RevenueTargetsDocument, baseOptions);
        }
export type RevenueTargetsQueryHookResult = ReturnType<typeof useRevenueTargetsQuery>;
export type RevenueTargetsLazyQueryHookResult = ReturnType<typeof useRevenueTargetsLazyQuery>;
export type RevenueTargetsQueryResult = Apollo.QueryResult<RevenueTargetsQuery, RevenueTargetsQueryVariables>;
export const SetCompanyRevenueTargetDocument = gql`
    mutation SetCompanyRevenueTarget($year: Int!, $revenueTargetDollars: Int!) {
  setCompanyRevenueTarget(
    year: $year
    revenueTargetDollars: $revenueTargetDollars
  ) {
    revenue_target_dollars
    year
  }
}
    `;
export type SetCompanyRevenueTargetMutationFn = Apollo.MutationFunction<SetCompanyRevenueTargetMutation, SetCompanyRevenueTargetMutationVariables>;

/**
 * __useSetCompanyRevenueTargetMutation__
 *
 * To run a mutation, you first call `useSetCompanyRevenueTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyRevenueTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyRevenueTargetMutation, { data, loading, error }] = useSetCompanyRevenueTargetMutation({
 *   variables: {
 *      year: // value for 'year'
 *      revenueTargetDollars: // value for 'revenueTargetDollars'
 *   },
 * });
 */
export function useSetCompanyRevenueTargetMutation(baseOptions?: Apollo.MutationHookOptions<SetCompanyRevenueTargetMutation, SetCompanyRevenueTargetMutationVariables>) {
        return Apollo.useMutation<SetCompanyRevenueTargetMutation, SetCompanyRevenueTargetMutationVariables>(SetCompanyRevenueTargetDocument, baseOptions);
      }
export type SetCompanyRevenueTargetMutationHookResult = ReturnType<typeof useSetCompanyRevenueTargetMutation>;
export type SetCompanyRevenueTargetMutationResult = Apollo.MutationResult<SetCompanyRevenueTargetMutation>;
export type SetCompanyRevenueTargetMutationOptions = Apollo.BaseMutationOptions<SetCompanyRevenueTargetMutation, SetCompanyRevenueTargetMutationVariables>;
export const SetPodRevenueTargetDocument = gql`
    mutation SetPodRevenueTarget($podId: ID!, $year: Int!, $revenueTargetDollars: Int!) {
  setPodRevenueTarget(
    podId: $podId
    year: $year
    revenueTargetDollars: $revenueTargetDollars
  ) {
    year
    podId
    revenue_target_dollars
  }
}
    `;
export type SetPodRevenueTargetMutationFn = Apollo.MutationFunction<SetPodRevenueTargetMutation, SetPodRevenueTargetMutationVariables>;

/**
 * __useSetPodRevenueTargetMutation__
 *
 * To run a mutation, you first call `useSetPodRevenueTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPodRevenueTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPodRevenueTargetMutation, { data, loading, error }] = useSetPodRevenueTargetMutation({
 *   variables: {
 *      podId: // value for 'podId'
 *      year: // value for 'year'
 *      revenueTargetDollars: // value for 'revenueTargetDollars'
 *   },
 * });
 */
export function useSetPodRevenueTargetMutation(baseOptions?: Apollo.MutationHookOptions<SetPodRevenueTargetMutation, SetPodRevenueTargetMutationVariables>) {
        return Apollo.useMutation<SetPodRevenueTargetMutation, SetPodRevenueTargetMutationVariables>(SetPodRevenueTargetDocument, baseOptions);
      }
export type SetPodRevenueTargetMutationHookResult = ReturnType<typeof useSetPodRevenueTargetMutation>;
export type SetPodRevenueTargetMutationResult = Apollo.MutationResult<SetPodRevenueTargetMutation>;
export type SetPodRevenueTargetMutationOptions = Apollo.BaseMutationOptions<SetPodRevenueTargetMutation, SetPodRevenueTargetMutationVariables>;
export const PeopleTimeTargetsAndEntriesDocument = gql`
    query PeopleTimeTargetsAndEntries($year: Int!, $start_date: Date!, $end_date: Date!) {
  peopleTimeTargetsAndEntries(
    year: $year
    start_date: $start_date
    end_date: $end_date
  ) {
    id
    first_name
    last_name
    title
    rate
    company
    created_at
    is_deleted_on_tw
    annual_time_targets(year: $year) {
      id
      year
      billableMinutes
      nonBillableMinutes
      personId
      salesAndMarketingMinutes
      innovationAndDevMinutes
      operationalMinutes
      professionalDevMinutes
    }
    daysOff(start_date: $start_date, end_date: $end_date) {
      id
      date
      reason
      personId
    }
    timeEntries(start_date: $start_date, end_date: $end_date) {
      id
      date
      minutes
      user_id
      company_id
      project_id
      project_tag_id
      billable
      project {
        name
      }
    }
  }
}
    `;

/**
 * __usePeopleTimeTargetsAndEntriesQuery__
 *
 * To run a query within a React component, call `usePeopleTimeTargetsAndEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleTimeTargetsAndEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleTimeTargetsAndEntriesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function usePeopleTimeTargetsAndEntriesQuery(baseOptions: Apollo.QueryHookOptions<PeopleTimeTargetsAndEntriesQuery, PeopleTimeTargetsAndEntriesQueryVariables>) {
        return Apollo.useQuery<PeopleTimeTargetsAndEntriesQuery, PeopleTimeTargetsAndEntriesQueryVariables>(PeopleTimeTargetsAndEntriesDocument, baseOptions);
      }
export function usePeopleTimeTargetsAndEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleTimeTargetsAndEntriesQuery, PeopleTimeTargetsAndEntriesQueryVariables>) {
          return Apollo.useLazyQuery<PeopleTimeTargetsAndEntriesQuery, PeopleTimeTargetsAndEntriesQueryVariables>(PeopleTimeTargetsAndEntriesDocument, baseOptions);
        }
export type PeopleTimeTargetsAndEntriesQueryHookResult = ReturnType<typeof usePeopleTimeTargetsAndEntriesQuery>;
export type PeopleTimeTargetsAndEntriesLazyQueryHookResult = ReturnType<typeof usePeopleTimeTargetsAndEntriesLazyQuery>;
export type PeopleTimeTargetsAndEntriesQueryResult = Apollo.QueryResult<PeopleTimeTargetsAndEntriesQuery, PeopleTimeTargetsAndEntriesQueryVariables>;
export const AddPersonAnnualTimeTargetsDocument = gql`
    mutation AddPersonAnnualTimeTargets($personIds: [String!]!, $year: Int!, $billableMinutes: Int, $nonBillableMinutes: Int, $salesAndMarketingMinutes: Int, $innovationAndDevMinutes: Int, $operationalMinutes: Int, $professionalDevMinutes: Int) {
  addPersonAnnualTimeTargets(
    personIds: $personIds
    year: $year
    billableMinutes: $billableMinutes
    nonBillableMinutes: $nonBillableMinutes
    salesAndMarketingMinutes: $salesAndMarketingMinutes
    innovationAndDevMinutes: $innovationAndDevMinutes
    operationalMinutes: $operationalMinutes
    professionalDevMinutes: $professionalDevMinutes
  ) {
    count
  }
}
    `;
export type AddPersonAnnualTimeTargetsMutationFn = Apollo.MutationFunction<AddPersonAnnualTimeTargetsMutation, AddPersonAnnualTimeTargetsMutationVariables>;

/**
 * __useAddPersonAnnualTimeTargetsMutation__
 *
 * To run a mutation, you first call `useAddPersonAnnualTimeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonAnnualTimeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonAnnualTimeTargetsMutation, { data, loading, error }] = useAddPersonAnnualTimeTargetsMutation({
 *   variables: {
 *      personIds: // value for 'personIds'
 *      year: // value for 'year'
 *      billableMinutes: // value for 'billableMinutes'
 *      nonBillableMinutes: // value for 'nonBillableMinutes'
 *      salesAndMarketingMinutes: // value for 'salesAndMarketingMinutes'
 *      innovationAndDevMinutes: // value for 'innovationAndDevMinutes'
 *      operationalMinutes: // value for 'operationalMinutes'
 *      professionalDevMinutes: // value for 'professionalDevMinutes'
 *   },
 * });
 */
export function useAddPersonAnnualTimeTargetsMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonAnnualTimeTargetsMutation, AddPersonAnnualTimeTargetsMutationVariables>) {
        return Apollo.useMutation<AddPersonAnnualTimeTargetsMutation, AddPersonAnnualTimeTargetsMutationVariables>(AddPersonAnnualTimeTargetsDocument, baseOptions);
      }
export type AddPersonAnnualTimeTargetsMutationHookResult = ReturnType<typeof useAddPersonAnnualTimeTargetsMutation>;
export type AddPersonAnnualTimeTargetsMutationResult = Apollo.MutationResult<AddPersonAnnualTimeTargetsMutation>;
export type AddPersonAnnualTimeTargetsMutationOptions = Apollo.BaseMutationOptions<AddPersonAnnualTimeTargetsMutation, AddPersonAnnualTimeTargetsMutationVariables>;
export const UpdateManyPersonAnnualTimeTargetsDocument = gql`
    mutation UpdateManyPersonAnnualTimeTargets($personIds: [String!]!, $year: Int!, $billableMinutes: Int, $nonBillableMinutes: Int, $salesAndMarketingMinutes: Int, $innovationAndDevMinutes: Int, $operationalMinutes: Int, $professionalDevMinutes: Int) {
  updateManyPersonAnnualTimeTargets(
    personIds: $personIds
    year: $year
    billableMinutes: $billableMinutes
    nonBillableMinutes: $nonBillableMinutes
    salesAndMarketingMinutes: $salesAndMarketingMinutes
    innovationAndDevMinutes: $innovationAndDevMinutes
    operationalMinutes: $operationalMinutes
    professionalDevMinutes: $professionalDevMinutes
  ) {
    count
  }
}
    `;
export type UpdateManyPersonAnnualTimeTargetsMutationFn = Apollo.MutationFunction<UpdateManyPersonAnnualTimeTargetsMutation, UpdateManyPersonAnnualTimeTargetsMutationVariables>;

/**
 * __useUpdateManyPersonAnnualTimeTargetsMutation__
 *
 * To run a mutation, you first call `useUpdateManyPersonAnnualTimeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyPersonAnnualTimeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyPersonAnnualTimeTargetsMutation, { data, loading, error }] = useUpdateManyPersonAnnualTimeTargetsMutation({
 *   variables: {
 *      personIds: // value for 'personIds'
 *      year: // value for 'year'
 *      billableMinutes: // value for 'billableMinutes'
 *      nonBillableMinutes: // value for 'nonBillableMinutes'
 *      salesAndMarketingMinutes: // value for 'salesAndMarketingMinutes'
 *      innovationAndDevMinutes: // value for 'innovationAndDevMinutes'
 *      operationalMinutes: // value for 'operationalMinutes'
 *      professionalDevMinutes: // value for 'professionalDevMinutes'
 *   },
 * });
 */
export function useUpdateManyPersonAnnualTimeTargetsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyPersonAnnualTimeTargetsMutation, UpdateManyPersonAnnualTimeTargetsMutationVariables>) {
        return Apollo.useMutation<UpdateManyPersonAnnualTimeTargetsMutation, UpdateManyPersonAnnualTimeTargetsMutationVariables>(UpdateManyPersonAnnualTimeTargetsDocument, baseOptions);
      }
export type UpdateManyPersonAnnualTimeTargetsMutationHookResult = ReturnType<typeof useUpdateManyPersonAnnualTimeTargetsMutation>;
export type UpdateManyPersonAnnualTimeTargetsMutationResult = Apollo.MutationResult<UpdateManyPersonAnnualTimeTargetsMutation>;
export type UpdateManyPersonAnnualTimeTargetsMutationOptions = Apollo.BaseMutationOptions<UpdateManyPersonAnnualTimeTargetsMutation, UpdateManyPersonAnnualTimeTargetsMutationVariables>;
export const UpdatePersonAnnualTimeTargetDocument = gql`
    mutation UpdatePersonAnnualTimeTarget($personId: String!, $year: Int!, $billableMinutes: Int, $nonBillableMinutes: Int, $salesAndMarketingMinutes: Int, $innovationAndDevMinutes: Int, $operationalMinutes: Int, $professionalDevMinutes: Int) {
  updatePersonAnnualTimeTarget(
    personId: $personId
    year: $year
    billableMinutes: $billableMinutes
    nonBillableMinutes: $nonBillableMinutes
    salesAndMarketingMinutes: $salesAndMarketingMinutes
    innovationAndDevMinutes: $innovationAndDevMinutes
    operationalMinutes: $operationalMinutes
    professionalDevMinutes: $professionalDevMinutes
  ) {
    id
    year
    billableMinutes
    nonBillableMinutes
    salesAndMarketingMinutes
    innovationAndDevMinutes
    operationalMinutes
    professionalDevMinutes
    personId
  }
}
    `;
export type UpdatePersonAnnualTimeTargetMutationFn = Apollo.MutationFunction<UpdatePersonAnnualTimeTargetMutation, UpdatePersonAnnualTimeTargetMutationVariables>;

/**
 * __useUpdatePersonAnnualTimeTargetMutation__
 *
 * To run a mutation, you first call `useUpdatePersonAnnualTimeTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonAnnualTimeTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonAnnualTimeTargetMutation, { data, loading, error }] = useUpdatePersonAnnualTimeTargetMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      year: // value for 'year'
 *      billableMinutes: // value for 'billableMinutes'
 *      nonBillableMinutes: // value for 'nonBillableMinutes'
 *      salesAndMarketingMinutes: // value for 'salesAndMarketingMinutes'
 *      innovationAndDevMinutes: // value for 'innovationAndDevMinutes'
 *      operationalMinutes: // value for 'operationalMinutes'
 *      professionalDevMinutes: // value for 'professionalDevMinutes'
 *   },
 * });
 */
export function useUpdatePersonAnnualTimeTargetMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonAnnualTimeTargetMutation, UpdatePersonAnnualTimeTargetMutationVariables>) {
        return Apollo.useMutation<UpdatePersonAnnualTimeTargetMutation, UpdatePersonAnnualTimeTargetMutationVariables>(UpdatePersonAnnualTimeTargetDocument, baseOptions);
      }
export type UpdatePersonAnnualTimeTargetMutationHookResult = ReturnType<typeof useUpdatePersonAnnualTimeTargetMutation>;
export type UpdatePersonAnnualTimeTargetMutationResult = Apollo.MutationResult<UpdatePersonAnnualTimeTargetMutation>;
export type UpdatePersonAnnualTimeTargetMutationOptions = Apollo.BaseMutationOptions<UpdatePersonAnnualTimeTargetMutation, UpdatePersonAnnualTimeTargetMutationVariables>;
export const PeopleTimeByMonthDocument = gql`
    query PeopleTimeByMonth($start_date: Date!, $end_date: Date!) {
  people {
    id
    first_name
    last_name
    title
    rate
    company
    created_at
    is_deleted_on_tw
    timeEntries(start_date: $start_date, end_date: $end_date) {
      id
      date
      minutes
      user_id
      company_id
      project_id
      project_tag_id
      billable
    }
  }
}
    `;

/**
 * __usePeopleTimeByMonthQuery__
 *
 * To run a query within a React component, call `usePeopleTimeByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleTimeByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleTimeByMonthQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function usePeopleTimeByMonthQuery(baseOptions: Apollo.QueryHookOptions<PeopleTimeByMonthQuery, PeopleTimeByMonthQueryVariables>) {
        return Apollo.useQuery<PeopleTimeByMonthQuery, PeopleTimeByMonthQueryVariables>(PeopleTimeByMonthDocument, baseOptions);
      }
export function usePeopleTimeByMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleTimeByMonthQuery, PeopleTimeByMonthQueryVariables>) {
          return Apollo.useLazyQuery<PeopleTimeByMonthQuery, PeopleTimeByMonthQueryVariables>(PeopleTimeByMonthDocument, baseOptions);
        }
export type PeopleTimeByMonthQueryHookResult = ReturnType<typeof usePeopleTimeByMonthQuery>;
export type PeopleTimeByMonthLazyQueryHookResult = ReturnType<typeof usePeopleTimeByMonthLazyQuery>;
export type PeopleTimeByMonthQueryResult = Apollo.QueryResult<PeopleTimeByMonthQuery, PeopleTimeByMonthQueryVariables>;
export const DeleteClientInvoiceDocument = gql`
    mutation DeleteClientInvoice($deleteOneClientInvoiceWhere: client_invoiceWhereUniqueInput!) {
  deleteOneClientInvoice(where: $deleteOneClientInvoiceWhere) {
    id
  }
}
    `;
export type DeleteClientInvoiceMutationFn = Apollo.MutationFunction<DeleteClientInvoiceMutation, DeleteClientInvoiceMutationVariables>;

/**
 * __useDeleteClientInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteClientInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientInvoiceMutation, { data, loading, error }] = useDeleteClientInvoiceMutation({
 *   variables: {
 *      deleteOneClientInvoiceWhere: // value for 'deleteOneClientInvoiceWhere'
 *   },
 * });
 */
export function useDeleteClientInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientInvoiceMutation, DeleteClientInvoiceMutationVariables>) {
        return Apollo.useMutation<DeleteClientInvoiceMutation, DeleteClientInvoiceMutationVariables>(DeleteClientInvoiceDocument, baseOptions);
      }
export type DeleteClientInvoiceMutationHookResult = ReturnType<typeof useDeleteClientInvoiceMutation>;
export type DeleteClientInvoiceMutationResult = Apollo.MutationResult<DeleteClientInvoiceMutation>;
export type DeleteClientInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteClientInvoiceMutation, DeleteClientInvoiceMutationVariables>;
export const ClientInvoicesListDocument = gql`
    query ClientInvoicesList($estimateWhere: estimateWhereUniqueInput!) {
  estimate(where: $estimateWhere) {
    id
    client_invoices {
      id
      created_at
      invoice_number
      date_invoiced
      amount_in_cents
      estimate {
        id
      }
    }
  }
}
    `;

/**
 * __useClientInvoicesListQuery__
 *
 * To run a query within a React component, call `useClientInvoicesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInvoicesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInvoicesListQuery({
 *   variables: {
 *      estimateWhere: // value for 'estimateWhere'
 *   },
 * });
 */
export function useClientInvoicesListQuery(baseOptions: Apollo.QueryHookOptions<ClientInvoicesListQuery, ClientInvoicesListQueryVariables>) {
        return Apollo.useQuery<ClientInvoicesListQuery, ClientInvoicesListQueryVariables>(ClientInvoicesListDocument, baseOptions);
      }
export function useClientInvoicesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInvoicesListQuery, ClientInvoicesListQueryVariables>) {
          return Apollo.useLazyQuery<ClientInvoicesListQuery, ClientInvoicesListQueryVariables>(ClientInvoicesListDocument, baseOptions);
        }
export type ClientInvoicesListQueryHookResult = ReturnType<typeof useClientInvoicesListQuery>;
export type ClientInvoicesListLazyQueryHookResult = ReturnType<typeof useClientInvoicesListLazyQuery>;
export type ClientInvoicesListQueryResult = Apollo.QueryResult<ClientInvoicesListQuery, ClientInvoicesListQueryVariables>;
export const PeopleForNewEstimateDocument = gql`
    query PeopleForNewEstimate {
  people {
    id
    email_address
    first_name
    last_name
    title
    is_deleted_on_tw
  }
}
    `;

/**
 * __usePeopleForNewEstimateQuery__
 *
 * To run a query within a React component, call `usePeopleForNewEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleForNewEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleForNewEstimateQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleForNewEstimateQuery(baseOptions?: Apollo.QueryHookOptions<PeopleForNewEstimateQuery, PeopleForNewEstimateQueryVariables>) {
        return Apollo.useQuery<PeopleForNewEstimateQuery, PeopleForNewEstimateQueryVariables>(PeopleForNewEstimateDocument, baseOptions);
      }
export function usePeopleForNewEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleForNewEstimateQuery, PeopleForNewEstimateQueryVariables>) {
          return Apollo.useLazyQuery<PeopleForNewEstimateQuery, PeopleForNewEstimateQueryVariables>(PeopleForNewEstimateDocument, baseOptions);
        }
export type PeopleForNewEstimateQueryHookResult = ReturnType<typeof usePeopleForNewEstimateQuery>;
export type PeopleForNewEstimateLazyQueryHookResult = ReturnType<typeof usePeopleForNewEstimateLazyQuery>;
export type PeopleForNewEstimateQueryResult = Apollo.QueryResult<PeopleForNewEstimateQuery, PeopleForNewEstimateQueryVariables>;
export const CompaniesForNewEstimateDocument = gql`
    query CompaniesForNewEstimate {
  companies {
    id
    company_name
  }
}
    `;

/**
 * __useCompaniesForNewEstimateQuery__
 *
 * To run a query within a React component, call `useCompaniesForNewEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForNewEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForNewEstimateQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesForNewEstimateQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesForNewEstimateQuery, CompaniesForNewEstimateQueryVariables>) {
        return Apollo.useQuery<CompaniesForNewEstimateQuery, CompaniesForNewEstimateQueryVariables>(CompaniesForNewEstimateDocument, baseOptions);
      }
export function useCompaniesForNewEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesForNewEstimateQuery, CompaniesForNewEstimateQueryVariables>) {
          return Apollo.useLazyQuery<CompaniesForNewEstimateQuery, CompaniesForNewEstimateQueryVariables>(CompaniesForNewEstimateDocument, baseOptions);
        }
export type CompaniesForNewEstimateQueryHookResult = ReturnType<typeof useCompaniesForNewEstimateQuery>;
export type CompaniesForNewEstimateLazyQueryHookResult = ReturnType<typeof useCompaniesForNewEstimateLazyQuery>;
export type CompaniesForNewEstimateQueryResult = Apollo.QueryResult<CompaniesForNewEstimateQuery, CompaniesForNewEstimateQueryVariables>;
export const BrandsForNewEstimateDocument = gql`
    query BrandsForNewEstimate {
  brands {
    id
    brand_name
    company {
      id
    }
  }
}
    `;

/**
 * __useBrandsForNewEstimateQuery__
 *
 * To run a query within a React component, call `useBrandsForNewEstimateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsForNewEstimateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsForNewEstimateQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrandsForNewEstimateQuery(baseOptions?: Apollo.QueryHookOptions<BrandsForNewEstimateQuery, BrandsForNewEstimateQueryVariables>) {
        return Apollo.useQuery<BrandsForNewEstimateQuery, BrandsForNewEstimateQueryVariables>(BrandsForNewEstimateDocument, baseOptions);
      }
export function useBrandsForNewEstimateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsForNewEstimateQuery, BrandsForNewEstimateQueryVariables>) {
          return Apollo.useLazyQuery<BrandsForNewEstimateQuery, BrandsForNewEstimateQueryVariables>(BrandsForNewEstimateDocument, baseOptions);
        }
export type BrandsForNewEstimateQueryHookResult = ReturnType<typeof useBrandsForNewEstimateQuery>;
export type BrandsForNewEstimateLazyQueryHookResult = ReturnType<typeof useBrandsForNewEstimateLazyQuery>;
export type BrandsForNewEstimateQueryResult = Apollo.QueryResult<BrandsForNewEstimateQuery, BrandsForNewEstimateQueryVariables>;
export const CreateEstimateDocument = gql`
    mutation CreateEstimate($createOneEstimateData: estimateCreateInput!) {
  createOneEstimate(data: $createOneEstimateData) {
    id
  }
}
    `;
export type CreateEstimateMutationFn = Apollo.MutationFunction<CreateEstimateMutation, CreateEstimateMutationVariables>;

/**
 * __useCreateEstimateMutation__
 *
 * To run a mutation, you first call `useCreateEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEstimateMutation, { data, loading, error }] = useCreateEstimateMutation({
 *   variables: {
 *      createOneEstimateData: // value for 'createOneEstimateData'
 *   },
 * });
 */
export function useCreateEstimateMutation(baseOptions?: Apollo.MutationHookOptions<CreateEstimateMutation, CreateEstimateMutationVariables>) {
        return Apollo.useMutation<CreateEstimateMutation, CreateEstimateMutationVariables>(CreateEstimateDocument, baseOptions);
      }
export type CreateEstimateMutationHookResult = ReturnType<typeof useCreateEstimateMutation>;
export type CreateEstimateMutationResult = Apollo.MutationResult<CreateEstimateMutation>;
export type CreateEstimateMutationOptions = Apollo.BaseMutationOptions<CreateEstimateMutation, CreateEstimateMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($createOneCompanyData: companyCreateInput!) {
  createOneCompany(data: $createOneCompanyData) {
    id
    company_name
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      createOneCompanyData: // value for 'createOneCompanyData'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($createOneBrandData: brandCreateInput!) {
  createOneBrand(data: $createOneBrandData) {
    id
    brand_name
    company {
      id
    }
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      createOneBrandData: // value for 'createOneBrandData'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, baseOptions);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const EstimateProjectBudgetsDocument = gql`
    query EstimateProjectBudgets($where: estimateWhereUniqueInput!) {
  estimate(where: $where) {
    id
    projects {
      id
      name
      budget
    }
  }
}
    `;

/**
 * __useEstimateProjectBudgetsQuery__
 *
 * To run a query within a React component, call `useEstimateProjectBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateProjectBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateProjectBudgetsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEstimateProjectBudgetsQuery(baseOptions: Apollo.QueryHookOptions<EstimateProjectBudgetsQuery, EstimateProjectBudgetsQueryVariables>) {
        return Apollo.useQuery<EstimateProjectBudgetsQuery, EstimateProjectBudgetsQueryVariables>(EstimateProjectBudgetsDocument, baseOptions);
      }
export function useEstimateProjectBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimateProjectBudgetsQuery, EstimateProjectBudgetsQueryVariables>) {
          return Apollo.useLazyQuery<EstimateProjectBudgetsQuery, EstimateProjectBudgetsQueryVariables>(EstimateProjectBudgetsDocument, baseOptions);
        }
export type EstimateProjectBudgetsQueryHookResult = ReturnType<typeof useEstimateProjectBudgetsQuery>;
export type EstimateProjectBudgetsLazyQueryHookResult = ReturnType<typeof useEstimateProjectBudgetsLazyQuery>;
export type EstimateProjectBudgetsQueryResult = Apollo.QueryResult<EstimateProjectBudgetsQuery, EstimateProjectBudgetsQueryVariables>;
export const EstimateForEstimatesPageDocument = gql`
    query EstimateForEstimatesPage($estimatesWhere: estimateWhereInput) {
  estimates(where: $estimatesWhere) {
    ...EstimateFragmentForEstimatesPage
  }
}
    ${EstimateFragmentForEstimatesPageFragmentDoc}`;

/**
 * __useEstimateForEstimatesPageQuery__
 *
 * To run a query within a React component, call `useEstimateForEstimatesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateForEstimatesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateForEstimatesPageQuery({
 *   variables: {
 *      estimatesWhere: // value for 'estimatesWhere'
 *   },
 * });
 */
export function useEstimateForEstimatesPageQuery(baseOptions?: Apollo.QueryHookOptions<EstimateForEstimatesPageQuery, EstimateForEstimatesPageQueryVariables>) {
        return Apollo.useQuery<EstimateForEstimatesPageQuery, EstimateForEstimatesPageQueryVariables>(EstimateForEstimatesPageDocument, baseOptions);
      }
export function useEstimateForEstimatesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimateForEstimatesPageQuery, EstimateForEstimatesPageQueryVariables>) {
          return Apollo.useLazyQuery<EstimateForEstimatesPageQuery, EstimateForEstimatesPageQueryVariables>(EstimateForEstimatesPageDocument, baseOptions);
        }
export type EstimateForEstimatesPageQueryHookResult = ReturnType<typeof useEstimateForEstimatesPageQuery>;
export type EstimateForEstimatesPageLazyQueryHookResult = ReturnType<typeof useEstimateForEstimatesPageLazyQuery>;
export type EstimateForEstimatesPageQueryResult = Apollo.QueryResult<EstimateForEstimatesPageQuery, EstimateForEstimatesPageQueryVariables>;
export const CompaniesForEstimatesTableDocument = gql`
    query CompaniesForEstimatesTable {
  companies {
    id
    company_name
    brands {
      id
      brand_name
    }
  }
}
    `;

/**
 * __useCompaniesForEstimatesTableQuery__
 *
 * To run a query within a React component, call `useCompaniesForEstimatesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesForEstimatesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesForEstimatesTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesForEstimatesTableQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesForEstimatesTableQuery, CompaniesForEstimatesTableQueryVariables>) {
        return Apollo.useQuery<CompaniesForEstimatesTableQuery, CompaniesForEstimatesTableQueryVariables>(CompaniesForEstimatesTableDocument, baseOptions);
      }
export function useCompaniesForEstimatesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesForEstimatesTableQuery, CompaniesForEstimatesTableQueryVariables>) {
          return Apollo.useLazyQuery<CompaniesForEstimatesTableQuery, CompaniesForEstimatesTableQueryVariables>(CompaniesForEstimatesTableDocument, baseOptions);
        }
export type CompaniesForEstimatesTableQueryHookResult = ReturnType<typeof useCompaniesForEstimatesTableQuery>;
export type CompaniesForEstimatesTableLazyQueryHookResult = ReturnType<typeof useCompaniesForEstimatesTableLazyQuery>;
export type CompaniesForEstimatesTableQueryResult = Apollo.QueryResult<CompaniesForEstimatesTableQuery, CompaniesForEstimatesTableQueryVariables>;
export const UpdateEstimateDocument = gql`
    mutation UpdateEstimate($updateOneEstimateData: estimateUpdateInput!, $updateOneEstimateWhere: estimateWhereUniqueInput!) {
  updateOneEstimate(data: $updateOneEstimateData, where: $updateOneEstimateWhere) {
    ...PartEstimate
  }
}
    ${PartEstimateFragmentDoc}`;
export type UpdateEstimateMutationFn = Apollo.MutationFunction<UpdateEstimateMutation, UpdateEstimateMutationVariables>;

/**
 * __useUpdateEstimateMutation__
 *
 * To run a mutation, you first call `useUpdateEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstimateMutation, { data, loading, error }] = useUpdateEstimateMutation({
 *   variables: {
 *      updateOneEstimateData: // value for 'updateOneEstimateData'
 *      updateOneEstimateWhere: // value for 'updateOneEstimateWhere'
 *   },
 * });
 */
export function useUpdateEstimateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstimateMutation, UpdateEstimateMutationVariables>) {
        return Apollo.useMutation<UpdateEstimateMutation, UpdateEstimateMutationVariables>(UpdateEstimateDocument, baseOptions);
      }
export type UpdateEstimateMutationHookResult = ReturnType<typeof useUpdateEstimateMutation>;
export type UpdateEstimateMutationResult = Apollo.MutationResult<UpdateEstimateMutation>;
export type UpdateEstimateMutationOptions = Apollo.BaseMutationOptions<UpdateEstimateMutation, UpdateEstimateMutationVariables>;
export const DeleteOneEstimateDocument = gql`
    mutation DeleteOneEstimate($deleteOneEstimateWhere: estimateWhereUniqueInput!) {
  deleteOneEstimate(where: $deleteOneEstimateWhere) {
    id
  }
}
    `;
export type DeleteOneEstimateMutationFn = Apollo.MutationFunction<DeleteOneEstimateMutation, DeleteOneEstimateMutationVariables>;

/**
 * __useDeleteOneEstimateMutation__
 *
 * To run a mutation, you first call `useDeleteOneEstimateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneEstimateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneEstimateMutation, { data, loading, error }] = useDeleteOneEstimateMutation({
 *   variables: {
 *      deleteOneEstimateWhere: // value for 'deleteOneEstimateWhere'
 *   },
 * });
 */
export function useDeleteOneEstimateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneEstimateMutation, DeleteOneEstimateMutationVariables>) {
        return Apollo.useMutation<DeleteOneEstimateMutation, DeleteOneEstimateMutationVariables>(DeleteOneEstimateDocument, baseOptions);
      }
export type DeleteOneEstimateMutationHookResult = ReturnType<typeof useDeleteOneEstimateMutation>;
export type DeleteOneEstimateMutationResult = Apollo.MutationResult<DeleteOneEstimateMutation>;
export type DeleteOneEstimateMutationOptions = Apollo.BaseMutationOptions<DeleteOneEstimateMutation, DeleteOneEstimateMutationVariables>;
export const CreateOneClientInvoiceDocument = gql`
    mutation CreateOneClientInvoice($createOneClientInvoiceData: client_invoiceCreateInput!) {
  createOneClientInvoice(data: $createOneClientInvoiceData) {
    id
    estimate {
      id
      po_subtotal
      po_subtotal_remaining
    }
  }
}
    `;
export type CreateOneClientInvoiceMutationFn = Apollo.MutationFunction<CreateOneClientInvoiceMutation, CreateOneClientInvoiceMutationVariables>;

/**
 * __useCreateOneClientInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateOneClientInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClientInvoiceMutation, { data, loading, error }] = useCreateOneClientInvoiceMutation({
 *   variables: {
 *      createOneClientInvoiceData: // value for 'createOneClientInvoiceData'
 *   },
 * });
 */
export function useCreateOneClientInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClientInvoiceMutation, CreateOneClientInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateOneClientInvoiceMutation, CreateOneClientInvoiceMutationVariables>(CreateOneClientInvoiceDocument, baseOptions);
      }
export type CreateOneClientInvoiceMutationHookResult = ReturnType<typeof useCreateOneClientInvoiceMutation>;
export type CreateOneClientInvoiceMutationResult = Apollo.MutationResult<CreateOneClientInvoiceMutation>;
export type CreateOneClientInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateOneClientInvoiceMutation, CreateOneClientInvoiceMutationVariables>;
export const ClientInvoicesPageDocument = gql`
    query ClientInvoicesPage {
  clientInvoices {
    ...ClientNewInvoiceFragment
  }
}
    ${ClientNewInvoiceFragmentFragmentDoc}`;

/**
 * __useClientInvoicesPageQuery__
 *
 * To run a query within a React component, call `useClientInvoicesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInvoicesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInvoicesPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientInvoicesPageQuery(baseOptions?: Apollo.QueryHookOptions<ClientInvoicesPageQuery, ClientInvoicesPageQueryVariables>) {
        return Apollo.useQuery<ClientInvoicesPageQuery, ClientInvoicesPageQueryVariables>(ClientInvoicesPageDocument, baseOptions);
      }
export function useClientInvoicesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInvoicesPageQuery, ClientInvoicesPageQueryVariables>) {
          return Apollo.useLazyQuery<ClientInvoicesPageQuery, ClientInvoicesPageQueryVariables>(ClientInvoicesPageDocument, baseOptions);
        }
export type ClientInvoicesPageQueryHookResult = ReturnType<typeof useClientInvoicesPageQuery>;
export type ClientInvoicesPageLazyQueryHookResult = ReturnType<typeof useClientInvoicesPageLazyQuery>;
export type ClientInvoicesPageQueryResult = Apollo.QueryResult<ClientInvoicesPageQuery, ClientInvoicesPageQueryVariables>;
export const GetClientInvoiceDocument = gql`
    query GetClientInvoice($id: String!) {
  clientInvoiceById(id: $id) {
    ...ClientNewInvoiceFragment
  }
}
    ${ClientNewInvoiceFragmentFragmentDoc}`;

/**
 * __useGetClientInvoiceQuery__
 *
 * To run a query within a React component, call `useGetClientInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetClientInvoiceQuery, GetClientInvoiceQueryVariables>) {
        return Apollo.useQuery<GetClientInvoiceQuery, GetClientInvoiceQueryVariables>(GetClientInvoiceDocument, baseOptions);
      }
export function useGetClientInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientInvoiceQuery, GetClientInvoiceQueryVariables>) {
          return Apollo.useLazyQuery<GetClientInvoiceQuery, GetClientInvoiceQueryVariables>(GetClientInvoiceDocument, baseOptions);
        }
export type GetClientInvoiceQueryHookResult = ReturnType<typeof useGetClientInvoiceQuery>;
export type GetClientInvoiceLazyQueryHookResult = ReturnType<typeof useGetClientInvoiceLazyQuery>;
export type GetClientInvoiceQueryResult = Apollo.QueryResult<GetClientInvoiceQuery, GetClientInvoiceQueryVariables>;
export const UpdateClientInvoiceFromCreateInvoicesPageDocument = gql`
    mutation UpdateClientInvoiceFromCreateInvoicesPage($updateOneClientInvoiceData: client_invoiceUpdateInput!, $updateOneClientInvoiceWhere: client_invoiceWhereUniqueInput!) {
  updateOneClientInvoice(
    data: $updateOneClientInvoiceData
    where: $updateOneClientInvoiceWhere
  ) {
    ...ClientNewInvoiceFragment
  }
}
    ${ClientNewInvoiceFragmentFragmentDoc}`;
export type UpdateClientInvoiceFromCreateInvoicesPageMutationFn = Apollo.MutationFunction<UpdateClientInvoiceFromCreateInvoicesPageMutation, UpdateClientInvoiceFromCreateInvoicesPageMutationVariables>;

/**
 * __useUpdateClientInvoiceFromCreateInvoicesPageMutation__
 *
 * To run a mutation, you first call `useUpdateClientInvoiceFromCreateInvoicesPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientInvoiceFromCreateInvoicesPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientInvoiceFromCreateInvoicesPageMutation, { data, loading, error }] = useUpdateClientInvoiceFromCreateInvoicesPageMutation({
 *   variables: {
 *      updateOneClientInvoiceData: // value for 'updateOneClientInvoiceData'
 *      updateOneClientInvoiceWhere: // value for 'updateOneClientInvoiceWhere'
 *   },
 * });
 */
export function useUpdateClientInvoiceFromCreateInvoicesPageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientInvoiceFromCreateInvoicesPageMutation, UpdateClientInvoiceFromCreateInvoicesPageMutationVariables>) {
        return Apollo.useMutation<UpdateClientInvoiceFromCreateInvoicesPageMutation, UpdateClientInvoiceFromCreateInvoicesPageMutationVariables>(UpdateClientInvoiceFromCreateInvoicesPageDocument, baseOptions);
      }
export type UpdateClientInvoiceFromCreateInvoicesPageMutationHookResult = ReturnType<typeof useUpdateClientInvoiceFromCreateInvoicesPageMutation>;
export type UpdateClientInvoiceFromCreateInvoicesPageMutationResult = Apollo.MutationResult<UpdateClientInvoiceFromCreateInvoicesPageMutation>;
export type UpdateClientInvoiceFromCreateInvoicesPageMutationOptions = Apollo.BaseMutationOptions<UpdateClientInvoiceFromCreateInvoicesPageMutation, UpdateClientInvoiceFromCreateInvoicesPageMutationVariables>;
export const GrossProfitSummaryDocument = gql`
    query GrossProfitSummary($start_date: Date!, $end_date: Date!) {
  people {
    id
    first_name
    last_name
  }
  grossProfitReport(start_date: $start_date, end_date: $end_date) {
    id
    entries {
      estimate_id
      estimate_name
      estimate_date
      owner_id
      estimate_po_subtotal
      expense_amount
    }
  }
}
    `;

/**
 * __useGrossProfitSummaryQuery__
 *
 * To run a query within a React component, call `useGrossProfitSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrossProfitSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrossProfitSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *   },
 * });
 */
export function useGrossProfitSummaryQuery(baseOptions: Apollo.QueryHookOptions<GrossProfitSummaryQuery, GrossProfitSummaryQueryVariables>) {
        return Apollo.useQuery<GrossProfitSummaryQuery, GrossProfitSummaryQueryVariables>(GrossProfitSummaryDocument, baseOptions);
      }
export function useGrossProfitSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GrossProfitSummaryQuery, GrossProfitSummaryQueryVariables>) {
          return Apollo.useLazyQuery<GrossProfitSummaryQuery, GrossProfitSummaryQueryVariables>(GrossProfitSummaryDocument, baseOptions);
        }
export type GrossProfitSummaryQueryHookResult = ReturnType<typeof useGrossProfitSummaryQuery>;
export type GrossProfitSummaryLazyQueryHookResult = ReturnType<typeof useGrossProfitSummaryLazyQuery>;
export type GrossProfitSummaryQueryResult = Apollo.QueryResult<GrossProfitSummaryQuery, GrossProfitSummaryQueryVariables>;
export const GetProjectsForBrandAnalysisDocument = gql`
    query GetProjectsForBrandAnalysis($where: projectWhereInput) {
  projects(orderBy: {created_at: desc}, where: $where) {
    id
    name
    project_status
    senior_brand_maven_lead
    owner_id
    owner {
      id
      first_name
      last_name
      title
    }
    client {
      id
      name
    }
    start_date
    end_date
    budget
    time_entries {
      id
      minutes
      user_id
    }
    rates {
      id
      person_id
      rate_in_cents
    }
    invoices {
      id
      amount_in_cents
    }
    sales_split_ratio
    sales_split_pod {
      id
      senior_brand_maven_lead
    }
    created_at
  }
}
    `;

/**
 * __useGetProjectsForBrandAnalysisQuery__
 *
 * To run a query within a React component, call `useGetProjectsForBrandAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsForBrandAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsForBrandAnalysisQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProjectsForBrandAnalysisQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>) {
        return Apollo.useQuery<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>(GetProjectsForBrandAnalysisDocument, baseOptions);
      }
export function useGetProjectsForBrandAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>(GetProjectsForBrandAnalysisDocument, baseOptions);
        }
export type GetProjectsForBrandAnalysisQueryHookResult = ReturnType<typeof useGetProjectsForBrandAnalysisQuery>;
export type GetProjectsForBrandAnalysisLazyQueryHookResult = ReturnType<typeof useGetProjectsForBrandAnalysisLazyQuery>;
export type GetProjectsForBrandAnalysisQueryResult = Apollo.QueryResult<GetProjectsForBrandAnalysisQuery, GetProjectsForBrandAnalysisQueryVariables>;
export const GetClientAnnualRevenueForecastDocument = gql`
    query GetClientAnnualRevenueForecast($client_id: String!, $year: Int!, $podId: String!) {
  clientAnnualRevenueForecast(clientId: $client_id, year: $year, podId: $podId) {
    id
    clientId
    podId
    year
    lowForecastDollars
    highForecastDollars
    updatedAt
    updatedByUserId
  }
}
    `;

/**
 * __useGetClientAnnualRevenueForecastQuery__
 *
 * To run a query within a React component, call `useGetClientAnnualRevenueForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAnnualRevenueForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAnnualRevenueForecastQuery({
 *   variables: {
 *      client_id: // value for 'client_id'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *   },
 * });
 */
export function useGetClientAnnualRevenueForecastQuery(baseOptions: Apollo.QueryHookOptions<GetClientAnnualRevenueForecastQuery, GetClientAnnualRevenueForecastQueryVariables>) {
        return Apollo.useQuery<GetClientAnnualRevenueForecastQuery, GetClientAnnualRevenueForecastQueryVariables>(GetClientAnnualRevenueForecastDocument, baseOptions);
      }
export function useGetClientAnnualRevenueForecastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientAnnualRevenueForecastQuery, GetClientAnnualRevenueForecastQueryVariables>) {
          return Apollo.useLazyQuery<GetClientAnnualRevenueForecastQuery, GetClientAnnualRevenueForecastQueryVariables>(GetClientAnnualRevenueForecastDocument, baseOptions);
        }
export type GetClientAnnualRevenueForecastQueryHookResult = ReturnType<typeof useGetClientAnnualRevenueForecastQuery>;
export type GetClientAnnualRevenueForecastLazyQueryHookResult = ReturnType<typeof useGetClientAnnualRevenueForecastLazyQuery>;
export type GetClientAnnualRevenueForecastQueryResult = Apollo.QueryResult<GetClientAnnualRevenueForecastQuery, GetClientAnnualRevenueForecastQueryVariables>;
export const GetClientAnnualRevenueForecastsDocument = gql`
    query GetClientAnnualRevenueForecasts($clientIds: [String!]!, $year: Int!, $podId: String!) {
  clientAnnualRevenueForecasts(clientIds: $clientIds, year: $year, podId: $podId) {
    id
    clientId
    podId
    year
    lowForecastDollars
    highForecastDollars
    updatedAt
    updatedByUserId
  }
}
    `;

/**
 * __useGetClientAnnualRevenueForecastsQuery__
 *
 * To run a query within a React component, call `useGetClientAnnualRevenueForecastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientAnnualRevenueForecastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientAnnualRevenueForecastsQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *   },
 * });
 */
export function useGetClientAnnualRevenueForecastsQuery(baseOptions: Apollo.QueryHookOptions<GetClientAnnualRevenueForecastsQuery, GetClientAnnualRevenueForecastsQueryVariables>) {
        return Apollo.useQuery<GetClientAnnualRevenueForecastsQuery, GetClientAnnualRevenueForecastsQueryVariables>(GetClientAnnualRevenueForecastsDocument, baseOptions);
      }
export function useGetClientAnnualRevenueForecastsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientAnnualRevenueForecastsQuery, GetClientAnnualRevenueForecastsQueryVariables>) {
          return Apollo.useLazyQuery<GetClientAnnualRevenueForecastsQuery, GetClientAnnualRevenueForecastsQueryVariables>(GetClientAnnualRevenueForecastsDocument, baseOptions);
        }
export type GetClientAnnualRevenueForecastsQueryHookResult = ReturnType<typeof useGetClientAnnualRevenueForecastsQuery>;
export type GetClientAnnualRevenueForecastsLazyQueryHookResult = ReturnType<typeof useGetClientAnnualRevenueForecastsLazyQuery>;
export type GetClientAnnualRevenueForecastsQueryResult = Apollo.QueryResult<GetClientAnnualRevenueForecastsQuery, GetClientAnnualRevenueForecastsQueryVariables>;
export const AddClientAnnualRevenueForecastsDocument = gql`
    mutation AddClientAnnualRevenueForecasts($clientIds: [String!]!, $year: Int!, $podId: String!, $lowForecastDollars: Int, $highForecastDollars: Int, $userId: String!) {
  addClientAnnualRevenueForecasts(
    clientIds: $clientIds
    year: $year
    podId: $podId
    lowForecastDollars: $lowForecastDollars
    highForecastDollars: $highForecastDollars
    userId: $userId
  ) {
    count
  }
}
    `;
export type AddClientAnnualRevenueForecastsMutationFn = Apollo.MutationFunction<AddClientAnnualRevenueForecastsMutation, AddClientAnnualRevenueForecastsMutationVariables>;

/**
 * __useAddClientAnnualRevenueForecastsMutation__
 *
 * To run a mutation, you first call `useAddClientAnnualRevenueForecastsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientAnnualRevenueForecastsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientAnnualRevenueForecastsMutation, { data, loading, error }] = useAddClientAnnualRevenueForecastsMutation({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *      lowForecastDollars: // value for 'lowForecastDollars'
 *      highForecastDollars: // value for 'highForecastDollars'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddClientAnnualRevenueForecastsMutation(baseOptions?: Apollo.MutationHookOptions<AddClientAnnualRevenueForecastsMutation, AddClientAnnualRevenueForecastsMutationVariables>) {
        return Apollo.useMutation<AddClientAnnualRevenueForecastsMutation, AddClientAnnualRevenueForecastsMutationVariables>(AddClientAnnualRevenueForecastsDocument, baseOptions);
      }
export type AddClientAnnualRevenueForecastsMutationHookResult = ReturnType<typeof useAddClientAnnualRevenueForecastsMutation>;
export type AddClientAnnualRevenueForecastsMutationResult = Apollo.MutationResult<AddClientAnnualRevenueForecastsMutation>;
export type AddClientAnnualRevenueForecastsMutationOptions = Apollo.BaseMutationOptions<AddClientAnnualRevenueForecastsMutation, AddClientAnnualRevenueForecastsMutationVariables>;
export const AddClientAnnualRevenueForecastDocument = gql`
    mutation AddClientAnnualRevenueForecast($clientId: String!, $year: Int!, $podId: String!, $lowForecastDollars: Int!, $highForecastDollars: Int!, $updatedByUserId: String!) {
  addClientAnnualRevenueForecast(
    clientId: $clientId
    year: $year
    podId: $podId
    lowForecastDollars: $lowForecastDollars
    highForecastDollars: $highForecastDollars
    updatedByUserId: $updatedByUserId
  ) {
    id
    clientId
    podId
    year
    lowForecastDollars
    highForecastDollars
    updatedByUserId
  }
}
    `;
export type AddClientAnnualRevenueForecastMutationFn = Apollo.MutationFunction<AddClientAnnualRevenueForecastMutation, AddClientAnnualRevenueForecastMutationVariables>;

/**
 * __useAddClientAnnualRevenueForecastMutation__
 *
 * To run a mutation, you first call `useAddClientAnnualRevenueForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientAnnualRevenueForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientAnnualRevenueForecastMutation, { data, loading, error }] = useAddClientAnnualRevenueForecastMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *      lowForecastDollars: // value for 'lowForecastDollars'
 *      highForecastDollars: // value for 'highForecastDollars'
 *      updatedByUserId: // value for 'updatedByUserId'
 *   },
 * });
 */
export function useAddClientAnnualRevenueForecastMutation(baseOptions?: Apollo.MutationHookOptions<AddClientAnnualRevenueForecastMutation, AddClientAnnualRevenueForecastMutationVariables>) {
        return Apollo.useMutation<AddClientAnnualRevenueForecastMutation, AddClientAnnualRevenueForecastMutationVariables>(AddClientAnnualRevenueForecastDocument, baseOptions);
      }
export type AddClientAnnualRevenueForecastMutationHookResult = ReturnType<typeof useAddClientAnnualRevenueForecastMutation>;
export type AddClientAnnualRevenueForecastMutationResult = Apollo.MutationResult<AddClientAnnualRevenueForecastMutation>;
export type AddClientAnnualRevenueForecastMutationOptions = Apollo.BaseMutationOptions<AddClientAnnualRevenueForecastMutation, AddClientAnnualRevenueForecastMutationVariables>;
export const UpdateClientAnnualRevenueForecastDocument = gql`
    mutation UpdateClientAnnualRevenueForecast($clientId: String!, $year: Int!, $podId: String!, $lowForecastDollars: Int, $highForecastDollars: Int, $updatedByUserId: String!) {
  updateClientAnnualRevenueForecast(
    clientId: $clientId
    year: $year
    podId: $podId
    lowForecastDollars: $lowForecastDollars
    highForecastDollars: $highForecastDollars
    updatedByUserId: $updatedByUserId
  ) {
    id
    clientId
    year
    lowForecastDollars
    highForecastDollars
    updatedByUserId
  }
}
    `;
export type UpdateClientAnnualRevenueForecastMutationFn = Apollo.MutationFunction<UpdateClientAnnualRevenueForecastMutation, UpdateClientAnnualRevenueForecastMutationVariables>;

/**
 * __useUpdateClientAnnualRevenueForecastMutation__
 *
 * To run a mutation, you first call `useUpdateClientAnnualRevenueForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientAnnualRevenueForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientAnnualRevenueForecastMutation, { data, loading, error }] = useUpdateClientAnnualRevenueForecastMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *      lowForecastDollars: // value for 'lowForecastDollars'
 *      highForecastDollars: // value for 'highForecastDollars'
 *      updatedByUserId: // value for 'updatedByUserId'
 *   },
 * });
 */
export function useUpdateClientAnnualRevenueForecastMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientAnnualRevenueForecastMutation, UpdateClientAnnualRevenueForecastMutationVariables>) {
        return Apollo.useMutation<UpdateClientAnnualRevenueForecastMutation, UpdateClientAnnualRevenueForecastMutationVariables>(UpdateClientAnnualRevenueForecastDocument, baseOptions);
      }
export type UpdateClientAnnualRevenueForecastMutationHookResult = ReturnType<typeof useUpdateClientAnnualRevenueForecastMutation>;
export type UpdateClientAnnualRevenueForecastMutationResult = Apollo.MutationResult<UpdateClientAnnualRevenueForecastMutation>;
export type UpdateClientAnnualRevenueForecastMutationOptions = Apollo.BaseMutationOptions<UpdateClientAnnualRevenueForecastMutation, UpdateClientAnnualRevenueForecastMutationVariables>;
export const DeleteClientAnnualRevenueForecastDocument = gql`
    mutation DeleteClientAnnualRevenueForecast($clientId: String!, $year: Int!, $podId: String!) {
  deleteClientAnnualRevenueForecast(
    clientId: $clientId
    year: $year
    podId: $podId
  ) {
    clientId
    year
    podId
  }
}
    `;
export type DeleteClientAnnualRevenueForecastMutationFn = Apollo.MutationFunction<DeleteClientAnnualRevenueForecastMutation, DeleteClientAnnualRevenueForecastMutationVariables>;

/**
 * __useDeleteClientAnnualRevenueForecastMutation__
 *
 * To run a mutation, you first call `useDeleteClientAnnualRevenueForecastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientAnnualRevenueForecastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientAnnualRevenueForecastMutation, { data, loading, error }] = useDeleteClientAnnualRevenueForecastMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      year: // value for 'year'
 *      podId: // value for 'podId'
 *   },
 * });
 */
export function useDeleteClientAnnualRevenueForecastMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientAnnualRevenueForecastMutation, DeleteClientAnnualRevenueForecastMutationVariables>) {
        return Apollo.useMutation<DeleteClientAnnualRevenueForecastMutation, DeleteClientAnnualRevenueForecastMutationVariables>(DeleteClientAnnualRevenueForecastDocument, baseOptions);
      }
export type DeleteClientAnnualRevenueForecastMutationHookResult = ReturnType<typeof useDeleteClientAnnualRevenueForecastMutation>;
export type DeleteClientAnnualRevenueForecastMutationResult = Apollo.MutationResult<DeleteClientAnnualRevenueForecastMutation>;
export type DeleteClientAnnualRevenueForecastMutationOptions = Apollo.BaseMutationOptions<DeleteClientAnnualRevenueForecastMutation, DeleteClientAnnualRevenueForecastMutationVariables>;
export const CompanyRevenueForecastDocument = gql`
    query CompanyRevenueForecast($year: Int!) {
  companyAnnualRevenueTargets {
    year
    revenue_target_dollars
    net_sales_potential_cents
    net_sales_earned_cents
  }
  companyAnnualRevenueForecastProjects(year: $year) {
    id
    project_status
    end_date
    cogs_in_cents
    budget
    senior_brand_maven_lead
    sales_split_ratio
    sales_split_pod {
      id
      senior_brand_maven_lead
    }
  }
  pods {
    id
    name
    senior_brand_maven_lead
    annual_revenue_target(year: $year) {
      podId
      revenue_target_dollars
      year
    }
  }
}
    `;

/**
 * __useCompanyRevenueForecastQuery__
 *
 * To run a query within a React component, call `useCompanyRevenueForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRevenueForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRevenueForecastQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useCompanyRevenueForecastQuery(baseOptions: Apollo.QueryHookOptions<CompanyRevenueForecastQuery, CompanyRevenueForecastQueryVariables>) {
        return Apollo.useQuery<CompanyRevenueForecastQuery, CompanyRevenueForecastQueryVariables>(CompanyRevenueForecastDocument, baseOptions);
      }
export function useCompanyRevenueForecastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyRevenueForecastQuery, CompanyRevenueForecastQueryVariables>) {
          return Apollo.useLazyQuery<CompanyRevenueForecastQuery, CompanyRevenueForecastQueryVariables>(CompanyRevenueForecastDocument, baseOptions);
        }
export type CompanyRevenueForecastQueryHookResult = ReturnType<typeof useCompanyRevenueForecastQuery>;
export type CompanyRevenueForecastLazyQueryHookResult = ReturnType<typeof useCompanyRevenueForecastLazyQuery>;
export type CompanyRevenueForecastQueryResult = Apollo.QueryResult<CompanyRevenueForecastQuery, CompanyRevenueForecastQueryVariables>;
export const PodRevenueForecastDocument = gql`
    query PodRevenueForecast($year: Int!) {
  me {
    id
    pods {
      id
      name
      senior_brand_maven_lead
      annual_revenue_target(year: $year) {
        podId
        year
        revenue_target_dollars
      }
      projects(year: $year) {
        id
        project_status
        start_date
        end_date
        cogs_in_cents
        budget
        senior_brand_maven_lead
        sales_split_ratio
        sales_split_pod {
          id
          senior_brand_maven_lead
        }
      }
    }
  }
}
    `;

/**
 * __usePodRevenueForecastQuery__
 *
 * To run a query within a React component, call `usePodRevenueForecastQuery` and pass it any options that fit your needs.
 * When your component renders, `usePodRevenueForecastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePodRevenueForecastQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function usePodRevenueForecastQuery(baseOptions: Apollo.QueryHookOptions<PodRevenueForecastQuery, PodRevenueForecastQueryVariables>) {
        return Apollo.useQuery<PodRevenueForecastQuery, PodRevenueForecastQueryVariables>(PodRevenueForecastDocument, baseOptions);
      }
export function usePodRevenueForecastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PodRevenueForecastQuery, PodRevenueForecastQueryVariables>) {
          return Apollo.useLazyQuery<PodRevenueForecastQuery, PodRevenueForecastQueryVariables>(PodRevenueForecastDocument, baseOptions);
        }
export type PodRevenueForecastQueryHookResult = ReturnType<typeof usePodRevenueForecastQuery>;
export type PodRevenueForecastLazyQueryHookResult = ReturnType<typeof usePodRevenueForecastLazyQuery>;
export type PodRevenueForecastQueryResult = Apollo.QueryResult<PodRevenueForecastQuery, PodRevenueForecastQueryVariables>;
export const NavDocument = gql`
    query Nav {
  people {
    id
    first_name
    last_name
    title
    email_address
    avatar_url
  }
  me {
    id
    email_address
    first_name
    last_name
    avatar_url
    pods {
      id
      name
      members {
        id
        first_name
        last_name
        email_address
      }
    }
  }
}
    `;

/**
 * __useNavQuery__
 *
 * To run a query within a React component, call `useNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavQuery(baseOptions?: Apollo.QueryHookOptions<NavQuery, NavQueryVariables>) {
        return Apollo.useQuery<NavQuery, NavQueryVariables>(NavDocument, baseOptions);
      }
export function useNavLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavQuery, NavQueryVariables>) {
          return Apollo.useLazyQuery<NavQuery, NavQueryVariables>(NavDocument, baseOptions);
        }
export type NavQueryHookResult = ReturnType<typeof useNavQuery>;
export type NavLazyQueryHookResult = ReturnType<typeof useNavLazyQuery>;
export type NavQueryResult = Apollo.QueryResult<NavQuery, NavQueryVariables>;
export const GetProjectPricingDocument = gql`
    query GetProjectPricing($projectId: String!) {
  projectPricing(project_id: $projectId) {
    id
    project_id
    price
    actual_cost
    currency
    projected_times {
      id
      tasklist_id
      role_id
      team_id
      projected_minutes
    }
    projected_expenses {
      id
      project_id
      project_pricing_id
      budget_in_cents
      expense_type
      payee_name
    }
  }
}
    `;

/**
 * __useGetProjectPricingQuery__
 *
 * To run a query within a React component, call `useGetProjectPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPricingQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectPricingQuery(baseOptions: Apollo.QueryHookOptions<GetProjectPricingQuery, GetProjectPricingQueryVariables>) {
        return Apollo.useQuery<GetProjectPricingQuery, GetProjectPricingQueryVariables>(GetProjectPricingDocument, baseOptions);
      }
export function useGetProjectPricingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectPricingQuery, GetProjectPricingQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectPricingQuery, GetProjectPricingQueryVariables>(GetProjectPricingDocument, baseOptions);
        }
export type GetProjectPricingQueryHookResult = ReturnType<typeof useGetProjectPricingQuery>;
export type GetProjectPricingLazyQueryHookResult = ReturnType<typeof useGetProjectPricingLazyQuery>;
export type GetProjectPricingQueryResult = Apollo.QueryResult<GetProjectPricingQuery, GetProjectPricingQueryVariables>;
export const GetProjectRatesByProjectIdDocument = gql`
    query GetProjectRatesByProjectId($projectId: String!) {
  projectRatesByProjectId(projectId: $projectId) {
    id
    rate_in_cents
    project_id
    project {
      name
    }
    person {
      id
      first_name
      last_name
      team {
        id
        teamName
      }
      role {
        id
        roleTitle
      }
    }
  }
}
    `;

/**
 * __useGetProjectRatesByProjectIdQuery__
 *
 * To run a query within a React component, call `useGetProjectRatesByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectRatesByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectRatesByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectRatesByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectRatesByProjectIdQuery, GetProjectRatesByProjectIdQueryVariables>) {
        return Apollo.useQuery<GetProjectRatesByProjectIdQuery, GetProjectRatesByProjectIdQueryVariables>(GetProjectRatesByProjectIdDocument, baseOptions);
      }
export function useGetProjectRatesByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectRatesByProjectIdQuery, GetProjectRatesByProjectIdQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectRatesByProjectIdQuery, GetProjectRatesByProjectIdQueryVariables>(GetProjectRatesByProjectIdDocument, baseOptions);
        }
export type GetProjectRatesByProjectIdQueryHookResult = ReturnType<typeof useGetProjectRatesByProjectIdQuery>;
export type GetProjectRatesByProjectIdLazyQueryHookResult = ReturnType<typeof useGetProjectRatesByProjectIdLazyQuery>;
export type GetProjectRatesByProjectIdQueryResult = Apollo.QueryResult<GetProjectRatesByProjectIdQuery, GetProjectRatesByProjectIdQueryVariables>;
export const CreateProjectPricingDocument = gql`
    mutation CreateProjectPricing($projectId: String!, $tasklistId: Int!, $creatorId: String!) {
  createProjectPricing(
    projectId: $projectId
    tasklistId: $tasklistId
    creatorId: $creatorId
  ) {
    id
    project_id
    price
    actual_cost
    currency
    projected_times {
      id
      tasklist_id
      role_id
      team_id
      projected_minutes
    }
  }
}
    `;
export type CreateProjectPricingMutationFn = Apollo.MutationFunction<CreateProjectPricingMutation, CreateProjectPricingMutationVariables>;

/**
 * __useCreateProjectPricingMutation__
 *
 * To run a mutation, you first call `useCreateProjectPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectPricingMutation, { data, loading, error }] = useCreateProjectPricingMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tasklistId: // value for 'tasklistId'
 *      creatorId: // value for 'creatorId'
 *   },
 * });
 */
export function useCreateProjectPricingMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectPricingMutation, CreateProjectPricingMutationVariables>) {
        return Apollo.useMutation<CreateProjectPricingMutation, CreateProjectPricingMutationVariables>(CreateProjectPricingDocument, baseOptions);
      }
export type CreateProjectPricingMutationHookResult = ReturnType<typeof useCreateProjectPricingMutation>;
export type CreateProjectPricingMutationResult = Apollo.MutationResult<CreateProjectPricingMutation>;
export type CreateProjectPricingMutationOptions = Apollo.BaseMutationOptions<CreateProjectPricingMutation, CreateProjectPricingMutationVariables>;
export const CreateProjectPricingProjectedTimesDocument = gql`
    mutation CreateProjectPricingProjectedTimes($projectId: String!, $projectPricingId: String!, $tasklistIds: [String!]!, $roleIds: [String!]!, $teamIds: [String!]!, $minutes: Int!) {
  createProjectedTimes(
    projectId: $projectId
    projectPricingId: $projectPricingId
    tasklistIds: $tasklistIds
    roleIds: $roleIds
    teamIds: $teamIds
    minutes: $minutes
  ) {
    count
  }
}
    `;
export type CreateProjectPricingProjectedTimesMutationFn = Apollo.MutationFunction<CreateProjectPricingProjectedTimesMutation, CreateProjectPricingProjectedTimesMutationVariables>;

/**
 * __useCreateProjectPricingProjectedTimesMutation__
 *
 * To run a mutation, you first call `useCreateProjectPricingProjectedTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectPricingProjectedTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectPricingProjectedTimesMutation, { data, loading, error }] = useCreateProjectPricingProjectedTimesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectPricingId: // value for 'projectPricingId'
 *      tasklistIds: // value for 'tasklistIds'
 *      roleIds: // value for 'roleIds'
 *      teamIds: // value for 'teamIds'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useCreateProjectPricingProjectedTimesMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectPricingProjectedTimesMutation, CreateProjectPricingProjectedTimesMutationVariables>) {
        return Apollo.useMutation<CreateProjectPricingProjectedTimesMutation, CreateProjectPricingProjectedTimesMutationVariables>(CreateProjectPricingProjectedTimesDocument, baseOptions);
      }
export type CreateProjectPricingProjectedTimesMutationHookResult = ReturnType<typeof useCreateProjectPricingProjectedTimesMutation>;
export type CreateProjectPricingProjectedTimesMutationResult = Apollo.MutationResult<CreateProjectPricingProjectedTimesMutation>;
export type CreateProjectPricingProjectedTimesMutationOptions = Apollo.BaseMutationOptions<CreateProjectPricingProjectedTimesMutation, CreateProjectPricingProjectedTimesMutationVariables>;
export const UpdateProjectedTimeDocument = gql`
    mutation UpdateProjectedTime($id: Int!, $minutes: Int!) {
  updateProjectedTime(id: $id, minutes: $minutes) {
    id
    tasklist_id
    role_id
    team_id
    projected_minutes
  }
}
    `;
export type UpdateProjectedTimeMutationFn = Apollo.MutationFunction<UpdateProjectedTimeMutation, UpdateProjectedTimeMutationVariables>;

/**
 * __useUpdateProjectedTimeMutation__
 *
 * To run a mutation, you first call `useUpdateProjectedTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectedTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectedTimeMutation, { data, loading, error }] = useUpdateProjectedTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useUpdateProjectedTimeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectedTimeMutation, UpdateProjectedTimeMutationVariables>) {
        return Apollo.useMutation<UpdateProjectedTimeMutation, UpdateProjectedTimeMutationVariables>(UpdateProjectedTimeDocument, baseOptions);
      }
export type UpdateProjectedTimeMutationHookResult = ReturnType<typeof useUpdateProjectedTimeMutation>;
export type UpdateProjectedTimeMutationResult = Apollo.MutationResult<UpdateProjectedTimeMutation>;
export type UpdateProjectedTimeMutationOptions = Apollo.BaseMutationOptions<UpdateProjectedTimeMutation, UpdateProjectedTimeMutationVariables>;
export const CreateProjectedExpenseDocument = gql`
    mutation CreateProjectedExpense($projectId: String!, $projectPricingId: Int!, $budgetInCents: Int!, $expenseType: String!, $payeeName: String!) {
  createProjectedExpense(
    projectId: $projectId
    projectPricingId: $projectPricingId
    budgetInCents: $budgetInCents
    expenseType: $expenseType
    payeeName: $payeeName
  ) {
    id
    project_id
    project_pricing_id
    budget_in_cents
    expense_type
    payee_name
  }
}
    `;
export type CreateProjectedExpenseMutationFn = Apollo.MutationFunction<CreateProjectedExpenseMutation, CreateProjectedExpenseMutationVariables>;

/**
 * __useCreateProjectedExpenseMutation__
 *
 * To run a mutation, you first call `useCreateProjectedExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectedExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectedExpenseMutation, { data, loading, error }] = useCreateProjectedExpenseMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectPricingId: // value for 'projectPricingId'
 *      budgetInCents: // value for 'budgetInCents'
 *      expenseType: // value for 'expenseType'
 *      payeeName: // value for 'payeeName'
 *   },
 * });
 */
export function useCreateProjectedExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectedExpenseMutation, CreateProjectedExpenseMutationVariables>) {
        return Apollo.useMutation<CreateProjectedExpenseMutation, CreateProjectedExpenseMutationVariables>(CreateProjectedExpenseDocument, baseOptions);
      }
export type CreateProjectedExpenseMutationHookResult = ReturnType<typeof useCreateProjectedExpenseMutation>;
export type CreateProjectedExpenseMutationResult = Apollo.MutationResult<CreateProjectedExpenseMutation>;
export type CreateProjectedExpenseMutationOptions = Apollo.BaseMutationOptions<CreateProjectedExpenseMutation, CreateProjectedExpenseMutationVariables>;
export const UpdateProjectedExpenseDocument = gql`
    mutation UpdateProjectedExpense($id: Int!, $budgetInCents: Int!, $expenseType: String!, $payeeName: String!) {
  updateProjectedExpense(
    id: $id
    budgetInCents: $budgetInCents
    expenseType: $expenseType
    payeeName: $payeeName
  ) {
    id
    project_id
    project_pricing_id
    budget_in_cents
    expense_type
    payee_name
  }
}
    `;
export type UpdateProjectedExpenseMutationFn = Apollo.MutationFunction<UpdateProjectedExpenseMutation, UpdateProjectedExpenseMutationVariables>;

/**
 * __useUpdateProjectedExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateProjectedExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectedExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectedExpenseMutation, { data, loading, error }] = useUpdateProjectedExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      budgetInCents: // value for 'budgetInCents'
 *      expenseType: // value for 'expenseType'
 *      payeeName: // value for 'payeeName'
 *   },
 * });
 */
export function useUpdateProjectedExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectedExpenseMutation, UpdateProjectedExpenseMutationVariables>) {
        return Apollo.useMutation<UpdateProjectedExpenseMutation, UpdateProjectedExpenseMutationVariables>(UpdateProjectedExpenseDocument, baseOptions);
      }
export type UpdateProjectedExpenseMutationHookResult = ReturnType<typeof useUpdateProjectedExpenseMutation>;
export type UpdateProjectedExpenseMutationResult = Apollo.MutationResult<UpdateProjectedExpenseMutation>;
export type UpdateProjectedExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateProjectedExpenseMutation, UpdateProjectedExpenseMutationVariables>;
export const DeleteProjectedExpenseDocument = gql`
    mutation DeleteProjectedExpense($id: Int!) {
  deleteProjectedExpense(id: $id) {
    id
    project_id
    project_pricing_id
    budget_in_cents
    expense_type
    payee_name
  }
}
    `;
export type DeleteProjectedExpenseMutationFn = Apollo.MutationFunction<DeleteProjectedExpenseMutation, DeleteProjectedExpenseMutationVariables>;

/**
 * __useDeleteProjectedExpenseMutation__
 *
 * To run a mutation, you first call `useDeleteProjectedExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectedExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectedExpenseMutation, { data, loading, error }] = useDeleteProjectedExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectedExpenseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectedExpenseMutation, DeleteProjectedExpenseMutationVariables>) {
        return Apollo.useMutation<DeleteProjectedExpenseMutation, DeleteProjectedExpenseMutationVariables>(DeleteProjectedExpenseDocument, baseOptions);
      }
export type DeleteProjectedExpenseMutationHookResult = ReturnType<typeof useDeleteProjectedExpenseMutation>;
export type DeleteProjectedExpenseMutationResult = Apollo.MutationResult<DeleteProjectedExpenseMutation>;
export type DeleteProjectedExpenseMutationOptions = Apollo.BaseMutationOptions<DeleteProjectedExpenseMutation, DeleteProjectedExpenseMutationVariables>;
export const SearchProjectsForEstimatorDocument = gql`
    query SearchProjectsForEstimator($keyword: String) {
  searchProjects: projects(
    where: {AND: [{OR: [{name: {contains: $keyword}}, {description: {contains: $keyword}}, {component_code: {contains: $keyword}}]}, {is_archived: {equals: false}}]}
    first: 10
  ) {
    id
    name
  }
}
    `;

/**
 * __useSearchProjectsForEstimatorQuery__
 *
 * To run a query within a React component, call `useSearchProjectsForEstimatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProjectsForEstimatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProjectsForEstimatorQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchProjectsForEstimatorQuery(baseOptions?: Apollo.QueryHookOptions<SearchProjectsForEstimatorQuery, SearchProjectsForEstimatorQueryVariables>) {
        return Apollo.useQuery<SearchProjectsForEstimatorQuery, SearchProjectsForEstimatorQueryVariables>(SearchProjectsForEstimatorDocument, baseOptions);
      }
export function useSearchProjectsForEstimatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProjectsForEstimatorQuery, SearchProjectsForEstimatorQueryVariables>) {
          return Apollo.useLazyQuery<SearchProjectsForEstimatorQuery, SearchProjectsForEstimatorQueryVariables>(SearchProjectsForEstimatorDocument, baseOptions);
        }
export type SearchProjectsForEstimatorQueryHookResult = ReturnType<typeof useSearchProjectsForEstimatorQuery>;
export type SearchProjectsForEstimatorLazyQueryHookResult = ReturnType<typeof useSearchProjectsForEstimatorLazyQuery>;
export type SearchProjectsForEstimatorQueryResult = Apollo.QueryResult<SearchProjectsForEstimatorQuery, SearchProjectsForEstimatorQueryVariables>;
export const CreatePricingCommentDocument = gql`
    mutation CreatePricingComment($projectId: String!, $projectPricingId: Int!, $tasklistId: String!, $createdById: String!, $isForCogs: Boolean!, $comment: String!) {
  createPricingComment(
    projectId: $projectId
    projectPricingId: $projectPricingId
    tasklistId: $tasklistId
    createdById: $createdById
    isForCogs: $isForCogs
    comment: $comment
  ) {
    id
    project_id
    project_pricing_id
    tasklist_id
    created_by_id
    is_for_cogs
    comment
    created_at
  }
}
    `;
export type CreatePricingCommentMutationFn = Apollo.MutationFunction<CreatePricingCommentMutation, CreatePricingCommentMutationVariables>;

/**
 * __useCreatePricingCommentMutation__
 *
 * To run a mutation, you first call `useCreatePricingCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePricingCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPricingCommentMutation, { data, loading, error }] = useCreatePricingCommentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectPricingId: // value for 'projectPricingId'
 *      tasklistId: // value for 'tasklistId'
 *      createdById: // value for 'createdById'
 *      isForCogs: // value for 'isForCogs'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreatePricingCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePricingCommentMutation, CreatePricingCommentMutationVariables>) {
        return Apollo.useMutation<CreatePricingCommentMutation, CreatePricingCommentMutationVariables>(CreatePricingCommentDocument, baseOptions);
      }
export type CreatePricingCommentMutationHookResult = ReturnType<typeof useCreatePricingCommentMutation>;
export type CreatePricingCommentMutationResult = Apollo.MutationResult<CreatePricingCommentMutation>;
export type CreatePricingCommentMutationOptions = Apollo.BaseMutationOptions<CreatePricingCommentMutation, CreatePricingCommentMutationVariables>;
export const UpdatePricingCommentDocument = gql`
    mutation UpdatePricingComment($id: Int!, $comment: String!) {
  updatePricingComment(id: $id, comment: $comment) {
    id
    project_id
    project_pricing_id
    tasklist_id
    created_by_id
    comment
  }
}
    `;
export type UpdatePricingCommentMutationFn = Apollo.MutationFunction<UpdatePricingCommentMutation, UpdatePricingCommentMutationVariables>;

/**
 * __useUpdatePricingCommentMutation__
 *
 * To run a mutation, you first call `useUpdatePricingCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePricingCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePricingCommentMutation, { data, loading, error }] = useUpdatePricingCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdatePricingCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePricingCommentMutation, UpdatePricingCommentMutationVariables>) {
        return Apollo.useMutation<UpdatePricingCommentMutation, UpdatePricingCommentMutationVariables>(UpdatePricingCommentDocument, baseOptions);
      }
export type UpdatePricingCommentMutationHookResult = ReturnType<typeof useUpdatePricingCommentMutation>;
export type UpdatePricingCommentMutationResult = Apollo.MutationResult<UpdatePricingCommentMutation>;
export type UpdatePricingCommentMutationOptions = Apollo.BaseMutationOptions<UpdatePricingCommentMutation, UpdatePricingCommentMutationVariables>;
export const DeletePricingCommentDocument = gql`
    mutation DeletePricingComment($id: Int!) {
  deletePricingComment(id: $id) {
    id
    project_id
    project_pricing_id
    tasklist_id
    created_by_id
    comment
    created_at
  }
}
    `;
export type DeletePricingCommentMutationFn = Apollo.MutationFunction<DeletePricingCommentMutation, DeletePricingCommentMutationVariables>;

/**
 * __useDeletePricingCommentMutation__
 *
 * To run a mutation, you first call `useDeletePricingCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePricingCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePricingCommentMutation, { data, loading, error }] = useDeletePricingCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePricingCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePricingCommentMutation, DeletePricingCommentMutationVariables>) {
        return Apollo.useMutation<DeletePricingCommentMutation, DeletePricingCommentMutationVariables>(DeletePricingCommentDocument, baseOptions);
      }
export type DeletePricingCommentMutationHookResult = ReturnType<typeof useDeletePricingCommentMutation>;
export type DeletePricingCommentMutationResult = Apollo.MutationResult<DeletePricingCommentMutation>;
export type DeletePricingCommentMutationOptions = Apollo.BaseMutationOptions<DeletePricingCommentMutation, DeletePricingCommentMutationVariables>;
export const UpsertProjectOwnerDocument = gql`
    mutation UpsertProjectOwner($projectId: ID!, $projectOwnerId: ID!) {
  upsertProjectOwner(projectId: $projectId, projectOwnerId: $projectOwnerId) {
    id
    owner {
      id
      first_name
      last_name
    }
  }
}
    `;
export type UpsertProjectOwnerMutationFn = Apollo.MutationFunction<UpsertProjectOwnerMutation, UpsertProjectOwnerMutationVariables>;

/**
 * __useUpsertProjectOwnerMutation__
 *
 * To run a mutation, you first call `useUpsertProjectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectOwnerMutation, { data, loading, error }] = useUpsertProjectOwnerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      projectOwnerId: // value for 'projectOwnerId'
 *   },
 * });
 */
export function useUpsertProjectOwnerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectOwnerMutation, UpsertProjectOwnerMutationVariables>) {
        return Apollo.useMutation<UpsertProjectOwnerMutation, UpsertProjectOwnerMutationVariables>(UpsertProjectOwnerDocument, baseOptions);
      }
export type UpsertProjectOwnerMutationHookResult = ReturnType<typeof useUpsertProjectOwnerMutation>;
export type UpsertProjectOwnerMutationResult = Apollo.MutationResult<UpsertProjectOwnerMutation>;
export type UpsertProjectOwnerMutationOptions = Apollo.BaseMutationOptions<UpsertProjectOwnerMutation, UpsertProjectOwnerMutationVariables>;
export const UpsertProjectSbmLeadDocument = gql`
    mutation UpsertProjectSBMLead($projectId: ID!, $sbmLeadName: String!) {
  upsertSBMLead(projectId: $projectId, sbmLeadName: $sbmLeadName) {
    id
    senior_brand_maven_lead
  }
}
    `;
export type UpsertProjectSbmLeadMutationFn = Apollo.MutationFunction<UpsertProjectSbmLeadMutation, UpsertProjectSbmLeadMutationVariables>;

/**
 * __useUpsertProjectSbmLeadMutation__
 *
 * To run a mutation, you first call `useUpsertProjectSbmLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectSbmLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectSbmLeadMutation, { data, loading, error }] = useUpsertProjectSbmLeadMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      sbmLeadName: // value for 'sbmLeadName'
 *   },
 * });
 */
export function useUpsertProjectSbmLeadMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectSbmLeadMutation, UpsertProjectSbmLeadMutationVariables>) {
        return Apollo.useMutation<UpsertProjectSbmLeadMutation, UpsertProjectSbmLeadMutationVariables>(UpsertProjectSbmLeadDocument, baseOptions);
      }
export type UpsertProjectSbmLeadMutationHookResult = ReturnType<typeof useUpsertProjectSbmLeadMutation>;
export type UpsertProjectSbmLeadMutationResult = Apollo.MutationResult<UpsertProjectSbmLeadMutation>;
export type UpsertProjectSbmLeadMutationOptions = Apollo.BaseMutationOptions<UpsertProjectSbmLeadMutation, UpsertProjectSbmLeadMutationVariables>;
export const UpsertProjectSalesSplitPodDocument = gql`
    mutation UpsertProjectSalesSplitPod($projectId: ID!, $salesSplitPodId: ID) {
  upsertProjectSalesSplitPod(
    projectId: $projectId
    salesSplitPodId: $salesSplitPodId
  ) {
    id
    senior_brand_maven_lead
    sales_split_pod {
      id
      senior_brand_maven_lead
    }
  }
}
    `;
export type UpsertProjectSalesSplitPodMutationFn = Apollo.MutationFunction<UpsertProjectSalesSplitPodMutation, UpsertProjectSalesSplitPodMutationVariables>;

/**
 * __useUpsertProjectSalesSplitPodMutation__
 *
 * To run a mutation, you first call `useUpsertProjectSalesSplitPodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectSalesSplitPodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectSalesSplitPodMutation, { data, loading, error }] = useUpsertProjectSalesSplitPodMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      salesSplitPodId: // value for 'salesSplitPodId'
 *   },
 * });
 */
export function useUpsertProjectSalesSplitPodMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectSalesSplitPodMutation, UpsertProjectSalesSplitPodMutationVariables>) {
        return Apollo.useMutation<UpsertProjectSalesSplitPodMutation, UpsertProjectSalesSplitPodMutationVariables>(UpsertProjectSalesSplitPodDocument, baseOptions);
      }
export type UpsertProjectSalesSplitPodMutationHookResult = ReturnType<typeof useUpsertProjectSalesSplitPodMutation>;
export type UpsertProjectSalesSplitPodMutationResult = Apollo.MutationResult<UpsertProjectSalesSplitPodMutation>;
export type UpsertProjectSalesSplitPodMutationOptions = Apollo.BaseMutationOptions<UpsertProjectSalesSplitPodMutation, UpsertProjectSalesSplitPodMutationVariables>;
export const UpsertProjectSalesSplitRatioPercentDocument = gql`
    mutation UpsertProjectSalesSplitRatioPercent($projectId: ID!, $salesSplitRatioPercent: Int) {
  upsertProjectSalesSplitRatioPercent(
    projectId: $projectId
    salesSplitRatioPercent: $salesSplitRatioPercent
  ) {
    id
    senior_brand_maven_lead
    sales_split_pod {
      id
      senior_brand_maven_lead
    }
    sales_split_ratio
  }
}
    `;
export type UpsertProjectSalesSplitRatioPercentMutationFn = Apollo.MutationFunction<UpsertProjectSalesSplitRatioPercentMutation, UpsertProjectSalesSplitRatioPercentMutationVariables>;

/**
 * __useUpsertProjectSalesSplitRatioPercentMutation__
 *
 * To run a mutation, you first call `useUpsertProjectSalesSplitRatioPercentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectSalesSplitRatioPercentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectSalesSplitRatioPercentMutation, { data, loading, error }] = useUpsertProjectSalesSplitRatioPercentMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      salesSplitRatioPercent: // value for 'salesSplitRatioPercent'
 *   },
 * });
 */
export function useUpsertProjectSalesSplitRatioPercentMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectSalesSplitRatioPercentMutation, UpsertProjectSalesSplitRatioPercentMutationVariables>) {
        return Apollo.useMutation<UpsertProjectSalesSplitRatioPercentMutation, UpsertProjectSalesSplitRatioPercentMutationVariables>(UpsertProjectSalesSplitRatioPercentDocument, baseOptions);
      }
export type UpsertProjectSalesSplitRatioPercentMutationHookResult = ReturnType<typeof useUpsertProjectSalesSplitRatioPercentMutation>;
export type UpsertProjectSalesSplitRatioPercentMutationResult = Apollo.MutationResult<UpsertProjectSalesSplitRatioPercentMutation>;
export type UpsertProjectSalesSplitRatioPercentMutationOptions = Apollo.BaseMutationOptions<UpsertProjectSalesSplitRatioPercentMutation, UpsertProjectSalesSplitRatioPercentMutationVariables>;
export const UpsertProjectNotes2Document = gql`
    mutation UpsertProjectNotes2($projectId: ID!, $newNotes: String!) {
  upsertProjectNotes2(projectId: $projectId, newNotes: $newNotes) {
    id
    project_notes_2
  }
}
    `;
export type UpsertProjectNotes2MutationFn = Apollo.MutationFunction<UpsertProjectNotes2Mutation, UpsertProjectNotes2MutationVariables>;

/**
 * __useUpsertProjectNotes2Mutation__
 *
 * To run a mutation, you first call `useUpsertProjectNotes2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectNotes2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectNotes2Mutation, { data, loading, error }] = useUpsertProjectNotes2Mutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newNotes: // value for 'newNotes'
 *   },
 * });
 */
export function useUpsertProjectNotes2Mutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectNotes2Mutation, UpsertProjectNotes2MutationVariables>) {
        return Apollo.useMutation<UpsertProjectNotes2Mutation, UpsertProjectNotes2MutationVariables>(UpsertProjectNotes2Document, baseOptions);
      }
export type UpsertProjectNotes2MutationHookResult = ReturnType<typeof useUpsertProjectNotes2Mutation>;
export type UpsertProjectNotes2MutationResult = Apollo.MutationResult<UpsertProjectNotes2Mutation>;
export type UpsertProjectNotes2MutationOptions = Apollo.BaseMutationOptions<UpsertProjectNotes2Mutation, UpsertProjectNotes2MutationVariables>;
export const GetProjectsDocument = gql`
    query getProjects($where: projectWhereInput) {
  projects(orderBy: {created_at: desc}, where: $where) {
    ...ProjectFragment
  }
  categories {
    id
    name
    color
  }
}
    ${ProjectFragmentFragmentDoc}`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetProjectsQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
      }
export function useGetProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
        }
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<typeof useGetProjectsLazyQuery>;
export type GetProjectsQueryResult = Apollo.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const UpsertProjectComponentCodeDocument = gql`
    mutation UpsertProjectComponentCode($projectId: ID!, $newComponentCode: String!) {
  upsertProjectComponentCode(
    projectId: $projectId
    componentCode: $newComponentCode
  ) {
    id
    component_code
  }
}
    `;
export type UpsertProjectComponentCodeMutationFn = Apollo.MutationFunction<UpsertProjectComponentCodeMutation, UpsertProjectComponentCodeMutationVariables>;

/**
 * __useUpsertProjectComponentCodeMutation__
 *
 * To run a mutation, you first call `useUpsertProjectComponentCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectComponentCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectComponentCodeMutation, { data, loading, error }] = useUpsertProjectComponentCodeMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newComponentCode: // value for 'newComponentCode'
 *   },
 * });
 */
export function useUpsertProjectComponentCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectComponentCodeMutation, UpsertProjectComponentCodeMutationVariables>) {
        return Apollo.useMutation<UpsertProjectComponentCodeMutation, UpsertProjectComponentCodeMutationVariables>(UpsertProjectComponentCodeDocument, baseOptions);
      }
export type UpsertProjectComponentCodeMutationHookResult = ReturnType<typeof useUpsertProjectComponentCodeMutation>;
export type UpsertProjectComponentCodeMutationResult = Apollo.MutationResult<UpsertProjectComponentCodeMutation>;
export type UpsertProjectComponentCodeMutationOptions = Apollo.BaseMutationOptions<UpsertProjectComponentCodeMutation, UpsertProjectComponentCodeMutationVariables>;
export const UpsertProjectCategoryDocument = gql`
    mutation UpsertProjectCategory($upsertProjectCategoryIdProjectId: ID!, $upsertProjectCategoryIdProjectCategoryId: ID!) {
  upsertProjectCategoryId(
    projectId: $upsertProjectCategoryIdProjectId
    projectCategoryId: $upsertProjectCategoryIdProjectCategoryId
  ) {
    id
    category {
      id
      name
    }
  }
}
    `;
export type UpsertProjectCategoryMutationFn = Apollo.MutationFunction<UpsertProjectCategoryMutation, UpsertProjectCategoryMutationVariables>;

/**
 * __useUpsertProjectCategoryMutation__
 *
 * To run a mutation, you first call `useUpsertProjectCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectCategoryMutation, { data, loading, error }] = useUpsertProjectCategoryMutation({
 *   variables: {
 *      upsertProjectCategoryIdProjectId: // value for 'upsertProjectCategoryIdProjectId'
 *      upsertProjectCategoryIdProjectCategoryId: // value for 'upsertProjectCategoryIdProjectCategoryId'
 *   },
 * });
 */
export function useUpsertProjectCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectCategoryMutation, UpsertProjectCategoryMutationVariables>) {
        return Apollo.useMutation<UpsertProjectCategoryMutation, UpsertProjectCategoryMutationVariables>(UpsertProjectCategoryDocument, baseOptions);
      }
export type UpsertProjectCategoryMutationHookResult = ReturnType<typeof useUpsertProjectCategoryMutation>;
export type UpsertProjectCategoryMutationResult = Apollo.MutationResult<UpsertProjectCategoryMutation>;
export type UpsertProjectCategoryMutationOptions = Apollo.BaseMutationOptions<UpsertProjectCategoryMutation, UpsertProjectCategoryMutationVariables>;
export const UpsertProjectBudgetDocument = gql`
    mutation UpsertProjectBudget($projectId: ID!, $budget: Int!) {
  upsertProjectBudget(projectId: $projectId, budget: $budget) {
    id
    budget
  }
}
    `;
export type UpsertProjectBudgetMutationFn = Apollo.MutationFunction<UpsertProjectBudgetMutation, UpsertProjectBudgetMutationVariables>;

/**
 * __useUpsertProjectBudgetMutation__
 *
 * To run a mutation, you first call `useUpsertProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectBudgetMutation, { data, loading, error }] = useUpsertProjectBudgetMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      budget: // value for 'budget'
 *   },
 * });
 */
export function useUpsertProjectBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectBudgetMutation, UpsertProjectBudgetMutationVariables>) {
        return Apollo.useMutation<UpsertProjectBudgetMutation, UpsertProjectBudgetMutationVariables>(UpsertProjectBudgetDocument, baseOptions);
      }
export type UpsertProjectBudgetMutationHookResult = ReturnType<typeof useUpsertProjectBudgetMutation>;
export type UpsertProjectBudgetMutationResult = Apollo.MutationResult<UpsertProjectBudgetMutation>;
export type UpsertProjectBudgetMutationOptions = Apollo.BaseMutationOptions<UpsertProjectBudgetMutation, UpsertProjectBudgetMutationVariables>;
export const UpsertProjectClientBrandManagerDocument = gql`
    mutation UpsertProjectClientBrandManager($projectId: ID!, $clientBrandManager: String!) {
  upsertProjectClientBrandManager(
    projectId: $projectId
    clientBrandManager: $clientBrandManager
  ) {
    id
    client_brand_manager
  }
}
    `;
export type UpsertProjectClientBrandManagerMutationFn = Apollo.MutationFunction<UpsertProjectClientBrandManagerMutation, UpsertProjectClientBrandManagerMutationVariables>;

/**
 * __useUpsertProjectClientBrandManagerMutation__
 *
 * To run a mutation, you first call `useUpsertProjectClientBrandManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectClientBrandManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectClientBrandManagerMutation, { data, loading, error }] = useUpsertProjectClientBrandManagerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      clientBrandManager: // value for 'clientBrandManager'
 *   },
 * });
 */
export function useUpsertProjectClientBrandManagerMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectClientBrandManagerMutation, UpsertProjectClientBrandManagerMutationVariables>) {
        return Apollo.useMutation<UpsertProjectClientBrandManagerMutation, UpsertProjectClientBrandManagerMutationVariables>(UpsertProjectClientBrandManagerDocument, baseOptions);
      }
export type UpsertProjectClientBrandManagerMutationHookResult = ReturnType<typeof useUpsertProjectClientBrandManagerMutation>;
export type UpsertProjectClientBrandManagerMutationResult = Apollo.MutationResult<UpsertProjectClientBrandManagerMutation>;
export type UpsertProjectClientBrandManagerMutationOptions = Apollo.BaseMutationOptions<UpsertProjectClientBrandManagerMutation, UpsertProjectClientBrandManagerMutationVariables>;
export const UpsertProjectNotesDocument = gql`
    mutation UpsertProjectNotes($projectId: ID!, $newNotes: String!) {
  upsertProjectNotes(projectId: $projectId, newNotes: $newNotes) {
    id
    project_notes
  }
}
    `;
export type UpsertProjectNotesMutationFn = Apollo.MutationFunction<UpsertProjectNotesMutation, UpsertProjectNotesMutationVariables>;

/**
 * __useUpsertProjectNotesMutation__
 *
 * To run a mutation, you first call `useUpsertProjectNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectNotesMutation, { data, loading, error }] = useUpsertProjectNotesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newNotes: // value for 'newNotes'
 *   },
 * });
 */
export function useUpsertProjectNotesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectNotesMutation, UpsertProjectNotesMutationVariables>) {
        return Apollo.useMutation<UpsertProjectNotesMutation, UpsertProjectNotesMutationVariables>(UpsertProjectNotesDocument, baseOptions);
      }
export type UpsertProjectNotesMutationHookResult = ReturnType<typeof useUpsertProjectNotesMutation>;
export type UpsertProjectNotesMutationResult = Apollo.MutationResult<UpsertProjectNotesMutation>;
export type UpsertProjectNotesMutationOptions = Apollo.BaseMutationOptions<UpsertProjectNotesMutation, UpsertProjectNotesMutationVariables>;
export const UpsertProjectTypeDocument = gql`
    mutation UpsertProjectType($upsertProjectTypeProjectId: ID!, $upsertProjectTypeProjectType: project_type!) {
  upsertProjectType(
    projectId: $upsertProjectTypeProjectId
    projectType: $upsertProjectTypeProjectType
  ) {
    id
    project_type
  }
}
    `;
export type UpsertProjectTypeMutationFn = Apollo.MutationFunction<UpsertProjectTypeMutation, UpsertProjectTypeMutationVariables>;

/**
 * __useUpsertProjectTypeMutation__
 *
 * To run a mutation, you first call `useUpsertProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectTypeMutation, { data, loading, error }] = useUpsertProjectTypeMutation({
 *   variables: {
 *      upsertProjectTypeProjectId: // value for 'upsertProjectTypeProjectId'
 *      upsertProjectTypeProjectType: // value for 'upsertProjectTypeProjectType'
 *   },
 * });
 */
export function useUpsertProjectTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectTypeMutation, UpsertProjectTypeMutationVariables>) {
        return Apollo.useMutation<UpsertProjectTypeMutation, UpsertProjectTypeMutationVariables>(UpsertProjectTypeDocument, baseOptions);
      }
export type UpsertProjectTypeMutationHookResult = ReturnType<typeof useUpsertProjectTypeMutation>;
export type UpsertProjectTypeMutationResult = Apollo.MutationResult<UpsertProjectTypeMutation>;
export type UpsertProjectTypeMutationOptions = Apollo.BaseMutationOptions<UpsertProjectTypeMutation, UpsertProjectTypeMutationVariables>;
export const UpsertProjectLanguageDocument = gql`
    mutation UpsertProjectLanguage($upsertProjectLanguageProjectId: ID!, $upsertProjectLanguageLanguageType: project_language!) {
  upsertProjectLanguage(
    projectId: $upsertProjectLanguageProjectId
    languageType: $upsertProjectLanguageLanguageType
  ) {
    id
    language
  }
}
    `;
export type UpsertProjectLanguageMutationFn = Apollo.MutationFunction<UpsertProjectLanguageMutation, UpsertProjectLanguageMutationVariables>;

/**
 * __useUpsertProjectLanguageMutation__
 *
 * To run a mutation, you first call `useUpsertProjectLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectLanguageMutation, { data, loading, error }] = useUpsertProjectLanguageMutation({
 *   variables: {
 *      upsertProjectLanguageProjectId: // value for 'upsertProjectLanguageProjectId'
 *      upsertProjectLanguageLanguageType: // value for 'upsertProjectLanguageLanguageType'
 *   },
 * });
 */
export function useUpsertProjectLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectLanguageMutation, UpsertProjectLanguageMutationVariables>) {
        return Apollo.useMutation<UpsertProjectLanguageMutation, UpsertProjectLanguageMutationVariables>(UpsertProjectLanguageDocument, baseOptions);
      }
export type UpsertProjectLanguageMutationHookResult = ReturnType<typeof useUpsertProjectLanguageMutation>;
export type UpsertProjectLanguageMutationResult = Apollo.MutationResult<UpsertProjectLanguageMutation>;
export type UpsertProjectLanguageMutationOptions = Apollo.BaseMutationOptions<UpsertProjectLanguageMutation, UpsertProjectLanguageMutationVariables>;
export const UpsertProjectStatusDocument = gql`
    mutation UpsertProjectStatus($upsertProjectStatusProjectId: ID!, $upsertProjectStatusStatus: String!) {
  upsertProjectStatus(
    projectId: $upsertProjectStatusProjectId
    status: $upsertProjectStatusStatus
  ) {
    id
    project_status
  }
}
    `;
export type UpsertProjectStatusMutationFn = Apollo.MutationFunction<UpsertProjectStatusMutation, UpsertProjectStatusMutationVariables>;

/**
 * __useUpsertProjectStatusMutation__
 *
 * To run a mutation, you first call `useUpsertProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectStatusMutation, { data, loading, error }] = useUpsertProjectStatusMutation({
 *   variables: {
 *      upsertProjectStatusProjectId: // value for 'upsertProjectStatusProjectId'
 *      upsertProjectStatusStatus: // value for 'upsertProjectStatusStatus'
 *   },
 * });
 */
export function useUpsertProjectStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectStatusMutation, UpsertProjectStatusMutationVariables>) {
        return Apollo.useMutation<UpsertProjectStatusMutation, UpsertProjectStatusMutationVariables>(UpsertProjectStatusDocument, baseOptions);
      }
export type UpsertProjectStatusMutationHookResult = ReturnType<typeof useUpsertProjectStatusMutation>;
export type UpsertProjectStatusMutationResult = Apollo.MutationResult<UpsertProjectStatusMutation>;
export type UpsertProjectStatusMutationOptions = Apollo.BaseMutationOptions<UpsertProjectStatusMutation, UpsertProjectStatusMutationVariables>;
export const UpsertProjectExternalReviewTypeDocument = gql`
    mutation UpsertProjectExternalReviewType($upsertProjectExternalReviewTypeProjectId: ID!, $upsertProjectExternalReviewTypeExternalReviewType: external_reviewer!) {
  upsertProjectExternalReviewType(
    projectId: $upsertProjectExternalReviewTypeProjectId
    externalReviewType: $upsertProjectExternalReviewTypeExternalReviewType
  ) {
    id
    external_reviewer
  }
}
    `;
export type UpsertProjectExternalReviewTypeMutationFn = Apollo.MutationFunction<UpsertProjectExternalReviewTypeMutation, UpsertProjectExternalReviewTypeMutationVariables>;

/**
 * __useUpsertProjectExternalReviewTypeMutation__
 *
 * To run a mutation, you first call `useUpsertProjectExternalReviewTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectExternalReviewTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectExternalReviewTypeMutation, { data, loading, error }] = useUpsertProjectExternalReviewTypeMutation({
 *   variables: {
 *      upsertProjectExternalReviewTypeProjectId: // value for 'upsertProjectExternalReviewTypeProjectId'
 *      upsertProjectExternalReviewTypeExternalReviewType: // value for 'upsertProjectExternalReviewTypeExternalReviewType'
 *   },
 * });
 */
export function useUpsertProjectExternalReviewTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProjectExternalReviewTypeMutation, UpsertProjectExternalReviewTypeMutationVariables>) {
        return Apollo.useMutation<UpsertProjectExternalReviewTypeMutation, UpsertProjectExternalReviewTypeMutationVariables>(UpsertProjectExternalReviewTypeDocument, baseOptions);
      }
export type UpsertProjectExternalReviewTypeMutationHookResult = ReturnType<typeof useUpsertProjectExternalReviewTypeMutation>;
export type UpsertProjectExternalReviewTypeMutationResult = Apollo.MutationResult<UpsertProjectExternalReviewTypeMutation>;
export type UpsertProjectExternalReviewTypeMutationOptions = Apollo.BaseMutationOptions<UpsertProjectExternalReviewTypeMutation, UpsertProjectExternalReviewTypeMutationVariables>;
export const SetProjectInternalReviewDocument = gql`
    mutation SetProjectInternalReview($setProjectInternalReviewProjectId: ID!, $setProjectInternalReviewInternalReview: Boolean!) {
  setProjectInternalReview(
    projectId: $setProjectInternalReviewProjectId
    internalReview: $setProjectInternalReviewInternalReview
  ) {
    id
    internal_review
  }
}
    `;
export type SetProjectInternalReviewMutationFn = Apollo.MutationFunction<SetProjectInternalReviewMutation, SetProjectInternalReviewMutationVariables>;

/**
 * __useSetProjectInternalReviewMutation__
 *
 * To run a mutation, you first call `useSetProjectInternalReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectInternalReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectInternalReviewMutation, { data, loading, error }] = useSetProjectInternalReviewMutation({
 *   variables: {
 *      setProjectInternalReviewProjectId: // value for 'setProjectInternalReviewProjectId'
 *      setProjectInternalReviewInternalReview: // value for 'setProjectInternalReviewInternalReview'
 *   },
 * });
 */
export function useSetProjectInternalReviewMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectInternalReviewMutation, SetProjectInternalReviewMutationVariables>) {
        return Apollo.useMutation<SetProjectInternalReviewMutation, SetProjectInternalReviewMutationVariables>(SetProjectInternalReviewDocument, baseOptions);
      }
export type SetProjectInternalReviewMutationHookResult = ReturnType<typeof useSetProjectInternalReviewMutation>;
export type SetProjectInternalReviewMutationResult = Apollo.MutationResult<SetProjectInternalReviewMutation>;
export type SetProjectInternalReviewMutationOptions = Apollo.BaseMutationOptions<SetProjectInternalReviewMutation, SetProjectInternalReviewMutationVariables>;
export const UpdateOneProjectDocument = gql`
    mutation UpdateOneProject($updateOneProjectData: projectUpdateInput!, $updateOneProjectWhere: projectWhereUniqueInput!) {
  updateOneProject(data: $updateOneProjectData, where: $updateOneProjectWhere) {
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type UpdateOneProjectMutationFn = Apollo.MutationFunction<UpdateOneProjectMutation, UpdateOneProjectMutationVariables>;

/**
 * __useUpdateOneProjectMutation__
 *
 * To run a mutation, you first call `useUpdateOneProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneProjectMutation, { data, loading, error }] = useUpdateOneProjectMutation({
 *   variables: {
 *      updateOneProjectData: // value for 'updateOneProjectData'
 *      updateOneProjectWhere: // value for 'updateOneProjectWhere'
 *   },
 * });
 */
export function useUpdateOneProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneProjectMutation, UpdateOneProjectMutationVariables>) {
        return Apollo.useMutation<UpdateOneProjectMutation, UpdateOneProjectMutationVariables>(UpdateOneProjectDocument, baseOptions);
      }
export type UpdateOneProjectMutationHookResult = ReturnType<typeof useUpdateOneProjectMutation>;
export type UpdateOneProjectMutationResult = Apollo.MutationResult<UpdateOneProjectMutation>;
export type UpdateOneProjectMutationOptions = Apollo.BaseMutationOptions<UpdateOneProjectMutation, UpdateOneProjectMutationVariables>;
export const PullProjectDetailsFromTeamworkDocument = gql`
    mutation PullProjectDetailsFromTeamwork($projectIds: [ID!]!) {
  pullProjectDetailsFromTeamwork(projectIds: $projectIds) {
    id
    ...ProjectFragment
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type PullProjectDetailsFromTeamworkMutationFn = Apollo.MutationFunction<PullProjectDetailsFromTeamworkMutation, PullProjectDetailsFromTeamworkMutationVariables>;

/**
 * __usePullProjectDetailsFromTeamworkMutation__
 *
 * To run a mutation, you first call `usePullProjectDetailsFromTeamworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePullProjectDetailsFromTeamworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pullProjectDetailsFromTeamworkMutation, { data, loading, error }] = usePullProjectDetailsFromTeamworkMutation({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function usePullProjectDetailsFromTeamworkMutation(baseOptions?: Apollo.MutationHookOptions<PullProjectDetailsFromTeamworkMutation, PullProjectDetailsFromTeamworkMutationVariables>) {
        return Apollo.useMutation<PullProjectDetailsFromTeamworkMutation, PullProjectDetailsFromTeamworkMutationVariables>(PullProjectDetailsFromTeamworkDocument, baseOptions);
      }
export type PullProjectDetailsFromTeamworkMutationHookResult = ReturnType<typeof usePullProjectDetailsFromTeamworkMutation>;
export type PullProjectDetailsFromTeamworkMutationResult = Apollo.MutationResult<PullProjectDetailsFromTeamworkMutation>;
export type PullProjectDetailsFromTeamworkMutationOptions = Apollo.BaseMutationOptions<PullProjectDetailsFromTeamworkMutation, PullProjectDetailsFromTeamworkMutationVariables>;
export const ProjectInvoicesDocument = gql`
    query ProjectInvoices($projectId: String!) {
  projects(where: {id: {equals: $projectId}}) {
    id
    invoices {
      id
      ...InvoiceFragment
    }
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useProjectInvoicesQuery__
 *
 * To run a query within a React component, call `useProjectInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectInvoicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectInvoicesQuery(baseOptions: Apollo.QueryHookOptions<ProjectInvoicesQuery, ProjectInvoicesQueryVariables>) {
        return Apollo.useQuery<ProjectInvoicesQuery, ProjectInvoicesQueryVariables>(ProjectInvoicesDocument, baseOptions);
      }
export function useProjectInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectInvoicesQuery, ProjectInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<ProjectInvoicesQuery, ProjectInvoicesQueryVariables>(ProjectInvoicesDocument, baseOptions);
        }
export type ProjectInvoicesQueryHookResult = ReturnType<typeof useProjectInvoicesQuery>;
export type ProjectInvoicesLazyQueryHookResult = ReturnType<typeof useProjectInvoicesLazyQuery>;
export type ProjectInvoicesQueryResult = Apollo.QueryResult<ProjectInvoicesQuery, ProjectInvoicesQueryVariables>;
export const UnassociatdInvoicesDocument = gql`
    query UnassociatdInvoices {
  invoices(where: {project_id: null}) {
    id
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useUnassociatdInvoicesQuery__
 *
 * To run a query within a React component, call `useUnassociatdInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassociatdInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassociatdInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnassociatdInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>) {
        return Apollo.useQuery<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>(UnassociatdInvoicesDocument, baseOptions);
      }
export function useUnassociatdInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>(UnassociatdInvoicesDocument, baseOptions);
        }
export type UnassociatdInvoicesQueryHookResult = ReturnType<typeof useUnassociatdInvoicesQuery>;
export type UnassociatdInvoicesLazyQueryHookResult = ReturnType<typeof useUnassociatdInvoicesLazyQuery>;
export type UnassociatdInvoicesQueryResult = Apollo.QueryResult<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>;
export const PeopleAndProjectsDocument = gql`
    query PeopleAndProjects {
  people {
    id
    first_name
    last_name
  }
  projects {
    id
    component_code
    name
  }
}
    `;

/**
 * __usePeopleAndProjectsQuery__
 *
 * To run a query within a React component, call `usePeopleAndProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleAndProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleAndProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleAndProjectsQuery(baseOptions?: Apollo.QueryHookOptions<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>) {
        return Apollo.useQuery<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>(PeopleAndProjectsDocument, baseOptions);
      }
export function usePeopleAndProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>) {
          return Apollo.useLazyQuery<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>(PeopleAndProjectsDocument, baseOptions);
        }
export type PeopleAndProjectsQueryHookResult = ReturnType<typeof usePeopleAndProjectsQuery>;
export type PeopleAndProjectsLazyQueryHookResult = ReturnType<typeof usePeopleAndProjectsLazyQuery>;
export type PeopleAndProjectsQueryResult = Apollo.QueryResult<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($createOneinvoicesData: invoiceCreateInput!) {
  createOneInvoice(data: $createOneinvoicesData) {
    id
    project {
      id
      invoices {
        id
      }
      cogs_in_cents
    }
    files {
      id
      contentType
      fileName
      createdBy {
        id
      }
    }
  }
}
    `;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      createOneinvoicesData: // value for 'createOneinvoicesData'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, baseOptions);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const InvoicesByInvoiceNumberDocument = gql`
    query InvoicesByInvoiceNumber($where: invoiceWhereInput) {
  invoices(where: $where) {
    ...InvoiceFragment
  }
}
    ${InvoiceFragmentFragmentDoc}`;

/**
 * __useInvoicesByInvoiceNumberQuery__
 *
 * To run a query within a React component, call `useInvoicesByInvoiceNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesByInvoiceNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesByInvoiceNumberQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInvoicesByInvoiceNumberQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesByInvoiceNumberQuery, InvoicesByInvoiceNumberQueryVariables>) {
        return Apollo.useQuery<InvoicesByInvoiceNumberQuery, InvoicesByInvoiceNumberQueryVariables>(InvoicesByInvoiceNumberDocument, baseOptions);
      }
export function useInvoicesByInvoiceNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesByInvoiceNumberQuery, InvoicesByInvoiceNumberQueryVariables>) {
          return Apollo.useLazyQuery<InvoicesByInvoiceNumberQuery, InvoicesByInvoiceNumberQueryVariables>(InvoicesByInvoiceNumberDocument, baseOptions);
        }
export type InvoicesByInvoiceNumberQueryHookResult = ReturnType<typeof useInvoicesByInvoiceNumberQuery>;
export type InvoicesByInvoiceNumberLazyQueryHookResult = ReturnType<typeof useInvoicesByInvoiceNumberLazyQuery>;
export type InvoicesByInvoiceNumberQueryResult = Apollo.QueryResult<InvoicesByInvoiceNumberQuery, InvoicesByInvoiceNumberQueryVariables>;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($invoiceId: String!) {
  deleteOneInvoice(where: {id: $invoiceId}) {
    id
  }
}
    `;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, baseOptions);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const PromptProjectDocument = gql`
    query PromptProject {
  projects {
    id
    component_code
    name
  }
}
    `;

/**
 * __usePromptProjectQuery__
 *
 * To run a query within a React component, call `usePromptProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromptProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromptProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function usePromptProjectQuery(baseOptions?: Apollo.QueryHookOptions<PromptProjectQuery, PromptProjectQueryVariables>) {
        return Apollo.useQuery<PromptProjectQuery, PromptProjectQueryVariables>(PromptProjectDocument, baseOptions);
      }
export function usePromptProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromptProjectQuery, PromptProjectQueryVariables>) {
          return Apollo.useLazyQuery<PromptProjectQuery, PromptProjectQueryVariables>(PromptProjectDocument, baseOptions);
        }
export type PromptProjectQueryHookResult = ReturnType<typeof usePromptProjectQuery>;
export type PromptProjectLazyQueryHookResult = ReturnType<typeof usePromptProjectLazyQuery>;
export type PromptProjectQueryResult = Apollo.QueryResult<PromptProjectQuery, PromptProjectQueryVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($invoiceId: String!, $invoiceData: invoiceUpdateInput!) {
  updateOneInvoice(data: $invoiceData, where: {id: $invoiceId}) {
    id
    invoice_number
    amount_in_cents
    expense_type
    notes
    project {
      id
      invoices {
        id
        amount_in_cents
      }
    }
  }
}
    `;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      invoiceData: // value for 'invoiceData'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, baseOptions);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const CompanyNameByCompanyIdDocument = gql`
    query CompanyNameByCompanyId($companyId: String!) {
  clientById(id: $companyId) {
    name
  }
}
    `;

/**
 * __useCompanyNameByCompanyIdQuery__
 *
 * To run a query within a React component, call `useCompanyNameByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyNameByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyNameByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyNameByCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<CompanyNameByCompanyIdQuery, CompanyNameByCompanyIdQueryVariables>) {
        return Apollo.useQuery<CompanyNameByCompanyIdQuery, CompanyNameByCompanyIdQueryVariables>(CompanyNameByCompanyIdDocument, baseOptions);
      }
export function useCompanyNameByCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyNameByCompanyIdQuery, CompanyNameByCompanyIdQueryVariables>) {
          return Apollo.useLazyQuery<CompanyNameByCompanyIdQuery, CompanyNameByCompanyIdQueryVariables>(CompanyNameByCompanyIdDocument, baseOptions);
        }
export type CompanyNameByCompanyIdQueryHookResult = ReturnType<typeof useCompanyNameByCompanyIdQuery>;
export type CompanyNameByCompanyIdLazyQueryHookResult = ReturnType<typeof useCompanyNameByCompanyIdLazyQuery>;
export type CompanyNameByCompanyIdQueryResult = Apollo.QueryResult<CompanyNameByCompanyIdQuery, CompanyNameByCompanyIdQueryVariables>;
export const ProjectNameByProjectIdDocument = gql`
    query ProjectNameByProjectId($projectId: String!) {
  projectById(id: $projectId) {
    name
  }
}
    `;

/**
 * __useProjectNameByProjectIdQuery__
 *
 * To run a query within a React component, call `useProjectNameByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNameByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNameByProjectIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectNameByProjectIdQuery(baseOptions: Apollo.QueryHookOptions<ProjectNameByProjectIdQuery, ProjectNameByProjectIdQueryVariables>) {
        return Apollo.useQuery<ProjectNameByProjectIdQuery, ProjectNameByProjectIdQueryVariables>(ProjectNameByProjectIdDocument, baseOptions);
      }
export function useProjectNameByProjectIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectNameByProjectIdQuery, ProjectNameByProjectIdQueryVariables>) {
          return Apollo.useLazyQuery<ProjectNameByProjectIdQuery, ProjectNameByProjectIdQueryVariables>(ProjectNameByProjectIdDocument, baseOptions);
        }
export type ProjectNameByProjectIdQueryHookResult = ReturnType<typeof useProjectNameByProjectIdQuery>;
export type ProjectNameByProjectIdLazyQueryHookResult = ReturnType<typeof useProjectNameByProjectIdLazyQuery>;
export type ProjectNameByProjectIdQueryResult = Apollo.QueryResult<ProjectNameByProjectIdQuery, ProjectNameByProjectIdQueryVariables>;
export const UserTimeSummaryDocument = gql`
    query UserTimeSummary($start_date: Date!, $end_date: Date!, $year: Int!) {
  me {
    id
    first_name
    last_name
    created_at
    annual_time_targets(year: $year) {
      id
      billableMinutes
      nonBillableMinutes
      salesAndMarketingMinutes
      innovationAndDevMinutes
      operationalMinutes
      professionalDevMinutes
      year
      personId
    }
    timeEntries(start_date: $start_date, end_date: $end_date) {
      id
      date
      minutes
      user_id
      company_id
      project_id
      project_tag_id
    }
    daysOff(start_date: $start_date, end_date: $end_date) {
      date
      reason
      personId
    }
  }
}
    `;

/**
 * __useUserTimeSummaryQuery__
 *
 * To run a query within a React component, call `useUserTimeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTimeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTimeSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUserTimeSummaryQuery(baseOptions: Apollo.QueryHookOptions<UserTimeSummaryQuery, UserTimeSummaryQueryVariables>) {
        return Apollo.useQuery<UserTimeSummaryQuery, UserTimeSummaryQueryVariables>(UserTimeSummaryDocument, baseOptions);
      }
export function useUserTimeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserTimeSummaryQuery, UserTimeSummaryQueryVariables>) {
          return Apollo.useLazyQuery<UserTimeSummaryQuery, UserTimeSummaryQueryVariables>(UserTimeSummaryDocument, baseOptions);
        }
export type UserTimeSummaryQueryHookResult = ReturnType<typeof useUserTimeSummaryQuery>;
export type UserTimeSummaryLazyQueryHookResult = ReturnType<typeof useUserTimeSummaryLazyQuery>;
export type UserTimeSummaryQueryResult = Apollo.QueryResult<UserTimeSummaryQuery, UserTimeSummaryQueryVariables>;
export const AddDayOffDocument = gql`
    mutation AddDayOff($personId: String!, $date: String!, $reason: String!) {
  markDayOff(personId: $personId, date: $date, reason: $reason) {
    date
    reason
    personId
  }
}
    `;
export type AddDayOffMutationFn = Apollo.MutationFunction<AddDayOffMutation, AddDayOffMutationVariables>;

/**
 * __useAddDayOffMutation__
 *
 * To run a mutation, you first call `useAddDayOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDayOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDayOffMutation, { data, loading, error }] = useAddDayOffMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      date: // value for 'date'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAddDayOffMutation(baseOptions?: Apollo.MutationHookOptions<AddDayOffMutation, AddDayOffMutationVariables>) {
        return Apollo.useMutation<AddDayOffMutation, AddDayOffMutationVariables>(AddDayOffDocument, baseOptions);
      }
export type AddDayOffMutationHookResult = ReturnType<typeof useAddDayOffMutation>;
export type AddDayOffMutationResult = Apollo.MutationResult<AddDayOffMutation>;
export type AddDayOffMutationOptions = Apollo.BaseMutationOptions<AddDayOffMutation, AddDayOffMutationVariables>;
export const RemoveDayOffDocument = gql`
    mutation RemoveDayOff($personId: String!, $date: String!) {
  removeDayOff(personId: $personId, date: $date) {
    date
    reason
    personId
  }
}
    `;
export type RemoveDayOffMutationFn = Apollo.MutationFunction<RemoveDayOffMutation, RemoveDayOffMutationVariables>;

/**
 * __useRemoveDayOffMutation__
 *
 * To run a mutation, you first call `useRemoveDayOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDayOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDayOffMutation, { data, loading, error }] = useRemoveDayOffMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useRemoveDayOffMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDayOffMutation, RemoveDayOffMutationVariables>) {
        return Apollo.useMutation<RemoveDayOffMutation, RemoveDayOffMutationVariables>(RemoveDayOffDocument, baseOptions);
      }
export type RemoveDayOffMutationHookResult = ReturnType<typeof useRemoveDayOffMutation>;
export type RemoveDayOffMutationResult = Apollo.MutationResult<RemoveDayOffMutation>;
export type RemoveDayOffMutationOptions = Apollo.BaseMutationOptions<RemoveDayOffMutation, RemoveDayOffMutationVariables>;
export const MarkMultipleDaysOffDocument = gql`
    mutation MarkMultipleDaysOff($personId: String!, $startDate: String!, $endDate: String!, $reason: String!) {
  markMultipleDaysOff(
    personId: $personId
    startDate: $startDate
    endDate: $endDate
    reason: $reason
  ) {
    date
    reason
    personId
  }
}
    `;
export type MarkMultipleDaysOffMutationFn = Apollo.MutationFunction<MarkMultipleDaysOffMutation, MarkMultipleDaysOffMutationVariables>;

/**
 * __useMarkMultipleDaysOffMutation__
 *
 * To run a mutation, you first call `useMarkMultipleDaysOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMultipleDaysOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMultipleDaysOffMutation, { data, loading, error }] = useMarkMultipleDaysOffMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useMarkMultipleDaysOffMutation(baseOptions?: Apollo.MutationHookOptions<MarkMultipleDaysOffMutation, MarkMultipleDaysOffMutationVariables>) {
        return Apollo.useMutation<MarkMultipleDaysOffMutation, MarkMultipleDaysOffMutationVariables>(MarkMultipleDaysOffDocument, baseOptions);
      }
export type MarkMultipleDaysOffMutationHookResult = ReturnType<typeof useMarkMultipleDaysOffMutation>;
export type MarkMultipleDaysOffMutationResult = Apollo.MutationResult<MarkMultipleDaysOffMutation>;
export type MarkMultipleDaysOffMutationOptions = Apollo.BaseMutationOptions<MarkMultipleDaysOffMutation, MarkMultipleDaysOffMutationVariables>;
export const AddTimeLogDocument = gql`
    mutation AddTimeLog($personId: String!, $date: String!, $hours: Int!, $minutes: Int!, $projectId: String!, $companyId: String!, $billable: Boolean!) {
  createTimeEntry(
    personId: $personId
    date: $date
    hours: $hours
    minutes: $minutes
    projectId: $projectId
    companyId: $companyId
    billable: $billable
  ) {
    id
    date
    minutes
    user_id
    company_id
    project_id
  }
}
    `;
export type AddTimeLogMutationFn = Apollo.MutationFunction<AddTimeLogMutation, AddTimeLogMutationVariables>;

/**
 * __useAddTimeLogMutation__
 *
 * To run a mutation, you first call `useAddTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTimeLogMutation, { data, loading, error }] = useAddTimeLogMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      date: // value for 'date'
 *      hours: // value for 'hours'
 *      minutes: // value for 'minutes'
 *      projectId: // value for 'projectId'
 *      companyId: // value for 'companyId'
 *      billable: // value for 'billable'
 *   },
 * });
 */
export function useAddTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<AddTimeLogMutation, AddTimeLogMutationVariables>) {
        return Apollo.useMutation<AddTimeLogMutation, AddTimeLogMutationVariables>(AddTimeLogDocument, baseOptions);
      }
export type AddTimeLogMutationHookResult = ReturnType<typeof useAddTimeLogMutation>;
export type AddTimeLogMutationResult = Apollo.MutationResult<AddTimeLogMutation>;
export type AddTimeLogMutationOptions = Apollo.BaseMutationOptions<AddTimeLogMutation, AddTimeLogMutationVariables>;
export const DeleteTimeLogDocument = gql`
    mutation DeleteTimeLog($id: String!) {
  deleteTimeEntry(id: $id) {
    id
  }
}
    `;
export type DeleteTimeLogMutationFn = Apollo.MutationFunction<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>;

/**
 * __useDeleteTimeLogMutation__
 *
 * To run a mutation, you first call `useDeleteTimeLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimeLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimeLogMutation, { data, loading, error }] = useDeleteTimeLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimeLogMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>) {
        return Apollo.useMutation<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>(DeleteTimeLogDocument, baseOptions);
      }
export type DeleteTimeLogMutationHookResult = ReturnType<typeof useDeleteTimeLogMutation>;
export type DeleteTimeLogMutationResult = Apollo.MutationResult<DeleteTimeLogMutation>;
export type DeleteTimeLogMutationOptions = Apollo.BaseMutationOptions<DeleteTimeLogMutation, DeleteTimeLogMutationVariables>;
export const UserPodTimeSummaryDocument = gql`
    query UserPodTimeSummary($start_date: Date!, $end_date: Date!, $year: Int!) {
  me {
    id
    first_name
    last_name
    pod {
      name
      senior_brand_maven_lead
      members {
        first_name
        last_name
        id
        annual_time_targets(year: $year) {
          id
          billableMinutes
          nonBillableMinutes
          salesAndMarketingMinutes
          innovationAndDevMinutes
          operationalMinutes
          professionalDevMinutes
          year
          personId
        }
        timeEntries(start_date: $start_date, end_date: $end_date) {
          id
          date
          minutes
          user_id
          company_id
          project_id
        }
        daysOff(start_date: $start_date, end_date: $end_date) {
          date
          reason
          personId
        }
      }
    }
  }
}
    `;

/**
 * __useUserPodTimeSummaryQuery__
 *
 * To run a query within a React component, call `useUserPodTimeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPodTimeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPodTimeSummaryQuery({
 *   variables: {
 *      start_date: // value for 'start_date'
 *      end_date: // value for 'end_date'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useUserPodTimeSummaryQuery(baseOptions: Apollo.QueryHookOptions<UserPodTimeSummaryQuery, UserPodTimeSummaryQueryVariables>) {
        return Apollo.useQuery<UserPodTimeSummaryQuery, UserPodTimeSummaryQueryVariables>(UserPodTimeSummaryDocument, baseOptions);
      }
export function useUserPodTimeSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPodTimeSummaryQuery, UserPodTimeSummaryQueryVariables>) {
          return Apollo.useLazyQuery<UserPodTimeSummaryQuery, UserPodTimeSummaryQueryVariables>(UserPodTimeSummaryDocument, baseOptions);
        }
export type UserPodTimeSummaryQueryHookResult = ReturnType<typeof useUserPodTimeSummaryQuery>;
export type UserPodTimeSummaryLazyQueryHookResult = ReturnType<typeof useUserPodTimeSummaryLazyQuery>;
export type UserPodTimeSummaryQueryResult = Apollo.QueryResult<UserPodTimeSummaryQuery, UserPodTimeSummaryQueryVariables>;
export const EstimatesDocument = gql`
    query Estimates {
  estimates {
    ...PartEstimate
  }
}
    ${PartEstimateFragmentDoc}`;

/**
 * __useEstimatesQuery__
 *
 * To run a query within a React component, call `useEstimatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstimatesQuery(baseOptions?: Apollo.QueryHookOptions<EstimatesQuery, EstimatesQueryVariables>) {
        return Apollo.useQuery<EstimatesQuery, EstimatesQueryVariables>(EstimatesDocument, baseOptions);
      }
export function useEstimatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstimatesQuery, EstimatesQueryVariables>) {
          return Apollo.useLazyQuery<EstimatesQuery, EstimatesQueryVariables>(EstimatesDocument, baseOptions);
        }
export type EstimatesQueryHookResult = ReturnType<typeof useEstimatesQuery>;
export type EstimatesLazyQueryHookResult = ReturnType<typeof useEstimatesLazyQuery>;
export type EstimatesQueryResult = Apollo.QueryResult<EstimatesQuery, EstimatesQueryVariables>;
export const PeopleDataDocument = gql`
    query PeopleData {
  me {
    ...Person
    pods {
      id
      name
      senior_brand_maven_lead
      members {
        id
        first_name
      }
    }
  }
  people {
    ...Person
  }
  pods {
    id
    name
    senior_brand_maven_lead
    members {
      id
    }
  }
}
    ${PersonFragmentDoc}`;

/**
 * __usePeopleDataQuery__
 *
 * To run a query within a React component, call `usePeopleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePeopleDataQuery(baseOptions?: Apollo.QueryHookOptions<PeopleDataQuery, PeopleDataQueryVariables>) {
        return Apollo.useQuery<PeopleDataQuery, PeopleDataQueryVariables>(PeopleDataDocument, baseOptions);
      }
export function usePeopleDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PeopleDataQuery, PeopleDataQueryVariables>) {
          return Apollo.useLazyQuery<PeopleDataQuery, PeopleDataQueryVariables>(PeopleDataDocument, baseOptions);
        }
export type PeopleDataQueryHookResult = ReturnType<typeof usePeopleDataQuery>;
export type PeopleDataLazyQueryHookResult = ReturnType<typeof usePeopleDataLazyQuery>;
export type PeopleDataQueryResult = Apollo.QueryResult<PeopleDataQuery, PeopleDataQueryVariables>;
export const GetProjectPricingDetailsByIdDocument = gql`
    query GetProjectPricingDetailsById($projectId: String!) {
  project(where: {id: $projectId}) {
    id
    name
    last_synced_details_at
    budget
    project_status
    invoices {
      id
      expense_type
      payee_name
      amount_in_cents
      date_created
    }
    time_entries {
      id
      date
      minutes
      tasklist_id
      user_id
    }
    project_pricing {
      id
      price
      actual_cost
      currency
      projected_times {
        id
        tasklist_id
        role_id
        team_id
        projected_minutes
      }
      projected_expenses {
        id
        project_id
        project_pricing_id
        budget_in_cents
        expense_type
        payee_name
      }
      comments {
        id
        project_id
        project_pricing_id
        tasklist_id
        comment
        created_at
        created_by_id
        is_for_cogs
        created_by {
          id
          first_name
          last_name
          avatar_url
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectPricingDetailsByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectPricingDetailsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPricingDetailsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPricingDetailsByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectPricingDetailsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectPricingDetailsByIdQuery, GetProjectPricingDetailsByIdQueryVariables>) {
        return Apollo.useQuery<GetProjectPricingDetailsByIdQuery, GetProjectPricingDetailsByIdQueryVariables>(GetProjectPricingDetailsByIdDocument, baseOptions);
      }
export function useGetProjectPricingDetailsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectPricingDetailsByIdQuery, GetProjectPricingDetailsByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectPricingDetailsByIdQuery, GetProjectPricingDetailsByIdQueryVariables>(GetProjectPricingDetailsByIdDocument, baseOptions);
        }
export type GetProjectPricingDetailsByIdQueryHookResult = ReturnType<typeof useGetProjectPricingDetailsByIdQuery>;
export type GetProjectPricingDetailsByIdLazyQueryHookResult = ReturnType<typeof useGetProjectPricingDetailsByIdLazyQuery>;
export type GetProjectPricingDetailsByIdQueryResult = Apollo.QueryResult<GetProjectPricingDetailsByIdQuery, GetProjectPricingDetailsByIdQueryVariables>;