
import { gql, NetworkStatus, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  SimpleGrid,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useRef, useState, useEffect } from "react";
import StateManager from "react-select";
import Select from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import tw, { css } from "twin.macro";
import * as Yup from "yup";
import {
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables,
  PeopleAndProjectsQuery,
  PeopleAndProjectsQueryVariables,
  UnassociatdInvoicesQuery,
  UnassociatdInvoicesQueryVariables,
} from "../../codegen/graphql-types";
import { FullSpinner } from "../../FullSpinner";
import { useDebouncedValue } from "../../helpers/useDebouncedValue";
import { InvoicesByInvoiceNumber } from "./InvoicesByInvoiceNumber";
import { InvoicesTable } from "./InvoicesTable";
import { InvoiceFragment, ProjectInvoices } from "./ProjectInvoices";
import { useAuthContext } from "../../useAuth";
import { RuntimeConfig } from "../../RuntimeConfig";
import { usePeopleContext } from "../../helpers/usePeopleContext";
import { SupplierInvoiceFile } from "./SupplierInvoiceFile";
// Import the images
import paabPrompt1 from './images/paab/prompt1.png';
import paabPrompt2 from './images/paab/prompt2.png';
import paabPrompt3 from './images/paab/prompt3.png';
import transperfectPrompt1 from './images/transperfect/prompt1.png';
import transperfectPrompt2 from './images/transperfect/prompt2.png';

export const payeesByExpenseType = {
  Subcontractor: [], // this is gonna be dynamic
  "Regulatory review": ["PAAB", "ASC", "other"],
  "Translation services": [
    "Transol",
    "Translation Network",
    "Transperfect",
    "other",
  ],
  "Stock Photography or Design Assets": [
    "Shutterstock",
    "Getty Images",
    "Adobe Stock",
    "Envato",
    "Premiumbeat",
    "other",
  ],
  Printer: [
    "Advertek",
    "Barney Printing",
    "Information Packaging",
    "The Printing House",
    "other",
  ],
  Shipping: ["UPS", "Purolator", "Canada Post", "other"],
  Other: ["4imprint", "other"],
} as const;

export const expenseTypes = Object.keys(payeesByExpenseType) as Array<
  keyof typeof payeesByExpenseType
>;

export const CreateExpensePage = () => {
  const { accessToken } = useAuthContext();
  const { data: peopleData } = usePeopleContext();
  const {
    data: unassociatdInvoicesData,
    // loading: unassociatdInvoicesLoading,
    refetch: refetchUnassociatedInvoices,
  } = useQuery<UnassociatdInvoicesQuery, UnassociatdInvoicesQueryVariables>(
    gql`
      query UnassociatdInvoices {
        invoices(where: { project_id: null }) {
          id
          ...InvoiceFragment
        }
      }
      ${InvoiceFragment}
    `,
    {}
  );

  const {
    data: peopleAndProjectsData,
    networkStatus: peopleAndProjectsNetworkStatus,
  } = useQuery<PeopleAndProjectsQuery, PeopleAndProjectsQueryVariables>(
    gql`
      query PeopleAndProjects {
        people {
          id
          first_name
          last_name
        }
        projects {
          id
          component_code
          name
        }
      }
    `,
    {}
  );

  const [createExpense] = useMutation<
    CreateInvoiceMutation,
    CreateInvoiceMutationVariables
  >(
    gql`
      mutation CreateInvoice($createOneinvoicesData: invoiceCreateInput!) {
        createOneInvoice(data: $createOneinvoicesData) {
          id
          project {
            id
            invoices {
              id
            }
            cogs_in_cents
          }
          files {
            id
            contentType
            fileName
            createdBy {
              id
            }
          }
        }
      }
    `
  );

  const validationSchema = Yup.object({
    project_id: Yup.string().nullable(),
    amount_in_cents: Yup.number().required(),
    invoice_number: Yup.string().required(),
    payee_name: Yup.string().required(),
    contractor_id: Yup.string().nullable(),
    // expense_type: Yup.string().required(),
    expense_type: Yup.mixed<typeof expenseTypes[number]>()
      .oneOf(expenseTypes)
      .required(),
    notes: Yup.string().nullable(),
  }).required();

  type FormValues = Yup.InferType<typeof validationSchema>;

  const [
    amountInputRef,
    invoiceNumberInputRef,
    projectCodeSelectRef,
    expenseTypeSelectRef,
    payeeNameSelectRef,
  ] = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    // @ts-ignore
    useRef<StateManager<any>>(),
    // @ts-ignore
    useRef<StateManager<any>>(),
    // @ts-ignore
    useRef<StateManager<any>>(),
  ];

  const [uploadedFiles, setUploadedFiles] = useState<
    Array<{
      kind: string;
      id: string;
      selfLink: string;
      mediaLink: string;
      name: string;
      bucket: string;
      generation: string;
      metageneration: string;
      contentType: string;
      storageClass: string;
      size: string;
      md5Hash: string;
      crc32c: string;
      etag: string;
      timeCreated: string;
      updated: string;
      timeStorageClassUpdated: string;
    }>
  >([]);

  const {
    errors,
    values,
    // touched,
    // dirty,
    resetForm,
    // submitCount,
    setFieldValue,
    submitForm,
    isSubmitting: formikIsSubmitting,
  } = useFormik<FormValues>({
    initialValues: {
      // //@ts-expect-error
      // component_code: undefined,
      project_id: undefined,
      notes: undefined,
      //@ts-expect-error
      amount_in_cents: undefined,
      //@ts-expect-error
      payee_name: undefined,
      contractor_id: undefined,
      //@ts-expect-error
      expense_type: undefined,
      //@ts-expect-error
      invoice_number: undefined,
    },
    async onSubmit() {

      if (values.payee_name.includes(" - ")) {
        alert("Payee name cannot contain ' - '. Please remove the hyphen.");
        return;
      }

      try {
        const currentUserId = peopleData?.me?.id;
        if (!currentUserId) {
          throw new Error("No logged in person id.");
        }
        await createExpense({
          variables: {
            createOneinvoicesData: {
              project: values.project_id
                ? {
                    connect: {
                      id: values.project_id,
                    },
                  }
                : undefined,
              contractor: values.contractor_id
                ? {
                    connect: {
                      id: values.contractor_id,
                    },
                  }
                : undefined,
              amount_in_cents: values.amount_in_cents,
              expense_type: values.expense_type,
              invoice_number: values.invoice_number,
              payee_name: values.payee_name,
              notes: values.notes,
              files:
                uploadedFiles.length > 0
                  ? {
                      createMany: {
                        data: uploadedFiles.map((file) => ({
                          content_type: file.contentType,
                          created_by_id: currentUserId,
                          file_name: file.name,
                        })),
                      },
                    }
                  : undefined,
            },
          },
        });

        if (!values.project_id) {
          refetchUnassociatedInvoices();
        }

        setUploadedFiles([]);
        clearForm();
      } catch (error) {
        console.error({ error });
      }
    },
    validationSchema,
  });

  const clearForm = useCallback(() => {
    if (amountInputRef.current) {
      amountInputRef.current.value = "0";
    }

    if (invoiceNumberInputRef.current) {
      invoiceNumberInputRef.current.value = "";
    }

    // projectCodeSelectRef.current.select.select.select.clearValue();
    expenseTypeSelectRef.current?.select.clearValue();
    payeeNameSelectRef.current?.select.clearValue();

    resetForm({
      // @ts-expect-error
      values: {
        project_id: values.project_id,
      },
    });
  }, [
    amountInputRef,
    invoiceNumberInputRef,
    // projectCodeSelectRef,
    expenseTypeSelectRef,
    payeeNameSelectRef,
    resetForm,
    values,
  ]);

  const debouncedInvoiceNumber = useDebouncedValue(values.invoice_number, 1500);

  const loadSuggestedOptions = useCallback(
    _.debounce((inputValue, callback) => {
      // getOptions(inputValue).then(options => callback(options))
      callback(
        peopleAndProjectsData?.projects.flatMap((project) => {
          if (
            inputValue &&
            !`${project.component_code} ${project.name}`
              .toLowerCase()
              .includes(inputValue.trim().toLowerCase())
          ) {
            return [];
          }
          return {
            value: project,
            label: <>{project.name}</>,
          };
        })
      );
    }, 500),
    [peopleAndProjectsData]
  );

  const uploadFiles = useCallback(
    async (files: Array<Blob>) => {
      if (!accessToken) {
        throw new Error("No auth. Cant upload files without accessToken.");
      }

      const formData = new FormData();
      files.forEach((file) => {
        formData.append("invoices", file);
      });

      const response = await fetch(
        `${RuntimeConfig.backendOrigin}/supplier-invoices`,
        {
          method: "POST",
          headers: {
            "x-api-key": accessToken,
          },
          body: formData,
        }
      ).then((response) => response.json());

      setUploadedFiles((x) => [...x, ...response.data]);
    },
    [accessToken]
  );

  useEffect(() => {
    const callback = function (evt: ClipboardEvent) {
      // Get the data of clipboard
      const clipboardItems = evt.clipboardData?.items;
      if (!clipboardItems) {
        console.log("No clipboard items");
        return;
      }
      const items = Array.from(clipboardItems).filter(function (item) {
        return item.type.indexOf("image") !== -1;
      });
      if (items.length === 0) {
        return;
      }

      const item = items[0];
      // Get the blob of image
      const blob = item.getAsFile();

      if (!blob) {
        throw new Error(`Could not get blob from clipboard item`);
      }
      uploadFiles([
        new File(
          [blob],
          `project-${values.project_id ?? "unknown"}--clipboard.${
            blob.type.split("/")[1]
          }`,
          {
            type: blob.type,
          }
        ),
      ]);
    };
    document.addEventListener("paste", callback);

    return () => {
      document.removeEventListener("paste", callback);
    };
  }, [uploadFiles, values.project_id]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png", ".jpg", ".jpeg", ".gif"],
      "application/pdf": [".pdf"],
    },
    onDropAccepted: async (acceptedFiles) => {
      console.log({ acceptedFiles });
      uploadFiles(acceptedFiles);
    },
  });

  const shouldShowPaabPOPrompt =
    values.expense_type === "Regulatory review" && values.payee_name === "PAAB";
  const [isProjectNameInputFocused, setIsProjectNameInputFocused] = useState(
    false
  );

  const shouldShowTransperfectPOPrompt =
    values.expense_type === "Translation services" &&
    values.payee_name === "Transperfect";

  if (
    peopleAndProjectsNetworkStatus === NetworkStatus.loading ||
    peopleAndProjectsNetworkStatus === NetworkStatus.poll ||
    peopleAndProjectsNetworkStatus === NetworkStatus.refetch
  ) {
    return <FullSpinner />;
  }

  if (!peopleAndProjectsData) {
    console.error({ peopleAndProjectsData });
    throw new Error(`Could not load any people or projects data`);
  }

  const currentExpenseType = values.expense_type;

  const payeeOptions = !currentExpenseType
    ? []
    : currentExpenseType === "Subcontractor"
    ? peopleAndProjectsData.people.flatMap((person) => {
        return {
          value: person,
          label: `${person.first_name} ${person.last_name}`,
        };
      })
    : Array.from(payeesByExpenseType[currentExpenseType]).map((name) => ({
        value: name,
        label: name,
      }));

  return (
    <Flex css={tw`p-6 pt-8`}>
      <Flex
        css={css`
          max-width: 100%;
        `}
      >
        <form
          css={[
            tw`w-1/3 mr-6`,
            css`
              max-width: 400px;
              flex-shrink: 0;
            `,
          ]}
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <SimpleGrid columns={1} rowGap={4}>
            <FormControl>
              <Flex>
                <FormLabel tw="text-sm">Expense Type *</FormLabel>
                <div>{errors.expense_type && <RequiredPrompt />}</div>
              </Flex>
              <Select
                isDisabled={formikIsSubmitting}
                isMulti={false}
                closeMenuOnSelect={true}
                onChange={(selectedOption) => {
                  setFieldValue("expense_type", selectedOption?.value);
                }}
                options={expenseTypes.map((expenseType) => {
                  return {
                    value: expenseType,
                    label: expenseType,
                  };
                })}
                ref={expenseTypeSelectRef}
              />
            </FormControl>

            <FormControl>
              <Flex>
                <FormLabel tw="text-sm">Payee Name *</FormLabel>
                <div>{errors.payee_name && <RequiredPrompt />}</div>
              </Flex>
              <CreatableSelect
                ref={payeeNameSelectRef}
                isDisabled={formikIsSubmitting}
                isMulti={false}
                closeMenuOnSelect={true}
                onChange={(selectedOption) => {
                  const selectedOptionValue = selectedOption?.value;
                  if (
                    typeof selectedOptionValue === "object" &&
                    "first_name" in selectedOptionValue
                  ) {
                    setFieldValue("contractor_id", selectedOptionValue.id);
                    setFieldValue(
                      "payee_name",
                      `${selectedOptionValue.first_name} ${selectedOptionValue.last_name}`
                    );
                  } else {
                    setFieldValue("contractor_id", undefined);
                    setFieldValue("payee_name", selectedOptionValue);
                  }
                }}
                // @ts-ignore
                options={payeeOptions}
              />
            </FormControl>

            <FormControl>
              <Flex>
                <FormLabel tw="text-sm">Project Name</FormLabel>
                <div>{errors.project_id && <RequiredPrompt />}</div>
              </Flex>

              <AsyncCreatableSelect
                ref={projectCodeSelectRef}
                loadOptions={loadSuggestedOptions}
                isDisabled={formikIsSubmitting}
                isMulti={false}
                closeMenuOnSelect={true}
                formatCreateLabel={(inputValue) =>
                  `Create expense for "${inputValue}" (unassociated with a project)`
                }
                onChange={(selectedOption, { action }) => {
                  if (action === "create-option") {
                    setFieldValue("project_id", undefined);
                    // @ts-ignore
                    setFieldValue("notes", selectedOption?.value);
                  } else if (action === "select-option") {
                    // @ts-ignore
                    setFieldValue("project_id", selectedOption?.value.id);
                    setFieldValue("notes", undefined);
                  }
                }}
                onFocus={() => {
                  setIsProjectNameInputFocused(true);
                }}
                onBlur={() => {
                  setIsProjectNameInputFocused(false);
                }}
              />
              {isProjectNameInputFocused &&
                (shouldShowPaabPOPrompt || shouldShowTransperfectPOPrompt) && (
                  <div
                    css={[
                      tw`fixed ml-3 p-4 bg-white border border-gray-300 rounded shadow-lg`,
                      css`
                        left: min(34vw, 430px);
                        top: 4vh;
                        z-index: 1000;
                      `,
                    ]}
                  >
                    {shouldShowPaabPOPrompt && <PaabPrompt />}
                    {shouldShowTransperfectPOPrompt && <TransperfectPOPrompt />}
                  </div>
                )}
            </FormControl>

            <FormControl>
              <Flex>
                <FormLabel tw="text-sm">Amount * </FormLabel>
                <div>{errors.amount_in_cents && <RequiredPrompt />}</div>
              </Flex>
              <Input
                ref={amountInputRef}
                isDisabled={formikIsSubmitting}
                defaultValue={0}
                onChange={(e) =>
                  setFieldValue(
                    "amount_in_cents",
                    Math.round(Number(e.target.value) * 100)
                  )
                }
                type="decimal"
                onFocus={(e) => {
                  e.target.select();
                }}
                inputMode="decimal"
              />
            </FormControl>

            <FormControl>
              <Flex>
                <FormLabel tw="text-sm">Invoice Number *</FormLabel>
                <div>{errors.invoice_number && <RequiredPrompt />}</div>
              </Flex>
              <Input
                ref={invoiceNumberInputRef}
                isDisabled={formikIsSubmitting}
                onFocus={(e) => {
                  e.target.select();
                }}
                onChange={(e) =>
                  setFieldValue("invoice_number", e.target.value)
                }
              />
            </FormControl>

            <section css={tw`w-full overflow-auto`}>
              <h2 css={tw`font-bold mb-2`}>Upload Invoice</h2>
              <div
                {...getRootProps({ className: "dropzone" })}
                css={tw`bg-gray-100 border border-gray-300 rounded p-2`}
              >
                <input {...getInputProps()} />
                <p>
                  Drag and drop invoices here, or paste them from your clipboard
                </p>
              </div>
              <ul>
                {uploadedFiles.map((file) => (
                  <li key={file.id}>
                    <Popover isLazy trigger="hover">
                      <PopoverTrigger>
                        <div css={tw`flex flex-row items-center gap-5 my-2`}>
                          <div css={tw`w-20 h-20 flex-shrink-0`}>
                            <SupplierInvoiceFile
                              fileName={file.name}
                              fileType={file.contentType}
                            />
                          </div>
                          <div
                            css={[
                              tw`truncate flex-grow`,
                              css`
                                max-width: 200px;
                              `,
                            ]}
                          >
                            {file.name}{" "}
                          </div>
                          <Button
                            css={tw`ml-auto`}
                            onClick={() =>
                              setUploadedFiles((uploadFiles) =>
                                uploadFiles.filter((x) => x.id !== file.id)
                              )
                            }
                          >
                            <DeleteIcon />
                          </Button>
                        </div>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverBody>
                            <SupplierInvoiceFile
                              fileName={file.name}
                              fileType={file.contentType}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </Popover>
                  </li>
                ))}
              </ul>
            </section>

            <Button type="submit" isDisabled={formikIsSubmitting}>
              Create Expense
            </Button>
          </SimpleGrid>
        </form>
        <Flex direction="column">
          {values.project_id ? (
            <ProjectInvoices projectId={values.project_id} />
          ) : debouncedInvoiceNumber?.length > 1 ? (
            <InvoicesByInvoiceNumber invoiceNumber={debouncedInvoiceNumber} />
          ) : (
            <div>
              <Heading size="md" textAlign="center">
                Expenses Not Yet Associated With Projects
              </Heading>
              <InvoicesTable
                invoices={unassociatdInvoicesData?.invoices ?? []}
              />
            </div>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const RequiredPrompt = () => (
  <span css={tw`text-red-700 text-xs`}>This field is required</span>
);

const PaabPrompt = () => (
  <ol css={tw`text-sm flex flex-col gap-2 font-bold list-decimal pl-3`}>
    <li>
      Please check PAAB invoice to see whether a "PO" exists
      <br />
      <img
        src={paabPrompt1}
        alt=""
        css={css`max-width: 800px;`}
      />
    </li>
    <li>
      If PO# exists, please apply PAAB fee against the PAAB PO
      <img
        src={paabPrompt2}
        css={css`max-width: 400px;`}
        alt=""
      />
    </li>
    <li>
      If PO# does not exist, please apply PAAB fee against the individual
      project
      <img
        src={paabPrompt3}
        css={css`max-width: 800px;`}
        alt=""
      />
    </li>
  </ol>
);

const TransperfectPOPrompt = () => (
  <ol css={tw`text-sm flex flex-col gap-2 font-bold list-decimal pl-3`}>
    <li>
      Please check Transperfect invoice to see whether a “PO” number is provided
      with each project:
      <br />
      <img
        src={transperfectPrompt1}
        alt=""
        css={css`max-width: 700px;`}
      />
    </li>
    <li>
      If PO# exists, please apply Transperfect fee against the PO (e.g.,
      921217122), NOT the project code (e.g., CP-428963)
      <img
        src={transperfectPrompt2}
        alt=""
        css={css`max-width: 400px;`}
      />
    </li>
    <li>
      If PO# does not exist, please apply Transperfect fee against the
      individual project code (e.g., CP-428963)
    </li>
  </ol>
);
