import type * as GraphQLTypes from "../codegen/graphql-types";

export const transformEstimateStatus = (estimateStatus: GraphQLTypes.EstimateStatus) => {
  switch (estimateStatus) {
    case "INVOICE_SUBMITTABLE": {
      return "READY TO SUBMIT INVOICE";
    }
    default: {
      return estimateStatus;
    }
  }
}